import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { subscribe } from 'redux-subscriber';

import Translation from '../../translation/Translation';
import {
  apiDeleteItemCart,
  apiGetCart,
  setUserCart
} from '../../helpers/CartFunctions';
import { convertMinsToHrsMins, dateInTz } from '../../helpers/DateFunctions';

class CartItem extends Component {

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
  }

  deleteItem(event) {
    event.preventDefault();

    /**
     * Disable button for block user spam
     */
    const deleteButtons = document.getElementsByClassName('delete-item');

    for (let i = 0; i < deleteButtons.length; i++) {
      deleteButtons[i].disabled = true;
    }

    const itemDiv = document.getElementsByClassName('item-' + this.props.object.id)[0];

    apiDeleteItemCart(
      this.props.object.id,
      () => {
        if (itemDiv) {
          if (itemDiv.classList.contains('item-' + this.props.object.id)) {
            itemDiv.classList.add('fadeOutRight');
          }
        }
        apiGetCart(
          (getResponseData) => {
            /**
             * Update the cart into redux store
             */
            setUserCart(getResponseData);
            /**
             * Re-activate buttons
             */
            for (let i = 0; i < deleteButtons.length; i++) {
              deleteButtons[i].disabled = false;
            }
          },
          () => { }
        )
      },
      () => {
        if (itemDiv) {
          if (itemDiv.classList.contains('item-' + this.props.object.id)) {
            itemDiv.classList.remove('fade-item');
          }
        }
        for (let i = 0; i < deleteButtons.length; i++) {
          deleteButtons[i].disabled = false;
        }
      }
    );
  }

  render () {
    const item = this.props.object;
    const timezoneCode = this.props.timezoneCode;
    const serviceDuration = convertMinsToHrsMins(item && item.duration, Translation().cart.time_separator);

    return (
      <div className={"cart-item item-" + (item.id && item.id)}>
        <p className="item-title">{ item && item.name } <span className="icon-delete delete-item" onClick={this.deleteItem} /></p>
        <Col xs={12} sm={12} md={6} lg={7} className="item-options">
          { item.options && item.options.length > 0
            ? item.options.map((option, index) => {
              return (
                <p key={index} className="cart-option">
                  - { option.name && option.name }
                </p>
              )
            })
            : ""
          }
        </Col>
        <Col xs={12} sm={12} md={6} lg={5}>
          <p className="item-price without-tax"><span>{Translation().shop.tax_excluded}</span> {item && item.price_tax_excluded_formatted}</p>
          <p className="item-price with-tax"><span>{Translation().shop.tax_included}</span> {item && item.price_formatted}</p>
        </Col>
        <Clearfix/>
        { item.start &&
          <div className="item-planning">
            <p>{Translation().cart.day_prefix} {item.start && dateInTz(item.start, 'localized-date', timezoneCode)} {Translation().cart.hours_prefix} {item.start && dateInTz(item.start, 'HH:mm', timezoneCode)} {Translation().cart.during} {serviceDuration && serviceDuration}</p>
          </div>
        }
      </div>
    );
  }
}

class CartMobile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userCart: undefined
    }
  }

  componentDidMount() {
    // eslint-disable-next-line
    const subscribeProductsCart = subscribe('userCart' , (state) => {
      if (state && state.userCart) {
        this.setState({ userCart: state.userCart });
      }
    });
  }

  render() {
    /**
     * Get the cart from redux store
     */
    const userCart = this.state.userCart;
    const products = userCart && userCart.products ? userCart.products : 0;
    const timezoneCode = userCart && userCart.property_timezone_code ? userCart.property_timezone_code : undefined;

    return (
      <div className="app-cart-mobile">
        <div className="container">
          <div className="row">
            <div className="page-header-title">
              <h1>{Translation().cart.name}</h1>
            </div>
            { userCart && userCart.property_object && userCart.property_object.address && userCart.property_object.size_formatted
              ? (
                <div className="property-adrs">
                  <p>{ userCart.property_object.address } - { userCart.property_object.size_formatted }</p>
                  <a href="/shop">{Translation().cart.edit_property}</a>
                </div>
              )
              : ''
            }
            <div className="app-cart">
              <div className="cart-content">
                { products && products.length > 0
                  ? products.map((cartItem, index) => {
                    return (
                      <CartItem
                        key={index}
                        object={cartItem}
                        timezoneCode={timezoneCode}
                      />
                    )
                  })
                  : ''
                }
              </div>
              { userCart && userCart.discounts && userCart.discounts.length > 0 &&
                <div className="cart-promocode">
                  <ul>
                    { userCart && userCart.discounts && userCart.discounts.length > 0
                      ? ( userCart.discounts.map((promoCode, index) => {
                            return (
                              <li key={index} className="pcode">
                                <span className="promo-icon icon-promo" /><p>{promoCode.name ? promoCode.name : 'PROMO'}</p>
                              </li>
                            )
                          })
                        )
                      : ''
                    }
                  </ul>
                </div>
              }
              <div className="cart-total-amount">
                <table>
                  <tbody>
                    { userCart && userCart.discounts && userCart.discounts.length > 0 &&
                      <tr className="taxes">
                      <td className="promo-text">{Translation().shop.promotion}</td>
                      <td className="promo-price">{userCart && userCart.total_discount_formatted ? userCart.total_discount_formatted : 0}</td>
                      </tr>
                    }
                    <tr className="taxes">
                      <td className="vat-text">{Translation().shop.vat}</td>
                      <td className="vat-price">{userCart && userCart.total_tax_formatted ? userCart.total_tax_formatted : 0}</td>
                    </tr>
                    <tr className="total-with-taxes">
                      <td className="tax-text">{Translation().shop.total} {Translation().shop.tax_included}</td>
                      <td className="tax-price">{userCart && userCart.total_formatted ? userCart.total_formatted : 0 }</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            { products && products.length > 0
              ? (
                <div className="cart-payment">
                  <a className="app-button-color" href="/shop/2">{Translation().cart.continue_shopping}</a>
                  <span/>
                  <a className="app-button-color app-button-dark" href="/shop/4">{Translation().cart.payment}</a>
                </div>
              )
              : (
                <div className="cart-payment">
                  <a className="app-button-color" href="/shop">{Translation().cart.order}</a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default CartMobile;
