/**
 * USER ACTIONS
 */

export const storeGeoSetting = (setting) => ({
  type: 'STORE_GEO_SETTING',
  setting
});

export const storeUser = (user) => ({
  type: 'STORE_USER',
  user
});

export const removeUser = () => ({
  type: 'REMOVE_USER'
});

export const storeCompany = (company) => ({
  type: 'STORE_COMPANY',
  company
});

export const storeUserLoading = (isUserLoading) => ({
  type: 'STORE_USER_LOADING',
  isUserLoading
});
