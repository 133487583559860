import React, { Component } from 'react';
import bowser from 'bowser';

import UpdateBrowser from '../../commons/browser/UpdateBrowser';
import Translation from '../../../translation/Translation';

class ShopFailure extends Component {

  render() {

    if (bowser && (bowser.name === "Internet Explorer") && (bowser.version <= 10)) {
      return (
        <UpdateBrowser/>
      )
    }
    else {
      return (
        <div className="shop-property-error api-error" id="shopFailure">
          <div className="container">
            <div className="row">
              <div className="error-message-shop">
                <h3>{Translation().shop.failure.error.title}</h3>
                <h4>{Translation().shop.failure.error.baseline}</h4>
                <p>{Translation().shop.failure.error.description}</p>
                <a className="button-retry" href="/shop">{Translation().shop.failure.error.button}</a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ShopFailure;
