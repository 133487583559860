import Translation from '../../../translation/Translation';

const validate = values => {

  const errors = {};

  if (!values.email) {
    errors.email = Translation().forms.errors.required;
  }
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = Translation().forms.errors.email_invalid;
  }

  if (!values.password) {
    errors.password = Translation().forms.errors.required;
  }
  if (values.password && values.password.length < 8) {
    errors.password = Translation().forms.errors.password_length;
  }
  if (!values.confirmPwd) {
    errors.confirmPwd = Translation().forms.errors.required;
  }
  if (values.password && values.confirmPwd) {
    if (values.password !== values.confirmPwd) {
      errors.confirmPwd = Translation().forms.errors.password_not_matching;
    }
  }

  if (!values.last_name) {
    errors.last_name = Translation().forms.errors.required;
  }

  if (!values.date_of_birth) {
    errors.date_of_birth = Translation().forms.errors.required;
  }

  if (!values.first_name) {
    errors.first_name = Translation().forms.errors.required;
  }

  if (!values.mobile) {
    errors.mobile = Translation().forms.errors.required;
  }

  return errors;
}

export default validate;
