import React, { Component } from 'react';
import bowser from 'bowser';
import { Alert } from 'react-bootstrap';

class ModalBrowserSupported extends Component {

  constructor() {
    super();
    this.state = { 
      alertVisible: true
    };

    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
  } 

  handleAlertDismiss() {
    this.setState({ alertVisible: !this.state.alertVisible });
  }

  render() {
    const alertVisible = this.state.alertVisible;

    if (alertVisible) {
      return (
        <Alert bsStyle="warning" className="browser-alert">
          <strong>Oops, something went wrong!</strong>
          <p>Sorry, your version <i>[{bowser.version}]</i> of <span>{bowser.name}</span> doesn't support all features of this site.<br />
          Thanks to update your browser or install a different one, for a better experience.</p>
          <p className="close-alert" onClick={this.handleAlertDismiss}>Close</p>
        </Alert>
      );
    }
    else {
      return false;
    }
  }
}

class BrowserSupported extends Component {

  render() {

    if (bowser.name === 'Safari' && bowser.version < 8) {
      return (
        <ModalBrowserSupported />
      );
    }
    else if (bowser.name === 'Internet Explorer' && bowser.version <= 9) {
      return (
        <ModalBrowserSupported />
      );
    }
    else {
      return false;
    }
  }
}

export default BrowserSupported; 