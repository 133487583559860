import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { RadioButtonGroup } from 'redux-form-material-ui';
import { RadioButton } from 'material-ui/RadioButton';

import validate from './validate';
import renderFieldLabel from '../fields/renderFieldLabel';
import PhoneField from '../fields/PhoneField';
import BirthdateField from '../fields/BirthdateField';
import { initPlaceholder } from '../../../helpers/JqueryFunctions';
import {
  getCurrency,
  getLocale,
  getTimezone,
  getResidenceCountry,
  getCitizenCountry,
  getUnitSystem,
  getReferrerCode,
  getInviteEmailToken,
  getInviteToken,
} from '../../../helpers/UserFunctions';

import Translation from '../../../translation/Translation';

/**
 * If member got an invitation with mail, we target params in url
 * Auto fill email value in field
 * Pass invite_token value to send object
 */
class RegisterForm extends Component {

  componentWillReceiveProps(nextProps) {

    const referrerCode = getReferrerCode();
    const inviteEmailToken = getInviteEmailToken();
    const inviteToken = getInviteToken();

    if (referrerCode)
      this.props.change('referrer_code', referrerCode);

    if (inviteEmailToken)
      this.props.change('email', inviteEmailToken);

    if (inviteToken)
      this.props.change('invite_token', inviteToken);

    if (nextProps.geoSettings && nextProps.geoSettings.locale)
      this.props.change('language_locale', nextProps.geoSettings.locale);
    else
      this.props.change('language_locale', getLocale());

    if (nextProps.geoSettings && nextProps.geoSettings.timezone_code)
      this.props.change('timezone_code', nextProps.geoSettings.timezone_code);
    else
      this.props.change('timezone_code', getTimezone());

    if (nextProps.geoSettings && nextProps.geoSettings.currency_isocode)
      this.props.change('currency_isocode', nextProps.geoSettings.currency_isocode);
    else
      this.props.change('currency_isocode', getCurrency());

    if (nextProps.geoSettings && nextProps.geoSettings.residence_country)
      this.props.change('residence_country_isocode', nextProps.geoSettings.residence_country);
    else
      this.props.change('residence_country_isocode', getResidenceCountry());

    if (nextProps.geoSettings && nextProps.geoSettings.citizen_country)
      this.props.change('citizen_country_isocode', nextProps.geoSettings.citizen_country);
    else
      this.props.change('citizen_country_isocode', getCitizenCountry());

    if (nextProps.geoSettings && nextProps.geoSettings.unit_system)
      this.props.change('unit_system', nextProps.geoSettings.unit_system);
    else
      this.props.change('unit_system', getUnitSystem());
  }

  componentDidMount() {
    initPlaceholder();
  }

  render() {

    const inviteToken = getInviteToken();

    const {
      handleSubmit,
      submitting,
      dirty,
      openTermOfUse
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="register-form">
        <Col xs={12} sm={6} md={6}>
          <Field
            name="referrer_code"
            type="hidden"
            component="input"
            readOnly={true}
          />
          <Field
            name="invite_token"
            type="hidden"
            component="input"
            readOnly={true}
          />
          <Field
            name="language_locale"
            readOnly={true}
            type="hidden"
            component="input"
          />
          <Field
            name="timezone_code"
            readOnly={true}
            type="hidden"
            component="input"
          />
          <Field
            name="unit_system"
            readOnly={true}
            type="hidden"
            component="input"
          />
          <Field
            name="currency_isocode"
            readOnly={true}
            type="hidden"
            component="input"
          />
          <Field
            name="citizen_country_isocode"
            readOnly={true}
            type="hidden"
            component="input"
          />
          <Field
            name="residence_country_isocode"
            readOnly={true}
            type="hidden"
            component="input"
          />
          <div className="gender-box">
            <Field name="gender" component={RadioButtonGroup} style={{display: 'flex'}}>
              <RadioButton
                style={{width: 'auto'}}
                value="F"
                label={Translation().register.form.labels.female}
              />
              <RadioButton
                style={{width: 'auto'}}
                value="M"
                label={Translation().register.form.labels.male}
              />
            </Field>
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} />
        <Clearfix/>
        <Col xs={12} sm={6} md={6}>
          <div className="form-cel">
            <Field
              name="last_name"
              type="text"
              component={renderFieldLabel}
              placeholder={Translation().register.form.labels.last_name}
              className="app-basic-input"
            />
            <Field
              name="first_name"
              type="text"
              component={renderFieldLabel}
              placeholder={Translation().register.form.labels.first_name}
              className="app-basic-input"
            />
            <div className="birthday-field">
              <label>{Translation().register.form.labels.birth_of_date}</label>
              <BirthdateField formName="register"/>
            </div>
            <Field
              name="mobile"
              type="text"
              component={PhoneField}
              placeholder={Translation().register.form.labels.mobile}
              className="app-basic-input"
              id="form-register-mobile"
              style={{
                display: "block",
                position: "relative",
                maxWidth: "400px",
                margin: "auto",
                fontSize: "14px",
                marginBottom: "25px"
              }}
            />
            <Field
              name="phone"
              type="text"
              component={PhoneField}
              placeholder={Translation().register.form.labels.phone}
              className="app-basic-input"
              id="form-register-phone"
              style={{
                display: "block",
                position: "relative",
                maxWidth: "400px",
                margin: "auto",
                fontSize: "14px",
                marginBottom: "25px"
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <div className="form-cel">
            <Field
              name="email"
              type="email"
              component={renderFieldLabel}
              placeholder={Translation().register.form.labels.email}
              className="app-basic-input"
            />
            <Field
              name="password"
              type="password"
              component={renderFieldLabel}
              placeholder={Translation().register.form.labels.password}
              className="app-basic-input"
            />
            <Field
              name="confirmPwd"
              type="password"
              component={renderFieldLabel}
              placeholder={Translation().register.form.labels.confirm_new_password}
              className="app-basic-input"
            />
          </div>
          {
            /**
             * Check if user on register got an invite token the display none the company register fields
             */
            inviteToken
            ? ''
            : (
                <div className="form-cel">
                  <p className="is-pro">{Translation().register.is_company}</p>
                  <Field
                    name="legal_id"
                    type="text"
                    component={renderFieldLabel}
                    placeholder={Translation().register.form.labels.legal_id}
                    className="app-basic-input"
                  />
                  <Field
                    name="legal_name"
                    type="text"
                    component={renderFieldLabel}
                    placeholder={Translation().register.form.labels.legal_name}
                    className="app-basic-input"
                  />
                </div>
            )
          }
        </Col>
        <Clearfix/>
        <Col xs={12} sm={6} md={6}>
          <div className="bottom-cel">
            <a className="use-condition" onClick={openTermOfUse}>{Translation().register.terms_of_use}</a>
            { dirty && <div className="login-errors"><span id="error-message-regis" className="error-message" /></div> }
          </div>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <div className="bottom-cel align-center">
            <button type="submit" id="btn-register" className="app-button-color button-api" disabled={submitting}>
              <span id="regis-loader" className="loader-api loader-api-auth" />
              <span id="regis-btn-text" className="btn-login-text">{Translation().register.form.buttons.submit}</span>
            </button>
          </div>
        </Col>
        <Clearfix/>
      </form>
    );
  }
}




export default reduxForm({
  form: 'register',
  validate
})(RegisterForm);
