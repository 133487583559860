import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { subscribe } from 'redux-subscriber';
import bowser from 'bowser';
import Dialog from 'material-ui/Dialog';

import GoogleAddressObjectField from '../../commons/fields/GoogleAddressObjectField';
import NumberField from '../../commons/fields/NumberField';
import validate from '../shopRouter/validate';
import ShopHeader from  '../shopHeader/ShopHeader';
import { ShopCart } from '../cart/Cart';

import { autoScrollTop } from '../../../helpers/JqueryFunctions';
import { setPropertyLocation } from '../../../helpers/ShopFunctions';
import { apiGetCart, setUserCart, deleteUserCart } from '../../../helpers/CartFunctions';
import { getResidenceCountry, getBrandPhoneNumber } from '../../../helpers/UserFunctions';
import { googleMapsAddressMapper, getUnitSystemSymbol } from '../../../helpers/HelperFunctions';
import { apiGeocode } from '../../../helpers/ApiFunctions';

import store from '../../../redux/store';
import Translation from '../../../translation/Translation';
import Config from '../../../config/Config';

const constructFields = (address, size, propertyType) => {

  if (address){
    store.dispatch(change('shop', 'address', address));
    store.dispatch(change('shop', 'addressAutocomplete', address));
  }
  if (size) {
    store.dispatch(change('shop', 'size', size));
  }
  if (propertyType) {
    store.dispatch(change('shop', 'propertyType', propertyType));
  }
}

const resetFields = () => {

  store.dispatch(change('shop', 'address', ''));
  store.dispatch(change('shop', 'size', ''));
  store.dispatch(change('shop', 'addressAutocomplete', ''));
  store.dispatch(change('shop', 'propertyType', ''));

  const propertyTypeLabels = document.getElementsByClassName("property-type");

  if (propertyTypeLabels) {
    for (let i = 0; i < propertyTypeLabels.length; i++) {
      propertyTypeLabels[i].classList.remove("property-selected");
    }
  }
}

const closePropertyChangedModal = () => {

  const overlayPropertyChanged = document.getElementsByClassName('overlay-property-changed')[0];

  if (overlayPropertyChanged)
    overlayPropertyChanged.style.display = 'none';
}

class Property extends Component {

  constructor(props) {

    super(props);

    this.state = {
      address: undefined,
      size: undefined,
      propertyType: undefined,
      isCartNotEmpty: false,
      isGeocodeLoading: false,
      isNoGoogleplaceDialogOpen: false,
      isAddressFailed: false,
    };

    this.checkPropertyObject = this.checkPropertyObject.bind(this);
    this.forceServivesStep = this.forceServivesStep.bind(this);
    this.resetPropertyStep = this.resetPropertyStep.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleAddressValue = this.handleAddressValue.bind(this);
    this.openNoGoogleplaceResultDialog = this.openNoGoogleplaceResultDialog.bind(this);
    this.closeNoGoogleplaceResultDialog = this.closeNoGoogleplaceResultDialog.bind(this);

    this.subscribePropertyform = undefined;
  }

  componentDidMount() {
    autoScrollTop();

    // eslint-disable-next-line
    const subscribePropertyType = subscribe('form.shop.values', state => {
      if (state && state.form && state.form.shop && state.form.shop.values) {
        if (state.form.shop.values.propertyType) {
          /**
           * Get the current property type selected
           */
          const propertyTypeSelected = state.form.shop.values.propertyType.replace(/ /g,"-").toLocaleLowerCase() + "-type";
          const propertyTypeLabels = document.getElementsByClassName("property-type");

          if (propertyTypeLabels) {
            for (let i = 0; i < propertyTypeLabels.length; i++) {
              propertyTypeLabels[i].classList.remove("property-selected");
               /**
                * Check where is the current property type selected then apply style
                */
              if (propertyTypeLabels[i].classList.contains(propertyTypeSelected))
                propertyTypeLabels[i].classList.add("property-selected");
            }
          }
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {

    this.setState({
      address: nextProps.address,
      size: nextProps.size,
      propertyType: nextProps.propertyType,
      isCartNotEmpty: nextProps.cartStatus
    });

    if (nextProps.address && nextProps.size) {
      if (nextProps.stepService && nextProps.stepService === 2) {
        const transitionStepLoader = document.getElementById('transition-service-step');

        if (transitionStepLoader) {
          transitionStepLoader.style.display = "block";
        }

        /**
         * Force service step
         */
        this.props.handleSubmit();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.subscribePropertyform = subscribe('form.shop.values', state => {
      if (state && state.form && state.form.shop && state.form.shop.values) {
        this.checkPropertyObject(state.form.shop.values);
      }
    });

    if (this.state.address !== prevProps.address || this.state.size !== prevProps.size || this.state.propertyType !== prevProps.propertyType) {
      constructFields(this.state.address, this.state.size, this.state.propertyType);
    }
  }

  openNoGoogleplaceResultDialog() {
    const state = store.getState();
    const selector = formValueSelector('shop');
    const currentAdrsValue = selector(state, 'addressAutocomplete');
    const bufferValue = selector(state, 'address');

    if (!bufferValue && currentAdrsValue) {
      this.setState({ isGeocodeLoading: true });
      apiGeocode(
        { address: currentAdrsValue },
        data => {
          if (data.lat && data.lng) {
            const location = {
              lat: data.lat,
              lng: data.lng
            };
            setPropertyLocation(location);
          }
          if (data.city && data.street) {
            store.dispatch(change('shop', 'address', data.full));
            store.dispatch(change('shop', 'addressAutocomplete', data.full));
          }
          else {
            store.dispatch(change('shop', 'address', ''));
            store.dispatch(change('shop', 'addressAutocomplete', ''));
            this.setState({ isNoGoogleplaceDialogOpen: true });
          }

          this.setState({isGeocodeLoading: false});
        },
        () => {
          store.dispatch(change('shop', 'address', ''));
          store.dispatch(change('shop', 'addressAutocomplete', ''));
          this.setState({ isNoGoogleplaceDialogOpen: true });
          this.setState({ isGeocodeLoading: false });
        }
      );
    }
  }

  closeNoGoogleplaceResultDialog() {
    this.setState({ isNoGoogleplaceDialogOpen: false });
  }

  forceServivesStep() {

    /**
     * Reset fields values with the state values
     */
    if (this.state.address && this.state.size) {
      constructFields(this.state.address, this.state.size, this.state.propertyType);
    }
    const transitionStepLoader = document.getElementById('transition-service-step');

    if (transitionStepLoader) {
      closePropertyChangedModal();
      transitionStepLoader.style.display = "block";
    }

    /**
     * Force service step
     */
    this.props.handleSubmit();
  }

  checkPropertyObject(propertyObject) {

    if (!propertyObject) {
      return;
    }

    const addressFieldValue = propertyObject.address;
    const sizeFieldValue = propertyObject.size;

    const overlayPropertyChanged = document.getElementsByClassName('overlay-property-changed')[0];
    /**
     * If the user have any product in his cart
     */
    if (this.state.isCartNotEmpty) {
      /**
       * Check if address field new value is different of the cart property address
       */
      if (this.state.address !== undefined && this.state.size !== undefined) {
        if (addressFieldValue && addressFieldValue !== this.state.address) {
          if (overlayPropertyChanged) {
            overlayPropertyChanged.style.display = 'block';
          }
        }
        if (sizeFieldValue && sizeFieldValue !== this.state.size) {
          if (overlayPropertyChanged) {
            overlayPropertyChanged.style.display = 'block';
          }
        }
      }
    }
    else {
      return;
    }
  }

  resetPropertyStep() {

    const transitionStepLoader = document.getElementById('transition-service-step');

    if (transitionStepLoader) {
      closePropertyChangedModal();
      transitionStepLoader.style.display = "block";
    }

    /**
     * Delete the userCart
     * In success callback we get again the user cart then we reset the property values
     */
    deleteUserCart(
      (successData) => {
        apiGetCart(
          (getResponseData) => {
            this.setState({
              address: undefined,
              size: undefined,
              propertyType: undefined
            })
            resetFields();
            /**
             * Update the cart into redux store
             */
            setUserCart(getResponseData);
            closePropertyChangedModal();

            if (transitionStepLoader) {
              transitionStepLoader.style.display = "none";
            }
          },
          (getResponseError) => {
            closePropertyChangedModal();

            if (transitionStepLoader) {
              transitionStepLoader.style.display = "none";
            }
          }
        )
      },
      (errorData) => {}
    );
  }

  handleAddressValue(addressObject) {

    if (!addressObject) return;

    if (addressObject.geometry && addressObject.geometry.location) {
      const location = {
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng()
      };

      setPropertyLocation(location);
    }

    /**
     * Check if address property have the required elements for API
     */
    const addressMapped = googleMapsAddressMapper(addressObject);

    if (addressMapped) {
      if (!addressMapped.locality || (!addressMapped.route && getResidenceCountry().indexOf(Config.countries_blacklist) === -1)) {
        this.setState({ isAddressFailed: true });
        store.dispatch(change('shop', 'address', ''));
        store.dispatch(change('shop', 'addressAutocomplete', ''));
      }
      else {
        this.setState({ isAddressFailed: false });
        store.dispatch(change('shop', 'address', addressObject.formatted_address));
        store.dispatch(change('shop', 'addressAutocomplete', addressObject.formatted_address));
      }
    }
    else {
      this.setState({ isAddressFailed: true });
      store.dispatch(change('shop', 'address', ''));
      store.dispatch(change('shop', 'addressAutocomplete', ''));
    }
  }

  handleChangeAddress() {
    setPropertyLocation(null);
    store.dispatch(change('shop', 'address', ''));
  }

  render() {

    const {
      handleSubmit,
      handleSteps
    } = this.props;

    const {
      isGeocodeLoading,
      isCartNotEmpty,
      isNoGoogleplaceDialogOpen,
      isAddressFailed
    } = this.state;

    /**
     * Browser version
     */
    const browserName = bowser.name ? bowser.name : "";
    const browserVersion = bowser.version ? bowser.version : "";
    const isNotIe9 = browserName === "Internet Explorer" && browserVersion <= 9 ? false : true;

    /**
     * Get the redux state
     */
    const state = store.getState();
    const selector = formValueSelector('shop');

    /**
     * Get the input values in state for allow or not the next step
     */
    const isAddressDirty = selector(state, 'address');
    const isSizeDirty = selector(state,'size');

    return (
      <div className="shop-property">
        <ShopHeader stepIndex={1} handleSteps={handleSteps}/>
        <div className="overlay-property-changed">
          <div className="container">
            <div className="row">
              <div className="app-modal">
                <h4 className="app-modal-title">{Translation().shop.informations.property_warning.warning_title}</h4>
                <p className="app-modal-desc">{Translation().shop.informations.property_warning.warning_baseline}</p>
                <div className="app-modal-content">
                  <p>{Translation().shop.informations.property_warning.warning_content.change_inc}</p>
                  <p>{Translation().shop.informations.property_warning.warning_content.change_cart}</p>
                  <p className="continue">{Translation().shop.informations.property_warning.warning_continue}</p>
                  <div className="btn-wrapper">
                    <div className="app-button-color confirm" onClick={this.resetPropertyStep}>
                      <span className="confirm-icon icon-check left" /><span>{Translation().shop.informations.property_warning.yes}</span>
                    </div>
                    <div className="app-button-color confirm" onClick={this.forceServivesStep}>
                      <span>{Translation().shop.informations.property_warning.no}</span><span className="confirm-icon icon-fermer right" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="app-overlay overlay-tss" id="transition-service-step">
          <div className="loader-api" />
        </div>
        <div className="container">
          <div className="row">
            <div className="shop-content">
              <form onSubmit={handleSubmit}>
                <Col xs={12} sm={12} md={9}>
                  <div className="intro-step">
                    <h1>{Translation().shop.step_titles.informations}</h1>
                    <p>{Translation().shop.step_descriptions.informations}</p>
                  </div>
                  <div className="order-col">
                    <Field
                      type="text"
                      id="address-input"
                      label={Translation().shop.step_fields.names.address}
                      name="addressAutocomplete"
                      className={"app-basic-input " + (isAddressFailed ? "error" : "")}
                      onSelect={(address) => { this.handleAddressValue(address) }}
                      onBlur={() => this.setState({ isAddressFailed: false })}
                      onFocus={() => this.setState({ isAddressFailed: false })}
                      onChange={this.handleChangeAddress}
                      component={GoogleAddressObjectField}
                      isFailed={isAddressFailed}
                      required={true}
                      autoFocus={true}
                    />
                    <Field
                      name="address"
                      type="hidden"
                      id="address-input-hidden"
                      className="app-hidden-input"
                      component="input"
                    />
                    <div className="address-details-output">
                      { isGeocodeLoading
                        ? (
                          <div className="no-googleplace-result">
                            <div className="linear-bullets-loader">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                        )
                        : (
                          <div>
                            <p className="no-googleplace-result" onClick={this.openNoGoogleplaceResultDialog}>{Translation().shop.informations.property_not_found.link}</p>
                            { isAddressFailed && <p className="no-googleplace-result output">{Translation().shop.informations.property_not_found.incomplete}</p> }
                          </div>
                        )
                      }
                    </div>
                    <Field
                      name="size"
                      type="number"
                      id="size-input"
                      label={Translation().shop.step_fields.names.size}
                      integer={25}
                      fraction={25}
                      required={true}
                      positive={true}
                      negative={false}
                      separator={"."}
                      className="app-basic-input"
                      isUnitSystem={true}
                      unitSystem={getUnitSystemSymbol()}
                      component={NumberField}
                    />
                    <div className="select-property-type">
                      <label className={("property-type " + Translation().shop.step_fields.names.apartment.replace(/ /g,"-").toLocaleLowerCase() + "-type")}>
                        <Field
                          name="propertyType"
                          component="input"
                          className="custom-radio"
                          type="radio"
                          value={Translation().shop.step_fields.names.apartment}
                        />
                        <span>{Translation().shop.step_fields.names.apartment}</span>
                      </label>
                      <label className={("property-type " + Translation().shop.step_fields.names.house.replace(/ /g,"-").toLocaleLowerCase() + "-type")}>
                        <Field
                          name="propertyType"
                          component="input"
                          className="custom-radio"
                          type="radio"
                          value={Translation().shop.step_fields.names.house}
                        />
                        <span>{Translation().shop.step_fields.names.house}</span>
                      </label>
                      <label className={("property-type " + Translation().shop.step_fields.names.office.replace(/ /g,"-").toLocaleLowerCase() + "-type")}>
                        <Field
                          name="propertyType"
                          component="input"
                          className="custom-radio"
                          type="radio"
                          value={Translation().shop.step_fields.names.office}
                        />
                        <span>{Translation().shop.step_fields.names.office}</span>
                      </label>
                      <label className="property-type other-type">
                        <Field
                          name="propertyType"
                          component="input"
                          className="custom-radio"
                          type="radio"
                          value="other"
                        />
                        <span>{Translation().shop.step_fields.names.other}</span>
                      </label>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} className="cart-col-desktop">
                  <div className="cart-col">
                    <div className="btn-next-top">
                      <button
                        type="submit"
                        className={"app-button-color app-button-dark btn-next " + (isNotIe9 ? "btn-next-animated " : " ") + ( isAddressDirty && isSizeDirty ? "step-available" : "")}>
                        <span id="btn-text-step1-1">{Translation().shop.step_buttons.informations}</span>
                        <div className="loader-api" id="loader-step1-1"/>
                        <span className="icon-next icon-chevron_droite" id="chevron-step1-1"></span>
                      </button>
                    </div>
                    <Clearfix/>
                    <ShopCart mobile={false}/>
                    { isCartNotEmpty &&
                      <div className="btn-next-bottom">
                        <button
                          type="submit"
                          className={"app-button-color app-button-dark btn-next " + ( isAddressDirty && isSizeDirty ? "step-available" : "")}>
                          <div className="loader-api" id="loader-step1-2"/>
                          <span className="icon-next icon-chevron_droite" id="chevron-step1-2"></span>
                        </button>
                      </div>
                    }
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} className="cart-col-mobile">
                  <div className="cart-col">
                    <ShopCart mobile={true}/>
                    <div className="btn-next-top">
                      <button
                        type="submit"
                        className={"app-button-color app-button-dark btn-next " + (isNotIe9 ? "btn-next-animated " : " ") + ( isAddressDirty && isSizeDirty ? "step-available" : "") }>
                        <span id="btn-text-step1-3">{Translation().shop.step_buttons.informations}</span>
                        <div className="loader-api" id="loader-step1-3"/>
                        <span className="icon-next icon-chevron_droite" id="chevron-step1-3"></span>
                      </button>
                    </div>
                  </div>
                </Col>
                <Clearfix/>
              </form>
            </div>
          </div>
        </div>
        <Dialog
          modal={false}
          actions={[<div onClick={this.closeNoGoogleplaceResultDialog} className="close-tos-modal">{Translation().shop.informations.property_not_found.close}</div>,]}
          className="api-modal"
          autoScrollBodyContent={true}
          title={Translation().shop.informations.property_not_found.title}
          onRequestClose={this.closeNoGoogleplaceResultDialog}
          open={isNoGoogleplaceDialogOpen}>
          <p>{Translation().shop.informations.property_not_found.desc}</p>
          <a href={"tel:" + getBrandPhoneNumber()}>
            <span className="icon-telephone" />
            <span className="phone-number">{getBrandPhoneNumber()}</span>
          </a>
        </Dialog>
      </div>
    );
  }

  componentWillUnmount() {
    this.subscribePropertyform && this.subscribePropertyform();
    this.subscribePropertyform = undefined;
  }
}

export default reduxForm({
  form: 'shop',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Property);
