import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { change } from 'redux-form';
import { subscribe } from 'redux-subscriber';
import bowser from 'bowser';

import Translation from '../../../../translation/Translation';
import store from '../../../../redux/store';
import { getDivWidth, setNewPosition, initSampleslider, serviceScroll, autoScrollTop } from '../../../../helpers/JqueryFunctions';
import { addThreeDots, formatPrice } from '../../../../helpers/HelperFunctions';
import { getBrandPhoneNumber } from '../../../../helpers/UserFunctions';
import {
  getServiceFamilies, unsetServiceFamilies,
  apiGetServices, setServices, unsetServices, getServices, getServiceSelected, unsetServiceSelected,
  getProperty, getPropertyGeo, setProperty, setPropertyTimezone,
  apiGetOptions, getOptions, setOptions, unsetOptions, unsetOptionsValues, getOptionsSelected,
  apiGetPlanning, getPlanning, setPlanning,  getRequirePlanning, unsetRequirePlanning, getSlotSelected, unsetSlotSelected,
  setServiceFamilyStatus,
  calcCurrentServicePrice,
  calcCurrentServiceDuration,
  triggerMissingFieldModal,
} from '../../../../helpers/ShopFunctions';
import {
  unsetCartRequestSlot,
  getCartRequest,
  getUserCart,
  apiPostCart,
  apiGetCart,
  setUserCart,
  setCartRequestProperty
 } from '../../../../helpers/CartFunctions';
import { apiGeocodeTimezone } from '../../../../helpers/ApiFunctions';
import { storeCartRequestOptions } from '../../../../redux/actions/cart/cartActions';
import { convertMinsToHrsMins } from '../../../../helpers/DateFunctions';
import { ServiceAvailable, ServiceNotAvailable } from '../Services';
import { OptionAvailable , OptionNotAvailable } from '../options/Options';
import { Planning } from '../planning/Planning';
import ValidatingFieldsForm from './validatingFields/ValidatingFieldsForm';

class Family extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      nbStep: 0,
      isPlanningRequired: false,
      nbDays: 7,
      isServicesOpen: false,
      isDescriptionOpen: false,
      isSamplesOpen: false,
      isSlotsLoading: false,
      newPos: 0,
      divWidth: '100%',
      _isMounted: true,
      currentServicePrice: 0,
      currentServiceDuration: null,
      validatingFields: null,
      validatingFieldsLoading: false,
      isSendToCardLoading: false
    };

    this.toggleDescription = this.toggleDescription.bind(this);
    this.toggleServices = this.toggleServices.bind(this);
    this.checkServiceOpened = this.checkServiceOpened.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.showServiceOptions = this.showServiceOptions.bind(this);
    this.showPlanning = this.showPlanning.bind(this);
    this.backToService = this.backToService.bind(this);
    this.backToOptions = this.backToOptions.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.sendValidatingFields = this.sendValidatingFields.bind(this);
    this.closeValidatingFieldsModal = this.closeValidatingFieldsModal.bind(this);
  }

  componentDidMount() {
    this.setState({ _isMounted: true });

    /**
     * Set the number of days to render in planning
     */
    let mqBasicPhone = false;
    let mqSmallPhone = false;

    if (bowser.name === 'Internet Explorer' && bowser.version < 10) {
      mqBasicPhone = false;
      mqSmallPhone = false;
    }
    else {
      mqBasicPhone = window.matchMedia("(max-width: 767px)");
      mqSmallPhone = window.matchMedia("(max-width: 485px)");
    }

    if (mqBasicPhone.matches) {
      this.setState({ nbDays: 4 });
    }
    if (mqSmallPhone.matches) {
      this.setState({ nbDays: 2 });
    }

    /**
     * Width and position of a family service
     */
    this.setState({
      newPos: setNewPosition('#services-available', this.props.index + 1),
      divWidth: getDivWidth('#services-available')
    })

    window.addEventListener('resize', () => {
      this.setState({
        newPos: setNewPosition('#services-available', this.props.index + 1),
        divWidth: getDivWidth('#services-available')
      });
    }, true);

    /**
     * Get the current price of the current service selected
     */
    // eslint-disable-next-line
    const subscribeServicePrices = subscribe('form.shop.values.serviceSelected', state => {
      this.setState({
        currentServicePrice: formatPrice(calcCurrentServicePrice()),
        currentServiceDuration: convertMinsToHrsMins(calcCurrentServiceDuration() ,"H")
      });
    });

    /**
     * Get the current price of options
     */
    // eslint-disable-next-line
    const subscribeOptionsPrices = subscribe('form.shop.values.options', state => {
      this.setState({
        currentServicePrice: formatPrice(calcCurrentServicePrice()),
        currentServiceDuration: convertMinsToHrsMins(calcCurrentServiceDuration(),"H")
       });
    });
  }

  showServices(shortCode) {

    const { object } = this.props;

    if (this.state.isServicesOpen) {
      return;
    }

    const serviceWrapper = document.getElementsByClassName('shortcode-' + shortCode)[0];
    const iconPlus = serviceWrapper.getElementsByClassName('load-family')[0];
    const loaderService = serviceWrapper.getElementsByClassName('loading-family')[0];

    if (loaderService && iconPlus) {
      iconPlus.style.display = 'none';
      loaderService.style.display = 'block';
    }

    this.resetAll();

    /**
     * Copy the property object in redux to a new one that we pass as query on the CallApi()
     */
    const property = getProperty();
    const propertyGeo = getPropertyGeo();
    const userCart = getUserCart();
    const serviceFamilyId = object.id;

    const loadServices = (serviceFamilyId, property) => {
      apiGetServices(
        serviceFamilyId,
        property,
        (responseData) => {
          if (responseData) {

            this.resetAll();

            serviceScroll(object.id);

            const overlayFields = document.getElementsByClassName('overlay-missing-field')[0];

            if (overlayFields) {
              overlayFields.style.display = "none";
            }

            const overlayService = document.getElementsByClassName('overlay-service-step')[0];
            if (overlayService) {
              overlayService.style.display = 'block';
            }

            /**
             * Set the services from redux store
             */
            setServices(responseData);

            if (loaderService && iconPlus) {
              iconPlus.style.display = 'block';
              loaderService.style.display = 'none';
            }
            this.setState({
              isSamplesOpen: false,
              isDescriptionOpen: false,
              step: 1,
              nbStep: 3,
              isPlanningRequired: getRequirePlanning() ? true : false,
            });

            setServiceFamilyStatus(object.id, 'open');
          }
        },
        (error) => {
          this.resetAll();
          if (loaderService && iconPlus && !this.state.isServicesOpen) {
            iconPlus.style.display = 'block';
            loaderService.style.display = 'none';
          }
          if (error.response && error.response.data) {
            const errorData = error.response.data;
            const mfBtnLoader = document.getElementById('mf-loader');
            const mfBtnText =  document.getElementById('send-mf-text');

            if (errorData.status_code && errorData.status_code === 422) {
              triggerMissingFieldModal(errorData, () => {
                loadServices(serviceFamilyId, getProperty());
              });
            }
            if (mfBtnLoader && mfBtnText) {
              mfBtnLoader.style.display = 'none';
              mfBtnText.style.display = 'block';
            }
          }
        }
      );
    }
    
    if (propertyGeo && propertyGeo.timezone_code) {
      loadServices(serviceFamilyId, property);
    }
    else {
      const propertyLocation =
        propertyGeo && propertyGeo.location && propertyGeo.location.lat && propertyGeo.location.lng
          ? propertyGeo.location
          : userCart && userCart.property_object && userCart.property_object.latitude && userCart.property_object.longitude
            ? { lat: userCart.property_object.latitude, lng: userCart.property_object.longitude}
            : undefined;

      if (propertyLocation) {
        apiGeocodeTimezone(
          propertyLocation,
          success => {
            if (success && success.code) {
              setPropertyTimezone(success.code);
            }
            
            loadServices(serviceFamilyId, property);
          },
          () => {
            loadServices(serviceFamilyId, property);
          }
        );
      }
      else {
        loadServices(serviceFamilyId, property);
      }
    }
  }

  checkServiceOpened() {

    if (this.state._isMounted) {
      const serviceFamilies =  getServiceFamilies();
      /**
       * Check which service family is opened
       */
      store.subscribe(() => {
        const appState = store.getState();

        if (serviceFamilies && appState.serviceFamilies && appState.serviceFamilies.length) {
          for (let i = 0; i < serviceFamilies.length; i++) {
            if (serviceFamilies[i].id === this.props.object.id) {
              this.setState({ isServicesOpen: (serviceFamilies[i].DOMstatus === 'open' ? true : false) });
            }
          }
        }
      });
    }
  }

  hideServices() {
    /**
     * Reset all services into redux store
     */
    unsetServices();
    /**
     * Reset the service selected
     */
    unsetServiceSelected();
    /**
     * Reset all options from redux
     * Reset all options values into shop form
     */
    unsetOptions();
    unsetOptionsValues();
    /**
     * Reset the bool is planning required
     */
    unsetRequirePlanning();
    /**
     * Close the current family opened
     */
    setServiceFamilyStatus(this.props.object.id, 'close');
    /**
     * Reset slot planning selected
     * Reset slot planning selected from cart
     */
    unsetSlotSelected();
    unsetCartRequestSlot();

    const overlay = document.getElementsByClassName('overlay-service-step')[0];
    if (overlay) {
      overlay.style.display = 'none';
    }
  }

  resetAll() {
    if (this.state._isMounted) {
      this.checkServiceOpened();
      const serviceFamilies =  getServiceFamilies();

      unsetServices();
      unsetServiceSelected();
      unsetRequirePlanning();
      unsetOptions();
      unsetOptionsValues();
      unsetSlotSelected();
      unsetCartRequestSlot();

      this.setState({
        validatingFields: null,
        validatingFieldsLoading: false
      });

      if (serviceFamilies && serviceFamilies.length) {
        for (let i = 0; i < serviceFamilies.length; i++) {
          setServiceFamilyStatus(serviceFamilies[i].id, 'close');
        }

        const overlay = document.getElementsByClassName('overlay-service-step')[0];
        if (overlay) {
          overlay.style.display = 'none';
        }
      }
    }
  }

  toggleServices(event) {
    event.preventDefault();

    if (this.state.isServicesOpen)
      this.hideServices();
    else
      this.showServices(this.props.object && this.props.object.shortcode ? this.props.object.shortcode : null);
  }

  toggleDescription() {
    this.setState({ isDescriptionOpen: !this.state.isDescriptionOpen });
  }

  toggleSamples(idSample) {
    serviceScroll(this.props.object.id);
    this.setState({ isSamplesOpen: !this.state.isSamplesOpen });
    /**
     * Init slick slider
     */
    initSampleslider(idSample);
  }

  showServiceOptions(event) {
    event.preventDefault();
    serviceScroll(this.props.object.id);
    const serviceSelected = getServiceSelected();
    const property = getProperty();
    const serviceError = document.getElementsByClassName('service-errors')[0];

    if (!serviceSelected) {
      const errorMessage = Translation().shop.services.select_service;

      if (serviceError) {
        serviceError.style.display = 'block';
        serviceError.innerHTML  = '<div class="error-message">' + errorMessage +' </div>';
      }
    }
    else {
      const loaderGto = document.getElementById('loader-gto');
      const textGto = document.getElementById('btn-text-gto');
      const chevronGto = document.getElementById('chevron-gto');

      if (loaderGto && textGto && chevronGto) {
        loaderGto.style.display = 'inline-block';
        textGto.style.display = 'none';
        chevronGto.style.display = 'none'
      }

      const loadOptions = (serviceSelected, property) => {
        apiGetOptions(
          serviceSelected,
          property,
          (responseData) => {
            if (responseData) {
              if (loaderGto && textGto && chevronGto) {
                loaderGto.style.display = 'none';
                textGto.style.display = 'inline-block';
                chevronGto.style.display = 'inline-block';
              }

              const overlayFields = document.getElementsByClassName('overlay-missing-field')[0];

              if (overlayFields) {
                overlayFields.style.display = "none";
              }

              /**
               * Set options to redux store
               */
              setOptions(responseData);

              /**
               * Check if API return pre selected option key
               * If yes, set pre selected options in redux
               */
              if (responseData.length > 0) {
                const preCheckedOptions = [];
                for (let i = 0; i < responseData.length; i++) {
                  if (responseData[i].advised && responseData[i].id) {
                    store.dispatch(change('shop', 'options.option-' + responseData[i].id, true));
                    preCheckedOptions.push(responseData[i].id);
                  }
                }
                store.dispatch(storeCartRequestOptions(preCheckedOptions));
              }

              this.setState({
                step: 2,
                nbStep: getRequirePlanning() ? 3 : 2,
                isPlanningRequired: getRequirePlanning() ? true : false,
                isSamplesOpen: false
              });
              if (serviceError) {
                serviceError.style.display = 'none';
              }
            }
          },
          (error) => {
            if (loaderGto && textGto && chevronGto) {
              loaderGto.style.display = 'none';
              textGto.style.display = 'inline-block';
              chevronGto.style.display = 'inline-block';
            }
            if (error.response && error.response.data) {
              const errorData = error.response.data;
              const mfBtnLoader = document.getElementById('mf-loader');
              const mfBtnText =  document.getElementById('send-mf-text');

              if (errorData.status_code && errorData.status_code === 422) {
                triggerMissingFieldModal(errorData, () => {
                  loadOptions(serviceSelected, getProperty());

                  if (mfBtnLoader && mfBtnText) {
                    mfBtnLoader.style.display = 'none';
                    mfBtnText.style.display = 'block';
                  }
                });
              }
            }
          }
        );
      }
      loadOptions(serviceSelected, property);
    }
  }

  showPlanning(event) {
    event.preventDefault();

    if (this.state.isSlotsLoading) return;

    serviceScroll(this.props.object.id);

    this.setState({ isSlotsLoading: true });

    const serviceSelected = getServiceSelected();
    const property = getProperty();
    const optionsSelected = getOptionsSelected();

    const loaderGtc = document.getElementById('loader-gtc');
    const textGtc = document.getElementById('btn-text-gtc');
    const chevronGtc = document.getElementById('chevron-gtc');

    if (loaderGtc && textGtc && chevronGtc) {
      loaderGtc.style.display = 'inline-block';
      textGtc.style.display = 'none';
      chevronGtc.style.display = 'none'
    }

    const options = [];

    for (let optionSelected in optionsSelected) {
      const option = optionSelected.replace('option-', '');
      options.push(option);
    }

    apiGetPlanning(
      null,
      null,
      serviceSelected,
      options,
      property,
      (responseData) => {
        if (responseData) {
          if (loaderGtc && textGtc && chevronGtc) {
            loaderGtc.style.display = 'none';
            textGtc.style.display = 'inline-block';
            chevronGtc.style.display = 'inline-block';
          }
          /**
           * Store Planning to redux store
           */
          setPlanning(responseData);
          this.setState({
            step: 3,
            isSlotsLoading: false
          });
        }
      },
      () => {
        if (loaderGtc && textGtc && chevronGtc) {
          loaderGtc.style.display = 'none';
          textGtc.style.display = 'inline-block';
          chevronGtc.style.display = 'inline-block';
        }

        this.setState({ isSlotsLoading: false });
      }
    )
  }

  sendValidatingFields() {
    const state = store.getState();
    const oldProperty = state.property;
    const property = {
      ...oldProperty
    }

    const values = state.form.shop && state.form.shop.values && state.form.shop.values.validating_fields ? state.form.shop.values.validating_fields : null;

    if (!values)
      return;

    for (let value in values) {
      property[value] = values[value];
    }

    setProperty(property);
    setCartRequestProperty(property);
    this.addToCart();
  }

  closeValidatingFieldsModal() {
    this.setState({
      validatingFieldsLoading: false,
      isSendToCardLoading: false,
      validatingFields: null
    });
  }

  addToCart(event) {
    event && event.preventDefault();

    /**
     *  Change checking cart ready method
     */
    const dateSelected = getSlotSelected();
    /**
     * Get the bool is planning required
     */
    const isPlanningRequired = getRequirePlanning();

    const serviceError = document.getElementsByClassName('service-errors')[0];

    const errorMessagePlanning = Translation().shop.services.planning.select_slot;
    const errorMessageCart = Translation().cart.errors.adding_error;

    if (!dateSelected && isPlanningRequired) {
      if (serviceError) {
        serviceError.style.display = 'block';
        serviceError.innerHTML  = '<div class="error-message">' + errorMessagePlanning +' </div>';
        return;
      }
    }
    else {
      if (serviceError) {
        serviceError.style.display = 'none';
      }
    }

    /**
     * Get the cart request from redux store
     */
    const cart = getCartRequest();

    if (cart) {

      if (this.state.validatingFields) {
        this.setState({ validatingFieldsLoading: true });
      }

      this.setState({ isSendToCardLoading: true });

      apiPostCart(
        cart,
        (success) => {
          this.setState({
            validatingFields: null,
            validatingFieldsLoading: false
          });

          /**
           * Send cart to google tag manager
           */
          if (window && window.dataLayer) {
            window.dataLayer.push({
              event: "cart_product.created",
              product_id: success.product_id,
              product_shortcode: success.shortcode,
              price_tax_excluded: success.price_tax_excluded,
              price: success.price,
              currency_isocode: success.currency && success.currency.isocode ? success.currency.isocode : null,
            });
          }

          apiGetCart(
            (responseData) => {
              setUserCart(responseData);
              this.hideServices();
              autoScrollTop();

              this.setState({ isSendToCardLoading: false });
            },
            () =>  {
              if (serviceError) {
                serviceError.style.display = 'block';
                serviceError.innerHTML  = '<div class="error-message">' + errorMessageCart +' </div>';
              }

              this.setState({ isSendToCardLoading: false });
            }
          );
        },
        (error) => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors) {
              /**
               * Set validating fields
               */
              this.setState({
                validatingFields: error.response.data.errors,
                validatingFieldsLoading: false,
              });
            }
            else {
              if (serviceError) {
                serviceError.style.display = 'block';
                serviceError.innerHTML  = '<div class="error-message">' + errorMessageCart +' </div>';
              }

              this.setState({ isSendToCardLoading: false });
            }
          }
          else {
            this.setState({
              isSendToCardLoading: false,
              validatingFieldsLoading: false,
            });

            if (serviceError) {
              serviceError.style.display = 'block';
              serviceError.innerHTML  = '<div class="error-message">' + errorMessageCart +' </div>';
            }
          }
        }
      );
    }
  }

  backToService() {
    this.setState({ step: 1 });
    serviceScroll(this.props.object.id);
  }

  backToOptions() {
    this.setState({ step: 2 });
    serviceScroll(this.props.object.id);
    unsetSlotSelected();
    unsetCartRequestSlot();
  }

  handleClosePropertyTimezoneError() {
    window.location.reload();
  }

  render() {

    const {
      object,
      index,
    } = this.props;

    const propertyGeo = getPropertyGeo();
    const timezoneCode = propertyGeo && propertyGeo.timezone_code ? propertyGeo.timezone_code : undefined; 

    const serviceFamily = object;
    const familyIndex = index + 1;

    const {
      step,
      nbStep,
      nbDays,
      newPos,
      divWidth,
      isSamplesOpen,
      isServicesOpen,
      validatingFields,
      isDescriptionOpen,
      isPlanningRequired,
      isSendToCardLoading,
      currentServicePrice,
      currentServiceDuration,
      validatingFieldsLoading
    } = this.state;

    const isServiceSelected = getServiceSelected();
    const serviceError = document.getElementsByClassName('service-errors')[0];

    if (isServiceSelected && serviceError) {
      serviceError.style.display = 'none';
    }

    /**
     * Redux store :
     * Get the services fropm the redux store
     * Get the options from the redux store
     * Get the planning from redux store
     */
    const services = getServices();
    const options = getOptions();
    const planning = getPlanning();

    const phoneNumber = getBrandPhoneNumber();
  
    return (
      <div>
        <div>
          { validatingFields &&
            <div className="app-overlay" style={{display: "block", left: "0px"}}>
              <div className="container">
                <div className="row">
                  <div className="app-modal">
                    <span className="icon-fermer close-modal" onClick={this.closeValidatingFieldsModal}/>
                    <h4 className="app-modal-title">{Translation().shop.missing_fields.title}</h4>
                    <p className="app-modal-desc">{Translation().shop.missing_fields.desc}</p>
                    <ValidatingFieldsForm
                      formName="shop"
                      fields={validatingFields}
                      handleSubmit={this.sendValidatingFields}
                      loading={validatingFieldsLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <Col xs={12} sm={12} md={4} lg={4}>
          <div className={"service-family-box " + (serviceFamily.orderable ? "service-available " : "service-unavailable ") + (serviceFamily.orderable && isServicesOpen ? "selected" : "") }>
            <div className={"family-box family-" + (serviceFamily.orderable && serviceFamily.id) + " shortcode-" + (serviceFamily.shortcode && serviceFamily.shortcode)}>
              { serviceFamily.orderable && isDescriptionOpen
                ? (
                  <div className="family-description">
                    <p>{ serviceFamily.orderable && addThreeDots(serviceFamily.description, 250) }</p>
                  </div>
                )
                : (
                  <div className="family-cover">
                    { isServicesOpen
                      ? (
                        <img
                          src={ serviceFamily && serviceFamily.icon_url_light }
                          alt={ serviceFamily ? serviceFamily.name : "product image"}
                          className={"family-img " + (!serviceFamily.orderable ? "img-unavailable" : "") }
                          onClick={serviceFamily.orderable && this.toggleServices}
                        />
                      )
                      : (
                        <img
                          src={ serviceFamily && serviceFamily.icon_url_dark }
                          alt={ serviceFamily ? serviceFamily.name : "product image"}
                          className={"family-img " + (!serviceFamily.orderable ? "img-unavailable" : "") }
                          onClick={serviceFamily.orderable && this.toggleServices}
                        />
                      )
                    }
                    <p className="family-title">{ serviceFamily && serviceFamily.name }</p>
                  </div>
                )
              }
              { serviceFamily.orderable
                ? ( <div className="family-actions">
                  <div className="show-service" onClick={this.toggleServices}>
                    <span className="icon-plus load-family"/>
                    <div className="loader-api loading-family"/>
                  </div>
                  { !isServicesOpen &&
                    <div className={"more-info-service " + (serviceFamily.orderable && isDescriptionOpen && "open")} onClick={this.toggleDescription}>
                      <span className="icon-infos"/>
                    </div>
                  }
                </div>
                )
                : ( <div className="unavailable">{Translation().shop.services.unavailable}</div> )
              }
              <div className="hide-border-top" />
            </div>
            { step === 3 && isPlanningRequired && !timezoneCode &&
              <div className="app-overlay" style={{left: 0}}>
                <div className="container">
                  <div className="row">
                    <div className="app-modal">
                    <div className="icon-fermer close-modal" onClick={this.handleClosePropertyTimezoneError} />
                    <h4 className="app-modal-title">{Translation().shop.informations.timezone_not_found.title}</h4>
                    <p className="app-modal-desc">
                      {Translation().shop.informations.timezone_not_found.desc}
                    </p>
                    <div className="property-timezone-error">
                      <div className="error-send">
                        { phoneNumber &&
                          <a href={"tel:" + phoneNumber}>
                            Contact: {phoneNumber}
                          </a>
                        }
                        <p className="error-message">error code: property_timezone_not_found</p>
                      </div>
                    </div>
                    <div className="app-button-color confirm" onClick={this.handleClosePropertyTimezoneError}>
                      {Translation().shop.informations.timezone_not_found.close}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            { serviceFamily.orderable && isServicesOpen ? (
              <div className="service-family-content" style={{width: divWidth, marginLeft: newPos}}>
                { step === 1  && !isSamplesOpen &&
                  <div className="service-container">
                    { services && services.length > 0 &&
                      <h3 className="service-step"><span>{step}/{nbStep}</span>{Translation().shop.step_header.sub_steps.select_service}</h3>
                    }
                    { services && services.length > 0
                      ? ( services.map((service, index) => {
                        return (
                          <ServiceAvailable
                            key={service.id}
                            object={service}
                          />
                        )
                      })
                      )
                      : <ServiceNotAvailable/>
                    }
                  </div>
                }
                { step === 1  && isSamplesOpen && serviceFamily.orderable &&
                  <div className="service-samples-slider" id={"sample-slider-" + serviceFamily.id}>
                    { serviceFamily.samples && serviceFamily.samples.map((samples, index) => {
                      return (
                        <div key={index}>
                          <img src={samples.url} alt={samples.title} />
                        </div>
                      )
                    })}
                  </div>
                }
                { step === 2 &&
                  <div className="service-container">
                    { options && options.length > 0 &&
                      <h3 className="service-step"><span>{step}/{nbStep}</span>{Translation().shop.step_header.sub_steps.options_choice}</h3>
                    }
                    { options && options.length > 0
                      ? ( options.map((option, index) => {
                        return (
                          <OptionAvailable key={index} object={option} index={index}/>
                        )
                      })
                      )
                      : <OptionNotAvailable/>
                    }
                  </div>
                }
                { step === 3 && isPlanningRequired && timezoneCode &&
                  <div className="service-container">
                    <h3 className="service-step"><span>{step}/{nbStep}</span>{Translation().shop.step_header.sub_steps.planning}</h3>
                    <Planning
                      timezoneCode={timezoneCode}
                      object={planning}
                      nbDays={nbDays}
                    />
                  </div>
                }
                <Clearfix/>
                { services && services.length > 0
                  ? (
                    <div className="service-footer">
                      <div className="service-errors" />
                      <div className="service-precart">
                        <Col xs={12} md={6}>
                          <div className="service-duration">
                            {Translation().shop.estimated_duration} <span> { currentServiceDuration }</span>
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="service-total">
                            Total <span className="type-of-tax">{Translation().shop.tax_excluded}</span><span> { currentServicePrice }</span>
                          </div>
                        </Col>
                        <Clearfix/>
                      </div>
                      <div className="service-nav">
                        { step === 1 && serviceFamily.samples && serviceFamily.samples.length > 0
                          && ( <div className="nav-left" onClick={this.toggleSamples.bind(this, serviceFamily.orderable && serviceFamily.id )}>
                            { isSamplesOpen
                              ? (
                                <div>
                                  <span className="nav-icon icon-fermer" />
                                  <span className="close-sample">{Translation().shop.services.close}</span>
                                </div>
                              )
                              : (
                                <div>
                                  <span className="nav-icon icon-detail" />
                                  <span className="open-samples btn-text">{Translation().shop.services.examples}</span>
                                </div>
                              )
                            }
                          </div>
                          )
                        }
                        { step === 2 &&
                          <div className="nav-left" onClick={this.backToService}>
                            <span className="icon-chevron_gauche nav-icon btn-prev" />
                            <span className="btn-text">{Translation().shop.step_buttons.select_service}</span>
                          </div>
                        }
                        { step === 3 &&
                          <div className="nav-left" onClick={this.backToOptions}>
                            <span className="icon-chevron_gauche nav-icon btn-prev" />
                            <span className="btn-text">{Translation().shop.step_buttons.options_choice}</span>
                          </div>
                        }
                        { step === 1 &&  !isSamplesOpen &&
                          <div className="nav-right" onClick={this.showServiceOptions}>
                            <span id="btn-text-gto" className={"btn-text " + (isServiceSelected ? "active" :"")}>{Translation().shop.step_buttons.options_choice}</span>
                            <span className={"nav-icon btn-next " + (isServiceSelected ? "active" :"")}>
                              <span id="chevron-gto" className="icon-chevron_droite"/>
                              <span id="loader-gto" className="loader-api" />
                            </span>
                          </div>
                        }
                        { step === 2 && isPlanningRequired &&
                          <div className="nav-right" onClick={this.showPlanning}>
                            <span id="btn-text-gtc" className="btn-text active">{Translation().shop.step_buttons.planning}</span>
                            <span className="nav-icon btn-next active">
                              <span id="chevron-gtc" className="icon-chevron_droite"/>
                              <span id="loader-gtc" className="loader-api" />
                            </span>
                          </div>
                        }
                        { step === 3 && isPlanningRequired &&
                          <button
                            className="nav-right add-to-cart app-button-color"
                            onClick={this.addToCart}
                            disabled={isSendToCardLoading}
                          >
                            { isSendToCardLoading
                              ? <span className="loader-api" style={{display: "inline-block"}} />
                              : <span id="txt-cart_1">{Translation().shop.step_buttons.add_to_cart}</span>
                            }
                          </button>
                        }
                        { step === 2 && !isPlanningRequired &&
                          <button
                            className="nav-right add-to-cart app-button-color"
                            onClick={this.addToCart}
                            disabled={isSendToCardLoading}
                          >
                            { isSendToCardLoading
                              ? <span className="loader-api" style={{display: "inline-block"}} />
                              : <span id="txt-cart_1">{Translation().shop.step_buttons.add_to_cart}</span>
                            }
                          </button>
                        }
                        <Clearfix/>
                      </div>
                    </div>
                  )
                  : ''
                }
              </div>
            ) : '' }
          </div>
        </Col>
        { familyIndex % 3 === 0 ? <Clearfix/> : null /* <-- Add a clearfix every 3 Cols */ }
      </div>
    );
  }

  componentWillUnmount() {
    unsetServiceFamilies();
    this.resetAll();
    this.setState({ _isMounted: false });
  }
}

export default Family;
