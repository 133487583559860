import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import validate from './validate';
import renderFieldLabel from '../../../../../components/commons/fields/renderFieldLabel';
import GoogleAddressStringField from '../../../../../components/commons/fields/GoogleAddressStringField';
import PhoneField from '../../../../../components/commons/fields/PhoneField';
import { initPlaceholder } from '../../../../../helpers/JqueryFunctions';
import {
  getCurrency,
  getLocale,
  getTimezone,
  getResidenceCountry,
  getCitizenCountry,
  getUnitSystem,
} from '../../../../../helpers/UserFunctions';

import Translation from '../../../../../translation/Translation';

class RegisterForm extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.geoSettings && nextProps.geoSettings.locale)
      this.props.change('language_locale', nextProps.geoSettings.locale);
    else this.props.change('language_locale', getLocale());

    if (nextProps.geoSettings && nextProps.geoSettings.timezone_code)
      this.props.change('timezone_code', nextProps.geoSettings.timezone_code);
    else this.props.change('timezone_code', getTimezone());

    if (nextProps.geoSettings && nextProps.geoSettings.currency_isocode)
      this.props.change(
        'currency_isocode',
        nextProps.geoSettings.currency_isocode
      );
    else this.props.change('currency_isocode', getCurrency());

    if (nextProps.geoSettings && nextProps.geoSettings.residence_country)
      this.props.change(
        'residence_country_isocode',
        nextProps.geoSettings.residence_country
      );
    else this.props.change('residence_country_isocode', getResidenceCountry());

    if (nextProps.geoSettings && nextProps.geoSettings.citizen_country)
      this.props.change(
        'citizen_country_isocode',
        nextProps.geoSettings.citizen_country
      );
    else this.props.change('citizen_country_isocode', getCitizenCountry());

    if (nextProps.geoSettings && nextProps.geoSettings.unit_system)
      this.props.change('unit_system', nextProps.geoSettings.unit_system);
    else this.props.change('unit_system', getUnitSystem());
  }

  componentDidMount() {
    initPlaceholder();
  }

  render() {
    const { handleSubmit, submitting, loading } = this.props;

    return (
      <form onSubmit={handleSubmit} className="register-form">
        <Field
          name="email"
          readOnly={true}
          type="hidden"
          disabled={loading}
          component="input"
        />
        <Field
          name="date_of_birth"
          readOnly={true}
          type="hidden"
          disabled={loading}
          component="input"
        />
        <Field
          name="password"
          readOnly={true}
          type="hidden"
          disabled={loading}
          component="input"
        />
        <Field
          name="language_locale"
          readOnly={true}
          type="hidden"
          disabled={loading}
          component="input"
        />
        <Field
          name="timezone_code"
          readOnly={true}
          type="hidden"
          disabled={loading}
          component="input"
        />
        <Field
          name="unit_system"
          readOnly={true}
          type="hidden"
          disabled={loading}
          component="input"
        />
        <Field
          name="currency_isocode"
          readOnly={true}
          type="hidden"
          disabled={loading}
          component="input"
        />
        <Field
          name="citizen_country_isocode"
          readOnly={true}
          type="hidden"
          disabled={loading}
          component="input"
        />
        <Field
          name="residence_country_isocode"
          readOnly={true}
          type="hidden"
          component="input"
          disabled={loading}
        />
        <div className="text-left">
          <Field
            name="last_name"
            type="text"
            component={renderFieldLabel}
            placeholder={Translation().register.form.labels.last_name}
            className="app-basic-input"
            disabled={loading}
            required
          />
          <Field
            name="first_name"
            type="text"
            component={renderFieldLabel}
            placeholder={Translation().register.form.labels.first_name}
            className="app-basic-input"
            disabled={loading}
            required
          />
          <Field
            name="mobile"
            type="text"
            component={PhoneField}
            placeholder={Translation().register.form.labels.mobile}
            className="app-basic-input"
            disabled={loading}
            style={{
              display: 'block',
              position: 'relative',
              maxWidth: '500px',
              margin: 'auto',
              fontSize: '14px',
              marginBottom: '25px',
            }}
            required
          />
          <div className="inner-field">
            <Field
              name="address"
              type="text"
              disabled={loading}
              label={Translation().shop.payment.invoice_address}
              className="app-basic-input"
              component={GoogleAddressStringField}
              required
            />
          </div>
        </div>
        <div className="bottom-cel align-center">
          <button
            type="submit"
            className="app-button-color button-api"
            disabled={submitting || loading}
          >
            {loading && <span className="loader-api" />}
            <span
              style={
                loading ? { display: 'none' } : { display: 'inline-block' }
              }
            >
              {Translation().pages.delegate.register.submit}
            </span>
          </button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'register_payment_delegate',
  validate,
})(RegisterForm);
