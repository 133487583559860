import * as Cookies from 'js-cookie';
import { formValueSelector, stopSubmit } from 'redux-form';

import { CallApi, apiLoadUser, apiGeocode } from './ApiFunctions';
import { apiGetCart, setUserCart, unsetUserCart } from './CartFunctions';
import {
  storeUser,
  removeUser,
  storeCompany,
  storeUserLoading,
  storeGeoSetting,
} from '../redux/actions/user/userActions';
import { registerDevice } from './PushNotificationsFunctions';
import { parseUrl, generateRandom } from './HelperFunctions';
import { initDateLocale } from './DateFunctions';

import Config from '../config/Config';
import store from '../redux/store';
import Translation from '../translation/Translation';

/**
 * INIT APP
 */
export const initializeApp = (callBackFunc) => {
  /**
   * Check if url contains tokens
   */
  const urlQueryString = parseUrl(window.location);
  const urlQuery = urlQueryString.query;

  if (urlQuery.referrer_code) {
    setReferrerCode(urlQuery.referrer_code);
  }
  if (urlQuery.email && urlQuery.invite_token) {
    setInviteEmailToken(urlQuery.email);
    setInviteToken(urlQuery.invite_token);
  }

  if (urlQuery.access_token) {
    setAccessToken(urlQuery.access_token, 30);
  }

  initializeGeoSettings(null, () => {
    initializeUser(callBackFunc);
  });

  initializeSession();
  initializeCart();
};

/**
 * LOGIN
 */
export const apiImplicitLogin = (credidentials, onDone = null) => {
  if (credidentials) {
    const loginIframe = document.getElementById('frame-auth');
    const iframeContainer = document.getElementById('frames-container');

    const btnLoginLoader = document.getElementsByClassName('loader-api-auth');
    const btnLoginText = document.getElementsByClassName('btn-login-text');

    if (btnLoginText) {
      for (let m = 0; m < btnLoginText.length; m++) {
        btnLoginText[m].style.display = 'none';
      }
    }
    if (btnLoginLoader) {
      for (let n = 0; n < btnLoginLoader.length; n++) {
        btnLoginLoader[n].style.display = 'block';
      }
    }

    /**
     * iframe URL,  If iframe already exists we only change url, if not we create it
     */
    const src =
      Config.api_url +
      'users/auth?client_id=' +
      Config.api_key +
      '&email=' +
      credidentials.email +
      '&password=' +
      encodeURIComponent(credidentials.password) +
      '&submit=1';

    if (loginIframe) loginIframe.setAttribute('src', src);
    else {
      const loginIframe =
        '<iframe id="frame-auth" title="implicit-auth" width="0" height="0" style="display: none" src=' +
        src +
        ' />';
      iframeContainer.innerHTML = loginIframe;
    }

    document.getElementById('frame-auth').onload = function () {
      const loginIframe = document.getElementById('frame-auth');

      /**
       * Check if our front app can access to iframe
       */
      try {
        const frameWindowLocation = loginIframe.contentWindow.location.href;
        /**
         * Check if iframe doesn't exist
         */
        if (!frameWindowLocation) {
          return;
        } else if (
          /**
           * Check if iframe exist but hash doesn't exist
           */
          frameWindowLocation &&
          !loginIframe.contentWindow.location.hash
        ) {
          return;
        }
      } catch (e) {
        const errorOutput = document.getElementsByClassName('error-message')[0];
        const errorMessage = Translation().login.form.callbacks.failure;

        if (errorOutput) {
          errorOutput.textContent = errorMessage;
        }
        if (btnLoginLoader) {
          for (let n = 0; n < btnLoginLoader.length; n++) {
            btnLoginLoader[n].style.display = 'none';
          }
        }
        if (btnLoginText) {
          for (let m = 0; m < btnLoginText.length; m++) {
            btnLoginText[m].style.display = 'block';
          }
        }
        return;
      }

      /**
       * Get and split all hash
       */
      const hash = loginIframe.contentWindow.location.hash;
      const hashParams = hash.substring(1).split('&');

      for (let i = 0; i < hashParams.length; i++) {
        const hashParamsSplit = hashParams[i].split('=');
        hashParams[hashParamsSplit[0]] = hashParamsSplit[1];
      }

      /**
       * Set access_token in cookies
       */
      const accessToken = hashParams.access_token;
      let expirationDelay = hashParams.expires_in;

      /**
       * Change seconds to days
       */
      expirationDelay = expirationDelay / (60 * 60 * 24);
      if (accessToken && expirationDelay) {
        setAccessToken(accessToken, expirationDelay);

        apiLoadUser(() => {
          registerDevice();

          if (onDone) {
            onDone();
          }
        });

        initializeCart();

        if (btnLoginLoader) {
          for (let n = 0; n < btnLoginLoader.length; n++) {
            btnLoginLoader[n].style.display = 'none';
          }
        }
        if (btnLoginText) {
          for (let m = 0; m < btnLoginText.length; m++) {
            btnLoginText[m].style.display = 'block';
          }
        }
        /**
         * Only for shop : simulate login click on login/register in shop step 3
         */
        const simulateBtn = document.getElementById('simulate-click');
        if (simulateBtn) {
          document.getElementById('simulate-click').click();
        }
      }
    };
  }
};

export const setUserLoading = (isUserLoading) => {
  store.dispatch(storeUserLoading(isUserLoading));
};

/**
 * REGISTER
 */
export const apiRegisterUser = (credidentials) => {
  if (credidentials) {
    const loader = document.getElementById('regis-loader');
    const textLoader = document.getElementById('regis-btn-text');
    const errorOutput = document.getElementById('error-message-regis');
    const state = store.getState();
    const selector = formValueSelector('register');

    if (loader && textLoader) {
      loader.style.display = 'block';
      textLoader.style.display = 'none';
    }

    if (credidentials.legal_id && credidentials.legal_name) {
      const credidentialsCompany = {};

      credidentialsCompany.legal_id = credidentials.legal_id;
      credidentialsCompany.legal_identifier = 'siren';
      credidentialsCompany.legal_name = credidentials.legal_name;
      credidentialsCompany.commercial_name = credidentials.legal_name;

      credidentialsCompany.users = [
        {
          gender: credidentials.gender ? credidentials.gender : null,
          first_name: credidentials.first_name
            ? credidentials.first_name
            : null,
          last_name: credidentials.last_name ? credidentials.last_name : null,
          date_of_birth: credidentials.date_of_birth
            ? credidentials.date_of_birth
            : null,
          email: credidentials.email ? credidentials.email : null,
          mobile: credidentials.mobile ? credidentials.mobile : null,
          phone: credidentials.phone ? credidentials.phone : null,
          referrer_code: credidentials.referrer_code
            ? credidentials.referrer_code
            : null,
          password: credidentials.password ? credidentials.password : null,
          confirmPwd: credidentials.confirmPwd
            ? credidentials.confirmPwd
            : null,
          timezone: credidentials.timezone ? credidentials.timezone : null,
          currency_isocode: credidentials.currency_isocode
            ? credidentials.currency_isocode
            : null,
          language_locale: credidentials.language_locale
            ? credidentials.language_locale
            : null,
          invite_token: credidentials.invite_token
            ? credidentials.invite_token
            : null,
        },
      ];

      CallApi(
        'post',
        'companies',
        null,
        credidentialsCompany,
        null,
        (responseData) => {
          /**
           * Send company created event to google tag manager
           */
          if (window && window.dataLayer) {
            window.dataLayer.push({
              event: 'company.created',
            });
          }

          if (loader && textLoader) {
            loader.style.display = 'none';
            textLoader.style.display = 'block';
          }
          if (errorOutput) {
            errorOutput.innerHTML = null;
          }

          /**
           * Remove all cookies tokens for registering
           */
          Cookies.remove(Config.referrer_cookie_name);
          Cookies.remove(Config.invite_cookie_name);
          Cookies.remove(Config.invite_email_cookie_name);

          /**
           * Get the password and email used in register form
           */
          const userPassword = selector(state, 'password');
          const userEmail = selector(state, 'email');
          /**
           * Copy them to a new credidentials object
           */
          const loginCredidentials = {
            email: userEmail,
            password: userPassword,
          };
          if (loginCredidentials) {
            apiImplicitLogin(loginCredidentials);
          }
        },
        (error) => {
          if (loader && textLoader) {
            loader.style.display = 'none';
            textLoader.style.display = 'block';
          }
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              const validationErrors = {};
              const errorsArray = error.response.data.errors;

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath)
                  validationErrors[errorsArray[i].propertyPath] =
                    errorsArray[i].message;
              }

              store.dispatch(stopSubmit('register', validationErrors));
            }

            if (
              error.response.status &&
              error.response.status === 429 &&
              errorOutput
            ) {
              errorOutput.innerHTML = 'To many requests, please try later.';
            }
          } else {
            if (errorOutput) {
              errorOutput.innerHTML = 'An error occurred, please retry later.';
            }
          }
        }
      );
    } else if (credidentials.legal_name && !credidentials.legal_id) {
      if (loader && textLoader) {
        loader.style.display = 'none';
        textLoader.style.display = 'block';
      }

      store.dispatch(
        stopSubmit('register', {
          legal_id:
            Translation().register.form.callbacks.fill_siren ||
            'Missing legal id',
        })
      );
    } else if (!credidentials.legal_name && credidentials.legal_id) {
      if (loader && textLoader) {
        loader.style.display = 'none';
        textLoader.style.display = 'block';
      }

      store.dispatch(
        stopSubmit('register', {
          legal_name:
            Translation().register.form.callbacks.fill_company_name ||
            'Missing legal name',
        })
      );
    } else {
      CallApi(
        'post',
        'users',
        null,
        credidentials,
        null,
        (responseData) => {
          /**
           * Send user created event to google tag manager
           */
          if (window && window.dataLayer) {
            window.dataLayer.push({
              event: 'user.created',
            });
          }

          if (loader && textLoader) {
            loader.style.display = 'none';
            textLoader.style.display = 'block';
          }
          if (errorOutput) {
            errorOutput.innerHTML = null;
          }

          /**
           * Remove all cookies tokens for registering
           */
          Cookies.remove(Config.referrer_cookie_name);
          Cookies.remove(Config.invite_cookie_name);
          Cookies.remove(Config.invite_email_cookie_name);

          /**
           * Get the password and email used in register form
           */
          const userPassword = selector(state, 'password');
          const userEmail = selector(state, 'email');
          /**
           * Copy them to a new credidentials object
           */
          const loginCredidentials = {
            email: userEmail,
            password: userPassword,
          };
          if (loginCredidentials) {
            apiImplicitLogin(loginCredidentials);
          }
        },
        (error) => {
          if (loader && textLoader) {
            loader.style.display = 'none';
            textLoader.style.display = 'block';
          }
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              const validationErrors = {};
              const errorsArray = error.response.data.errors;

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath)
                  validationErrors[errorsArray[i].propertyPath] =
                    errorsArray[i].message;
              }

              store.dispatch(stopSubmit('register', validationErrors));
            }

            if (
              error.response.status &&
              error.response.status === 429 &&
              errorOutput
            ) {
              errorOutput.innerHTML = 'To many requests, please try later.';
            }
          } else {
            if (errorOutput) {
              errorOutput.innerHTML = 'An error occurred, please retry later.';
            }
          }
        }
      );
    }
  }
};

/**
 * USER
 */
export const initializeUser = (callBackFunc) => {
  apiLoadUser(callBackFunc);
};

/**
 * Get the referrer token from cookies
 */
export const getReferrerCode = () => {
  return Cookies.get(Config.referrer_cookie_name);
};

/**
 * Set the referrer token in cookies
 */
const setReferrerCode = (referrerCode) => {
  if (referrerCode) {
    Cookies.set(Config.referrer_cookie_name, referrerCode, { expires: 7 });
  }
};

/**
 * Get the referrer token from cookies
 */
export const getInviteToken = () => {
  return Cookies.get(Config.invite_cookie_name);
};

/**
 * Set the referrer token in cookies
 */
const setInviteToken = (inviteToken) => {
  if (inviteToken) {
    Cookies.set(Config.invite_cookie_name, inviteToken, { expires: 1 });
  }
};

/**
 * Get the invite email token from cookies
 */
export const getInviteEmailToken = () => {
  return Cookies.get(Config.invite_email_cookie_name);
};

/**
 * Set the invite email token in cookies
 */
const setInviteEmailToken = (inviteEmail) => {
  if (inviteEmail) {
    Cookies.set(Config.invite_email_cookie_name, inviteEmail, { expires: 1 });
  }
};

/**
 * Get the access token from cookies
 */
export const getAccessToken = () => {
  return Cookies.get(Config.access_token_cookie_name);
};

/**
 * Set the access token in cookies
 * Secure true only works on https domains
 */
const setAccessToken = (accessToken, expirationDelay) => {
  if (accessToken && expirationDelay) {
    if (Config.debug === 'DEV') {
      Cookies.set(Config.access_token_cookie_name, accessToken, {
        expires: expirationDelay,
      });
    } else {
      Cookies.set(Config.access_token_cookie_name, accessToken, {
        expires: expirationDelay,
        secure: true,
      });
    }
  }
};

/**
 * Get the user from redux store
 */
export const getUser = () => {
  const state = store.getState();
  const user = state.user;
  return user;
};

/**
 * Set the user into redux store
 */
export const setUser = (userData) => {
  if (userData) {
    store.dispatch(storeUser(userData));
    initializeGeoSettings(userData);

    /**
     * Init google tag manager user
     */
    if (window && window.dataLayer) {
      window.dataLayer.push({
        user_id: userData.id,
        user_name: userData.first_name + ' ' + userData.last_name,
        company_id:
          userData.company && userData.company.id ? userData.company.id : null,
        company_name:
          userData.company && userData.company.commercial_name
            ? userData.company.commercial_name
            : null,
      });
    }
  }
};

/**
 * Edit a user
 */
export const apiPutUser = (values, callBackSuccess, callBackError) => {
  if (values && callBackSuccess && callBackError) {
    CallApi(
      'put',
      'users/self',
      null,
      values,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Edit a company
 */
export const apiPutCompany = (values, callBackSuccess, callBackError) => {
  if (values && callBackSuccess && callBackError) {
    CallApi(
      'put',
      'companies/self',
      null,
      values,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Store company to redux store
 */
export const setCompany = (company) => {
  if (company) {
    store.dispatch(storeCompany(company));
  }
};

/**
 * Get the users of the company
 */
export const apiGetUsersCompany = (
  routePage,
  callBackSuccess,
  callBackError
) => {
  if (callBackSuccess && callBackError) {
    CallApi(
      'get',
      routePage
        ? routePage.replace(Config.api_url, '')
        : 'companies/self/users',
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Invite one or mulitple users for join a company
 */
export const invitesUserCompany = (emails, callBackSuccess, callBackError) => {
  if (!emails) {
    return;
  }

  CallApi(
    'post',
    'companies/self/users/invite',
    null,
    emails,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Unlink a user from a company
 */
export const unlinkUserCompany = (userId, callBackSuccess, callBackError) => {
  if (!userId) {
    return;
  }

  CallApi(
    'post',
    'companies/self/users/' + userId + '/unlink',
    null,
    null,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Edit a user password
 */
export const apiPutUserPassword = (values, callBackSuccess, callBackError) => {
  if (values && callBackSuccess && callBackError) {
    CallApi(
      'put',
      'users/self/password',
      null,
      values,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 *  Logout the user
 */
export const logoutUser = () => {
  const accessToken = Cookies.get(Config.access_token_cookie_name);

  if (accessToken) {
    /**
     * Kill the old access_token in cookies
     * Kill the session id
     */
    Cookies.remove(Config.access_token_cookie_name);
    Cookies.remove(Config.visitor_id_cookie_name);
    unsetUserCart();
  }
  /**
   * Remove the user from redux store
   */
  store.dispatch(removeUser());
};

/**
 * Add an invoice address to the user
 */
export const setInvoiceUserAddress = (
  companyToggleValue,
  addressName,
  address,
  callBackSuccess,
  callBackError
) => {
  if (!address || !addressName || !callBackSuccess || !callBackError) {
    return;
  }

  CallApi(
    'get',
    'geocoder/geocode',
    { address: address },
    null,
    null,
    (successGeoData) => {
      /**
       * Construct new address with the address geocoded
       */
      const newAddress = {
        name: addressName,
        latitude: successGeoData.latitude,
        longitude: successGeoData.longitude,
        street: successGeoData.street,
        street_number: successGeoData.street_number,
        zip_code: successGeoData.zip_code,
        city: successGeoData.city,
        country_isocode: successGeoData.country_isocode,
        state_isocode: successGeoData.state_isocode,
      };
      if (companyToggleValue) {
        CallApi(
          'post',
          'companies/self/addresses',
          null,
          newAddress,
          null,
          callBackSuccess,
          callBackError
        );
      } else {
        CallApi(
          'post',
          'users/self/addresses',
          null,
          newAddress,
          null,
          callBackSuccess,
          callBackError
        );
      }
    },
    (errorGeoData) => {
      callBackError(errorGeoData);
    }
  );
};

export const editInvoiceUserAddress = (
  companyToggleValue,
  addressId,
  addressName,
  address,
  callBackSuccess,
  callBackError
) => {
  if (
    !address ||
    !addressId ||
    !addressName ||
    !callBackSuccess ||
    !callBackError
  )
    return;

  CallApi(
    'get',
    'geocoder/geocode',
    { address: address },
    null,
    null,
    (successGeoData) => {
      /**
       * Construct new address with the address geocoded
       */
      const newAddress = {
        name: addressName,
        latitude: successGeoData.latitude,
        longitude: successGeoData.longitude,
        street: successGeoData.street,
        street_number: successGeoData.street_number,
        zip_code: successGeoData.zip_code,
        city: successGeoData.city,
        country_isocode: successGeoData.country_isocode,
        state_isocode: successGeoData.state_isocode,
      };
      if (companyToggleValue) {
        CallApi(
          'put',
          'companies/self/addresses/' + addressId,
          null,
          newAddress,
          null,
          callBackSuccess,
          callBackError
        );
      } else {
        CallApi(
          'put',
          'users/self/addresses/' + addressId,
          null,
          newAddress,
          null,
          callBackSuccess,
          callBackError
        );
      }
    },
    (errorGeoData) => {
      callBackError(errorGeoData);
    }
  );
};

/**
 * Get all invoice addresses of a user
 */
export const getInvoiceUserAddresses = (callBackSuccess, callBackError) => {
  if (callBackSuccess && callBackError) {
    CallApi(
      'get',
      'users/self/addresses/usable',
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Remove an invoice address of a user
 */
export const deleteInvoiceUserAddress = (
  companyToggleValue,
  invoiceAddressId,
  callBackSuccess,
  callBackError
) => {
  if (!invoiceAddressId || !callBackError || !callBackSuccess) {
    return;
  }

  CallApi(
    'delete',
    (companyToggleValue
      ? 'companies/self/addresses/'
      : 'users/self/addresses/') + invoiceAddressId,
    null,
    null,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Get the payment methods of a user or a company
 */
export const getUserPaymentMethod = (
  usable,
  routePage,
  companyToggleValue,
  query = {},
  callBackSuccess,
  callBackError
) => {
  if (callBackSuccess && callBackError) {
    if (usable) {
      CallApi(
        'get',
        routePage
          ? routePage.replace(Config.api_url, '')
          : companyToggleValue
          ? 'companies/self/payment-methods'
          : 'users/self/payment-methods/usable',
        companyToggleValue ? { filters: 'active|eq|1', ...query } : query,
        null,
        null,
        callBackSuccess,
        callBackError
      );
    } else {
      CallApi(
        'get',
        routePage
          ? routePage.replace(Config.api_url, '')
          : companyToggleValue
          ? 'companies/self/payment-methods'
          : 'users/self/payment-methods',
        { filters: 'active|eq|1', ...query },
        null,
        null,
        callBackSuccess,
        callBackError
      );
    }
  }
};

/**
 * Delete a payment methods of a user
 */
export const deleteUserPaymentMethod = (
  companyToggleValue,
  paymentMethodId,
  callBackSuccess,
  callBackError
) => {
  if (paymentMethodId && callBackSuccess && callBackError) {
    CallApi(
      'delete',
      companyToggleValue
        ? 'companies/self/payment-methods/' + paymentMethodId
        : 'users/self/payment-methods/' + paymentMethodId,
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Get authorization of a company payment methods for a user
 */
export const getUserAuthorizationStatusPaymentMethod = (
  userId,
  paymentId,
  callBackSuccess,
  callBackError
) => {
  if (userId && paymentId && callBackSuccess && callBackError) {
    CallApi(
      'get',
      'companies/self/payment-methods/' + paymentId + '/users/' + userId,
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Allow a user to use company payment methods
 */
export const authorizeCompanyPaymentMethod = (
  userId,
  paymentId,
  callBackSuccess,
  callBackError
) => {
  if (userId && paymentId && callBackSuccess && callBackError) {
    CallApi(
      'post',
      'companies/self/payment-methods/' + paymentId + '/users/' + userId,
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Unallow a user to use company payment methods
 */
export const unauthorizeCompanyPaymentMethod = (
  userId,
  paymentId,
  callBackSuccess,
  callBackError
) => {
  if (userId && paymentId && callBackSuccess && callBackError) {
    CallApi(
      'delete',
      'companies/self/payment-methods/' + paymentId + '/users/' + userId,
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Adminize or Unadminize user company
 */
export const adminizeCompanyUser = (
  checked,
  userId,
  callBackSuccess,
  callBackError
) => {
  if (!userId) return;

  CallApi(
    'post',
    'companies/self/users/' + userId + (checked ? '/adminize' : '/unadminize'),
    null,
    null,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Load in DOM the payment iframe
 */
export const loadPaymentMethodIframe = (
  companyToggleValue = false,
  callBackFunction
) => {
  if (!callBackFunction) {
    return;
  }

  const iframeContainer = document.getElementById('payment-frame-container');
  const iframePaymentLoader = document.getElementById('frame-payment-loader');

  if (!iframeContainer) {
    return;
  }

  if (iframePaymentLoader) {
    iframePaymentLoader.style.display = 'block';
  }

  /**
   * Iframe URL
   */
  const route = companyToggleValue
    ? 'companies/self/payment-methods/form'
    : 'users/self/payment-methods/form'; // <-- Check if user is company admin
  const src =
    Config.api_url +
    route +
    '?access_token=' +
    (getAccessToken() ? getAccessToken() : null);
  const paymentFrame =
    '<iframe id="frame-payment" class="app-basic-iframe" title="paymentMethod" src=' +
    src +
    ' />';

  iframeContainer.innerHTML = '';
  iframeContainer.innerHTML = paymentFrame;

  document.getElementById('frame-payment').onload = function () {
    if (iframePaymentLoader) {
      iframePaymentLoader.style.display = 'none';
    }

    const paymentFrame = document.getElementById('frame-payment');

    /**
     * Check if our front app can access to iframe
     * Check if the ifram exists
     * Check if iframe exist but hash doesn't exist
     */
    try {
      const frameWindowLocation = paymentFrame.contentWindow.location.href;

      if (!frameWindowLocation) {
        return;
      } else if (
        frameWindowLocation &&
        !paymentFrame.contentWindow.location.hash
      ) {
        return;
      }
    } catch (e) {
      if (iframePaymentLoader) {
        iframePaymentLoader.style.display = 'none';
      }
      return;
    }

    /**
     * Get and split all hash
     */
    const hash = paymentFrame.contentWindow.location.hash;

    if (hash === '#success') {
      callBackFunction();
    }
  };
};

/**
 * SESSION
 */
const initializeSession = () => {
  const sessionId = getSessionId();

  if (sessionId) {
    return;
  } else {
    setSessionId();
  }
};

/**
 * Get the session id from cookies
 */
const getSessionId = () => {
  return Cookies.get(Config.visitor_id_cookie_name);
};

/**
 * Generate a random session id
 */
const generateSessionId = () => {
  const timeStamp = new Date().getTime();
  const prefix = generateRandom(1, 999);
  const suffix = generateRandom(1, 999);
  const sessionId = 'sess' + prefix + timeStamp + suffix;

  return sessionId;
};

/**
 * Set the session into cookies
 */
const setSessionId = () => {
  const sessionId = generateSessionId();

  if (Config.debug === 'DEV') {
    Cookies.set(Config.visitor_id_cookie_name, sessionId, { expires: 1 });
  } else {
    Cookies.set(Config.visitor_id_cookie_name, sessionId, {
      expires: 1,
      secure: true,
    });
  }
};

/**
 * LOCALE
 */

/**
 * Get locale from cookies
 */
export const getLocale = () => {
  const locale = Cookies.get(Config.locale_cookie_name);

  if (locale) return locale;
  else return Config.default_locale;
};

export const isSetLocale = () => {
  const locale = Cookies.get(Config.locale_cookie_name);

  if (locale) return true;
  else return false;
};

/**
 * Set locale in cookies
 */
export const setLocale = (locale) => {
  if (locale) Cookies.set(Config.locale_cookie_name, locale, { expires: 365 });
};

/**
 * Remove the session from cookies
 * const unsetLocale = () =>  {
 *   Cookies.remove(Config.locale_cookie_name);
 * }
 */
export const selectLang = (locale) => {
  if (!locale) return;

  /**
   * Set locale in cookies then refresh page
   */
  setLocale(locale);
  window.location.reload();
};

/**
 * CURRENCY
 */

/**
 * Get the currency from cookies
 */
export const getCurrency = () => {
  const currency = Cookies.get(Config.currency_cookie_name);

  if (currency) return currency;
  else return Config.default_currency;
};

export const isSetCurrency = () => {
  const currency = Cookies.get(Config.currency_cookie_name);

  if (currency) return true;
  else return false;
};

/**
 * Set the currency into cookies
 */
const setCurrency = (currency) => {
  if (currency)
    Cookies.set(Config.currency_cookie_name, currency, { expires: 365 });
};

/**
 * COUNTRY
 */
export const getCitizenCountry = () => {
  const country = Cookies.get(Config.citizen_country_cookie_name);

  if (country) return country;
  else return Config.default_country;
};

export const isSetCitizenCountry = () => {
  const country = Cookies.get(Config.citizen_country_cookie_name);

  if (country) return true;
  else return false;
};

export const setCitizenCountry = (country) => {
  if (country)
    Cookies.set(Config.citizen_country_cookie_name, country, { expires: 365 });
};

export const getResidenceCountry = () => {
  const country = Cookies.get(Config.residence_country_cookie_name);

  if (country) return country;
  else return Config.default_country;
};

export const isSetResidenceCountry = () => {
  const country = Cookies.get(Config.residence_country_cookie_name);

  if (country) return true;
  else return false;
};

export const setResidenceCountry = (country) => {
  if (country)
    Cookies.set(Config.residence_country_cookie_name, country, {
      expires: 365,
    });
};

/**
 * TIMEZONE
 */

/**
 * Get the timezone from cookies
 */
export const getTimezone = () => {
  const timezone = Cookies.get(Config.timezone_cookie_name);

  if (timezone) return timezone;
  else return Config.default_timezone;
};

export const isSetTimezone = () => {
  const timezone = Cookies.get(Config.timezone_cookie_name);

  if (timezone) return true;
  else return false;
};

/**
 * Set the timezone into cookies
 */
export const setTimezone = (timezone) => {
  if (timezone)
    Cookies.set(Config.timezone_cookie_name, timezone, { expires: 365 });
};

/**
 * UNIT SYSTEM
 */
export const setUnitSystem = (unitSystem) => {
  if (unitSystem)
    Cookies.set(Config.unit_system_cookie_name, unitSystem, { expires: 365 });
};

export const getUnitSystem = () => {
  const unitSystem = Cookies.get(Config.unit_system_cookie_name);

  if (unitSystem) return unitSystem;
  else return Config.default_unit_system;
};

export const isSetUnitSystem = () => {
  const unitSystem = Cookies.get(Config.unit_system_cookie_name);

  if (unitSystem) return true;
  else return false;
};

/**
 * BRAND PHONE NUMBER
 */
export const setBrandPhoneNumber = (brandPhoneNumber) => {
  if (brandPhoneNumber)
    Cookies.set(Config.brand_phone_number_cookie_name, brandPhoneNumber, {
      expires: 365,
    });
};

export const getBrandPhoneNumber = () => {
  const brandPhoneNumber = Cookies.get(Config.brand_phone_number_cookie_name);

  if (brandPhoneNumber) return brandPhoneNumber;
  else return Config.brand_phone_number;
};

export const isSetBrandPhoneNumber = () => {
  const brandPhoneNumber = Cookies.get(Config.brand_phone_number_cookie_name);

  if (brandPhoneNumber) return true;
  else return false;
};

export const setSupportEmail = (email) => {
  if (email)
    Cookies.set(Config.support_email_cookie_name, email, { expires: 365 });
};

export const getSupportEmail = () => {
  const email = Cookies.get(Config.support_email_cookie_name);

  if (email) return email;
  else return Config.support_email;
};

export const isSetSupportEmail = () => {
  const email = Cookies.get(Config.support_email_cookie_name);

  if (email) return true;
  else return false;
};

const initializeGeoSettings = (user = null, callBackFunc) => {
  if (user) {
    if (user.language && user.language.locale) {
      setLocale(user.language.locale);
      initDateLocale(user.language.locale);
    } else {
      initDateLocale(getLocale());
    }

    if (user.currency && user.currency.isocode)
      setCurrency(user.currency.isocode);

    if (user.timezone_code) setTimezone(user.timezone_code);

    if (user.residence_country && user.residence_country.isocode) {
      setResidenceCountry(user.residence_country.isocode);
      getApiGeoBrandPhoneNumber(
        user.residence_country.isocode,
        (success) => {
          store.dispatch(
            storeGeoSetting({
              brand_phone_number: success.support_phone_formatted
                ? success.support_phone_formatted
                : success.support_phone
                ? success.support_phone
                : Config.brand_phone_number,
              support_email: success.support_email
                ? success.support_email
                : Config.support_email,
            })
          );
          setBrandPhoneNumber(
            success.support_phone_formatted
              ? success.support_phone_formatted
              : success.support_phone
              ? success.support_phone
              : Config.brand_phone_number
          );
          setSupportEmail(
            success.support_email ? success.support_email : Config.support_email
          );
        },
        () => {
          setBrandPhoneNumber(Config.brand_phone_number);
          setSupportEmail(Config.support_email);
        }
      );
    } else {
      store.dispatch(
        storeGeoSetting({
          brand_phone_number: Config.brand_phone_number,
          support_email: Config.support_email,
        })
      );
      setBrandPhoneNumber(Config.brand_phone_number);
      setSupportEmail(Config.support_email);
    }

    if (user.citizen_country && user.citizen_country.isocode)
      setCitizenCountry(user.citizen_country.isocode);

    if (user.unit_system) setUnitSystem(user.unit_system);

    callBackFunc && callBackFunc();
  } else {
    if (
      !isSetLocale() ||
      !isSetCurrency() ||
      !isSetTimezone() ||
      !isSetCitizenCountry() ||
      !isSetResidenceCountry() ||
      !isSetUnitSystem() ||
      !isSetBrandPhoneNumber() ||
      !isSetSupportEmail()
    ) {
      apiGeocode(
        { details: true },
        (data) => {
          if (data.timezone_code) {
            setTimezone(data.timezone_code);
            store.dispatch(
              storeGeoSetting({ timezone_code: data.timezone_code })
            );
          } else setTimezone(Config.default_timezone);

          if (data.locale) {
            setLocale(data.locale);
            initDateLocale(data.locale);
            store.dispatch(storeGeoSetting({ locale: data.locale }));
          } else {
            setLocale(Config.default_locale);
            initDateLocale(Config.default_locale);
          }

          if (data.currency) {
            setCurrency(data.currency_isocode);
            store.dispatch(
              storeGeoSetting({ currency: data.currency_isocode })
            );
          } else setCurrency(Config.default_currency);

          if (data.country_isocode) {
            setCitizenCountry(data.country_isocode);
            setResidenceCountry(data.country_isocode);
            store.dispatch(
              storeGeoSetting({ citizen_country: data.country_isocode })
            );
            store.dispatch(
              storeGeoSetting({ residence_country: data.country_isocode })
            );

            getApiGeoBrandPhoneNumber(
              data.country_isocode,
              (success) => {
                store.dispatch(
                  storeGeoSetting({
                    brand_phone_number: success.support_phone_formatted
                      ? success.support_phone_formatted
                      : success.support_phone
                      ? success.support_phone
                      : Config.brand_phone_number,
                    support_email: success.support_email
                      ? success.support_email
                      : Config.support_email,
                  })
                );
                setBrandPhoneNumber(
                  success.support_phone_formatted
                    ? success.support_phone_formatted
                    : success.support_phone
                    ? success.support_phone
                    : Config.brand_phone_number
                );
                setSupportEmail(
                  success.support_email
                    ? success.support_email
                    : Config.support_email
                );
              },
              () => {
                setBrandPhoneNumber(Config.brand_phone_number);
                setSupportEmail(Config.support_email);
              }
            );
          } else {
            setCitizenCountry(Config.default_country);
            setResidenceCountry(Config.default_country);
            setBrandPhoneNumber(Config.brand_phone_number);
            setSupportEmail(Config.support_email);
          }

          if (data.unit_system) {
            setUnitSystem(data.unit_system);
            store.dispatch(storeGeoSetting({ unit_system: data.unit_system }));
          } else setUnitSystem(Config.default_unit_system);

          callBackFunc();
        },
        (e) => {
          setTimezone(Config.default_timezone);
          setLocale(Config.default_locale);
          setCurrency(Config.default_currency);
          setCitizenCountry(Config.default_country);
          setResidenceCountry(Config.default_country);
          setUnitSystem(Config.default_unit_system);
          initDateLocale(Config.default_locale);

          getApiGeoBrandPhoneNumber(
            Config.default_country,
            (success) => {
              store.dispatch(
                storeGeoSetting({
                  brand_phone_number: success.support_phone_formatted
                    ? success.support_phone_formatted
                    : success.support_phone
                    ? success.support_phone
                    : Config.brand_phone_number,
                })
              );
              setBrandPhoneNumber(
                success.support_phone_formatted
                  ? success.support_phone_formatted
                  : success.support_phone
                  ? success.support_phone
                  : Config.brand_phone_number
              );
              setSupportEmail(
                success.support_email
                  ? success.support_email
                  : Config.support_email
              );
            },
            () => {
              setBrandPhoneNumber(Config.brand_phone_number);
              setSupportEmail(Config.support_email);
            }
          );

          callBackFunc();
        }
      );
    } else {
      initDateLocale(getLocale());
      callBackFunc();
    }
  }
};

// const getBrowserGeolocation = (callBackSuccess, callBackError, callBackGeoRefused) => {
//
//     if (!navigator || !navigator.geolocation)
//         return;
//
//     navigator.geolocation.getCurrentPosition(
//         function(position) {
//             apiGeocode(position.coords.latitude, position.coords.longitude,
//                 function(response) {
//                     callBackSuccess && callBackSuccess(response);
//                 },
//                 function(error) {
//                     callBackError && callBackError(error);
//                 }
//             );
//         },
//         function(error) {
//             callBackGeoRefused && callBackGeoRefused();
//         }
//     );
// }

/**
 * INIT CART
 * See ./cartFunctions.js
 */
const initializeCart = () => {
  apiGetCart(
    (responseData) => {
      if (responseData) {
        setUserCart(responseData);
      }
    },
    (error) => {}
  );
};

export const getApiGeoBrandPhoneNumber = (code, onSuccess, onError) => {
  if (!code) return;
  CallApi(
    'get',
    'countries/' + code.toLowerCase() + '/support',
    null,
    null,
    null,
    onSuccess,
    onError
  );
};
