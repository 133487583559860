import React, { Component } from 'react';
import { stopSubmit } from 'redux-form';
import { subscribe } from 'redux-subscriber';
import { Alert } from 'react-bootstrap';

import {
  apiImplicitLogin,
  setInvoiceUserAddress,
} from '../../../../../helpers/UserFunctions';
import { CallApi } from '../../../../../helpers/ApiFunctions';
import { generateRandomPassword } from '../../../../../helpers/HelperFunctions';
import store from '../../../../../redux/store';
import Translation from '../../../../../translation/Translation';
import RegisterForm from './RegisterForm';

class RegisterDelegateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      geoSettings: null,
      error: null,
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.createAddress = this.createAddress.bind(this);
    this.subscribeGeoSettings = undefined;
  }

  componentDidMount() {
    this.subscribeGeoSettings = subscribe('geoSettings', (state) => {
      if (state.geoSettings) {
        this.setState({
          geoSettings: state.geoSettings,
        });
      }
    });
  }

  componentWillUnmount() {
    this.subscribeGeoSettings();
  }

  createAddress(address) {
    const { onRegister } = this.props;

    this.setState({
      error: null,
      loading: true,
    });

    setInvoiceUserAddress(
      false,
      'Default',
      address,
      (data) => {
        this.setState({
          error: null,
          loading: false,
        });

        // Final callback for the end of the page
        if (data.id) {
          onRegister(data.id);
        } else {
          this.setState({
            error: 'Fatal error: no address id found.',
          });
        }
      },
      (error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          const fieldsErrors = [
            'street_number',
            'street',
            'zip_code',
            'city',
            'country_isocode',
            'state_isocode',
            'latitude',
            'longitude',
          ];
          const validationErrors = {};
          const errorsArray = error.response.data.errors;

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath === 'address') {
              validationErrors['address'] = errorsArray[i].message;
            } else if (fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0) {
              validationErrors['address'] =
                errorsArray[i].propertyPath + ': ' + errorsArray[i].message;
            } else
              validationErrors[errorsArray[i].propertyPath] =
                errorsArray[i].message;
          }

          store.dispatch(
            stopSubmit('register_payment_delegate', validationErrors)
          );
        }

        this.setState({
          error:
            Translation().account.invoices.modals.invoice_addresses.create.form
              .callbacks.error,
          loading: false,
        });
      }
    );
  }

  registerUser(values) {
    const { onSubmit } = this.props;

    if (onSubmit) {
      onSubmit();
    }

    this.setState({
      error: null,
      loading: true,
    });

    CallApi(
      'post',
      'users',
      null,
      values,
      null,
      () => {
        /**
         * Send user created event to google tag manager
         */
        if (window && window.dataLayer) {
          window.dataLayer.push({
            event: 'user.created',
          });
        }

        const credentials = {
          email: values.email,
          password: values.password,
        };

        apiImplicitLogin(credentials, () => {
          // create user success & auto login
          this.createAddress(values.address);
        });
      },
      (error) => {
        const errorMessage =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : 'An error has occurred, please try again';

        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          const validationErrors = {};
          const errorsArray = error.response.data.errors;

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath)
              validationErrors[errorsArray[i].propertyPath] =
                errorsArray[i].message;
          }

          store.dispatch(
            stopSubmit('register_payment_delegate', validationErrors)
          );
        }

        this.setState({
          error: errorMessage,
          loading: false,
        });
      }
    );
  }

  handleSubmit(values) {
    if (!values || !values.address) return;

    this.registerUser(values);
  }

  render() {
    const { email } = this.props;
    const { error, geoSettings, loading } = this.state;

    return (
      <div>
        <RegisterForm
          name="register_payment_delegate"
          onSubmit={this.handleSubmit}
          loading={loading}
          geoSettings={geoSettings}
          initialValues={{
            email,
            password: generateRandomPassword(),
            date_of_birth: '1980-01-01',
          }}
        />
        {error && (
          <Alert bsStyle="danger" className="delegate-alert">
            <b>
              <span className="icon-warning" style={{ marginRight: 4 }} />
              {Translation().pages.delegate.error}
            </b>
            <p>{error}</p>
          </Alert>
        )}
      </div>
    );
  }
}

export default RegisterDelegateUser;
