import React, { Component } from 'react';

import Config from '../../../config/Config';

class LegalNoticeEN extends Component {

  render() {
    return (
      <div className="app-legal-notice">
        <div className="page-header-title">
          <h1>Wall-Market’s Legal Notice </h1>
        </div>
        <div className="container">
          <div className="row">
            <h2>1. Editor’s information</h2>
            <p>
              Wall Market © <br/>
              Registered office: {Config.brand_address}, {Config.brand_city} <br/>
              EU VAT No: FR 775 327 27740 <br/>
              Paris Trade and Companies Register No: 532 727 740 <br/>
              A French <i>société par actions simplifiée</i> (simplified company) with a share capital of €19,146  <br/>
              <a target="_blank" rel="noopener noreferrer" href="mailto:sales@wall-market.com">sales@wall-market.com</a>
            </p>
            <h2>2. Host’s information</h2>
            <p>
              Amazon Web Services, Inc. <br/>
              P.O. Box 81226 Seattle, WA 98108-1226 <br/>
              <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/fr/">https://aws.amazon.com/fr/</a>
            </p>
            <h2>3. EU Online Dispute Resolution Platform.</h2>
            <p>
              <a target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/consumers/odr/main/?event=main.trader.register">https://ec.europa.eu/consumers/odr/main/?event=main.trader.register</a>
            </p>
            <h2>4. Intellectual Property</h2>
            <p>
              The website, its overall structure and the text, animated and still images, know-how, designs and graphics etc. and any other component of the website, are the property of Wall Market. Any total or partial representation of this website using any process whatsoever, without the express permission of Wall-Market is prohibited and would constitute an infringement punishable under Article L. 335-2 et seq. of the French Intellectual Property Code (code de la propriété intellectuelle). The trademarks of Wall-Market and/or its partners and the logos displayed on the website are registered trademarks (semi-figurative or other types). Accordingly, any total or partial reproduction of those trademarks or logos made from components of the website without the express Wall-Market or its assigns is prohibited, specifically within the meaning of Article L. 713-2 of the French Intellectual Property Code.
              <br/> <br/> Any breach of this Clause may subject you to civil and/or criminal liability.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalNoticeEN;
