import React, { Component } from 'react';
import { Col }  from 'react-bootstrap';
import CountUp from 'react-countup';
import AnimFunctions from '../../../helpers/AnimFunctions';

import Translation from '../../../translation/Translation';

class Milestones extends Component {

  constructor() {
    super();

    this.state = {
      milestonesDone: false
    };
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    const removeHeight = 300;
    const div = document.getElementById('lunch-milestones');
    const offset = AnimFunctions.CalcOffset(div);

    if (!this.state.milestonesDone) {
      if (window.scrollY >= offset.top - removeHeight || document.documentElement.scrollTop >= offset.top - removeHeight || document.body.parentNode.scrollTop >= offset.top - removeHeight || document.body.scrollTop >= offset.top - removeHeight) {
        this.setState({ milestonesDone: true });
      }
    }
  }

  render () {
    const { milestonesDone } = this.state;

    return (
      <div>
        <Col xs={12} sm={6} md={3}>
          <span className="more-than">{Translation().pages.home.milestones.more_than} </span>
          { milestonesDone ? ( <CountUp className="milestones-number" duration={3} start={0} end={3000} /> ) : '' }
          <p>{Translation().pages.home.milestones.agencies}</p>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <span className="more-than">{Translation().pages.home.milestones.more_than} </span>
          { milestonesDone ? ( <CountUp className="milestones-number" duration={3} start={0} end={200000} /> ) : '' }
          <p>{Translation().pages.home.milestones.shooting}</p>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <span className="more-than">{Translation().pages.home.milestones.more_than} </span>
          { milestonesDone ? ( <CountUp className="milestones-number" duration={3} start={0} end={80000} /> ) : '' }
          <p>{Translation().pages.home.milestones.plans}</p>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <span className="more-than">{Translation().pages.home.milestones.more_than} </span>
          { milestonesDone ? ( <CountUp className="milestones-number" duration={5} start={0} end={12500} /> ) : '' }
          <p>{Translation().pages.home.milestones.virtual_visit}</p>
        </Col>
      </div>
    )
  }
}

export default Milestones;
