import React, { Component } from 'react';

import Translation from '../../../translation/Translation';

class UpdateBrowser extends Component {
  
  render() {

    return (
      <div className="shop-property-error" id="updateBrowser">
        <div className="container">
          <div className="row">
            <div className="error-message-shop no-property">
              <h3>{Translation().browser.update_browser.page_title}</h3>
              <h4>{Translation().browser.update_browser.baseline}</h4>
              <p>{Translation().browser.update_browser.description}</p>
              <a className="button-retry" style={{marginBottom: 50}} href="/shop">{Translation().browser.update_browser.button}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateBrowser; 