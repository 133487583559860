import React, { Component } from 'react';

import RegisterView from '../../components/commons/register/RegisterView';
import Translation from '../../translation/Translation';

class Register extends Component {

  render() {

    const { geoSettings } = this.props;

    return (
      <div className="app-register">
        <div className="container">
          <div className="row">
            <div className="register-view">
              <h3>{Translation().register.page_title}</h3>
              <RegisterView geoSettings={geoSettings} />
              <div style={{textAlign: 'center', marginTop: 40}}>
                <a className="use-condition" style={{fontSize: 14}} href="/login">{Translation().register.got_account}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
