import React, { Component } from 'react';

import { getLocale } from '../../../helpers/UserFunctions';
import Translation from '../../../translation/Translation';

import TermsOfUseFR from './translation/TermsOfUseFR';
import TermsOfUseEN from './translation/TermsOfUseEN';
import TermsOfUseES from './translation/TermsOfUseES';

class TermsOfUse extends Component {

  renderTermsOfUse() {
    const locale = getLocale();
  
    if (locale && locale === "fr")
      return <TermsOfUseFR />
    else if (locale && locale === "en")
      return <TermsOfUseEN />
    else if (locale && locale === "es")
      return <TermsOfUseES />
    else if (locale && locale === "it")
      return <TermsOfUseEN />
    else
      return <TermsOfUseEN />
  }

  render() {

    const { isDialog } = this.props;

    return (
      <div className="app-terms-of-use">
        { isDialog &&
          <div className="page-header-title">
            <h1>{Translation().pages.terms_of_use.title}</h1>
          </div>
        }
       { this.renderTermsOfUse() }
      </div>
    );
  }
}

export default TermsOfUse;
