import bowser from 'bowser';

import { CallApi } from './ApiFunctions';
import store from '../redux/store';
import {
  storeCartRequestSlot,
  storeCartRequestProperty,
  storeCartRequestProductId,
  storeCartRequestOptions,
  removeCartRequestSlot,
  removeCartRequestOptions,
  storeUserCart,
  removeUserCart
} from '../redux/actions/cart/cartActions';

/**
 * Set the slot selected in planning into redux store
 */
export const setCartRequestSlot = (slot) => {

  if (slot) {
    store.dispatch(storeCartRequestSlot(slot));
  }
}

/**
 * Remove the slot selected in planning from redux store
 */
export const unsetCartRequestSlot = () => {
 store.dispatch(removeCartRequestSlot());
}

/**
 * Set the property from shop into redux store
 */
export const setCartRequestProperty = (property) => {

  if (property) {
    store.dispatch(storeCartRequestProperty(property));
  }
}

/**
 * Set the property from shop into redux store
 */
export const setCartRequestProductId = (productId) => {

  if (productId) {
    store.dispatch(storeCartRequestProductId(productId));
  }
}

/**
 * Set the options from shop into redux store
 */
export const setCartRequestOptions = (options) => {

  if (options) {
    let optionsFormated = [];

    for (let option in options) {
      /**
       * Remove 'option-' before options selected
       * Then push to new array
       */
      option = option.replace('option-', '');
      optionsFormated.push(option);
    }
    store.dispatch(storeCartRequestOptions(optionsFormated));
  }
}

/**
 * Remove options from the cart request redux store
 */
export const unsetCartRequestOptions = () => {
  store.dispatch(removeCartRequestOptions());
}

/**
 * Get the cart from redux store
 */
export const getCartRequest = () => {

  const state = store.getState();

  if (state && state.cartRequest) {
    const cartRequest = state.cartRequest;
    return cartRequest;
  }
}

/**
 * Send the cart to the api
 */
export const apiPostCart = (cart, callBackSuccess, callBackError) => {

  if (cart && callBackSuccess && callBackError) {
    CallApi('post', "carts/self/products", null, cart, null, callBackSuccess, callBackError);
  }
}

/**
 * Get the cart to the api
 */
export const apiGetCart = (callBackSuccess, callBackError) => {
  if (callBackSuccess && callBackError) {
    CallApi('get', "carts/self", null, null, null, callBackSuccess, callBackError);
  }
}

/**
 * Delete one item from the user cart
 */
export const apiDeleteItemCart = (productId, callBackSuccess, callBackError) => {

  if (productId && callBackSuccess && callBackError) {
    if (bowser.name === 'Internet Explorer' && bowser.version <= 9) {
      console.log('Your trying to delete a cart item on internet explorer 9 ... Try to use Chrome or Firefox');
      CallApi('delete', 'carts/self/products/' + productId, null, null, null, callBackSuccess, callBackError);
    }
    else {
      CallApi('delete', 'carts/self/products/' + productId, null, null, null, callBackSuccess, callBackError);
    }
  }
}

/**
 * Set the user cart into redux store
 */
export const setUserCart = (cart) => {
  if (cart) {
    store.dispatch(storeUserCart(cart));
  }
}

/**
 * Unet the user cart into redux store
 */
export const unsetUserCart = () => {
  store.dispatch(removeUserCart());
}

/**
 * Delete the cart of a user
 */
export const deleteUserCart = (callBackSuccess, callBackError) => {

  if (callBackSuccess && callBackError) {
    if (bowser.name === 'Internet Explorer' && bowser.version <= 9) {
      console.log('Your trying to delete a cart on internet explorer 9 ... Try to use Chrome or Firefox');
      CallApi('delete', 'carts/self', null, null, null, callBackSuccess, callBackError); // <-- Error on IE9
    }
    else {
      CallApi('delete', 'carts/self', null, null, null, callBackSuccess, callBackError);
    }
  }
}

/**
 * Get the user cart from redux store
 */
export const getUserCart = () => {

  const state = store.getState();

  if (state && state.userCart) {
    const userCart = state.userCart;
    return userCart;
  }
}

/**
 * Check promo code on payment step
 */
export const apiPostPromoCode = (promoCode, callBackSuccess, callBackError) => {

  if (promoCode && callBackSuccess && callBackError) {
    CallApi('post', "carts/self/discounts", {return_cart: 1}, {code: promoCode}, null, callBackSuccess, callBackError);
  }
}
