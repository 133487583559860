import React from 'react';
import Select from 'react-select';

/**
 * For documentation : https://gist.github.com/leocristofani/98312e61807db8f32e720c9f97a186e5
 */

export default function RFReactSelect({ input, required, placeHolder, options, multi, className,searchable, clearable, meta: { touched, error } }) {

  const { name, value, onBlur, onChange, onFocus } = input;
  const transformedValue = transformValue(value, options, multi);

  return (
    <span>
      <Select
        valueKey="value"
        name={name}
        value={transformedValue}
        multi={multi}
        options={options}
        onChange={multi
          ? multiChangeHandler(onChange)
          : singleChangeHandler(onChange)
        }
        onBlur={() => onBlur(value)}
        onFocus={onFocus}
        className={error && touched ? (className + " field-get-error") : className}
        placeholder={placeHolder}
        searchable={searchable ? true : false}
        clearable={clearable ? false : true}
      />
      {touched && error && <span className="error-message error-field">{error}</span>}
    </span>
  );
}

const singleChangeHandler = (func) => {
  return function handleSingleChange(value) {
    func(value ? value.value : '');
  };
}

const multiChangeHandler = (func) => {
  return function handleMultiHandler(values) {
    func(values.map(value => value.value));
  };
}

const transformValue = (value, options, multi) => {
  if (multi && typeof value === 'string') return [];

  const filteredOptions = options.filter(option => {
    return multi
      ? value.indexOf(option.value) !== -1
      : option.value === value;
  });

  return multi ? filteredOptions : filteredOptions[0];
}
