import React, { Component } from 'react';
import { Field, change, reduxForm } from 'redux-form';
import { Col, Clearfix } from 'react-bootstrap';
import Checkbox from 'material-ui/Checkbox';
import Dialog from 'material-ui/Dialog';

import {
  getUserPaymentMethod,
  loadPaymentMethodIframe,
} from '../../../../../helpers/UserFunctions';
import Snackbar from '../../../../../components/commons/snackbar/Snackbar';
import Translation from '../../../../../translation/Translation';
import TermsOfUse from '../../../../../components/commons/termsOfUse/TermsOfUse';
import store from '../../../../../redux/store';
import validate from './validate';

class PaymentMethodShortlink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Snackbar
      autoHideDuration: 4000,
      isSnackbarOpen: false,
      snackbarMessage: '',
      snackbarStatus: '',

      // Submit
      isTermsAccepted: false,
      isTermsOpen: false,
    };

    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.handleCheckTerms = this.handleCheckTerms.bind(this);
    this.handleCloseTerms = this.handleCloseTerms.bind(this);
    this.handleOpenTerms = this.handleOpenTerms.bind(this);
  }

  componentDidMount() {
    loadPaymentMethodIframe(false, () => {
      this.setState({
        isPaymentMethodsLoading: true,
      });

      const iframeContainer = document.getElementById(
        'payment-frame-container'
      );
      if (iframeContainer) {
        iframeContainer.style.display = 'none';
      }

      getUserPaymentMethod(
        'usable',
        null,
        null,
        { way: 'pay' },
        (success) => {
          if (success && success.length > 0) {
            let paymentMethodsArray = [];

            for (let i = 0; i < success.length; i++) {
              let paymentMethods = {};

              paymentMethods['value'] = success[i].id;
              paymentMethods['label'] =
                (success[i].type ? success[i].type : '') +
                ' - ' +
                success[i].name;

              paymentMethodsArray.push(paymentMethods);
            }

            this.setState({
              paymentMethods: paymentMethodsArray,
              isPaymentMethodsLoading: false,
            });

            if (paymentMethodsArray[0] && paymentMethodsArray[0].value) {
              store.dispatch(
                change(
                  'payment_delegate',
                  'payment_method',
                  paymentMethodsArray[0].value
                )
              );
            }
          }
        },
        () => {
          this.setState({
            isPaymentMethodsLoading: false,
          });
        }
      );
    });
  }

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  }

  handleOpenTerms() {
    this.setState({ isTermsOpen: true });
  }

  handleCloseTerms() {
    this.setState({ isTermsOpen: false });
  }

  handleCheckTerms(event) {
    this.setState({ isTermsAccepted: event.target.checked });
  }

  render() {
    const { loading, handleSubmit, order } = this.props;

    const {
      autoHideDuration,
      isTermsAccepted,
      isTermsOpen,
      isSnackbarOpen,
      snackbarMessage,
      snackbarStatus,
    } = this.state;

    return (
      <form onSubmit={handleSubmit} className="delegate-methods shortlink">
        <div className="row">
          <Col lg={12} md={12} sm={12}>
            <h2>{Translation().pages.delegate.accept.confirm}</h2>
            {order &&
              order.user &&
              order.user.first_name &&
              order.user.last_name &&
              order.delegate_amount_formatted && (
                <p className="delegate-disclamer">
                  {Translation().pages.delegate.accept.texts[0]}{' '}
                  <b>
                    {order.user.first_name} {order.user.last_name}
                  </b>{' '}
                  {Translation().pages.delegate.accept.texts[1]}{' '}
                  <b>{order.delegate_amount_formatted}</b>{' '}
                  {Translation().pages.delegate.accept.tax_included}
                  {Translation().pages.delegate.accept.texts[2]}
                </p>
              )}
          </Col>
          <Clearfix />
          <Field
            name="payment_method"
            readOnly
            type="hidden"
            component="input"
            required
          />
          <div className="payment-container">
            <div id="frame-payment-loader" className="loader-api" />
            <div id="payment-frame-container" />
          </div>
          <div className="accept-terms">
            <label htmlFor="terms-of-sales">
              <Checkbox
                name="terms_of_sales"
                id="terms-of-sales"
                style={{
                  width: '30px',
                  display: 'inline-block',
                }}
                required
                onCheck={this.handleCheckTerms}
              />
              <span>
                {Translation().shop.payment.term_of_sales.read}{' '}
                <a onClick={this.handleOpenTerms}>
                  {Translation().shop.payment.term_of_sales.tos}
                </a>{' '}
                {Translation().shop.payment.term_of_sales.agree} *
              </span>
            </label>
          </div>
          <div className="submit-wrapper">
            <button
              className="app-button-color"
              type="submit"
              disabled={loading || !isTermsAccepted}
            >
              {loading && (
                <span
                  className="loader-api"
                  style={{ display: 'inline-block' }}
                />
              )}
              <span style={{ opacity: loading ? 0 : 1 }}>
                {Translation().pages.delegate.accept.submit}
              </span>
            </button>
          </div>
          <div className="text-left">
            <p className="required-field">
              <span>*</span> {Translation().shop.payment.required_fields}
            </p>
          </div>
        </div>
        <Dialog
          modal={false}
          actions={[
            <div onClick={this.handleCloseTerms} className="btn-tos-modal">
              {Translation().shop.payment.modal.close
                ? Translation().shop.payment.modal.close
                : 'Close'}
            </div>,
          ]}
          className="term-use-modal"
          autoScrollBodyContent={true}
          onRequestClose={this.handleCloseTerms}
          open={isTermsOpen}
        >
          <TermsOfUse isDialog={true} />
        </Dialog>
        <Snackbar
          messageText={snackbarMessage}
          statusClass={snackbarStatus}
          open={isSnackbarOpen}
          autoHideDuration={autoHideDuration}
          closeFunction={this.closeSnackbar}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'payment_delegate',
  validate,
})(PaymentMethodShortlink);
