import React, { Component } from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
  stopSubmit
} from 'redux-form';
import { subscribe } from 'redux-subscriber';
import { change } from 'redux-form';
import { Table } from 'react-bootstrap';
import { Toggle } from 'redux-form-material-ui';

import Snackbar from '../../commons/snackbar/Snackbar';
import store from '../../../redux/store';
import {
  setInvoiceUserAddress,
  editInvoiceUserAddress,
  deleteInvoiceUserAddress,
  initializeUser,
  getUser,
} from '../../../helpers/UserFunctions';
import GoogleAddressStringField from '../../commons/fields/GoogleAddressStringField';
import renderFieldLabel from '../../commons/fields/renderFieldLabel';
import { autoScrollTop, initPlaceholder } from '../../../helpers/JqueryFunctions';

import Translation from '../../../translation/Translation';

class InvoiceAddress extends Component {

  render() {

    const {
      dataAddress,
      openRemoveInvoiceAddress,
      openEditInvoiceAddress
    } = this.props;

    return (
      <tr>
        <td>{dataAddress.name && dataAddress.name}</td>
        <td width="60%">{(dataAddress.street_number ? dataAddress.street_number : '') + ' ' + ( dataAddress.street ? dataAddress.street : '')  + ' ' + (dataAddress.city ? dataAddress.city : '')  + ' ' + (dataAddress.zip_code ? dataAddress.zip_code : '')}</td>
        <td className="td-icons">
          <span className="action-address edit-address icon-editer" onClick={openEditInvoiceAddress.bind(this, dataAddress)} />
          <span className="action-address delete-address icon-delete" onClick={openRemoveInvoiceAddress.bind(this, dataAddress.id)} />
        </td>
      </tr>
    );
  }
}

class InvoicesAddresses extends Component {

  constructor(props) {
    super(props);

    this.state = {
      autoHideDuration: 4000,
      isSnackbarOpen: undefined,
      snackbarMessage: '',
      snackbarStatus: '',
      isInvoiceAddressToggled: false,
      isEditInvoiceAdrsOpen: false,
      isUserCompanyAdmin: false,
      isCompanyToggled: this.props.isUserCompanyAdmin,
      invoiceAddressValue: undefined,
      invoiceAddressName: undefined,
      invoiceAddresses: [],
      invoiceAddressId: null
    }

    this.subscribeCompanyToggle = undefined;
    this.subscribeUserAddresses = undefined;

    this.openSnackbar = this.openSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.openInvoiceAddressModal = this.openInvoiceAddressModal.bind(this);
    this.closeInvoiceAddressModal = this.closeInvoiceAddressModal.bind(this);
    this.openRemoveInvoiceAddress = this.openRemoveInvoiceAddress.bind(this);
    this.closeRemoveInvoiceAddress = this.closeRemoveInvoiceAddress.bind(this);
    this.addInvoiceAddress = this.addInvoiceAddress.bind(this);
    this.removeInvoiceAddress = this.removeInvoiceAddress.bind(this);
    this.openEditInvoiceAddress = this.openEditInvoiceAddress.bind(this);
    this.closeEditInvoiceAddress = this.closeEditInvoiceAddress.bind(this);
    this.editInvoiceAddress = this.editInvoiceAddress.bind(this);
  }

  componentDidMount() {
    /**
     * Get the current's) address(es) of the user
     */
    const user = getUser();

    if (user && user.company_admin) {
      this.setState({
        isUserCompanyAdmin: true,
        isCompanyToggled: true,
        invoiceAddresses: user.company && user.company.addresses ? user.company.addresses : []
      });
    }
    else {
      this.setState({
        isUserCompanyAdmin: false,
        isCompanyToggled: false,
        invoiceAddresses: user && user.addresses ? user.addresses : []
      });
    }

    /**
     * Get the new address submited if redux user.addresses changed
     */
    this.subscribeUserAddresses = subscribe('user.addresses', state => {
      if (state.user && state.user.addresses) {
        /**
         * Push new user invoices addresses from the user updated into state
         */
        const newUserAdressesInvoice = state.user.addresses;
        const newCompanyAdressesInvoice = state.user.company && state.user.company.addresses ? state.user.company.addresses : [];

        if (this.state.isCompanyToggled)
          this.setState({ invoiceAddresses: newCompanyAdressesInvoice });
        else
          this.setState({ invoiceAddresses: newUserAdressesInvoice });
      }
    });

    /**
     * Check user type address toggle changes
     */
    this.subscribeCompanyToggle = subscribe('form.editInvoiceAdressesAccount.values.companyToggle', state => {
      if (state && state.form && state.form.editInvoiceAdressesAccount && state.form.editInvoiceAdressesAccount.values && state.form.editInvoiceAdressesAccount.values.companyToggle) {
        const user = getUser();

        this.setState({
          isCompanyToggled: true,
          invoiceAddresses: user.company && user.company.addresses ? user.company.addresses : []
        });
      }
      else {
        const user = getUser();

        this.setState({
          isCompanyToggled: false,
          invoiceAddresses: user && user.addresses ? user.addresses : []
        });
      }
    });
  }

  openSnackbar() {
    this.setState({ isSnackbarOpen: true });
  };

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  };

  openInvoiceAddressModal() {
    autoScrollTop();

    this.setState({ isInvoiceAddressToggled: true, invoiceAddressValue: undefined, invoiceAddressName: undefined}, () => {
      /**
       * Clear inputs values
       */
      const inputNewAddress = document.getElementById('new-invoice-address');
      store.dispatch(change('editInvoiceAdressesAccount', 'newInvoiceAddressName', ''));
      store.dispatch(change('editInvoiceAdressesAccount', 'newInvoiceAddress', ''));

      initPlaceholder();

      if (inputNewAddress)
        inputNewAddress.focus();

    });
  }

  addInvoiceAddress() {
    const newAddressValue = this.state.invoiceAddressValue;
    const newAddressName = this.state.invoiceAddressName;

    const errorAddress = document.getElementById('err-new-inv-adrs');
    const loaderBtnAddress = document.getElementById('adrs-inv-btn-loader');
    const textBtnAddress = document.getElementById('adrs-inv-btn-txt');

    if (newAddressValue && newAddressName) {
      if (newAddressValue !== undefined && newAddressName !== undefined) {

        if (textBtnAddress && loaderBtnAddress) {
          textBtnAddress.style.display = 'none';
          loaderBtnAddress.style.display = 'inline-block';
        }

        setInvoiceUserAddress(
          this.state.isCompanyToggled,
          newAddressName,
          newAddressValue,
          (successData) => {
            initializeUser();

            if (textBtnAddress && loaderBtnAddress) {
              textBtnAddress.style.display = 'inline-block';
              loaderBtnAddress.style.display = 'none';
            }
            this.setState({ snackbarMessage: Translation().account.invoices.modals.invoice_addresses.create.form.callbacks.success, snackbarStatus: "success"});
            this.openSnackbar();
            this.closeInvoiceAddressModal();

          },
          (error) => {

            if (textBtnAddress && loaderBtnAddress) {
              textBtnAddress.style.display = 'inline-block';
              loaderBtnAddress.style.display = 'none';
            }

            if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
              const fieldsErrors = ["street_number", "street", "zip_code", "city", "country_isocode", "state_isocode", "latitude", "longitude"];
              const validationErrors = {};
              const errorsArray = error.response.data.errors;

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath === "address") {
                  validationErrors["newInvoiceAddress"] = errorsArray[i].message;
                }
                else if ((fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0)) {
                  validationErrors["newInvoiceAddress"] =  errorsArray[i].propertyPath + ": "+ errorsArray[i].message;
                }
                else if (errorsArray[i].propertyPath === "name") {
                  validationErrors["newInvoiceAddressName"] = errorsArray[i].message;
                }
                else
                  validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
              }

              store.dispatch(stopSubmit(
                'editInvoiceAdressesAccount',
                validationErrors
              ));
            }

            this.setState({ snackbarMessage: Translation().account.invoices.modals.invoice_addresses.create.form.callbacks.error, snackbarStatus: "error"});
            this.openSnackbar();
          }
        );
      }
    }
    else if (!newAddressValue) {
      if (errorAddress) {
        errorAddress.innerHTML = Translation().account.invoices.modals.invoice_addresses.create.form.validation.missing_address;
      }
    }
    else if (!newAddressName) {
      if (errorAddress) {
        errorAddress.innerHTML = Translation().account.invoices.modals.invoice_addresses.create.form.validation.missing_address_name;
      }
    }
    else {
      if (errorAddress) {
        errorAddress.innerHTML = Translation().account.invoices.modals.invoice_addresses.create.form.validation.missing_fields;
      }
    }
  }

  removeInvoiceAddress() {
    const removeInvoiceAdrsLoader = document.getElementById('confirm-ria-loader');
    const removeInvoiceAdrsTxt = document.getElementById('confirm-ria-txt');

    if (removeInvoiceAdrsLoader && removeInvoiceAdrsTxt) {
      removeInvoiceAdrsTxt.style.visibility = "hidden";
      removeInvoiceAdrsLoader.style.display = "block";
    }

    deleteInvoiceUserAddress(
      this.state.isCompanyToggled,
      this.state.invoiceAddressId,
      (success) => {
        initializeUser();

        if (removeInvoiceAdrsLoader && removeInvoiceAdrsTxt) {
          removeInvoiceAdrsTxt.style.visibility = "visible";
          removeInvoiceAdrsLoader.style.display = "none";
        }

        this.setState({ snackbarMessage: Translation().account.invoices.modals.invoice_addresses.delete.form.callbacks.success, snackbarStatus: "success" });
        this.openSnackbar();
        this.closeRemoveInvoiceAddress();
      },
      (error) => {
        const errorMessage = error && error.response && error.response.data && error.response.data.detail ? error.response.data.detail : Translation().account.invoices.modals.invoice_addresses.delete.form.callbacks.error;
        
        if (removeInvoiceAdrsLoader && removeInvoiceAdrsTxt) {
          removeInvoiceAdrsTxt.style.visibility = "visible";
          removeInvoiceAdrsLoader.style.display = "none";
        }
        this.setState({ snackbarMessage: errorMessage, snackbarStatus: "error" });
        this.openSnackbar();
      }
    );
  }

  editInvoiceAddress() {
    const state = store.getState();
    const selector = formValueSelector("editInvoiceAdressesAccount");
    const newAddressValue =  selector(state, 'editInvoiceAddress');
    const newAddressName =  selector(state, 'editInvoiceAddressName');

    const editInvoiceAdrsLoader = document.getElementById('confirm-eia-loader');
    const editInvoiceAdrsTxt = document.getElementById('confirm-eia-txt');

    const { isCompanyToggled, invoiceAddressId } = this.state;

    if (newAddressValue && newAddressName) {

      if (editInvoiceAdrsLoader && editInvoiceAdrsTxt) {
        editInvoiceAdrsTxt.style.visibility = "hidden";
        editInvoiceAdrsLoader.style.display = "block";
      }

      editInvoiceUserAddress(
        isCompanyToggled,
        invoiceAddressId,
        newAddressName,
        newAddressValue,
        (successData) => {
          initializeUser();

          if (editInvoiceAdrsLoader && editInvoiceAdrsTxt) {
            editInvoiceAdrsTxt.style.visibility = "visible";
            editInvoiceAdrsLoader.style.display = "none";
          }

          this.setState({ snackbarMessage: Translation().account.invoices.modals.invoice_addresses.edit.form.callbacks.success, snackbarStatus: "success"});
          this.openSnackbar();
          this.closeEditInvoiceAddress();
        },
        (error) => {
          if (editInvoiceAdrsLoader && editInvoiceAdrsTxt) {
            editInvoiceAdrsTxt.style.visibility = "visible";
            editInvoiceAdrsLoader.style.display = "none";
          }

          if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
            const fieldsErrors = ["street_number", "street", "zip_code", "city", "country_isocode", "state_isocode", "latitude", "longitude"];
            const validationErrors = {};
            const errorsArray = error.response.data.errors;

            for (let i = 0; i < errorsArray.length; i++) {
              if (errorsArray[i].propertyPath === "address") {
                validationErrors["editInvoiceAddress"] = errorsArray[i].message;
              }
              else if ((fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0)) {
                validationErrors["editInvoiceAddress"] =  errorsArray[i].propertyPath + ": "+ errorsArray[i].message;
              }
              else if (errorsArray[i].propertyPath === "name") {
                validationErrors["editInvoiceAddressName"] = errorsArray[i].message;
              }
              else
                validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
            }

            store.dispatch(stopSubmit(
              'editInvoiceAdressesAccount',
              validationErrors
            ));
          }

          this.setState({ snackbarMessage: Translation().account.invoices.modals.invoice_addresses.edit.form.callbacks.error, snackbarStatus: "error"});
          this.openSnackbar();
        }
      );
    }
  }

  closeInvoiceAddressModal() {
    this.setState({ isInvoiceAddressToggled: false });
  }

  openRemoveInvoiceAddress(invoiceAddressId) {
    this.setState({
      isRemoveInvoiceAdrsOpen: true,
      invoiceAddressId: invoiceAddressId
    });
  }

  closeRemoveInvoiceAddress() {
    this.setState({ isRemoveInvoiceAdrsOpen: false });
  }

  openEditInvoiceAddress(dataAddress) {
    this.setState({
      isEditInvoiceAdrsOpen: true,
      invoiceAddressId: dataAddress.id
    });

    /**
     * Init the form with address values
     */
    store.dispatch(change("editInvoiceAdressesAccount" , "editInvoiceAddressName", dataAddress.name ? dataAddress.name : ''));
    store.dispatch(change("editInvoiceAdressesAccount" , "editInvoiceAddress", (dataAddress.street_number ? dataAddress.street_number : '') + ' ' + ( dataAddress.street ? dataAddress.street : '')  + ' ' + (dataAddress.city ? dataAddress.city : '')  + ' ' + (dataAddress.zip_code ? dataAddress.zip_code : '')));
  }

  closeEditInvoiceAddress() {
    this.setState({ isEditInvoiceAdrsOpen: false });
    store.dispatch(change('editInvoiceAdressesAccount', 'editInvoiceAddressName', ''));
    store.dispatch(change('editInvoiceAdressesAccount', 'editInvoiceAddress', ''));
  }

  render() {
    const {
      isUserCompanyAdmin,
      isInvoiceAddressToggled,
      isCompanyToggled,
      isRemoveInvoiceAdrsOpen,
      isEditInvoiceAdrsOpen,
      invoiceAddresses
    } = this.state;

    /**
     * Toggle switch style
     */
    const toggleStyle = {
      backgroundColor: isCompanyToggled ? "#1a2123" : "#FFD700",
      zIndex: 1
    };

    return (
      <div className="invoices-addresses">
        <div className="addresses-header">
          <div className={"add-address " + (isCompanyToggled ? "company" : "")} onClick={this.openInvoiceAddressModal}>
            <span className="add-icon icon-ajout_adresse"/>
          </div>
        </div>
        { isRemoveInvoiceAdrsOpen &&
          <div className="app-overlay remove-iadrs-modal">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.closeRemoveInvoiceAddress} />
                  <h4 className="app-modal-title">{Translation().account.invoices.modals.invoice_addresses.delete.title},</h4>
                  <p className="app-modal-desc">{Translation().account.invoices.modals.invoice_addresses.delete.description}</p>
                  <div className="app-modal-content">
                    <div className="btn-wrapper">
                      <div className="app-button-color confirm" onClick={this.closeRemoveInvoiceAddress}>
                        <span className="confirm-icon icon-fermer left" /> {Translation().account.invoices.modals.invoice_addresses.delete.form.buttons.cancel}
                      </div>
                      <div className="app-button-color confirm" onClick={this.removeInvoiceAddress}>
                        <span id="confirm-ria-loader" className="loader-api" />
                        <span id="confirm-ria-txt">
                          <span>{Translation().account.invoices.modals.invoice_addresses.delete.form.buttons.submit}</span><span className="confirm-icon icon-check right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { isEditInvoiceAdrsOpen &&
          <div className="app-overlay remove-iadrs-modal">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.closeEditInvoiceAddress} />
                  <h4 className="app-modal-title">{Translation().account.invoices.modals.invoice_addresses.edit.title}</h4>
                  <p className="app-modal-desc">{Translation().account.invoices.modals.invoice_addresses.edit.description}</p>
                  <div className="app-modal-content">
                      <div className="edit-iadrs">
                      <label htmlFor="edit-invoice-address">{Translation().account.invoices.modals.invoice_addresses.edit.form.labels.new_address}</label>
                      <Field
                        name="editInvoiceAddress"
                        type="text"
                        id="edit-invoice-address"
                        label={Translation().account.invoices.modals.invoice_addresses.edit.form.labels.new_address}
                        className="app-basic-input"
                        component={GoogleAddressStringField}
                        onChange={
                          (value, address) => {
                            this.setState({ invoiceAddressValue: address });
                          }
                        }
                      />
                      <label style={{marginTop: "20px"}} htmlFor="edit-invoice-address-name">{Translation().account.invoices.modals.invoice_addresses.edit.form.labels.new_address_name}</label>
                      <Field
                        name="editInvoiceAddressName"
                        type="text"
                        id="edit-invoice-address-name"
                        placeholder={Translation().account.invoices.modals.invoice_addresses.edit.form.labels.new_address_name}
                        className="app-basic-input"
                        component={renderFieldLabel}
                        tabIndex="-1"
                        onChange={
                          (event, value) => {
                            this.setState({ invoiceAddressName: value });
                          }
                        }
                      />
                    </div>
                    <div className="btn-wrapper">
                      <div className="app-button-color confirm" onClick={this.closeEditInvoiceAddress}>
                        <span className="confirm-icon icon-fermer left" /> {Translation().account.invoices.modals.invoice_addresses.edit.form.buttons.cancel}
                      </div>
                      <div className="app-button-color confirm" onClick={this.editInvoiceAddress}>
                        <span id="confirm-eia-loader" className="loader-api" />
                        <span id="confirm-eia-txt">
                          <span>{Translation().account.invoices.modals.invoice_addresses.edit.form.buttons.submit}</span><span className="confirm-icon icon-check right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { isInvoiceAddressToggled &&
          <div className="app-overlay overlay-ina">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.closeInvoiceAddressModal} />
                  <h4 className="app-modal-title">{Translation().account.invoices.modals.invoice_addresses.create.title}</h4>
                  <p className="app-modal-desc">{Translation().account.invoices.modals.invoice_addresses.create.description}</p>
                  <p className="bold">{ isCompanyToggled ? Translation().account.invoices.modals.invoice_addresses.create.pro_address : Translation().account.invoices.modals.invoice_addresses.create.personal_address} </p>
                  <div className="app-modal-content">
                    <form>
                      <Field
                        name="newInvoiceAddress"
                        type="text"
                        id="new-invoice-address"
                        label={Translation().account.invoices.modals.invoice_addresses.create.form.labels.address}
                        className="app-basic-input"
                        component={GoogleAddressStringField}
                        onChange={
                          (value, address) => {
                            this.setState({ invoiceAddressValue: address });
                          }
                        }
                      />
                      <Field
                        name="newInvoiceAddressName"
                        type="text"
                        id="new-invoice-address-name"
                        placeholder={Translation().account.invoices.modals.invoice_addresses.create.form.labels.address_name}
                        className="app-basic-input"
                        component={renderFieldLabel}
                        tabIndex="-1"
                        onChange={
                          (event, value) => {
                            this.setState({ invoiceAddressName: value });
                          }
                        }
                      />
                      <div className="btn-wrapper">
                        <div className={"app-button-color " + (isCompanyToggled && "app-button-black")} onClick={this.addInvoiceAddress}>
                          <span id="adrs-inv-btn-loader" className="loader-api" />
                          <span id="adrs-inv-btn-txt">{Translation().account.invoices.modals.invoice_addresses.create.form.buttons.submit}</span>
                        </div>
                        <p id="err-new-inv-adrs" className="error-message" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { isUserCompanyAdmin &&
          <div className="select-user-type list">
            <span
              className={"text-ut " + (isCompanyToggled ? "" : "active")}
              onClick={() => { store.dispatch(change('editInvoiceAdressesAccount', 'companyToggle', '')); }}>
                {Translation().account.invoices.company_toggle.personal}
            </span>
            <Field
              type="checkbox"
              component={Toggle}
              className="toggle-ut"
              thumbStyle={toggleStyle}
              thumbSwitchedStyle={toggleStyle}
              name="companyToggle"
              onChange={() => { return false; }}
            />
            <span
              className={"text-ut right " + (isCompanyToggled ? "active" : "")}
              onClick={() => { store.dispatch(change('editInvoiceAdressesAccount', 'companyToggle', true)); }}>
                {Translation().account.invoices.company_toggle.pro}
            </span>
          </div>
        }
        { invoiceAddresses && invoiceAddresses.length > 0
          ? (
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>{Translation().account.invoices.tables.invoice_addresses.name}</th>
                    <th>{Translation().account.invoices.tables.invoice_addresses.address}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { invoiceAddresses && invoiceAddresses.length > 0 && invoiceAddresses.map((address, index) => {
                      return (
                        <InvoiceAddress
                          key={index}
                          dataAddress={address}
                          openRemoveInvoiceAddress={this.openRemoveInvoiceAddress}
                          openEditInvoiceAddress={this.openEditInvoiceAddress}
                        />
                      );
                    })
                  }
                </tbody>
              </Table>
            )
          : (
              <div className="no-address">
                <p>{Translation().account.invoices.tables.invoice_addresses.no_invoice_address}</p>
              </div>
            )
        }
        <Snackbar
          messageText={this.state.snackbarMessage}
          statusClass={this.state.snackbarStatus}
          open={this.state.isSnackbarOpen}
          autoHideDuration={this.state.autoHideDuration}
          closeFunction={this.closeSnackbar}
        />
      </div>
    );
  }

  componentWillUnmount() {
    /**
     * Unsubscribe the toggle company
     * Unsubscribe the user addresses changes
     */
    this.subscribeCompanyToggle();
    this.subscribeUserAddresses();
  }
}

export default reduxForm({
  form: 'editInvoiceAdressesAccount',
})(InvoicesAddresses);
