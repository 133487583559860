import React, { Component } from 'react';

import Autocomplete from 'react-google-autocomplete';

import { getResidenceCountry } from '../../../helpers/UserFunctions';
import Config from '../../../config/Config';

class GoogleAddressObjectField extends Component {

  render() {

    const {
      input,
      name,
      label,
      className,
      id,
      onSelect,
      style,
      onInputChange,
      autoFocus,
      required
    } = this.props;

    const inputProps = {
      onChange: input.onChange,
      onBlur: input.onBlur,
      onFocus: input.onFocus,
      type: "text",
      value: input.value,
      autoFocus: autoFocus,
      placeholder: label,
      name: name,
      id: id,
      required: required,
    }

    return (
      <Autocomplete
        {...inputProps}
        style={style}
        className={className}
        onPlaceSelected={(place) => {
          onSelect && onSelect(place);
          onInputChange && onInputChange(place.formatted_address);
        }}
        types={getResidenceCountry().indexOf(Config.countries_blacklist) === -1 ? ['address'] : ['establishment', 'geocode']}
      />
    )
  }
}

export default GoogleAddressObjectField;
