import React, { Component } from 'react';
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Col,
  Clearfix,
} from 'react-bootstrap';
import { subscribe } from 'redux-subscriber';

import Config from '../../../config/Config';
import {
  logoutUser,
  getLocale,
  getBrandPhoneNumber,
  selectLang,
} from '../../../helpers/UserFunctions';
import { unregisterDevice } from '../../../helpers/PushNotificationsFunctions';
import RegisterView from '../register/RegisterView';
import LoginView from '../login/LoginView';
import LanguageSelector from './LanguageSelector';

import Translation from '../../../translation/Translation';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brandPhoneNumber: null,
      isDropdownOpen: false,
      isLoginOpen: false,
      isRegisterOpen: false,
      isFullRegisterOpen: false,
      isAnimeDone: false,
      isOverlayOpen: false,
      isLangOpen: false,
      locale: Config.default_locale,
      isLoggedIn: false,
      isUserOpen: false,
      userName: '',
      isCompanyAdmin: false,
      nbCartItems: 0,
    };

    this.handleClickDrop = this.handleClickDrop.bind(this);
    this.handleClickLog = this.handleClickLog.bind(this);
    this.handleClickRegister = this.handleClickRegister.bind(this);
    this.openRegister = this.openRegister.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.closeRegister = this.closeRegister.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.openLanguage = this.openLanguage.bind(this);
    this.closeLanguage = this.closeLanguage.bind(this);
    this.handleUserloggedIn = this.handleUserloggedIn.bind(this);
    this.toggleUser = this.toggleUser.bind(this);
    this.closeUser = this.closeUser.bind(this);
    this.selectLang = this.selectLang.bind(this);
  }

  componentDidMount() {
    /**
     * Check if user exist in redux state, if yes we update header state who will re-render
     * If user is logged in, change the login link to username in header
     * Else not, remove the username in header and refresh page location to home
     */
    // eslint-disable-next-line
    const unsubscribeUser = subscribe('user', (state) => {
      if (state.user) {
        this.handleUserloggedIn();
        this.setState({ userName: state.user.first_name });
      } else {
        this.setState({ isLoggedIn: false });
        window.location = '/';
      }

      /**
       * Check if the user is a company admin
       */
      if (state.user && state.user.company_admin)
        this.setState({ isCompanyAdmin: true });
    });

    /**
     * Check how many items are in cart
     */
    // eslint-disable-next-line
    const subscribeCartProducts = subscribe('userCart.products', (state) => {
      if (state.userCart) {
        const userCart = state.userCart;
        this.setState({
          nbCartItems:
            userCart && userCart.products && userCart.products.length
              ? userCart.products.length
              : 0,
        });
      } else this.setState({ nbCartItems: 0 });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.geoSettings && nextProps.geoSettings.brand_phone_number) {
      this.setState({
        brandPhoneNumber: nextProps.geoSettings.brand_phone_number,
      });
    } else {
      this.setState({ brandPhoneNumber: getBrandPhoneNumber() });
    }

    if (nextProps.geoSettings && nextProps.geoSettings.locale) {
      this.setState({ locale: nextProps.geoSettings.locale });
    } else {
      this.setState({ locale: getLocale() });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const nbItemsWrapper = document.getElementsByClassName('nb-items');

    /**
     * Add animation to cart item count in header in case of nb product change
     */
    if (nbItemsWrapper) {
      for (let i = 0; i < nbItemsWrapper.length; i++) {
        if (prevState.nbCartItems !== this.state.nbCartItems) {
          nbItemsWrapper[i].classList.add('flipIn');
          setTimeout(() => {
            nbItemsWrapper[i].classList.remove('flipIn');
          }, 1800);
        }
      }
    }
  }

  handleUserloggedIn() {
    this.setState({
      isLoggedIn: true,
      isDropdownOpen: false,
      isOverlayOpen: false,
    });
  }

  handleClickDrop() {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
      isFullRegisterOpen: false,
      isAnimeDone: false,
      isOverlayOpen: !this.state.isOverlayOpen,
    });
  }

  handleClickLog() {
    this.setState({ isLoginOpen: !this.state.isLoginOpen });

    if (this.state.isRegisterOpen) {
      this.setState({ isRegisterOpen: false });
    }
  }

  handleClickRegister() {
    this.setState({ isRegisterOpen: !this.state.isRegisterOpen });

    if (this.state.isLoginOpen) {
      this.setState({ isLoginOpen: false });
    }
  }

  openRegister() {
    this.setState({ isFullRegisterOpen: true });

    setTimeout(
      function () {
        this.setState({ isAnimeDone: true });
      }.bind(this),
      1200
    );
  }

  closeRegister() {
    this.setState({ isFullRegisterOpen: false, isAnimeDone: false });
  }

  handleClickClose() {
    this.setState({
      isDropdownOpen: false,
      isLoginOpen: false,
      isRegisterOpen: false,
      isFullRegisterOpen: false,
      isAnimeDone: false,
      isOverlayOpen: false,
    });
  }

  toggleLanguage() {
    this.setState({ isLangOpen: !this.state.isLangOpen });
  }

  openLanguage() {
    this.setState({ isLangOpen: true });
  }

  closeLanguage() {
    this.setState({ isLangOpen: false });
  }

  toggleUser() {
    this.setState({ isUserOpen: !this.state.isUserOpen });
  }

  closeUser() {
    this.setState({ isUserOpen: false });
  }

  selectLang(locale) {
    this.setState({ locale, isLangOpen: false }, selectLang(locale));
  }

  renderEnvironmentLabel() {
    const labelStyle = {
      backgroundColor: '#42a5f5',
      borderRadius: 50,
      padding: '4px 12px',
      lineHeight: 1,
      fontSize: 10,
      color: 'white',
      marginLeft: 4,
      display: 'inline-block',
      verticalAlign: 'middle',
      position: 'absolute',
      top: 4,
    };

    if (Config.api_env === 'dev') {
      return <div style={labelStyle}>{Config.api_env}</div>;
    } else if (Config.api_env === 'sandbox') {
      return <div style={labelStyle}>{Config.api_env}</div>;
    } else if (Config.api_env === 'local') {
      return <div style={labelStyle}>{Config.api_env}</div>;
    } else {
      return false;
    }
  }

  render() {
    const {
      brandPhoneNumber,
      isDropdownOpen,
      isAnimeDone,
      isFullRegisterOpen,
      isOverlayOpen,
      isLoggedIn,
      isCompanyAdmin,
      isUserOpen,
      nbCartItems,
      isLangOpen,
      locale,
      userName,
    } = this.state;

    return (
      <header className="app-header">
        <Navbar id="navbar" role="navigation" fluid fixedTop>
          <Navbar.Toggle />
          <Navbar.Header>
            <Navbar.Brand>
              <a href="/">
                <img
                  className="app-logo"
                  src="/assets/images/commons/app-logo-header.svg"
                  alt={Config.app_name}
                />
              </a>
              <a href="/">
                <img
                  className="app-logo mobile"
                  src="/assets/images/commons/app-logo-sm.svg"
                  alt={Config.app_name}
                />
              </a>
              {this.renderEnvironmentLabel()}
              {brandPhoneNumber && (
                <a
                  href={'tel:' + brandPhoneNumber}
                  className="menu-icon menu-phone"
                >
                  <span className="icon-telephone" />{' '}
                  <span className="text-telephone">{brandPhoneNumber}</span>
                </a>
              )}
            </Navbar.Brand>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavDropdown
                eventKey={1}
                title={Translation().header.app_nav.services}
                className="dropdown-desktop dropdown-services"
                id="dropdown-servicse-desktop"
              >
                <div className="container">
                  <MenuItem eventKey={1.1} href="/pro-pack">
                    <div
                      className="menu-item-cover"
                      id="pro-packs-item-cover"
                    />
                    <div className="menu-icon-wpr">
                      <div className="icon-packs menu-item-icon" />
                      <p className="menu-item-title">
                        {Translation().header.app_nav.products.pro_packs.title}
                      </p>
                      <p className="menu-item-desc">
                        {
                          Translation().header.app_nav.products.pro_packs
                            .description
                        }
                      </p>
                    </div>
                    <div className="clearfix" />
                  </MenuItem>
                  <MenuItem eventKey={1.2} href="/scan-pack">
                    <div
                      className="menu-item-cover"
                      id="scan-packs-item-cover"
                    />
                    <div className="menu-icon-wpr">
                      <div className="icon-pack_san menu-item-icon" />
                      <p className="menu-item-title">
                        {Translation().header.app_nav.products.scan_packs.title}
                      </p>
                      <p className="menu-item-desc">
                        {
                          Translation().header.app_nav.products.scan_packs
                            .description
                        }
                      </p>
                    </div>
                    <div className="clearfix" />
                  </MenuItem>
                  <MenuItem eventKey={1.3} href="/photography">
                    <div
                      className="menu-item-cover"
                      id="shootings-item-cover"
                    />
                    <div className="menu-icon-wpr">
                      <div className="icon-photo menu-item-icon" />
                      <p className="menu-item-title">
                        {Translation().header.app_nav.products.photoshoot.title}
                      </p>
                      <p className="menu-item-desc">
                        {
                          Translation().header.app_nav.products.photoshoot
                            .description
                        }
                      </p>
                    </div>
                    <div className="clearfix" />
                  </MenuItem>
                  <MenuItem eventKey={1.4} href="/floorplan">
                    <div
                      className="menu-item-cover"
                      id="floor-plans-item-cover"
                    />
                    <div className="menu-icon-wpr">
                      <div className="icon-plan menu-item-icon" />
                      <p className="menu-item-title">
                        {Translation().header.app_nav.products.plans.title}
                      </p>
                      <p className="menu-item-desc">
                        {
                          Translation().header.app_nav.products.plans
                            .description
                        }
                      </p>
                    </div>
                    <div className="clearfix" />
                  </MenuItem>
                  <MenuItem eventKey={1.5} href="/virtual-tour">
                    <div className="menu-item-cover" id="vv-item-cover" />
                    <div className="menu-icon-wpr">
                      <div className="icon-visite_virtuelle menu-item-icon" />
                      <p className="menu-item-title">
                        {
                          Translation().header.app_nav.products.virtual_visit
                            .title
                        }
                      </p>
                      <p className="menu-item-desc">
                        {
                          Translation().header.app_nav.products.virtual_visit
                            .description
                        }
                      </p>
                    </div>
                    <div className="clearfix" />
                  </MenuItem>
                  <MenuItem eventKey={1.6} href="/certificates">
                    <div className="menu-item-cover" id="diags-item-cover" />
                    <div className="menu-icon-wpr">
                      <div className="icon-diagnostic menu-item-icon" />
                      <p className="menu-item-title">
                        {Translation().header.app_nav.products.diagnostic.title}
                      </p>
                      <p className="menu-item-desc">
                        {
                          Translation().header.app_nav.products.diagnostic
                            .description
                        }
                      </p>
                    </div>
                    <div className="clearfix" />
                  </MenuItem>
                  <MenuItem eventKey={1.7} href="/drone-video">
                    <div
                      className="menu-item-cover"
                      id="video-drone-item-cover"
                    />
                    <div className="menu-icon-wpr">
                      <div className="icon-videodrone menu-item-icon" />
                      <p className="menu-item-title">
                        {
                          Translation().header.app_nav.products.video_drone
                            .title
                        }
                      </p>
                      <p className="menu-item-desc">
                        {
                          Translation().header.app_nav.products.video_drone
                            .description
                        }
                      </p>
                    </div>
                    <div className="clearfix" />
                  </MenuItem>
                  <MenuItem eventKey={1.8} href="/home-staging">
                    <div
                      className="menu-item-cover"
                      id="home-staging-item-cover"
                    />
                    <div className="menu-icon-wpr">
                      <img
                        className="menu-item-icon"
                        src="assets/images/home_staging/virtual-home-staging.png"
                        width={46}
                        alt=""
                      />
                      <p className="menu-item-title">
                        {/* {
                          Translation().header.app_nav.products.video_drone
                            .title
                        } */}
                        Home Staging
                      </p>
                      <p className="menu-item-desc">
                        {/* {
                          Translation().header.app_nav.products.video_drone
                            .description
                        } */}
                        Révélez le potentiel d'un bien en réalisant un home
                        staging d'une ou plusieurs pièces
                      </p>
                    </div>
                    <div className="clearfix" />
                  </MenuItem>
                  <MenuItem eventKey={1.9} className="inactive" />
                </div>
              </NavDropdown>
              <NavDropdown
                eventKey={1}
                title={Translation().header.app_nav.services}
                id="dropdown-services-responsive"
                className="dropdown-responsive"
              >
                <MenuItem eventKey={1.1} href="/pro-pack">
                  {Translation().header.app_nav.products.pro_packs.title}
                </MenuItem>
                <MenuItem eventKey={1.2} href="/scan-pack">
                  {Translation().header.app_nav.products.scan_packs.title}
                </MenuItem>
                <MenuItem eventKey={1.3} href="/photography">
                  {Translation().header.app_nav.products.photoshoot.title}
                </MenuItem>
                <MenuItem eventKey={1.4} href="/floorplan">
                  {Translation().header.app_nav.products.plans.title}
                </MenuItem>
                <MenuItem eventKey={1.5} href="/virtual-tour">
                  {Translation().header.app_nav.products.virtual_visit.title}
                </MenuItem>
                <MenuItem eventKey={1.6} href="/drone-video">
                  {Translation().header.app_nav.products.video_drone.title}
                </MenuItem>
                <MenuItem eventKey={1.7} href="/certificates">
                  {Translation().header.app_nav.products.diagnostic.title}
                </MenuItem>
                <MenuItem eventKey={1.8} href="/home-staging">
                  {/* {Translation().header.app_nav.products.diagnostic.title} */}
                  Home staging
                </MenuItem>
              </NavDropdown>
              <NavItem eventKey={2} href="/how-it-works">
                {Translation().header.app_nav.how_it_work}
              </NavItem>
              <NavItem eventKey={3} href="/gallery">
                {Translation().header.app_nav.gallery}
              </NavItem>
              <NavItem eventKey={4} href="/shop" className="btn-order-mobile">
                {Translation().header.app_nav.order}
              </NavItem>
              {brandPhoneNumber && (
                <NavItem
                  eventKey={5}
                  href={'tel:' + brandPhoneNumber}
                  className="menu-icon menu-phone mobile"
                >
                  <span className="icon-telephone" />{' '}
                  <span className="text-telephone">{brandPhoneNumber}</span>
                </NavItem>
              )}
              {!isLoggedIn && (
                <NavItem eventKey={6} className="select-lang" tabIndex={0}>
                  <div className="lang-selector open">
                    <img
                      src={
                        '/assets/images/commons/languages/flag-' +
                        locale +
                        '.png'
                      }
                      width={16}
                      alt={locale}
                    />
                  </div>
                  {Config.open_countries_lang &&
                  Config.open_countries_lang.length ? (
                    <LanguageSelector
                      selected={locale}
                      languages={Config.open_countries_lang}
                      selectLang={this.selectLang}
                    />
                  ) : (
                    ''
                  )}
                </NavItem>
              )}
            </Nav>
          </Navbar.Collapse>
          <Nav pullRight>
            <li role="presentation" className="menu-icon">
              {isLoggedIn ? (
                <p
                  className={
                    'nav-user-login user-logged-in ' +
                    (isUserOpen ? 'open' : '')
                  }
                  onClick={this.toggleUser}
                >
                  <span className="icon-connecte" />
                  <span className="user-name">{userName}</span>
                  <span className="icon-chevron_bas" />
                  {isUserOpen && (
                    <ul className="dropdown-user">
                      <div className="dropdown-pointer" />
                      <li>
                        <a className="user-menuitem" href="/orders">
                          <span className="link-icon icon-panier" />{' '}
                          <span className="link-text">
                            {Translation().header.user_nav.orders}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="user-menuitem" href="/profile">
                          <span className="link-icon icon-connecte" />{' '}
                          <span className="link-text">
                            {Translation().header.user_nav.profile}
                          </span>
                        </a>
                      </li>
                      {isCompanyAdmin && (
                        <li>
                          <a className="user-menuitem" href="/company">
                            <span className="link-icon icon-pro" />{' '}
                            <span className="link-text">
                              {Translation().header.user_nav.company}
                            </span>
                          </a>
                        </li>
                      )}
                      <li>
                        <a className="user-menuitem" href="/invoices">
                          <span className="link-icon icon-facture" />{' '}
                          <span className="link-text">
                            {Translation().header.user_nav.invoices}
                          </span>
                        </a>
                      </li>
                      <li
                        className="logout-zone"
                        onClick={() => {
                          unregisterDevice(logoutUser, logoutUser);
                        }}
                      >
                        <span className="link-icon icon-deconnexion" />{' '}
                        <span className="link-text">
                          {Translation().header.user_nav.logout}
                        </span>
                      </li>
                    </ul>
                  )}
                </p>
              ) : (
                <p className="nav-user-login" onClick={this.handleClickDrop}>
                  <span className="icon-connecte" />
                  <span className="user-name">
                    {Translation().header.user_nav.sign_up}
                  </span>
                </p>
              )}
            </li>
            <NavItem
              eventKey={1}
              href={'/shop' + (nbCartItems && nbCartItems > 0 ? '/4' : '')}
              className="menu-icon header-cart desktop"
            >
              <span className="icon-panier">
                <div className="nb-items">
                  <span className="count">{nbCartItems}</span>
                </div>
              </span>
            </NavItem>
            <NavItem
              eventKey={2}
              href="/cart"
              className="menu-icon header-cart mobile"
            >
              <span className="icon-panier">
                <div className="nb-items">
                  <span className="count">{nbCartItems}</span>
                </div>
              </span>
            </NavItem>
            <NavItem
              eventKey={3}
              href="/shop"
              className="btn-order btn-order-dekstop"
            >
              {Translation().header.app_nav.order}
            </NavItem>
            {!isLoggedIn && (
              <NavItem
                eventKey={4}
                className="select-lang"
                tabIndex={0}
                onClick={this.openLanguage}
                onBlur={this.closeLanguage}
              >
                <div
                  className={'lang-selector ' + (isLangOpen ? 'open' : '')}
                  onClick={this.toggleLanguage}
                >
                  {Config.open_locales.indexOf(locale) >= 0 ? (
                    <img
                      src={
                        '/assets/images/commons/languages/flag-' +
                        locale +
                        '.png'
                      }
                      width={16}
                      alt={locale}
                    />
                  ) : (
                    <img
                      src={
                        '/assets/images/commons/languages/flag-' +
                        Config.default_locale +
                        '.png'
                      }
                      width={16}
                      alt={Config.default_locale}
                    />
                  )}
                </div>
                {isLangOpen &&
                Config.open_countries_lang &&
                Config.open_countries_lang.length ? (
                  <LanguageSelector
                    selected={locale}
                    languages={Config.open_countries_lang}
                    selectLang={this.selectLang}
                  />
                ) : (
                  ''
                )}
              </NavItem>
            )}
          </Nav>
        </Navbar>
        {isDropdownOpen ? (
          <div>
            <div className="dropdown-login" id="dropdownLogin">
              <Col
                xs={12}
                sm={12}
                md={6}
                className={
                  'col-register ' + (isFullRegisterOpen ? 'slide-left' : '')
                }
                onClick={this.handleClickLog}
              >
                <div className="toggle-desktop">
                  <h4>{Translation().header.login_dropdown.register.title}</h4>
                  <p className="register-baseline">
                    {Translation().header.login_dropdown.register.description}
                  </p>
                  <div
                    onClick={this.openRegister}
                    className="app-button-color app-button-dark"
                  >
                    {Translation().header.login_dropdown.register.button}
                  </div>
                  {/*
                      <div className="social-ntw-register">
                        <p className="or-log-with">Ou enregistrez-vous avec :</p>
                        <a href="#logWithFB" className="button-social button-fb"><span className="icon-facebook"></span> Facebook</a>
                        <a href="#logWithtwtr" className="button-social button-twitter"><span className="icon-twitter"></span> Twitter</a>
                        <a href="#logWithGoogle" className="button-social button-gplus"><span className="icon-google_plus"></span> Google +</a>
                      </div>
                    */}
                </div>
                <div className="toggle-mobile">
                  <a
                    href="/register"
                    className="app-button-color app-button-dark"
                  >
                    {Translation().header.login_dropdown.register.button}
                  </a>
                  {/*
                      <div className="social-ntw-register">
                        <p className="or-log-with">Ou enregistrez-vous avec :</p>
                        <a href="#logWithFB" className="button-social button-fb"><span className="icon-facebook"></span> Facebook</a>
                        <a href="#logWithtwtr" className="button-social button-twitter"><span className="icon-twitter"></span> Twitter</a>
                        <a href="#logWithGoogle" className="button-social button-gplus"><span className="icon-google_plus"></span> Google +</a>
                      </div>
                    */}
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                className={
                  'col-login ' + (isFullRegisterOpen ? 'slide-right' : '')
                }
                onClick={this.handleClickRegister}
              >
                <div className="toggle-desktop">
                  <h4>{Translation().header.login_dropdown.login.title}</h4>
                  <p className="login-baseline">
                    {Translation().header.login_dropdown.login.description}
                  </p>
                  <LoginView />
                </div>
                <div className="toggle-mobile">
                  <a href="/login" className="app-button-color app-button-dark">
                    {Translation().header.login_dropdown.login.button}
                  </a>
                </div>
                <div
                  className="icon-fermer"
                  onClick={this.handleClickClose}
                ></div>
              </Col>
              <Clearfix />
            </div>
            <div
              className={
                'dropdown-register ' + (isAnimeDone ? 'change-index' : '')
              }
              id="dropdownRegister"
            >
              <div className="register-view">
                <div className="container">
                  <div className="row">
                    <RegisterView />
                  </div>
                </div>
              </div>
              <div
                className="icon-fermer"
                onClick={this.handleClickClose}
              ></div>
            </div>
          </div>
        ) : (
          ''
        )}
        {isOverlayOpen && <div className="app-overlay" />}
      </header>
    );
  }
}

export default Header;
