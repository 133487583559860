import React, { Component } from 'react';

class PrivacyPolicyEN extends Component {
  render () {
    return (
      <div className="app-terms-of-use">
        <div className="articles">
          <div className="article-content" id="introduction">
            <p>
              For Wall-Market, your privacy is essential. This policy is designed to help you understand how we collect and process your personal data in connection with the provision of our services, your use of our website <a href="https://lightshop.wall-market.com" target="_blank" rel="noopener noreferrer">lightshop.wall-market.com</a> and Lightshop applications, and what your rights are.
            </p>
            <ul>
              <li>1. WHO IS RESPONSIBLE FOR PROCESSING YOUR DATA?</li>
              <li>2. WHAT DATA DO WE COLLECT?</li>
              <li>3. WHY DO WE COLLECT YOUR DATA AND HOW LONG DO WE KEEP IT?</li>
              <li>4. WHO DO WE SHARE YOUR DATA WITH?</li>
              <li>5. WHAT ARE YOUR RIGHTS AND HOW CAN YOU EXERCISE THEM?</li>
              <li>6. CONTACT</li>
              <li>7. CHANGES TO THE PRIVACY POLICY</li>
            </ul>
          </div>
          <div className="article-content">
            <h2>1. WHO IS RESPONSIBLE FOR PROCESSING YOUR DATA?</h2>
            <p>
              Wall-Market, a company registered under the number 532 727 740 RCS Paris, and whose registered office is located at 22 rue Legendre, 75017 Paris, France, is responsible for processing your data.  
            </p>
          </div>
          <div className="article-content with-list">
            <h2>2. WHAT DATA DO WE COLLECT?</h2>
            <p>We collect the following personal data:</p>
            <ul>
              <li>
                <span className="bold">The data you provide us:</span> when you register on our website or our application to access our services or update your account, or when you contact us (in particular via our contact form, email, telephone or social networks), we collect the personal information you provide us with. For example, when you create an account you may be asked to provide us with your full name, email address, postal address (country of residence if different), mobile and/or landline telephone number, date of birth, nationality, credit card or bank account information, language(s) spoken and, if applicable, picture. 
                <br /><br />
                In all cases, we will only ask you for the information we really need. You are free to choose whether or not to provide it, but if you refuse, you will not be able to use our services. 
                <br /><br />
              </li>
              <li>
                <span className="bold">Transaction data:</span> we collect transaction data associated with the use of our services, including the type of service ordered or provided, the details of the order, the time and date the service was performed, the amount charged and the method of payment.
                <br /><br />
              </li>
              <li>
                <span className="bold">Usage data:</span> we collect data about users' interaction with our services. This includes data such as access dates and times, application features or pages used, application crashes and other system-related activities, and browser type. We collect this data automatically through the use of cookies. To learn more about these technologies, please read our <a href="javascript:Didomi.preferences.show()">Cookie Policy</a>. 
                <br /><br />
              </li>
              <li>
                <span className="bold">Geolocation:</span> we collect your geolocation, in particular to enable the organisation and monitoring of missions. We collect this data with your consent. You can deactivate geolocation at any time in the application settings. Geolocation is optional, but it significantly improves the functioning of our services and greatly reduces the problems of matching.  
                <br /><br />
              </li>
            </ul>
            <p>
            Our services are not intended for minors and are not intended to be used by minors. We do not knowingly collect information from minors and we do not ask them for any information. We do not knowingly allow minors to use our services. Anyone providing information to Wall-Market through the new customer login page, registration page or any other part of our site or application represents that they are of legal age of majority in their country of residence. If we discover that we have collected information from a minor without their parent's or legal guardian's permission, we will delete that information. Please contact us at the contact information set forth in Section <a href="#privacy-policy-contact">« Contact »</a> below if you believe that you have provided us with information about a minor without the permission of his or her parent or legal guardian(s).
            </p>
          </div>
          <div className="article-content">
            <h2>3. WHY DO WE COLLECT YOUR DATA AND HOW LONG DO WE KEEP IT?</h2>
            <p>
              We process your data for defined purposes, based on a legal "justification" (or legal basis), and for a limited period of time. The table below details the purposes and legal basis for our processing and the applicable retention periods. 
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Goals</th>
                  <th style={{textAlign: 'center'}}>Legal basis</th>
                  <th style={{textAlign: 'center'}}>Shelf life</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Management of your user account (creation and update)
                  </td>
                  <td>
                    This processing is necessary for the execution of the contract concluded between you and Wall-Market.
                  </td>
                  <td>
                    As long as you keep your account <br/><br/> If you are inactive for a period of 5 years, your account will be deleted automatically. 
                  </td>
                </tr>
                <tr>
                  <td>
                    Order management (service delivery, relationship management and statistical analysis)
                  </td>
                  <td>
                    This processing is necessary for the execution of the contract concluded between you and Wall-Market.
                  </td>
                  <td>
                    We also keep a history of all your orders over the last 5 years for customer tracking and statistical analysis.  
                  </td>
                </tr>
                <tr>
                  <td>
                    Geolocation (proposal of missions in the vicinity, follow-up of the mission and management of disputes)
                  </td>
                  <td>
                    This processing is carried out with your consent.
                  </td>
                  <td>
                    48 hours. Nevertheless, the data may be kept for a longer period in the event of a dispute over the services provided, until the dispute is settled.
                  </td>
                </tr>
                <tr>
                  <td>User evaluation</td>
                  <td>This processing is necessary to pursue our legitimate interest in ensuring and improving the quality of the services</td>
                  <td>As long as you have an active account, plus 5 years</td>
                </tr>
                <tr>
                  <td>Processing of requests received by any means (contact form, email, telephone)</td>
                  <td>This processing is carried out with your consent.</td>
                  <td>Time needed to process your request</td>
                </tr>
                <tr>
                  <td>Sending communications to independent partner providers</td>
                  <td>This processing is necessary to pursue our legitimate interest in animating our community of independent service providers and keeping them informed of Wall-Market news. </td>
                  <td>As long as the independent contractor has an active account or until he unsubscribes from receiving these communications.</td>
                </tr>
                <tr>
                  <td>Sending marketing emails (commercial offers, newsletters, etc.)</td>
                  <td>This processing is necessary to pursue our legitimate interest in developing our business.  </td>
                  <td>Until you opt out of receiving our marketing emails or 3 years from the last contact</td>
                </tr>
                <tr>
                  <td>Accounting, tax, etc. obligations</td>
                  <td>This processing is necessary to comply with a legal obligation to which we are subject.</td>
                  <td>For the applicable legal retention period</td>
                </tr>
                <tr>
                  <td>Management of pre-litigation and litigation</td>
                  <td>This processing is necessary for the pursuit of our legitimate interest in establishing proof of a right or a contract, and the defence of our rights.</td>
                  <td>For the duration of the related limitation period. As a rule, we keep the data for 6 years.</td>
                </tr>
                <tr>
                  <td>Management of requests to exercise rights</td>
                  <td>This processing is necessary to comply with a legal obligation to which we are subject. </td>
                  <td>5 years from the end of the calendar year of your application.<br/><br/>
                    If a copy of your identity document is requested, it is kept for 1 year if your request is to exercise your rights of access or rectification and for 3 years if you exercise your right to object or delete.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Please note that your data may be kept for a longer period of time, for example in order to establish proof of a right or to comply with a legal obligation. In all cases, your data will not be kept beyond what is strictly necessary for the purposes for which it is processed. When your data is no longer required, we will ensure that it is either deleted or anonymised.
            </p>
          </div>
          <div className="article-content with-list">
            <h2>4. WHO DO WE SHARE YOUR DATA WITH?</h2>
            <p>
              We share your data only with:
            </p>
            <ul>
              <li>other users only to enable the completion of assignments,</li>
              <li>duly authorised persons of Wall-Market, who need access to your data in the course of their duties,</li>
              <li>our subcontractors, who process your data on behalf of and for Wall-Market (including our hosting and maintenance providers, analytics and payment services), </li>
              <li>our external advisors (lawyers, auditors, etc.), where this is necessary for the legitimate interests pursued by Wall-Market,</li>
              <li>public or judicial authorities, where we are legally obliged to do so.</li>
            </ul>
            <p>We may also share your personal data with third parties in connection with a potential or actual sale or restructuring of our company or certain of our assets, in which case your data may become part of the transferred assets.</p>
            <p>Your personal data may be transferred outside the European Economic Area (EEA) to countries that do not provide an equivalent level of protection for your data as you enjoy within the EEA. In the absence of an adequacy decision by the European Commission under Article 45 of the GDPR, the transfer of your personal data will be governed by appropriate transfer mechanisms under Article 46 et seq of the GDPR (such as standard contractual clauses adopted by the European Commission). You can obtain a copy of these (excluding confidential provisions) by contacting us using the contact details set out in Article CONTACT <a href="#privacy-policy-contact">« Contact »</a>. </p>
          </div>
          <div className="article-content with-list">
            <h2>5. WHAT ARE YOUR RIGHTS AND HOW CAN YOU EXERCISE THEM?</h2>
            <p>
              You have a number of rights over your data. 
            </p>
            <ul>
              <li>You may revoke your consent to any processing of your data based on your consent at any time. </li>
              <li>You can ask us to confirm whether we are processing your data and, if so, to inform you of the characteristics of the processing, give you access to it and obtain a copy. </li>
              <li>You can ask us to correct or complete your data if it is incorrect or incomplete.</li>
              <li>In some cases, you can ask us to delete your data or to limit the processing of your data. </li>
              <li>You may ask us, in some cases, to provide your data in a structured, commonly used and machine-readable format, or you may request that it be transmitted directly to another controller. </li>
              <li>You have the right to define (general or specific) directives concerning the fate of your data after your death. </li>
              <li className="bold">
                You may object to any processing of your data that is based on our "legitimate interests". If you exercise this right, we must cease processing unless we demonstrate compelling legitimate grounds which override your fundamental rights and freedoms, or for the establishment, exercise or defence of legal claims.  
              </li>
              <li className="bold">
                You may object at any time to the processing of your data for the purpose of prospecting. 
              </li>
            </ul>
            <p>You can exercise your rights by contacting us at the address indicated in article CONTACT <a href="#privacy-policy-contact">« Contact »</a>. </p>
            <p>You also have the right to file a complaint with the competent supervisory authority regarding the processing of your data. </p>
          </div>
          <div className="article-content" id="privacy-policy-contact">
            <h2>6. CONTACT</h2>
            <p>For more information about your rights, to exercise them or if you have any questions or complaints about the protection of your personal data, please contact us at <a href="mailto: rgpd@wall-market.com">rgpd@wall-market.com</a>.</p>
          </div>
          <div className="article-content">
            <h2>7. CHANGES TO THE PRIVACY POLICY</h2>
            <p>We may change this privacy policy from time to time, for example to take account of legal changes, technological advances and good business practice. We will notify you if we make any substantial changes.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicyEN;
