import React, { Component } from 'react';

import Translation from '../../../translation/Translation';
import CartItem from './CartItem';

class Order extends Component {
  render() {
    const { order } = this.props;
    const products = order && order.products ? order.products : 0;

    const timezoneCode =
      order && order.property && order.property.timezone_code
        ? order.property.timezone_code
        : undefined;

    return (
      <div className="app-cart">
        {!this.props.mobile && (
          <span>
            <div className="cart-header">
              <p className="cart-title">
                {Translation().pages.delegate.resume}{' '}
                <span>
                  ({products && products.length ? products.length : 0})
                </span>
              </p>
              <span className="icon-menu-cart icon-panier" />
              {order &&
              order.property_object &&
              order.property_object.address &&
              order.property_object.size_formatted &&
              order.products.length ? (
                <div className="property-adrs">
                  <p>
                    {order.property_object.address} -{' '}
                    {order.property_object.size_formatted}
                  </p>
                  <a href="/shop">{Translation().cart.edit_property}</a>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="cart-content">
              {products && products.length > 0
                ? products.map((item, index) => {
                    return (
                      <CartItem
                        key={index}
                        item={item}
                        timezoneCode={timezoneCode}
                        productInfos={
                          order.delegate_amount &&
                          order.total &&
                          order.delegate_amount === order.total
                            ? true
                            : false
                        }
                      />
                    );
                  })
                : ''}
            </div>
            {order && order.discounts && order.discounts.length > 0 && (
              <div className="cart-code">
                <ul>
                  {order && order.discounts && order.discounts.length > 0
                    ? order.discounts.map((code, index) => {
                        return (
                          <li key={index} className="pcode">
                            <span className="promo-icon icon-promo" />
                            <p>{code.name ? code.name : 'PROMO'}</p>
                          </li>
                        );
                      })
                    : ''}
                </ul>
              </div>
            )}
          </span>
        )}
        <div className="cart-total-amount">
          <table>
            <tbody>
              {order && order.discounts && order.discounts.length > 0 && (
                <tr className="taxes">
                  <td className="promo-text">{Translation().shop.promotion}</td>
                  <td className="promo-price">
                    {order && order.total_discount_formatted
                      ? order.total_discount_formatted
                      : 0}
                  </td>
                </tr>
              )}
              <tr className="taxes">
                <td className="vat-text">{Translation().shop.vat}</td>
                <td className="vat-price">
                  {order && order.delegate_amount_tax_formatted
                    ? order.delegate_amount_tax_formatted
                    : 0}
                </td>
              </tr>
              <tr className="total-with-taxes">
                <td className="tax-text">
                  {Translation().shop.total} {Translation().shop.tax_included}
                </td>
                <td className="tax-price">
                  {order && order.delegate_amount_formatted
                    ? order.delegate_amount_formatted
                    : 0}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Order;
