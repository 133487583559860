import React, { Component } from "react";
import { Col, Clearfix } from "react-bootstrap";
import Dialog from "material-ui/Dialog";
import { Field, reduxForm, stopSubmit } from "redux-form";
import { Checkbox, Toggle } from "redux-form-material-ui";
import { subscribe } from "redux-subscriber";
import { change } from "redux-form";
import bowser from "bowser";

import ShopHeader from "../shopHeader/ShopHeader";
import store from "../../../redux/store";
import Translation from "../../../translation/Translation";
import validate from "../shopRouter/validate";
import { ShopCart } from "../cart/Cart";
import {
  getUserCart,
  setUserCart,
  apiPostPromoCode,
} from "../../../helpers/CartFunctions";
import { objectMapToArray } from "../../../helpers/HelperFunctions";
import {
  setInvoiceUserAddress,
  getUser,
  getUserPaymentMethod,
  loadPaymentMethodIframe,
  getInvoiceUserAddresses,
} from "../../../helpers/UserFunctions";
import { autoScrollTop } from "../../../helpers/JqueryFunctions";
import NumberField from "../../commons/fields/NumberField";
import renderFieldLabel from "../../commons/fields/renderFieldLabel";
import PhoneField from "../../commons/fields/PhoneField";
import GoogleAddressStringField from "../../commons/fields/GoogleAddressStringField";
import SelectField from "../../commons/fields/SelectField";
import TermsOfUse from "../../commons/termsOfUse/TermsOfUse";

class PaymentStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userCart: null,
      isCompanyToggled: false,
      isUserCompanyAdmin: false,
      isInvoiceAddressToggled: false,
      invoiceAddressValue: undefined,
      invoiceAddressName: undefined,
      invoiceAddressOptions: [],
      paymentMethodOptions: [],
      invoiceAddressUpdatedValue: null,
      isPaymentReady: false,
      isTermOfUseOpen: false,
      isDelegatePaymentChecked: false,
    };

    /**
     *Addresses
     */
    this.openInvoiceAddressModal = this.openInvoiceAddressModal.bind(this);
    this.closeInvoiceAddressModal = this.closeInvoiceAddressModal.bind(this);
    this.addInvoiceAddress = this.addInvoiceAddress.bind(this);

    /**
     * Payment
     */
    this.openPaymentMethodModal = this.openPaymentMethodModal.bind(this);
    this.closePaymentMethodModal = this.closePaymentMethodModal.bind(this);
    this.checkPromoCode = this.checkPromoCode.bind(this);

    /**
     * Terms of use
     */
    this.openTermOfUse = this.openTermOfUse.bind(this);
    this.closeTermOfUse = this.closeTermOfUse.bind(this);
    this.acceptTerms = this.acceptTerms.bind(this);

    /**
     * Payment delegate
     */
    this.handleCheckPaymentDelegate =
      this.handleCheckPaymentDelegate.bind(this);
  }

  componentDidMount() {
    /**
     * Delete step in url
     */
    const browserName = bowser.name ? bowser.name : "";
    const browserVersion = bowser.version ? bowser.version : "";
    const isNotIe9 =
      browserName === "Internet Explorer" && browserVersion <= 9 ? false : true;

    if (isNotIe9) {
      this.props.history.replace("/shop");
    }

    autoScrollTop();

    /**
     * Auto focus first input of the complete info form
     */
    const inputs = document.getElementsByTagName("input");
    if (inputs) {
      for (let i = 0; i < inputs.length; i++) {
        inputs[0].focus();
      }
    }

    /**
     * Get the current's) address(es) of the user
     */
    const user = getUser();

    if (user) {
      if (user.mobile) {
        store.dispatch(change("shop", "phone", user.mobile));
      }
      if (user.first_name && user.last_name) {
        store.dispatch(
          change("shop", "onPlace", user.first_name + " " + user.last_name)
        );
      }
      if (user.company_admin && user.company) {
        this.setState({
          isUserCompanyAdmin: true,
          isCompanyToggled: true,
        });

        store.dispatch(change("shop", "companyToggle", true));
      }
    }

    /**
     * Get the user cart and update if changes
     */
    this.setState({ userCart: getUserCart() });

    // eslint-disable-next-line
    const subscribeUserCart = subscribe("userCart", (state) => {
      if (
        state.userCart &&
        state.userCart.products &&
        state.userCart.products.length > 0
      ) {
        this.setState({ userCart: state.userCart });
      } else {
        this.setState({ userCart: null });

        /**
         *  Redirect user on first step if he deleted his last cart item
         */
        document.location.href = "/shop";
      }
    });

    /**
     * Get the current user payment method(s)
     */
    getUserPaymentMethod(
      "usable",
      null,
      null,
      { way: "pay" },
      (successData) => {
        if (successData && successData.length > 0) {
          /**
           * Push user invoices addresses from user into state
           */
          let paymentMethodOptionsArray = [];

          for (let i = 0; i < successData.length; i++) {
            let paymentMethodOptions = {};

            paymentMethodOptions["value"] = successData[i].id;
            paymentMethodOptions["label"] =
              (successData[i].type ? successData[i].type : "") +
              " - " +
              successData[i].name;
            paymentMethodOptionsArray.push(paymentMethodOptions);
          }
          this.setState({ paymentMethodOptions: paymentMethodOptionsArray });
        }
      },
      (error) => {}
    );

    // eslint-disable-next-line
    const subscribePayment = subscribe("form.shop.values", (state) => {
      /**
       * Check if all required fields have a value, then allow checkout button
       */
      const isInvoiceAddressDirty =
        state.form.shop.values && state.form.shop.values.invoiceAddress
          ? true
          : false;
      const isPaymentMethodDirty =
        state.form.shop.values && state.form.shop.values.paymentMethod
          ? true
          : false;
      const isTermsDirty =
        state.form.shop.values && state.form.shop.values.termsOfSales
          ? true
          : false;
      const isPhoneDirty =
        state.form.shop.values && state.form.shop.values.phone ? true : false;
      const isOnPlaceDirty =
        state.form.shop.values && state.form.shop.values.onPlace ? true : false;
      const isPaymentReady =
        isInvoiceAddressDirty &&
        isPaymentMethodDirty &&
        isTermsDirty &&
        isPhoneDirty &&
        isOnPlaceDirty
          ? true
          : false;

      this.setState({ isPaymentReady: isPaymentReady });

      /**
       * Check user type address / payment toggle changes
       */
      const isCompanyToggled =
        state.form.shop.values && state.form.shop.values.companyToggle
          ? true
          : false;

      if (isCompanyToggled) {
        this.setState({ isCompanyToggled: true });
      } else {
        this.setState({ isCompanyToggled: false });
      }
    });

    /**
     * Get all user invoice addresses
     */
    getInvoiceUserAddresses(
      (invoiceAddresses) => {
        let invoiceAddressOptionsArray = [];

        objectMapToArray(invoiceAddresses, (index, address) => {
          let newAddressesOptions = {};

          newAddressesOptions["value"] = address.id;
          newAddressesOptions["label"] =
            address.name +
            " (" +
            (address.street_number ? address.street_number : "") +
            " " +
            (address.street ? address.street : "") +
            " " +
            (address.zip_code ? address.zip_code : " ") +
            " " +
            (address.city ? address.city : "") +
            ")";
          invoiceAddressOptionsArray.push(newAddressesOptions);
        });

        this.setState({ invoiceAddressOptions: invoiceAddressOptionsArray });

        if (!this.state.invoiceAddressUpdatedValue) {
          store.dispatch(
            change(
              "shop",
              "invoiceAddress",
              invoiceAddressOptionsArray[0].value
            )
          );
        }
      },
      (error) => {}
    );
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * Update select value of invoice address field after add a new one
     */
    if (prevState.invoiceAddressOptions !== this.state.invoiceAddressOptions) {
      for (let i = 0; i < this.state.invoiceAddressOptions.length; i++) {
        if (
          this.state.invoiceAddressOptions[i].value ===
          this.state.invoiceAddressUpdatedValue
        ) {
          // <-- Target new address value submited
          store.dispatch(
            change(
              "shop",
              "invoiceAddress",
              this.state.invoiceAddressUpdatedValue
            )
          ); // <-- Force field value
        }
      }
    }

    /**
     * Update select value of payment method field after add a new one
     */
    if (prevState.paymentMethodOptions !== this.state.paymentMethodOptions) {
      store.dispatch(
        change(
          "shop",
          "paymentMethod",
          this.state.paymentMethodOptions[0].value
        )
      );
    }
  }

  openInvoiceAddressModal() {
    this.setState(
      {
        isInvoiceAddressToggled: true,
        invoiceAddressValue: undefined,
        invoiceAddressName: undefined,
      },
      () => {
        /**
         * Clear inputs values
         */
        const inputNewAddress = document.getElementById("new-invoice-address");
        store.dispatch(change("shop", "newInvoiceAddressName", ""));
        store.dispatch(change("shop", "newInvoiceAddress", ""));

        if (inputNewAddress) {
          inputNewAddress.focus();
        }
      }
    );
  }

  closeInvoiceAddressModal() {
    this.setState({ isInvoiceAddressToggled: false });
  }

  addInvoiceAddress() {
    const newAddressValue = this.state.invoiceAddressValue;
    const newAddressName = this.state.invoiceAddressName;

    const errorAddress = document.getElementById("err-new-inv-adrs");
    const loaderBtnAddress = document.getElementById("adrs-inv-btn-loader");
    const textBtnAddress = document.getElementById("adrs-inv-btn-txt");

    const fieldsErrors = [
      "street_number",
      "street",
      "zip_code",
      "city",
      "country_isocode",
      "state_isocode",
      "latitude",
      "longitude",
    ];

    if (newAddressValue && newAddressName) {
      if (newAddressValue !== undefined && newAddressName !== undefined) {
        if (textBtnAddress && loaderBtnAddress) {
          textBtnAddress.style.display = "none";
          loaderBtnAddress.style.display = "inline-block";
        }

        setInvoiceUserAddress(
          this.state.isCompanyToggled,
          newAddressName,
          newAddressValue,
          (successData) => {
            /**
             * Get all user invoice addresses
             */
            getInvoiceUserAddresses(
              (invoiceAddresses) => {
                let invoiceAddressOptionsArray = [];

                objectMapToArray(invoiceAddresses, (index, address) => {
                  let newAddressesOptions = {};

                  newAddressesOptions["value"] = address.id;
                  newAddressesOptions["label"] =
                    address.name +
                    " (" +
                    (address.street_number ? address.street_number : "") +
                    " " +
                    (address.street ? address.street : "") +
                    " " +
                    (address.zip_code ? address.zip_code : " ") +
                    " " +
                    (address.city ? address.city : "") +
                    ")";
                  invoiceAddressOptionsArray.push(newAddressesOptions);
                });

                /**
                 * update invoice addresses array options
                 * Register temporaly the value of the new address
                 */
                this.setState({
                  invoiceAddressOptions: invoiceAddressOptionsArray,
                  invoiceAddressUpdatedValue: successData.id
                    ? successData.id
                    : null,
                });

                if (textBtnAddress && loaderBtnAddress) {
                  textBtnAddress.style.display = "inline-block";
                  loaderBtnAddress.style.display = "none";
                }
                this.closeInvoiceAddressModal();
              },
              (error) => {}
            );
          },
          (error) => {
            if (textBtnAddress && loaderBtnAddress) {
              textBtnAddress.style.display = "inline-block";
              loaderBtnAddress.style.display = "none";
            }

            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              const validationErrors = {};
              const errorsArray = error.response.data.errors;

              for (let i = 0; i < errorsArray.length; i++) {
                if (errorsArray[i].propertyPath === "address") {
                  validationErrors["newInvoiceAddress"] =
                    errorsArray[i].message;
                } else if (
                  fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0
                ) {
                  validationErrors["newInvoiceAddress"] =
                    errorsArray[i].propertyPath + ": " + errorsArray[i].message;
                } else if (errorsArray[i].propertyPath === "name") {
                  validationErrors["newInvoiceAddressName"] =
                    errorsArray[i].message;
                } else
                  validationErrors[errorsArray[i].propertyPath] =
                    errorsArray[i].message;
              }

              store.dispatch(stopSubmit("shop", validationErrors));
            } else {
              if (errorAddress) {
                errorAddress.innerHTML =
                  error.response &&
                  error.response.data &&
                  error.response.data.detail
                    ? error.response.data.detail
                    : "An error occurred, please retry later.";
              }
            }
          }
        );
      }
    } else if (!newAddressValue) {
      if (errorAddress) {
        errorAddress.innerHTML = "Veuillez indiquer une addresse";
      }
    } else if (!newAddressName) {
      if (errorAddress) {
        errorAddress.innerHTML = "Veuillez indiquer le nom de l'adresse";
      }
    } else {
      if (errorAddress) {
        errorAddress.innerHTML = "Veuillez completer les champs";
      }
    }
  }

  openPaymentMethodModal(value) {
    const paymentMethodModal = document.getElementsByClassName(
      "overlay-payment-method"
    )[0];

    if (paymentMethodModal) {
      paymentMethodModal.style.display = "block";
    }

    let checkValue = false;

    if (value) {
      if (value.target && value.target.checked) {
        checkValue = value.target.checked;
      } else if (typeof value !== "boolean") {
        checkValue = false;
      } else {
        checkValue = value;
      }
    }

    loadPaymentMethodIframe(checkValue, () => {
      /**
       * Update the payment method list after submit new one
       */
      getUserPaymentMethod(
        "usable",
        null,
        null,
        { way: "pay" },
        (successData) => {
          if (successData && successData.length > 0) {
            let paymentMethodOptionsArray = [];

            for (let i = 0; i < successData.length; i++) {
              let paymentMethodOptions = {};

              paymentMethodOptions["value"] = successData[i].id;
              paymentMethodOptions["label"] =
                (successData[i].type ? successData[i].type : "") +
                " - " +
                successData[i].name;

              paymentMethodOptionsArray.push(paymentMethodOptions);
            }
            this.setState({ paymentMethodOptions: paymentMethodOptionsArray });
          }
        },
        (error) => {}
      );

      this.closePaymentMethodModal();
    });
  }

  closePaymentMethodModal() {
    const paymentMethodModal = document.getElementsByClassName(
      "overlay-payment-method"
    )[0];

    if (paymentMethodModal) {
      paymentMethodModal.style.display = "none";
    }
  }

  checkPromoCode() {
    const promoCodeSubmit = document.getElementById("pcode-submit");
    const promoCodeOutput = document.getElementById("pcode-output");
    const promoCodeInput = document.getElementById("promo-code-input");

    if (promoCodeInput && promoCodeInput.value) {
      if (promoCodeSubmit) {
        promoCodeSubmit.style.display = "none";
      }
      if (promoCodeOutput) {
        promoCodeOutput.style.display = "block";
        promoCodeOutput.innerHTML = '<span class="loader-api block"></span>';
      }

      apiPostPromoCode(
        promoCodeInput.value,
        (successData) => {
          /**
           * Re-set the userCart with the new cart answered by the api
           */
          setUserCart(successData);

          if (promoCodeOutput) {
            promoCodeInput.classList.add("success");
            promoCodeOutput.innerHTML =
              '<span class="icon-check valid-promocode"></span>';
          }
        },
        (error) => {
          if (promoCodeSubmit && promoCodeOutput) {
            promoCodeOutput.style.display = "block";
            promoCodeInput.classList.add("error");
            promoCodeOutput.innerHTML =
              '<span class="icon-fermer invalid-promocode"></span>';

            setTimeout(() => {
              promoCodeOutput.style.display = "none";
              promoCodeInput.classList.remove("error");
              promoCodeSubmit.style.display = "block";
              promoCodeInput.value = "";
              promoCodeSubmit.innerHTML = "OK";
            }, 1500);
          }
        }
      );
    }
  }

  openTermOfUse(event) {
    event.preventDefault();
    this.setState({ isTermOfUseOpen: true });
  }

  closeTermOfUse() {
    this.setState({ isTermOfUseOpen: false });
  }

  acceptTerms() {
    this.closeTermOfUse();
    store.dispatch(change("shop", "termsOfSales", true));
  }

  handleCheckPaymentDelegate(event) {
    const { isDelegatePaymentChecked } = this.state;

    if (isDelegatePaymentChecked) {
      store.dispatch(change("shop", "delegate_amount", ""));
      store.dispatch(change("shop", "delegate_email", ""));

      this.setState({
        isDelegatePaymentChecked: false,
      });
    } else {
      store.dispatch(change("shop", "delegate_amount", "100%"));
      this.setState({
        isDelegatePaymentChecked: true,
      });
    }
  }

  render() {
    /**
     * Browser version
     */
    const browserName = bowser.name ? bowser.name : "";
    const browserVersion = bowser.version ? bowser.version : "";
    const isNotIe9 =
      browserName === "Internet Explorer" && browserVersion <= 9 ? false : true;

    const { handleSubmit } = this.props;

    const {
      invoiceAddressOptions,
      isCompanyToggled,
      isDelegatePaymentChecked,
      isInvoiceAddressToggled,
      isPaymentReady,
      isUserCompanyAdmin,
      paymentMethodOptions,
      userCart,
    } = this.state;

    /**
     * Toggle switch style
     */
    const toggleStyle = {
      backgroundColor: isCompanyToggled ? "#1a2123" : "#FFD700",
      zIndex: 1,
    };

    /**
     * Checkbox style
     */
    const inputCheckboxStyle = {
      width: "30px",
      display: "inline-block",
    };

    return (
      <div className="shop-payment">
        <ShopHeader stepIndex={4} />
        {isInvoiceAddressToggled && (
          <div className="overlay-invoice-address">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div
                    className="icon-fermer close-modal"
                    onClick={this.closeInvoiceAddressModal}
                  />
                  <h4 className="app-modal-title">
                    {Translation().shop.payment.add_invoice_address}
                  </h4>
                  <p className="app-modal-desc">
                    {Translation().shop.payment.complete_fields}
                  </p>
                  <div className="app-modal-content">
                    <form>
                      {isUserCompanyAdmin && (
                        <div className="select-user-type">
                          <span
                            className={
                              "text-ut " + (isCompanyToggled ? "" : "active")
                            }
                            onClick={() => {
                              store.dispatch(
                                change("shop", "companyToggle", "")
                              );
                            }}
                          >
                            {Translation().shop.step_fields.names.personal}
                          </span>
                          <Field
                            type="checkbox"
                            component={Toggle}
                            className="toggle-ut"
                            thumbStyle={toggleStyle}
                            thumbSwitchedStyle={toggleStyle}
                            name="companyToggle"
                            onChange={() => {
                              return false;
                            }}
                          />
                          <span
                            className={
                              "text-ut right " +
                              (isCompanyToggled ? "active" : "")
                            }
                            onClick={() => {
                              store.dispatch(
                                change("shop", "companyToggle", true)
                              );
                            }}
                          >
                            {Translation().shop.step_fields.names.pro}
                          </span>
                        </div>
                      )}
                      <Field
                        name="newInvoiceAddress"
                        type="text"
                        id="new-invoice-address"
                        label={
                          Translation().shop.step_fields.placeholders.address
                        }
                        className="app-basic-input"
                        component={GoogleAddressStringField}
                        onChange={(value, address) => {
                          this.setState({ invoiceAddressValue: address });
                        }}
                      />
                      <Field
                        name="newInvoiceAddressName"
                        type="text"
                        id="new-invoice-address-name"
                        placeholder={
                          Translation().shop.step_fields.placeholders.give_name
                        }
                        className="app-basic-input"
                        component={renderFieldLabel}
                        tabIndex="-1"
                        onChange={(event, value) => {
                          this.setState({ invoiceAddressName: value });
                        }}
                      />
                      <div className="btn-wrapper">
                        <div
                          className={
                            "app-button-color" +
                            (isCompanyToggled ? " app-button-black" : "")
                          }
                          onClick={this.addInvoiceAddress}
                        >
                          <span
                            id="adrs-inv-btn-loader"
                            className="loader-api"
                          />
                          <span id="adrs-inv-btn-txt">
                            {Translation().shop.step_buttons.add}
                          </span>
                        </div>
                        <p id="err-new-inv-adrs" className="error-message" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="overlay-payment-method">
          <div className="container">
            <div className="row">
              <div className="app-modal">
                <div
                  className="icon-fermer close-modal"
                  onClick={this.closePaymentMethodModal}
                />
                <h4 className="app-modal-title">
                  {Translation().shop.payment.add_payment_method}
                </h4>
                <p className="app-modal-desc">
                  {Translation().shop.payment.complete_fields}
                </p>
                <div className="app-modal-content">
                  <form>
                    <div className="payment-container">
                      {isUserCompanyAdmin && (
                        <div className="select-user-type">
                          <span
                            className={
                              "text-ut " + (isCompanyToggled ? "" : "active")
                            }
                            onClick={() => {
                              this.openPaymentMethodModal(false);
                              store.dispatch(
                                change("shop", "companyToggle", "")
                              );
                            }}
                          >
                            {Translation().shop.step_fields.names.personal}
                          </span>
                          <Field
                            type="checkbox"
                            component={Toggle}
                            className="toggle-ut"
                            thumbStyle={toggleStyle}
                            thumbSwitchedStyle={toggleStyle}
                            name="companyToggle"
                            onChange={(event) => {
                              this.openPaymentMethodModal(event);
                            }}
                          />
                          <span
                            className={
                              "text-ut right " +
                              (isCompanyToggled ? "active" : "")
                            }
                            onClick={() => {
                              this.openPaymentMethodModal(true);
                              store.dispatch(
                                change("shop", "companyToggle", true)
                              );
                            }}
                          >
                            {Translation().shop.step_fields.names.pro}
                          </span>
                        </div>
                      )}
                      <div id="frame-payment-loader" className="loader-api" />
                      <div id="payment-frame-container" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="shop-content">
              <form onSubmit={handleSubmit}>
                <Col xs={12} sm={12} md={3} className="cart-col-mobile">
                  <div className="cart-col">
                    <div className="btn-next-top">
                      <a
                        href="/shop/2"
                        className="app-button-color app-button-dark btn-shopping"
                      >
                        <span className="icon-next icon-chevron_gauche" />
                        {Translation().shop.step_buttons.continue_shopping}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={9}>
                  <div className="intro-step">
                    <h1>{Translation().shop.step_titles.payment}</h1>
                    <p>{Translation().shop.step_descriptions.payment}</p>
                  </div>
                  <div className="order-col property-access">
                    <div className="complete-infos">
                      <h3>{Translation().shop.payment.property_access}</h3>
                      <Col xs={4} sm={2} md={2}>
                        <label htmlFor="floor">
                          {Translation().shop.step_fields.names.floor}
                        </label>
                        <Field
                          name="floor"
                          id="floor-input"
                          className="app-basic-input"
                          integer={25}
                          fraction={2}
                          positive={true}
                          negative={true}
                          separator={"."}
                          required={false}
                          component={NumberField}
                        />
                      </Col>
                      <Col xs={8} sm={5} md={5}>
                        <label htmlFor="digicode">
                          {Translation().shop.step_fields.names.digital_code}
                        </label>
                        <Field
                          name="digicode"
                          id="digicode"
                          component="input"
                          type="text"
                          className="app-basic-input"
                        />
                      </Col>
                      <Col xs={12} sm={5} md={5}>
                        <label htmlFor="interphone">
                          {Translation().shop.step_fields.names.intercom}
                        </label>
                        <Field
                          name="interphone"
                          id="interphone"
                          component="input"
                          type="text"
                          className="app-basic-input"
                        />
                      </Col>
                      <Clearfix />
                      <Col xs={12} sm={6} md={6} lg={7}>
                        <label htmlFor="on-place">
                          {Translation().shop.step_fields.names.person_on_place}{" "}
                          <span className="asterix">*</span>
                        </label>
                        <Field
                          name="onPlace"
                          id="on-place"
                          component="input"
                          type="text"
                          className="app-basic-input"
                          required={true}
                        />
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={5}>
                        <label htmlFor="phone">
                          {Translation().shop.step_fields.names.phone_on_place}{" "}
                          <span className="asterix">*</span>
                        </label>
                        <Field
                          name="phone"
                          id="phone-input"
                          required={true}
                          component={PhoneField}
                          placeholder={
                            Translation().register.form.labels.mobile
                          }
                          className="app-basic-input"
                        />
                      </Col>
                      <Clearfix />
                      <Col xs={12} sm={12} md={12}>
                        <label htmlFor="reference">
                          {Translation().shop.step_fields.names.reference}
                        </label>
                        <Field
                          name="reference"
                          id="reference"
                          component="input"
                          type="text"
                          className="app-basic-input"
                        />
                      </Col>
                      <Clearfix />
                      <Col xs={12} sm={12} md={12}>
                        <label htmlFor="comment">
                          {Translation().shop.step_fields.names.comment}
                        </label>
                        <Field
                          name="comment"
                          id="comment"
                          component="textarea"
                          className="app-basic-input"
                        />
                      </Col>
                      <Clearfix />
                    </div>
                  </div>
                  <div className="order-col invoicing">
                    <Col xs={12} sm={6} md={6} className="col-method cleft">
                      <div className="invoice-method payment-method">
                        <h3>{Translation().shop.payment.payment_method} *</h3>
                        <div
                          className={
                            "add-method " +
                            (paymentMethodOptions &&
                            paymentMethodOptions.length > 0
                              ? ""
                              : "empty")
                          }
                          onClick={() =>
                            this.openPaymentMethodModal(isCompanyToggled)
                          }
                        >
                          <span className="icon-plus add-icon" />
                        </div>
                        <Field
                          name="paymentMethod"
                          options={paymentMethodOptions}
                          placeHolder="CB N°"
                          component={SelectField}
                          className={
                            "app-basic-input payment-input select-inputs " +
                            (paymentMethodOptions &&
                            paymentMethodOptions.length > 0
                              ? "display-block"
                              : "display-none")
                          }
                          required={true}
                        />
                        <p
                          className={
                            "no-method " +
                            (paymentMethodOptions &&
                            paymentMethodOptions.length > 0
                              ? "display-none"
                              : "display-block")
                          }
                        >
                          {
                            Translation().shop.step_fields.names
                              .add_payment_method
                          }
                        </p>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="col-method cright">
                      <div className="invoice-method invoice-address">
                        <h3>{Translation().shop.payment.invoice_address} *</h3>
                        <div
                          className={
                            "add-method " +
                            (invoiceAddressOptions &&
                            invoiceAddressOptions.length > 0
                              ? ""
                              : "empty")
                          }
                          onClick={this.openInvoiceAddressModal}
                        >
                          <span className="icon-plus add-icon" />
                        </div>
                        <Field
                          name="invoiceAddress"
                          options={invoiceAddressOptions}
                          placeHolder={
                            Translation().shop.step_fields.placeholders.address
                          }
                          component={SelectField}
                          className={
                            "app-basic-input invoice-input select-inputs " +
                            (invoiceAddressOptions &&
                            invoiceAddressOptions.length > 0
                              ? "display-block"
                              : "display-none")
                          }
                          required={true}
                        />
                        <p
                          className={
                            "no-method " +
                            (invoiceAddressOptions &&
                            invoiceAddressOptions.length > 0
                              ? "display-none"
                              : "display-block")
                          }
                        >
                          {
                            Translation().shop.step_fields.names
                              .add_invoice_address
                          }
                        </p>
                      </div>
                    </Col>
                    <Col xs={12} sm={12}>
                      <div className="payment-delegate">
                        <div className="trigger-form">
                          <Field
                            name="payment_delegated"
                            id="paymentDelegated"
                            component={Checkbox}
                            style={inputCheckboxStyle}
                            onChange={this.handleCheckPaymentDelegate}
                          />
                          <label
                            className="checkbox-label"
                            htmlFor="paymentDelegated"
                          >
                            {
                              Translation().shop.step_fields.names
                                .payment_delegate
                            }
                          </label>
                        </div>
                        {isDelegatePaymentChecked && (
                          <div className="delegate-values">
                            <p className="delegate-desc">
                              {Translation().shop.payment_delegate.desc}
                            </p>
                            <div className="row">
                              <Col xs={12} sm={6} className="cleft">
                                <div className="complete-infos">
                                  <label htmlFor="delegate-email">
                                    {
                                      Translation().shop.step_fields
                                        .placeholders.email
                                    }
                                    *
                                  </label>
                                  <Field
                                    className="app-basic-input"
                                    component={renderFieldLabel}
                                    id="delegate-email"
                                    name="delegate_email"
                                    required
                                    type="email"
                                    error="test"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} sm={6} className="cright">
                                <div className="complete-infos">
                                  <label htmlFor="delegate-amount-tax-excluded">
                                    {
                                      Translation().shop.step_fields
                                        .placeholders.delegate_amount
                                    }
                                  </label>
                                  <Field
                                    className="app-basic-input"
                                    component={renderFieldLabel}
                                    id="delegate-amount-tax-excluded"
                                    name="delegate_amount"
                                    type="text"
                                  />
                                </div>
                              </Col>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Clearfix />
                  </div>
                  <div className="order-col">
                    <div className="final-infos">
                      <Field
                        name="termsOfSales"
                        id="termsOfSales"
                        component={Checkbox}
                        style={inputCheckboxStyle}
                        required={true}
                      />
                      <label className="checkbox-label" htmlFor="termsOfSales">
                        {Translation().shop.payment.term_of_sales.read}{" "}
                        <a onClick={this.openTermOfUse}>
                          {Translation().shop.payment.term_of_sales.tos}
                        </a>{" "}
                        {Translation().shop.payment.term_of_sales.agree} *
                      </label>
                      <p className="required-field">
                        <span>*</span>{" "}
                        {Translation().shop.payment.required_fields}
                      </p>
                      <div className="checkout-order">
                        <Col xs={12} sm={5} md={6} lg={5} className="col">
                          <span className="checkout-label">
                            {Translation().shop.payment.promo_code}
                          </span>
                          <input
                            id="promo-code-input"
                            className="app-basic-input promo-input"
                            maxLength="160"
                            onKeyPress={(e) => {
                              // Block form send on enter key press
                              if (e.key === "Enter") {
                                e.preventDefault();
                                this.checkPromoCode();
                              }
                            }}
                          />
                          <div
                            className="check-promocode"
                            id="pcode-submit"
                            onClick={this.checkPromoCode}
                          >
                            OK
                          </div>
                          <div className="promocode-output" id="pcode-output" />
                        </Col>
                        <Col xs={12} sm={3} md={6} lg={3} className="col">
                          <span className="checkout-label">
                            {Translation().shop.total}{" "}
                            {Translation().shop.tax_included}
                          </span>
                          <p className="total-order">
                            {userCart && userCart.total_formatted
                              ? userCart.total_formatted
                              : 0}
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          sm={4}
                          md={12}
                          lg={4}
                          className="col col-btn-ckt"
                        >
                          <button
                            type="submit"
                            id="appPaymentBtn"
                            className={
                              "app-button-color btn-checkout " +
                              (isNotIe9 ? "btn-checkout-animated " : " ") +
                              (isPaymentReady ? "checkout-rdy" : "")
                            }
                          >
                            <span id="order-btn-txt">
                              {Translation().shop.step_buttons.finalize}
                            </span>
                            <span
                              className="loader-api"
                              id="order-btn-loader"
                            />
                          </button>
                        </Col>
                        <Clearfix />
                        <div>
                          <p className="error-message" id="payment-failed" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} className="cart-col-desktop">
                  <div className="cart-col">
                    <div className="btn-next-top">
                      <a
                        href="/shop/2"
                        className="app-button-color app-button-dark btn-shopping"
                      >
                        <span className="icon-next icon-chevron_gauche" />
                        {Translation().shop.step_buttons.continue_shopping}
                      </a>
                    </div>
                    <ShopCart mobile={false} isOpen={true} />
                  </div>
                </Col>
                <Clearfix />
              </form>
            </div>
          </div>
        </div>
        <Dialog
          modal={false}
          actions={[
            <div onClick={this.acceptTerms} className="btn-tos-modal">
              {Translation().shop.payment.modal.accept
                ? Translation().shop.payment.modal.accept
                : "Accept"}
            </div>,
            <div onClick={this.closeTermOfUse} className="btn-tos-modal">
              {Translation().shop.payment.modal.close
                ? Translation().shop.payment.modal.close
                : "Close"}
            </div>,
          ]}
          className="term-use-modal"
          autoScrollBodyContent={true}
          onRequestClose={this.closeTermOfUse}
          open={this.state.isTermOfUseOpen}
        >
          <TermsOfUse isDialog={true} />
        </Dialog>
      </div>
    );
  }
}

export default reduxForm({
  form: "shop",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(PaymentStep);
