import { getLocale } from '../helpers/UserFunctions';

const da_DA = require('./languages/da.json');
const de_DE = require('./languages/de.json');
const en_GB = require('./languages/en.json');
const es_ES = require('./languages/es.json');
const fi_FI = require('./languages/fi.json');
const fr_FR = require('./languages/fr.json');
const it_IT = require('./languages/it.json');
const nl_NL = require('./languages/nl.json');
const no_NO = require('./languages/no.json');
const pt_PT = require('./languages/pt.json');
const sv_SV = require('./languages/sv.json');

const Translation = locale => {
  
  if (!locale) {
    locale = getLocale();
  }
  
  switch (locale) {
    case "da":
      return da_DA;
    case "de":
      return de_DE;
    case "en":
      return en_GB;
    case "es":
      return es_ES;
    case "fi":
      return fi_FI;
    case "fr":
      return fr_FR;
    case "it":
      return it_IT;
    case "nl":
      return nl_NL;
    case "no":
      return no_NO;
    case "pt":
      return pt_PT;
    case "sv":
      return sv_SV;
    default:
      return en_GB;
  }
}

export default Translation;
