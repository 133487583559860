import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import Translation from '../../translation/Translation';

class ScanPacks extends Component {

  render() {

    return (
      <div className="app-service app-packs">
        <div className="page-header-title">
          <div className="icon-pack_san icon-page-head"></div>
          <h1>{Translation().pages.scan_packs.page_title.title}</h1>
          <h2>{Translation().pages.scan_packs.page_title.baseline}</h2>
        </div>
        <section className="packs-products">
          <section className="pack-product boost">
            <div className="container">
              <div className="row">
                <img className="product-avatar" src="/assets/images/packs/pack-scan-boost-avatar.png" alt="Pro pack"/>
                <div className="product-title">
                  <img src="/assets/images/commons/icon-pack-boost-light.png" alt="Pack boost"/>
                  <h3><span>{Translation().pages.scan_packs.products.boost.title}</span> {Translation().pages.scan_packs.products.boost.sub_title}</h3>
                </div>
                <div style={{position: 'relative'}}>
                  <Col xs={12} sm={12} md={4}>
                    <div className="product-cover">
                      <div className="background-services scan-pack-vv-cover" />
                      <div className="details">
                        <h4>{Translation().pages.scan_packs.products.boost.offers.visit.title}</h4>
                        <p>{Translation().pages.scan_packs.products.boost.offers.visit.text}</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={4}>
                    <div className="products-fab first">
                      <span className="icon-plus"/>
                    </div>
                    <div className="product-cover">
                      <div className="background-services scan-pack-plan-cover" />
                      <div className="details">
                        <h4>{Translation().pages.scan_packs.products.boost.offers.plan.title}</h4>
                        <p>{Translation().pages.scan_packs.products.boost.offers.plan.text}</p>
                      </div>
                    </div>
                    <div className="products-fab second">
                      <span className="icon-plus"/>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={4}>
                    <div className="product-cover">
                      <div className="background-services scan-pack-capture-cover" />
                      <div className="details">
                        <h4>{Translation().pages.scan_packs.products.boost.offers.capture.title}</h4>
                        <p>{Translation().pages.scan_packs.products.boost.offers.capture.text}</p>
                      </div>
                    </div>
                  </Col>
                  <Clearfix />
                  <a href="/shop" className="app-button-color red">{Translation().pages.scan_packs.products.button}</a>
                </div>
              </div>
            </div>
          </section>
        </section>
        <div className="no-price-page">
          <div className="container">
            <div className="row">
              <div className="pagination-service">
               <div className="prev-service">
                  <a href="/pro-pack">
                    <span className="icon-chevron_gauche icon-pagination"></span>
                    <p>{Translation().header.app_nav.products.pro_packs.title}</p>
                  </a>
                </div>
                <div className="next-service">
                  <a href="/photography">
                    <p>{Translation().header.app_nav.products.photoshoot.title}</p>
                    <span className="icon-chevron_droite icon-pagination"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ScanPacks;
