import bowser from 'bowser';
import { Pushwoosh } from 'web-push-notifications';

import { CallApi } from './ApiFunctions';
import { getDeviceBrand } from './HelperFunctions';

let pwInstance = null;

const pwConfig = {
  logLevel: 'info',
  applicationCode: "02B34-29F1C",
  safariWebsitePushID: 'web.lightshop',
  defaultNotificationTitle: 'LightShop',
  defaultNotificationImage: 'https://lightshop.wall-market.com/public/images/icon.png',
  autoSubscribe: false
}

const init = (callBackFunc) => {
  pwInstance = new Pushwoosh();

  pwInstance.push(['init', pwConfig]);
  pwInstance.push(['onSubscribe', api => {
    registerDevice();
  }]);
}

init();

export const registerDevice = () => {

  if (!pwInstance)
    return;

  pwInstance.isSubscribed()
    .then((isSubscribed) => {

      if (isSubscribed) {
        pwInstance.getParams()
          .then((params) => {
            params = params || {};
            const deviceModel = params && params.tags && params.tags["Device Model"] ? params.tags["Device Model"] : null;
            const devicePlatform = bowser.name.toLowerCase();

            if (params && params.pushToken && params.pushToken.length > 1) {
              postUserSelfDevice({
                model: deviceModel,
                pusher: "lightshop",
                brand: getDeviceBrand(),
                platform: devicePlatform,
                push_token: params.pushToken
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      else {
        pwInstance.subscribe();
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export const unregisterDevice = (callBackSuccess = null, callBackError = null) => {

  if (!pwInstance) {

    if (callBackError && typeof callBackError === 'function')
      callBackError();

    return;
  }

  pwInstance.getParams()
    .then((params) => {
      params = params || {};

      if (params && params.pushToken && params.pushToken.length > 1)
        deleteUserSelfDeviceByToken(params.pushToken, callBackSuccess, callBackError);
      else {
        if (callBackError && typeof callBackError === 'function')
          callBackError();
      }
    })
    .catch((err) => {
      if (callBackError && typeof callBackError === 'function')
        callBackError();
    });
}

const postUserSelfDevice = data => {

  if (!data)
    return;

  CallApi(
    "post",
    "users/self/devices",
    null,
    data,
    null,
    null,
    null
  );
}

const deleteUserSelfDeviceByToken = (pushToken, callBackSuccess, callBackError) => {

  if (!pushToken)
    return;

  CallApi(
    "delete",
    "users/self/devices",
    null,
    { push_token: pushToken.toString() },
    null,
    callBackSuccess,
    callBackError
  );
}
