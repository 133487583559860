import React, { Component } from 'react';

import Translation from '../../../translation/Translation';

class ShopHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stepIndex: this.props.stepIndex ? this.props.stepIndex : 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ stepIndex:  nextProps.stepIndex });
  }

  render() {
    const { stepIndex } = this.state;

    const completedStepClass = "completed-step";
    const activeStepClass = "active-step";

    return (
      <ul className="order-steps">
        <li>
          <div className={"shop-step " + (stepIndex === 1 ? activeStepClass : '') + (stepIndex > 1 ? completedStepClass : '')}>
            <span className="icon-step icon-recapitulatif"></span>
            <span className="text-step">{Translation().shop.step_header.informations}</span>
          </div>
        </li>
        <li className="navlink-separator">---------</li>
        <li>
          <div className={"shop-step " + (stepIndex === 2 ? activeStepClass : '') + (stepIndex > 2 ? completedStepClass : '')}>
            <span className="icon-step icon-services"></span>
            <span className="text-step">{Translation().shop.step_header.services}</span>
          </div>
        </li>
        {
          /*
            <li className="navlink-separator">---------</li>
            <li>
              <div className={"shop-step " + (stepIndex === 3 ? activeStepClass : '') + (stepIndex > 3 ? completedStepClass : '')}>
                <span className="icon-step icon-connexion2"></span>
                <span className="text-step">{Translation().shop.step_header.connexion}</span>
              </div>
            </li>
          */
        }
        <li className="navlink-separator">---------</li>
        <li>
          <div className={"shop-step " + (stepIndex === 4 ? activeStepClass : '')}>
            <span className="icon-step icon-paiement"></span>
            <span className="text-step">{Translation().shop.step_header.payment}</span>
          </div>
        </li>
      </ul>
    )
  }
}

export default ShopHeader;
