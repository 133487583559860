import React, { Component } from 'react';

import Config from '../../../../config/Config';

class TermsOfUseES extends Component {
  render() {
    return (
      <div className="articles">
        <div className="article-content" id="introduction">
          <p>Les damos la bienvenida a nuestro sitio web <a href="https://lightshop.wall-market.com" target="_blank" rel="noopener noreferrer">lightshop.wall-market.com</a> (en lo sucesivo, el «Sitio»).</p>
          <p>Las presentes condiciones generales de venta definen los términos y condiciones de compra de los diversos servicios que ofrece el Sitio.</p>
          <p className="bold">Por favor, lea cuidadosamente estas condiciones antes de realizar cualquier pedido en el Sitio. Al acceder a este y realizar un pedido, acepta quedar obligado sin reservas por estas condiciones generales de venta.</p>
          <p>La forma en que recabamos y utilizamos los datos personales en relación con su acceso y uso del Sitio se describe en nuestra política de privacidad <a href="https://lightshop.wall-market.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://lightshop.wall-market.com/privacy-policy</a></p>
          <p>Si tiene alguna pregunta acerca de estas condiciones, por favor contáctenos antes de realizar un pedido. Si no acepta plenamente estas condiciones, no realice ningún pedido en nuestro Sitio.</p>
        </div>
        <div className="article-content" id="about-us">
          <h2>1. ¿Quiénes somos?</h2>
          <p>El Sitio lo gestiona SAS Wall-Market, Sociedad por acciones simplificada con un capital de 19 146 euros, con sede social en 22 rue Legendre 75017 París, e inscrita en el Registro Mercantil de París con el número de registro único 532 727 740.</p>
          <p>Datos de contacto:</p>
          <ul>
            <li>Dirección : {Config.brand_address}, {Config.brand_city}</li>
            <li>Tel : +33 (0)1 42 27 45 53</li>
            <li>Correo-e : <a href="mailto:sales@wall-market.com" target="_blank" rel="noopener noreferrer">sales@wall-market.com</a></li>
          </ul>
        </div>
        <div className="article-content" id="services-description">
          <h2>2. Descripción de los Servicios </h2>
          <p>El Sitio ofrece varios servicios (en lo sucesivo, el o los « <b>Service(s)</b> »), para facilitar la comercialización o revalorización de bienes inmuebles.</p>
          <p>La prestación de nuestros Servicios se lleva a cabo en varias etapas:</p>
          <p>
            - Registro por parte del Cliente de la información relativa a su bien, en particular la superficie, y que le permita ser contactado; <br/>
            - Prestación del servicio que permite la entrega del Servicio por un prestador independiente seleccionado por Wall-Market, a distancia o en el lugar donde se encuentra el bien inmueble objeto del Servicio. En caso de ejecución in situ, el Cliente se compromete a recibir al prestador en un lugar limpio y ordenado para permitir la prestación inmediata del servicio; y<br/>
            - Entrega del Servicio en forma de fichero intangible (en lo sucesivo, el o los «Ficheros»), cuyo plazo de conservación y período de uso varía según las indicaciones dadas para cada Servicio en el momento del pedido.
          </p>
          <p>
            Los Servicios pueden solicitarse a la carta o por PACK y se les pueden agregar Opciones. Cuando realice el pedido, tendrá a su disposición la descripción detallada de los Servicios, las Opciones disponibles y el plazo de conservación en su cuenta personal.
          </p>
          <p>
            <b>ADVERTENCIA: no todos los Servicios u Opciones están disponibles en todos los países. </b>
          </p>
          <p>Asimismo, se establece lo siguiente:</p>
          <p>
            - Un « <b>SESIÓN DE FOTOS</b> » or una « <b>FOTO</b> » corresponde a la realización de fotos HD de su bien inmueble mediante el uso de un equipo profesional.<br/>
            - El Servicio « <b>PLAN</b> » permite generar planos en 2D (también en 3D si se elige esta Opción) de su bien inmueble. 
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              ADVERTENCIA: estos planos en 2D o 3D, así como las mediciones a partir de las cuales se generan, no tienen ningún valor legal. Son estrictamente indicativos e ilustrativos. Por ejemplo, no pueden utilizarse como documentación de apoyo para llevar a cabo obras, determinar los metros cuadrados o sustituir un plano oficial (por ejemplo, el plano catastral, plano anexo al reglamento de comunidad de propietarios). Asimismo, si se facilitan los elementos estructurales (como la ubicación de los muros de carga), es solo a título informativo: solo profesionales certificados están autorizados a proporcionar esa información que tiene valor legal.
            </b>
          </p>
          <p>
            - El Servicio de « <b>DIAGNÓSTICOS</b> » permite a un profesional con un certificado expedido por un organismo acreditado por el Comité Francés de Acreditación (COFRAC) realizar uno o varios diagnósticos ( «<b>DIAGNÓSTICOS A LA CARTA</b>» ) exigidos por la legislación francesa para los bienes inmuebles situados en Francia. Los diagnósticos exigidos por la legislación no son los mismos para todos los bienes inmuebles, dependiendo de su naturaleza o propósito.
          </p>
          <p>
            <b>Dado que Wall-Market no ofrece servicios de consultoría, le corresponde a usted determinar, en función del objetivo que quiere alcanzar (por ejemplo, la venta o el alquiler de su bien inmueble), qué diagnósticos exige la ley. Se excluye cualquier responsabilidad de Wall-Market a este respecto.</b>
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              Atención: el precio de los «diagnósticos a la carta» implica el pago: i) del desplazamiento de un prestador, por un importe fijo cuyo precio se especifica antes de realizar el pedido, y ii) de cada diagnóstico seleccionado.
            </b>
          </p>
          <p>
            - <b>Las IMÁGENES (p. ej., propuestas en el «PACK») no son Servicios de «FOTO» o de «SESIÓN DE FOTOS», sino «CAPTURAS».</b> Las «CAPTURAS» no están hechas con un equipo fotográfico profesional sino con imágenes de calidad de alta definición extraídas de la máquina SCAN 3D MATTERPORT utilizada para realizar « <b>VISITAS VIRTUALES</b> ».
          </p>
        </div>
        <div className="article-content" id="access-services">
          <h2>3. Acceso al Sitio y sus Servicios</h2>
          <p>
            El acceso a nuestro Sitio y sus Servicios se permite temporalmente. Nos reservamos el derecho de interrumpir o modificar el acceso a nuestro Sitio o Servicios sin previo aviso. <br/> <br/>
            No somos responsables si, por cualquier razón, nuestro Sitio o los servicios que ofrece no están disponibles en un momento dado o durante un período determinado. 
          </p>
        </div>
        <div className="article-content" id="product-delivery">
          <h2>4. Entrega de los Servicios, período de uso y plazo de conservación</h2>
          <p className="bold">4.1 Plazo de entrega</p>
          <p>
            Wall-Market se compromete a entregar el o los Archivos de los Servicios solicitados en los plazos indicados para cada Servicio, en el marco de la descripción propuesta antes de cualquier pedido. 
          </p>
          <p>
            Los plazos de entrega indicados son en días laborables.
          </p>
          <p>
            En caso de retraso causado por Wall-Market, esta se compromete a ejecutar y entregar los Servicios dentro de un plazo razonable.
          </p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            La posible responsabilidad de Wall-Market se limita únicamente al precio del o los Servicios de un pedido.<br/><br/>
            El Cliente reconoce y acepta que no puede producirse ningún perjuicio adicional (económico o moral) por el retraso en la prestación de un Servicio o su entrega.<br/><br/>
            Si el Cliente considera que tal demora puede causarle un perjuicio adicional (económico o moral), debe renunciar a realizar un pedido en el Sitio.
          </p>
          <p className="bold">4.2 Modalidades de entrega</p>
          <p>
            El o los Archivos resultantes del o los Servicios se entregan al Cliente en su cuenta dedicada, creada en el momento del primer pedido, en el plazo estipulado en la cláusula 4.1.
          </p>
          <p>
            Hay que recordar que el soporte de los Archivos es inmaterial y electrónico. No se facilitará ningún otro formato (p. ej., papel, CD ROM o memoria USB).
          </p>
          <p className="bold">4.3 Período de uso y plazo de conservación de los Archivos en la cuenta dedicada</p>
          <p>El plazo de conservación de un Archivo en la cuenta dedicada del Cliente, y el período de uso de un Archivo varía en función del Servicio.</p>
          <p>Los plazos de conservación y períodos de uso de un Archivo se especifican en la ficha del Servicio.</p>
          <p>En términos generales:</p>
          <p>
            - <span className="bold" style={{textDecoration: "underline"}}>el plazo de conservación</span> de un Archivo en la cuenta dedicada es de <span className="bold" style={{textDecoration: "underline"}}>6 meses</span> a partir de su entrega.
          </p>
          <p className="bold">Una vez que un Archivo ha sido entregado, le corresponde al Cliente descargarlo para poder utilizarlo y asegurar su conservación.</p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              Una vez transcurridos 6 meses, ya no se podrá acceder a los Archivos en la cuenta del Cliente dedicada. Por consiguiente, si el Cliente quiere seguir teniendo acceso a estos, deberá descargarlos y conservarlos. <br/><br/>
              EN CASO DE PÉRDIDA, NO SE ENTREGARÁN COPIAS.
            </b>
          </p>
          <p>
            - <span className="bold" style={{textDecoration: "underline"}}>el período de uso</span> de un Archivo es, en su mayor parte, ilimitado, sujeto a las obligaciones legales que puedan aplicarse y que corresponde al Cliente determinar (p. ej., para los DIAGNÓSTICOS obligatorios).
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>LE CORRESPONDE AL CLIENTE INFORMARSE DEL PERÍODO DE USO DE CADA SERVICIO SOLICITADO.</b><br/><br/>
            Por ejemplo, <span className="bold">los Archivos de una VISITA VIRTUAL tienen un </span> <span className="bold" style={{textDecoration: "underline"}}>período limitado de uso de 6 meses</span>. Este período puede renovarse con el correspondiente pago.
          </p>
        </div>
        <div className="article-content" id="retractation-right">
          <h2>5. Derecho de desistimiento</h2>
          <p>
            El cliente que tenga la condición de consumidor tiene derecho a desistirse sin indicar la razón dentro de los catorce días siguientes a la solicitud de uno de nuestros servicios, excepto en los casos mencionados a continuación. El día en que se firma el contrato no se contará en el período mencionado. Si este plazo expira un sábado, un domingo o un día festivo o no laborable, se prorrogará hasta el primer día laborable siguiente.
          </p>
          <p>
            Con arreglo al artículo L.221-28 del Código de Consumo francés, el derecho de desistimiento no puede ejercerse para determinados contratos, como los siguientes: <br/>
            - contrato de prestación de servicios totalmente realizados antes de que finalice el plazo de desistimiento y cuando la prestación haya comenzado tras el acuerdo previo y expreso del consumidor y la renuncia expresa a su derecho de desistimiento;<br/>
            - contrato de suministro de bienes elaborados según las especificaciones del consumidor o claramente personalizados;<br/>
            - contrato de suministro de contenidos digitales no suministrados en un soporte físico, cuya ejecución ha comenzado tras el acuerdo previo y expreso del consumidor y la renuncia expresa a su derecho de desistimiento.<br/>
          </p>
          <p>
            Para ejercer su derecho de desistimiento, usted deberá notificarnos su decisión de acuerdo con el formulario adjunto en el Anexo I.
          </p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            Por consiguiente, el Cliente que puede beneficiarse de la calificación de «consumidor» en el sentido del Código de Consumo francés reconoce que renuncia expresamente a su derecho de desistimiento para cualquier prestación que se realice en un plazo inferior a 14 días naturales a partir de la confirmación de su pedido.
          </p>
          <p>
            El Cliente consumidor ejercerá su derecho de desistimiento informando a Wall-Market de su decisión de desistirse enviando, antes de que expire el plazo previsto más arriba, el formulario de desistimiento adjunto en el anexo I del presente documento o cualquier otra declaración, sin ambigüedades, en la que exprese su deseo de desistirse.
          </p>
          <p>
            Si el Cliente ejerce su derecho de desistimiento, Wall-Market le reembolsará todos los importes pagados sin demora y a más tardar en un plazo de catorce (14) días a partir de la fecha en que se informa a Wall-Market de la decisión del Cliente de desistirse. El reembolso se realizará mediante el mismo método de pago que el Cliente utilizó para la transacción inicial.
          </p>
        </div>
        <div className="article-content" id="client-duty">
          <h2>6. Obligaciones del cliente</h2>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            El Cliente se compromete a facilitar, <span style={{textDecoration: "underline"}}> en el momento del pedido:</span> (i) datos precisos que permitan ponerse en contacto con él, pero también (ii) relativos a la superficie del bien inmueble objeto de los Servicios. La información relativa a la superficie es decisiva para determinar el precio de los Servicios y el tiempo necesario para su ejecución. Cualquier información errónea sobre este punto tendrá como efecto la revisión del precio de los Servicios, que el Cliente acepta.<br/><br/>
            La presencia del Cliente <span style={{textDecoration: "underline"}}>durante la ejecución de un Servicio</span> que requiera el desplazamiento de un prestador independiente en lugar donde se ubica el bien inmueble es obligatoria.<br/><br/>
            El Cliente se compromete a garantizar que los bienes inmuebles objeto del Servicio estarán limpios y ordenados en el momento de la recepción del prestador, para permitir su ejecución inmediata. En caso de que el estado del bien inmueble no permita la realización de los servicios cuando llegue el prestador, el servicio no se realizará y el Cliente no será reembolsado.<br/><br/>
            De la misma manera, el Cliente se compromete a asegurar sus objetos, especialmente los de valor. Cualquier daño debido al incumplimiento de esta obligación por parte del Cliente será asumido exclusivamente por este.
          </p>
        </div>
        <div className="article-content" id="property-right">
          <h2>7. Limitaciones del derecho a utilizar los Servicios</h2>
          <p>Los Servicios solicitados son propiedad de Wall-Market.</p>
          <p>Por consiguiente, el Cliente solo puede utilizar los Servicios para aumentar el valor de su bien inmueble, para su propio uso o para su venta o alquiler. Cualquier otro uso está prohibido y requiere el acuerdo previo, expreso y por escrito de Wall-Market.</p>
        </div>
        <div className="article-content" id="client-account">
          <h2>8. Funcionamiento de la cuenta del Cliente</h2>
          <p className="bold">8.1 Necesita crear una cuenta de Cliente para realizar un pedido en nuestro Sitio</p>
          <p>Para realizar un pedido en nuestro Sitio, el Cliente debe registrarse y crear una cuenta que le será dedicada. </p>
          <p>Para registrar una cuenta, el Cliente debe proporcionar determinados datos personales. Esta información debe ser precisa y mantenerse actualizada.</p>
          <p className="bold">8.2 Responsabilidad del Cliente acerca de la gestión de la cuenta</p>
          <p>
            El Cliente es responsable de mantener la confidencialidad de sus datos de acceso y de la actividad de su cuenta. Si usted tiene dudas acerca de sus datos de acceso o si cree que han sido pirateados, contáctenos inmediatamente a la dirección <a href="mailto:rgpd@wall-market.com" target="_blank" rel="noopener noreferrer">rgpd@wall-market.com</a> para comunicárnoslo. 
          </p>
          <p className="bold">8.3 Cancelación y desactivación de la cuenta</p>
          <p>
           Podemos desactivar su cuenta y el acceso a los Servicios en cualquier momento, en particular por las siguientes razones:<br/>
            - Incumplimiento de las presentes condiciones generales;  <br/>
            - Problemas o dificultades técnicas o de seguridad;<br/>
            - Periodo de inactividad del Cliente durante un (1) año;<br/>
            - Uso ilegal o fraudulento de la cuenta o el Sitio.
          </p>
          <p>También se puede desactivar la cuenta a petición del Cliente.</p>
          <p>En particular, la cancelación de la cuenta implicará la eliminación de los Archivos que contiene. </p>
          <p>El Cliente reconoce que Wall-Market no podrá ser considerada responsable ante él o cualquier otra persona por razón de la cancelación o desactivación de la cuenta.</p>
        </div>
        <div className="article-content" id="responsibility">
          <h2>9. Exclusión de responsabilidad</h2>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            En caso de que el Cliente demuestre que se ha cometido una falta, Wall-Market solo podrá ser considerada responsable de la compensación de las consecuencias pecuniarias de los daños directos y previsibles.<br/><br/>
            Como resultado, la posible responsabilidad de Wall-Market se limitará al coste del servicio solicitado. Por consiguiente, la responsabilidad de Wall-Market por pérdida de beneficios, lucro cesante o perjuicio moral queda excluida.<br/><br/>
            En cualquier caso, Wall-Market no podrá ser considerada responsable por el retraso en la ejecución de un Servicio, la entrega de un Archivo o la pérdida del mismo.<br/><br/>
            Las limitaciones y exclusiones de esta sección no pretenden limitar la responsabilidad ni modificar los derechos de los Clientes «consumidores».  
          </p>
        </div>
        <div className="article-content" id="personal-data">
          <h2>10. Datos personales</h2>
          <p>
            Los datos personales facilitados para el tratamiento de los servicios ofrecidos por el Sitio están sujetos a las medidas de protección descritas en la página «Aviso legal» del Sitio.<br/><br/>
            Para cualquier pedido de un Servicio en el Sitio, el Cliente autoriza a Wall-Market a transferir los datos necesarios para realizar uno o más Servicios, en particular al prestador seleccionado.
          </p>
        </div>
        <div className="article-content" id="price-refund">
          <h2>11. Precio, reembolso y cancelación</h2>
          <p><span className="bold">11.1</span> El Servicio se presta al precio indicado en el Sitio. </p>
          <p><span className="bold">11.2</span> Se recuerda que en el precio de los «DIAGNÓSTICOS A LA CARTA» se factura un importe fijo por el desplazamiento del diagnosticador además del precio de cada DIAGNÓSTICO seleccionado. </p>
          <p className="bold">11.3 Cancelación por parte del cliente:</p>
          <p>
            - <b>Los Servicios que no impliquen el desplazamiento de un prestador independiente al lugar donde se ubica el bien inmueble </b> podrán cancelarse hasta una hora después de que se haya realizado el pedido del Servicio. <b>Una vez transcurrido este plazo, se facturará el 100 % de los Servicios.</b> <br/>
            - <b>Los Servicios que impliquen el desplazamiento de un prestador independiente al lugar donde se ubica el bien inmueble</b> podrán cancelarse hasta 6 horas antes de la cita con el prestador para la prestación del o los Servicios. <b>Una vez transcurrido este plazo, se facturará el 100 % de los Servicios.</b>
          </p>
          <p><span className="bold">11.4 Cancelación por parte de Wall-Market: </span> Wall-Market puede verse obligada a cancelar la ejecución de un Servicio y por lo tanto se reserva este derecho. En tal supuesto, se informará al Cliente por teléfono o correo electrónico y se le ofrecerá la posibilidad de elegir entre la prestación de los Servicios solicitados en otra fecha o período aceptado por el Cliente O BIEN su reembolso.</p>
          <p><span className="bold">11.5 Condiciones de pago</span></p>
          <p style={{border: "1px solid", padding: 4}}>
             Todos los pagos se realizan a través de la sociedad MangoPay. Por consiguiente, todo pedido de un servicio propuesto por Wall-Market implica la aceptación sin reserva de las condiciones generales de uso de MangoPay, disponibles clicando en el siguiente enlace: <a href="https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf" target="_blank" rel="noopener noreferrer">https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf</a>.
          </p>
          <p>Se emitirá una factura que estará disponible en la cuenta del Cliente dentro de su espacio dedicado.</p>
          <p><span className="bold">11.6 Sanciones en caso de incidencias en el pago</span></p>
          <p>
            Cualquier incidencia en el pago tendrá como consecuencia inmediata y automática, sin un requerimiento previo:<br/>
            - la exigibilidad inmediata de los importes adeudados; <br/>
            - a suspensión inmediata de la ejecución o entrega de los Servicios en curso hasta el pago completo de todos los importes adeudados por el Cliente; <br/>
            - la facturación de intereses de demora a un tipo del 10 % (diez por ciento), calculados sobre el importe total de los importes adeudados por el Cliente y una indemnización fija de 100 (cien) euros por gastos de cobro.
          </p>
        </div>
        <div className="article-content" id="payment-modality">
          <h2>12. Mediación para los clientes consumidores</h2>
          <p>
            <span className="bold">Mediación.</span >En caso de litigio entre el comerciante y el consumidor, ambos se esforzarán por encontrar una solución amistosa. Si no se llega a un acuerdo amistoso, con arreglo al artículo L. 152-1 del Código de Consumo francés, puede recurrir de forma gratuita al servicio de mediación de AME Conso, ya sea electrónicamente: <a href="https://www.mediationconso-ame.com/demande-de-mediation-ame.html" target="_blank" rel="noopener noreferrer">https://www.mediationconso-ame.com/demande-de-mediation-ame.html</a> o por correo postal: AME Conso – 11 place Dauphine 75001 Paris.
          </p>
          <p>
            Así pues, en caso de una reclamación que no se haya resuelto amistosamente y tras una gestión previa por escrito con Wall-Market, se podrá recurrir al Mediador para todo conflicto en materia de consumo que no se haya resuelto. El siguiente enlace ofrece información acerca de los tipos de remisión al Mediador <a href="https://www.mediationconso-ame.com/le-processus-de-la-mediation-de-la-consommation.html" target="_blank" rel="noopener noreferrer">https://www.mediationconso-ame.com/le-processus-de-la-mediation-de-la-consommation.html</a>.
          </p>
          <p>
            A nivel europeo, la Comisión Europea pone a disposición de los consumidores una plataforma de resolución de conflictos en línea, a la que puede acceder clicando en el siguiente enlace: <a href="https://ec.europa.eu/consumers/odr/main/?event=main.trader.register" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/main/?event=main.trader.register</a>.
          </p>
          <p>Wall-Market no está obligada y no se compromete a utilizar un método alternativo de resolución de conflictos para resolver todo conflicto con un consumidor.</p>
          <p>
            <b>Plataforma de resolución de conflictos en línea.</b>. Con arreglo al artículo 14 del Reglamento (UE) 524/2013, la Comisión Europea ha creado una plataforma de resolución de conflictos en línea, que facilita la resolución extrajudicial e independiente de los conflictos en línea entre consumidores y profesionales de la Unión Europea. Se puede acceder a esta plataforma a través del siguiente enlace: <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage</a>
          </p>
        </div>
        <div className="article-content" id="applicable-law">
          <h2>13. Tribunal competente y derecho aplicable</h2>
          <p>Las presentes condiciones generales de venta están sujetas al derecho francés.</p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
          En caso de litigio, solo serán competentes los tribunales franceses de la ciudad de París (75), salvo disposición imperativa en contrario (p. ej., la acción de un consumidor francés). 
          </p>
        </div>
      </div>

      
    );
  }
}

export default TermsOfUseES;
