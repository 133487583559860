import React from 'react';
import Dialog from 'material-ui/Dialog';
import { change, formValueSelector } from 'redux-form';
import { subscribe } from 'redux-subscriber';

import HomeShopPropertyForm from './HomeShopPropertyForm';

import { apiGeocode } from '../../../../helpers/ApiFunctions';
import { getBrandPhoneNumber } from '../../../../helpers/UserFunctions';
import { setPropertyLocation } from '../../../../helpers/ShopFunctions';

import store from '../../../../redux/store';

const noPlaceStyle = {
  backgroundColor: "transparent",
  cursor: 'default',
  color: '#b5b5b5'
};

class HomeShopPropertyWrapper extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      bufferValue: null,
      currentAdrsValue: null,
      isGeocodeLoading: false,
      isNoGoogleplaceDialogOpen: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openNoGoogleplaceResultDialog = this.openNoGoogleplaceResultDialog.bind(this);
    this.closeNoGoogleplaceResultDialog = this.closeNoGoogleplaceResultDialog.bind(this);

    this.subscribeToAddress = undefined;
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.subscribeToAddress = subscribe('form.homeShopPropertyForm.values', state => {
      if (state.form.homeShopPropertyForm) {
        if (state.form.homeShopPropertyForm.values && state.form.homeShopPropertyForm.values.address) {
          this.setState({ bufferValue: state.form.homeShopPropertyForm.values.address });
        }
        else {
          this.setState({ bufferValue: null });
        }
        if (state.form.homeShopPropertyForm.values && state.form.homeShopPropertyForm.values.addressAutocomplete) {
          this.setState({ currentAdrsValue: state.form.homeShopPropertyForm.values.addressAutocomplete });
        }
        else {
          this.setState({ currentAdrsValue: null });
        }
      }
    });
  }

  componentWillUnmount() {
    this.subscribeToAddress();
    this.subscribeToAddress = undefined;
  }

  openNoGoogleplaceResultDialog() {
    const state = store.getState();
    const selector = formValueSelector('homeShopPropertyForm');
    const currentAdrsValue = selector(state, 'addressAutocomplete');
    const bufferValue = selector(state, 'address');

    if (!bufferValue && currentAdrsValue) {
      this.setState({ isGeocodeLoading: true });
      apiGeocode(
        {address: currentAdrsValue},
        data => {
          if (data.lat && data.lng) {
            const location = {
              lat: data.lat,
              lng: data.lng
            };
            setPropertyLocation(location);
          }
          if (data.city && data.street) {
            store.dispatch(change('homeShopPropertyForm', 'address', data.full));
            store.dispatch(change('homeShopPropertyForm', 'addressAutocomplete', data.full));
          }
          else {
            store.dispatch(change('homeShopPropertyForm', 'address', ''));
            store.dispatch(change('homeShopPropertyForm', 'addressAutocomplete', ''));
            this.setState({ isNoGoogleplaceDialogOpen: true });
          }

          this.setState({isGeocodeLoading: false});
        },
        () => {
          store.dispatch(change('homeShopPropertyForm', 'address', ''));
          store.dispatch(change('homeShopPropertyForm', 'addressAutocomplete', ''));
          this.setState({ isNoGoogleplaceDialogOpen: true });
          this.setState({ isGeocodeLoading: false });
        }
      );
    }
  }

  closeNoGoogleplaceResultDialog() {
    this.setState({ isNoGoogleplaceDialogOpen: false });
  }

  handleSubmit(values) {

    if (!values) return;

    const history = this.props.homeProps.history;

    /**
     * Send property to shop step 2
     */
    history.push({
      pathname: '/shop/2',
      property: values
    });
  }

  render() {

    const {
      bufferValue,
      currentAdrsValue,
      isGeocodeLoading,
      isNoGoogleplaceDialogOpen
    } = this.state;

    const {
      Translation,
      unitSystem
    } = this.props;

    return (
      <div className="container inputs-container">
        <div className="row">
          <HomeShopPropertyForm
            onSubmit={this.handleSubmit}
            Translation={Translation}
            labels={Translation().shop.step_fields.names}
            unitSystem={unitSystem}
          />
        </div>
        <div form="homeShopPropertyForm" />
        { isGeocodeLoading
          ? (
            <div className="trigger-geocode loading">
              <div className="linear-bullets-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )
          : <p className="trigger-geocode" style={!bufferValue && currentAdrsValue ? {} : noPlaceStyle} onClick={this.openNoGoogleplaceResultDialog}>{Translation().shop.informations.property_not_found.link}</p>
        }
        <Dialog
          modal={false}
          actions={[<div onClick={this.closeNoGoogleplaceResultDialog} className="close-tos-modal">{Translation().pages.home.page_title.buttons.close}</div>,]}
          className="api-modal"
          autoScrollBodyContent={true}
          title={Translation().shop.informations.property_not_found.title}
          onRequestClose={this.closeNoGoogleplaceResultDialog}
          open={isNoGoogleplaceDialogOpen}>
          <p>{Translation().shop.informations.property_not_found.desc}</p>
          <a href={"tel:" + getBrandPhoneNumber()}>
            <span className="icon-telephone" />
            <span className="phone-number">{getBrandPhoneNumber()}</span>
          </a>
        </Dialog>
      </div>

    );
  }
}

export default HomeShopPropertyWrapper;
