import React, { Component } from "react";
import { Col, Clearfix } from "react-bootstrap";
import $ from "jquery";
import { reset } from "redux-form";

import store from "../../redux/store";
import { CallApi } from "../../helpers/ApiFunctions";
import { getLocale } from "../../helpers/UserFunctions";
import Config from "../../config/Config";

import Translation from "../../translation/Translation";

class ProviderTestimonial extends Component {
  render() {
    return (
      <div className="provider-slider">
        <div className="advantages-slides">
          <Col sm={12} md={8} lg={6} className="no-padding col-with-padding">
            <p className="advantages-text">
              {Translation().pages.recruitment.advantages.agenda.title}
            </p>
            <ul>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.agenda.texts[0]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.agenda.texts[1]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.agenda.texts[2]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.agenda.texts[3]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.agenda.texts[4]}
              </li>
            </ul>
          </Col>
          <Col sm={12} md={4} lg={6} className="no-padding">
            <img
              src="/assets/images/recruitment/recruitment-pic-slider-1.png"
              alt="agenda"
            />
          </Col>
          <Clearfix />
        </div>
        <div className="advantages-slides">
          <Col sm={12} md={8} lg={6} className="no-padding col-with-padding">
            <p className="advantages-text">
              {Translation().pages.recruitment.advantages.invoices.title}
            </p>
            <ul>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.invoices.texts[0]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.invoices.texts[1]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.invoices.texts[2]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.invoices.texts[3]}
              </li>
            </ul>
          </Col>
          <Col sm={12} md={4} lg={6} className="no-padding">
            <img
              src="/assets/images/recruitment/recruitment-pic-slider-2.png"
              alt="invoices"
            />
          </Col>
          <Clearfix />
        </div>
        <div className="advantages-slides">
          <Col sm={12} md={8} lg={6} className="no-padding col-with-padding">
            <p className="advantages-text">
              {Translation().pages.recruitment.advantages.metrics.title}
            </p>
            <ul>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.metrics.texts[0]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.metrics.texts[1]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.metrics.texts[2]}
              </li>
              <li>
                <span className="icon-check" />{" "}
                {Translation().pages.recruitment.advantages.metrics.texts[3]}
              </li>
            </ul>
          </Col>
          <Col sm={12} md={4} lg={6} className="no-padding">
            <img
              src="/assets/images/recruitment/recruitment-pic-slider-3.png"
              alt="metrics"
            />
          </Col>
          <Clearfix />
        </div>
      </div>
    );
  }
}

class Recruitment extends Component {
  sendRecruitmentForm(values) {
    if (
      !values ||
      !values.lastName ||
      !values.firstName ||
      !values.email ||
      !values.phone ||
      !values.postalCode
    ) {
      return;
    }

    const text =
      "Nouvelle candidature depuis " +
      Config.app_name +
      "." +
      "\nNom de la personne: " +
      values.lastName +
      "\nPrénom de la personne: " +
      values.firstName +
      "\nEmail: " +
      values.email +
      "\nNuméro de téléphone: " +
      values.phone +
      (values.address ? "\nAdresse: " + values.address : "") +
      (values.postalCode ? "\nCode postal: " + values.postalCode : "") +
      (values.website ? "\nPortfolio: " + values.website : "") +
      (values.date_of_birth
        ? "\nDate de naissance: " + values.date_of_birth
        : "") +
      (values.locomotive ? "\nMoyen de locomotion: " + values.locomotive : "");

    let recruitmentObj = {
      text: text,
      from: {},
    };
    recruitmentObj.from[values.lastName] = values.email;

    const btnRecruitmentText = document.getElementById(
      "wrapper-recruitement-text"
    );
    const sendRecruitmentIcon = document.getElementById("send-rctm-icon");
    const sendRecruitmentText = document.getElementById("send-rctm-txt");
    const sendRecruitmentButton = document.getElementById("send-recruitement");
    const apiLoader = document.getElementById("api-recruitement-loader");

    if (btnRecruitmentText && apiLoader) {
      btnRecruitmentText.style.display = "none";
      apiLoader.style.display = "block";
    }

    CallApi(
      "post",
      "notifier/contact-join/send",
      null,
      recruitmentObj,
      null,
      (success) => {
        if (sendRecruitmentIcon && sendRecruitmentText) {
          sendRecruitmentIcon.classList.remove("icon-envoyer");
          sendRecruitmentIcon.classList.add("icon-check");
          sendRecruitmentText.innerHTML = "Envoyé !";
        }

        if (sendRecruitmentButton) {
          sendRecruitmentButton.disabled = "true";
        }
        if (apiLoader && btnRecruitmentText) {
          apiLoader.style.display = "none";
          btnRecruitmentText.style.display = "block";
        }

        /**
         * Clean recruitment form fields
         */
        store.dispatch(reset("recruitment"));
      },
      (error) => {
        if (apiLoader && btnRecruitmentText) {
          apiLoader.style.display = "none";
          btnRecruitmentText.style.display = "block";
        }
      }
    );
  }

  renderRecruitmentLink() {
    const locale = getLocale();
    const frLink = "https://blog.wall-market.com/recrutement";
    const itLink = "https://blog.wall-market.com/recrutement";
    const esLink = "https://blog.wall-market.com/recrutement";
    const enLink = "https://blog.wall-market.com/recrutement";

    if (locale) {
      if (locale === "fr") {
        return frLink;
      } else if (locale === "en") {
        return enLink;
      } else if (locale === "it") {
        return itLink;
      } else if (locale === "es") {
        return esLink;
      } else {
        return frLink;
      }
    }
    return frLink;
  }

  render() {
    const recruitementLink = this.renderRecruitmentLink();

    return (
      <div className="app-recruitment">
        <div className="app-overlay-video">
          <div className="container overlay-content">
            <iframe
              id="htw-video"
              className="overlay-video"
              src="https://player.vimeo.com/video/237413337?title=0&byline=0&portrait=0"
              width="640"
              height="360"
              frameBorder="0"
              allowFullScreen
              title="Comment ça marche?"
            ></iframe>
            <div className="close-video">
              <span className="icon-fermer close-icon" />
              {Translation().pages.recruitment.close}
            </div>
          </div>
        </div>
        <div className="recruitment-intro">
          <div className="container">
            <div className="row">
              <Col xs={12} sm={6} md={6}>
                <img
                  src="/assets/images/recruitment/providers-recruitment-avatar.png"
                  className="providers-avatars mobile"
                  alt="France providers"
                />
                <h1>{Translation().pages.recruitment.page_title.title}</h1>
                <h2>{Translation().pages.recruitment.page_title.baseline}</h2>
                <div className="btn-wrapper">
                  <a
                    href={recruitementLink}
                    target="blank"
                    rel="noreferrer noopener"
                    className="app-button-color app-button-recruit"
                  >
                    {Translation().pages.recruitment.page_title.buttons.apply}
                  </a>
                  <div className="app-button-video play-video">
                    <span className="icon-player"></span>
                    {
                      Translation().pages.recruitment.page_title.buttons
                        .how_it_work
                    }
                  </div>
                  <img
                    src="/assets/images/recruitment/providers-recruitment-avatar.png"
                    className="providers-avatars desktop"
                    alt="France providers"
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} className="map-col">
                <img
                  className="app-showcase"
                  src="/assets/images/recruitment/recruitment-mobileapp-showcase-2.png"
                  alt="App mobile"
                />
              </Col>
              <Clearfix />
            </div>
          </div>
        </div>
        <section className="recruitment-details">
          <div className="container">
            <div className="recruitment-details-intro">
              <h3>{Translation().pages.recruitment.description.title}</h3>
              <p>{Translation().pages.recruitment.description.detail}</p>
            </div>
            <div className="details-sections row">
              <Col xs={12} sm={4} md={4}>
                <div className="detail-job-recruitment">
                  <Col sm={12} md={3} lg={2}>
                    <span className="icon-calendrier icon-job" />
                  </Col>
                  <Col sm={12} md={9} lg={10} className="col-no-padding">
                    <h3>{Translation().pages.recruitment.liberty.title}</h3>
                    <p>{Translation().pages.recruitment.liberty.text}</p>
                  </Col>
                  <Clearfix />
                </div>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <div className="detail-job-recruitment">
                  <Col sm={12} md={3} lg={2}>
                    <span className="icon-revenus icon-job" />
                  </Col>
                  <Col sm={12} md={9} lg={10} className="col-no-padding">
                    <h3>{Translation().pages.recruitment.salary.title}</h3>
                    <p>{Translation().pages.recruitment.salary.text}</p>
                  </Col>
                  <Clearfix />
                </div>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <div className="detail-job-recruitment">
                  <Col sm={12} md={3} lg={2}>
                    <span className="icon-competences icon-job" />
                  </Col>
                  <Col sm={12} md={9} lg={10} className="col-no-padding">
                    <h3>{Translation().pages.recruitment.learning.title}</h3>
                    <p>{Translation().pages.recruitment.learning.text}</p>
                  </Col>
                  <Clearfix />
                </div>
              </Col>
              <Clearfix />
            </div>
          </div>
        </section>
        <section className="logos-recrutement">
          <h3>{Translation().pages.recruitment.partners.title}</h3>
          <img
            src="/assets/images/recruitment/logos-recrutement-prestataire.png"
            alt="Logos partenaires"
          />
        </section>
        <section className="ProviderTestimonial">
          <ProviderTestimonial />
        </section>
        <div className="testimontials-recruitment">
          <div className="container">
            <div>
              <Col sm={12} md={6} lg={6} className="col-testimonial-avatar" />
              <Col sm={12} md={6} lg={6} className="col-testimonial-text">
                <div className="text-temoignage">
                  <Col sm={12} md={2} lg={2} className="no-padding">
                    <div className="icon-temoignage" />
                  </Col>
                  <Col sm={12} md={8} lg={8} className="no-padding">
                    <p className="testimonials-text">
                      {
                        Translation().pages.recruitment.testimonials.quentin
                          .text
                      }
                    </p>
                    <p className="testimonials-author">
                      {
                        Translation().pages.recruitment.testimonials.quentin
                          .author
                      }
                    </p>
                  </Col>
                  <Clearfix />
                </div>
              </Col>
              <Clearfix />
            </div>
          </div>
        </div>
        <div className="want-join-us">
          <div className="container">
            <div className="row">
              <Col sm={12} md={8} lg={8}>
                <h3>{Translation().pages.recruitment.join.title}</h3>
              </Col>
              <Col sm={12} md={4} lg={4}>
                <a
                  href={recruitementLink}
                  target="blank"
                  rel="noreferrer noopener"
                  className="app-button-color"
                >
                  {Translation().pages.recruitment.join.button}
                </a>
              </Col>
              <Clearfix />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Recruitment;

$(document).ready(function () {
  $(".provider-slider").slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  /**
   * Video how it work iframe
   */
  const srcVideo =
    "https://player.vimeo.com/video/237413337?title=0&byline=0&portrait=0";

  /**
   * Open video modal
   */
  $(".play-video").on("click", function () {
    $(".app-overlay-video").fadeIn(300);
    $("#htw-video").attr("src", srcVideo);
  });

  function closeIframeVideo() {
    $(".app-overlay-video").fadeOut(300);
    $("#htw-video").attr("src", "");
  }

  /**
   * Close video modal
   */
  $(".close-video").on("click", function () {
    closeIframeVideo();
  });
  $(".app-overlay-video").on("click", function () {
    closeIframeVideo();
  });

  /**
   * Close video modal on ESC key press
   */
  $(window, document).on("keydown", function (e) {
    if (e.keyCode === 27) {
      closeIframeVideo();
    }
  });
});
