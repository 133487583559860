import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { initPlaceholder } from '../../../helpers/JqueryFunctions';
import validate from './validate';
import renderField from '../fields/renderField';

import Translation from '../../../translation/Translation';

class LoginForm extends Component {

  componentDidMount() {
    initPlaceholder();
  }

  render() {
    const { handleSubmit, submitting, dirty } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          type="email"
          component={renderField}
          label={Translation().login.form.labels.email}
          className="app-basic-input"
        />
        <Field
          name="password"
          type="password"
          component={renderField}
          label={Translation().login.form.labels.password}
          className="app-basic-input"
        />
        <a className="forgot-pwd" href="/requestPassword">{Translation().login.form.labels.forgotten_password}</a>
        <button type="submit" id="btn-login" className="app-button-color button-api" disabled={submitting}>
          <span className="loader-api loader-api-auth" />
          <span className="btn-login-text">{Translation().login.form.buttons.submit}</span>
        </button>
        { dirty && <div className="login-errors"><span className="error-message" /></div> }
      </form>
    );
  }
}

export default reduxForm({
  form: 'login',
  validate
})(LoginForm);
