import React, { Component } from 'react';
import { reset } from 'redux-form';
import {
  Clearfix,
  Col,
  Navbar,
  Nav,
  NavItem
} from 'react-bootstrap';
import Dialog from 'material-ui/Dialog';

import store from '../../../redux/store';
import Config from '../../../config/Config';
import { CallApi } from '../../../helpers/ApiFunctions';
import Snackbar from '../snackbar/Snackbar';
import NewsletterForm from '../newsletter/NewsletterForm';
import Translation from '../../../translation/Translation';
import { getSupportEmail } from '../../../helpers/UserFunctions';

class Footer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isApiDialogOpen: false,
      autoHideDuration: 4000,
      isSnackbarOpen: undefined,
      snackbarMessage: '',
      snackbarStatus: ''
    }

    this.openApiDialog = this.openApiDialog.bind(this);
    this.closeApiDialog = this.closeApiDialog.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.sendNewsletter = this.sendNewsletter.bind(this);
  }

  openApiDialog() {
    this.setState({ isApiDialogOpen: true });
  }

  closeApiDialog() {
    this.setState({ isApiDialogOpen: false });
  }

  openSnackbar() {
    this.setState({ isSnackbarOpen: true });
  };

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  };

  sendNewsletter(values) {

    if (!values || !values.email) {
      return;
    }

    const nwslSubmit = document.getElementById('nwsl-submit');
    const nwslOuput = document.getElementById('nwsl-output');

    if (nwslSubmit) {
      nwslSubmit.style.display = "none";
    }
    if (nwslOuput) {
      nwslOuput.style.display = "block";
      nwslOuput.innerHTML = '<span class="loader-api block"></span>';
    }

    const successMessage = Translation().footer.newsletter.form.callbacks.success;
    const errorMessage = Translation().footer.newsletter.form.callbacks.error;
    const text = Translation().footer.newsletter.form.callbacks.email_message + Config.app_name + ".\nEmail: " + values.email;

    let contactObj = {
      text: text,
      from: {}
    }

    contactObj.from[values.email] = values.email;

    CallApi(
      'post',
      'notifier/contact/send',
      null,
      contactObj,
      null,
      (success) => {
        if (nwslSubmit) {
          nwslSubmit.style.display = "block";
        }
        if (nwslOuput) {
          nwslOuput.style.display = "none";
          nwslOuput.innerHTML = '<span class="send-icon icon-envoyer"></span>';
        }
        this.setState({ snackbarMessage: successMessage, snackbarStatus: "success"});
        this.openSnackbar();
        store.dispatch(reset('newsletter'));
      },
      (error) => {
        if (nwslSubmit) {
          nwslSubmit.style.display = "block";
        }
        if (nwslOuput) {
          nwslOuput.style.display = "none";
          nwslOuput.innerHTML = '<span class="send-icon icon-envoyer"></span>';
        }
        this.setState({ snackbarMessage: errorMessage, snackbarStatus: "error"});
        this.openSnackbar();
      }
    );
  }

  render() {

    return (
      <footer className="app-footer">
        <div className="banner-footer">
          <div className="container">
            <div className="row">
              <Col xs={6} sm={6} md={3} className="col">
                <span className="icon-marketplace icon-banner"/>
                <span className="text-banner">
                  <span className="txtb">{Translation().footer.banner.marketplace.title}</span>
                  {Translation().footer.banner.marketplace.text}
                </span>
              </Col>
              <Col xs={6} sm={6} md={3} className="col">
                <span className="icon-offrepacks icon-banner"/>
                <span className="text-banner">
                  <span className="txtb">{Translation().footer.banner.pack_offer.title}</span>
                  {Translation().footer.banner.pack_offer.text}
                </span>
              </Col>
              <Col xs={6} sm={6} md={3} className="col">
                <span className="icon-liveplanning icon-banner"/>
                <span className="text-banner">
                  <span className="txtb">{Translation().footer.banner.planning.title}</span>
                  {Translation().footer.banner.planning.text}
                </span>
              </Col>
              <Col xs={6} sm={6} md={3} className="col">
                <span className="icon-rea04 icon-banner"/>
                <span className="text-banner">
                  <span className="txtb">{Translation().footer.banner.secure_payment}</span>
                  <img width="auto" height="15" src="/assets/images/commons/powered-by-mangopay.svg" alt="powered by mangopay"/>
                </span>
              </Col>
            </div>
          </div>
        </div>
        <Clearfix />
        <div className="news-follow-footer">
          <div className="container">
            <div className="row">
              <Col xs={12} sm={12} md={6} className="col newsletter">
                <h4>{Translation().footer.newsletter.title}</h4>
                <p>{Translation().footer.newsletter.description}</p>
                <NewsletterForm
                  labels={Translation().footer.newsletter.form.labels}
                  onSubmit={this.sendNewsletter}
                />
                <div form="newsletter"/>
              </Col>
              <Col xs={12} sm={12} md={6} className="col follow-footer">
                <h4>{Translation().footer.follow_us.title}</h4>
                <p>{Translation().footer.follow_us.description}</p>
                <div>
                  <a href="https://www.facebook.com/wallmarketlightshop/" target="_blank" rel="noopener noreferrer"><span className="icon-facebook icon-social"></span></a>
                  <a href="https://twitter.com/wallmarketserv" target="_blank" rel="noopener noreferrer"><span className="icon-twitter icon-social"></span></a>
                  <a href="https://www.instagram.com/lightshopwm/" target="_blank" rel="noopener noreferrer"><span className="icon-instagram icon-social"></span></a>
                  <a href="https://www.linkedin.com/company/2598629/" target="_blank" rel="noopener noreferrer"><span className="icon-linkedin icon-social"></span></a>
                  <a href="https://fr.pinterest.com/wallmarket/" target="_blank" rel="noopener noreferrer"><span className="icon-pinterest icon-social"></span></a>
                </div>
              </Col>
            </div>
          </div>
        </div>
        <Clearfix/>
        <Snackbar
          messageText={this.state.snackbarMessage}
          statusClass={this.state.snackbarStatus}
          open={this.state.isSnackbarOpen}
          autoHideDuration={this.state.autoHideDuration}
          closeFunction={this.closeSnackbar}
        />
        <Navbar>
          <Navbar.Text className="device-stores">
            <a href="http://onelink.to/lightshop"><img src="/assets/images/commons/googleplay.jpg" alt="Get it on GooglePlay" /></a>
            <a href="http://onelink.to/lightshop"><img src="/assets/images/commons/appstore.jpg" alt="Get it on AppStore" /></a>
          </Navbar.Text>
          <Navbar.Text className="copyright-desktop">&copy; {Config.current_year} {Config.app_name} <span className="copyright-text">{Translation().footer.sub_footer.copyright}</span></Navbar.Text>
          <Nav pullRight>
            <NavItem eventKey={1} onClick={this.openApiDialog}>{Translation().footer.sub_footer.nav.api}</NavItem>
            <NavItem eventKey={2} href="/recruitment">{Translation().footer.sub_footer.nav.recruitment}</NavItem>
            <NavItem eventKey={3} href="/faq">{Translation().footer.sub_footer.nav.faq}</NavItem>
            <NavItem eventKey={4} href="/about">{Translation().footer.sub_footer.nav.about}</NavItem>
            <NavItem eventKey={5} href="/contact">{Translation().footer.sub_footer.nav.contact}</NavItem>
            <NavItem eventKey={6} href="/termsOfUse">{Translation().footer.sub_footer.nav.terms_of_use}</NavItem>
            <NavItem eventKey={7} href="/privacy-policy">{Translation().footer.sub_footer.nav.privacy_policy}</NavItem>
            <NavItem eventKey={8} href="/legalNotice">{Translation().footer.sub_footer.nav.legal_notice}</NavItem>
          </Nav>
          <Navbar.Text className="copyright-mobile">&copy; {Config.current_year} {Config.app_name} <span className="copyright-text">{Translation().footer.sub_footer.copyright}</span></Navbar.Text>
        </Navbar>
        <div id="frames-container" />
        <Dialog
          modal={false}
          actions={[<div onClick={this.closeApiDialog} className="close-tos-modal">{Translation().api.modal.close}</div>,]}
          className="api-modal"
          autoScrollBodyContent={true}
          title={Translation().api.modal.title}
          onRequestClose={this.closeApiDialog}
          open={this.state.isApiDialogOpen}>
            {Translation().api.modal.description} <a href={"mailto:" + getSupportEmail()}>{getSupportEmail()}</a>
         </Dialog>
      </footer>
    );
  }
}

export default Footer;
