import React, { Component } from 'react';

import Translation from '../../../translation/Translation';

export class TestimonialHomeSlider extends Component {

  render() {

    return (
      <div className="testimonials-home-slider">
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/Baptiste_Bordeleau.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.home.Baptiste_Bordeleau.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.home.Baptiste_Bordeleau.author}</p>
          </div>
        </div>
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/Guilaine_Gougnard.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.home.Guilaine_Gougnard.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.home.Guilaine_Gougnard.author}</p>
          </div>
        </div>
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/Philippe_Thomas.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.home.Philippe_Thomas.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.home.Philippe_Thomas.author}</p>
          </div>
        </div>
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/Cedric_de_Labouchere.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.home.Cedric_de_Labouchere.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.home.Cedric_de_Labouchere.author}</p>
          </div>
        </div>
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/emeric_lelong.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.home.emeric_lelong.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.home.emeric_lelong.author}</p>
          </div>
        </div>
      </div>
    );
  }
}

export class TestimonialAboutSlider extends Component {

  render() {

    return (
      <div className="testimonials-about-slider">
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/Baptiste_Bordeleau.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.about.Baptiste_Bordeleau.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.about.Baptiste_Bordeleau.author}</p>
          </div>
        </div>
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/Guilaine_Gougnard.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.about.Guilaine_Gougnard.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.about.Guilaine_Gougnard.author}</p>
          </div>
        </div>
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/Philippe_Thomas.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.about.Philippe_Thomas.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.about.Philippe_Thomas.author}</p>
          </div>
        </div>
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/Cedric_de_Labouchere.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.about.Cedric_de_Labouchere.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.about.Cedric_de_Labouchere.author}</p>
          </div>
        </div>
        <div className="testimonials-slides">
          <div className="container">
            <img src="/assets/images/commons/emeric_lelong.jpg" alt="People" />
            <p className="testimonials-text">{Translation().pages.sliders.testimonials.about.emeric_lelong.text}</p>
            <p className="testimonials-author">{Translation().pages.sliders.testimonials.about.emeric_lelong.author}</p>
          </div>
        </div>
      </div>
    );
  }
} 