import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import bowser from 'bowser';
import $ from 'jquery';
import Dialog from 'material-ui/Dialog';

import AnimFunctions from '../../helpers/AnimFunctions';
import { TestimonialHomeSlider } from '../../components/commons/sliders/TestimonialSliders';
import ClientSlider from './components/ClientSlider';
import Milestones from './components/Milestones';
import HowItWorkStepper from './components/HowItWorkStepper';
import HomeShopPropertyWrapper from './components/homeShopPropertyForm/HomeShopPropertyWrapper';
import Translation from '../../translation/Translation';
import { getUnitSystemSymbol } from '../../helpers/HelperFunctions';
import { getSupportEmail } from '../../helpers/UserFunctions';

class Home extends Component  {

  constructor() {
    super();

    this.animations = {
      slides: {
        shooting: { cover: 'shooting-cover', detail: 'shooting-detail', animation: 'left-to-right', done: false },
        floorplan: { cover: 'plans-cover', detail: 'plans-detail', animation: 'right-to-left', done: false },
        vv: { cover: 'vv-cover', detail: 'vv-detail', animation: 'left-to-right', done: false },
        videoDrone: { cover: 'videos-cover', detail: 'videos-detail', animation: 'right-to-left', done: false },
      },
      fades: {
        diagnostic: { id: 'diagnostic-detail', done: false },
        inventory: { id: 'inventory-detail', done: false },
        apiRecruiting: { id: 'api-recruiting', done: false }
      }
    };

    this.state = {
      isApiDialogOpen: false,
    }

    this.openApiDialog = this.openApiDialog.bind(this);
    this.closeApiDialog = this.closeApiDialog.bind(this);
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    const slideToDo = this.animations.slides;
    const fadeToDo = this.animations.fades;
    const removeHeight = 500;
    let mq = false;

    if (bowser.name === 'Internet Explorer' && bowser.version < 10) {
      mq = true;
    }
    else {
      mq = window.matchMedia("(max-width: 991px)");
    }

    for (let slide in slideToDo) {
      const cover = document.getElementById(slideToDo[slide].cover);
      const detail = document.getElementById(slideToDo[slide].detail);
      const offsetCover = AnimFunctions.CalcOffset(cover);

      if (!mq.matches) {
        if (!slideToDo[slide].done) {
          if (window.scrollY >= offsetCover.top - removeHeight || document.documentElement.scrollTop >= offsetCover.top - removeHeight || document.body.parentNode.scrollTop >= offsetCover.top - removeHeight || document.body.scrollTop >= offsetCover.top - removeHeight) {
            if (slideToDo[slide].animation === 'left-to-right') {
              AnimFunctions.ShowFromLeftCover(cover);
              AnimFunctions.ShowFromRightDetail(detail);
            }
            else if (slideToDo[slide].animation === 'right-to-left') {
              AnimFunctions.ShowFromRightCover(cover);
              AnimFunctions.ShowFromLeftDetail(detail);
            }
            slideToDo[slide].done = true;
          }
        }
      }
    }
    for (let fade in fadeToDo) {
      const avatar = document.getElementById(fadeToDo[fade].id);
      const offsetAvatar = AnimFunctions.CalcOffset(avatar);

      if (!mq.matches) {
        if (!fadeToDo[fade].done) {
          if (window.scrollY >= offsetAvatar.top - removeHeight * 2 || document.documentElement.scrollTop >= offsetAvatar.top - removeHeight * 2 || document.body.parentNode.scrollTop >= offsetAvatar.top - removeHeight * 2 || document.body.scrollTop >= offsetAvatar.top - removeHeight * 2) {
            AnimFunctions.ShowFade(avatar);
            fadeToDo[fade].done = true;
          }
        }
      }
    }
  }

  openApiDialog() {
    this.setState({ isApiDialogOpen: true });
  }

  closeApiDialog() {
    this.setState({ isApiDialogOpen: false });
  }

  render() {

    return (
      <div className="app-home">
        <section className="intro-home">
          <div className="container">
            <div className="app-main-title">
              <h1>{Translation().pages.home.page_title.main}</h1>
              <h2>{Translation().pages.home.page_title.highlight}</h2>
            </div>
          </div>
          <div className="intro-command">
            <HomeShopPropertyWrapper
              homeProps={this.props}
              Translation={Translation}
              unitSystem={getUnitSystemSymbol()}
            />
          </div>
          <div className="intro-video">
            <div className="play-video trigger-video">
              <span className="icon-player" />
              <p>{Translation().pages.home.how_it_work.title}</p>
            </div>
          </div>
        </section>
        <div className="app-overlay-video">
          <div className="container overlay-content">
            <div className="video-wrapper">
              <iframe
                id="htw-video"
                className="overlay-video"
                src="https://player.vimeo.com/video/237413337?title=0&byline=0&portrait=0"
                width="640"
                height="360"
                frameBorder="0"
                allowFullScreen
                title={Translation().pages.home.how_it_work.title}
              />
              <div className="close-video">
                <span className="icon-fermer close-icon"/>
                {Translation().pages.home.page_title.buttons.close}
              </div>
            </div>
          </div>
        </div>
        <section className="packs-home">
          <h3 className="packs-title">{Translation().pages.home.packs_intro.title}</h3>
          <div className="packs-home-slider container">
            <div className="pack-slide">
              <Col sm={12} md={6} className="pack-slide-illustration desktop">
                <img src="/assets/images/home/home-pack-boost-slide-illustration.png" alt="Boost pack" />
              </Col>
              <Col sm={12} md={6} className="pack-slide-content">
                <div className="pack-slide-tabs">
                  <div className="pack-slide-tab">
                    <div className="pack-slide-icon"><img src="/assets/images/commons/icon-pack-boost-light.png" alt="Pack Boost" /> <p>{Translation().pages.scan_packs.products.boost.pack} <span>{Translation().pages.scan_packs.products.boost.title}</span></p></div>
                    <h3>{Translation().pages.scan_packs.products.boost.sub_title}</h3>
                    <div className="chip-container">
                      <div className="app-chip">{Translation().pages.scan_packs.products.boost.offers.visit.title}</div>
                      <div className="app-chip">{Translation().pages.scan_packs.products.boost.offers.plan.title}</div>
                      <div className="app-chip">{Translation().pages.scan_packs.products.boost.offers.capture.title}</div>
                    </div>
                    <div className="prices-wrapper">
                      <a className="app-button-color" href="/shop">{Translation().pages.home.page_title.buttons.order}</a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} className="pack-slide-illustration mobile">
                <img src="/assets/images/home/home-pack-boost-slide-illustration.png" alt="Boost pack" />
              </Col>
              <Clearfix/>
            </div>
            <div className="pack-slide">
              <Col sm={12} md={6} className="pack-slide-illustration desktop">
                <img src="/assets/images/home/home-pack-pro-1-slide-illustration.png" alt="Boost pack" />
              </Col>
              <Col sm={12} md={6} className="pack-slide-content">
                <div className="pack-slide-tabs">
                  <div className="pack-slide-tab">
                    <div className="pack-slide-icon"><img src="/assets/images/commons/icon-pack-pro-1.png" alt="Pack Pro" /> <p>{Translation().pages.scan_packs.products.max.pack} <span>{Translation().pages.scan_packs.products.max.title}</span></p></div>
                    <h3>{Translation().pages.pro_packs.products.photoshoot_plan.sub_title}</h3>
                    <div className="chip-container">
                      <div className="app-chip">{Translation().pages.pro_packs.products.photoshoot_plan.offers.photo.title}</div>
                      <div className="app-chip">{Translation().pages.pro_packs.products.photoshoot_plan.offers.plan.title}</div>
                    </div>
                    <div className="prices-wrapper">
                      <a className="app-button-color" href="/shop">{Translation().pages.home.page_title.buttons.order}</a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} className="pack-slide-illustration mobile">
                <img src="/assets/images/home/home-pack-pro-1-slide-illustration.png" alt="Pack Pro" />
              </Col>
              <Clearfix/>
            </div>
            <div className="pack-slide">
              <Col sm={12} md={6} className="pack-slide-illustration desktop">
                <img src="/assets/images/home/home-pack-pro-2-slide-illustration.png" alt="Pack Pro" />
              </Col>
              <Col sm={12} md={6} className="pack-slide-content">
                <div className="pack-slide-tabs">
                  <div className="pack-slide-tab">
                    <div className="pack-slide-icon"><img src="/assets/images/commons/icon-pack-pro-2.png" alt="Pack Pro" /> <p>{Translation().pages.scan_packs.products.max.pack} <span>{Translation().pages.scan_packs.products.max.title}</span></p></div>
                    <h3>{Translation().pages.pro_packs.products.photoshoot_vv.sub_title}</h3>
                    <div className="chip-container">
                      <div className="app-chip">{Translation().pages.pro_packs.products.photoshoot_vv.offers.photo.title}</div>
                      <div className="app-chip">{Translation().pages.pro_packs.products.photoshoot_vv.offers.visit.title}</div>
                    </div>
                    <div className="prices-wrapper">
                      <a className="app-button-color" href="/shop">{Translation().pages.home.page_title.buttons.order}</a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} className="pack-slide-illustration mobile">
                <img src="/assets/images/home/home-pack-pro-2-slide-illustration.png" alt="Pack Pro" />
              </Col>
              <Clearfix/>
            </div>
            <div className="pack-slide">
              <Col sm={12} md={6} className="pack-slide-illustration desktop">
                <img src="/assets/images/home/home-pack-pro-3-slide-illustration.png" alt="Pack Pro" />
              </Col>
              <Col sm={12} md={6} className="pack-slide-content">
                <div className="pack-slide-tabs">
                  <div className="pack-slide-tab">
                    <div className="pack-slide-icon"><img src="/assets/images/commons/icon-pack-pro-3.png" alt="Pack Pro" /> <p>{Translation().pages.scan_packs.products.max.pack} <span>{Translation().pages.scan_packs.products.max.title}</span></p></div>
                    <h3>{Translation().pages.pro_packs.products.photoshoot_plan_vv.sub_title}</h3>
                    <div className="chip-container">
                      <div className="app-chip">{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.photo.title}</div>
                      <div className="app-chip">{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.plan.title}</div>
                      <div className="app-chip">{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.visit.title}</div>
                    </div>
                    <div className="prices-wrapper">
                      <a className="app-button-color" href="/shop">{Translation().pages.home.page_title.buttons.order}</a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} className="pack-slide-illustration mobile">
                <img src="/assets/images/home/home-pack-pro-3-slide-illustration.png" alt="Pack Pro" />
              </Col>
              <Clearfix/>
            </div>
          </div>
        </section>
        <section className="baseline-home">
          <div className="container">
            <Col sm={12} md={6} className="baseline-store-description">
              <div className="basline-store-content">
                <h2>{Translation().pages.home.packs_intro.app_teaser.title}</h2>
                <h3>{Translation().pages.home.packs_intro.app_teaser.baseline}</h3>
                <ul>
                  <li><span className="icon-check"/> {Translation().pages.home.packs_intro.app_teaser.checkboxes[0]}</li>
                  <li><span className="icon-check"/> {Translation().pages.home.packs_intro.app_teaser.checkboxes[1]}</li>
                  <li><span className="icon-check"/> {Translation().pages.home.packs_intro.app_teaser.checkboxes[2]}</li>
                  <li><span className="icon-check"/> {Translation().pages.home.packs_intro.app_teaser.checkboxes[3]}</li>                 
                </ul>
                <div>
                  <a href="http://onelink.to/lightshop"><img className="store-images" src="/assets/images/commons/appstore.jpg" alt="Download in Apple Store" /></a>
                  <a href="http://onelink.to/lightshop"><img className="store-images" src="/assets/images/commons/googleplay.jpg" alt="Download in Android Store" /></a>
                </div>
              </div>
            </Col>
            <Clearfix/>
          </div>
          <Col sm={12} md={6} className="baseline-store-illustration" />
          <Col sm={12} md={6} className="laptop-lightshop-demo" />
          <Clearfix/>
        </section>
        <HowItWorkStepper />
        <section className="milestones-home">
          <div className="container">
            <div className="row">
              <Milestones/>
            </div>
          </div>
        </section>
        <div className="services-home">
          <div className="container">
            <div className="row">
              <div className="intro-services">
                <h2>{Translation().pages.home.products.title.text} <span>{Translation().pages.home.products.title.highlight}</span></h2>
                <p>{Translation().pages.home.products.baseline}</p>
              </div>
              <section className="shooting-services-home">
                <Col xs={12} sm={12} md={7} className="col-with-cover">
                  <div className="background-services-home" id="shooting-cover" />
                </Col>
                <Col xs={12} sm={12} md={5} className="col-with-detail">
                  <div className="detail-services-home" id="shooting-detail">
                    <div style={{textAlign: 'right'}}>
                      <a href="/photography" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                    </div>
                    <h3>{Translation().pages.home.products.photoshoot.title}</h3>
                    <p>{Translation().pages.home.products.photoshoot.text}</p>
                    <div className="offers">
                      { Translation().pages.home.products.photoshoot.offers && Translation().pages.home.products.photoshoot.offers.length > 0
                        ? (
                          <span>
                            <p>{Translation().pages.home.products.photoshoot.offers_title}</p>
                            { Translation().pages.home.products.photoshoot.offers && Translation().pages.home.products.photoshoot.offers.length > 0
                              ? Translation().pages.home.products.photoshoot.offers.map((item, index) => (
                                <span className="app-chip" key={index}>{item}</span>
                              ))
                              : false
                            }
                          </span>
                        )
                        : ''
                      }
                    </div>
                    <div className="pricing-area">
                      <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="col-detail-mobile">
                  <div className="detail-services-home">
                    <div style={{textAlign: 'right'}}>
                      <a href="/photography" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                    </div>
                    <h3>{Translation().pages.home.products.photoshoot.title}</h3>
                    <p>{Translation().pages.home.products.photoshoot.text}</p>
                    <div className="offers">
                      { Translation().pages.home.products.photoshoot.offers && Translation().pages.home.products.photoshoot.offers.length > 0
                        ? (
                          <span>
                            <p>{Translation().pages.home.products.photoshoot.offers_title}</p>
                            { Translation().pages.home.products.photoshoot.offers && Translation().pages.home.products.photoshoot.offers.length > 0
                              ? Translation().pages.home.products.photoshoot.offers.map((item, index) => (
                                <span className="app-chip" key={index}>{item}</span>
                              ))
                              : false
                            }
                          </span>
                        )
                        : ''
                      }
                    </div>
                    <div className="pricing-area">
                      <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                    </div>
                  </div>
                </Col>
              </section>
              <Clearfix />
              <section className="plans-services-home">
                <Col xs={12} sm={12} md={5} className="col-with-detail">
                  <div className="detail-services-home" id="plans-detail">
                    <div style={{textAlign: 'right'}}>
                      <a href="/floorplan" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                    </div>
                    <h3>{Translation().pages.home.products.floor_plan.title}</h3>
                    <p>{Translation().pages.home.products.floor_plan.text}</p>
                    <div className="offers">
                      { Translation().pages.home.products.floor_plan.offers && Translation().pages.home.products.floor_plan.offers.length > 0
                        ? (
                          <span>
                            <p>{Translation().pages.home.products.floor_plan.offers_title}</p>
                            { Translation().pages.home.products.floor_plan.offers && Translation().pages.home.products.floor_plan.offers.length > 0
                              ? Translation().pages.home.products.floor_plan.offers.map((item, index) => (
                                <span className="app-chip" key={index}>{item}</span>
                              ))
                              : false
                            }
                          </span>
                        )
                        : ''
                      }
                    </div>
                    <div className="pricing-area">
                      <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={7} className="col-with-cover">
                  <div className="background-services-home" id="plans-cover"></div>
                </Col>
                <Col xs={12} className="col-detail-mobile">
                  <div className="detail-services-home">
                    <div style={{textAlign: 'right'}}>
                      <a href="/floorplan" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                    </div>
                    <h3>{Translation().pages.home.products.floor_plan.title}</h3>
                    <p>{Translation().pages.home.products.floor_plan.text}</p>
                    <div className="offers">
                      { Translation().pages.home.products.floor_plan.offers && Translation().pages.home.products.floor_plan.offers.length > 0
                        ? (
                          <span>
                            <p>{Translation().pages.home.products.floor_plan.offers_title}</p>
                            { Translation().pages.home.products.floor_plan.offers && Translation().pages.home.products.floor_plan.offers.length > 0
                              ? Translation().pages.home.products.floor_plan.offers.map((item, index) => (
                                <span className="app-chip" key={index}>{item}</span>
                              ))
                              : false
                            }
                          </span>
                        )
                        : ''
                      }
                    </div>
                    <div className="pricing-area">
                      <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                    </div>
                  </div>
                </Col>
              </section>
              <Clearfix/>
              <section className="vv-services-home">
                <Col xs={12} sm={12} md={7} className="col-with-cover">
                  <div className="background-services-home" id="vv-cover"></div>
                </Col>
                <Col xs={12} sm={12} md={5} className="col-with-detail">
                  <div className="detail-services-home" id="vv-detail">
                    <div style={{textAlign: 'right'}}>
                      <a href="/virtual-tour" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                    </div>
                    <h3>{Translation().pages.home.products.virtual_visit.title}</h3>
                    <p>{Translation().pages.home.products.virtual_visit.text}</p>
                    <div className="offers">
                      { Translation().pages.home.products.virtual_visit.offers && Translation().pages.home.products.virtual_visit.offers.length > 0
                        ? (
                          <span>
                            <p>{Translation().pages.home.products.virtual_visit.offers_title}</p>
                            { Translation().pages.home.products.virtual_visit.offers && Translation().pages.home.products.virtual_visit.offers.length > 0
                              ? Translation().pages.home.products.virtual_visit.offers.map((item, index) => (
                                <span className="app-chip" key={index}>{item}</span>
                              ))
                              : false
                            }
                          </span>
                        )
                        : ''
                      }
                    </div>
                    <div className="pricing-area">
                      <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="col-detail-mobile">
                  <div className="detail-services-home">
                    <div style={{textAlign: 'right'}}>
                      <a href="/virtual-tour" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                    </div>
                    <h3>{Translation().pages.home.products.virtual_visit.title}</h3>
                    <p>{Translation().pages.home.products.virtual_visit.text}</p>
                    <div className="offers">
                      { Translation().pages.home.products.virtual_visit.offers && Translation().pages.home.products.virtual_visit.offers.length > 0
                        ? (
                          <span>
                            <p>{Translation().pages.home.products.virtual_visit.offers_title}</p>
                            { Translation().pages.home.products.virtual_visit.offers && Translation().pages.home.products.virtual_visit.offers.length > 0
                              ? Translation().pages.home.products.virtual_visit.offers.map((item, index) => (
                                <span className="app-chip" key={index}>{item}</span>
                              ))
                              : false
                            }
                          </span>
                        )
                        : ''
                      }
                    </div>
                    <div className="pricing-area">
                      <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                    </div>
                  </div>
                </Col>
              </section>
              <Clearfix/>
              <section className="videos-services-home">
                <Col xs={12} sm={12} md={5} className="col-with-detail">
                  <div className="detail-services-home" id="videos-detail">
                    <div style={{textAlign: 'right'}}>
                      <a href="/drone-video" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                    </div>
                    <h3>{Translation().pages.home.products.video_drone.title}</h3>
                    <p>{Translation().pages.home.products.video_drone.text}</p>
                    <div className="offers">
                      { Translation().pages.home.products.video_drone.offers && Translation().pages.home.products.video_drone.offers.length > 0
                        ? (
                          <span>
                            <p>{Translation().pages.home.products.video_drone.offers_title}</p>
                            { Translation().pages.home.products.video_drone.offers && Translation().pages.home.products.video_drone.offers.length > 0
                              ? Translation().pages.home.products.video_drone.offers.map((item, index) => (
                                <span className="app-chip" key={index}>{item}</span>
                              ))
                              : false
                            }
                          </span>
                        )
                        : ''
                      }
                    </div>
                    <div className="pricing-area">
                      <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={7} className="col-with-cover">
                  <div className="background-services-home" id="videos-cover"></div>
                </Col>
                <Col xs={12} className="col-detail-mobile">
                  <div className="detail-services-home">
                    <div style={{textAlign: 'right'}}>
                      <a href="/drone-video" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                    </div>
                    <h3>{Translation().pages.home.products.video_drone.title}</h3>
                    <p>{Translation().pages.home.products.video_drone.text}</p>
                    <div className="offers">
                      <p>{Translation().pages.home.products.video_drone.offers_title}</p>
                      { Translation().pages.home.products.video_drone.offers && Translation().pages.home.products.video_drone.offers.length > 0
                          ? (
                            <span>
                              <p>{Translation().pages.home.products.video_drone.offers_title}</p>
                              { Translation().pages.home.products.video_drone.offers && Translation().pages.home.products.video_drone.offers.length > 0
                                ? Translation().pages.home.products.video_drone.offers.map((item, index) => (
                                  <span className="app-chip" key={index}>{item}</span>
                                ))
                                : false
                              }
                            </span>
                          )
                          : ''
                        }
                    </div>
                    <div className="pricing-area">
                      <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                    </div>
                  </div>
                </Col>
              </section>
              <Clearfix/>
              <section className="more-services-home container">
                <div className="row">
                  <Col xs={12} sm={12} md={6}>
                    <div className="detail-services-home" id="diagnostic-detail">
                      <div style={{textAlign: 'right'}}>
                        <a href="/certificates" className="in-detail">{Translation().pages.home.products.more_infos}</a>
                      </div>
                      <h3>{Translation().pages.home.products.diagnostic.title}</h3>
                      <p>{Translation().pages.home.products.diagnostic.text}</p>
                      <div className="offers">
                        { Translation().pages.home.products.diagnostic.offers && Translation().pages.home.products.diagnostic.offers.length > 0
                          ? (
                            <span>
                              <p>{Translation().pages.home.products.diagnostic.offers_title}</p>
                              { Translation().pages.home.products.diagnostic.offers && Translation().pages.home.products.diagnostic.offers.length > 0
                                ? Translation().pages.home.products.diagnostic.offers.map((item, index) => (
                                  <span className="app-chip" key={index}>{item}</span>
                                ))
                                : false
                              }
                            </span>
                          )
                          : ''
                        }
                      </div>
                      <div className="pricing-area">
                        <a href="/shop" className="app-button-color">{Translation().pages.home.products.order}</a>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}/>
                </div>
              </section>
              <Clearfix/>
            </div>
          </div>
        </div>
        <section className="testimonials-slider">
          <div className="intro-testimonial">
            <h2 className="title-home">{Translation().pages.home.testimonials.title}</h2>
          </div>
          <TestimonialHomeSlider/>
        </section>
        <section className="clients-home">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.home.they_trust.title}</h3>
            </div>
          </div>
          <ClientSlider/>
        </section>
        <section className="api-recruiting-home" id="api-recruiting">
          <div className="container">
            <div className="row">
              <Col xs={12} sm={12} md={4} className="col">
                <div className="recruiting-home">
                  <div className="recruiting-home-banner">
                    <h5>{Translation().pages.home.recruiting_banner.main_title} <span>{Translation().pages.home.recruiting_banner.sub_title}</span></h5>
                    <p>{Translation().pages.home.recruiting_banner.main_baseline}</p>
                    <i>{Translation().pages.home.recruiting_banner.sub_baseline}</i>
                    <a href="/recruitment" className="app-button-color app-button-dark">{Translation().pages.home.recruiting_banner.button}</a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} className="col">
                <div onClick={this.openApiDialog} className="api-home">
                  <div className="api-home-banner">
                    <h5>
                      {Translation().pages.home.api_banner.main_title}
                      <span className="api-cog">
                        <span>{"<"}</span><span className="icon-parametres"/> <span>{">"}</span>
                      </span>
                      <span>{Translation().pages.home.api_banner.sub_title} </span>
                    </h5>
                    <p>{Translation().pages.home.api_banner.main_baseline} </p>
                    <i>{Translation().pages.home.api_banner.sub_baseline} </i>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} className="col">
                <div className="appli-home">
                  <a href="http://onelink.to/lightshop">
                    <div className="appli-home-banner" />
                  </a>
                </div>
              </Col>
              <Clearfix/>
            </div>
          </div>
        </section>
        <Dialog
          modal={false}
          actions={[<div onClick={this.closeApiDialog} className="close-tos-modal">{Translation().api.modal.close}</div>,]}
          className="api-modal"
          autoScrollBodyContent={true}
          title={Translation().api.modal.title}
          onRequestClose={this.closeApiDialog}
          open={this.state.isApiDialogOpen}
        >
          {Translation().api.modal.description} <a href={"mailto:" + getSupportEmail()}>{getSupportEmail()}</a>
         </Dialog>
      </div>
    );
  }
}

export default Home;

$(document).ready(function(){

  /**
   * Initialize home sliders
   */
  $('.clients-slider').slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 2000,
    draggable: false,
    swipeToSlide: false,
    pauseOnHover: false,
    slidesToShow: 6,
    centerMode: true,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 500, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 3 } },
      { breakpoint: 1024, settings: { slidesToShow: 5 } }
    ]
  });

  $('.testimonials-home-slider').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  });

  $('.packs-home-slider').slick({
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<div class="arrow-left icon-chevron_gauche"></div>',
    nextArrow: '<div class="arrow-right icon-chevron_droite"></div>',
  });

  /*
   * Video how it work iframe
   */
  const srcVideo = "https://player.vimeo.com/video/237413337?title=0&byline=0&portrait=0";

  /**
   * Open video modal
   */
  $('.play-video').on('click', function() {
    $('.app-overlay-video').fadeIn(300);
    $('#htw-video').attr('src', srcVideo);
  });

  function closeIframeVideo() {
    $('.app-overlay-video').fadeOut(300);
    $('#htw-video').attr('src', '');
  }

  /**
   * Close video modal
   */
  $('.close-video').on('click', function() {
    closeIframeVideo();
  });
  $('.app-overlay-video').on('click', function() {
    closeIframeVideo();
  });

  /**
   * Close video modal on ESC key press
   */
  $(window, document).on('keydown', function (e) {
    if (e.keyCode === 27) {
      closeIframeVideo();
    }
  });

});
