import React, { Component } from 'react';
import { reset, stopSubmit } from 'redux-form';

import { setUser, apiPutUser, apiPutUserPassword, getLocale } from '../../../helpers/UserFunctions';
import { autoScrollTop, scrollToAnchor } from '../../../helpers/JqueryFunctions';

import Snackbar from '../../commons/snackbar/Snackbar';
import PasswordForm from './forms/PasswordForm';
import ProfileForm from './forms/ProfileForm';
import SettingsForm from './forms/SettingsForm';

import store from '../../../redux/store';
import Translation from '../../../translation/Translation';

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isInfosOpen: true,
      isPasswordOpen: false,
      isSettingsOpen: false,
      autoHideDuration: 4000,
      isSnackbarOpen: undefined,
      snackbarMessage: '',
      snackbarStatus: ''
    }

    this.handleOpenInfosTab = this.handleOpenInfosTab.bind(this);
    this.handleOpenPasswordTab = this.handleOpenPasswordTab.bind(this);
    this.handeOpenSettingsTab = this.handeOpenSettingsTab.bind(this);
    this.handleEditInfos = this.handleEditInfos.bind(this);
    this.handleEditPassword = this.handleEditPassword.bind(this);
    this.handleEditSettings = this.handleEditSettings.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
  }

  componentDidMount() {
    autoScrollTop();
  }

  openSnackbar() {
    this.setState({ isSnackbarOpen: true });
  };

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false});
  };

  handleOpenInfosTab() {
    this.setState({
        isInfosOpen: true,
        isPasswordOpen: false,
        isSettingsOpen: false
    });
  }

  handleOpenPasswordTab() {
    this.setState({
        isInfosOpen: false,
        isPasswordOpen: true,
        isSettingsOpen: false
    });
  }

  handeOpenSettingsTab() {
    this.setState({
        isInfosOpen: false,
        isPasswordOpen: false,
        isSettingsOpen: true
    });
  }

  handleEditInfos(values) {

    if (!values) return;

    const btnApiLoader = document.getElementById('edit-perso-loader');
    const btnText = document.getElementById('edit-perso-txt');
    const successMessage = Translation().account.profile.form.callbacks.personal_info.success;
    const errorMessage = Translation().account.profile.form.callbacks.personal_info.error;

    if (btnApiLoader && btnText) {
      btnText.style.display = "none";
      btnApiLoader.style.display = "block";
    }

    apiPutUser(
      values,
      (user) => {
        setUser(user);

        if (btnApiLoader && btnText) {
          btnText.style.display = "block";
          btnApiLoader.style.display = "none";
        }
        this.openSnackbar();
        this.setState({
          snackbarMessage: successMessage,
          snackbarStatus: "success"
        });
      },
      (error) => {
        if (btnApiLoader && btnText) {
          btnText.style.display = "block";
          btnApiLoader.style.display = "none";
        }

        if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          const validationErrors = {};
          const errorsArray = error.response.data.errors;
          const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

          if (scrollAnchor[0])
            scrollToAnchor(scrollAnchor[0])

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath)
              validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
          }

          store.dispatch(stopSubmit(
            'editBasicAccount',
            validationErrors
          ));
        }

        this.openSnackbar();
        this.setState({ snackbarMessage: errorMessage, snackbarStatus: "error"});
      }
    );
  }

  handleEditPassword(values) {

    if (!values) return;

    const btnApiLoader = document.getElementById('edit-password-loader');
    const btnText = document.getElementById('edit-password-txt');
    const successMessage = Translation().account.profile.form.callbacks.password.success;
    const errorMessage = Translation().account.profile.form.callbacks.password.error;

    if (btnApiLoader && btnText) {
      btnText.style.display = "none";
      btnApiLoader.style.display = "block";
    }

    apiPutUserPassword(
      values,
      (user) => {
        setUser(user);

        if (btnApiLoader && btnText) {
          btnText.style.display = "block";
          btnApiLoader.style.display = "none";
        }
        this.openSnackbar();
        this.setState({ snackbarMessage: successMessage, snackbarStatus: "success"});
        store.dispatch(reset('editPassword'));
      },
      (error) => {

        if (btnApiLoader && btnText) {
          btnText.style.display = "block";
          btnApiLoader.style.display = "none";
        }

        if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          const validationErrors = {};
          const errorsArray = error.response.data.errors;
          const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

          if (scrollAnchor[0])
            scrollToAnchor(scrollAnchor[0])

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath)
              validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
          }

          store.dispatch(stopSubmit(
            'editPassword',
            validationErrors
          ));
        }

        this.openSnackbar();
        this.setState({ snackbarMessage: errorMessage, snackbarStatus: "error"});
      }
    );
  }

  handleEditSettings(values) {

    if (!values) return;

    const btnApiLoader = document.getElementById('edit-settings-loader');
    const btnText = document.getElementById('edit-settings-txt');
    const successMessage = Translation().account.profile.form.callbacks.settings.success;
    const errorMessage = Translation().account.profile.form.callbacks.settings.error;
    const oldLocale = getLocale();

    if (btnApiLoader && btnText) {
      btnText.style.display = "none";
      btnApiLoader.style.display = "block";
    }

    apiPutUser(
      values,
      (user) => {
        setUser(user);

        if (btnApiLoader && btnText) {
          btnText.style.display = "block";
          btnApiLoader.style.display = "none";
        }
        this.openSnackbar();
        this.setState({
          snackbarMessage: successMessage,
          snackbarStatus: "success"
        });

        /**
         * Check if user changed his locale, if yes reload page for translation
         */
        if (user.language && user.language.locale)
          if (user.language.locale !== oldLocale)
            document.location.reload();

      },
      (error) => {
        if (btnApiLoader && btnText) {
          btnText.style.display = "block";
          btnApiLoader.style.display = "none";
        }

        if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          const validationErrors = {};
          const errorsArray = error.response.data.errors;
          const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

          if (scrollAnchor[0])
            scrollToAnchor(scrollAnchor[0])

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath)
              validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
          }

          store.dispatch(stopSubmit(
            'editSettings',
            validationErrors
          ));
        }

        this.openSnackbar();
        this.setState({ snackbarMessage: errorMessage, snackbarStatus: "error"});
      }
    );
  }

  render() {

    const {
      isInfosOpen,
      isPasswordOpen,
      isSettingsOpen
    } = this.state;

    return (
      <div className="app-account app-profile">
        <div className="account-header desktop">
          <div className={"tab-header " + (isInfosOpen ? "active" : "")} onClick={this.handleOpenInfosTab}>
            <h1>{Translation().account.profile.page_titles.personal_info}</h1>
          </div>
          <div className={"tab-header " + (isSettingsOpen ? "active" : "")} onClick={this.handeOpenSettingsTab}>
            <h1>{Translation().account.profile.page_titles.settings}</h1>
          </div>
          <div className={"tab-header " + (isPasswordOpen ? "active" : "")} onClick={this.handleOpenPasswordTab}>
            <h1>{Translation().account.profile.page_titles.password}</h1>
          </div>
        </div>
        <div className="account-header mobile">
          <div className={"tab-header " + (isInfosOpen ? "active" : "")} onClick={this.handleOpenInfosTab}>
            <div className="icon-connecte" />
          </div>
          <div className={"tab-header " + (isSettingsOpen ? "active" : "")} onClick={this.handeOpenSettingsTab}>
            <div className="icon-parametres" />
          </div>
          <div className={"tab-header " + (isPasswordOpen ? "active" : "")} onClick={this.handleOpenPasswordTab}>
            <div className="icon-connexion" />
          </div>
        </div>
        <div className="container">
          { isInfosOpen &&
            <div className="edit-profile">
              <div className="account-form">
                <ProfileForm
                  onSubmit={this.handleEditInfos}
                  labels={Translation().account.profile.form.labels}
                  buttonLabel={Translation().account.profile.form.buttons.submit}
                />
                <div form="editBasicAccount" />
              </div>
            </div>
          }
          { isPasswordOpen &&
            <div className="edit-profile">
              <div className="account-form">
                <PasswordForm onSubmit={this.handleEditPassword}/>
                <div form="editPassword" />
              </div>
            </div>
          }
          { isSettingsOpen &&
            <div className="edit-profile">
              <div className="account-form">
                <SettingsForm
                  onSubmit={this.handleEditSettings}
                  labels={Translation().account.profile.form.labels}
                  buttonLabel={Translation().account.profile.form.buttons.submit}
                />
                <div form="editSettings" />
              </div>
            </div>
          }
          <Snackbar
            messageText={this.state.snackbarMessage}
            statusClass={this.state.snackbarStatus}
            open={this.state.isSnackbarOpen}
            autoHideDuration={this.state.autoHideDuration}
            closeFunction={this.closeSnackbar}
          />
        </div>
      </div>
    );
  }
}

export default Profile;
