import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { subscribe } from 'redux-subscriber';
import bowser from 'bowser';

import Translation from '../../../translation/Translation';
import NoFamilyAvailable from '../shopRouter/NoFamilyAvailable';
import ShopFailure from '../shopRouter/ShopFailure';
import ShopHeader from  '../shopHeader/ShopHeader';
import { ShopCart } from '../cart/Cart';
import validate from '../shopRouter/validate';
import { autoScrollTop } from '../../../helpers/JqueryFunctions';
import { addThreeDots } from '../../../helpers/HelperFunctions';
import { convertHourToMonths } from '../../../helpers/DateFunctions';
import {
  getServiceFamilies,
  unsetOptionsValues,
  getServiceSelected,
  setRequirePlanning,
  unsetRequirePlanning,
  apiGetFamilyService,
  getProperty
} from '../../../helpers/ShopFunctions';
import { setCartRequestProductId, unsetCartRequestOptions, getUserCart } from '../../../helpers/CartFunctions';
import Family from './families/Family';

export class ServiceNotAvailable extends Component {

  render() {

    return (
      <div className="no-service">
        <p className="error-message">No service available for this product family.</p>
      </div>
    );
  }
}

export class ServiceAvailable extends Component {

  constructor(props) {
    super(props);

    this.state = { isDescriptionOpen: false };

    this.toggleDescription = this.toggleDescription.bind(this);
    this.selectService = this.selectService.bind(this);
  }

  componentDidMount() {
    /**
     * Get the current service selected
     */
    const serviceSelected = "service-" + getServiceSelected();
    const serviceBoxes = document.getElementsByClassName("service-box");

    for (let i = 0; i < serviceBoxes.length; i++) {
      /**
       * Check where is the current service selected then apply style
       */
      if (serviceBoxes[i].classList.contains(serviceSelected)) {
        serviceBoxes[i].classList.add("service-selected");
      }
    }
  }

  toggleDescription(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isDescriptionOpen: !this.state.isDescriptionOpen });
  }

  selectService(event) {
    const serviceSelected = getServiceSelected();
    /**
     * Set the service selected to redux cart
     */
    setCartRequestProductId(this.props.object.id);
    /**
     * Set the bool is planning required into redux store
     */
    unsetRequirePlanning();
    setRequirePlanning(this.props.object.require_planning);

    /**
     * Get all previous services targeted
     */
    const previousTarget = document.getElementsByClassName('service-box');
    /**
     * Reset all service style
     */
    for (let i = 0; i < previousTarget.length; i++) {
      previousTarget[i].classList.remove("service-selected");
    }
    /**
     * Apply style to the current service targeted
     */
    event.currentTarget.classList.add("service-selected");

    /**
     * If user change service, reset options in cart
     */
    if (serviceSelected !== this.props.object.id) {
      unsetCartRequestOptions();
    }
  }

  render() {
    const service = this.props.object;
    const { isDescriptionOpen } = this.state;

    /**
     * Redux always convert a value to string. Here we set parse for return an integer to redux on decimal base
     */
    const parse = value => value === undefined ? undefined : parseInt(value, 10);

    if (service.orderable) {
      return (
        <Col xs={12} sm={12} md={6} lg={6} className="service orderable">
          <label className={"service-box service-" + (service && service.id) + " shortcode-" + (service.shortcode && service.shortcode)} onClick={this.selectService}>
            <div className="inner-box">
              <div className="more-info-service" onClick={this.toggleDescription}>
                <span className="icon-infos"/>
              </div>
              { isDescriptionOpen
                ? (
                    <div className="service-description">
                      <p>{ service.description && addThreeDots(service.description, 250) }</p>
                      <div className="service-media-detail">
                        <Col xs={12} sm={6} md={6} className="col-left">
                          { service.delivery_time &&
                            <p>{Translation().shop.services.delivery_time} : <span>{service.delivery_time}h</span></p>
                          }
                        </Col>
                        <Col xs={12} sm={6} md={6} className="col-right">
                          { service.expiry_time &&
                            <p>{Translation().shop.services.backup} : <span>{ convertHourToMonths(service.expiry_time) } {Translation().shop.services.months}</span></p>
                          }
                        </Col>
                        <Clearfix/>
                      </div>
                    </div>
                  )
                : (
                    <div>
                      <img
                        src={service && service.icon_url_dark}
                        alt={service ? service.name : "service-image"}
                        className="service-img"
                      />
                      <p className="service-name">{service && service.name}</p>
                      <Field
                        type="radio"
                        component="input"
                        className="hidden-radio"
                        name="serviceSelected"
                        parse={parse}
                        value={service ? service.id : undefined}
                        onChange={unsetOptionsValues}
                      />
                      <span className="service-price">{service && service.price_tax_excluded_formatted} <span className="type-of-tax">{Translation().shop.tax_excluded}</span></span>
                    </div>
                  )
              }
            </div>
          </label>
        </Col>
      );
    }
    else {
      return (
        <Col xs={12} sm={12} md={6} lg={6} className="service unordarable">
          <div className="service-box">
            <div className="inner-box">
              <img
                src={service && service.icon_url_dark}
                alt={service ? service.name : "service-image"}
                className="service-img"
              />
              <p className="service-name">{service && service.name}</p>
              <p>{Translation().shop.services.unavailable}</p>
            </div>
          </div>
        </Col>
      );
    }
  }
}

class Services extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCartDirty: false,
      serviceFamilies: undefined
    }
  }

  componentWillMount() {
    autoScrollTop();
  }

  componentDidMount() {
    /**
     * Delete step in url
     */
    const browserName = bowser.name ? bowser.name : "";
    const browserVersion = bowser.version ? bowser.version : "";
    const isNotIe9 = browserName === "Internet Explorer" && browserVersion <= 9 ? false : true;
    const property = getProperty();
  
    if (isNotIe9) {
      /**
       * Replace window url
       */
      this.props.history.replace('/shop');
    }
    /**
     * Get the user cart if he exists
     */
    const userCart = getUserCart();

    if (userCart && userCart.products && userCart.products.length >= 1) {
      this.setState({ isCartDirty: true });
    }
    else {
      this.setState({ isCartDirty: false });
    }

    /**
     * Listen adding cart items
     */
    // eslint-disable-next-line
    const subscribeUserCart = subscribe('userCart', state => {
      const userCartListener = state.userCart;

      if (userCartListener && userCartListener.products && userCartListener.products.length >= 1) {
        this.setState({ isCartDirty: true });
      }
      else {
        this.setState({ isCartDirty: false });
      }
    });

    this.setState(
      { serviceFamilies: getServiceFamilies() ? getServiceFamilies() : undefined },
      () => {

        if (this.state.serviceFamilies === undefined) {
          const familyApiLoader = document.getElementById('family-api-loader');

          if (familyApiLoader)
            familyApiLoader.style.display = 'block';

          if (property && property.address && property.size) {

            if (property.property_type && property.property_type === "other") {
              property.property_type = null;
            }

            apiGetFamilyService(
              property,
              (successData) => {

                if (successData && successData.length) {
                  this.setState({ serviceFamilies: successData });
                }
                else {
                  this.setState({ serviceFamilies: undefined });

                  const noFamilyAvailable = document.getElementById('noFamilyAvailable');
                  const serviceShopContent = document.getElementById('serviceShopContent');

                  if (serviceShopContent)
                    serviceShopContent.style.display = 'none';
                  if (noFamilyAvailable)
                    noFamilyAvailable.style.display = 'block';
                }

                if (familyApiLoader)
                  familyApiLoader.style.display = 'none';
              },
              (error) => {
                this.setState({ serviceFamilies: undefined });

                const shopFailure = document.getElementById('shopFailure');
                const addressNotFoundFailure = document.getElementById('addressNotFoundFailure');
                const updateBrowser = document.getElementById('updateBrowser');
                const serviceShopContent = document.getElementById('serviceShopContent');

                if (serviceShopContent)
                  serviceShopContent.style.display = 'none';

                if (updateBrowser)
                  updateBrowser.style.display = 'block';

                if (error && error.response && error.response.status && error.response.status === 400) {
                  /**
                   * Error on property address
                   */
                  if (addressNotFoundFailure)
                    addressNotFoundFailure.style.display = 'block';
                }
                else {
                  if (shopFailure)
                    shopFailure.style.display = 'block';
                }

                if (familyApiLoader)
                  familyApiLoader.style.display = 'none';
              }
            );
          }
          else {
            const shopFailure = document.getElementById('shopFailure');
            const serviceShopContent = document.getElementById('serviceShopContent');

            if (serviceShopContent)
              serviceShopContent.style.display = 'none';
            if (shopFailure)
              shopFailure.style.display = 'block';

            if (familyApiLoader)
              familyApiLoader.style.display = 'none';
          }
        }
      }
    );
  }

  render() {

    const { handleSubmit } = this.props;
    const {
      isCartDirty,
      serviceFamilies
    } = this.state;

    /**
     * Browser version
     */
    const browserName = bowser.name ? bowser.name : "";
    const browserVersion = bowser.version ? bowser.version : "";
    const isNotIe9 = browserName === "Internet Explorer" && browserVersion <= 9 ? false : true;

    return (
      <div className="shop-services">
        <ShopHeader stepIndex={2} />
        <NoFamilyAvailable/>
        <ShopFailure/>
        <div className="overlay-service-step" />
        <div className="overlay-missing-field">
          <div className="container">
            <div className="row">
              <div className="mf-modal app-modal">
                <h4 className="app-modal-title">{Translation().shop.missing_fields.title}</h4>
                <p className="app-modal-desc">{Translation().shop.missing_fields.desc}</p>
                <div className="mf-modal-content" />
                <div className="mf-modal-error" />
              </div>
            </div>
          </div>
        </div>
        <div className="service-container container" id="serviceShopContent">
          <div className="row">
            <div className="shop-content">
              <form onSubmit={handleSubmit}>
                <Col xs={12} sm={12} md={9} id="services-available">
                  <div className="intro-step">
                    <h1>{Translation().shop.step_titles.services}</h1>
                    <p>{Translation().shop.step_descriptions.services}</p>
                  </div>
                  <div className="order-col" style={{minHeight: '70px'}}>
                    <div className="loader-api" id="family-api-loader" />
                    { serviceFamilies && serviceFamilies.length > 0 && serviceFamilies.map((serviceFamily, index) => {
                        return (
                          <Family
                            key={serviceFamily.id}
                            object={serviceFamily}
                            index={index}
                          />
                        );
                      })
                    }
                    <Clearfix/>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} className="cart-col-mobile">
                  <div className="cart-col">
                    <ShopCart mobile={true} />
                    <div className="btn-next-top">
                      { isCartDirty
                        ? (
                            <button
                              type="submit"
                              className={"app-button-color app-button-dark btn-next step-available "  + (isNotIe9 ? "btn-next-animated " : " ")}>
                              {Translation().shop.step_buttons.payment}
                              <span className="icon-next icon-chevron_droite"></span>
                            </button>
                          )
                       : (
                            <div className="app-button-color app-button-dark btn-next">
                              {Translation().shop.step_buttons.payment}
                              <span className="icon-next icon-chevron_droite"></span>
                            </div>
                          )
                      }
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} className="cart-col-desktop">
                  <div className="cart-col">
                    <div className="btn-next-top">
                      { isCartDirty
                        ? (
                            <button
                              type="submit"
                              className={"app-button-color app-button-dark btn-next step-available "  + (isNotIe9 ? "btn-next-animated " : " ")}>
                              {Translation().shop.step_buttons.payment}
                              <span className="icon-next icon-chevron_droite"></span>
                            </button>
                          )
                       : (
                            <div className="app-button-color app-button-dark btn-next">
                              {Translation().shop.step_buttons.payment}
                              <span className="icon-next icon-chevron_droite"></span>
                            </div>
                          )
                      }
                    </div>
                    <Clearfix/>
                    <ShopCart mobile={false}/>
                    <div className="btn-next-bottom">
                      { isCartDirty
                        ? (
                            <button
                              type="submit"
                              className="app-button-color app-button-dark btn-next step-available">
                              <span className="icon-next icon-chevron_droite"></span>
                            </button>
                          )
                       : ''
                      }
                    </div>
                  </div>
                </Col>
                <Clearfix/>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'shop',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Services);
