/**
 * MAIN INDEX FILE
 */

/**
 * REACT
 */
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * REDUX
 */
import { Provider } from 'react-redux';
import store from './redux/store';

/**
 * IE 9 Polyfill for classList IE & Android
 */
import 'classlist-polyfill/src/index.js';

/**
 * APP
 */
import App from './app';

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>
  ,document.getElementById('app-lightshop-container')
);
