import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import ShopBanner from '../../components/commons/banners/ShopBanner';

import Translation from '../../translation/Translation';

class VideosDrones extends Component {
  render() {
    return (
      <div className="app-service app-videos-drones">
        <div className="page-header-title">
          <div className="icon-videodrone icon-page-head"></div>
          <h1>{Translation().pages.video_drone.page_title.title}</h1>
          <h2>{Translation().pages.video_drone.page_title.baseline}</h2>
        </div>
        <section className="intro-service">
          <Col xs={12} sm={12} md={6} className="cover-service"></Col>
          <Col xs={12} sm={12} md={6} className="details-service">
            <h3>{Translation().pages.video_drone.description.title}</h3>
            <p>{Translation().pages.video_drone.description.texts[0]}</p>
            <p>{Translation().pages.video_drone.description.texts[1]}</p>
            <a className="prices scroll-anch" href="#tarifs">
              {Translation().pages.video_drone.description.buttons.pricing}
            </a>
            <a className="app-button-color" href="/shop">
              {Translation().pages.video_drone.description.buttons.order}
            </a>
          </Col>
          <Clearfix />
        </section>
        <section className="process-service">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.video_drone.guarantees.title}</h3>
              <Col xs={12} sm={5} md={5}>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.video_drone.guarantees.texts[0]}</p>
                  <Clearfix />
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.video_drone.guarantees.texts[1]}</p>
                  <Clearfix />
                </div>
              </Col>
              <Col xs={12} sm={2} md={2}>
                <img
                  src="/assets/images/commons/videodrone-avatar.png"
                  alt="Plans"
                />
              </Col>
              <Col xs={12} sm={5} md={5} className="padding-left">
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.video_drone.guarantees.texts[2]}</p>
                  <Clearfix />
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.video_drone.guarantees.texts[3]}</p>
                  <Clearfix />
                </div>
              </Col>
              <Clearfix />
            </div>
          </div>
        </section>
        <ShopBanner />
        <div className="showroom-service">
          <iframe
            style={{ border: 0, backgroundColor: '#000' }}
            src="https://player.vimeo.com/video/247151987?title=0&byline=0&portrait=0"
            title="video drones"
            height="100%"
            width="100%"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <section className="prices-service" id="tarifs">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.video_drone.pricing.title}</h3>
              <p className="baseline-title" />
              {/* <p className="baseline-title">{Translation().pages.video_drone.pricing.baseline}</p> */}
              <Col xs={12} sm={6} md={6}>
                <div className="package">
                  <div className="package-header">
                    <h5>
                      {
                        Translation().pages.video_drone.pricing.products
                          .video_drone_in.title
                      }
                    </h5>
                    <h4>
                      {
                        Translation().pages.video_drone.pricing.products
                          .video_drone_in.name
                      }
                    </h4>
                  </div>
                  <div className="icon-videodrone icon-price"></div>
                  <div className="package-features">
                    <p>
                      {
                        Translation().pages.video_drone.pricing.products
                          .video_drone_in.texts[0]
                      }
                    </p>
                    <p>
                      {
                        Translation().pages.video_drone.pricing.products
                          .video_drone_in.texts[1]
                      }
                    </p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">
                    {Translation().pages.video_drone.pricing.button}
                  </a>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <div className="package">
                  <div className="package-header">
                    <h5>
                      {
                        Translation().pages.video_drone.pricing.products
                          .video_drone_out.title
                      }
                    </h5>
                    <h4>
                      {
                        Translation().pages.video_drone.pricing.products
                          .video_drone_out.name
                      }
                    </h4>
                  </div>
                  <div className="icon-dronevideo icon-price"></div>
                  <div className="package-features">
                    <p>
                      {
                        Translation().pages.video_drone.pricing.products
                          .video_drone_out.texts[0]
                      }
                    </p>
                    <p>
                      {
                        Translation().pages.video_drone.pricing.products
                          .video_drone_out.texts[1]
                      }
                    </p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">
                    {Translation().pages.video_drone.pricing.button}
                  </a>
                </div>
              </Col>
              <Clearfix />
              <div className="pagination-service">
                <div className="prev-service">
                  <a href="/certificates">
                    <span className="icon-chevron_gauche icon-pagination" />
                    <p>
                      {Translation().header.app_nav.products.diagnostic.title}
                    </p>
                  </a>
                </div>
                <div className="next-service">
                  <a href="/home-staging">
                    {/* <p>{Translation().header.app_nav.products.diagnostic.title}</p> */}
                    <p>Home staging</p>
                    <span className="icon-chevron_droite icon-pagination" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default VideosDrones;
