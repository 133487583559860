import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import ShopHeader from  '../shopHeader/ShopHeader';
import { autoScrollTop, scrollToAnchor } from '../../../helpers/JqueryFunctions';
import RegisterView from '../../commons/register/RegisterView';
import LoginView from '../../commons/login/LoginView';

import Translation from '../../../translation/Translation';

class Connection extends Component {

  constructor(props) {
    super();
    this.state = {
      isDropdownOpen: false,
      isLoginOpen: false,
      isRegisterOpen: false,
      isFullRegisterOpen: false,
      isAnimeDone: false,
    };

    this.handleClickLog = this.handleClickLog.bind(this);
    this.handleClickRegister = this.handleClickRegister.bind(this);
    this.openRegister = this.openRegister.bind(this);
    this.closeRegister = this.closeRegister.bind(this);
  }

  componentDidMount() {
    autoScrollTop();

    const shopRegisterContainer = document.getElementById('shop-register');
    const btnRegister = document.getElementById('btn-register-instep');

    if (btnRegister) {
      btnRegister.addEventListener('click', () => {
        if (shopRegisterContainer) {
          scrollToAnchor('#shop-register');
        }
      });
    }
  }

  handleClickLog() {
    this.setState({ isLoginOpen: !this.state.isLoginOpen });

    if (this.state.isRegisterOpen){
      this.setState({ isRegisterOpen: false });
    }
  }

  handleClickRegister() {
    this.setState({ isRegisterOpen: !this.state.isRegisterOpen });

    if (this.state.isLoginOpen) {
      this.setState({ isLoginOpen: false });
    }
  }

  openRegister() {
    this.setState({ isFullRegisterOpen: true });

    setTimeout(function() {
      this.setState({ isAnimeDone: true });
    }.bind(this), 1200);
  }

  closeRegister() {
    this.setState({ isFullRegisterOpen: false, isAnimeDone: false });
  }

  render() {
    const { onSubmit } = this.props;

    const { isAnimeDone, isFullRegisterOpen } = this.state;

    const hiddenBtnStyle = {
      display: 'none',
      opacity: '0',
      visibility: 'hidden'
    }

    return (
      <div className="shop-connection">
        <ShopHeader stepIndex={3}/>
        <div className="shop-logger">
          <div className="dropdown-login shop-login">
            <Col xs={12} sm={12} md={6} className={"col-register " + (isFullRegisterOpen ? "slide-left" : "")} onClick={this.handleClickLog}>
              <h4 className="toggle-desktop">{Translation().header.login_dropdown.register.title}</h4>
              <p className="register-baseline">{Translation().header.login_dropdown.register.description}</p>
              <div className="toggle-desktop">
                <div onClick={this.openRegister} className="app-button-color app-button-dark">{Translation().header.login_dropdown.register.button}</div>
              </div>
              {/*
                <div className="social-ntw-register">
                  <p className="or-log-with">Ou enregistrez-vous avec :</p>
                  <div className="button-social button-fb"><span className="icon-facebook"></span> Facebook</div>
                  <div className="button-social button-twitter"><span className="icon-twitter"></span> Twitter</div>
                  <div className="button-social button-gplus"><span className="icon-google_plus"></span> Google +</div>
                </div>
              */}
            </Col>
            <Col xs={12} sm={12} md={6} className={"col-login " + (isFullRegisterOpen ? "slide-right" : "")} onClick={this.handleClickRegister}>
              <h4>{Translation().header.login_dropdown.login.title}</h4>
              <p className="login-baseline">{Translation().header.login_dropdown.login.description}</p>
              <LoginView/>
            </Col>
            <Clearfix/>
          </div>
          <div id="shop-register" className={"dropdown-register shop-register " + (isAnimeDone ? "change-index" : "")} >
            <div className="register-view">
              <div className="container">
                <div className="row">
                  <div className="toggle-mobile">
                    <h4>{Translation().header.login_dropdown.register.title}</h4>
                  </div>
                  <RegisterView/>
                </div>
              </div>
            </div>
            <div className="icon-fermer" onClick={this.closeRegister}></div>
          </div>
          <button type="button" 
            onClick={onSubmit}
            id="simulate-click"
            style={hiddenBtnStyle}
            className="app-button-color app-button-dark btn-next">
            <span className="icon-chevron_droite"></span>
          </button>
        </div>
      </div>
    );
  }
}

export default Connection;
