import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import { getResidenceCountry } from '../../../helpers/UserFunctions';

class PhoneField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isFocus: false,
      isValid: false,
      residenceCountry: getResidenceCountry() || null,
      value: ''
    };

    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectFlag = this.handleSelectFlag.bind(this);
  }

  handleChange(isValid, value, fullObj, selected) {

    const {
      input,
      onChange,
    } = this.props;

    this.setState({ value });

    if (onChange && typeof onChange === "function")
      onChange({isValid, value, fullObj, selected});

    // Redux integration
    if (input && input.onChange) {
      input.onChange(selected || null);
    }
  }

  handleFocus() {
      this.setState({ isFocus: true });
  }

  handleBlur() {
      this.setState({ isFocus: false });
  }

  handleSelectFlag() {

      const {
        input,
        onChange,
        onSelectFlag
      } = this.props;

      this.setState({ value: '' });

      if (onSelectFlag && typeof onSelectFlag === 'function')
        onSelectFlag();

      if (onChange && typeof onChange === "function")
        onChange({});

      // Redux integration
      if (input && input.onChange) {
        input.onChange('');
      }
  }

  render() {

    const {
      id,
      input,
      excludeCountries,
      defaultCountry,
      fullWidth,
      className,
      wrapperClassName,
      containerClassName,
      disabled,
      name,
      placeholder,
      preferredCountries,
      required,
      style,
      meta,
      label,
    } = this.props;

    const {
      isFocus,
      residenceCountry,
      value
    } = this.state;

    const finalClassName =  (className ? className : '') + (isFocus ? " focus " : ' ' ) + (meta && meta.touched && meta.error ? " field-get-error " : ' ' );

    return (
      <div className={wrapperClassName} onFocus={this.handleFocus}>
        {label && <label htmlFor={id || name}>{label}</label>}
        <div>
          <IntlTelInput
            fieldId={id}
            fieldName={name}
            inputClassName={finalClassName}
            defaultCountry={defaultCountry}
            placeholder={placeholder}
            onPhoneNumberChange={this.handleChange}
            excludeCountries={excludeCountries}
            disabled={disabled}
            onSelectFlag={this.handleSelectFlag}
            containerClassName={"intl-tel-input " + (containerClassName ? containerClassName : "")}
            onPhoneNumberBlur={this.handleBlur}
            formatOnInit={true}
            preferredCountries={preferredCountries || [residenceCountry ? residenceCountry.toLowerCase() : "fr", "es", "it", "gb", "de", "be", "ch"]}
            defaultValue={input && input.value ? input.value : ''}
            telInputProps={{
              disabled,
              required,
              name,
              id: id || name
            }}
            format={true}
            useMobileFullscreenDropdown={false}
            style={fullWidth ? {...style, width: "100%"} : style}
            value={value}
          />
        </div>
        {meta && meta.touched && meta.error && <div className="error-message error-field" style={{display: "block"}}>{meta.error}</div>}
      </div>
    );
  }
}

PhoneField.defaultProps = {
  wrapperClassName: "ls-phone-field",
  fullWidth: true
}

PhoneField.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  input: PropTypes.shape(),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSelectFlag: PropTypes.func,
  style: PropTypes.shape(),
  defaultCountry: PropTypes.string,
  label: PropTypes.string,
  wrapperClassName: PropTypes.string,
  preferredCountries: PropTypes.array,
  excludeCountries: PropTypes.array,
}

export default PhoneField;
