import React, { Component } from 'react';
import { Field, formValueSelector, change } from 'redux-form';
import { Col, Clearfix } from 'react-bootstrap';

import store from '../../../redux/store';
import renderField from './renderField';
import {
  dateInTz,
  getCurrentDate,
  addToDate,
  formatDate,
  createDateUtc,
  createDateFromArray,
  removeFromDate
} from '../../../helpers/DateFunctions';

const compileBirthday = (form, type, value) => {
  /**
   * Set value from input
   * If no value we check in redux form values
   */
  const state = store.getState();
  const selector = formValueSelector(form);
  const reduxYear =  selector(state, 'birthdayYear');
  const reduxMonth =  selector(state, 'birthdayMonth');
  const reduxDay =  selector(state, 'birthdayDay');

  const year = (type && type === "year") && value ? value : reduxYear ? reduxYear : null;
  const month = (type && type === "month") && value ? value : reduxMonth ? reduxMonth : null;
  const day = (type && type === "day") && value ? value : reduxDay ? reduxDay : null;

  if (!year || !month || !day) return;

  const birthday = createDateFromArray([year, month, day]);

  /**
   * Set new birthday to redux
   */
  store.dispatch(change(form, 'date_of_birth', formatDate(birthday, 'YYYY-MM-DD')));
}

class BirthdateField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      date: getCurrentDate(),
      days: [],
      months: [],
      years: []
    }
  }

  componentDidMount() {

    const nowTz = dateInTz(this.state.date);
    const monthsArray = [];
    const yearsArray = [];
    let daysArray = [];

    /**
     * Create months
     */
    for (let i = 0; i < 12; i++) {
      let months = addToDate(nowTz, i, 'months', null);
      monthsArray.push(months);
    }

    /**
     * Create daysArray
     */
    for (let i = 1; i < 32; i++) {
      daysArray[i] = i;
    }

    /**
     * Create years
     */
    for (let i = 0; i < 100; i++) {
      const currentYear = createDateUtc();
      let years = removeFromDate(currentYear, i, 'years', 'YYYY');
      yearsArray.push(years);
    }

    /**
     * Pushing to component state
     */
    this.setState({
      months: monthsArray,
      days: daysArray,
      years: yearsArray
    });
  }

  render() {

    const {
      formName,
      label
    } = this.props;

    const {
      months,
      years,
      days,
    } = this.state;

    return (
      <div className="inner-field">
        <label>{label}</label>
        <Col xs={4} sm={4} md={3} className="birth-date-col">
          <Field
            name="birthdayDay"
            component="select"
            className="app-basic-input"
            onChange={(event, value) => {
              compileBirthday(formName, "day", value);
            }}>
            <option value="" disabled />
            { days && days.map((day, index) => {
                return <option key={index} value={day}>{day}</option>
              })
            }
          </Field>
        </Col>
        <Col xs={4} sm={4} md={5} className="birth-date-col">
          <Field
            name="birthdayMonth"
            component="select"
            className="app-basic-input"
            onChange={(event, value) => {
              compileBirthday(formName, "month", value);
            }}>
            <option value="" disabled />
            { months && months.map((month, index) => {
                return <option key={index} value={formatDate(month, "M")}>{formatDate(month, "MMMM")}</option>
              })
            }
          </Field>
        </Col>
        <Col xs={4} sm={4} md={4} className="birth-date-col">
          <Field
            name="birthdayYear"
            component="select"
            className="app-basic-input"
            onChange={(event, value) => {
              compileBirthday(formName, "year", value);
            }}>
            <option value="" disabled />
            { years && years.reverse().map((year, index) => {
                return <option key={index} value={year}>{year}</option>
              })
            }
          </Field>
        </Col>
        <div className="bd-required">
          <Field
            name="date_of_birth"
            component={renderField}
            type="hidden"
            id="dateOfBirth"
            className="hidden-input"
          />
        </div>
        <Clearfix/>
      </div>
    );
  }
}

export default BirthdateField;
