import React, { Component } from 'react';
import { reset } from 'redux-form';

import { apiResetPassword } from '../../helpers/AccountFunctions';
import { parseUrl} from '../../helpers/HelperFunctions';
import ResetPasswordForm from './resetPasswordForm';
import store from '../../redux/store';

import Translation from '../../translation/Translation';

class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    }

    this.resetPassword = this.resetPassword.bind(this);
  }

  resetPassword(values) {

    if (values && values.new_password) {
      const urlQueryString = parseUrl(window.location);
      const query = urlQueryString.query;

      if (query && query.email && query.reset_token) {

        this.setState({ isLoading: true });

        const resetObject = {
          email: query.email,
          reset_token: query.reset_token,
          new_password : values.new_password
        }

        apiResetPassword(
          resetObject,
          (successData) => {
            this.setState({
              isLoading: false,
              isSendSuccess: true,
              errorMessage: ''
            });

            store.dispatch(reset('resetPassword'));
          },
          (error) => {
            let errorMessage = Translation().pages.reset_password.form.callbacks.failure;

            if (error.response && error.response.status) {
              if (error.response.status === 400) {
                errorMessage = Translation().pages.reset_password.form.callbacks.expired;
              }
            }

            this.setState({
              isLoading: false,
              isSendSuccess: false,
              errorMessage: errorMessage
            });
          }
        )
      }
      else {
        this.setState({
          errorMessage: Translation().pages.reset_password.form.callbacks.no_token
        });
      }
    }
  }

  render() {

    const {
        isLoading,
        isSendSuccess,
        errorMessage
    } = this.state;

    const urlQueryString = parseUrl(window.location);
    const query = urlQueryString.query;

    return (
      <div className="app-reset-pwd">
        <div className="page-header-title">
          <h1>{Translation().pages.reset_password.page_title}</h1>
        </div>
        <div className="container">
            {
                query.email && query.reset_token
                ? (
                    <ResetPasswordForm
                      isLoading={isLoading}
                      isSendSuccess={isSendSuccess}
                      errorMessage={errorMessage}
                      onSubmit={this.resetPassword}
                    />
                )
                : <div style={{textAlign: 'center'}}>Missing link parameters. Please re send a request.</div>
            }
          <div form="resetPassword" />
        </div>
      </div>
    )
  }
}

export default ResetPassword;
