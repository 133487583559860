import React, { Component } from 'react';
import classNames from 'classnames';

import Translation from '../../../../../translation/Translation';

class HostingPlan extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { onClick, plan } = this.props;

    if (onClick && plan.id) {
      onClick(plan.id);
    }
  }

  render () {
    const { plan, selected } = this.props;

    return (
      <div className={classNames("hosting-plan", {"selected": selected === plan.id})} onClick={this.handleClick}>
        <p className="plan-month">{ plan.nb_months } {Translation().account.orders.hosting_plans.months} </p> 
        <p className="plan-price-tax-excl">{ plan.price_tax_excluded_formatted } {Translation().shop.tax_excluded}</p>
        <p className="plan-price">{ plan.price_formatted } {Translation().shop.tax_included}</p>
      </div>
    )
  }
}

export default HostingPlan;