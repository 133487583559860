import Translation from '../../../../../translation/Translation';

const validate = values => {

  const errors = {};

  if (!values.unit_system) {
    errors.unit_system = Translation().forms.errors.required;
  }
  if (!values.language_locale) {
    errors.language_locale = Translation().forms.errors.required;
  }
  if (!values.timezone_code) {
    errors.timezone_code = Translation().forms.errors.required;
  }
  if (!values.currency_isocode) {
    errors.currency_isocode = Translation().forms.errors.required;
  }
  if (!values.citizen_country_isocode) {
    errors.citizen_country_isocode = Translation().forms.errors.required;
  }
  if (!values.residence_country_isocode) {
    errors.residence_country_isocode = Translation().forms.errors.required;
  }

  return errors;
}

export default validate;
