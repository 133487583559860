import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { initPlaceholder } from '../../../helpers/JqueryFunctions';
import renderField from '../fields/renderField';

import validate from './validate';

class NewsletterForm extends Component {

  componentDidMount() {
    initPlaceholder();
  }

  render() {
    const { handleSubmit, labels } = this.props;

    return (
      <form id="newsletter-form" onSubmit={handleSubmit}>
        <Field 
          name="email" 
          type="email"
          id="newsletter-input"
          component={renderField}
          label={labels.email}
          className="app-basic-input"
        />
        <div className="send-nl" id="nwsl-submit" onClick={handleSubmit}><span className="send-icon icon-envoyer"/></div>
        <div className="nwsl-output" id="nwsl-output" />
      </form>
    );
  }
}

export default reduxForm({
  form: 'newsletter',
  validate
})(NewsletterForm); 