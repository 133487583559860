import React, { Component } from 'react';

import Config from '../../../config/Config';

class LegalNoticeFR extends Component {

  render() {
    return (
      <div className="app-legal-notice">
        <div className="page-header-title">
          <h1>Mentions légales et<br/>politique de données personnelles Wall-Market</h1>
        </div>
        <div className="container">
          <div className="row">
            <h2>1. Informations éditeur</h2>
            <p>
              Wall Market © <br/>
              Siège : {Config.brand_address}, {Config.brand_city} <br/>
              N° de TVA intracommunautaire : FR 775 327 27740 <br/>
              RCS Paris : 532 727 740 <br/>
              SAS au capital de 19.146 euros <br/>
              <a target="_blank" rel="noopener noreferrer" href="mailto:sales@wall-market.com">sales@wall-market.com</a>
            </p>
            <h2>2. Information hébergeur</h2>
            <p>
              Amazon Web Services, Inc. <br/>
              P.O. Box 81226 Seattle, WA 98108-1226 <br/>
              <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/fr/">https://aws.amazon.com/fr/</a>
            </p>
            <h2>3. Plateforme européenne du règlement des litiges en ligne</h2>
            <p>
              <a target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/consumers/odr/main/?event=main.trader.register">https://ec.europa.eu/consumers/odr/main/?event=main.trader.register</a>
            </p>
            <h2>4. Propriété intellectuelle</h2>
            <p>
              Le site Internet, sa structure générale, ainsi que les textes, images animées ou non, savoir-faire, dessins, graphismes (...) et tout autre élément composant le site, sont la propriété de Wall-Market. Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l'éditeur du site Internet est interdite et constituerait une contrefaçon sanctionnée par les articles L 335-2 et suivants du Code de la propriété intellectuelle français. Les marques de l'exploitant du site Internet et de ses partenaires, ainsi que les logos figurant sur le site sont des marques (semi-figuratives ou non) déposées. Toute reproduction totale ou partielle de ces marques ou de ces logos effectuée à partir des éléments du site sans l'autorisation expresse de l'exploitant du site Internet ou de son ayant-droit est donc prohibée, notamment au sens de l'article L713-2 du Code de la propriété intellectuelle français.
              <br/> <br/> Toute violation de cette clause peut engager votre responsabilité civile et/ou pénale.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalNoticeFR;
