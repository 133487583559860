import React, { Component } from 'react';

import Config from '../../../config/Config';

class LegalNoticeEN extends Component {

  render() {
    return (
      <div className="app-legal-notice">
        <div className="page-header-title">
          <h1>Aviso legal de Wall-Market</h1>
        </div>
        <div className="container">
          <div className="row">
            <h2>1. Información del editor</h2>
            <p>
              Wall Market © <br/>
              Sede: {Config.brand_address}, {Config.brand_city} <br/>
              Núm. d IVA intracomunitario: FR 775 327 27740 <br/>
              RM París: 532 727 740 <br/>
              SAS con un capital de 19 146 euros <br/>
              <a target="_blank" rel="noopener noreferrer" href="mailto:sales@wall-market.com">sales@wall-market.com</a>
            </p>
            <h2>2. Alojamiento</h2>
            <p>
              Amazon Web Services, Inc. <br/>
              P.O. Box 81226 Seattle, WA 98108-1226 <br/>
              <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/fr/">https://aws.amazon.com/fr/</a>
            </p>
            <h2>3. Plataforma europea de resolución de conflictos en línea</h2>
            <p>
              <a target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/consumers/odr/main/?event=main.trader.register">https://ec.europa.eu/consumers/odr/main/?event=main.trader.register</a>
            </p>
            <h2>4. Propiedad intelectual</h2>
            <p>
              El sitio web, su estructura general, así como los textos, imágenes, animadas o no, conocimientos técnicos, dibujos, diseños (etc.) y cualquier otro elemento que componga el sitio, son propiedad de Wall Market. Queda prohibida toda representación, reproducción, adaptación, explotación, total o parcial de este sitio por cualquier procedimiento conocido o desconocido hasta la fecha, sin la autorización previa, expresa y por escrito de Wall-Market y constituirá una falsificación sancionada por los artículos L 335-2 y siguientes del Código de Propiedad Intelectual francés. Las marcas de Wall-Market y/o de sus socios, así como los logotipos que aparecen en el sitio son marcas (semifigurativas o no) registradas. Por lo tanto, queda prohibida toda reproducción total o parcial de estas marcas o logotipos realizada a partir de elementos del sitio web sin la autorización previa, expresa y por escrito de Wall-Market o su derechohabiente, en particular en el sentido del artículo L 713-2 del Código de Propiedad Intelectual francés.
              <br/> <br/> Cualquier incumplimiento de esta cláusula puede comprometer su responsabilidad civil y/o penal.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalNoticeEN;
