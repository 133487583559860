import React, { Component } from 'react';
import { getLocale } from '../../helpers/UserFunctions';

import Translation from '../../translation/Translation';

import PrivacyPolicyFR from './translation/PrivacyPolicyFR';
import PrivacyPolicyEN from './translation/PrivacyPolicyEN';
import PrivacyPolicyES from './translation/PrivacyPolicyES';
import PrivacyPolicyIT from './translation/PrivacyPolicyIT';
import PrivacyPolicyDE from './translation/PrivacyPolicyDE';

class PrivacyPolicyPage extends Component {
  
  renderPrivacyPolicy() {
    const locale = getLocale();
  
    if (locale && locale === "fr")
      return <PrivacyPolicyFR />
    else if (locale && locale === "en")
      return <PrivacyPolicyEN />
    else if (locale && locale === "es")
      return <PrivacyPolicyES />
    else if (locale && locale === "de")
      return <PrivacyPolicyDE />
    else if (locale && locale === "it")
      return <PrivacyPolicyIT />
    else
      return <PrivacyPolicyEN />
  }

  render() {
    return (
      <div className="app-legal-notice">
        <div className="page-header-title">
         <h1>{Translation().pages.legal_notice.title}</h1>
        </div>
        <div className="container">
          <div className="row">
          	{this.renderPrivacyPolicy()}
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyPage; 
