import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import $ from 'jquery';

import ShopBanner from '../../components/commons/banners/ShopBanner';
import BeforeAfterSlider from '../../components/commons/sliders/BeforeAfterSlider';

import Translation from '../../translation/Translation';

class ShootingSlider extends Component {

  render() {

    const bg1 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic01.jpg')" };
    const bg2 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic02.jpg')" };
    const bg3 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic03.jpg')" };
    const bg4 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic04.jpg')" };
    const bg5 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic05.jpg')" };
    const bg6 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic21.jpg')" };
    const bg7 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic22.jpg')" };
    const bg8 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic08.jpg')" };
    const bg9 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic09.jpg')" };
    const bg10 = { backgroundImage: "url('/assets/images/shooting/shooting-slider-pic10.jpg')" };


    return (
      <div className="service-slider shooting-slider">
        <div className="service-slides" style={bg1}></div>
        <div className="service-slides" style={bg2}></div>
        <div className="service-slides" style={bg3}></div>
        <div className="service-slides" style={bg4}></div>
        <div className="service-slides" style={bg5}></div>
        <div className="service-slides" style={bg6}></div>
        <div className="service-slides" style={bg7}></div>
        <div className="service-slides" style={bg8}></div>
        <div className="service-slides" style={bg9}></div>
        <div className="service-slides" style={bg10}></div>
      </div>
    );
  }
}

class Shooting extends Component {

  render() {

    return (
      <div className="app-service app-shooting">
        <div className="page-header-title">
          <div className="icon-photo icon-page-head"></div>
          <h1>{Translation().pages.photoshoot.page_title.title}</h1>
          <h2>{Translation().pages.photoshoot.page_title.baseline}</h2>
        </div>
        <section className="intro-service">
          <Col xs={12} sm={12} md={6} className="cover-service">
            <BeforeAfterSlider/>
          </Col>
          <Col xs={12} sm={12} md={6} className="details-service">
            <h3>{Translation().pages.photoshoot.description.title}</h3>
            <p>{Translation().pages.photoshoot.description.texts[0]}</p>
            <p>{Translation().pages.photoshoot.description.texts[1]}</p>
            <a className="prices scroll-anch" href="#offers">{Translation().pages.photoshoot.description.buttons.pricing}</a>
            <a className="app-button-color" href="/shop">{Translation().pages.photoshoot.description.buttons.order}</a>
          </Col>
          <Clearfix/>
        </section>
        <section className="process-service">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.photoshoot.guarantees.title}</h3>
              <Col xs={12} sm={5} md={5}>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.photoshoot.guarantees.texts[0]}</p>
                  <Clearfix/>
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.photoshoot.guarantees.texts[1]}</p>
                  <Clearfix/>
                </div>
              </Col>
              <Col xs={12} sm={2} md={2}>
                <img src="/assets/images/commons/shooting-avatar.png" alt="Shooting"/>
              </Col>
              <Col xs={12} sm={5} md={5} className="padding-left">
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.photoshoot.guarantees.texts[2]}</p>
                  <Clearfix/>
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.photoshoot.guarantees.texts[3]}</p>
                  <Clearfix/>
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.photoshoot.guarantees.texts[4]}</p>
                  <Clearfix/>
                </div>
              </Col>
              <Clearfix/>
            </div>
          </div>
        </section>
        <ShopBanner/>
        <div className="showroom-service">
          <ShootingSlider/>
        </div>
        <section className="prices-service" id="offers">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.photoshoot.pricing.title}</h3>
              <p className="baseline-title"/>
              {/* <p className="baseline-title">{Translation().pages.photoshoot.pricing.baseline}</p> */}
              <Col xs={12} sm={6} md={3}>
                <div className="package">
                  <div className="package-header">
                    <h5>{Translation().pages.photoshoot.pricing.products.height_images.title}</h5>
                    <h4>{Translation().pages.photoshoot.pricing.products.height_images.name}</h4>
                  </div>
                  <div className="icon-images icon-price"></div>
                  <div className="package-features">
                    <p>{Translation().pages.photoshoot.pricing.products.height_images.delivery_time}</p>
                    <p>{Translation().pages.photoshoot.pricing.products.height_images.text}</p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">{Translation().pages.photoshoot.pricing.button}</a>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <div className="package">
                  <div className="package-header">
                    <h5>{Translation().pages.photoshoot.pricing.products.twelve_images.title}</h5>
                    <h4>{Translation().pages.photoshoot.pricing.products.twelve_images.name}</h4>
                  </div>
                  <div className="icon-images2 icon-price"></div>
                  <div className="package-features">
                    <p>{Translation().pages.photoshoot.pricing.products.twelve_images.delivery_time}</p>
                    <p>{Translation().pages.photoshoot.pricing.products.twelve_images.text}</p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">{Translation().pages.photoshoot.pricing.button}</a>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <div className="package">
                  <div className="package-header">
                    <h5>{Translation().pages.photoshoot.pricing.products.sixteen_images.title}</h5>
                    <h4>{Translation().pages.photoshoot.pricing.products.sixteen_images.name}</h4>
                  </div>
                  <div className="icon-images3 icon-price"></div>
                  <div className="package-features">
                    <p>{Translation().pages.photoshoot.pricing.products.sixteen_images.delivery_time}</p>
                    <p>{Translation().pages.photoshoot.pricing.products.sixteen_images.text}</p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">{Translation().pages.photoshoot.pricing.button}</a>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3}>
                <div className="package">
                  <div className="package-header">
                    <h5>{Translation().pages.photoshoot.pricing.products.twenty_five_images.title}</h5>
                    <h4>{Translation().pages.photoshoot.pricing.products.twenty_five_images.name}</h4>
                  </div>
                  <div className="icon-images4 icon-price"></div>
                  <div className="package-features">
                    <p>{Translation().pages.photoshoot.pricing.products.twenty_five_images.delivery_time}</p>
                    <p>{Translation().pages.photoshoot.pricing.products.twenty_five_images.text}</p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">{Translation().pages.photoshoot.pricing.button}</a>
                </div>
              </Col>
              <Clearfix/>
              <div className="pagination-service">
                <div className="prev-service">
                  <a href="/pro-pack">
                    <span className="icon-chevron_gauche icon-pagination"></span>
                    <p>{Translation().header.app_nav.products.pro_packs.title}</p>
                  </a>
                </div>
                <div className="next-service">
                  <a href="/floorplan">
                    <p>{Translation().header.app_nav.products.plans.title}</p>
                    <span className="icon-chevron_droite icon-pagination"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Shooting;

$(document).ready(function(){

  $('.shooting-slider').slick({
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
    prevArrow: '<div class="arrow-left icon-chevron_gauche"></div>',
    nextArrow: '<div class="arrow-right icon-chevron_droite"></div>',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  });

});
