import React, { Component } from 'react';

import Translation from '../../../translation/Translation';

class CompanyZone extends Component {

  render() {

    return (
      <div className="app-account">
        <div className="company-zone">
          <div className="container">
            <div className="row">
              <div className="message">
                <h1>{Translation().account.company_zone.page_title},</h1>
                <p>{Translation().account.company_zone.description}</p>
                <a className="app-button-color" href="/profile">{Translation().account.company_zone.button}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyZone; 