import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import ShopBanner from '../../components/commons/banners/ShopBanner';

import Translation from '../../translation/Translation';

class VirtualVisit extends Component {

  render() {
    return (
      <div className="app-service app-virtual-visit">
        <div className="page-header-title">
          <div className="icon-visite_virtuelle icon-page-head"></div>
          <h1>{Translation().pages.virtual_visit.page_title.title}</h1>
          <h2>{Translation().pages.virtual_visit.page_title.baseline}</h2>
        </div>
        <section className="intro-service">
          <Col xs={12} sm={12} md={6} className="cover-service"></Col>
          <Col xs={12} sm={12} md={6} className="details-service">
            <h3>{Translation().pages.virtual_visit.description.title}</h3>
            <p>{Translation().pages.virtual_visit.description.texts[0]}</p>
            <p>{Translation().pages.virtual_visit.description.texts[1]}</p>
            <a className="prices scroll-anch" href="#offers">{Translation().pages.virtual_visit.description.buttons.pricing}</a>
            <a className="app-button-color" href="/shop">{Translation().pages.virtual_visit.description.buttons.order}</a>
          </Col>
          <Clearfix/>
        </section>
        <section className="process-service">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.virtual_visit.guarantees.title}</h3>
              <Col xs={12} sm={5} md={5}>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.virtual_visit.guarantees.texts[0]}</p>
                  <Clearfix/>
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.virtual_visit.guarantees.texts[1]}</p>
                  <Clearfix/>
                </div>
              </Col>
              <Col xs={12} sm={2} md={2}>
                <img src="/assets/images/commons/visit-avatar.png" alt="Plans"/>
              </Col>
              <Col xs={12} sm={5} md={5} className="padding-left">
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.virtual_visit.guarantees.texts[2]}</p>
                  <Clearfix/>
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.virtual_visit.guarantees.texts[3]}</p>
                  <Clearfix/>
                </div>
              </Col>
              <Clearfix/>
            </div>
          </div>
        </section>
        <ShopBanner/>
        <div className="showroom-service">
          <iframe title="virtual-visit" height="100%" width="100%" allowFullScreen="" src="https://my.matterport.com/show/?m=2g6LGQ6boTA" frameBorder="0">
          </iframe>
        </div>
        <section className="prices-service" id="offers">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.virtual_visit.pricing.title}</h3>
              <p className="baseline-title" />
              <Col xs={12} sm={6} md={6}>
                <div className="package">
                  <div className="package-header">
                    <h5>{Translation().pages.virtual_visit.pricing.products.virtual_visit_100.title}</h5>
                    <h4>{Translation().pages.virtual_visit.pricing.products.virtual_visit_100.name}</h4>
                  </div>
                  <div className="icon-visite_virtuelle icon-price"></div>
                  <div className="package-features">
                    <p>{Translation().pages.virtual_visit.pricing.products.virtual_visit_100.texts[0]}</p>
                    <p>{Translation().pages.virtual_visit.pricing.products.virtual_visit_100.texts[1]}</p>
                  </div>
                  <div className="package-details">
                    <p>{Translation().pages.virtual_visit.pricing.products.virtual_visit_100.hosting.texts[0]}</p>
                    <p>&nbsp;</p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">{Translation().pages.virtual_visit.pricing.button}</a>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <div className="package">
                  <div className="package-header">
                    <h5>{Translation().pages.virtual_visit.pricing.products.virtual_visit_200.title}</h5>
                    <h4>{Translation().pages.virtual_visit.pricing.products.virtual_visit_200.name}</h4>
                  </div>
                  <div className="icon-visite_virtuelle icon-price"></div>
                  <div className="package-features">
                    <p>{Translation().pages.virtual_visit.pricing.products.virtual_visit_200.texts[0]}</p>
                  </div>
                  <div className="package-details">
                    <p>{Translation().pages.virtual_visit.pricing.products.virtual_visit_200.hosting.texts[0]}</p>
                    <p>{Translation().pages.virtual_visit.pricing.products.virtual_visit_200.hosting.texts[1]}</p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">{Translation().pages.virtual_visit.pricing.button}</a>
                </div>
              </Col>
              <Clearfix/>
              <div className="pagination-service">
                <div className="prev-service">
                  <a href="/floorplan">
                    <span className="icon-chevron_gauche icon-pagination"></span>
                    <p>{Translation().header.app_nav.products.plans.title}</p>
                  </a>
                </div>
                <div className="next-service">
                  <a href="/certificates">
                    <p>{Translation().header.app_nav.products.diagnostic.title}</p>
                    <span className="icon-chevron_droite icon-pagination"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default VirtualVisit;
