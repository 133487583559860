import React, { Component } from 'react';

class ClientsSlider extends Component {

  render() {

    return (
      <div className="clients-slider">
        <div className="clients-slides">
          <img src="/assets/images/home/client-2.png" className="clients-images" alt="Clients" />
        </div>
        <div className="clients-slides">
          <img src="/assets/images/home/client-3.png" className="clients-images" alt="Clients" />
        </div>
        <div className="clients-slides">
          <img src="/assets/images/home/client-5.png" className="clients-images" alt="Clients" />
        </div>
        <div className="clients-slides">
          <img src="/assets/images/home/client-6.png" className="clients-images" alt="Clients" />
        </div>
        <div className="clients-slides">
          <img src="/assets/images/home/client-7.png" className="clients-images" alt="Clients" />
        </div>
        <div className="clients-slides">
          <img src="/assets/images/home/client-8.png" className="clients-images" alt="Clients" />
        </div>
        <div className="clients-slides">
          <img src="/assets/images/home/client-9.png" className="clients-images" alt="Clients" />
        </div>
        <div className="clients-slides">
          <img src="/assets/images/home/client-10.png" className="clients-images" alt="Clients" />
        </div>
      </div>
    );
  }
}

export default ClientsSlider;
