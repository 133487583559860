import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import Translation from '../../translation/Translation';

class Accordion extends Component {

  constructor(props) {

    super(props);

    this.state = { open: false }

    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion() {
    this.setState({ open: !this.state.open });
  }

  render() {

    const { open } = this.state;
    const { title, text } = this.props;

    return (
      <div className="faq-item">
        <h5 onClick={this.toggleAccordion}> {open ? <span>-</span> : <span>+</span>} {title}</h5>
        { open && <p>{text}</p> }
      </div>
    );
  }
}

class Faq extends Component {

  constructor(props) {

    super(props);

    this.state = { tabIndex: 1 }
  }

  handleTabIndex(tabIndex) {
    this.setState({ tabIndex });
  }

  render() {

    const { tabIndex } = this.state;

    return (
      <div className="app-service app-faq">
        <div className="page-header-title">
          <h1>{Translation().pages.faq.page_title.title}</h1>
          <h2>{Translation().pages.faq.page_title.baseline}</h2>
        </div>
        <section className="container">
          <div className="row">
            <div className="wrapper">
              <Col xs={12} sm={3} md={2}>
                <ul className="faq-item-list">
                  <li onClick={this.handleTabIndex.bind(this, 1)}>
                    <span className="icon-chevron_droite" style={tabIndex === 1 ? {visibility: 'visible'} : {visibility: 'hidden'}} />
                    {Translation().pages.faq.items_list[0]}
                  </li>
                  <li onClick={this.handleTabIndex.bind(this, 2)}>
                    <span className="icon-chevron_droite" style={tabIndex === 2 ? {visibility: 'visible'} : {visibility: 'hidden'}} />
                    {Translation().pages.faq.items_list[1]}
                  </li>
                  <li onClick={this.handleTabIndex.bind(this, 3)}>
                    <span className="icon-chevron_droite" style={tabIndex === 3 ? {visibility: 'visible'} : {visibility: 'hidden'}} />
                    {Translation().pages.faq.items_list[2]}
                  </li>
                  <li onClick={this.handleTabIndex.bind(this, 4)}>
                    <span className="icon-chevron_droite" style={tabIndex === 4 ? {visibility: 'visible'} : {visibility: 'hidden'}} />
                    {Translation().pages.faq.items_list[3]}
                  </li>
                  <li onClick={this.handleTabIndex.bind(this, 5)}>
                    <span className="icon-chevron_droite" style={tabIndex === 5 ? {visibility: 'visible'} : {visibility: 'hidden'}} />
                    {Translation().pages.faq.items_list[4]}
                  </li>
                </ul>
              </Col>
              <Col xs={12} sm={9} md={10}>
                {
                  tabIndex === 1 ? (
                    <div className="faq-items">
                      <Accordion
                        title={Translation().pages.faq.planning.cancel.title}
                        text={Translation().pages.faq.planning.cancel.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.planning.change_appointment.title}
                        text={Translation().pages.faq.planning.change_appointment.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.planning.change_order.title}
                        text={Translation().pages.faq.planning.change_order.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.planning.unable_to_order.title}
                        text={Translation().pages.faq.planning.unable_to_order.text}
                      />
                    </div>
                  )
                  : ""
                }
                {
                  tabIndex === 2 ? (
                    <div className="faq-items">
                      <Accordion
                        title={Translation().pages.faq.account.change_pwd.title}
                        text={Translation().pages.faq.account.change_pwd.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.account.create_company.title}
                        text={Translation().pages.faq.account.create_company.text}
                      />
                    </div>
                  )
                  : ""
                }
                {
                  tabIndex === 3 ? (
                    <div className="faq-items">
                      <Accordion
                        title={Translation().pages.faq.orders.how_change.title}
                        text={Translation().pages.faq.orders.how_change.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.orders.how_executed.title}
                        text={Translation().pages.faq.orders.how_executed.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.orders.how_order.title}
                        text={Translation().pages.faq.orders.how_order.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.orders.order_not_delivred.title}
                        text={Translation().pages.faq.orders.order_not_delivred.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.orders.orders_format.title}
                        text={Translation().pages.faq.orders.orders_format.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.orders.order_issue.title}
                        text={Translation().pages.faq.orders.order_issue.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.orders.not_satisfied.title}
                        text={Translation().pages.faq.orders.not_satisfied.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.orders.delivery_time.title}
                        text={Translation().pages.faq.orders.delivery_time.text}
                      />
                    </div>
                  )
                  : ""
                }
                {
                  tabIndex === 4 ? (
                    <div className="faq-items">
                      <Accordion
                        title={Translation().pages.faq.payment.use_promo.title}
                        text={Translation().pages.faq.payment.use_promo.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.payment.multiple_methods.title}
                        text={Translation().pages.faq.payment.multiple_methods.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.payment.reimbursement.title}
                        text={Translation().pages.faq.payment.reimbursement.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.payment.price_iusse.title}
                        text={Translation().pages.faq.payment.price_iusse.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.payment.billing_time.title}
                        text={Translation().pages.faq.payment.billing_time.text}
                      />
                      <Accordion
                        title={Translation().pages.faq.payment.add_method_failed.title}
                        text={Translation().pages.faq.payment.add_method_failed.text}
                      />
                    </div>
                  )
                  : ""
                }
                {
                  tabIndex === 5 ? (
                    <div className="faq-items">
                      <Accordion
                        title={Translation().pages.faq.medias.watermark.title}
                        text={Translation().pages.faq.medias.watermark.text}
                      />
                    </div>
                  )
                  : ""
                }
              </Col>
              <Clearfix/>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Faq;
