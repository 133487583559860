import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { initPlaceholder } from '../../helpers/JqueryFunctions';
import renderField from '../../components/commons/fields/renderField';
import validate from './validate';

class RequestPasswordForm extends Component {

  componentDidMount() {
    initPlaceholder();
  }

  render() {

    const {
      handleSubmit,
      submitting,
      isLoading,
      isSendSuccess,
      errorMessage,
      labels,
      buttonLabel,
      callbackMessage
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          type="email"
          component={renderField}
          label={labels.email}
          className="app-basic-input"
        />
        <button type="submit" className="app-button-color button-api" disabled={submitting}>
          { isLoading
            ? <span className="loader-api" style={{display: 'inline-block'}} />
          : <span className="btn-login-text">{buttonLabel}</span>
          }
        </button>
        { isSendSuccess &&
          <div className="request-done">
            <p><span className="icon-check" /> {callbackMessage}</p>
          </div>
        }
        { errorMessage &&
          <p className="error-message">{errorMessage}</p>
        }
      </form>
    );
  }
}

export default reduxForm({
  form: 'requestPassword',
  validate
})(RequestPasswordForm);
