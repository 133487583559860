// import { TweenMax } from 'gsap';
// const gsap = require("gsap");
// const TweenMax = gsap.TweenMax;

import { TweenMax } from 'gsap';

/**
 * DOM animations functions
 */
class AnimFunctions {

  CalcOffset(element) {
    if (element) {
      var rect = element.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
    else {
      return false
    }
  }

  ShowFromLeftCover(div) {
    TweenMax.fromTo(
      div, 1,
        {autoAlpha: 0, left: '-100%', xPercent: -100},
        {autoAlpha: 1, left: '0%', xPercent: 0}
    );
  }

  ShowFromRightCover(div) {
    TweenMax.fromTo(
      div, 1,
        {autoAlpha: 0, right: '-100%', xPercent: 100},
        {autoAlpha: 1, right: '20%', xPercent: 0}
    );
  }

  ShowFromLeftDetail(div) {
    TweenMax.fromTo(
      div, 1,
        {autoAlpha: 0, left: '-100%', xPercent: -100,  yPercent: 20},
        {autoAlpha: 1, left: '40%', xPercent: 0, yPercent: 20}
    );
  }

  ShowFromRightDetail(div) {
    TweenMax.fromTo(
      div, 1,
        {autoAlpha: 0, right: '-100%', xPercent: 100, yPercent: 20},
        {autoAlpha: 1, right: '20%', xPercent: 0, yPercent: 20}
    );
  }

  ShowFade(div) {
    TweenMax.fromTo(
      div, 2,
        {autoAlpha: 0},
        {autoAlpha: 1}
    );
  }

  ShowNoFade(div) {
    TweenMax.fromTo(
      div, 0,
        {autoAlpha: 0},
        {autoAlpha: 1}
    );
  }
}

export default new AnimFunctions();
