/**
 * CART ACTIONS
 */

export const storeCartRequest = (cartRequest) => ({
  type: 'STORE_CART_REQUEST',
  cartRequest: cartRequest
});

export const storeCartRequestSlot = (slot) => ({
  type: 'STORE_CART_REQUEST_SLOT',
  slot: slot
});

export const removeCartRequestSlot = () => ({
  type: 'REMOVE_CART_REQUEST_SLOT'
})

export const storeCartRequestProperty = (property) => ({
  type: 'STORE_CART_REQUEST_PROPERTY',
  property: property
});

export const storeCartRequestProductId = (productId) => ({
  type: 'STORE_CART_REQUEST_PRODUCT_ID',
  product_id: productId
});

export const storeCartRequestOptions = (options) => ({
  type: 'STORE_CART_REQUEST_OPTIONS',
  options: options
});

export const removeCartRequestOptions = (options) => ({
  type: 'REMOVE_CART_REQUEST_OPTIONS',
});

export const removeCartRequest = () => ({
  type: 'REMOVE_CART_REQUEST'
});

/**
 * User cart
 */
export const storeUserCart = (cart) => ({
  type: 'STORE_USER_CART',
  userCart: cart
});

export const removeUserCart = () => ({
  type: 'REMOVE_USER_CART',
}); 