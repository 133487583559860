import React, { Component } from 'react';

class PrivacyPolicyFR extends Component {
  render () {
    return (
      <div className="app-terms-of-use">
        <div className="articles">
          <div className="article-content" id="introduction">
            <p>
              Pour Wall-Market, le respect de votre vie privée est essentiel. La présente politique a été conçue pour vous aider à comprendre la manière dont nous collectons et traitons vos données personnelles dans le cadre de la fourniture de nos services et votre utilisation de notre site Internet <a href="https://lightshop.wall-market.com" target="_blank" rel="noopener noreferrer">lightshop.wall-market.com</a> et de nos applications Lightshop, et quels sont vos droits.
            </p>
            <ul>
              <li>1. QUI EST RESPONSABLE DU TRAITEMENT DE VOS DONNEES ?</li>
              <li>2. QUELLES DONNÉES COLLECTONS-NOUS ?</li>
              <li>3. POURQUOI COLLECTONS-NOUS VOS DONNÉES ET COMBIEN DE TEMPS LES CONSERVONS-NOUS ?</li>
              <li>4. AVEC QUI PARTAGEONS-NOUS VOS DONNÉES ?</li>
              <li>5. QUELS SONT VOS DROITS ET COMMENT LES EXERCER ?</li>
              <li>6. CONTACT</li>
              <li>7. MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</li>
            </ul>
          </div>
          <div className="article-content">
            <h2>1. QUI EST RESPONSABLE DU TRAITEMENT DE VOS DONNEES ?</h2>
            <p>
              La société Wall-Market, immatriculée sous le numéro 532 727 740 RCS Paris, et dont le siège social se situe  22 rue Legendre, 75017 Paris, France, est le responsable du traitement de vos données.
            </p>
          </div>
          <div className="article-content with-list">
            <h2>2. QUELLES DONNÉES COLLECTONS-NOUS ?</h2>
            <p>Nous collectons les données personnelles suivantes:</p>
            <ul>
              <li>
                <span className="bold">Les données que vous nous fournissez:</span> lors de votre inscription sur notre site ou notre application pour accéder à nos services ou de la mise à jour de votre compte, ou encore lorsque vous nous contactez (notamment via notre formulaire de contact, email, téléphone ou les réseaux sociaux), nous collectons les informations personnelles que vous nous fournissez. Par exemple, lorsque vous créez un compte vous pouvez être amené à nous transmettre vos nom, prénom, adresse mél, adresse postale (pays de résidence à supposer qu’il soit différent), numéro de téléphone portable et/ou fixe, date de naissance, nationalité, informations relatives à votre carte bancaire ou votre compte bancaire, langue(s) parlée(s) et le cas échéant photo.
                <br /><br />
                Dans tous les cas, nous ne vous demanderons que les informations dont nous avons réellement besoin. Vous êtes libres de nous les communiquer ou non, mais si vous refusez, vous ne pourrez pas utiliser nos services.
                <br /><br />
              </li>
              <li>
                <span className="bold">Les données sur les transactions:</span> nous recueillons les données relatives aux transactions associées à l'utilisation de nos services, y compris le type de service commandé ou fourni, les détails de la commande, l'heure et la date d'exécution du service, le montant facturé et le moyen de paiement.
                <br /><br />
              </li>
              <li>
                <span className="bold">Les données d'utilisation:</span> nous recueillons des données sur l'interaction des utilisateurs avec nos services. Elles incluent des données telles que les dates et heures d'accès, les fonctionnalités ou les pages d'application utilisées, les pannes d'application et les autres activités associées au système, ainsi que le type de navigateur. Nous recueillons ces données de manière automatique par l'intermédiaire de cookies. Pour en savoir plus sur ces technologies, veuillez lire notre <a href="javascript:Didomi.preferences.show()">Politique relative aux cookies</a>.
                <br /><br />
              </li>
              <li>
                <span className="bold">La géolocalisation:</span> nous recueillons votre géolocalisation, notamment pour permettre l'organisation et le suivi des missions. Nous recueillons ces données avec votre consentement. Vous pourrez désactiver la géolocalisation à tout moment dans les réglages de l’application. La géolocalisation est facultative mais améliore sensiblement le fonctionnement de nos services et réduit fortement les problèmes de mise en relation.
                <br /><br />
              </li>
            </ul>
            <p>
              Nos services ne s'adressent pas aux mineurs et ne sont pas destinés à être utilisés par des mineurs. Nous ne collectons pas sciemment des informations auprès de mineurs et nous ne leur demandons aucune information. Nous n'autorisons pas sciemment les mineurs à utiliser nos services. Toute personne fournissant ses informations à Wall-Market par le biais de la page de connexion au compte pour les nouveaux clients, de la page d'inscription ou de toute autre partie de notre site ou de notre application déclare avoir atteint l'âge légal de la majorité dans son pays de résidence. Si nous découvrons que nous avons recueilli des informations auprès d'un mineur sans l'autorisation de ses parents ou de son ou ses tuteurs légaux, nous les supprimerons. Veuillez nous contacter aux coordonnées indiquées à l'article 6. <a href="#privacy-policy-contact">« Contact »</a> ci-dessous si vous pensez nous avoir fourni des informations sur un mineur sans l'autorisation de ses parents ou de son ou ses tuteurs légaux.
            </p>
          </div>
          <div className="article-content">
            <h2>3. POURQUOI COLLECTONS-NOUS VOS DONNÉES ET COMBIEN DE TEMPS LES CONSERVONS-NOUS ?</h2>
            <p>
              Nous traitons vos données pour des finalités définies, sur la base d’une « justification » légale (ou base légale), et pour une durée limitée.  Le tableau ci-dessous détaille les finalités et bases légales de nos traitements, ainsi que les durées de conservation applicables. 
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Finalités</th>
                  <th style={{textAlign: 'center'}}>Bases légales</th>
                  <th style={{textAlign: 'center'}}>Durée de conservation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Gestion de votre compte utilisateur (création et mise à jour)
                  </td>
                  <td>
                    Ce traitement est nécessaire à l’exécution du contrat conclu entre vous et Wall-Market.
                  </td>
                  <td>
                    Aussi longtemps que vous conservez votre compte.<br/><br/>En cas d’inactivité pendant une période de 5 ans, votre compte sera supprimé automatiquement. 
                  </td>
                </tr>
                <tr>
                  <td>
                    Gestion des commandes (fourniture des services, suivi de la relation et analyses statistiques)
                  </td>
                  <td>
                    Ce traitement est nécessaire à l’exécution du contrat conclu entre vous et Wall-Market.
                  </td>
                  <td>
                    Nous conservons également un historique de toutes vos commandes sur les 5 dernières années à des fins de suivi client et d’analyse statistique.  
                  </td>
                </tr>
                <tr>
                  <td>
                    Géolocalisation (proposition de missions à proximité, suivi de la mission et gestion des contestations)
                  </td>
                  <td>
                    Ce traitement est effectué avec votre consentement.
                  </td>
                  <td>
                    48 heures. Néanmoins, les données peuvent être conservées pour une durée supérieure en cas de contestation des prestations effectuées, jusqu’au règlement de la contestation.
                  </td>
                </tr>
                <tr>
                  <td>Évaluation des utilisateurs</td>
                  <td>Ce traitement est nécessaire à la poursuite de notre intérêt légitime afin de garantir et améliorer la qualité des services.</td>
                  <td>Tant que vous disposez d’un compte actif, plus 5 ans</td>
                </tr>
                <tr>
                  <td>Traitement des demandes reçues par tout moyen (formulaire de contact, email, téléphone)</td>
                  <td>Ce traitement est effectué avec votre consentement.</td>
                  <td>Temps nécessaire au traitement de votre demande</td>
                </tr>
                <tr>
                  <td>Envoi de communiqués aux prestataires indépendants partenaires</td>
                  <td>Ce traitement est nécessaire à la poursuite de notre intérêt légitime pour animer notre communauté de prestataires indépendants partenaires et les tenir informés de l’actualité de Wall-Market.</td>
                  <td>Tant que le prestataire indépendant dispose d’un compte actif ou jusqu’à ce qu’il se désinscrive de l’envoi de ces communiqués.</td>
                </tr>
                <tr>
                  <td>Envoi d’emails marketing (offres commerciales, newsletters, etc.)</td>
                  <td>Ce traitement est nécessaire à la poursuite de notre intérêt légitime pour développer notre activité. </td>
                  <td>Jusqu’à ce que vous vous opposiez à recevoir nos emails marketing ou 3 ans à compter du dernier contact.</td>
                </tr>
                <tr>
                  <td>Obligations comptables, fiscales, etc.</td>
                  <td>Ce traitement est nécessaire au respect d’une obligation légale à laquelle nous sommes soumis.</td>
                  <td>Pour la durée légale de conservation applicable</td>
                </tr>
                <tr>
                  <td>Gestion des précontentieux et contentieux</td>
                  <td>Ce traitement est nécessaire à la poursuite de notre intérêt légitime pour l’établissement de la preuve d’un droit ou d’un contrat, et la défense de nos droits.</td>
                  <td>Pour la durée de la prescription liée. En règle générale, nous conservons les données pendant 6 ans.</td>
                </tr>
                <tr>
                  <td>Gestion des demandes d’exercice des droits</td>
                  <td>Ce traitement est nécessaire au respect d’une obligation légale à laquelle nous sommes soumis. </td>
                  <td>5 ans à compter de la fin de l’année civile de votre demande.<br/><br/>
                      Si une copie de pièce d’identité vous est demandée, elle est conservée pendant 1 an si votre demande tend à exercer vos droits d’accès ou de rectification et pendant 3 ans, en cas d’exercice de votre droit d’opposition ou de suppression.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Veuillez noter que vos données peuvent être conservées plus longtemps, par exemple afin d’établir la preuve d’un droit ou au titre d’une obligation légale. Dans tous les cas, vos données ne sont pas conservées au-delà de ce qui est strictement nécessaires aux finalités pour lesquelles elles sont traitées. Lorsque vos données ne seront plus nécessaires, nous veillerons à ce qu’elles soient soit supprimées ou anonymisées.
            </p>
          </div>
          <div className="article-content with-list">
            <h2>4. AVEC QUI PARTAGEONS-NOUS VOS DONNÉES ?</h2>
            <p>
              Nous partageons vos données uniquement avec :
            </p>
            <ul>
              <li>d’autres utilisateurs uniquement pour permettre la réalisation des missions,</li>
              <li>les personnes dûment habilitées de Wall-Market, qui ont besoin d’accéder à vos données dans le cadre de leurs fonctions,</li>
              <li>nos sous-traitants, qui traitent vos données au nom et pour le compte de Wall-Market (notamment nos prestataires d’hébergement et maintenance, analytics et service de paiement),</li>
              <li>nos conseillers externes (avocats, auditeurs, etc.), lorsque cela est nécessaire aux fins des intérêts légitimes poursuivis par Wall-Market,</li>
              <li>des autorités publiques ou judiciaires, dès lors que nous y sommes légalement tenus.</li>
            </ul>
            <p>Nous pouvons également partager vos données personnelles avec des tiers dans le cadre d’une vente ou d’une restructuration potentielle ou effective de notre société ou de certains de nos actifs, auquel cas vos données pourront faire partie des actifs transférés.</p>
            <p>Vos données personnelles sont susceptibles d’être transférées en dehors de l’Espace économique européen (EEE), vers des pays n’offrant pas un niveau de protection à vos données équivalent à celui dont vous bénéficiez au sein de l'EEE. En l’absence de décision d’adéquation de la Commission européenne en vertu de l’article 45 du RGPD, le transfert de vos données personnelles sera encadré par des mécanismes de transfert appropriés en vertu des articles 46 et suivants du RGPD (tels que des clauses contractuelles types adoptées par la Commission européenne). Vous pouvez en obtenir une copie (à l’exclusion des dispositions confidentielles) en nous contactant aux coordonnées indiquées à l’article CONTACT <a href="#privacy-policy-contact">« Contact »</a>. </p>
          </div>
          <div className="article-content with-list">
            <h2>5. QUELS SONT VOS DROITS ET COMMENT LES EXERCER ?</h2>
            <p>
              Vous bénéficiez d’un certain nombre de droits sur vos données. 
            </p>
            <ul>
              <li>Vous pouvez révoquer à tout moment, votre consentement à tout traitement de vos données fondé sur votre consentement.</li>
              <li>Vous pouvez nous demander de confirmer si nous traitons vos données et, le cas échéant, vous informer des caractéristiques du traitement, vous permettre d’y accéder et en obtenir une copie.</li>
              <li>Vous pouvez nous demander de rectifier ou compléter vos données si elles sont incorrectes ou incomplètes.</li>
              <li>Vous pouvez, dans certains cas, nous demander d’effacer vos données ou d’en limiter le traitement.</li>
              <li>Vous pouvez nous demander, dans certains cas, de vous fournir vos données dans un format structuré, couramment utilisé et lisible par une machine, ou vous pouvez demander qu’elles soient transmises directement à un autre responsable de traitement. </li>
              <li>Vous avez le droit de définir des directives (générales ou spécifiques) relatives au sort de vos données après votre mort.</li>
              <li className="bold">
                Vous pouvez vous opposer à tout traitement de vos données qui est basé sur notre « intérêt légitime ». Si vous exercez ce droit, nous devons cesser le traitement, sauf si nous démontrons l’existence de motifs légitimes et impérieux qui prévalent sur vos droits et libertés fondamentaux, ou pour la constatation, l’exercice ou la défense de droits en justice. 
              </li>
              <li className="bold">
                Vous pouvez vous opposer à tout moment au traitement de vos données à des fins de prospection. 
              </li>
            </ul>
            <p>Vous pouvez exercer vos droits en nous contactant aux coordonnées indiquées à l’article CONTACT <a href="#privacy-policy-contact">« Contact »</a>. </p>
            <p>Vous avez également le droit d’introduire une réclamation auprès de l’autorité de contrôle compétente relative au traitement de vos données. </p>
          </div>
          <div className="article-content" id="privacy-policy-contact">
            <h2>6. CONTACT</h2>
            <p>Pour davantage d’informations concernant vos droits, les exercer ou pour toute question ou réclamation concernant la protection de vos données personnelles, veuillez nous contacter à <a href="mailto: rgpd@wall-market.com">rgpd@wall-market.com</a>.</p>
          </div>
          <div className="article-content">
            <h2>7. MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
            <p>Nous pouvons modifier la présente politique de confidentialité occasionnellement, par exemple pour prendre en compte les changements légaux, les avancées technologiques et les bonnes pratiques commerciales. Nous vous informerons en cas de changement substantiel.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicyFR;
