import React from 'react';
import DynamicNumber from 'react-dynamic-number';

import { getUnitSystemSymbol } from '../../../helpers/HelperFunctions';

const NumberField = ({
  id,
  input,
  required,
  positive,
  integer,
  fraction,
  negative,
  label,
  separator,
  className,
  isUnitSystem,
  unitSystem,
  meta: { touched, error },
  ...props
}) => {
  let thousand = false;

  return (
    <div className={isUnitSystem ? 'app-system-unit-input-group' : ''}>
      <DynamicNumber
        {...props}
        {...input}
        value={input.value ? parseFloat(input.value) : ''}
        positive={positive}
        negative={negative}
        separator={separator}
        thousand={thousand}
        integer={integer}
        fraction={fraction}
        id={id}
        required={required}
        className={isUnitSystem ? 'app-system-unit-input' : className}
        placeholder={label}
      />
      {isUnitSystem && (
        <div className="unit-system">
          {unitSystem ? unitSystem : getUnitSystemSymbol()}
        </div>
      )}
    </div>
  );
};

export default NumberField;
