import Translation from '../../../translation/Translation';

const validate = (values) => {

  const errors = {};

  if (!values.email1) {
    errors.email1 = Translation().forms.errors.required;
  }
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email1)) {
    errors.email1 = Translation().forms.errors.email_invalid;
  }

  if (values.email2 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email2)) {
    errors.email2 = Translation().forms.errors.email_invalid;
  }

  if (values.email3 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email3)) {
    errors.email3 = Translation().forms.errors.email_invalid;
  }

  return errors;
}

export default validate;
