import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import Rating from 'react-rating';

import Snackbar from '../../../commons/snackbar/Snackbar';
// import Support from '../../../commons/support/Support';
import {
  apiGetUserOrderProductMedias,
  cancelOrderProduct,
  rateOrderProduct
} from '../../../../helpers/AccountFunctions';
import { toUnix, createDateUtc, dateInTz, formatDate, convertMinsToHrsMins } from '../../../../helpers/DateFunctions';
import { getBrandPhoneNumber } from '../../../../helpers/UserFunctions';
import ProductRating from '../../../commons/productRating/ProductRating';
import Planning from './planning/Planning';
import HostingPlans from './hostingPlans/HostingPlans';
import OrderProductMediaGroup from './OrderProductMediaGroup';

import Translation from '../../../../translation/Translation';

class OrderProduct extends Component {

  constructor(props) {
    super(props);

    this.state = {
      autoHideDuration: 4000,
      isCancelInProgess: false,
      isCancelOpen: false,
      isHostingPlansOpen: false,
      isPlanningModalOpen: false,
      isProductMediasLoading: false,
      isSupportOpen: false,
      isSnackbarOpen: undefined,
      productMedias: null,
      productCancelled: null,
      productStartUpdated: null,
      productExpireUpdated: null,
      ratingDone: null,
      snackbarMessage: '',
      snackbarStatus: ''
    };

    this.toggleCancelProduct = this.toggleCancelProduct.bind(this);
    this.cancelProduct = this.cancelProduct.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.openSupport = this.openSupport.bind(this);
    this.closeSupport = this.closeSupport.bind(this);
    this.handleOpenRateModal = this.handleOpenRateModal.bind(this);
    this.handleCloseRateModal = this.handleCloseRateModal.bind(this);
    this.handleOpenPlanning = this.handleOpenPlanning.bind(this);
    this.handleOpenHostingPlans = this.handleOpenHostingPlans.bind(this);
    this.handleClosePlanning = this.handleClosePlanning.bind(this);
    this.handleCloseHostingPlans = this.handleCloseHostingPlans.bind(this);
    this.handleSubmitRating = this.handleSubmitRating.bind(this);
    this.handleErrorUpdateStart = this.handleErrorUpdateStart.bind(this);
    this.handleSuccessUpdateStart = this.handleSuccessUpdateStart.bind(this);
    this.handleSuccessHostingPlans = this.handleSuccessHostingPlans.bind(this);
  }

  componentWillReceiveProps(nextProps) {

    if (!nextProps.isOpen) {
      /**
       * Close all on order closing
       */
      this.setState({
        isDlSettingsOpen: false,
        isCancelOpen: false
      });
    }
    else {
      /**
       * Load the orders medias
       */
       if (nextProps.product.downloadable) {
        this.setState({ isProductMediasLoading: true });

        apiGetUserOrderProductMedias(
          this.props.userType,
          this.props.orderId,
          this.props.product.id,
          data => {
            this.setState({
              productMedias: data,
              isProductMediasLoading: false
            });
          },
          () => {
            this.setState({
              productMedias: [],
              isProductMediasLoading: false
            });
          }
        );
      }
    }
  }

  toggleCancelProduct() {
    this.setState({ isCancelOpen: !this.state.isCancelOpen });
  }

  handleOpenHostingPlans() {
    this.setState({ isHostingPlansOpen: true });
  }

  handleCloseHostingPlans() {
    this.setState({ isHostingPlansOpen: false });
  }

  cancelProduct() {
    /**
     * Cancel an order product
     */
    this.setState({ isCancelInProgess: true });

    cancelOrderProduct(
      this.props.userType,
      this.props.orderId,
      this.props.product.id,
      (successData) => {

        /**
         * Send order product id to google tag manager
         */
        if (window && window.dataLayer) {
          window.dataLayer.push({
            event: "order_product.cancelled",
            order_product_id: successData.id,
          });
        }

        this.setState({
          isCancelInProgess: false,
          isCancelOpen: false,
          snackbarMessage: Translation().account.orders.cancel_product.product_cancelled,
          snackbarStatus: "success",
          productCancelled: successData.cancelled ? true : false
        });
        this.openSnackbar();
      },
      (error) => {
        this.setState({
          isCancelInProgess: false,
          isCancelOpen: false,
          snackbarMessage: Translation().account.orders.cancel_product.product_cancel_failed,
          snackbarStatus: "error"
        });
        this.openSnackbar();
      }
    )
  }

  openSnackbar() {
    this.setState({ isSnackbarOpen: true });
  };

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  };

  openSupport() {
    this.setState({ isSupportOpen: true });
  }

  closeSupport() {
    this.setState({ isSupportOpen: false });
  }

  handleOpenRateModal(rate) {
    this.setState({ isRateModalOpen: true });
  };

  handleCloseRateModal() {
    this.setState({ isRateModalOpen: false });
  };

  handleOpenPlanning () {
    this.setState({ isPlanningModalOpen: true });
  };

  handleClosePlanning() {
    this.setState({ isPlanningModalOpen: false });
  };

  handleSubmitRating(values) {

    if (!values || !values.rating)
      return;

    this.setState({ isRatingLoading: true });

    rateOrderProduct(
      this.props.userType,
      this.props.orderId,
      this.props.product.id,
      values,
      success => {
        this.setState({
          isRatingLoading: false,
          isRateModalOpen: false,
          snackbarMessage: Translation().account.orders.rating.form.callbacks.success,
          snackbarStatus: "success",
          ratingDone: values.rating
        });
        this.openSnackbar();
      },
      error => {
        const errorMessage = error && error.response && error.response.data && error.response.data.detail ? error.response.data.detail : Translation().account.orders.rating.form.callbacks.error;

        this.setState({
          isRatingLoading: false,
          snackbarMessage: errorMessage,
          snackbarStatus: "error",
          ratingDone: null
        });
        this.openSnackbar();
      }
    )
  }

  handleErrorUpdateStart() {
    this.setState({
      snackbarMessage: Translation().account.orders.update_start.error,
      snackbarStatus: "error",
    });
    this.openSnackbar();
  }

  handleSuccessUpdateStart(success) {
    this.setState({
      productStartUpdated: success && success.start ? success.start : null,
      snackbarMessage: Translation().account.orders.update_start.success,
      isPlanningModalOpen: false,
      snackbarStatus: "success",
    });
    this.openSnackbar();
  }

  handleSuccessHostingPlans(success) {
    this.setState({
      productExpireUpdated: success && success.extend_until ? success.extend_until : null,
      snackbarMessage: Translation().account.orders.hosting_plans.callbacks_post.success,
      isHostingPlansOpen: false,
      isSnackbarOpen: true,
      snackbarStatus: "success"
    });
  }

  render() {

    const {
      product,
      userType,
      orderId,
      orderRef,
      orderUser,
      isCompanyOrder,
      property
    } = this.props;

    const {
      autoHideDuration,
      isCancelInProgess,
      isCancelOpen,
      isHostingPlansOpen,
      isPlanningModalOpen,
      isRatingLoading,
      isRateModalOpen,
      isSnackbarOpen,
      // isSupportOpen,
      isProductMediasLoading,
      productMedias,
      productCancelled,
      productStartUpdated,
      productExpireUpdated,
      ratingDone,
      snackbarMessage,
      snackbarStatus
    } = this.state;

    /**
     * We check if the difference between the expiration date and today's date is greater than two months
     */
    const todayUtc = formatDate(createDateUtc(), "YYYY-MM-DDTHH:mm:ss");
    const daysBetweenNowAndExpire = product && product.expire ? (toUnix(productExpireUpdated || product.expire) - toUnix(todayUtc)) / 86400 : 0;
    const isProductExpireSoon = daysBetweenNowAndExpire <= 60;
    const showButtonExpired = product && product.renewable && isProductExpireSoon && !product.cancelled ? true : false;
    const isProductExpired = product && product.expire && toUnix(productExpireUpdated || product.expire) < toUnix(todayUtc) ? true : false;

    const timezoneCode = property && property.timezone_code ? property.timezone_code : undefined;
    const phoneNumber = getBrandPhoneNumber();

    return (
      <div className="order-product">
        { isRateModalOpen &&
          <ProductRating
            product={product}
            timezoneCode={timezoneCode}
            propertyAddress={property && property.address ? property.address : null}
            onClose={this.handleCloseRateModal}
            onSubmit={this.handleSubmitRating}
            isLoading={isRatingLoading}
          />
        }
        <Col xs={12}>
          <h2>
            { product.name && product.name }
            { productCancelled || product.cancelled
              ? (
                <span className="cancelled">
                  {Translation().account.orders.cancel_product.cancelled}
                </span>
              )
              : ''
            }
          </h2>
          { product.provider_rating || ratingDone
            ? (
              <div className="product-rating">
                <Rating
                  initialRating={product.provider_rating || ratingDone}
                  readonly={true}
                  emptySymbol={<span className="icon-etoilepleine rate-icon" />}
                  fullSymbol={<span className="icon-etoilepleine rate-icon filled" />}
                />
              </div>
            )
            : product.downloadable ? (
              <div className="product-rating">
                <p onClick={this.handleOpenRateModal} className="trigger-notation">
                  {Translation().account.orders.rating.trigger_rating}
                </p>
              </div>
            ) : false
          }
        </Col>
        <Clearfix/>
        {/* { isSupportOpen &&
          <div className="app-overlay overlay-support">
            <div className="container">
          <div className="row">
          <div className="app-modal">
          <div className="icon-fermer close-modal" onClick={this.closeSupport} />
          <h4 className="app-modal-title">Obtenir de l'aide</h4>
          <p className="app-modal-desc">
          Problème sur une commande livrée
          </p>
          <div className="app-modal-content">
          <Support
          // orderId
          // orderProductId
          // orderDone={false}
          // onActionSuccess
          // onActionFailure
          />
          </div>
          </div>
          </div>
            </div>
          </div>
        } */}
        { isCancelOpen &&
          <div className="app-overlay overlay-cancel">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.toggleCancelProduct} />
                  <h4 className="app-modal-title">{Translation().account.orders.cancel_product.modal.title},</h4>
                  <p className="app-modal-desc">
                    {Translation().account.orders.cancel_product.modal.description}
                    <span style={{fontStyle: "italic", fontSize: "10px", display: "block"}}>{Translation().account.orders.cancel_product.modal.text}</span>
                  </p>
                  <div className="app-modal-content">
                    <div className="btn-wrapper">
                      <div className="app-button-color confirm" onClick={this.toggleCancelProduct}>
                        <span className="confirm-icon icon-fermer left" /> {Translation().account.orders.cancel_product.modal.cancel}
                      </div>
                      <div className="app-button-color confirm" onClick={this.cancelProduct}>
                        <span className="loader-api" style={isCancelInProgess ? {display: 'block'} : {}} />
                        <span id="cpo-txt" style={isCancelInProgess ? {opacity: 0, visbility: 'hidden'} : {}}>
                          <span>{Translation().account.orders.cancel_product.modal.confirm}</span>
                          <span className="confirm-icon icon-check right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { isPlanningModalOpen &&
          <div className="app-overlay order-product-planning-overlay">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.handleClosePlanning} />
                  <h4 className="app-modal-title">{timezoneCode ? Translation().account.orders.update_start.modal.title : Translation().shop.informations.timezone_not_found.title}</h4>
                  <p className="app-modal-desc">
                    {timezoneCode ? Translation().account.orders.update_start.modal.description : Translation().shop.informations.timezone_not_found.desc}
                  </p>
                  <div className="app-modal-content">
                    { timezoneCode
                      ? (
                        <div className="product-edit-start">
                          <Planning
                            orderId={product.order_id}
                            productId={product.id}
                            timezoneCode={timezoneCode}
                            onError={this.handleErrorUpdateStart}
                            onSuccess={this.handleSuccessUpdateStart}
                            userType={userType}
                          />
                        </div>
                      )
                      : (
                        <div>
                          <div className="property-timezone-error">
                            <div className="error-send">
                              { phoneNumber &&
                                <a href={"tel:" + phoneNumber}>
                                  Contact: {phoneNumber}
                                </a>
                              }
                              <p className="error-message">error code: property_timezone_not_found</p>
                            </div>
                          </div>
                          <div className="app-button-color confirm" onClick={this.handleClosePlanning}>
                            {Translation().shop.informations.timezone_not_found.close}
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { isHostingPlansOpen &&
          <div className="app-overlay overlay-hosting-plans">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.handleCloseHostingPlans} />
                  <h4 className="app-modal-title">{Translation().account.orders.hosting_plans.modal.title}</h4>
                  <p className="app-modal-desc">
                    {Translation().account.orders.hosting_plans.modal.description}
                  </p>
                  <div className="app-modal-content">
                    <HostingPlans
                      orderId={orderId}
                      productId={product.id}
                      userType={userType}
                      onPostSuccess={this.handleSuccessHostingPlans}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="details">
          <div className="planning">
            { product.start && product.start ?
              (
                <span className="detail">
                  <span className="detail-icon icon-calendrier" />
                  { productStartUpdated ? dateInTz(productStartUpdated, 'localized-date', timezoneCode) : dateInTz(product.start, 'localized-date', timezoneCode) }
                </span>
              ) : ''
            }
            { product.start && product.start ?
              (
                <span className="detail">
                  <span className="detail-icon icon-horaire" />
                  { productStartUpdated ? dateInTz(productStartUpdated, 'HH:mm', timezoneCode) : dateInTz(product.start, 'HH:mm', timezoneCode) }
                </span>
              ) : ''
            }
            { product && product.duration ?
              (
                <span className="detail">
                  <span className="detail-icon icon-duree" />
                  { convertMinsToHrsMins(product.duration, "h")}
                </span>
              ) : ""
            }
            { userType && userType === "companies" && orderUser && orderUser.first_name && orderUser.last_name ?
              (
                <span className="detail">
                  <span className="detail-icon icon-connecte" />
                  {orderUser.first_name + " " + orderUser.last_name}
                </span>
              ) : ""
            }
            { orderId &&
              <span className="detail">
                <span className="detail-icon icon-panier" />
                {Translation().account.orders.order_number} { orderId } { orderRef ? " / " + orderRef : false }
              </span>
            }
            { (productExpireUpdated || product.expire) && !isProductExpired && !product.cancelled &&
              <span className="detail">
                <span className="detail-icon icon-rea01" />
                {Translation().account.orders.hosting_plans.expire_label} { dateInTz(productExpireUpdated || product.expire, 'localized-date') }
              </span>
            }
            { isProductExpired && !product.cancelled &&
              <span className="detail">
                <span className="detail-icon icon-rea01" />
                {Translation().account.orders.hosting_plans.expired_label} { dateInTz(product.expire, 'localized-date') }
              </span>
            }
          </div>
          <div className="options">
            { product.options && product.options.map((option, index) => {
              return (
                <div key={index} className="option">
                  <span className="name">{Translation().account.orders.option} : </span> { option.name }
                </div>
              );
            }) }
          </div>
          <div className="product-actions">
            { product.reschedulable && !productCancelled &&
              <span className={"product-action update-start " + (isPlanningModalOpen ? "active" : "")} onClick={this.handleOpenPlanning}>
                <span className="detail-icon icon-calendrier" />
                <span className="detail-text">{Translation().account.orders.update_start.update_btn}</span>
              </span>
            }
            { !productCancelled && !product.cancelled && product.cancellable &&
              <span className={"product-action cancel-product " + (isCancelOpen ? "active" : "")} onClick={this.toggleCancelProduct}>
                <span className="detail-icon icon-fermer" />
                <span className="detail-text">{Translation().account.orders.cancel_product.cancel_btn}</span>
              </span>
            }
            { showButtonExpired &&
              <span className={"product-action hosting-button" + (isHostingPlansOpen ? "active" : "")} onClick={this.handleOpenHostingPlans}>
                <span className="detail-icon icon-miseajour" />
                <span className="detail-text">{Translation().account.orders.hosting_plans.button}</span>
              </span>
            }
            {/* <span className="product-action show-support">
              <span className="detail-icon help-icon">?</span>
              <span className="detail-text">Aide</span>
            </span> */}
          </div>
        </div>
        <div>
          { product.downloadable && isProductMediasLoading &&
            <div className="prd-medias-ldr">
              <div className="loader-api" />
            </div>
          }
          <Col xs={12} md={12}>
            { productMedias && productMedias.length ? productMedias.map((productMedia, index) => {
              /**
               * Check of all media groups and medias types if the media groups need a downloader
               */
              let needDownloader = false;

              if (productMedia.media_types && productMedia.media_types.length) {
                for (let i = 0; i < productMedia.media_types.length; i++) {
                  if (productMedia.media_types[i].type && productMedia.media_types[i].type === "iframe") {
                    if (productMedia.media_types[i].medias && productMedia.media_types[i].medias.length) {
                      for (let j = 0; j < productMedia.media_types[i].medias.length; j++) {
                        if (productMedia.media_types[i].medias[j].thumbnail_url) {
                          needDownloader = true;
                          break;
                        }
                        else if (productMedia.media_types[i].medias[j].extension) {
                          needDownloader = true;
                          break;
                        }
                      }
                    }
                  }
                  else if (productMedia.media_types[i].type && productMedia.media_types[i].type !== "iframe" && productMedia.media_types[i].type !== "form") {
                    if (productMedia.media_types[i].medias && productMedia.media_types[i].medias.length) {
                      needDownloader = true;
                    }
                  }
                }
              }

              return (
                <OrderProductMediaGroup
                  isCompanyOrder={isCompanyOrder}
                  needDownloader={needDownloader}
                  product={product}
                  userType={userType}
                  orderId={orderId}
                  productMedia={productMedia}
                  key={index}
                />
              )
            })
            : !isProductMediasLoading
              ? (
                <span>
                  <p className="no-media" style={{marginLeft: 0}}>{Translation().account.orders.wait_appointement}</p>
                  <div className="product-separator">
                    <div className="first-sep" />
                    <div className="second-sep" />
                  </div>
                </span>
              )
              : false
            }
          </Col>
          <Clearfix/>
        </div>
        <Snackbar
          messageText={snackbarMessage}
          statusClass={snackbarStatus}
          open={isSnackbarOpen}
          autoHideDuration={autoHideDuration}
          closeFunction={this.closeSnackbar}
        />
      </div>
    );
  }
}

export default OrderProduct;
