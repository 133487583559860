import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import { Toggle } from 'redux-form-material-ui';

import store from '../../../../redux/store';
import { addThreeDots } from '../../../../helpers/HelperFunctions';
import { getOptionsSelected } from '../../../../helpers/ShopFunctions';
import { setCartRequestOptions, unsetCartRequestOptions } from '../../../../helpers/CartFunctions';

import Translation from '../../../../translation/Translation';

export const OptionNotAvailable = () => {
  return (
    <p>{Translation().shop.services.no_option}</p>
  )
}

export class OptionAvailable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDescriptionOpen: false,
      isToggled: false
    }

    this.toggleDescription = this.toggleDescription.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  componentDidMount() {
    const optionsSelected = getOptionsSelected();
    /**
     * Check if options are selected then auto set their state
     */
    for (let optionSelected in optionsSelected) {
      if (optionSelected.replace('option-', '') === this.props.object.id.toString()) {
        this.setState({ isToggled: true });
      }
    }
  }

  selectOption(event) {
    event.preventDefault();

    this.setState({ isToggled: !this.state.isToggled });

    /**
     * If switch toggled delete redux field
     * If not set it to true
     */

    if(this.state.isToggled) {
      store.dispatch(change('shop', 'options.option-' + this.props.object.id, ''));
    }
    else {
      store.dispatch(change('shop', 'options.option-' + this.props.object.id, true));
    }

    /**
     * Get the current options selected
     */
    const optionsSelected = getOptionsSelected();
    if (optionsSelected) {
      /**
       * Set options selected to redux store
       */
      setCartRequestOptions(optionsSelected);
    }
    else {
      unsetCartRequestOptions();
    }
  }

  toggleDescription(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isDescriptionOpen: !this.state.isDescriptionOpen });
  }

  render() {
    const option = this.props.object;
    const index = this.props.index + 1;

    const { isToggled, isDescriptionOpen} = this.state;

    /**
     * Toggle switch style when toggle is [off]
     */
    const initStyle = {
      backgroundImage: 'url("/assets/images/commons/unchecked-switch.png")',
      backgroundPosition: 'center center',
      backgroundSize: '60%',
      backgroundRepeat: 'no-repeat',
      zIndex: 99
    };

    /**
     * Toggle switch style when toggle is [on]
     */
    const switchedStyle = {
      backgroundImage: 'url("/assets/images/commons/checked-switch.png")',
      backgroundPosition: 'center center',
      backgroundSize: '60%',
      backgroundRepeat: 'no-repeat',
      zIndex:99
    };

    return (
      <div>
       <Col xs={12} sm={4} md={4} lg={4}>
          <div className={"option-box option-" + (option && option.id) + (isToggled ? " option-selected" : "")} onClick={this.selectOption}>
            <div className="more-info-service" onClick={this.toggleDescription}>
              <span className="icon-infos"/>
            </div>
            { isDescriptionOpen
              ? (
                  <div className="service-option">
                    <div className="inner-box">
                      <p className="option-description">{option && option.description && addThreeDots(option.description, 250)}</p>
                    </div>
                  </div>
                )
              : (
                  <div className="service-option">
                    <div className="inner-box left-box">
                      <p className="option-title">{option &&  addThreeDots(option.name, 80)}</p>
                      <span className="option-price">
                        {option && option.price_tax_excluded_formatted} <span className="type-of-tax">{Translation().shop.tax_excluded}</span>
                      </span>
                    </div>
                    <div className="inner-box right-box">
                      <Field
                        type="checkbox"
                        component={Toggle}
                        className="option-switch"
                        thumbStyle={initStyle}
                        thumbSwitchedStyle={switchedStyle}
                        name={"options.option-" + (option ? option.id : undefined)}
                        onChange={() => { return false;}}
                      />
                    </div>
                  </div>
                )
              }
          </div>
        </Col>
        { index % 3 === 0 ? <Clearfix/> : null }
      </div>
    );
  }
}
