import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import ShopBanner from '../../components/commons/banners/ShopBanner';

import Translation from '../../translation/Translation';

class Diagnostic extends Component {
  render() {
    return (
      <div className="app-service app-diagnostic">
        <div className="page-header-title">
          <div className="icon-diagnostic icon-page-head"></div>
          <h1>{Translation().pages.diagnostic.page_title.title}</h1>
          <h2>{Translation().pages.diagnostic.page_title.baseline}</h2>
        </div>
        <section className="intro-service">
          <Col xs={12} sm={12} md={6} className="cover-service"></Col>
          <Col xs={12} sm={12} md={6} className="details-service">
            <h3>{Translation().pages.diagnostic.description.title}</h3>
            <p>{Translation().pages.diagnostic.description.text}</p>
            <div style={{ height: '50px' }} />
            <a className="prices scroll-anch" href="#offers">
              {Translation().pages.diagnostic.description.buttons.pricing}
            </a>
            <a className="app-button-color" href="/shop">
              {Translation().pages.diagnostic.description.buttons.order}
            </a>
          </Col>
          <Clearfix />
        </section>
        <section className="process-service">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.diagnostic.guarantees.title}</h3>
              <Col xs={12} sm={5} md={4}>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.diagnostic.guarantees.texts[0]}</p>
                  <Clearfix />
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.diagnostic.guarantees.texts[1]}</p>
                  <Clearfix />
                </div>
              </Col>
              <Col xs={12} sm={2} md={3}>
                <img
                  src="/assets/images/commons/diagnostic-avatar.png"
                  alt="Plans"
                />
              </Col>
              <Col xs={12} sm={5} md={5}>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.diagnostic.guarantees.texts[2]}</p>
                  <Clearfix />
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.diagnostic.guarantees.texts[3]}</p>
                  <Clearfix />
                </div>
              </Col>
              <Clearfix />
            </div>
          </div>
        </section>
        <ShopBanner />
        <section className="prices-service" id="offers">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.diagnostic.pricing.title}</h3>
              <p className="baseline-title" />
              {/* <p className="baseline-title">{Translation().pages.diagnostic.pricing.baseline}</p> */}
              <Col xs={12} sm={4} md={4}>
                <div className="package">
                  <div className="package-header">
                    <h5>
                      {
                        Translation().pages.diagnostic.pricing.products.full
                          .title
                      }
                    </h5>
                    <h4>
                      {
                        Translation().pages.diagnostic.pricing.products.full
                          .name
                      }
                    </h4>
                  </div>
                  <div className="icon-diagnostic icon-price"></div>
                  <div className="package-features plans">
                    <p>
                      {
                        Translation().pages.diagnostic.pricing.products.full
                          .text
                      }
                    </p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">
                    {Translation().pages.diagnostic.pricing.button}
                  </a>
                </div>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <div className="package">
                  <div className="package-header">
                    <h5>
                      {
                        Translation().pages.diagnostic.pricing.products
                          .electricity.title
                      }
                    </h5>
                    <h4>
                      {
                        Translation().pages.diagnostic.pricing.products
                          .electricity.name
                      }
                    </h4>
                  </div>
                  <div className="icon-elec icon-price"></div>
                  <div className="package-features plans">
                    <p>
                      {
                        Translation().pages.diagnostic.pricing.products
                          .electricity.text
                      }
                    </p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">
                    {Translation().pages.diagnostic.pricing.button}
                  </a>
                </div>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <div className="package">
                  <div className="package-header">
                    <h5>
                      {
                        Translation().pages.diagnostic.pricing.products.gaz
                          .title
                      }
                    </h5>
                    <h4>
                      {Translation().pages.diagnostic.pricing.products.gaz.name}
                    </h4>
                  </div>
                  <div className="icon-gaz icon-price"></div>
                  <div className="package-features plans">
                    <p>
                      {Translation().pages.diagnostic.pricing.products.gaz.text}
                    </p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">
                    {Translation().pages.diagnostic.pricing.button}
                  </a>
                </div>
              </Col>
              <Clearfix />
              <div className="pagination-service">
                <div className="prev-service">
                  <a href="/virtual-tour">
                    <span className="icon-chevron_gauche icon-pagination" />
                    <p>
                      {
                        Translation().header.app_nav.products.virtual_visit
                          .title
                      }
                    </p>
                  </a>
                </div>
                <div className="next-service">
                  <a href="/drone-video">
                    <p>
                      {Translation().header.app_nav.products.video_drone.title}
                    </p>
                    <span className="icon-chevron_droite icon-pagination" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Diagnostic;
