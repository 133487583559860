import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import Translation from '../../../translation/Translation';

class ShopBanner extends Component {
  
  render () {

    return (
      <div className="shop-banner">
        <div className="container">
          <div className="row">
            <Col xs={12} sm={1} md={1} />
            <Col xs={12} sm={2} md={2}>
              <div className="icon-commande icon-banner"></div>
              <p>{Translation().pages.banners.shop.order}</p>
            </Col>
            <Col xs={12} sm={2} md={2}>
              <div className="icon-chevron_droite chevron desktop"></div>
              <div className="icon-chevron_bas chevron mobile"></div>
            </Col>
            <Col xs={12} sm={2} md={2}>
              <div className="icon-intervention icon-banner"></div>
              <p>{Translation().pages.banners.shop.intervention}</p>
            </Col>
            <Col xs={12} sm={2} md={2}>
              <div className="icon-chevron_droite chevron desktop"></div>
              <div className="icon-chevron_bas chevron mobile"></div>
            </Col>
            <Col xs={12} sm={2} md={2}>
              <div className="icon-reception icon-banner"></div>
              <p>{Translation().pages.banners.shop.reception}</p>
            </Col>
            <Col xs={12} sm={1} md={1} />
          </div>
        </div>
        <Clearfix/>
      </div>
    );
  }
}

export default ShopBanner; 