import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { change } from 'redux-form';
import { Toggle } from 'redux-form-material-ui';
import { Table } from 'react-bootstrap';
import $ from 'jquery';

import store from '../../../redux/store';
import { getInvoices, getInvoice, loadPaymentInvoiceIframe } from '../../../helpers/AccountFunctions';
import { getApiUrl } from '../../../helpers/ApiFunctions';
import { dateInTz, toUnix } from '../../../helpers/DateFunctions';

import Translation from '../../../translation/Translation';

class Invoice extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isItemLoading: false,
    };

    this.handleClickPay = this.handleClickPay.bind(this);
    this.handleReloadItem = this.handleReloadItem.bind(this);
  }

  handleReloadItem(invoiceId) {

    if (!invoiceId) return;

    const { isCompanyToggled, onReloadItem } = this.props;

    this.setState({ isItemLoading: true });

    getInvoice(
      isCompanyToggled,
      invoiceId,
      (success) => {
        this.setState({ isItemLoading: false });
        onReloadItem(success);
      },
      () => {
        this.setState({ isItemLoading: false });
      }
    );
  }

  handleClickPay() {
    const {
      invoice,
      isCompanyToggled,
      openPaymentIframe,
      onPaySuccess,
    } = this.props;

    if (invoice && invoice.id) {
      openPaymentIframe(
        invoice,
        () => {
          loadPaymentInvoiceIframe(
            isCompanyToggled,
            invoice.id,
            () => {
              onPaySuccess();
              this.handleReloadItem(invoice.id);
            }
          );
        }
      );
    }
  }

  render() {

    const {
      invoice,
      isCompanyToggled,
    } = this.props;

    const { isItemLoading } = this.state;

    const downloadLink = getApiUrl((isCompanyToggled ? "companies" : "users") + "/self/invoices/" + invoice.id + "/download", { mode:"attachment", v: toUnix()});
    const streamLink = getApiUrl((isCompanyToggled ? "companies" : "users") + "/self/invoices/" + invoice.id + "/download", { mode:"inline", v: toUnix()});
  
    return (
      <tr>
        <td>{ invoice.number_formatted && invoice.number_formatted }</td>
        <td width="25%">{ invoice.date && dateInTz(invoice.date, 'localized-date') }</td>
        <td>{ invoice.total_tax_excluded_formatted && invoice.total_tax_excluded_formatted }</td>
        <td>{ invoice.total_formatted && invoice.total_formatted }</td>
        <td style={invoice.payment_status && invoice.payment_status.color ? {color: invoice.payment_status.color} : {}}>
          <img
            title={invoice.payment_status && invoice.payment_status.name  ? invoice.payment_status.name  : ""}
            src={invoice.payment_status.icon_url_dark} alt={invoice.payment_status && invoice.payment_status.name ? invoice.payment_status.name : "Status"}
            className="payment-status-img"
          />
        </td>
        { isItemLoading
          ? (
            <td className="td-download">
              <span
                className="loader-api"
                style={{
                  height: 20,
                  width: 20,
                  position: "relative",
                  display: "inline-block",
                  left: 0,
                  top: 0,
                  margin: 0
                }}
              />
            </td>
          )
          : (
            <td className="td-download">
              <a className="download-icon" href={downloadLink} target="_blank">
                <span className="icon-download" />
              </a>
              <a className="download-icon" href={streamLink} target="_blank">
                <span className="icon-detail" />
              </a>
              { invoice.payment_status && invoice.payment_status.shortcode && invoice.payment_status.shortcode === "payment_failed" && 
                <span className="download-icon" onClick={this.handleClickPay}>
                  <span className="icon-CB" />
                </span>
              }
            </td>
          )
        }
      </tr>
    );
  }
}

class InvoicesList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCompanyToggled: this.props.isUserCompanyAdmin,
      isInvoicesLoading: false,
      isDownloadInProgress: false,
      isPayInvoiceOpen: false,
      invoiceSelected: null,
      scrollBuffer: 1,
      invoices: [],
      paging: null,
    };

    this.loadInvoicesList = this.loadInvoicesList.bind(this);
    this.handleCompanyToggle = this.handleCompanyToggle.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleClosePayInvoice = this.handleClosePayInvoice.bind(this);
    this.handleOpenPaymentIframe = this.handleOpenPaymentIframe.bind(this);
    this.handlePaySuccess = this.handlePaySuccess.bind(this);
    this.handleReloadItem = this.handleReloadItem.bind(this);
  }

  componentDidMount() {
    this.loadInvoicesList(this.props.isUserCompanyAdmin, null);

    const handleScrollListener = this.handleScroll;
    window.addEventListener('scroll', handleScrollListener);
  }

  handleCompanyToggle(isCompanyToggled) {

    if (isCompanyToggled === this.state.isCompanyToggled)
      return;

    store.dispatch(change('editInvoiceListAccount', 'companyToggle', isCompanyToggled ? true : ''));

    this.setState({
      isCompanyToggled: isCompanyToggled ? true : false,
      paging: null,
      invoices: [],
      scrollBuffer: 1
    });

    this.loadInvoicesList(isCompanyToggled ? true : false, null);
  }

  loadInvoicesList(isCompany, paging) {

    if (!paging || !paging.next) {
      this.setState({
        invoices: [],
        paging: null,
        isInvoicesLoading: true
      });
    }
    else {
      this.setState({ isInvoicesLoading: true });
    }

    getInvoices(
      isCompany,
      paging && paging.next ? paging.next : null,
      {},
      (successData, paging) => {
        /**
         * Concat the response to the current invoice array if invoices already exists
         * If not pass the first api reponse data
         */
        const invoices =  this.state.invoices ? this.state.invoices.concat(successData) : successData;

        this.setState({
          invoices,
          paging: !paging || !paging.next ? null : paging,
          isInvoicesLoading: false,
          scrollBuffer: 1,
        });
      },
      () => {
        this.setState({
          isInvoicesLoading: false,
          scrollBuffer: 1,
        });
      }
    );
  }
  
  handleScroll() {

    const {
      scrollBuffer,
      isCompanyToggled,
      paging,
      invoiceSelected
    } = this.state;

    if (!invoiceSelected && scrollBuffer === 1) {
      if (($(window).scrollTop() + $(window).height()) + ($('.app-footer').height()) > $(document).height()) {
        if (paging && paging.next) {
          this.loadInvoicesList(isCompanyToggled, paging);
          this.setState({ scrollBuffer: 2 });
        }
      }
    }
  }

  handleOpenPaymentIframe(selected, callback) {
    if (!selected) return;
  
    this.setState({
      isPayInvoiceOpen: true,
      invoiceSelected: selected
    });

    if (callback) {
      callback();
    }
  }

  handleClosePayInvoice() {
    this.setState({
      isPayInvoiceOpen: false,
      invoiceSelected: false
    });
  }

  handlePaySuccess(callback) {
    this.handleClosePayInvoice();

    if (callback) {
      callback();
    }
  }

  handleReloadItem(item) {
    const { invoices } = this.state;

    if (!item || !item.id || invoices.length <= 0) return;

    let invoicesInState = invoices;

    for (let i = 0; i < invoicesInState.length; i++) {
      if (invoicesInState[i] && (invoicesInState[i].id === item.id)) {
        invoicesInState[i] = item;
        break;
      }
    }

    this.setState({ invoices: invoicesInState });
  }

  render() {

    const {
      isCompanyToggled,
      isInvoicesLoading,
      isPayInvoiceOpen,
      invoiceSelected,
      invoices,
    } = this.state;

    const {
      isUserCompanyAdmin,
    } = this.props;

    /**
     * Toggle switch style
     */
    const toggleStyle = {
      backgroundColor: isCompanyToggled ? "#1a2123" : "#FFD700",
      zIndex: 1
    };

    return (
      <div className="invoices-list">
        { isUserCompanyAdmin &&
          <div className="select-user-type list">
            <span
              className={"text-ut " + (isCompanyToggled ? "" : "active")}
              disabled={isInvoicesLoading}
              onClick={() => {
                this.handleCompanyToggle(false)
              }}>
                {Translation().account.invoices.company_toggle.personal}
            </span>
            <Field
              type="checkbox"
              component={Toggle}
              className="toggle-ut"
              disabled={isInvoicesLoading}
              thumbStyle={toggleStyle}
              thumbSwitchedStyle={toggleStyle}
              name="companyToggle"
              onChange={(event, value) => {
                this.handleCompanyToggle(value);
              }}
            />
            <span
              className={"text-ut right " + (isCompanyToggled ? "active" : "")}
              disabled={isInvoicesLoading}
              onClick={() => {
                this.handleCompanyToggle(true)
              }}>
                {Translation().account.invoices.company_toggle.pro}
            </span>
          </div>
        }
        { invoices && invoices.length > 0
          ? (
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>{Translation().account.invoices.tables.invoices.number}</th>
                    <th width="25%">{Translation().account.invoices.tables.invoices.date}</th>
                    <th>{Translation().account.invoices.tables.invoices.tax_excluded}</th>
                    <th>{Translation().account.invoices.tables.invoices.tax_included}</th>
                    <th>{Translation().account.invoices.tables.invoices.status}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { invoices.map((invoice, index) => {
                      return (
                        <Invoice
                          key={index}
                          invoice={invoice}
                          isCompanyToggled={isCompanyToggled}
                          openPaymentIframe={this.handleOpenPaymentIframe}
                          onPaySuccess={this.handlePaySuccess}
                          onReloadItem={this.handleReloadItem}
                        />
                      );
                    })
                  }
                </tbody>
              </Table>
            )
          : !isInvoicesLoading && <p className="no-invoice">{Translation().account.invoices.tables.invoices.no_invoice}</p>
        }
        { isInvoicesLoading &&
          <div className="overlay-linv">
            <div className="loader-api" />
          </div>
        }
        <div className="app-overlay overlay-pay-invoice" style={{display: isPayInvoiceOpen && invoiceSelected ? "block" : "none"}}>
          <div className="container">
            <div className="row">
              <div className="app-modal">
                <div className="icon-fermer close-modal" onClick={this.handleClosePayInvoice} />
                <h4 className="app-modal-title">{Translation().account.invoices.tables.invoices.pay_invoices.title}{invoiceSelected && invoiceSelected.number_formatted ? invoiceSelected.number_formatted : false}</h4>
                <div className="app-modal-content">
                  <div id="frame-payment-invoice-loader" className="loader-api" />
                  <div id="payment-invoice-frame-container" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    const handleScrollListener = this.handleScroll;
    window.removeEventListener('scroll', handleScrollListener);
  }
}

export default reduxForm({
  form: 'editInvoiceListAccount',
})(InvoicesList);
