import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import { parseUrl } from '../../../helpers/HelperFunctions';
import {
  decineOrderDelegate,
  getOrderDelegate,
} from '../../../helpers/AccountFunctions';
import Order from '../../../components/commons/delegate/Order';
import Translation from '../../../translation/Translation';

class DeclineDelegate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined, // string or undefined
      success: false, // boolean,
      loading: false, // boolean,
      id: null, // integer,
      order: null, // the order to delegate
      order_loading: false, // boolean
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    const urlQueryString = parseUrl(window.location);
    const query = urlQueryString.query;

    // Check window location for get all delegate params required for the operation.

    if (query) {
      if (!query.id) {
        this.setState({ error: 'Error url params: no id found.' });
      }

      if (!query.delegate_token) {
        this.setState({
          error: 'Error url params: no delegate_token found.',
        });
      }

      /**
       * Get the order to delegate for check status and display infos
       */
      if (query.id && query.delegate_token) {
        this.setState({
          order_loading: true,
          order: null,
        });

        getOrderDelegate(
          query.id,
          { delegate_token: query.delegate_token },
          (order) => {
            this.setState({
              order_loading: false,
              order,
            });

            if (order && order.delegate_status === true) {
              this.setState({
                error: Translation().pages.delegate.errors[0],
              });
            } else if (order && order.delegate_status === false) {
              this.setState({
                error: Translation().pages.delegate.errors[1],
              });
            }
          },
          (error) => {
            const errorMessage =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.detail
                ? error.response.data.detail
                : Translation().pages.delegate.errors[2];

            this.setState({
              error: errorMessage,
              order_loading: false,
            });
          }
        );
      }
    } else {
      this.setState({
        error: 'Error url params: no query found.',
      });
    }
  }

  handleClick() {
    const urlQueryString = parseUrl(window.location);
    const query = urlQueryString.query;

    if (query && query.id && query.delegate_token) {
      this.setState({
        success: false,
        error: undefined,
        loading: true,
      });

      decineOrderDelegate(
        query.id,
        { delegate_token: query.delegate_token },
        () => {
          this.setState({
            success: true,
            error: undefined,
            loading: false,
          });
        },
        (error) => {
          const errorMessage =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.detail
              ? error.response.data.detail
              : Translation().pages.delegate.decline.errors[2];

          this.setState({
            success: false,
            error: errorMessage,
            loading: false,
          });
        }
      );
    }
  }

  render() {
    const { error, loading, success, order, order_loading } = this.state;

    return (
      <div className="payment-delegate decline-delegate">
        <div className="container">
          <div className="page-header-title">
            <h1>{Translation().pages.delegate.decline.title}</h1>
          </div>
        </div>
        {error ? (
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Alert bsStyle="danger" className="delegate-alert">
                    <b>
                      <span
                        className="icon-warning"
                        style={{ marginRight: 4 }}
                      />
                      {Translation().pages.delegate.error}
                    </b>
                    <p>{error}</p>
                  </Alert>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content">
            {success ? (
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Alert bsStyle="success" className="delegate-alert">
                      <b>
                        <span
                          className="icon-check"
                          style={{ marginRight: 8 }}
                        />
                        {Translation().pages.delegate.decline.success.title}
                      </b>
                      <p>{Translation().pages.delegate.decline.success.text}</p>
                    </Alert>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                {order_loading ? (
                  <div className="loader-wrapper">
                    <div
                      className="loader-api"
                      style={{ display: 'inline-block' }}
                    />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="decline-delegate">
                        {order &&
                          order.user &&
                          order.user.first_name &&
                          order.user.last_name &&
                          order.delegate_amount_formatted && (
                            <p>
                              {Translation().pages.delegate.decline.texts[0]}{' '}
                              <b>
                                {order.user.first_name} {order.user.last_name}
                              </b>{' '}
                              {Translation().pages.delegate.decline.texts[1]}{' '}
                              <b>{order.delegate_amount_formatted}</b>{' '}
                              {
                                Translation().pages.delegate.decline
                                  .tax_included
                              }
                            </p>
                          )}
                        <div className="text-center button-wrapper">
                          <button
                            className="app-button-color"
                            type="submit"
                            disabled={loading}
                            aria-label={
                              Translation().pages.delegate.decline.submit
                            }
                            onClick={this.handleClick}
                          >
                            {loading && (
                              <span
                                className="loader-api"
                                style={{ display: 'inline-block' }}
                              />
                            )}
                            <span style={{ opacity: loading ? 0 : 1 }}>
                              {Translation().pages.delegate.decline.submit}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      {order && <Order order={order} />}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DeclineDelegate;
