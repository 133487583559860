import React, { Component } from 'react';
import { Field, change, reduxForm, stopSubmit } from 'redux-form';
import { Col, Clearfix } from 'react-bootstrap';
import Checkbox from 'material-ui/Checkbox';
import Dialog from 'material-ui/Dialog';

import {
  setInvoiceUserAddress,
  getUserPaymentMethod,
  loadPaymentMethodIframe,
  getInvoiceUserAddresses,
} from '../../../../../helpers/UserFunctions';
import { objectMapToArray } from '../../../../../helpers/HelperFunctions';
import renderFieldLabel from '../../../../../components/commons/fields/renderFieldLabel';
import GoogleAddressStringField from '../../../../../components/commons/fields/GoogleAddressStringField';
import Snackbar from '../../../../../components/commons/snackbar/Snackbar';
import SelectField from '../../../../../components/commons/fields/SelectField';
import TermsOfUse from '../../../../../components/commons/termsOfUse/TermsOfUse';
import Translation from '../../../../../translation/Translation';
import store from '../../../../../redux/store';
import validate from './validate';

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // addresses
      addresses: null,
      addressValue: null,
      addressName: undefined,
      isAddressesLoading: false,
      isNewAddressLoading: false,

      // payments methods
      paymentMethods: null,
      paymentMethodValue: null,
      isPaymentMethodsLoading: false,

      // Snackbar
      autoHideDuration: 4000,
      isSnackbarOpen: false,
      snackbarMessage: '',
      snackbarStatus: '',

      // submit
      isTermsAccepted: false,
      isTermsOpen: false,
    };

    this.addInvoiceAddress = this.addInvoiceAddress.bind(this);
    this.closeInvoiceAddressModal = this.closeInvoiceAddressModal.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.handleCheckTerms = this.handleCheckTerms.bind(this);
    this.handleCloseTerms = this.handleCloseTerms.bind(this);
    this.handleOpenTerms = this.handleOpenTerms.bind(this);
    this.openInvoiceAddressModal = this.openInvoiceAddressModal.bind(this);
    this.handleOpenPaymentMethodModal =
      this.handleOpenPaymentMethodModal.bind(this);
    this.handleClosePaymentMethodModal =
      this.handleClosePaymentMethodModal.bind(this);
  }

  componentWillMount() {
    this.setState({
      error: null,
      isAddressesLoading: true,
      isPaymentMethodsLoading: true,
    });

    getInvoiceUserAddresses(
      (success) => {
        let addressesArray = [];

        objectMapToArray(success, (index, address) => {
          let addressObject = {};

          addressObject['value'] = address.id;
          addressObject['label'] =
            address.name +
            ' (' +
            (address.street_number ? address.street_number : '') +
            ' ' +
            (address.street ? address.street : '') +
            ' ' +
            (address.zip_code ? address.zip_code : ' ') +
            ' ' +
            (address.city ? address.city : '') +
            ')';
          addressesArray.push(addressObject);
        });

        this.setState({
          addresses: addressesArray,
          isAddressesLoading: false,
        });

        if (addressesArray[0] && addressesArray[0].value) {
          store.dispatch(
            change('payment_delegate', 'address', addressesArray[0].value)
          );
        }
      },
      (error) => {
        const errorMessage =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : Translation().account.orders.hosting_plans.callbacks_get.error;

        this.setState({
          addresses: null,
          isAddressesLoading: false,
          isSnackbarOpen: true,
          snackbarMessage: errorMessage,
          snackbarStatus: 'error',
        });
      }
    );

    getUserPaymentMethod(
      'usable',
      null,
      false,
      { way: 'pay' },
      (success) => {
        let paymentMethodsArray = [];

        for (let i = 0; i < success.length; i++) {
          let paymentMethods = {};

          paymentMethods['value'] = success[i].id;
          paymentMethods['label'] =
            (success[i].type ? success[i].type : '') + ' - ' + success[i].name;

          paymentMethodsArray.push(paymentMethods);
        }

        this.setState({
          paymentMethods: paymentMethodsArray,
          isPaymentMethodsLoading: false,
        });

        if (paymentMethodsArray[0] && paymentMethodsArray[0].value) {
          store.dispatch(
            change(
              'payment_delegate',
              'payment_method',
              paymentMethodsArray[0].value
            )
          );
        }
      },
      (error) => {
        const errorMessage =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : Translation().account.orders.hosting_plans.callbacks_get.error;

        this.setState({
          paymentMethods: null,
          isPaymentMethodsLoading: false,
          isSnackbarOpen: true,
          snackbarMessage: errorMessage,
          snackbarStatus: 'error',
        });
      }
    );
  }

  openInvoiceAddressModal() {
    this.setState(
      {
        isAddressModalOpen: true,
        addressValue: undefined,
        addressName: undefined,
      },
      () => {
        /**
         * Clear inputs values
         */
        const inputNewAddress = document.getElementById('new-invoice-address');
        store.dispatch(change('payment_delegate', 'address_name', ''));
        store.dispatch(change('payment_delegate', 'address_value', ''));

        if (inputNewAddress) {
          inputNewAddress.focus();
        }
      }
    );
  }

  closeInvoiceAddressModal() {
    this.setState({ isAddressModalOpen: false });
  }

  addInvoiceAddress() {
    const errorAddress = document.getElementById('address-error-output');
    const { address_name, address_value } = this.state;

    const fieldsErrors = [
      'street_number',
      'street',
      'zip_code',
      'city',
      'country_isocode',
      'state_isocode',
      'latitude',
      'longitude',
    ];

    if (!address_value || !address_name) {
      return;
    }

    this.setState({ isNewAddressLoading: true });

    setInvoiceUserAddress(
      false,
      address_name,
      address_value,
      (data) => {
        /**
         * Get all user invoice addresses
         */
        getInvoiceUserAddresses(
          (addresses) => {
            let addressesArray = [];

            objectMapToArray(addresses, (index, address) => {
              let addressObject = {};

              addressObject['value'] = address.id;
              addressObject['label'] =
                address.name +
                ' (' +
                (address.street_number ? address.street_number : '') +
                ' ' +
                (address.street ? address.street : '') +
                ' ' +
                (address.zip_code ? address.zip_code : ' ') +
                ' ' +
                (address.city ? address.city : '') +
                ')';
              addressesArray.push(addressObject);
            });

            this.setState({
              addresses: addressesArray,
              addressValue: data.id ? data.id : null,
              isAddressesLoading: false,
              isNewAddressLoading: false,
            });

            if (addressesArray[0] && addressesArray[0].value) {
              store.dispatch(
                change('payment_delegate', 'address', addressesArray[0].value)
              );
            }

            this.closeInvoiceAddressModal();
          },
          (error) => {
            const errorMessage =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.detail
                ? error.response.data.detail
                : Translation().account.orders.hosting_plans.callbacks_get
                    .error;

            this.setState({
              addresses: null,
              isAddressesLoading: false,
              isNewAddressLoading: false,
              isSnackbarOpen: true,
              snackbarMessage: errorMessage,
              snackbarStatus: 'error',
            });
          }
        );
      },
      (error) => {
        if (
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          const validationErrors = {};
          const errorsArray = error.response.data.errors;

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath === 'address') {
              validationErrors['address_value'] = errorsArray[i].message;
            } else if (fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0) {
              validationErrors['address_value'] =
                errorsArray[i].propertyPath + ': ' + errorsArray[i].message;
            } else if (errorsArray[i].propertyPath === 'name') {
              validationErrors['address_name'] = errorsArray[i].message;
            } else
              validationErrors[errorsArray[i].propertyPath] =
                errorsArray[i].message;
          }

          store.dispatch(stopSubmit('payment_delegate', validationErrors));
        } else {
          if (errorAddress) {
            errorAddress.innerHTML =
              error.response &&
              error.response.data &&
              error.response.data.detail
                ? error.response.data.detail
                : 'An error occurred, please retry later.';
          }
        }

        this.setState({
          isNewAddressLoading: false,
        });
      }
    );
  }

  handleOpenPaymentMethodModal() {
    const paymentMethodModal = document.getElementsByClassName(
      'overlay-payment-method'
    )[0];

    if (paymentMethodModal) {
      paymentMethodModal.style.display = 'block';
    }

    loadPaymentMethodIframe(false, () => {
      this.setState({
        isPaymentMethodsLoading: true,
      });

      getUserPaymentMethod(
        'usable',
        null,
        null,
        { way: 'pay' },
        (success) => {
          if (success && success.length > 0) {
            let paymentMethodsArray = [];

            for (let i = 0; i < success.length; i++) {
              let paymentMethods = {};

              paymentMethods['value'] = success[i].id;
              paymentMethods['label'] =
                (success[i].type ? success[i].type : '') +
                ' - ' +
                success[i].name;

              paymentMethodsArray.push(paymentMethods);
            }

            this.setState({
              paymentMethods: paymentMethodsArray,
              isPaymentMethodsLoading: false,
            });

            if (paymentMethodsArray[0] && paymentMethodsArray[0].value) {
              store.dispatch(
                change(
                  'payment_delegate',
                  'payment_method',
                  paymentMethodsArray[0].value
                )
              );
            }
          }
        },
        () => {
          this.setState({
            isPaymentMethodsLoading: false,
          });
        }
      );

      this.handleClosePaymentMethodModal();
    });
  }

  handleClosePaymentMethodModal() {
    const paymentMethodModal = document.getElementsByClassName(
      'overlay-payment-method'
    )[0];

    if (paymentMethodModal) {
      paymentMethodModal.style.display = 'none';
    }
  }

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  }

  handleOpenTerms() {
    this.setState({ isTermsOpen: true });
  }

  handleCloseTerms() {
    this.setState({ isTermsOpen: false });
  }

  handleCheckTerms(event) {
    this.setState({ isTermsAccepted: event.target.checked });
  }

  render() {
    const { loading, handleSubmit, order } = this.props;
    const {
      addresses,
      autoHideDuration,
      isAddressModalOpen,
      isNewAddressLoading,
      isAddressesLoading,
      isPaymentMethodsLoading,
      isTermsAccepted,
      isTermsOpen,
      paymentMethods,
      isSnackbarOpen,
      snackbarMessage,
      snackbarStatus,
    } = this.state;

    const isLoading = isAddressesLoading || isPaymentMethodsLoading || loading;

    return (
      <form onSubmit={handleSubmit} className="delegate-methods">
        {isAddressModalOpen && (
          <div className="overlay-invoice-address">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div
                    className="icon-fermer close-modal"
                    onClick={this.closeInvoiceAddressModal}
                  />
                  <h4 className="app-modal-title">
                    {Translation().shop.payment.add_invoice_address}
                  </h4>
                  <p className="app-modal-desc">
                    {Translation().shop.payment.complete_fields}
                  </p>
                  <div className="app-modal-content">
                    <Field
                      name="address_value"
                      type="text"
                      id="new-invoice-address"
                      label={
                        Translation().shop.step_fields.placeholders.address
                      }
                      className="app-basic-input"
                      component={GoogleAddressStringField}
                      onChange={(value, address) => {
                        this.setState({ address_value: address });
                      }}
                    />
                    <Field
                      name="address_name"
                      type="text"
                      placeholder={
                        Translation().shop.step_fields.placeholders.give_name
                      }
                      className="app-basic-input"
                      component={renderFieldLabel}
                      tabIndex="-1"
                      onChange={(event, value) => {
                        this.setState({ address_name: value });
                      }}
                    />
                    <div className="btn-wrapper">
                      <button
                        type="button"
                        disabled={isNewAddressLoading}
                        className="app-button-color"
                        onClick={this.addInvoiceAddress}
                      >
                        {isNewAddressLoading && (
                          <span
                            className="loader-api"
                            style={{ display: 'inline-block' }}
                          />
                        )}
                        <span style={{ opacity: isNewAddressLoading ? 0 : 1 }}>
                          {Translation().shop.step_buttons.add}
                        </span>
                      </button>
                      <p id="address-error-output" className="error-message" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="overlay-payment-method">
          <div className="container">
            <div className="row">
              <div className="app-modal">
                <div
                  className="icon-fermer close-modal"
                  onClick={this.handleClosePaymentMethodModal}
                />
                <h4 className="app-modal-title">
                  {Translation().shop.payment.add_payment_method}
                </h4>
                <p className="app-modal-desc">
                  {Translation().shop.payment.complete_fields}
                </p>
                <div className="app-modal-content">
                  <form>
                    <div className="payment-container">
                      <div id="frame-payment-loader" className="loader-api" />
                      <div id="payment-frame-container" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <Col lg={12} md={12} sm={12}>
            <h2>{Translation().pages.delegate.accept.confirm}</h2>
            {order &&
              order.user &&
              order.user.first_name &&
              order.user.last_name &&
              order.delegate_amount_formatted && (
                <p className="delegate-disclamer">
                  {Translation().pages.delegate.accept.texts[0]}{' '}
                  <b>
                    {order.user.first_name} {order.user.last_name}
                  </b>{' '}
                  {Translation().pages.delegate.accept.texts[1]}{' '}
                  <b>{order.delegate_amount_formatted}</b>{' '}
                  {Translation().pages.delegate.accept.tax_included}
                  {Translation().pages.delegate.accept.texts[2]}
                </p>
              )}
          </Col>
          <Clearfix />
          <Col lg={6} md={6} sm={12}>
            <div className="delegate-method">
              <h3>{Translation().shop.payment.invoice_address}: *</h3>
              {!isAddressesLoading && (
                <div
                  className={
                    'add-method ' +
                    (addresses && addresses.length > 0 ? '' : 'empty')
                  }
                  onClick={this.openInvoiceAddressModal}
                  role="button"
                  aria-label="create address"
                >
                  <span className="icon-plus add-icon" />
                </div>
              )}
              {isAddressesLoading ? (
                <div className="loader-api loader-api-center" />
              ) : (
                <div>
                  {addresses && addresses.length > 0 ? (
                    <Field
                      component={SelectField}
                      valueKey="value"
                      clearable
                      searchable={false}
                      disabled={loading}
                      id="address"
                      name="address"
                      options={addresses}
                      placeHolder={
                        Translation().shop.step_fields.placeholders.address
                      }
                      className={
                        'app-basic-input invoice-input select-inputs ' +
                        (addresses && addresses.length > 0
                          ? 'display-block'
                          : 'display-none')
                      }
                    />
                  ) : (
                    <p className="text-center">
                      {Translation().shop.step_fields.names.add_invoice_address}
                    </p>
                  )}
                </div>
              )}
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="delegate-method">
              <h3>{Translation().shop.payment.payment_method}: *</h3>
              {!isPaymentMethodsLoading && (
                <div
                  className={
                    'add-method ' +
                    (paymentMethods && paymentMethods.length > 0 ? '' : 'empty')
                  }
                  onClick={this.handleOpenPaymentMethodModal}
                  role="button"
                  aria-label="create payment method"
                >
                  <span className="icon-plus add-icon" />
                </div>
              )}
              {isPaymentMethodsLoading ? (
                <div className="loader-api loader-api-center" />
              ) : (
                <div>
                  {paymentMethods && paymentMethods.length > 0 ? (
                    <Field
                      component={SelectField}
                      valueKey="value"
                      onChange={this.handleChangePaymentMethods}
                      clearable
                      searchable={false}
                      disabled={loading}
                      id="payment-method"
                      name="payment_method"
                      options={paymentMethods}
                      placeHolder="CB N°"
                      className={
                        'app-basic-input payment-input select-inputs ' +
                        (paymentMethods && paymentMethods.length > 0
                          ? 'display-block'
                          : 'display-none')
                      }
                      required
                    />
                  ) : (
                    <p className="text-center">
                      {Translation().shop.step_fields.names.add_payment_method}
                    </p>
                  )}
                </div>
              )}
            </div>
          </Col>
          <Clearfix />
          <div className="accept-terms">
            <label htmlFor="terms-of-sales">
              <Checkbox
                name="terms_of_sales"
                id="terms-of-sales"
                style={{
                  width: '30px',
                  display: 'inline-block',
                }}
                required
                onCheck={this.handleCheckTerms}
              />
              <span>
                {Translation().shop.payment.term_of_sales.read}{' '}
                <a onClick={this.handleOpenTerms}>
                  {Translation().shop.payment.term_of_sales.tos}
                </a>{' '}
                {Translation().shop.payment.term_of_sales.agree} *
              </span>
            </label>
          </div>
        </div>
        <div className="submit-wrapper">
          <button
            className="app-button-color"
            type="submit"
            disabled={isLoading || !isTermsAccepted}
          >
            {loading && (
              <span
                className="loader-api"
                style={{ display: 'inline-block' }}
              />
            )}
            <span style={{ opacity: loading ? 0 : 1 }}>
              {Translation().pages.delegate.accept.submit}
            </span>
          </button>
        </div>
        <div className="text-left">
          <p className="required-field">
            <span>*</span> {Translation().shop.payment.required_fields}
          </p>
        </div>
        <Dialog
          modal={false}
          actions={[
            <div onClick={this.handleCloseTerms} className="btn-tos-modal">
              {Translation().shop.payment.modal.close
                ? Translation().shop.payment.modal.close
                : 'Close'}
            </div>,
          ]}
          className="term-use-modal"
          autoScrollBodyContent={true}
          onRequestClose={this.handleCloseTerms}
          open={isTermsOpen}
        >
          <TermsOfUse isDialog={true} />
        </Dialog>
        <Snackbar
          messageText={snackbarMessage}
          statusClass={snackbarStatus}
          open={isSnackbarOpen}
          autoHideDuration={autoHideDuration}
          closeFunction={this.closeSnackbar}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'payment_delegate',
  validate,
})(Payment);
