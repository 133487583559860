/**
 * MAIN APP FILE
 */

/**
 * REACT
 */
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

/**
 * VENDORS
 */
import bowser from 'bowser';
import BrowserSupported from './components/commons/browser/BrowserSupported';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import injectTapEventPlugin from 'react-tap-event-plugin';
import './helpers/JqueryFunctions';
import 'slick-carousel/slick/slick.min.js';

/**
 * HELPERS
 */
import { initializeApp } from './helpers/UserFunctions';

import CovidCharter from './pages/tmp/CovidCharter';

/**
 * PAGES
 */
import About from './pages/about/About';
import AcceptDelegate from './pages/delegate/accept/Accept';
import Contact from './pages/contact/Contact';
import DeclineDelegate from './pages/delegate/decline/Decline';
import Diagnostic from './pages/diagnostic/Diagnostic';
import Faq from './pages/faq/Faq';
import Gallery from './pages/gallery/Gallery';
import Home from './pages/home/Home';
import HomeStaging from './pages/home-staging/HomeStaging';
import HowItWork from './pages/howItWork/HowItWork';
import LegalNotice from './pages/legalNotice/LegalNotice';
import Login from './pages/login/Login';
import NotFound from './pages/notFound/NotFound';
import Plans from './pages/plans/Plans';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import ProPacks from './pages/packs/ProPacks';
import Recruitment from './pages/recruitment/Recruitment';
import Register from './pages/register/Register';
import RequestPassword from './pages/requestPassword/requestPassword';
import ResetPassword from './pages/resetPassword/resetPassword';
import ScanPacks from './pages/packs/ScanPacks';
import Shooting from './pages/shooting/Shooting';
import TermsOfUse from './pages/termsOfUse/TermsOfUse';
import VideosDrones from './pages/videosDrones/VideosDrones';
import VirtualVisit from './pages/virtualVisit/VirtualVisit';

/**
 * SHOP
 */
import Shop from './components/shop/Shop';
import CartMobile from './pages/cart/CartMobile';

/**
 * ACCOUNT
 */
import Profile from './components/account/profile/Profile';
import Invoices from './components/account/invoices/Invoices';
import OrdersContainer from './components/account/orders/OrdersContainer';
import Company from './components/account/company/Company';
import CompanyZone from './components/account/company/CompanyZone';

/**
 * COMMONS
 */
import Header from './components/commons/header/Header';
import Footer from './components/commons/footer/Footer';
// import Translation from './translation/Translation';

/**
 * REDUX
 */
import { subscribe } from 'redux-subscriber';

/**
 * IE Assign polyfill
 */
if (bowser.name === 'Internet Explorer') {
  if (!Object.assign) {
    Object.defineProperty(Object, 'assign', {
      enumerable: false,
      configurable: true,
      writable: true,
      value: function (target) {
        'use strict';
        if (target === undefined || target === null) {
          throw new TypeError('Cannot convert first argument to object');
        }

        var to = Object(target);
        for (var i = 1; i < arguments.length; i++) {
          var nextSource = arguments[i];
          if (nextSource === undefined || nextSource === null) {
            continue;
          }
          nextSource = Object(nextSource);

          var keysArray = Object.keys(Object(nextSource));
          for (
            var nextIndex = 0, len = keysArray.length;
            nextIndex < len;
            nextIndex++
          ) {
            var nextKey = keysArray[nextIndex];
            var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
            if (desc !== undefined && desc.enumerable) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
        return to;
      },
    });
  }
}

class ApiLoader extends Component {
  render() {
    return (
      <div className="loader-api-container">
        <div className="loader-api" />
      </div>
    );
  }
}

/**
 * MAIN APP ROUTING & MAIN APP COMPONENT
 */
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserLoggedIn: false,
      isUserLoadingDone: false,
      isCartProducts: false,
      isUserCompanyAdmin: false,
      geoSettings: null,
      shopReady: false,
    };

    this.subscribeGeoSet = undefined;
  }

  componentWillMount() {
    injectTapEventPlugin();

    /**
     * App datas initialization
     */
    initializeApp(() => {
      /**
       * Loader undisplaying (Get documentById don't working on IE <= 9)
       */
      if (bowser.name === 'Internet Explorer' && bowser.version <= 9) {
        return false;
      } else {
        var ele = document.getElementById('loader-bar-id');

        if (ele) {
          ele.classList.add('available');

          setTimeout(() => {
            ele.outerHTML = '';
          }, 1200);
        }
      }

      this.setState({ shopReady: true });
    });
  }

  componentDidMount() {
    // eslint-disable-next-line
    const subscribeUser = subscribe('user', (state) => {
      if (state.user) {
        this.setState({ isUserLoggedIn: true });

        if (state.user.company_admin && state.user.company) {
          this.setState({ isUserCompanyAdmin: true });
        }
      } else {
        this.setState({ isUserLoggedIn: false });
      }
    });

    // eslint-disable-next-line
    const subscribeUserLoading = subscribe('userLoading', (state) => {
      if (state.userLoading !== null)
        this.setState({ isUserLoadingDone: true });
      else this.setState({ isUserLoadingDone: false });
    });

    /**
     * Check if userCart exist with a product
     */
    // eslint-disable-next-line
    const subscribeUserCartProducts = subscribe(
      'userCart.products',
      (state) => {
        const userCart = state.userCart;

        if (userCart && userCart.products && userCart.products.length > 0) {
          this.setState({ isCartProducts: true });
        } else {
          this.setState({ isCartProducts: false });
        }
      }
    );

    this.subscribeGeoSet = subscribe('geoSettings', (state) => {
      if (state.geoSettings) {
        this.setState({
          geoSettings: state.geoSettings,
        });
      }
    });
  }

  render() {
    const {
      isUserLoggedIn,
      isUserCompanyAdmin,
      isCartProducts,
      isUserLoadingDone,
      geoSettings,
      shopReady,
    } = this.state;

    /**
     * Material Ui theme config
     */
    const muiTheme = getMuiTheme({
      palette: {
        primary1Color: '#1A2123',
        primary2Color: '#FFD700',
        textColor: '#1A2123',
        alternateTextColor: '#FFFFFF',
      },
      radioButton: {
        labelColor: '#929497',
        checkedColor: '#1A2123',
      },
      toggle: {
        thumbOffColor: '#929497',
        thumbOnColor: '#FFD700',
      },
      snackbar: {
        textColor: '#FFFFFF',
        backgroundColor: '#1A2123',
        actionColor: '#FFD700',
      },
    });

    /**
     * Component who needs to be logged
     */

    let componentProfile,
      componentInvoices,
      componentOrders,
      componentCompany = undefined;

    if (isUserLoadingDone && isUserLoggedIn && !isUserCompanyAdmin) {
      componentCompany = CompanyZone;
    } else if (isUserLoadingDone && isUserLoggedIn && isUserCompanyAdmin) {
      componentCompany = Company;
    } else if (!isUserLoadingDone && !isUserLoggedIn) {
      componentCompany = ApiLoader;
    } else if (isUserLoadingDone && !isUserLoggedIn) {
      componentCompany = Login;
    }

    if (isUserLoadingDone && isUserLoggedIn) {
      componentProfile = Profile;
      componentOrders = OrdersContainer;
      componentInvoices = Invoices;
    } else if (!isUserLoadingDone && !isUserLoggedIn) {
      componentProfile = ApiLoader;
      componentInvoices = ApiLoader;
      componentOrders = ApiLoader;
    } else if (isUserLoadingDone && !isUserLoggedIn) {
      componentProfile = Login;
      componentOrders = Login;
      componentInvoices = Login;
    }

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <Router>
          <div className="react-root-class">
            <BrowserSupported />
            <Header geoSettings={geoSettings} />
            {/* <div className="shop-unavailable">
              <a href="/covid-charter" rel="nofollow">
                <img
                  src="/assets/images/tmp/mask-protection-icon.png"
                  alt="Covid charter"
                  height="50"
                  width="50"
                  className="shop-unavailable-icon"
                />
                <span>{Translation().covid.title}</span>
              </a>
            </div> */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/photography" component={Shooting} />
              <Route path="/floorplan" component={Plans} />
              <Route path="/virtual-tour" component={VirtualVisit} />
              <Route path="/drone-video" component={VideosDrones} />
              <Route path="/certificates" component={Diagnostic} />
              <Route path="/pro-pack" component={ProPacks} />
              <Route path="/scan-pack" component={ScanPacks} />
              <Route path="/home-staging" component={HomeStaging} />
              <Route path="/how-it-works" component={HowItWork} />
              <Route path="/contact" component={Contact} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/legalNotice" component={LegalNotice} />
              <Route path="/faq" component={Faq} />
              <Route path="/termsOfUse" component={TermsOfUse} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/recruitment" component={Recruitment} />
              <Route path="/about" component={About} />
              <Route path="/resetPassword" component={ResetPassword} />
              <Route path="/requestPassword" component={RequestPassword} />
              <Route
                path="/login"
                component={isUserLoggedIn ? OrdersContainer : Login}
              />
              <Route path="/covid-charter" component={CovidCharter} />
              <Route
                path="/register"
                render={(props) =>
                  !isUserLoggedIn ? (
                    <Register {...props} geoSettings={geoSettings} />
                  ) : (
                    <OrdersContainer {...props} />
                  )
                }
              />
              <Route
                path="/shop"
                render={(props) => (
                  <Shop
                    {...props}
                    userStatus={isUserLoggedIn}
                    cartStatus={isCartProducts}
                    shopReady={shopReady}
                  />
                )}
              />
              <Route path="/cart" component={CartMobile} />
              <Route path="/profile" component={componentProfile} />
              <Route path="/invoices" component={componentInvoices} />
              <Route path="/orders/:id?" component={componentOrders} />
              <Route path="/company" component={componentCompany} />
              <Route path="/accept-delegate" component={AcceptDelegate} />
              <Route path="/decline-delegate" component={DeclineDelegate} />
              <Route path="*" component={NotFound} />
              <Redirect from="*" to="/not-found" />
            </Switch>
            <div id="scroll-to-top">
              <span className="icon-chevron_haut" />
            </div>
            <Footer />
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
