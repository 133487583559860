import currencyFormatter from 'currency-formatter';
import { unescape } from 'lodash';
import { getCurrency, getUnitSystem } from './UserFunctions';
import Config from '../config/Config';

export const buildDocumentTitle = (title) => {
  document.title = Config.app_name + ' | ' + title;
  return false;
};

export const getUnitSystemSymbol = () => {
  const unitSystem = getUnitSystem();

  if (!unitSystem) return;

  if (unitSystem === 'metric') return 'm²';
  else if (unitSystem === 'imperial_us') return 'ft²';
  else if (unitSystem === 'imperial') return 'ft²';
};

/**
 * Add three dots for long text
 */
export const addThreeDots = (string, limit) => {
  const dots = '...';

  if (!string || !limit) return;

  if (string.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
};

/**
 * Add zero before a number if he is < 10
 */
export const pad = (n) => {
  return n < 10 ? '0' + n : n;
};

/**
 * Function that take url for compose an object
 */
export const parseUrl = (url) => {
  if (!url) {
    return;
  }

  /**
   * Decode special char like &amp; to &
   */
  url = unescape(decodeURIComponent(url));

  const parts = {};
  let hasDomain = true;

  if (url.indexOf('://') < 0) {
    hasDomain = false;
  }

  parts.scheme = hasDomain ? url.split('://').shift() : '';
  parts.host = hasDomain ? url.split('://').pop().split('/').shift() : '';
  parts.route =
    hasDomain && url.indexOf('/') > 0
      ? url
          .split(parts.host + '/')
          .pop()
          .split('?')
          .shift()
      : url
          .split(parts.host + '/')
          .split('?')
          .shift();
  parts.queryString =
    url.indexOf('?') > 0 ? url.split('?').pop().split('#').shift() : '';
  parts.fragment = url.indexOf('#') > 0 ? url.split('#').pop() : '';

  let query_split = parts.queryString.split('&');
  parts.query = {};
  for (let i = 0; i < query_split.length; i++) {
    const eq = query_split[i].split('=');
    parts.query[eq[0]] = eq[1];
  }

  return parts;
};

/**
 * Function that take object url for compose an URL
 */
export const composeUrl = (parts) => {
  if (!parts) return;

  let queryEqs = [];
  for (let key in parts.query) {
    queryEqs.push(key + '=' + parts.query[key]);
  }

  const url =
    (parts.scheme ? parts.scheme + '://' : '') +
    (parts.host ? parts.host + '/' : '') +
    parts.route +
    (parts.query ? '?' + queryEqs.join('&') : '') +
    (parts.fragment ? '#' + parts.fragment : '');

  return url;
};

/**
 * Query object to query string
 */
export const stringifyQuery = (query, encodeUrl = true) => {
  if (!query || isObjEmpty(query)) return;

  let queryStringParts = [];

  for (let key in query) {
    const queryKey = key;
    let queryValue = '';

    if (typeof query[key] === ('object' || 'array'))
      queryValue = JSON.stringify(query[key]);
    else queryValue = query[key].toString();

    queryStringParts.push(
      (encodeUrl ? encodeURIComponent(queryKey) : queryKey) +
        '=' +
        (encodeUrl ? encodeURIComponent(queryValue) : queryValue)
    );
  }

  return queryStringParts.join('&');
};

/**
 * Hash a string
 */
export const generateHash = (string) => {
  let hash = 0;

  if (string.length === 0) {
    return hash;
  }
  for (var i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
};

/**
 * Generate a random password
 */
export const generateRandomPassword = () => {
  const randomPassword = '!' + Math.random().toString(36).slice(-8) + '@';
  return randomPassword;
};

/**
 * Count object properties
 */
export const countObjProps = (obj) => {
  if (!obj) {
    return;
  }

  let count = 0;

  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      count++;
    }
  }
  return count;
};

/**
 * Check is an object is empty
 */
export const isObjEmpty = (obj) => {
  if (obj) {
    return !countObjProps(obj);
  }
};

/**
 * Generate random number, used for session for exemple
 */
export const generateRandom = (min, max) => {
  if (min && max) {
    const randomNbr = Math.floor(Math.random() * (max - min) + min);
    return randomNbr;
  } else {
    return;
  }
};

/**
 * Get a country for return his timezone
 */
// export const countryToTimezone = (countryCode) => {
//
//   if (!countryCode)
//     return;
//
//   if (Config.country_timezones[countryCode]) {
//     return (Config.country_timezones[countryCode]);
//   }
// }

/**
 * Map an object as an array
 */
export const objectMapToArray = (object, callback) => {
  if (typeof callback !== 'function' || !object) {
    return;
  }

  let resultArray = [];
  let index = 0;

  for (let prop in object) {
    resultArray.push(callback(prop, object[prop], index, object));
    index++;
  }

  return resultArray;
};

/**
 * Check is a var is an integer
 */
export const isInteger = (value) => {
  const regExpInt = /^-?\d+\.?\d*$/;

  if (value.toString().match(regExpInt)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Check is a var is a float
 */
export const isFloat = (value) => {
  const regExpFloat = /^[-+][0-9]+\.[0-9]+[eE][-+]?[0-9]+$/;

  if (isInteger(value)) {
    return true;
  } else if (value.toString().match(regExpFloat)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Function that format a price with a currency code
 */
export const formatPrice = (price) => {
  const currency = getCurrency();

  if (!price) {
    const price = 0;
    return currencyFormatter.format(price, { code: currency });
  }

  let priceFormated = price;

  if (currency) {
    priceFormated = currencyFormatter.format(price, { code: currency });
  }

  return priceFormated;
};

/**
 * Function that copy a string from DOM
 */
export const copyStringToClipboard = (classNameText) => {
  const copyTextarea = document.querySelector(classNameText);
  copyTextarea.select();

  try {
    document.execCommand('copy');
  } catch (err) {}
};

/**
 * Function that use address google autocomplete component for build property object
 */
export const googleMapsAddressMapper = (addressObject) => {
  if (!addressObject) return null;

  if (addressObject.address_components) {
    const addressComponents = addressObject.address_components;
    let addressSelected = {};

    for (let i = 0; i < addressComponents.length; i++) {
      if (
        addressComponents[i] &&
        addressComponents[i].types &&
        addressComponents[i].types.length
      ) {
        for (let j = 0; j < addressComponents[i].types.length; j++) {
          addressSelected[addressComponents[i].types[j]] = {};

          if (addressComponents[i].long_name)
            addressSelected[addressComponents[i].types[j]]['long_name'] =
              addressComponents[i].long_name;

          if (addressComponents[i].short_name)
            addressSelected[addressComponents[i].types[j]]['short_name'] =
              addressComponents[i].short_name;
        }
      }
    }

    /**
     * UK tricks, if no locality found, replace it by postal_town
     */
    if (!addressSelected.locality && addressSelected.postal_town) {
      addressSelected.locality = addressSelected.postal_town;
    }

    return addressSelected;
  } else return false;
};

export const getDeviceBrand = () => {
  let deviceBrand = 'Unknown';

  if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1)
    deviceBrand = 'Windows 10';
  if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1)
    deviceBrand = 'Windows 8';
  if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1)
    deviceBrand = 'Windows 7';
  if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1)
    deviceBrand = 'Windows Vista';
  if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1)
    deviceBrand = 'Windows XP';
  if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1)
    deviceBrand = 'Windows 2000';
  if (window.navigator.userAgent.indexOf('Mac') !== -1) deviceBrand = 'Apple';
  if (window.navigator.userAgent.indexOf('X11') !== -1) deviceBrand = 'Unix';
  if (window.navigator.userAgent.indexOf('Linux') !== -1) deviceBrand = 'Linux';

  return deviceBrand;
};
