import React from 'react';

const renderFieldLabel = ({ input, label, id, disabled, placeholder, type, className, meta: { touched, error } }) => {
  return (
    <div className="inner-field">
      { label ? (
        <label htmlFor={id}>{label}</label>
      ) : ''}
      <input
        {...input}
        placeholder={placeholder}
        disabled={disabled}
        className={error && touched ? (className + " field-get-error") : className}
        type={type}
        id={id}
      />
      {touched && error && <span className="error-message error-field">{error}</span>}
    </div>
  );
}

export default renderFieldLabel;
