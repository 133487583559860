import React, { Component } from 'react';

class PrivacyPolicyES extends Component {
  render () {
    return (
      <div className="app-terms-of-use">
        <div className="articles">
          <div className="article-content" id="introduction">
            <p>
              Su privacidad es importante para Wall-Market. Esta política está diseñada para ayudarle a entender cómo recopilamos y procesamos sus datos personales en relación con la prestación de nuestros servicios y su uso de nuestro sitio web <a href="https://lightshop.wall-market.com" target="_blank" rel="noopener noreferrer">lightshop.wall-market.com</a> y de las aplicaciones Lightshop, y cuáles son sus derechos.
            </p>
            <ul>
              <li>1. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS?</li>
              <li>2. ¿QUÉ DATOS RECOGEMOS?</li>
              <li>3. ¿POR QUÉ RECOGEMOS SUS DATOS Y CUÁNTO TIEMPO LOS CONSERVAMOS?</li>
              <li>4. ¿CON QUIÉN COMPARTIMOS SUS DATOS?</li>
              <li>5. ¿CUÁLES SON SUS DERECHOS Y CÓMO EJERCERLOS?</li>
              <li>6. CONTACTO</li>
              <li>7. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD</li>
            </ul>
          </div>
          <div className="article-content">
            <h2>1. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS?</h2>
            <p>
              El responsable del tratamiento de sus datos es la empresa Wall-Market, registrada con el número  532 727 740  RCS Paris, con domicilio social en  22 rue Legendre, 75017 París, Francia.
            </p>
          </div>
          <div className="article-content with-list">
            <h2>2. ¿QUÉ DATOS RECOGEMOS?</h2>
            <p>Recogemos los siguientes datos personales:</p>
            <ul>
              <li>
                <span className="bold">Los datos que nos proporciona:</span> cuando se registra en nuestro sitio o en nuestra aplicación para acceder a nuestros servicios o actualizar su cuenta, o cuando se pone en contacto con nosotros (en particular a través de nuestro formulario de contacto, correo electrónico, teléfono o redes sociales), recogemos la información personal que nos proporciona. Por ejemplo, al crear una cuenta se le puede pedir que nos proporcione su nombre y apellidos, dirección de correo electrónico, dirección postal (país de residencia si es diferente), número de teléfono móvil y/o fijo, fecha de nacimiento, nacionalidad, información de la tarjeta de crédito o cuenta bancaria, idioma(s) que habla y, si procede, foto. 
                <br /><br />
                En todos los casos, sólo le pediremos la información que realmente necesitamos. Usted es libre de elegir si la proporciona o no, pero si se niega, no podrá utilizar nuestros servicios. 
                <br /><br />
              </li>
              <li>
                <span className="bold">Datos de la transacción:</span> recogemos los datos de la transacción asociados al uso de nuestros servicios, incluido el tipo de servicio solicitado o prestado, los detalles del pedido, la hora y la fecha en que se realizó el servicio, el importe cobrado y el método de pago.
                <br /><br />
              </li>
              <li>
                <span className="bold">Datos de uso:</span> recogemos datos sobre la interacción  de los usuarios con nuestros servicios. Esto incluye datos como las fechas y horas de acceso, las funciones de la aplicación o las páginas utilizadas, los bloqueos de la aplicación y otras actividades relacionadas con el sistema, y el tipo de navegador. Recogemos estos datos automáticamente mediante el uso de cookies. Para saber más sobre estas tecnologías, lea nuestra <a href="javascript:Didomi.preferences.show()">Política de Cookies</a>.
                <br /><br />
              </li>
              <li>
                <span className="bold">Geolocalización:</span> recogemos su geolocalización, en particular para permitir la organización y el seguimiento de las misiones. Recogemos estos datos con su consentimiento. Puedes desactivar la geolocalización en cualquier momento en los ajustes de la aplicación. La geolocalización es opcional, pero mejora notablemente el funcionamiento de nuestros servicios y reduce en gran medida los problemas de conexión.
                <br /><br />
              </li>
            </ul>
            <p>
              Nuestros servicios no están destinados a los menores y no están pensados para ser utilizados por ellos. No recopilamos conscientemente información de menores y no les pedimos ninguna información. No permitimos conscientemente que los menores utilicen nuestros servicios. Cualquier persona que proporcione información a Wall-Market a través de la página de inicio de sesión de nuevos clientes, la página de registro o cualquier otra parte de nuestro sitio o aplicación declara que es mayor de edad en su país de residencia. Si descubrimos que hemos recopilado información de un menor sin el permiso de sus padres o tutores legales, eliminaremos esa información. Por favor, póngase en contacto con nosotros en la información de contacto que aparece en la sección  6.más abajo si cree que nos ha proporcionado información sobre un menor sin el permiso de sus padres o tutores legales.
            </p>
          </div>
          <div className="article-content">
            <h2>3. ¿POR QUÉ RECOGEMOS SUS DATOS Y CUÁNTO TIEMPO LOS CONSERVAMOS?</h2>
            <p>
              Tratamos sus datos para fines definidos, sobre la base de una "justificación" legal (o base jurídica), y durante un período de tiempo limitado. En la siguiente tabla se detallan los fines y la base jurídica de nuestro tratamiento y los períodos de conservación aplicables.
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Objetivos</th>
                  <th style={{textAlign: 'center'}}>Base jurídica</th>
                  <th style={{textAlign: 'center'}}>Vida útil</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Gestión de su cuenta de usuario (creación yactualización)
                  </td>
                  <td>
                    Este tratamiento es necesario para la ejecución del contrato celebrado entre usted y Wall-Market.
                  </td>
                  <td>
                    Mientras mantenga su cuenta
                    <br/><br/>
                    En caso de inactividad durante un periodo de 5 años, su cuenta se eliminará automáticamente.
                  </td>
                </tr>
                <tr>
                  <td>
                    Gestión de pedidos (prestación de servicios, gestión de relaciones y análisis estadístico)
                  </td>
                  <td>
                    Este tratamiento es necesario para la ejecución del contrato celebrado entre usted y Wall-Market.
                  </td>
                  <td>
                    También guardamos un historial de todos sus pedidos de los últimos 5 años para el seguimiento de los clientes y el análisis estadístico.  
                  </td>
                </tr>
                <tr>
                  <td>
                    Geolocalización (propuesta de misiones en lascercanías, seguimiento de la misión y gestión de conflictos)
                  </td>
                  <td>
                    Este tratamiento se realiza con su consentimiento.
                  </td>
                  <td>
                    48 horas. No obstante, los datos podrán conservarse durante más tiempo en caso de litigio sobre los servicios prestados, hasta que se resuelva el litigio.
                  </td>
                </tr>
                <tr>
                  <td>Evaluación del usuario</td>
                  <td>Este tratamiento es necesario para perseguir nuestro interés legítimode garantizar y mejorar la calidad de los servicios.</td>
                  <td>Mientras tenga una cuenta activa, más 5 años</td>
                </tr>
                <tr>
                  <td>Tramitación de las solicitudes recibidas por cualquier medio (formulario de contacto, correo electrónico, teléfono)</td>
                  <td>Este tratamiento se realiza con su consentimiento.</td>
                  <td>Tiempo necesario para tramitar su solicitud</td>
                </tr>
                <tr>
                  <td>Envío de comunicados a los proveedores asociados independientes</td>
                  <td>Este tratamiento es necesario para perseguir nuestro interés legítimo de animar a nuestra comunidad de proveedores de servicios independient y mantenerlos informados de las novedades de Wall-Market. </td>
                  <td>Mientras el contratista independiente tenga un acuenta activa o hasta que se dé de baja de la recepción de estas comunicaciones.</td>
                </tr>
                <tr>
                  <td>Envío de correos electrónicos de marketing (ofertas comerciales, boletines, etc.)</td>
                  <td>Este tratamiento es necesario para perseguir nuestro interés legítimo en el desarrollo de nuestro negocio.</td>
                  <td>Hasta que decida dejar de recibir nuestros correos electrónicos de marketing o 3 años después  del último contacto</td>
                </tr>
                <tr>
                  <td>Obligaciones contables, fiscales, etc.</td>
                  <td>Este tratamiento es necesario para cumplir con una obligación legal a la que estamos sujetos.</td>
                  <td>Para el período de conservación legal aplicable</td>
                </tr>
                <tr>
                  <td>Gestión de precontenciosos y litigios</td>
                  <td>Este tratamiento es necesario para la persecución de nuestro interés legítimo en establecer la prueba de un derecho o contrato, y la defensa de nuestros derechos</td>
                  <td>Por la duración de la prescripción relacionada. Por regla general, conservamos los datos durante 6 años</td>
                </tr>
                <tr>
                  <td>Gestión de las solicitudes de ejercicio de derechos</td>
                  <td>Este tratamiento es necesario para cumplir con una obligación legal a la que estamos sujetos. </td>
                  <td>5 años a partir del final del año natural de su solicitud.<br/><br/>
                    Si se solicita una copia de su documento de identidad, se conserva durante 1 año si su solicitud es para ejercer su derecho de acceso o rectificación y durante 3 años si ejerce su derecho de oposición o supresión.
                  </td>
                </tr>
              </tbody> 
            </table>
            <p>
            Tenga en cuenta que sus datos pueden conservarse durante un periodo de tiempo más largo, por ejemplo, para establecer la prueba de un derecho o para cumplir con una obligación legal. En todos los casos, sus datos no se conservan más allá de lo estrictamente necesario para los fines para los que se tratan. Cuando sus datos ya no sean necesarios, nos aseguraremos de que sean eliminados o anonimizados.
            </p>
          </div>
          <div className="article-content with-list">
            <h2>4. ¿CON QUIÉN COMPARTIMOS SUS DATOS?</h2>
            <p>
              Sólo compartimos sus datos con :
            </p>
            <ul>
              <li>otros usuarios sólo para permitir el cumplimiento de las misiones,</li>
              <li>las personas debidamente autorizadas de Wall-Market, que necesitan acceder a sus datos en el ejercicio de sus funciones,</li>
              <li>nuestros subcontratistas, que tratan sus datos en nombre de Wall-Market (incluidos nuestros proveedores de alojamiento y mantenimiento, servicios de análisis y de pago),</li>
              <li>nuestros asesores externos (abogados, auditores, etc.), cuando sea necesario para los intereses legítimos de Wall-Market,</li>
              <li>autoridades públicas o judiciales, cuando estemos legalmente obligados a ello.</li>
            </ul>
            <p>También podemos compartir sus datos personales con terceros en relación con una venta o reestructuración potencial o real de nuestra empresa o de algunos de nuestros activos, en cuyo caso sus datos pueden ser uno de los activos transferidos.</p>
            <p>Sus datos personales pueden ser transferidos fuera del Espacio Económico Europeo (EEE), a países que no ofrecen un nivel de protección de sus datos equivalente al que disfrutan dentro del EEE. En ausencia de una decisión de adecuación por parte de la Comisión Europea en virtud del artículo 45 del RGPD, la transferencia de sus datos personales se regirá por los mecanismos de transferencia adecuados en virtud del artículo 46 y siguientes del RGPD (como las cláusulas contractuales estándar adoptadas por la Comisión Europea). Puede obtener una copia de las mismas (excluyendo las disposiciones confidenciales) poniéndose en contacto con nosotros a través de los datos que figuran en el artículo <a href="#privacy-policy-contact">« contacto »</a>.</p>
          </div>
          <div className="article-content with-list">
            <h2>5. ¿CUÁLES SON SUS DERECHOS Y CÓMO EJERCERLOS?</h2>
            <p>
              Usted tiene una serie de derechos sobre sus datos. 
            </p>
            <ul>
              <li>Usted puede revocar en cualquier momento su consentimiento para cualquier tratamiento de sus datos basado en su consentimiento.</li>
              <li>Puede pedirnos que le confirmemos si estamos tratando sus datos y, en caso afirmativo, que le informemos de las características del tratamiento, que le permitamos acceder a ellos y obtener una copia.</li>
              <li>Puede pedirnos que corrijamos o completemos sus datos si son incorrectos o están incompletos.</li>
              <li>En algunos casos, puede pedirnos que eliminemos sus datos o que limitemos el tratamiento de los mismos.</li>
              <li>En algunos casos, puede pedirnos que le proporcionemos sus datos en un formato estructurado, de uso común y legible por máquina, o puede solicitar que se transmitan directamente a otro responsable del tratamiento.</li>
              <li>Tiene derecho a dar instrucciones (generales o específicas) sobre lo que ocurre con sus datos después de su muerte.</li>
              <li className="bold">
                Puede oponerse a cualquier tratamiento de sus datos que se base en nuestro "interés legítimo". Si usted ejerce este derecho, debemos dejar de tratarlo a menos que demostremos motivos legítimos imperiosos que prevalezcan sobre sus derechos y libertades fundamentales, o para el establecimiento, ejercicio o defensa de reclamaciones legales. 
              </li>
              <li className="bold">
                Puede oponerse en cualquier momento al tratamiento de sus datos con fines de prospección. 
              </li>
            </ul>
            <p>Puede ejercer sus derechos poniéndose en contacto con nosotros en la dirección indicada en el artículo <a href="#privacy-policy-contact">« contacto »</a>. </p>
            <p>También tiene derecho a presentar una reclamación ante la autoridad de control competente en relación con el tratamiento de sus datos. </p>
          </div>
          <div className="article-content" id="privacy-policy-contact">
            <h2>6. CONTACTO</h2>
            <p>Para más información sobre sus derechos, para ejercerlos o si tiene alguna pregunta o queja sobre la protección de sus datos personales, póngase en contacto con nosotros en <a href="mailto: rgpd@wall-market.com">rgpd@wall-market.com</a>.</p>
          </div>
          <div className="article-content">
            <h2>7. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD</h2>
            <p>Es posible que modifiquemos esta política de privacidad de vez en cuando, por ejemplo, para tener en cuenta los cambios legales, los avances tecnológicos y las buenas prácticas empresariales. Le notificaremos cualquier cambio importante.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicyES;
