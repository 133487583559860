import React, { Component } from 'react';

class Snackbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
    this.timer = undefined;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.open });
    this.forceUpdate();
  }

  componentDidMount() {
    clearTimeout(this.timer);
  }

  autoHide() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.closeFunction();
    }, this.props.autoHideDuration);
  }

  render() {

    const {
      messageText,
      statusClass,
      closeFunction
    } = this.props;

    return (
      <div> {
        this.state.open ? (
          <div className="app-snackbar fadeInUp" ref={this.autoHide.bind(this)}>
            <div className="content">
              <p className={"message " + statusClass}>
                <span className="icon-infos info-icon" />
                {messageText ? messageText : ''}
              </p>
              <div className="close-sb icon-fermer" onClick={closeFunction} />
            </div>
          </div>
        ) : ''
      } </div>
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
}

export default Snackbar;
