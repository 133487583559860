import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import Translation from '../../translation/Translation';

class ProPacks extends Component {

  render() {

    return (
      <div className="app-service app-packs">
        <div className="page-header-title">
          <div className="icon-packs icon-page-head"></div>
          <h1>{Translation().pages.pro_packs.page_title.title}</h1>
          <h2>{Translation().pages.pro_packs.page_title.baseline}</h2>
        </div>
        <section className="packs-products">
          <section className="pack-product photo-plan">
            <div className="container">
              <div className="row">
                <img className="product-avatar" src="/assets/images/packs/pack-pro-photo-plan-avatar.png" alt="Pro pack"/>
                <div className="product-title">
                  <img src="/assets/images/commons/icon-pack-plan.png" alt="Photoshoot + Plan"/>
                  <h3><span>{Translation().pages.pro_packs.products.photoshoot_plan.title}</span> {Translation().pages.pro_packs.products.photoshoot_plan.sub_title}</h3>
                </div>
                <div style={{position: 'relative'}}>
                  <Col xs={12} sm={12} md={6}>
                    <div className="product-cover">
                      <div className="background-services pro-pack-photo-cover" />
                      <div className="details">
                        <h4>{Translation().pages.pro_packs.products.photoshoot_plan.offers.photo.title}</h4>
                        <p>{Translation().pages.pro_packs.products.photoshoot_plan.offers.photo.text}</p>
                      </div>
                    </div>
                  </Col>
                  <div className="products-fab">
                    <span className="icon-plus"/>
                  </div>
                  <Col xs={12} sm={12} md={6}>
                    <div className="product-cover">
                      <div className="background-services pro-pack-plan-cover" />
                      <div className="details">
                        <h4>{Translation().pages.pro_packs.products.photoshoot_plan.offers.plan.title}</h4>
                        <p>{Translation().pages.pro_packs.products.photoshoot_plan.offers.plan.text}</p>
                      </div>
                    </div>
                  </Col>
                  <Clearfix />
                  <a href="/shop" className="app-button-color orange">{Translation().pages.pro_packs.products.button}</a>
                </div>
              </div>
            </div>
          </section>
          <section className="pack-product">
            <div className="container">
              <div className="row">
                <img className="product-avatar" src="/assets/images/packs/pack-pro-photo-visit-avatar.png" alt="Pro pack"/>
                <div className="product-title">
                  <img src="/assets/images/commons/icon-pack-vv.png" alt="Photoshoot + Virtual tour"/>
                  <h3><span>{Translation().pages.pro_packs.products.photoshoot_vv.title}</span> {Translation().pages.pro_packs.products.photoshoot_vv.sub_title}</h3>
                </div>
                <div style={{position: 'relative'}}>
                  <Col xs={12} sm={12} md={6}>
                    <div className="product-cover">
                      <div className="background-services pro-pack-photo-cover" />
                      <div className="details">
                        <h4>{Translation().pages.pro_packs.products.photoshoot_vv.offers.photo.title}</h4>
                        <p>{Translation().pages.pro_packs.products.photoshoot_vv.offers.photo.text}</p>
                      </div>
                    </div>
                  </Col>
                  <div className="products-fab">
                    <span className="icon-plus"/>
                  </div>
                  <Col xs={12} sm={12} md={6}>
                    <div className="product-cover">
                      <div className="background-services pro-pack-vv-cover" />
                      <div className="details">
                        <h4>{Translation().pages.pro_packs.products.photoshoot_vv.offers.visit.title}</h4>
                        <p>{Translation().pages.pro_packs.products.photoshoot_vv.offers.visit.text}</p>
                      </div>
                    </div>
                  </Col>
                  <Clearfix />
                  <a href="/shop" className="app-button-color grey">{Translation().pages.pro_packs.products.button}</a>
                </div>
              </div>
            </div>
          </section>
          <section className="pack-product photo-plan-vv">
            <div className="container">
              <div className="row">
                <img className="product-avatar" src="/assets/images/packs/pack-pro-photo-plan-visit-avatar.png" alt="Pro pack"/>
                <div className="product-title">
                  <img src="/assets/images/commons/icon-pack-plan-vv.png" alt="Photoshoot + Plan + Virtual tour"/>
                  <h3><span>{Translation().pages.pro_packs.products.photoshoot_plan_vv.title}</span> {Translation().pages.pro_packs.products.photoshoot_plan_vv.sub_title}</h3>
                </div>
                <div style={{position: 'relative'}}>
                  <Col xs={12} sm={12} md={4}>
                    <div className="product-cover">
                      <div className="background-services pro-pack-photo-cover" />
                      <div className="details">
                        <h4>{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.photo.title}</h4>
                        <p>{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.photo.text}</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={4}>
                    <div className="products-fab first">
                      <span className="icon-plus"/>
                    </div>
                    <div className="product-cover">
                      <div className="background-services pro-pack-plan-cover" />
                      <div className="details">
                        <h4>{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.plan.title}</h4>
                        <p>{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.plan.text}</p>
                      </div>
                    </div>
                    <div className="products-fab second">
                      <span className="icon-plus"/>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={4}>
                    <div className="product-cover">
                      <div className="background-services pro-pack-vv-cover" />
                      <div className="details">
                        <h4>{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.visit.title}</h4>
                        <p>{Translation().pages.pro_packs.products.photoshoot_plan_vv.offers.visit.text}</p>
                      </div>
                    </div>
                  </Col>
                  <Clearfix />
                  <a href="/shop" className="app-button-color">{Translation().pages.pro_packs.products.button}</a>
                </div>
              </div>
            </div>
          </section>
        </section>
        <div className="no-price-page">
          <div className="container">
            <div className="row">
              <div className="pagination-service">
               <div className="prev-service">
                  <a href="/drone-video">
                    <span className="icon-chevron_gauche icon-pagination"></span>
                    <p>{Translation().header.app_nav.products.video_drone.title}</p>
                  </a>
                </div>
                <div className="next-service">
                  <a href="/scan-pack">
                    <p>{Translation().header.app_nav.products.scan_packs.title}</p>
                    <span className="icon-chevron_droite icon-pagination"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProPacks;
