import React, { Component } from 'react';

import ShopRouter from './shopRouter/ShopRouter';
import { isInteger } from '../../helpers/HelperFunctions';

class Shop extends Component {

  render() {

  	/**
     * Check step number in the url
     */
    const appUrl = window.location.pathname;
    const pathArray = appUrl.split( '/' );
    const shopStepIndex = parseInt(pathArray[2], 10);

  	const {
      history,
      userStatus,
      cartStatus,
      shopReady
    } = this.props;

    return (
      <div>
        <ShopRouter
          userStatus={userStatus}
          step={isInteger(shopStepIndex) ? shopStepIndex : 1}
          cartStatus={cartStatus}
          history={history}
          shopReady={shopReady}
        />
        <div form="shop" />
      </div>
    );
  }
}

export default Shop;
