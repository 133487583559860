import Translation from '../../../translation/Translation';

const validate = (values) => {

  const errors = {};

  if (!values.legal_name) {
    errors.legal_name = Translation().forms.errors.required;
  }
  if (!values.commercial_name) {
    errors.commercial_name = Translation().forms.errors.required;
  }

  return errors;
}

export default validate;
