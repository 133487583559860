import React, { Component } from 'react';
import { reset } from 'redux-form';

import { apiRequestPassword } from '../../helpers/AccountFunctions';
import RequestPasswordForm from './requestPasswordForm';
import store from '../../redux/store';

import Translation from '../../translation/Translation';

class RequestPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSendSuccess: false,
      errorMessage: ''
    }

    this.requestPassword = this.requestPassword.bind(this);
  }

  requestPassword(values) {

    if (values) {
      this.setState({ isLoading: true });

      apiRequestPassword(
        values,
        (successData) => {
          this.setState({
            isLoading: false,
            isSendSuccess: true,
            errorMessage: ''
          });

          store.dispatch(reset('requestPassword'));
        },
        (error) => {
          let errorMessage = Translation().pages.request_password.form.callbacks.failure;

          if (error.response && error.response.status) {
            if (error.response.status === 404) {
              errorMessage = Translation().pages.request_password.form.callbacks.not_found;
            }
          }

          this.setState({
            isLoading: false,
            isSendSuccess: false,
            errorMessage: errorMessage
          });
        }
      )
    }
  }

  render() {

    const { isLoading, isSendSuccess, errorMessage } = this.state;

    return (
      <div className="app-request-pwd">
        <div className="page-header-title">
          <h1>{Translation().pages.request_password.page_title}</h1>
        </div>
        <div className="container">
          <RequestPasswordForm
            isLoading={isLoading}
            isSendSuccess={isSendSuccess}
            onSubmit={this.requestPassword}
            errorMessage={errorMessage}
            labels={Translation().pages.request_password.form.labels}
            buttonLabel={Translation().pages.request_password.form.button}
            callbackMessage={Translation().pages.request_password.form.callbacks.success}
          />
          <div form="requestPassword" />
        </div>
      </div>
    )
  }
}

export default RequestPassword;
