import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import $ from 'jquery';

import ShopBanner from '../../components/commons/banners/ShopBanner';
import BeforeAfterSlider from '../../components/commons/sliders/BeforeAfterSlider';

import Translation from '../../translation/Translation';

class ShootingSlider extends Component {
  render() {
    const bg1 = {
      backgroundImage:
        "url('/assets/images/home_staging/home-staging-slider-pic01.jpg",
    };
    const bg2 = {
      backgroundImage:
        "url('/assets/images/home_staging/home-staging-slider-pic02.jpg",
    };
    const bg3 = {
      backgroundImage:
        "url('/assets/images/home_staging/home-staging-slider-pic03.jpg",
    };
    const bg4 = {
      backgroundImage:
        "url('/assets/images/home_staging/home-staging-slider-pic04.jpg",
    };

    return (
      <div className="service-slider home-staging-slider">
        <div className="service-slides" style={bg1}></div>
        <div className="service-slides" style={bg2}></div>
        <div className="service-slides" style={bg3}></div>
        <div className="service-slides" style={bg4}></div>
      </div>
    );
  }
}

class HomeStaging extends Component {
  render() {
    return (
      <div className="app-service app-home-staging">
        <div className="page-header-title">
          <img
            className="icon-page-head"
            src="assets/images/home_staging/virtual-home-staging.png"
            width={80}
            alt=""
          />
          <h1>{Translation().pages.home_staging.page_title.title}</h1>
          <h2>{Translation().pages.home_staging.page_title.baseline}</h2>
        </div>
        <section className="intro-service">
          <Col xs={12} sm={12} md={6} className="cover-service">
            <BeforeAfterSlider />
          </Col>
          <Col xs={12} sm={12} md={6} className="details-service">
            <h3>{Translation().pages.home_staging.description.title}</h3>
            <p>{Translation().pages.home_staging.description.texts[0]}</p>
            <p>{Translation().pages.home_staging.description.texts[1]}</p>
            <a className="prices scroll-anch" href="#offers">
              {Translation().pages.home_staging.description.buttons.pricing}
            </a>
            <a className="app-button-color" href="/shop">
              {Translation().pages.home_staging.description.buttons.order}
            </a>
          </Col>
          <Clearfix />
        </section>
        <section className="process-service">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.home_staging.guarantees.title}</h3>
              <Col xs={12} sm={5} md={5}>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.home_staging.guarantees.texts[0]}</p>
                  <Clearfix />
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.home_staging.guarantees.texts[1]}</p>
                  <Clearfix />
                </div>
              </Col>
              <Col xs={12} sm={2} md={2} />
              <Col xs={12} sm={5} md={5} className="padding-left">
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.home_staging.guarantees.texts[2]}</p>
                  <Clearfix />
                </div>
                <div className="process-step">
                  <span className="icon-avantages"></span>
                  <p>{Translation().pages.home_staging.guarantees.texts[3]}</p>
                  <Clearfix />
                </div>
              </Col>
              <Clearfix />
            </div>
          </div>
        </section>
        <ShopBanner />
        <div className="showroom-service">
          <ShootingSlider />
        </div>
        <section className="prices-service" id="offers">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.home_staging.pricing.title}</h3>
              <p className="baseline-title" />
              <Col xs={12} sm={6} md={4} />
              <Col xs={12} sm={6} md={4}>
                <div className="package">
                  <div className="package-header">
                    <h5>
                      {
                        Translation().pages.home_staging.pricing.products
                          .virtual.title
                      }
                    </h5>
                    <h4>
                      {
                        Translation().pages.home_staging.pricing.products
                          .virtual.name
                      }
                    </h4>
                  </div>
                  <img
                    className=" icon-price"
                    src="assets/images/home_staging/virtual-home-staging.png"
                    width={65}
                    alt=""
                  />
                  <div className="package-features">
                    <p>
                      {
                        Translation().pages.home_staging.pricing.products
                          .virtual.text
                      }
                    </p>
                  </div>
                  <a href="/shop" className="app-button-color app-button-dark">
                    {Translation().pages.home_staging.pricing.button}
                  </a>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} />
              <Clearfix />
              <div className="pagination-service">
                <div className="prev-service">
                  <a href="/drone-video">
                    <span className="icon-chevron_gauche icon-pagination"></span>
                    <p>
                      {Translation().header.app_nav.products.video_drone.title}
                    </p>
                  </a>
                </div>
                <div className="next-service">
                  <a href="/pro-pack">
                    <p>
                      {Translation().header.app_nav.products.pro_packs.title}
                    </p>
                    <span className="icon-chevron_droite icon-pagination"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomeStaging;

$(document).ready(function () {
  $('.home-staging-slider').slick({
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
    prevArrow: '<div class="arrow-left icon-chevron_gauche"></div>',
    nextArrow: '<div class="arrow-right icon-chevron_droite"></div>',
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
});
