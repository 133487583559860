import React, { Component } from 'react';

import { getLocale } from '../../helpers/UserFunctions';

import LegalNoticeFR from './translation/LegalNoticeFR';
import LegalNoticeEN from './translation/LegalNoticeEN';
import LegalNoticeES from './translation/LegalNoticeES';

class LegalNotice extends Component {
  render() {
    const locale = getLocale();
  
    if (locale && locale === "fr")
      return <LegalNoticeFR />
    else if (locale && locale === "en")
      return <LegalNoticeEN />
    else if (locale && locale === "es")
      return <LegalNoticeES />
    else if (locale && locale === "it")
      return <LegalNoticeEN />
    else
      return <LegalNoticeEN />
  }
}

export default LegalNotice;
