import React, { Component } from 'react';

import Translation from '../../../translation/Translation';

class AddressFailure extends Component {

  render() {

    return (
      <div className="shop-property-error api-error" id="addressNotFoundFailure">
        <div className="container">
          <div className="row">
            <div className="error-message-shop">
              <h3>{Translation().shop.failure.address_failure.title}</h3>
              <h4>{Translation().shop.failure.address_failure.baseline}</h4>
              <p>{Translation().shop.failure.address_failure.description}</p>
              <a className="button-retry" href="/shop">{Translation().shop.failure.address_failure.button}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddressFailure;
