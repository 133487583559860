import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { RadioButton } from 'material-ui/RadioButton';
import { RadioButtonGroup } from 'redux-form-material-ui';

import BirthdateField from '../../../commons/fields/BirthdateField';
import renderFieldLabel from '../../../commons/fields/renderFieldLabel';
import PhoneField from '../../../commons/fields/PhoneField';

import { initUserBasicsInfos } from '../../../../helpers/AccountFunctions';
import { initPlaceholder } from '../../../../helpers/JqueryFunctions';

import validate from './validation/validateProfile';

class ProfileForm extends Component {

  componentDidMount() {
    initPlaceholder();
  }

  render() {

    const {
      handleSubmit,
      labels,
      buttonLabel
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="edit-account-form" ref={initUserBasicsInfos}>
        <div className="gender-box">
          <label>{labels.civility}</label>
          <Field name="gender" className="gender-field" component={RadioButtonGroup} style={{display:'flex'}}>
            <RadioButton
              style={{width: '100px'}}
              value="F"
              label={labels.female}
            />
            <RadioButton
              style={{width: '100px'}}
              value="M"
              label={labels.male}
            />
          </Field>
        </div>
        <div className="form-cel">
          <BirthdateField
            formName="editBasicAccount"
            label={labels.birth_of_date}
          />
        </div>
        <div className="form-cel">
          <Field
            name="first_name"
            id="firstName"
            type="text"
            label={labels.first_name}
            component={renderFieldLabel}
            className="app-basic-input"
          />
        </div>
        <div className="form-cel">
          <Field
            name="last_name"
            id="lastName"
            type="text"
            label={labels.last_name}
            component={renderFieldLabel}
            className="app-basic-input"
          />
        </div>
        <div className="form-cel">
          <Field
            name="mobile"
            id="mobile"
            type="text"
            label={labels.mobile}
            component={PhoneField}
            className="app-basic-input"
            fullWidth={true}
          />
        </div>
        <div className="form-cel">
          <Field
            name="phone"
            id="phone"
            type="text"
            label={labels.phone}
            component={PhoneField}
            className="app-basic-input"
            fullWidth={true}
          />
        </div>
        <div className="form-cel">
          <Field
            name="email"
            type="mail"
            id="email"
            label={labels.email}
            component={renderFieldLabel}
            className="app-basic-input"
          />
        </div>
        <button type="submit" className="app-button-color button-api btn-edit">
          <span id="edit-perso-loader" className="loader-api" />
          <span id="edit-perso-txt">{buttonLabel}</span>
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'editBasicAccount',
  validate
})(ProfileForm);
