import React, { Component } from 'react';

import { autoScrollTop } from '../../helpers/JqueryFunctions';
import Translation from '../../translation/Translation';

class Tab extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    autoScrollTop();
  }

  handleChange() {

    const {
      value,
      onChange
    } = this.props;

    onChange(value);
  }

  render() {

    const {
      value,
      children,
      tabOpen
    } = this.props;

    return (
      <div className={"gallery-tab " + (value === tabOpen ? "active" : "")} onClick={this.handleChange}>
        {children}
      </div>
    )
  }
}

class Gallery extends Component {

  constructor() {
    super();

    this.state = {
      tabOpen: 1,
      imageSelected: null
    }

    this.shootingArray = [
      "/assets/images/shooting/shooting-slider-pic01.jpg",
      "/assets/images/shooting/shooting-slider-pic02.jpg",
      "/assets/images/shooting/shooting-slider-pic03.jpg",
      "/assets/images/shooting/shooting-slider-pic04.jpg",
      "/assets/images/shooting/shooting-slider-pic05.jpg",
      "/assets/images/shooting/shooting-slider-pic21.jpg",
      "/assets/images/shooting/shooting-slider-pic22.jpg",
      "/assets/images/shooting/shooting-slider-pic08.jpg",
      "/assets/images/shooting/shooting-slider-pic09.jpg",
      "/assets/images/shooting/shooting-slider-pic10.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic11.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic12.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic13.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic14.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic15.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic16.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic17.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic18.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic19.jpg",
      "/assets/images/gallery/shooting/shooting-slider-pic20.jpg",

    ]

    this.planArray = [
      "/assets/images/plans/plan-slider-pic01.jpg",
      "/assets/images/plans/plan-slider-pic02.jpg",
      "/assets/images/plans/plan-slider-pic03.jpg",
      "/assets/images/plans/plan-slider-pic04.jpg",
      "/assets/images/plans/plan-slider-pic05.jpg",
      "/assets/images/gallery/plans/plan-slider-pic06.jpg",
      "/assets/images/gallery/plans/plan-slider-pic07.jpg",
      "/assets/images/gallery/plans/plan-slider-pic08.jpg",
      "/assets/images/gallery/plans/plan-slider-pic09.jpg",
      "/assets/images/gallery/plans/plan-slider-pic10.jpg",
      "/assets/images/gallery/plans/plan-slider-pic11.jpg",
      "/assets/images/gallery/plans/plan-slider-pic12.jpg",
      "/assets/images/gallery/plans/plan-slider-pic13.jpg",
    ]

    this.virtualTourArray = [
      "https://my.matterport.com/show/?m=phCoAyWQNx6",
      "https://my.matterport.com/show/?m=5VL6SkZQfZp",
      "https://my.matterport.com/show/?m=2g6LGQ6boTA"
    ];

    this.handleChangeTab = this.handleChangeTab.bind(this);

    this.handleOpenImageModal = this.handleOpenImageModal.bind(this);
    this.handleCloseImageModal = this.handleCloseImageModal.bind(this);
  }

  handleChangeTab(tabOpen) {
    this.setState({ tabOpen });
  }

  handleOpenImageModal(imageSelected) {
    this.setState({ imageSelected });
  }

  handleCloseImageModal() {
    this.setState({ imageSelected: null });
  }

  render() {

    const {
      tabOpen,
      imageSelected
    } = this.state;

    return (
      <div className="app-gallery">
        <div className="page-header-title">
          <h1>{Translation().pages.gallery.page_title}</h1>
        </div>
        <div className="gallery-tabs">
          <Tab
            value={1}
            tabOpen={tabOpen}
            onChange={this.handleChangeTab}
          >
            <h2>{Translation().pages.gallery.tabs_titles.shooting}</h2>
          </Tab>
          <Tab
            value={2}
            tabOpen={tabOpen}
            onChange={this.handleChangeTab}
          >
            <h2>{Translation().pages.gallery.tabs_titles.floor_plan}</h2>
          </Tab>
          <Tab
            value={3}
            tabOpen={tabOpen}
            onChange={this.handleChangeTab}
          >
            <h2>{Translation().pages.gallery.tabs_titles.virtual_tour}</h2>
          </Tab>
        </div>
        <div className="galleries-wrapper">
          {
            tabOpen === 1 &&
            <div className="container gallery-container">
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[0])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[0] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[1])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[1] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[2])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[2] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[3])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[3] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[4])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[4] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[5])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[5] + ')'}}/>
                </div>
              </div>

              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[6])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[6] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[7])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[7] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[8])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[8] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[9])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[9] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[10])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[10] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[11])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[11] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[12])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[12] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[13])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[13] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[14])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[14] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[15])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[15] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[16])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[16] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[17])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[17] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[18])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[18] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.shootingArray[19])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.shootingArray[19] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
            </div>
          }
          {
            tabOpen === 2 &&
            <div className="container gallery-container">
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[0])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[0] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[1])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[1] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[2])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[2] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[3])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[3] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[4])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[4] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[5])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[5] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[6])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[6] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[7])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[7] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[8])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[8] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[9])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[9] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[10])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[10] + ')'}}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[11])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[11] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-6">
                <div className="gallery-col" onClick={() => this.handleOpenImageModal(this.planArray[12])}>
                  <div className="gallery-overlay">
                    <span className="icon-search zoom-icon"/>
                  </div>
                  <div className="gallery-item" style={{backgroundImage: 'url(' + this.planArray[12] + ')'}}/>
                </div>
              </div>
              <div className="clearfix"/>
            </div>
          }
          {
            tabOpen === 3 &&
            <div className="container gallery-container">
              <div className="col-md-12">
                <div className="gallery-col virtual-tour">
                  <iframe title={this.virtualTourArray[0]} height="100%" width="100%" allowFullScreen="1" src={this.virtualTourArray[0]} frameBorder="0">
                  </iframe>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-12">
                <div className="gallery-col virtual-tour">
                  <iframe title={this.virtualTourArray[1]} height="100%" width="100%" allowFullScreen="1" src={this.virtualTourArray[1]} frameBorder="0">
                  </iframe>
                </div>
              </div>
              <div className="clearfix"/>
              <div className="col-md-12">
                <div className="gallery-col virtual-tour">
                  <iframe title={this.virtualTourArray[2]} height="100%" width="100%" allowFullScreen="1" src={this.virtualTourArray[2]} frameBorder="0">
                  </iframe>
                </div>
              </div>
              <div className="clearfix"/>
            </div>
          }
        </div>
        <div className="clearfix"/>
        {
          imageSelected &&
            <div className="app-overlay" onClick={this.handleCloseImageModal}>
              <div className="gallery-modal">
                <img
                  alt="Not found"
                  className="gallery-image"
                  src={imageSelected}
                />
              </div>
            </div>
        }
      </div>
    )
  }
}

export default Gallery;
