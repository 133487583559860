import React, { Component } from 'react';

class PrivacyPolicyIT extends Component {
  render () {
    return (
      <div className="app-terms-of-use">
        <div className="articles">
          <div className="article-content" id="introduction">
            <p>
            La tua privacy è importante per Wall-Market. Questa politica ha lo scopo di aiutarvi a capire come raccogliamo ed elaboriamo i vostri dati personali in relazione alla fornitura dei nostri servizi e al vostro utilizzo del nostro sito web <a href="https://lightshop.wall-market.com" target="_blank" rel="noopener noreferrer">lightshop.wall-market.com</a> e delle applicazioni Lightshop, e quali sono i vostri diritti. 
            </p>
            <ul>
              <li>1. CHI È RESPONSABILE DEL TRATTAMENTO DEI SUOI DATI?</li>
              <li>2. QUALI DATI RACCOGLIAMO?</li>
              <li>3. PERCHÉ RACCOGLIAMO I SUOI DATI E PER QUANTO TEMPO LI CONSERVIAMO?</li>
              <li>4. CON CHI CONDIVIDIAMO I SUOI DATI? </li>
              <li>5. QUALI SONO I SUOI DIRITTI E COME ESERCITARLI? </li>
              <li>6. CONTATTO</li>
              <li>7. MODIFICHE ALLA POLITICA SULLA PRIVACY</li>
            </ul>
          </div>
          <div className="article-content">
            <h2>1. CHI È RESPONSABILE DEL TRATTAMENTO DEI SUOI DATI?</h2>
            <p>La società Wall-Market, registrata con il numero 532 727 740 RCS Parigi, e la cui sede sociale si trova al 22 rue Legendre, 75017 Parigi, Francia, è responsabile del trattamento dei vostri dati.</p>
          </div>
          <div className="article-content with-list">
            <h2>2. QUALI DATI RACCOGLIAMO?</h2>
            <p>Raccogliamo i seguenti dati personali: </p>
            <ul>
              <li>
                <span className="bold">I dati che ci fornisci:</span> : quando ti registri sul nostro sito o sulla nostra applicazione per accedere ai nostri servizi o aggiornare il tuo account, o quando ci contatti (in particolare tramite il nostro modulo di contatto, e-mail, telefono o social network), raccogliamo le informazioni personali che ci fornisci. Per esempio, quando si crea un account può essere richiesto di fornire il proprio nome e cognome, indirizzo e-mail, indirizzo postale (paese di residenza se diverso), numero di telefono cellulare e/o fisso, data di nascita, nazionalità, informazioni sulla carta di credito o sul conto bancario, lingua/e parlata/e e, se applicabile, foto. 
                <br /><br />
                In tutti i casi, vi chiederemo solo le informazioni di cui abbiamo veramente bisogno. Lei è libero di scegliere se fornirla o meno, ma se la rifiuta, non potrà utilizzare i nostri servizi. 
                <br /><br />
              </li>
              <li>
                <span className="bold">Dati sulle transazioni:</span> raccogliamo i dati delle transazioni associate all'uso dei nostri servizi, compreso il tipo di servizio ordinato o fornito, i dettagli dell'ordine, l'ora e la data in cui il servizio è stato eseguito, l'importo addebitato e il metodo di pagamento.
                <br /><br />
              </li>
              <li>
                <span className="bold">Dati di utilizzo:</span> raccogliamo dati sull'interazione degli utenti con i nostri servizi. Questo include dati come le date e le ore di accesso, le funzioni dell'applicazione o le pagine utilizzate, i crash dell'applicazione e altre attività legate al sistema, e il tipo di browser. Raccogliamo questi dati automaticamente attraverso l'uso di cookies. Per saperne di più su queste tecnologie, leggi la nostra <a href="javascript:Didomi.preferences.show()">Politica sui cookie</a>. 
                <br /><br />
              </li>
              <li>
                <span className="bold">Geolocalizzazione:</span> raccogliamo la vostra geolocalizzazione, in particolare per permettere l'organizzazione e il follow-up delle missioni. Raccogliamo questi dati con il suo consenso. Puoi disabilitare la geolocalizzazione in qualsiasi momento nelle impostazioni dell'applicazione. La geolocalizzazione è facoltativa, ma migliora significativamente il funzionamento dei nostri servizi e riduce notevolmente i problemi di connessione. 
                <br /><br />
              </li>
            </ul>
            <p>
              I nostri servizi non sono destinati ai minori e non sono destinati ad essere utilizzati dai minori. Non raccogliamo consapevolmente informazioni da minori e non chiediamo loro alcuna informazione. Non permettiamo consapevolmente ai minori di utilizzare i nostri servizi. Chiunque fornisca informazioni a Wall-Market attraverso la pagina di login del nuovo cliente, la pagina di registrazione, o qualsiasi altra parte del nostro sito o applicazione, dichiara di essere maggiorenne nel proprio paese di residenza. Se scopriamo di aver raccolto informazioni da un minore senza il permesso dei suoi genitori o tutori legali, cancelleremo tali informazioni. Contattateci alle informazioni di contatto elencate nella sezione 6 di seguito se ritenete di averci fornito informazioni su un minore senza il permesso dei suoi genitori o del suo tutore legale.
            </p>
          </div>
          <div className="article-content">
            <h2>3. PERCHÉ RACCOGLIAMO I SUOI DATI E PER QUANTO TEMPO LI CONSERVIAMO?</h2>
            <p>
             Trattiamo i vostri dati per scopi definiti, sulla base di una "giustificazione" legale (o base giuridica), e per un periodo di tempo limitato. La tabella qui sotto dettaglia gli scopi e le basi legali del nostro trattamento e i periodi di conservazione applicabili. 
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Obiettivi</th>
                  <th style={{textAlign: 'center'}}>Base legale</th>
                  <th style={{textAlign: 'center'}}>Durata di conservazione</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Gestione del suo account utente (creazione e aggiornamento)
                  </td>
                  <td>
                    Questo trattamento è necessario per l'esecuzione del contratto concluso tra voi e Wall-Market.
                  </td>
                  <td>
                    Finché mantieni il tuo account <br/><br/>In caso di inattività per un periodo di 5 anni, il tuo account sarà automaticamente cancellato. 
                  </td>
                </tr>
                <tr>
                  <td>
                    Gestione degli ordini (fornitura di servizi, gestione delle relazioni e analisi statistiche)
                  </td>
                  <td>
                    Questo trattamento è necessario per l'esecuzione del contratto concluso tra voi e Wall-Market.
                  </td>
                  <td>
                    Conserviamo anche uno storico di tutti i tuoi ordini degli ultimi 5 anni per il monitoraggio dei clienti e l'analisi statistica. 
                  </td>
                </tr>
                <tr>
                  <td>
                    Geolocalizzazione (proposta di missioni nelle vicinanze, follow-up della missione e gestione delle controversie)
                  </td>
                  <td>
                    Questo trattamento viene effettuato con il suo consenso
                  </td>
                  <td>
                    48 ore. Tuttavia, i dati possono essere conservati per un periodo più lungo in caso di controversia sui servizi forniti, fino alla risoluzione della controversia.
                  </td>
                </tr>
                <tr>
                  <td>Valutazione degli utenti</td>
                  <td>Questo trattamento è necessario per il perseguimento del nostro legittimo interesse a garantire e migliorare la qualità dei servizi</td>
                  <td>Finché hai un account attivo, più 5 anni</td>
                </tr>
                <tr>
                  <td>Trattamento delle richieste ricevute con qualsiasi mezzo (modulo di contatto, e-mail, telefono)</td>
                  <td>Questo trattamento viene effettuato con il suo consenso.</td>
                  <td>Tempo necessario per elaborare la sua richiesta</td>
                </tr>
                <tr>
                  <td>Invio di comunicati ai fornitori partner indipendenti</td>
                  <td>Questo trattamento è necessario per perseguire il nostro legittimo interesse ad animare la nostra comunità di fornitori di servizi indipendenti e tenerli informati sulle novità di Wall-Market. </td>
                  <td>Fino a quando il contraente indipendente ha un account attivo o fino a quando non disdice di ricevere queste comunicazioni</td>
                </tr>
                <tr>
                  <td>Invio di e-mail di marketing (offerte commerciali, newsletter, ecc.)</td>
                  <td>Questo trattamento è necessario per perseguire il nostro legittimo interesse a sviluppare la nostra attività.</td>
                  <td>Fino a quando non rinuncerà a ricevere le nostre e-mail di marketing o fino a 3 anni dall'ultimo contatto</td>
                </tr>
                <tr>
                  <td>Obblighi contabili, fiscali, ecc.</td>
                  <td>Questo trattamento è necessario per rispettare un obbligo legale al quale siamo soggetti.</td>
                  <td>Per il periodo di conservazione legale applicabile</td>
                </tr>
                <tr>
                  <td>Gestione del precontenzioso e del contenzioso</td>
                  <td>Questo trattamento è necessario per il perseguimento del nostro legittimo interesse a stabilire la prova di un diritto o di un contratto, e la difesa dei nostri diritti</td>
                  <td>Per la durata della relativa prescrizione. Come regola, conserviamo i dati per 6 anni.</td>
                </tr>
                <tr>
                  <td>Gestione delle richieste di esercizio dei diritt</td>
                  <td>Questo trattamento è necessario per rispettare un obbligo legale al quale siamo soggetti.</td>
                  <td>5 anni dalla fine dell'anno solare della sua domanda.<br/><br/>
                    Se viene richiesta una copia del suo documento d'identità, essa viene conservata per 1 anno se la sua richiesta è per esercitare i suoi diritti di accesso o di rettifica e per 3 anni se esercita il suo diritto di opposizione o di cancellazione.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Si prega di notare che i suoi dati possono essere conservati per un periodo di tempo più lungo, ad esempio per stabilire la prova di un diritto o per rispettare un obbligo legale. In tutti i casi, i suoi dati non sono conservati oltre lo stretto necessario per le finalità per cui sono trattati. Quando i suoi dati non sono più necessari, faremo in modo che vengano cancellati o resi anonimi.
            </p>
          </div>
          <div className="article-content with-list">
            <h2>4. CON CHI CONDIVIDIAMO I SUOI DATI? </h2>
            <p>
              Condividiamo i suoi dati solo con :
            </p>
            <ul>
              <li>altri utenti solo per consentire il completamento delle missioni, </li>
              <li>debitamente autorizzati da Wall-Market, che hanno bisogno di accedere ai vostri dati nell'ambito delle loro funzioni,</li>
              <li>i nostri subappaltatori, che trattano i tuoi dati per conto di Wall-Market (compresi i nostri fornitori di hosting e manutenzione, i servizi di analisi e di pagamento), </li>
              <li>i nostri consulenti esterni (avvocati, revisori, ecc.), quando ciò sia necessario per i legittimi interessi di Wall-Market,</li>
              <li>autorità pubbliche o giudiziarie, quando siamo legalmente obbligati a farlo.</li>
            </ul>
            <p>Possiamo anche condividere i vostri dati personali con terzi in relazione a una vendita o ristrutturazione potenziale o effettiva della nostra azienda o di alcuni dei nostri beni, nel qual caso i vostri dati possono essere uno dei beni trasferiti.</p>
            <p>I suoi dati personali possono essere trasferiti al di fuori dello Spazio Economico Europeo (SEE), in paesi che non offrono un livello di protezione dei suoi dati equivalente a quello di cui gode all'interno del SEE. In assenza di una decisione di adeguatezza da parte della Commissione europea ai sensi dell'articolo 45 del GDPR, il trasferimento dei vostri dati personali sarà regolato da meccanismi di trasferimento adeguati ai sensi dell'articolo 46 e seguenti del GDPR (come le clausole contrattuali standard adottate dalla Commissione europea). Potete ottenerne una copia (escluse le disposizioni riservate) contattandoci ai recapiti indicati nell'articolo <a href="#privacy-policy-contact">« Contatto »</a>. </p>
          </div>
          <div className="article-content with-list">
            <h2>5. QUALI SONO I SUOI DIRITTI E COME ESERCITARLI? </h2>
            <p>
              Lei ha una serie di diritti sui suoi dati.
            </p>
            <ul>
              <li>Potete revocare il vostro consenso a qualsiasi trattamento dei vostri dati basato sul vostro consenso in qualsiasi momento.</li>
              <li>Potete chiederci di confermare se stiamo trattando i vostri dati e, in caso affermativo, di informarvi sulle caratteristiche del trattamento, di permettervi di accedervi e di ottenerne una copia. </li>
              <li>Può chiederci di correggere o completare i suoi dati se sono errati o incompleti.</li>
              <li>In alcuni casi, potete chiederci di cancellare i vostri dati o di limitarne il trattamento. </li>
              <li>Lei può chiederci, in alcuni casi, di fornire i suoi dati in un formato strutturato, di uso comune e leggibile da una macchina, oppure può chiedere che siano trasmessi direttamente a un altro controllore.</li>
              <li>Lei ha il diritto di dare istruzioni (generali o specifiche) su cosa succede ai suoi dati dopo la sua morte. </li>
              <li className="bold">
                Potete opporvi a qualsiasi trattamento dei vostri dati che sia basato sul nostro "legittimo interesse". Se lei esercita questo diritto, dobbiamo cessare l'elaborazione a meno che non dimostriamo motivi legittimi impellenti che prevalgono sui suoi diritti e libertà fondamentali, o per l'istituzione, l'esercizio o la difesa di rivendicazioni legali. 
              </li>
              <li className="bold">
                Potete opporvi in qualsiasi momento al trattamento dei vostri dati a scopo di prospezione. 
              </li>
            </ul>
            <p>Potete esercitare i vostri diritti contattandoci all'indirizzo indicato nell'articolo  <a href="#privacy-policy-contact">« Contatto »</a>. </p>
            <p>Lei ha anche il diritto di presentare un reclamo all'autorità di controllo competente per quanto riguarda il trattamento dei suoi dati. </p>
          </div>
          <div className="article-content" id="privacy-policy-contact">
            <h2>6. CONTATTO</h2>
            <p>Per ulteriori informazioni sui vostri diritti, per esercitarli, o se avete domande o reclami sulla protezione dei vostri dati personali, contattateci all'indirizzo <a href="mailto: rgpd@wall-market.com">rgpd@wall-market.com</a>.</p>
          </div>
          <div className="article-content">
            <h2>7. MODIFICHE ALLA POLITICA SULLA PRIVACY</h2>
            <p>Possiamo modificare questa politica sulla privacy di tanto in tanto, ad esempio per tenere conto dei cambiamenti legali, dei progressi tecnologici e delle buone pratiche commerciali. Vi informeremo di qualsiasi cambiamento materiale.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicyIT;
