import Translation from '../../../translation/Translation';

const validate = values => {

  const errors = {};

  if (!values.email) {
    errors.email = Translation().forms.errors.required;
  }
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = Translation().forms.errors.email_invalid;
  }

  if (!values.password) {
    errors.password = Translation().forms.errors.required;
  }

  return errors;
}

export default validate;
