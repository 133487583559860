import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import Translation from '../../../translation/Translation';
import {dateInTz, convertMinsToHrsMins } from '../../../helpers/DateFunctions';
import { autoScrollTop } from '../../../helpers/JqueryFunctions';

class Validation extends Component {

  componentDidMount() {
    autoScrollTop();

    const buttonCommandHeaders = document.getElementsByClassName('header-cart');

    if (buttonCommandHeaders) {
      for (let i = 0; i < buttonCommandHeaders.length; i++) {
        buttonCommandHeaders[i].style.display = 'none';
      }
    }
  }

  getOrderDeliveryTime(order) {
    let deliveryTime = 48;
    const deliveryTimes = [];

    if (order) {
      if (order.products && order.products.length) {
        for (let i = 0; i < order.products.length; i++) {
          if (order.products[i].delivery_time) {
            deliveryTimes.push(order.products[i].delivery_time);
          }
        }
        if (deliveryTimes && deliveryTimes.length) {
          return Math.min(...deliveryTimes);
        }
      }
    }

    return deliveryTime;
  }

  render() {

    const orderData = this.props.dataObject;
    const timezoneCode = orderData && orderData.property && orderData.property.timezone_code ? orderData.property.timezone_code : undefined;

    return (
      <div>
        <ul className="order-steps">
          <li>
            <div className="shop-step active-step">
              <span className="icon-step icon-confirmation"></span>
              <span>{Translation().shop.step_header.validation} !</span>
            </div>
          </li>
        </ul>
        <div className="shop-validation">
          <div className="container">
            <div className="row">
              <div className="order-confirmed">
                <div className="order-header">
                  <h2>{Translation().shop.step_titles.validation}</h2>
                  <p className="order-desc">{Translation().shop.step_descriptions.validation} <span>{orderData && orderData.user && orderData.user.email ? orderData.user.email : ''}</span></p>
                </div>
                <h3 className="order-adrs">{orderData && orderData.property && orderData.property.address ? orderData.property.address : ''}</h3>
                { orderData && orderData.products && orderData.products.map((product, index) => {
                    return (
                      <div key={index} className="order-item">
                        <div className="item-title">
                         {product && product.name ? product.name : ''}
                        </div>
                        <div className="item-planning">
                          <Col xs={12} sm={4} md={4}>
                            { product && product.start
                              ? (
                                <div>
                                  <div className="icon-calendrier item-icon" />
                                  <p>{dateInTz(product.start, 'localized-date', timezoneCode)}</p>
                                </div>
                              )
                              : ''
                            }
                          </Col>
                          <Col xs={12} sm={4} md={4}>
                            { product && product.start
                              ? (
                                <div>
                                  <div className="icon-horaire item-icon" />
                                  <p>{dateInTz(product.start, 'HH:mm', timezoneCode)}</p>
                                </div>
                              )
                              : ''
                            }
                          </Col>
                          <Col xs={12} sm={4} md={4}>
                            { product && product.duration
                              ? (
                                <div>
                                  <div className="icon-duree item-icon" />
                                  <p>{convertMinsToHrsMins(product.duration, Translation().cart.time_separator)}</p>
                                </div>
                              )
                              : ''
                            }
                          </Col>
                          <Clearfix/>
                        </div>
                        <div className="item-product">
                          <Col xs={12} sm={8} md={8}>
                            <p className="item-name">{product && product.name ? product.name : ''}</p>
                          </Col>
                          <Col xs={12} sm={4} md={4}>
                            <p className="item-price">{product && product.amount_without_options_formatted ? product.amount_without_options_formatted : ''} {Translation().shop.tax_included}</p>
                          </Col>
                          <Clearfix/>
                          { product && product.options
                            ? product.options.map((option, index) => {
                              return (
                                <div key={index} className="item-option">
                                  <Col xs={6} sm={8} md={8}>
                                    <p className="option-name"><span className="icon-check option-icon"/> {option && option.name ? option.name : ''}</p>
                                  </Col>
                                  <Col xs={6} sm={4} md={4}>
                                    <p className="option-price">{option && option.amount_formatted ? ('+' + option.amount_formatted) : '' } {Translation().shop.tax_included}</p>
                                  </Col>
                                  <Clearfix/>
                                </div>
                              )})
                            : ''
                          }
                        </div>
                        <div className="item-total">
                          <p>{product && product.amount_formatted ? product.amount_formatted : ''} {Translation().shop.tax_included}</p>
                        </div>
                      </div>
                    );
                  })
                }
                <div className="order-total">
                  { orderData && orderData.discounts && orderData.discounts.length > 0 &&
                    <div>
                      <Col xs={6} className="col-text">
                        <p className="small">{Translation().shop.promotion}</p>
                      </Col>
                      <Col xs={6} className="col-price">
                        <p className="total small">{orderData.total_discount_formatted ? orderData.total_discount_formatted : '' }</p>
                      </Col>
                      <Clearfix/>
                    </div>
                  }
                  <Col xs={6} className="col-text">
                    <p className="small">{Translation().shop.total} {Translation().shop.tax_excluded}</p>
                  </Col>
                  <Col xs={6} className="col-price">
                    <p className="total small">{orderData && orderData.total_tax_excluded_formatted ? orderData.total_tax_excluded_formatted : ''}</p>
                  </Col>
                  <Clearfix/>
                  <Col xs={6} className="col-text">
                    <p className="small">{Translation().shop.total} {Translation().shop.vat}</p>
                  </Col>
                  <Col xs={6} className="col-price">
                    <p className="total small">{orderData && orderData.total_tax_formatted ? orderData.total_tax_formatted : ''}</p>
                  </Col>
                  <Clearfix/>
                  <Col xs={6} className="col-text">
                    <p className="large">{Translation().shop.total} {Translation().shop.tax_included} </p>
                  </Col>
                  <Col xs={6} className="col-price">
                    <p className="total large">{orderData && orderData.total_formatted ? orderData.total_formatted : '' }</p>
                  </Col>
                  <Clearfix/>
                </div>
                <div className="after-order">
                  <div className="delivery">
                    <h2>{Translation().shop.validation.after_order} ?</h2>
                    <h3><span className="icon-h delivery-icon"/> {Translation().shop.validation.delivery_content}</h3>
                    <p>
                      {Translation().shop.validation.start_of_description}
                      {this.getOrderDeliveryTime(orderData)}
                      {Translation().shop.validation.end_of_description}                      
                    </p>
                    <a href="/orders" className="app-button-color">{Translation().shop.validation.orders}</a>
                  </div>
                </div>
                <div className="more">
                  <h2>{Translation().shop.validation.more_order} ?</h2>
                  <p>{Translation().shop.validation.more_order_sentence}</p>
                  <a href="/shop" className="app-button-color app-button-dark">{Translation().shop.validation.order}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Validation;
