import Translation from '../../../../../translation/Translation';

const validate = (values) => {
  const errors = {};

  if (!values.address) {
    errors.address = Translation().forms.errors.required;
  }

  if (!values.address_value) {
    errors.address_value = Translation().forms.errors.required;
  }

  if (!values.address_name) {
    errors.address_name = Translation().forms.errors.required;
  }

  if (!values.payment_method) {
    errors.payment_method = Translation().forms.errors.required;
  }

  if (!values.terms_of_sales) {
    errors.terms_of_sales = Translation().forms.errors.required;
  }

  return errors;
};

export default validate;
