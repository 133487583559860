import React, { Component } from 'react';
import bowser from 'bowser';

import { objectMapToArray } from '../../../../../helpers/HelperFunctions';
import { CallApi } from '../../../../../helpers/ApiFunctions';
import {
  createDate,
  dateInTz,
  getCurrentDate,
  addToDate,
  formatDate,
  toUtcTimestamp
} from '../../../../../helpers/DateFunctions';
import { getTimezone } from '../../../../../helpers/UserFunctions';

import Translation from '../../../../../translation/Translation';

class LoadMoreTimes extends Component {

  render() {
    const { isPlanningToggled } = this.props;

    return (
      <div className= {"full-planning " + (isPlanningToggled ? "close" : "") }>
        <i onClick={this.props.togglePlanning}>
          <span className="icon-detail" />
          {Translation().shop.services.planning.see_more}
        </i>
      </div>
    );
  }
}

class Planning extends Component {

  constructor(props) {
    super(props);

    this.state = {
      planning: null,
      isLoading: false,
      isUpdateStartLoading: false,
      nbTimesMax: 0,
      isPlanningToggled: false,
      slotSelected: undefined,
      isRefreshLoading: false,
      startDate: getCurrentDate(null, this.props.timezoneCode),
      minStartDate: getCurrentDate(null, this.props.timezoneCode),
      maxStartDate: addToDate(getCurrentDate(null, this.props.timezoneCode), 3, 'months'),
      nbDays:  7,
      error: null,
      errorUpdate: null
    }

    this.autoRefreshPlanning = this.autoRefreshPlanning.bind(this);
    this.createPlanning = this.createPlanning.bind(this);
    this.handleUpdateStart = this.handleUpdateStart.bind(this);
    this.togglePlanning = this.togglePlanning.bind(this);
    this.loadNextPeriod = this.loadNextPeriod.bind(this);
    this.loadPrevPeriod = this.loadPrevPeriod.bind(this);
    this.selectDate = this.selectDate.bind(this);
    
    this.timeout = undefined;
    this.refreshTimer = 15000;
  }

  componentWillMount() {

    const { userType, orderId, productId } = this.props;

    /**
     * Set the number of days to render in planning
     */
    let mqBasicPhone = false;
    let mqSmallPhone = false;

    if (bowser.name === 'Internet Explorer' && bowser.version < 10) {
      mqBasicPhone = false;
      mqSmallPhone = false;
    }
    else {
      mqBasicPhone = window.matchMedia("(max-width: 767px)");
      mqSmallPhone = window.matchMedia("(max-width: 485px)");
    }

    if (mqBasicPhone.matches) {
      this.setState({ nbDays: 4 });
    }
    if (mqSmallPhone.matches) {
      this.setState({ nbDays: 2 });
    }

    if (productId && orderId) {
      this.setState({ isLoading: true });

      CallApi(
        'get',
        userType + '/self/orders/' + orderId +'/products/' + productId + '/slots',
        null,
        null,
        null,
        (slots) => {
          this.setState({ isLoading: false });
          this.createPlanning(slots);
        },
        error => {
          if (error && error.response && error.response.data && error.response.data.detail) {
            this.setState({ isLoading: false, error: error.response.data.detail });
          }
          else {
            this.setState({ isLoading: false, error: Translation().account.orders.update_start.error });
          }
        }
      );
    }
  }

  componentDidMount() {
    this.autoRefreshPlanning();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  autoRefreshPlanning() {
    const { userType, orderId, productId } = this.props;

    clearTimeout(this.timeout);

    this.timeout = setInterval(() => {

      if (this.state.isRefreshLoading) {
        return;
      }

      this.setState({ isRefreshLoading: true });

      const { startDate } = this.state;

      const endDate = addToDate(startDate, this.state.nbDays, 'days');

      const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
      const endtDateFormated = formatDate(endDate, 'YYYY-MM-DD');

      CallApi(
        'get',
        userType + '/self/orders/' + orderId +'/products/' + productId + '/slots',
        {
          start: startDateFormated,
          end: endtDateFormated
        },
        null,
        null,
        slots => {
          this.createPlanning(slots);
          this.setState({ isRefreshLoading: false });
        },
        () => {
          this.setState({ isRefreshLoading: false });
        }
      );
    }, this.refreshTimer);
  }

  handleUpdateStart() {

    const {
      onError,
      onSuccess,
      orderId,
      productId,
      userType
    } = this.props;
    const { slotSelected } = this.state;

    let typeSelf = userType || "users";

    if (!slotSelected) return;

    this.setState({ isUpdateStartLoading: true });

    CallApi(
      'put',
      typeSelf + "/self/orders/" + orderId + "/products/" + productId + "/start",
      null,
      { start: slotSelected },
      null,
      success => {
        this.setState({ isUpdateStartLoading: false });

        /**
         * Send order product id to google tag manager
         */
        if (window && window.dataLayer) {
          window.dataLayer.push({
            event: "order_product.start.changed",
            order_product_id: success.id,
          });
        }

        if (onSuccess) {
          onSuccess(success);
        }
      },
      error => {
        if (error && error.response && error.response.data && error.response.data.detail) {
          this.setState({ isUpdateStartLoading: false, errorUpdate: error.response.data.detail });
        }
        else {
          this.setState({ isUpdateStartLoading: false, errorUpdate: Translation().account.orders.update_start.error })
        }

        if (onError) {
          onError(error && error.response && error.response.data && error.response.data.detail ? error.response.data.detail : Translation().account.orders.update_start.error);
        }
      }
    );
  }

  createPlanning(slots) {
    const { timezoneCode } = this.props;

    const planning = {};
    let nbTimesMax = 0;

    /**
     * Create planning days
     */
    for (let i = 0; i < this.state.nbDays; i++) {
      const day = addToDate(this.state.startDate, i, 'days', 'YYYY-MM-DD');
      planning[day] = [];
    }

    /**
     * Create slots time and push them to their right day
     */
    for (let j = 0; j < slots.length; j++) {
      const slotDate = formatDate(dateInTz(slots[j], null, timezoneCode), 'YYYY-MM-DD');
      const slotTime = formatDate(dateInTz(slots[j], null, timezoneCode), 'HH:mm');

      if (planning[slotDate]) {
        planning[slotDate].push(slotTime);
      }
    }

    /**
     * Push new planning to component state
     */
    this.setState({ planning });

    /**
     * Calculate the number max of slots in all days
     */
    for (let slotDate in planning) {
      nbTimesMax = Math.max(5, nbTimesMax, planning[slotDate].length);
      this.setState({ nbTimesMax });
    }
  }

  selectDate(time, date) {
    const { timezoneCode } = this.props;

    if (!time || !date) {
      return;
    }

    if (time !== "Invalid date" || date !== "Invalid date") {
      /**
       * Get the date selected from planning
       */
      const slotSelected = createDate(date + " " + time, timezoneCode);
      const slotSelectedUTC = toUtcTimestamp(slotSelected);

      this.setState({ slotSelected: slotSelectedUTC });
    }
  }

  togglePlanning() {
    this.setState({ isPlanningToggled: !this.state.isPlanningToggled });
  }

  loadNextPeriod() {
    this.autoRefreshPlanning();

    const { userType, orderId, productId } = this.props;
    const { minStartDate, maxStartDate} = this.state;

    const startDate = addToDate(this.state.startDate, this.state.nbDays, 'days');
    const endDate = addToDate(startDate, this.state.nbDays, 'days');
    const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
    const endtDateFormated = formatDate(endDate, 'YYYY-MM-DD');
    const minStartDateFormated = formatDate(minStartDate, 'YYYY-MM-DD');
    const maxStartDateFormated = formatDate(maxStartDate, 'YYYY-MM-DD');

    if (startDateFormated < minStartDateFormated || startDateFormated > maxStartDateFormated) {
      return;
    }

    /**
     * Displaying overlay
     */
    const overlay = document.getElementsByClassName('overlay-planning')[0];
    if (overlay) {
      overlay.style.display = 'block';
    }

    this.setState({ startDate });

    /**
     * Load the next week of planning
     */
    CallApi(
      'get',
      userType + '/self/orders/' + orderId +'/products/' + productId + '/slots',
      {
        start: startDateFormated,
        end: endtDateFormated
      },
      null,
      null,
      slots => {
        const overlay = document.getElementsByClassName('overlay-planning')[0];
        if (overlay) {
          overlay.style.display = 'none';
        }

        this.createPlanning(slots);
        this.setState({ isPlanningToggled: false });
      },
      () => {
        const overlay = document.getElementsByClassName('overlay-planning')[0];
        if (overlay) {
          overlay.style.display = 'none';
        }
      }
    );
  }

  loadPrevPeriod() {
    this.autoRefreshPlanning();

    const { userType, orderId, productId } = this.props;
    const { minStartDate, maxStartDate } = this.state;

    const startDate = addToDate(this.state.startDate, this.state.nbDays * -1, 'days');
    const endDate = addToDate(startDate, this.state.nbDays, 'days');
    const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
    const endtDateFormated = formatDate(endDate, 'YYYY-MM-DD');
    const minStartDateFormated = formatDate(minStartDate, 'YYYY-MM-DD');
    const maxStartDateFormated = formatDate(maxStartDate, 'YYYY-MM-DD');

    if (startDateFormated < minStartDateFormated || startDateFormated > maxStartDateFormated) {
      return;
    }

    /**
     * Displaying overlay
     */
    const overlay = document.getElementsByClassName('overlay-planning')[0];
    if (overlay) {
      overlay.style.display = 'block';
    }

    this.setState({ startDate });

    /**
     * Load the previous week of planning
     */
    CallApi(
      'get',
      userType + '/self/orders/' + orderId +'/products/' + productId + '/slots',
      {
        start: startDateFormated,
        end: endtDateFormated
      },
      null,
      null,
      slots => {
        const overlay = document.getElementsByClassName('overlay-planning')[0];
        if (overlay) {
          overlay.style.display = 'none';
        }

        this.createPlanning(slots);
        this.setState({ isPlanningToggled: false });
      },
      () => {
        const overlay = document.getElementsByClassName('overlay-planning')[0];
        if (overlay) {
          overlay.style.display = 'none';
        }
      }
    );
  }

  render() {

    const { timezoneCode } = this.props;

    const {
      isLoading,
      isUpdateStartLoading,
      error,
      planning,
      nbTimesMax,
      isPlanningToggled,
      startDate,
      minStartDate,
      slotSelected,
      maxStartDate,
    } = this.state;

    const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
    const minStartDateFormated = formatDate(minStartDate, 'YYYY-MM-DD');
    const maxStartDateFormated = formatDate(maxStartDate, 'YYYY-MM-DD');

    return (
      <div className="service-planning">
        <div className="overlay-planning">
          <div className="loader-api" />
        </div>
        { !isLoading && timezoneCode && timezoneCode !== getTimezone() &&
          <div className="service-planning-warning">
            <p><span className="icon-infos" />{Translation().shop.services.planning.timezone_diff} ({timezoneCode})</p>
          </div>
        }
        { planning && !error
          ? (
            <div className={"planning-table " + (isPlanningToggled ? "open" : "")}>
              <table>
                <thead>
                  <tr className="planning-head">
                    <th className="planning-day">
                      <div className={"icon-chevron_gauche nav-planning left " + (startDateFormated <= minStartDateFormated ? "unavailable" : "")} onClick={this.loadPrevPeriod}/>
                    </th>
                    { Object.keys(planning).map((date, index) => {
                      return (
                        <th className="planning-day" key={index}>
                        <span className="day-alpha">{formatDate(date, 'dddd')}</span>
                        <span className="day-numeric">{formatDate(date, 'localized-date')}</span>
                        </th>
                      );
                    })
                    }
                    <th className="planning-day">
                      <div className={"icon-chevron_droite nav-planning right " + (startDateFormated >= maxStartDateFormated ? "unavailable" : "")} onClick={this.loadNextPeriod}/>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td />
                    { objectMapToArray(planning, (date, times, index, object) => {
                      let nbEmptyTimes = Math.max(0, nbTimesMax - times.length);
                      let emptySlots = [];
                      /**
                      * Calculte how many slots are empty
                      */
                      for (let g = 0; g < nbEmptyTimes; g++) {
                        emptySlots.push(g);
                      }
                      return (
                        <td key={index} className="planning-hours">
                          { times.map((time, index) => {
                            return (
                              <div key={index} className={"planning-hour " + (slotSelected === toUtcTimestamp(createDate(date + " " + time, timezoneCode)) ? "time-selected" : "")} onClick={this.selectDate.bind(this, time, date)}>
                                { time }
                              </div>
                            );
                          })
                          }
                          {
                            /**
                            * Display all slots empty
                            */
                            emptySlots.map((elem, index) => {
                              return <div key={index} className="empty-slot"><span /></div>
                            })
                          }
                        </td>
                      );
                    })
                    }
                    <td/>
                  </tr>
                </tbody>
              </table>
            </div>
          )
          : error
            ? (
              <div className="planning-error error-message">
                <span className="icon-comment03 planning-error-icon" />
                <p>{error}</p>
              </div>
            )
            : ''
        }
        { nbTimesMax > 6 && <LoadMoreTimes togglePlanning={this.togglePlanning} isPlanningToggled={isPlanningToggled}/> }
        { isLoading &&
          <div className="laoder-wrapper">
            <div className="loader-api" style={{position: "relative"}} />
          </div>
        }
        { !isLoading && !error &&
          <div className="button-wrapper">
            <button
              className={"app-button-color " + ((isUpdateStartLoading || !slotSelected) ? "disabled" : "")}
              disabled={isUpdateStartLoading || !slotSelected}
              onClick={this.handleUpdateStart}
            >
              {isUpdateStartLoading ? <div className="loader-api" style={{display: 'inline-block'}} /> : <span>{Translation().account.orders.update_start.modal.confirm}</span>}
            </button>
          </div>
        }
      </div>
    );
  }
}

export default Planning;
