import React, { Component } from 'react';
import TermsOfUse from '../../components/commons/termsOfUse/TermsOfUse';

import Translation from '../../translation/Translation';

class TermsOfUsePage extends Component {
  render() {
    return (
      <div className="app-legal-notice">
        <div className="page-header-title">
          <h1>{Translation().pages.terms_of_use.title}</h1>
        </div>
        <div className="container">
          <div className="row">
          	<TermsOfUse />
          </div>
        </div>
      </div>
    );
  }
}

export default TermsOfUsePage; 