import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { Toggle } from 'redux-form-material-ui';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import $ from 'jquery';

import store from '../../../redux/store';
import { apiGetUserOrders } from '../../../helpers/AccountFunctions';
import Order from './Order';
import DeliveryStatusFilter from './filters/DeliveryStatusFilter';

import Translation from '../../../translation/Translation';

class Orders extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isCompanyToggled: false,
      orders: null,
      paging: null,
      loading: false,
      ordersRequestLimit: 15,
      scrollBuffer: 1,
      isToggleAvailable: false,
      filters: null,
      deliveryStatusSelected: [],
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.handleCompanyToggle = this.handleCompanyToggle.bind(this);
    this.handleFilterDeliveryStatus = this.handleFilterDeliveryStatus.bind(this);
  }

  componentWillMount() {
    /**
     * Check if an order Id exists in the url and get user order filter.
     * If no id, load all orders.
     */
    const {
      isCompanyAdmin,
      match
    } = this.props

    if (match && match.params && match.params.id) {
      store.dispatch(change('accountOrder', 'companyToggle', ''));
      this.setState({ isCompanyToggled: false });

      this.loadOrders(
        false,
        null,
        { filters: 'id|eq|' + match.params.id }
      );
    }
    else {
      this.setState({ isCompanyToggled: isCompanyAdmin });

      this.loadOrders(isCompanyAdmin, null);
    }

    const handleScrollListener = this.handleScroll;
    window.addEventListener('scroll', handleScrollListener);
  }

  handleScroll() {
    const {
      isCompanyToggled,
      scrollBuffer,
      paging,
    } = this.state;

    if (scrollBuffer === 1) {
      if (($(window).scrollTop() + $(window).height()) + ($('.app-footer').height()) > $(document).height()) {
        if (paging && paging.next) {
          this.loadOrders(isCompanyToggled, paging);
          this.setState({ scrollBuffer: 2 });
        }
      }
    }
  }

  handleCompanyToggle(isCompanyToggled) {

    if (isCompanyToggled === this.state.isCompanyToggled)
      return;

    store.dispatch(change('accountOrder', 'companyToggle', isCompanyToggled ? true : ''));

    this.setState({
      isCompanyToggled: isCompanyToggled ? true : false,
      paging: null,
      orders: [],
      scrollBuffer: 1,
      isToggleAvailable: false,
    });

    this.loadOrders(isCompanyToggled ? true : false, null);
  }

  loadOrders(isCompanyToggled, paging, query = {}) {
    
    const { filters } = this.state;

    const state = store.getState();
    const selector = formValueSelector("accountOrder");
    const searchValue = selector(state, 'searchOrder');
  
    if (!paging || !paging.next) {
      this.setState({ orders: null })
    }

    if (!query.filters && filters) {
      query.filters = filters;
    }

    if (!query.search && searchValue) {
      query.search = searchValue;
    }

    if (!query.limit) {
      query.limit = this.state.ordersRequestLimit
    }

    this.setState({ loading: true });

    apiGetUserOrders(
      paging && paging.next ? null : query,
      paging && paging.next ? paging.next : null,
      isCompanyToggled,
      (successData, paging) => {
        /**
         * Concat the response to the current order array if orders already exists
         * If not pass the first api reponse data
         */
        const orders =  this.state.orders ? this.state.orders.concat(successData) : successData;

        this.setState({
          orders: orders,
          loading: false,
          paging: !paging || !paging.next ? null : paging,
          isToggleAvailable: true,
          scrollBuffer: 1,
        });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  handleFilterDeliveryStatus(ids) {
    const { isCompanyToggled, deliveryStatusSelected } = this.state;

    if (deliveryStatusSelected === ids) return;

    this.setState({ deliveryStatusSelected: ids });

    let deliveryStatus = null;
    let filters = "";

    if (ids.length <= 1 && ids[0]) {
      deliveryStatus = "delivery_status_id|eq|" + ids[0];
    }
    else if (ids.length > 1) {
      deliveryStatus = "delivery_status_id|in|";

      for (let i = 0; i < ids.length; i++) {
        deliveryStatus += ids[i] + "/" 
      }

      deliveryStatus = deliveryStatus.substring(0, deliveryStatus.length - 1);
    }
  
    filters += deliveryStatus;

    this.setState({ filters });
  
    this.loadOrders(
      isCompanyToggled,
      null,
      filters ? { filters } : {}
    );
  }

  render() {

    const {
      deliveryStatusSelected,
      isCompanyToggled,
      isToggleAvailable,
      loading,
      orders
    } = this.state;

    const {
      isCompanyAdmin
    } = this.props;

    /**
     * Toggle switch style
     */
    const toggleStyle = {
      backgroundColor: isCompanyToggled ? "#1a2123" : "#FFD700",
      zIndex: 1
    };

    return (
      <div className="app-orders">
        <div className="app-overlay" id="overlay-order" />
        <div className="orders-header">
            <Col xs={12} sm={4} md={3} className="col-filters">
              {
                /*
                  <div className="filter"> Date la plus proche <span className="filter-icon icon-chevron_bas" /> </div>
                */
              }
            </Col>
          <Col xs={12} sm={4} md={6}>
            <h1>{Translation().account.orders.page_title}</h1>
          </Col>
          <Col xs={12} sm={4} md={3} className="col-search">
            <div className="search-ord-wrapper">
              <Field
                name="searchOrder"
                type="search"
                component="input"
                placeholder={Translation().account.orders.search_input}
                className="app-basic-input"
                onKeyPress={(e) => {
                  // Block form send on enter key press
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.loadOrders(
                      isCompanyToggled,
                      null,
                      e.target && e.target.value ? { search:  e.target.value } : {}
                    );
                  }}
                }
              />
              <div className="search-ord-submit">
                <span className="send-icon icon-search"/>
              </div>
            </div>
            <DeliveryStatusFilter
              onSelect={this.handleFilterDeliveryStatus}
              disabled={loading}
              filtered={deliveryStatusSelected}
            />
          </Col>
          <Clearfix/>
        </div>
        <div className="orders-container">
          { isCompanyAdmin &&
            <div className="toggle-company">
              <span
                className={"text-ut left " + (isCompanyToggled ? "" : "active")}
                disabled={!isToggleAvailable}
                onClick={() => {
                  this.handleCompanyToggle(false);
                }}>
                {Translation().account.orders.company_toggle.personal}
              </span>
              <Field
                type="checkbox"
                component={Toggle}
                className="toggle-ut"
                thumbStyle={toggleStyle}
                thumbSwitchedStyle={toggleStyle}
                name="companyToggle"
                disabled={!isToggleAvailable}
                onChange={(event, value) => {
                  this.handleCompanyToggle(value);
                }}
              />
              <span
                className={"text-ut right " + (isCompanyToggled ? "active" : "")}
                disabled={!isToggleAvailable}
                onClick={() => {
                  this.handleCompanyToggle(true);
                }}>
                {Translation().account.orders.company_toggle.pro}
              </span>
            </div>
          }
          <div id="orders-wrapper">
            { orders && orders.length > 0
              ? ( <ul>
                    { orders.map((order, index) => {
                        if (!order.cancelled) {
                          return (
                            <Order key={index} order={order} userType={isCompanyToggled ? "companies" : "users"} />
                          );
                        }
                        else return '';
                      })
                    }
                    <Clearfix/>
                  </ul>
                )
              : !loading
                ? (
                  <div className="no-order">
                    <p className="info">{Translation().account.orders.no_order}</p>
                    <a className="app-button-color" href="/shop">{Translation().account.orders.order_btn}</a>
                  </div>
                )
                : ''
            }
          </div>
          <div id="orders-loader-wpr" className="orders-loader load-first-orders " style={loading ? {display: "block"} : {display: "none"}}>
            <div className="loader-api" />
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    const handleScrollListener = this.handleScroll;
    window.removeEventListener('scroll', handleScrollListener);
  }
}

export default reduxForm({
  form: 'accountOrder',
})(Orders);
