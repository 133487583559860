import Translation from '../../../../../translation/Translation';

const validate = (values) => {
  const errors = {};

  if (!values.last_name) {
    errors.last_name = Translation().forms.errors.required;
  }

  if (!values.first_name) {
    errors.first_name = Translation().forms.errors.required;
  }

  if (!values.mobile) {
    errors.mobile = Translation().forms.errors.required;
  }

  if (!values.address) {
    errors.address = Translation().forms.errors.required;
  }

  return errors;
};

export default validate;
