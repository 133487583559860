import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import { getLocale } from '../../../helpers/UserFunctions';

class GoogleAddressStringField extends Component {

  render() {
    const {
      input,
      name,
      label,
      className,
      id,
      onSelect,
      meta: { touched, error }
    } = this.props;

    const cssClasses = {
      input: touched && error ? (className + " field-get-error") : className,
      autocompleteContainer: 'geo-suggestion-container',
    }

    const inputProps = {
      onChange: input.onChange,
      onBlur: input.onBlur,
      onFocus: input.onFocus,
      type: "text",
      value: input.value,
      autoFocus: true,
      placeholder: label,
      name: name,
      id: id,
      required: true
    }

    const options = {
      PlaceReview: {
        language: getLocale()
      },
      types: ['address']
    }

    const AutocompleteItem = ({ formattedSuggestion }) => (
      <div className="geo-suggestion-item">
        <span className="icon-comment01 place-icon" />
        <strong>{formattedSuggestion.mainText}</strong>{' '}
        <small className="text-muted">{formattedSuggestion.secondaryText}</small>
      </div>
    )

    return (
      <div>
        <PlacesAutocomplete
          inputProps={inputProps}
          onSelect={onSelect}
          classNames={cssClasses}
          autocompleteItem={AutocompleteItem}
          options={options}
        />
        {touched && error && <span className="error-message error-field">{error}</span>}
      </div>
    );
  }
}

export default GoogleAddressStringField;
