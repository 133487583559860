import React, { Component }  from 'react';
import Dropzone from 'react-dropzone';
import { Field, change } from 'redux-form';
import { Toggle } from 'redux-form-material-ui';

import { apiPostFile } from '../../../../../helpers/ApiFunctions';

import renderFieldLabel from '../../../../../components/commons/fields/renderFieldLabel';
import renderTextareaField from '../../../../../components/commons/fields/renderTextareaField';

import Config from '../../../../../config/Config';
import Translation from '../../../../../translation/Translation';
import store from '../../../../../redux/store';

const required = value => value ? undefined : Translation().forms.errors.required;

const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? Translation().forms.errors.email_invalid : undefined;

class ValidatingField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uploadFileInProgress: false,
      fileError: null,
      fileLimit: 1,
      filesArray: [],
    }

    this.onDropFile = this.onDropFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }

  onDropFile(filesAccepted, filesRejected) {

    const {
      field,
      formName
    } = this.props;

    this.setState({ filesAccepted, filesRejected });

    if (filesAccepted) {
      for (let i = 0; i < filesAccepted.length; i++) {
        if ((filesAccepted.length) <= this.state.fileLimit) {
          this.setState({ uploadFileInProgress: filesAccepted.length });

          apiPostFile(
            filesAccepted[i],
            (successData) => {
              const newFile = successData.data && successData.data ? successData.data : '';
              const newFilesArray = [newFile];

              this.setState({
                filesArray: newFilesArray,
                fileError: '',
                uploadFileInProgress: this.state.uploadFileInProgress - 1
              });

              store.dispatch(change(formName, "validating_fields." + field.name, newFile.url));
            },
            (error) => {
              if (error.response && error.response.data && error.response.data.detail) {
                this.setState({
                  fileError: error.response.data.detail,
                  uploadFileInProgress: this.state.uploadFileInProgress - 1
                });
              }
            }
          );
        }
        else {
          this.setState({ fileError: Translation().shop.validating_fields.files.file_limit + this.state.fileLimit });
          return;
        }
      }
    }

    if (filesRejected && filesRejected.length > 0) {
      let fileError = Translation().shop.validating_fields.files.files;

      for (let j = 0; j < filesRejected.length; j++) {
        if (filesRejected[j].name)
          fileError += (filesRejected[j].name + ", ");
      }

      fileError += Translation().shop.validating_fields.files.files_refused;

      this.setState({ fileError });
    }
  }

  deleteFile() {

    this.setState({
      filesArray: [],
      fileError: null,
    });

    store.dispatch(change(this.props.formName, "validating_fields." + this.props.field.name, ''));
  }

  render() {

    const {
      uploadFileInProgress,
      filesArray,
      fileError
    } = this.state;

    const {
      field,
      disabled
    } = this.props;

    let dropzoneRef;

    field.required = true;

    if (field.type === "boolean") {
      return <div className="app-md-space">
                <Field
                  name={"validating_fields." + field.name}
                  component={Toggle}
                  disabled={disabled}
                  label={field.required ? field.label + "*" : field.label}
                  validate={field.required ? [required] : []}
                  labelPosition="left"
                  style={{width: 'auto'}}
                  labelStyle={{width: 'auto', color: '#0000004d', fontSize: '16px'}}
                />
            </div>
    }
    else if (field.choices && field.choices.length) {
      return (
        <span>
          <label>{field.required ? field.label + "*" : field.label}</label>
          <Field
            name={"validating_fields." + field.name}
            component="select"
            validate={field.required ? [required] : []}
            disabled={disabled}
            hintText={field.label}
            className="app-basic-input"
          >
            {
              field.choices.map((choice, j) =>{
                return(
                  <option key={j} value={choice}>choice</option>
                )
              })
            }
          </Field>
        </span>
      )
    }
    else if (field.type === "collection" || field.type === "entity") {
      return false;
    }
    else if (field.type === "array") {
      return (
        <span>
          <label>{field.required ? field.label + "*" : field.label}</label>
          <Field
            name={"validating_fields." + field.name}
            multiline={true}
            rows={3}
            className="app-basic-input"
            component={renderTextareaField}
          />
        </span>
      )
    }
    else if (field.type === "text") {
      return (
        <span>
          <label>{field.required ? field.label + "*" : field.label}</label>
          <Field
           name={"validating_fields." + field.name}
           type="textarea"
           component={renderTextareaField}
           disabled={disabled}
           validate={field.required ? [required] : []}
           rows={2}
           className="app-basic-input"
         />
        </span>
      )
    }
    else if (field.type === "password") {
        return <Field
                  name={"validating_fields." + field.name}
                  type="password"
                  component={renderFieldLabel}
                  className="app-basic-input"
                  autoComplete="new-password"
                  label={field.required ? field.label + "*" : field.label}
                  validate={field.required ? [required] : []}
                  disabled={disabled}
                />
    }
    else if (field.input_type === 'file' && field.type === "file") {
      return (
          <div className="inner-field">
            <div className="upld-wrapper">
              <label>{field.required ? field.label + '*' : field.label}</label>
              {
                !uploadFileInProgress
                  ? (
                    filesArray && filesArray.length > 0
                      ? filesArray.map((file, index) => {
                          if (file.thumbnail_url) {
                            return (
                                <img
                                  key={index}
                                  className="file-img-prev"
                                  alt="file.thumbnail_url"
                                  src={file.thumbnail_url + "?client_id=" + Config.api_key}
                                />
                            )
                          }
                          else if (file.extension) {
                            const fileStyle = {
                              backgroundImage: 'url("/assets/images/medias/file-extension-' + file.extension + '.png")',
                              border: "1px solid #c6c7c9",
                              backgroundSize: "cover"
                            }

                            return (
                              <div className="file-preview" style={fileStyle} key={index} />
                            )
                          }
                          else return false;
                      })
                      : ''
                    )
                  : (
                    <div className="loader-api file-loading"/>
                  )
              }
              <Field
                component="input"
                className="hidden-input"
                name={"validating_fields." + field.name}
                type="hidden"
                label={field.required ? field.label + '*' : field.label}
                validate={field.required ? [required] : []}
              />
              <Dropzone
                ref={(node) => { dropzoneRef = node; }}
                onDrop={(accepted, rejected) => { this.onDropFile(accepted, rejected); }}
                disableClick={true}
                className="field-dropper"
                activeClassName="dropper-ondrag"
                accept=
                  "application/pdf,
                  text/csv,
                  text/plain,
                  text/xml,
                  application/zip,
                  application/xml,
                  application/xhtml+xml,
                  application/vnd.ms-excel,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                  application/vnd.oasis.opendocument.text,
                  video/mpeg
                  audio/x-mpeg-3,
                  audio/mpeg3,
                  audio/mp3,
                  video/mp4,
                  image/jpeg,
                  image/png,
                  image/gif"
              >
                {
                  !uploadFileInProgress
                    ? (
                        <div className="drop-it">
                          <div className="app-button-color drop-btn" onClick={() => { dropzoneRef.open() }}>
                            <span className="icon-upload" />
                            {Translation().shop.validating_fields.buttons.upload}
                          </div>
                          { filesArray && filesArray.length > 0 &&
                            <div className="delete-media" onClick={this.deleteFile}>
                              <span className="icon-delete" />
                              {Translation().shop.validating_fields.buttons.delete}
                            </div>
                          }
                        </div>
                      )
                    : ''
                }
              </Dropzone>
              { fileError && <p className="error-message">{fileError}</p> }
          </div>
        </div>
      )
    }
    else if (field.input_type === 'file' && field.type === "image") {
      return (
        <div className="inner-field">
          <div className="upld-wrapper">
            <label>{field.required ? field.label + '*' : field.label}</label>
            <Field
              component="input"
              className="hidden-input"
              name={"validating_fields." + field.name}
              type="hidden"
              validate={field.required ? [required] : []}
            />
            { fileError && <p className="error-message">{fileError}</p> }
            {
              !uploadFileInProgress
                ? (
                  <div>
                    {
                      filesArray && filesArray.length > 0 ? filesArray.map((file, index) => {
                        return (
                          <img
                            key={index}
                            className="file-img-prev"
                            alt="file.thumbnail_url"
                            src={file.thumbnail_url}
                          />
                        )
                      })
                      : ''
                    }
                  </div>
                )
                : (
                  <div className="loader-api file-loading"/>
                )
            }
            <Dropzone
              ref={(node) => { dropzoneRef = node; }}
              onDrop={(accepted, rejected) => { this.onDropFile(accepted, rejected); }}
              disableClick={true}
              className="field-dropper"
              accept="image/jpeg, image/png, image/gif"
              activeClassName="dropper-ondrag">
                {
                  !uploadFileInProgress
                    ? (
                        <div className="drop-it">
                          <div className="app-button-color drop-btn" onClick={() => { dropzoneRef.open() }}>
                            <span className="icon-upload" />
                            {Translation().account.company.forms.company_infos.buttons.upload}
                          </div>
                          { filesArray && filesArray.length > 0 &&
                            <div className="delete-media" onClick={this.deleteFile}>
                              <span className="icon-delete" />
                              {Translation().account.company.forms.company_infos.buttons.delete}
                            </div>
                          }
                        </div>
                      )
                    : ''
                }
                </Dropzone>
              </div>
            </div>
      )
    }
    else {
      if (field.type === "integer" || field.type === "float" || field.type === "decimal") {
        return <Field
                  name={"validating_fields." + field.name}
                  type="number"
                  component={renderFieldLabel}
                  label={field.required ? field.label + '*' : field.label}
                  validate={field.required ? [required] : []}
                  disabled={disabled}
                  className="app-basic-input"
                />
      }
      else if (field.type === "url") {
        return <Field
                  name={"validating_fields." + field.name}
                  type="url"
                  component={renderFieldLabel}
                  label={field.required ? field.label + '*' : field.label}
                  validate={field.required ? [required] : []}
                  disabled={disabled}
                  className="app-basic-input"
                />
      }
      else if (field.type === "email") {
        return <Field
                  name={"validating_fields." + field.name}
                  type="email"
                  component={renderFieldLabel}
                  label={field.required ? field.label + '*' : field.label}
                  validate={field.required ? [required, email] : [email]}
                  disabled={disabled}
                  autoComplete="nope"
                  className="app-basic-input"
                />
      }
      else if (field.type === "phone") {
        return <Field
                  name={"validating_fields." + field.name}
                  type="phone"
                  component={renderFieldLabel}
                  label={field.required ? field.label + '*' : field.label}
                  validate={field.required ? [required] : []}
                  disabled={disabled}
                  autoComplete="nope"
                  className="app-basic-input"
                />
      }
      else {
        return <Field
                  name={"validating_fields." + field.name}
                  type="text"
                  component={renderFieldLabel}
                  label={field.required ? field.label + '*' : field.label}
                  validate={field.required ? [required] : []}
                  disabled={disabled}
                  className="app-basic-input"
                  autoComplete="nope"
                />
      }
    }
  }
}

export default ValidatingField;
