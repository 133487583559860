import React, { Component } from 'react';
import { stopSubmit } from 'redux-form';

import store from '../../../redux/store';

import Snackbar from '../../commons/snackbar/Snackbar';
import {
  apiPutCompany,
  setCompany,
  invitesUserCompany,
  unlinkUserCompany
} from '../../../helpers/UserFunctions';
import { autoScrollTop, scrollToAnchor } from '../../../helpers/JqueryFunctions';
import CompanyForm from './CompanyForm';
import CompanyMembers from './CompanyMembers';

import Translation from '../../../translation/Translation';

class Company extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isInfosOpen: true,
      isMembersOpen: false,
      autoHideDuration: 4000,
      isSnackbarOpen: false,
      snackbarMessage: '',
      snackbarStatus: '',
      isAddMemberRequestLoading: false,
      isUnlinkMemberRequestLoading: false,
      closeAddMemberModal: false
    }

    this.openInfosContainer = this.openInfosContainer.bind(this);
    this.openMembersContainer = this.openMembersContainer.bind(this);
    this.editCompanyInfos = this.editCompanyInfos.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.addMembers = this.addMembers.bind(this);
    this.unlinkMember = this.unlinkMember.bind(this);
  }

  componentDidMount() {
    autoScrollTop();
  }

  openSnackbar() {
    this.setState({ isSnackbarOpen: true });
  };

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  };

  openInfosContainer() {
    this.setState({ isMembersOpen: false, isInfosOpen: true });
  }

  openMembersContainer() {
    this.setState({ isMembersOpen: true, isInfosOpen: false });
  }

  editCompanyInfos(values) {

    if (!values) {
      return;
    }
    if (!values.phone) {
      values.phone = null;
    }
    if (!values.fax) {
      values.fax = null;
    }
    if (!values.email) {
      values.email = null;
    }

    if (!values.website_url)
      values.website_url = null;
    else
      if (values.website_url.indexOf("http") === -1)
        values.website_url = "http://" + values.website_url;

    if (!values.logo_url) {
      values.logo_url = null;
    }
    if (!values.watermark_url) {
      values.watermark_url = null;
    }

    const btnApiLoader = document.getElementById('edit-company-loader');
    const btnText = document.getElementById('edit-company-txt');
    const successMessage = Translation().account.company.forms.company_infos.callbacks.success;
    const errorMessage = Translation().account.company.forms.company_infos.callbacks.error;

    if (btnApiLoader && btnText) {
      btnText.style.display = "none";
      btnApiLoader.style.display = "block";
    }

    apiPutCompany(
      values,
      (successData) => {
        if (btnApiLoader && btnText) {
          btnText.style.display = "block";
          btnApiLoader.style.display = "none";
        }
        /**
         * Update company data into redux store
         */
        setCompany(values);
        this.setState({ snackbarMessage: successMessage, snackbarStatus: "success"});
        this.openSnackbar();
      },
      (error) => {

        if (btnApiLoader && btnText) {
          btnText.style.display = "block";
          btnApiLoader.style.display = "none";
        }

        if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          const validationErrors = {};
          const errorsArray = error.response.data.errors;
          const scrollAnchor = document.getElementsByName(errorsArray[0].propertyPath);

          if (scrollAnchor[0])
            scrollToAnchor(scrollAnchor[0])

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath)
              validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
          }

          store.dispatch(stopSubmit(
            'editCompany',
            validationErrors
          ));
        }

        this.openSnackbar();
        this.setState({ snackbarMessage: errorMessage, snackbarStatus: "error"});
      }
    );
  }

  addMembers(values) {

    if (!values) {
      return;
    }

    let emailsArray = [];

    for (let value in values) {
      if (values[value]) {
        emailsArray.push(values[value]);
      }
    }

    if (emailsArray && emailsArray.length) {
      this.setState({ isAddMemberRequestLoading: true });

      invitesUserCompany(
        { emails: emailsArray },
        (successData) => {
          let successMessage = Translation().account.company.forms.members.callbacks.link.success;
          /*
          if (emailsArray.length > 1)
            successMessage = "Les invitations ont bien été envoyées."
          */

          this.setState({
            snackbarMessage: successMessage,
            snackbarStatus: "success",
            isAddMemberRequestLoading: false
          });

          this.openSnackbar();
          this.companymembers.closeAddMemberModal();
        },
        (error) => {
          let errorMessage = Translation().account.company.forms.members.callbacks.link.error;

          if (error.response && error.response.data && error.response.data.errors) {
            errorMessage = errorMessage + "\n";

            for (let i = 0; i < error.response.data.errors.length; i++) {
              if (error.response.data.errors[i].message) {
                errorMessage += (error.response.data.errors[i].message + "\n");
              }
            }
          }

          this.setState({ snackbarMessage: errorMessage, snackbarStatus: "error", isAddMemberRequestLoading: false});
          this.openSnackbar();
        }
      );
    }
  }

  unlinkMember(member) {
    if (!member || !member.id) {
      return;
    }

    this.setState({ isUnlinkMemberRequestLoading: true });

    unlinkUserCompany(
      member.id,
      (successData) => {
        let successMessage = Translation().account.company.forms.members.callbacks.unlink.success;

        this.setState({
          snackbarMessage: successMessage,
          snackbarStatus: "success",
          isUnlinkMemberRequestLoading: false
        });

        this.openSnackbar();
        this.companymembers.closeRemoveMemberModal();
        this.companymembers.reloadUsersCompany();
      },
      (error) => {
        this.setState({ snackbarMessage: Translation().account.company.forms.members.callbacks.unlink.error, snackbarStatus: "error", isUnlinkMemberRequestLoading: false});
        this.openSnackbar();
      }
    );
  }

  render() {

    const {
      isInfosOpen,
      isMembersOpen,
      isAddMemberRequestLoading,
      isUnlinkMemberRequestLoading,
      closeAddMemberModal
    } = this.state;

    return (
      <div className="app-account app-company">
        <div className="account-header desktop">
          <div className={"tab-header " + (isInfosOpen ? "active" : "")} onClick={this.openInfosContainer}>
            <h1>{Translation().account.company.page_titles.company_infos}</h1>
          </div>
          <div className={"tab-header " + (isMembersOpen ? "active" : "")} onClick={this.openMembersContainer}>
            <h1>{Translation().account.company.page_titles.members}</h1>
          </div>
        </div>
        <div className="account-header mobile">
          <div className={"tab-header " + (isInfosOpen ? "active" : "")} onClick={this.openInfosContainer}>
            <div className="icon-pro" />
          </div>
          <div className={"tab-header " + (isMembersOpen ? "active" : "")} onClick={this.openMembersContainer}>
            <div className="icon-ajout_membre" />
          </div>
        </div>
        <div className="container">
          { isInfosOpen &&
            <div className="edit-company">
              <div className="account-form">
                <CompanyForm onSubmit={this.editCompanyInfos} />
                <div form="editCompany" />
              </div>
            </div>
          }
          { isMembersOpen &&
            <div className="edit-company-members">
              <CompanyMembers
                onSubmit={this.addMembers}
                unlinkMember={this.unlinkMember}
                onRef={ref => (this.companymembers = ref)}
                closeAddMemberModal={closeAddMemberModal}
                isAddMemberRequestLoading={isAddMemberRequestLoading}
                isUnlinkMemberRequestLoading={isUnlinkMemberRequestLoading}
              />
              <div form="companyMembers" />
            </div>
          }
          <Snackbar
            messageText={this.state.snackbarMessage}
            statusClass={this.state.snackbarStatus}
            open={this.state.isSnackbarOpen}
            autoHideDuration={this.state.autoHideDuration}
            closeFunction={this.closeSnackbar}
          />
        </div>
      </div>
    );
  }
}

export default Company;
