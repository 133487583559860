import React, { Component } from 'react';

import Translation from '../../translation/Translation';

class NotFound extends Component {

  render() {

    return (
      <div className="app-not-found">
        <div className="container">
          <div className="row">
            <img src="/assets/images/commons/link-not-found.png" alt="404" />
            <h1>{Translation().pages.not_found.page_title}</h1>
            <h2>{Translation().pages.not_found.baseline}</h2>
            <p>{Translation().pages.not_found.text}.</p>
            <a href="/" className="app-button-color app-button-light"><span className="icon-chevron_gauche"></span> {Translation().pages.not_found.button}</a>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
