import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import Dropzone from 'react-dropzone';
import { Col, Clearfix } from 'react-bootstrap';

import store from '../../../redux/store';
import Config from '../../../config/Config';
import { apiPostFile } from '../../../helpers/ApiFunctions';
import { initCompanyInfos } from '../../../helpers/AccountFunctions';
import renderFieldLabel from '../../commons/fields/renderFieldLabel';
import PhoneField from '../../commons/fields/PhoneField';
import validate from './validateCompany';

import Translation from '../../../translation/Translation';

class CompanyForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filesAccepted: null,
      filesRejected: null,
      uploadInProgressLogo: 0,
      uploadInProgressWatermark: 0,
      filesArrayLogo: [],
      filesArrayWatermark: [],
      fileLimitLogo: 1,
      fileLimitWatermark: 1,
      fileErrorLogo: '',
      fileErrorWatermark: '',
    }

    this.onDropLogo = this.onDropLogo.bind(this);
    this.onDropWatermark = this.onDropWatermark.bind(this);
    this.deleteCompanyMedia = this.deleteCompanyMedia.bind(this);
  }

  componentDidMount() {
    /**
     * Init logos images if exists in user company object
     */
    const companyData = initCompanyInfos();

    if (companyData && companyData.logo_url) {
      this.setState({ filesArrayLogo: [{ thumbnail_url: companyData.logo_url + "?client_id=" + Config.api_key }] });
    }
    if (companyData && companyData.watermark_url) {
      this.setState({ filesArrayWatermark: [{ thumbnail_url: companyData.watermark_url + "?client_id=" + Config.api_key }] });
    }
  }

  onDropLogo(filesAccepted, filesRejected) {

    this.setState({ filesAccepted, filesRejected });

    /*
     * Post to api file one by one
     * Get the url of response data then add it to file url array
     */
    if (filesAccepted) {
      for (let i = 0; i < filesAccepted.length; i++) {
        /**
         * Check the quantity of files in dropper
         */
        if ((filesAccepted.length) <= this.state.fileLimitLogo) {
          /**
           * Increment the number of file in upload progress
           */
          this.setState({ uploadInProgressLogo: filesAccepted.length });

          /**
           * Post the file to the API
           */
          apiPostFile(
            filesAccepted[i],
            (successData) => {
              const newFile = successData.data && successData.data ? successData.data : '';
              const newFilesArrayLogo = [newFile];

              this.setState({
                filesArrayLogo: newFilesArrayLogo,
                fileErrorLogo: '',
                uploadInProgressLogo: this.state.uploadInProgressLogo - 1
              });

              store.dispatch(change('editCompany', 'logo_url', newFile.url));
            },
            (error) => {
              if (error.response && error.response.data && error.response.data.detail) {
                /**
                 * Error: decrement the nb of file not uploaded
                 * Display api error message
                 */
                this.setState({
                  fileErrorLogo: error.response.data.detail,
                  uploadInProgressLogo: this.state.uploadInProgressLogo - 1
                });
              }
            }
          );
        }
        else {
          this.setState({ fileErrorLogo: Translation().account.company.forms.company_infos.validation.file_limit + this.state.fileLimitLogo });
          return;
        }
      }
    }

    /*
     * Handling files rejected
     */
    if (filesRejected && filesRejected.length > 0) {
      let fileErrorLogo = Translation().account.company.forms.company_infos.validation.files;

      for (let j = 0; j < filesRejected.length; j++) {
        if (filesRejected[j].name) {
          fileErrorLogo += (filesRejected[j].name + ", ");
        }
      }
      fileErrorLogo += Translation().account.company.forms.company_infos.validation.files_refused;

      this.setState({ fileErrorLogo });
    }
  }

  onDropWatermark(filesAccepted, filesRejected) {

    this.setState({ filesAccepted, filesRejected });

    /*
     * Post to api file one by one
     * Get the url of response data then add it to file url array
     */
    if (filesAccepted) {
      for (let i = 0; i < filesAccepted.length; i++) {
        /**
         * Check the quantity of files in dropper
         */
        if ((filesAccepted.length) <= this.state.fileLimitWatermark) {
          /**
           * Increment the number of file in upload progress
           */
          this.setState({ uploadInProgressWatermark: filesAccepted.length });

          /**
           * Post the file to the API
           */
          apiPostFile(
            filesAccepted[i],
            (successData) => {
              const newFile = successData.data && successData.data ? successData.data : '';
              const newFilesArrayWatermark = [newFile];

              this.setState({
                filesArrayWatermark: newFilesArrayWatermark,
                fileErrorWatermark: '',
                uploadInProgressWatermark: this.state.uploadInProgressWatermark - 1
              });

              store.dispatch(change('editCompany', 'watermark_url', newFile.url));
            },
            (error) => {
              if (error.response && error.response.data && error.response.data.detail) {
                /**
                 * Error: decrement the nb of file not uploaded
                 * Display api error message
                 */
                this.setState({
                  fileErrorWatermark: error.response.data.detail,
                  uploadInProgressWatermark: this.state.uploadInProgressWatermark - 1
                });
              }
            }
          );
        }
        else {
          this.setState({ fileErrorWatermark: Translation().account.company.forms.company_infos.validation.file_limit + this.state.fileLimitWatermark });
          return;
        }
      }
    }

    /*
     * Handling files rejected
     */
    if (filesRejected && filesRejected.length > 0) {
      let fileErrorWatermark = Translation().account.company.forms.company_infos.validation.files;

      for (let j = 0; j < filesRejected.length; j++) {
        if (filesRejected[j].name) {
          fileErrorWatermark += (filesRejected[j].name + ", ");
        }
      }
      fileErrorWatermark += Translation().account.company.forms.company_infos.validation.files_refused;

      this.setState({ fileErrorWatermark });
    }
  }

  deleteCompanyMedia(media) {
    if (media === "logo_url") {
      this.setState({ filesArrayLogo: [] });
      store.dispatch(change('editCompany', 'logo_url', ''));
    }
    else if (media === "watermark_url") {
      this.setState({ filesArrayWatermark: [] });
      store.dispatch(change('editCompany', 'watermark_url', ''));
    }
  }

  render() {
    /**
     * Copy dropzone click for isolate it to the button
     * Then disable the dropzone click for more usability of sortable component
     */
    let dropzoneLogoRef, dropzoneWatermarkRef;

    const { handleSubmit } = this.props;

    const {
      filesArrayLogo,
      uploadInProgressLogo,
      fileErrorLogo,
      uploadInProgressWatermark,
      fileErrorWatermark,
      filesArrayWatermark
    } = this.state;

    return (
      <form onSubmit={handleSubmit} className="edit-account-form" ref={initCompanyInfos}>
        <style dangerouslySetInnerHTML={{__html: `
          .company-dropper input[type=file] {
            display: none
          }
        `}} />
        <div className="form-cel">
          <Field
            name="legal_name"
            id="legalName"
            type="text"
            component={renderFieldLabel}
            className="app-basic-input"
            label={Translation().account.company.forms.company_infos.labels.legal_name}
          />
        </div>
        <div className="form-cel">
          <Field
            name="commercial_name"
            id="commercialName"
            type="text"
            component={renderFieldLabel}
            className="app-basic-input"
            label={Translation().account.company.forms.company_infos.labels.commercial_name}
          />
        </div>
        <div className="form-cel">
          <Field
            name="phone"
            type="text"
            id="phone"
            label={Translation().account.company.forms.company_infos.labels.phone}
            component={PhoneField}
            className="app-basic-input"
          />
        </div>
        <div className="form-cel">
          <Field
            name="fax"
            type="text"
            id="fax"
            label={Translation().account.company.forms.company_infos.labels.fax}
            component={PhoneField}
            className="app-basic-input"
          />
        </div>
        <div className="form-cel">
          <Field
            name="email"
            type="email"
            id="email"
            label={Translation().account.company.forms.company_infos.labels.email}
            component={renderFieldLabel}
            className="app-basic-input"
          />
        </div>
        <div className="form-cel">
          <Field
            name="website_url"
            type="text"
            id="websiteUrl"
            label={Translation().account.company.forms.company_infos.labels.web_site}
            component={renderFieldLabel}
            className="app-basic-input"
          />
        </div>
        <div className="form-cel row">
          <Col xs={12} md={6}>
            <div className="upld-wrapper">
              <label className="no-cursor">{Translation().account.company.forms.company_infos.labels.company_logo}</label>
              { fileErrorLogo && <p className="error-message">{fileErrorLogo}</p> }
              { !uploadInProgressLogo
                ? (
                    <span>
                      { filesArrayLogo && filesArrayLogo.length > 0 ? filesArrayLogo.map((file, index) => {
                          return (
                            <div className="company-logo" style={{backgroundImage: ('url(' + file.thumbnail_url + ')') }} key={index} />
                          )
                        })
                        : ''
                      }
                    </span>
                  )
                : (
                    <div className="company-logo upld-inp">
                      <div className="loader-api" />
                    </div>
                  )
              }
              <Dropzone
                ref={(node) => { dropzoneLogoRef = node; }}
                onDrop={(accepted, rejected) => { this.onDropLogo(accepted, rejected); }}
                disableClick={true}
                name="companyLogo"
                className="company-dropper"
                accept="image/jpeg, image/png, image/gif"
                activeClassName="dropper-ondrag">
                { !uploadInProgressLogo
                  ? (
                      <div className="drop-it">
                        <div className="app-button-color drop-btn" onClick={() => { dropzoneLogoRef.open() }}>
                          <span className="icon-upload" />
                          {Translation().account.company.forms.company_infos.buttons.upload}
                        </div>
                        { filesArrayLogo && filesArrayLogo.length > 0 &&
                          <div className="delete-media" onClick={this.deleteCompanyMedia.bind(this, 'logo_url')}>
                            <span className="icon-delete" />
                            {Translation().account.company.forms.company_infos.buttons.delete}
                          </div>
                        }
                      </div>
                    )
                  : ''
                }
              </Dropzone>
              <Field
                name="logo_url"
                type="hidden"
                component="input"
                className="hidden-input"
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="upld-wrapper">
              <label className="no-cursor">{Translation().account.company.forms.company_infos.labels.watermark}</label>
              { fileErrorWatermark && <p className="error-message">{fileErrorWatermark}</p> }
              { !uploadInProgressWatermark
                ? (
                    <span>
                      { filesArrayWatermark && filesArrayWatermark.length > 0 ? filesArrayWatermark.map((file, index) => {
                          return (
                            <div className="company-logo" style={{backgroundImage: ('url(' + file.thumbnail_url + ')') }} key={index} />
                          )
                        })
                        : ''
                      }
                    </span>
                  )
                : (
                    <div className="company-logo upld-inp">
                      <div className="loader-api" />
                    </div>
                  )
              }
              <Dropzone
                ref={(node) => { dropzoneWatermarkRef = node; }}
                onDrop={(accepted, rejected) => { this.onDropWatermark(accepted, rejected); }}
                disableClick={true}
                name="watermarkLogo"
                className="company-dropper"
                accept="image/jpeg, image/png, image/gif"
                activeClassName="dropper-ondrag">
                { !uploadInProgressWatermark
                  ? (
                      <div className="drop-it">
                        <div className="app-button-color drop-btn" onClick={() => { dropzoneWatermarkRef.open() }}>
                          <span className="icon-upload" />
                          {Translation().account.company.forms.company_infos.buttons.upload}
                        </div>
                        { filesArrayWatermark && filesArrayWatermark.length > 0 &&
                          <div className="delete-media" onClick={this.deleteCompanyMedia.bind(this, 'watermark_url')}>
                            <span className="icon-delete" />
                            {Translation().account.company.forms.company_infos.buttons.delete}
                          </div>
                        }
                      </div>
                    )
                  : ''
                }
              </Dropzone>
              <Field
                name="watermark_url"
                type="hidden"
                component="input"
                className="hidden-input"
              />
            </div>
          </Col>
          <Clearfix/>
        </div>
        <button type="submit" className="app-button-color app-button-black button-api btn-edit">
          <span id="edit-company-loader" className="loader-api" />
          <span id="edit-company-txt">{Translation().account.company.forms.company_infos.buttons.submit}</span>
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'editCompany',
  validate
})(CompanyForm);
