import React, { Component } from 'react';

import InvoicesPayment from './InvoicesPayment';
import InvoicesAddresses from './InvoicesAddresses';
import InvoicesList from './InvoicesList';
import { autoScrollTop } from '../../../helpers/JqueryFunctions';
import { getUser } from '../../../helpers/UserFunctions';

import Translation from '../../../translation/Translation';

/**
 * Invoices tab container
 */
class Invoices extends Component {

  constructor(props) {
    super();
    this.state = {
      isUserCompanyAdmin: false,
      isInvoicesOpen: true,
      isPaymentOpen: false,
      isAddressOpen: false,
      isCompanyToggled: false
    };

    this.openPaymentContainer = this.openPaymentContainer.bind(this);
    this.openAddressContainer = this.openAddressContainer.bind(this);
    this.openInvoicesContainer = this.openInvoicesContainer.bind(this);
  }

  componentWillMount() {
    autoScrollTop();

    /**
     * Get the invoice list
     */
    const user = getUser();

    if (user && user.company_admin) {
      this.setState({
        isUserCompanyAdmin: true,
        isCompanyToggled: true,
      });
    }
  }

  openPaymentContainer() {
     this.setState({
      isPaymentOpen: true,
      isInvoicesOpen: false,
      isAddressOpen: false
    });
  }

  openAddressContainer() {
     this.setState({
      isAddressOpen: true,
      isInvoicesOpen: false,
      isPaymentOpen: false
    });
  }

  openInvoicesContainer() {
     this.setState({
      isAddressOpen: false,
      isInvoicesOpen: true,
      isPaymentOpen: false
    });
  }

  render() {
    const {
      isInvoicesOpen,
      isPaymentOpen,
      isAddressOpen,
      isCompanyToggled,
      isUserCompanyAdmin
    } = this.state;

    return (
      <div className="app-account app-invoices">
        <div className="account-header desktop">
          <div className={"tab-header " + (isInvoicesOpen ? "active" : "")} onClick={this.openInvoicesContainer}>
            <h1>{Translation().account.invoices.page_titles.invoices}</h1>
          </div>
          <div className={"tab-header " + (isPaymentOpen ? "active" : "")} onClick={this.openPaymentContainer}>
            <h1>{Translation().account.invoices.page_titles.payment_methods}</h1>
          </div>
          <div className={"tab-header " + (isAddressOpen ? "active" : "")} onClick={this.openAddressContainer}>
            <h1>{Translation().account.invoices.page_titles.invoice_addresses}</h1>
          </div>
        </div>
        <div className="account-header mobile">
          <div className={"tab-header " + (isInvoicesOpen ? "active" : "")} onClick={this.openInvoicesContainer}>
            <div className="icon-facture" />
          </div>
          <div className={"tab-header " + (isPaymentOpen ? "active" : "")} onClick={this.openPaymentContainer}>
            <div className="icon-ajout_carte" />
          </div>
          <div className={"tab-header " + (isAddressOpen ? "active" : "")} onClick={this.openAddressContainer}>
            <div className="icon-ajout_adresse" />
          </div>
        </div>
        <div className="container">
          { isInvoicesOpen &&
            <div className="tab-content">
              <InvoicesList
                isUserCompanyAdmin={isUserCompanyAdmin}
                initialValues={{
                  companyToggle: isCompanyToggled
                }}
              />
              <div form="editInvoiceListAccount" />
            </div>
          }
          { isPaymentOpen  &&
            <div className="tab-content">
              <InvoicesPayment
                isUserCompanyAdmin={isUserCompanyAdmin}
                initialValues={{
                  companyToggle: isCompanyToggled
                }}
              />
              <div form="editPaymentAccount" />
            </div>
          }
          { isAddressOpen &&
            <div className="tab-content">
              <InvoicesAddresses
                isUserCompanyAdmin={isUserCompanyAdmin}
                initialValues={{
                  companyToggle: isCompanyToggled
                }}
              />
              <div form="editInvoiceAdressesAccount" />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Invoices; 