import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import $ from 'jquery';

import { TestimonialAboutSlider } from '../../components/commons/sliders/TestimonialSliders';
import Config from '../../config/Config';
import Translation from "../../translation/Translation";

class About extends Component {

  render() {

    return (
      <div className="app-about-us">
        <div className="page-header-title">
          <h1>{Translation().pages.about.page_title.title} {Config.app_name}</h1>
          <h2>{Translation().pages.about.page_title.baseline}</h2>
        </div>
        <section className="services-about">
          <div className="container">
            <div className="row">
              <Col xs={12} sm={4} md={6} />
              <Col xs={12} sm={8} md={6}>
                <h3>{Translation().pages.about.about_block.title}</h3>
                <h4>{Translation().pages.about.about_block.baseline}</h4>
                <p>{Translation().pages.about.about_block.text}</p>
              </Col>
            </div>
          </div>
          <Clearfix/>
        </section>
        <section className="quality-about">
          <h3><span className="icon-qualite"></span>{Translation().pages.about.quality_chart.title}<span className="icon-laurier_droit"></span></h3>
          <p className="title-baseline">« {Translation().pages.about.quality_chart.baseline} »</p>
          <div className="container chart-showcase">
            <div className="row">
              <Col xs={12} sm={4} md={4}>
                <p>{Translation().pages.about.quality_chart.texts[0]}</p>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <p>{Translation().pages.about.quality_chart.texts[1]}</p>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <p>{Translation().pages.about.quality_chart.texts[2]}</p>
              </Col>
              <Clearfix/>
            </div>
          </div>
        </section>
        <div className="testimonials-slider">
          <div className="intro-testimonial">
            <h3>{Translation().pages.about.testimonials.title}</h3>
          </div>
          <TestimonialAboutSlider/>
        </div>
      </div>
    );
  }
}

export default About;

$(document).ready(function(){
  $('.testimonials-about-slider').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  });
});
