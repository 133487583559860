import React, { Component } from 'react';

import { getBrandPhoneNumber } from '../../../helpers/UserFunctions';
import Translation from '../../../translation/Translation';

class NoFamilyAvailable extends Component {

  render() {

    return (
      <div className="shop-property-error" id="noFamilyAvailable">
        <div className="container">
          <div className="row">
            <div className="error-message-shop no-property">
              <h3>{Translation().shop.failure.property.title}</h3>
              <h4>{Translation().shop.failure.property.baseline}</h4>
              <p>{Translation().shop.failure.property.description}</p>
              <a className="button-retry" href="/shop">{Translation().shop.failure.property.button}</a>
              <div className="error-send">
                <p>{Translation().shop.failure.property.call_us}</p>
                <a href={"tel:" + getBrandPhoneNumber()}>
                  <span className="icon-telephone"></span>
                  <span className="phone-number">{getBrandPhoneNumber()}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NoFamilyAvailable;
