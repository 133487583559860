import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import { adjustOrderCard, orderContentScroll } from '../../../helpers/JqueryFunctions';
import { dateInTz } from '../../../helpers/DateFunctions';
import { addThreeDots } from '../../../helpers/HelperFunctions';
import OrderProduct from './orderProduct/OrderProduct';

class Order extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOrderOpen: false,
      divWidth: '100%',
    }

    this.toggleOrder = this.toggleOrder.bind(this);
    this.closeOrder = this.closeOrder.bind(this);
  }

  toggleOrder() {
    const overlayOder = document.getElementById('overlay-order');

    if (overlayOder) {
      overlayOder.style.display = "block";
    }

    if (this.state.isOrderOpen) {
      this.closeOrder();
    }
    else {
      this.setState({ isOrderOpen: true }, orderContentScroll(this.props.order.id));
    }
  }

  closeOrder() {
    const overlayOder = document.getElementById('overlay-order');

    if (overlayOder) {
      overlayOder.style.display = "none";
    }

    this.setState({ isOrderOpen: false });
  }

  componentDidUpdate() {
    adjustOrderCard();
  }

  render() {
    adjustOrderCard();

    const { isOrderOpen } = this.state;

    const { order, userType } = this.props;

    const coverStyle = {
      backgroundImage: "url(" + (order.cover_url ? order.cover_url : '/assets/images/medias/order-cover-defaut.jpg' ) + ")",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    };

    return (
      <li className={"order " + (isOrderOpen ? "open" :"")} id={"order-" + order.id}>
        <div className="shodow-wrapper">
          <div className="cover" style={coverStyle}>
            <div className={"trigger-content " + (isOrderOpen ? "open" :"")} onClick={this.toggleOrder}>
              <span className="icon-bar"/>
              <span className="icon-bar"/>
              <span className="icon-bar"/>
            </div>
          </div>
          <div className="overview">
            <Col xs={12} sm={12} md={12}>
              <p className="order-adres">{order.property && order.property.address ? addThreeDots(order.property.address, 82) : ''}</p>
            </Col>
            <Clearfix/>
            <div className="timeline">
              <Col xs={12} md={6} className="planning">
                <p><span className="planning-icon icon-calendrier" /> {order.created && dateInTz(order.created, 'localized-date')}</p>
              </Col>
              <Col xs={12} md={6} className="status" style={{color: order.delivery_status.color ? order.delivery_status.color : '#000000'}}>
                <img
                  src={order.delivery_status && order.delivery_status.icon_url_dark ? order.delivery_status.icon_url_dark : ''}
                  alt="Status"
                  className="status-img"
                />
                {order.delivery_status && order.delivery_status.name ? order.delivery_status.name : ''}
              </Col>
              <Clearfix/>
            </div>
            <div className="time-track">
              <div className="tracker" />
            </div>
            <div className="products-img">
              { order.products && order.products.length > 0 
                ? order.products.map((product, index) => {
                  return (
                    <div className={"product-img " + (product.downloadable ? "" : "unavailable")} key={index}>
                      <img src={product.icon_url_dark ? product.icon_url_dark : ''} alt={product.name ? product.name : "Missing img"} />
                    </div>
                  );
                })
                : ""
              }
            </div>
          </div>
        </div>
        <div className="order-pointer">
          <div className="pointer" />
        </div>
        <div className="order-content">
          <div className="close-content icon-fermer" onClick={this.closeOrder} />
          <div className="container">
            <div className="row">
              { order.products && order.products.length > 0
                ? order.products.map((product, index) => {
                  return (
                    <OrderProduct
                      key={index}
                      product={product}
                      isOpen={isOrderOpen}
                      orderId={order.id}
                      orderRef={order.reference}
                      userType={userType}
                      orderUser={order.user ? order.user : null}
                      isCompanyOrder={order.company ? true : false}
                      property={order.property}
                    />
                  );
                })
                : ""
              }
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default Order;
