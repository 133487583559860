import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Clearfix } from 'react-bootstrap';

import validate from './validate';
import renderFieldLabel from '../../components/commons/fields/renderFieldLabel';
import renderTextareaField from '../../components/commons/fields/renderTextareaField';

class ContactForm extends Component {

  render() {

    const {
      handleSubmit,
      submitting,
      labels,
      btnText
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Col xs={12} sm={12} md={6} className="input-col">
          <Field
            required={true}
            name="name"
            type="text"
            component={renderFieldLabel}
            label={labels.last_name}
            className="app-basic-input"
          />
        </Col>
        <Col xs={12} sm={12} md={6} className="input-col">
          <Field
            required={true}
            name="email"
            type="email"
            component={renderFieldLabel}
            label={labels.mail}
            className="app-basic-input"
          />
        </Col>
        <Clearfix/>
        <Col xs={12} sm={12} md={12} className="input-col">
          <label htmlFor="comments">{labels.message}</label>
          <Field
            required={true}
            name="text"
            component={renderTextareaField}
            className="app-basic-input"
            id="comments"
          />
        </Col>
        <Clearfix/>
        <Col xs={12} sm={12} md={12} className="button-col">
          <button type="submit" id="send-contact" className="app-button-color app-button-send button-api" disabled={submitting}>
            <span className="loader-api" id="api-contact-loader"/>
            <span className="btn-contact-text" id="wrapper-contact-text">
              <span id="send-ctc-icon" className="icon-envoyer animated-send"></span> <span id="send-ctc-txt">{btnText.submit}</span>
            </span>
          </button>
          {/*<div className="error-message" />*/}
        </Col>
        <Clearfix/>
      </form>
    );
  }
}

export default reduxForm({
  form: 'contact',
  validate
})(ContactForm);
