import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import renderFieldLabel from '../../../commons/fields/renderFieldLabel';
import validate from './validation/validatePassword'
import Translation from '../../../../translation/Translation';

class PasswordForm extends Component {

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
          <div className="form-cel">
              <Field
                  name="old_password"
                  id="oldPassword"
                  type="password"
                  label={Translation().account.profile.form.labels.current_password}
                  component={renderFieldLabel}
                  className="app-basic-input"
                  required={true}
              />
          </div>
          <div className="form-cel">
              <Field
                  name="new_password"
                  id="newPassword"
                  type="password"
                  label={Translation().account.profile.form.labels.new_password}
                  component={renderFieldLabel}
                  className="app-basic-input"
                  required={true}
              />
          </div>
          <div className="form-cel">
              <Field
                  name="confirmPwd"
                  id="confirmPwd"
                  type="password"
                  label={Translation().account.profile.form.labels.confirm_new_password}
                  component={renderFieldLabel}
                  className="app-basic-input"
                  required={true}
              />
          </div>
          <button type="submit" className="app-button-color button-api btn-edit">
              <span id="edit-password-loader" className="loader-api" />
              <span id="edit-password-txt">{Translation().account.profile.form.buttons.submit}</span>
          </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'editPassword',
  validate
})(PasswordForm);
