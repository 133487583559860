import React, { Component } from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import Checkbox from 'material-ui/Checkbox';
import { Table } from 'react-bootstrap';
import $ from 'jquery';

import store from '../../../redux/store';
import renderField from '../../commons/fields/renderField';
import {
    apiGetUsersCompany,
    adminizeCompanyUser,
    getUser
} from '../../../helpers/UserFunctions';
import validate from './validateMembers';

import Translation from '../../../translation/Translation';

class CompanyMember extends Component {

  constructor(props) {
    super(props);

    this.state = {
        checked: false,
        loading: false
    }

    this.updateCheck = this.updateCheck.bind(this);
  }

  componentWillMount() {
    if (this.props.dataMember.company_admin) {
      this.setState({ checked: true });
    }
  }

  updateCheck(val) {

    const {
      dataMember,
      userId
    } = this.props;

    if (userId === dataMember.id)
        return;

    this.setState({ loading: true });

    adminizeCompanyUser(
      val ? true : false,
      dataMember.id,
      succcess => {
        this.setState({
            checked: !this.state.checked,
            loading: false
        });
      },
      error => {
        this.setState({
          checked: this.state.checked,
          loading: false
        });
      }
    );
  }

  render() {

    const {
      dataMember,
      openRemoveMemberModal,
      userId
    } = this.props;

    const {
      checked,
      loading
    } = this.state;

    const loadingStyle = {
        display: "inline-block",
        left: "15px",
        position: "relative",
        top: "5px",
        width: "21px",
        height: "21px"
    }

    const tdStyle = {
        position: "relative",
        lineHeight: "24px"
    }

    return (
      <tr>
        <td>{dataMember.last_name && dataMember.last_name}</td>
        <td>{dataMember.first_name && dataMember.first_name}</td>
        <td style={tdStyle}>
          {
              loading
                ? <span className="loader-api" style={loadingStyle} />
                : (
                    <span>
                      { userId !== dataMember.id
                        ? (
                            <Checkbox
                              onCheck={(event, val) => this.updateCheck(val)}
                              style={{width: 'auto'}}
                              inputStyle={{margin: 0}}
                              iconStyle={{margin: 0}}
                              checked={checked}
                            />
                        )
                        : <span className="icon-check" style={{fontSize: "16px", color: "#48C17B"}}/>
                      }
                    </span>
              )
          }
        </td>
        <td className="td-delete">
          { userId !== dataMember.id &&
            <span className="delete-member icon-delete" onClick={openRemoveMemberModal.bind(this, dataMember.id, dataMember.first_name, dataMember.last_name)}/>
          }
        </td>
      </tr>
    );
  }
}

class CompanyMembers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAddMemberModalOpen: false,
      isRemoveUserModalOpen: false,
      scrollBuffer: 1,
      paging: null,
      isUsersCompanyLoading: false,
      usersCompany: null,
      userId: null,
      member: null,
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.loadUsersCompany = this.loadUsersCompany.bind(this);
    this.closeAddMemberModal = this.closeAddMemberModal.bind(this);
    this.openAddMemberModal = this.openAddMemberModal.bind(this);
    this.closeRemoveMemberModal = this.closeRemoveMemberModal.bind(this);
    this.openRemoveMemberModal = this.openRemoveMemberModal.bind(this);
    this.reloadUsersCompany = this.reloadUsersCompany.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);

    this.loadUsersCompany(null);

    const user = getUser();

    if (user && user.id) {
      this.setState({ userId: user.id });
    }

    const handleScrollListener = this.handleScroll;
    window.addEventListener('scroll', handleScrollListener);
  }

  handleScroll() {

    const { scrollBuffer } = this.state;

    if (scrollBuffer === 1) {
      if (($(window).scrollTop() + $(window).height()) + ($('.app-footer').height()) > $(document).height()) {
        if (this.state.paging && this.state.paging.next) {
          this.loadUsersCompany(this.state.paging);
          this.setState({ scrollBuffer: 2 });
        }
      }
    }
  }

  loadUsersCompany(paging) {

    this.setState({ isUsersCompanyLoading: true });

    /**
     * Get the users of the company then store to component state
     */
    apiGetUsersCompany(
      paging && paging.next ? paging.next : null,
      (successData, paging) => {
        const usersCompany = this.state.usersCompany ? this.state.usersCompany.concat(successData) : successData;

        this.setState({
          usersCompany,
          paging,
          scrollBuffer: 1,
          isUsersCompanyLoading: false
        });
      },
      (error) => {
        this.setState({
          usersCompany: this.state.usersCompany ? this.state.usersCompany : null,
          isUsersCompanyLoading: false,
          scrollBuffer: 1
        });
      }
    );
  }

  reloadUsersCompany() {

    this.setState({ isUsersCompanyLoading: true });

    apiGetUsersCompany(
      null,
      (usersCompany, paging) => {
        this.setState({
          usersCompany,
          paging,
          scrollBuffer: 1,
          isUsersCompanyLoading: false
        });
      },
      (error) => {
        this.setState({
          usersCompany: null,
          isUsersCompanyLoading: false,
          scrollBuffer: 1
        });
      }
    );
  }

  openAddMemberModal() {
    store.dispatch(reset('companyMembers'));
    this.setState({ isAddMemberModalOpen: true });
  }

  closeAddMemberModal() {
    this.setState({ isAddMemberModalOpen: false });
  }

  openRemoveMemberModal(memberId, memberFirstName, memberLastName) {
    this.setState({
      member: {
        id: memberId,
        first_name: memberFirstName,
        last_name: memberLastName
      },
      isRemoveUserModalOpen: true
    });
  }

  closeRemoveMemberModal() {
    this.setState({ isRemoveUserModalOpen: false });
  }

  render() {

    const {
      handleSubmit,
      unlinkMember,
      isAddMemberRequestLoading,
      isUnlinkMemberRequestLoading,
    } = this.props;

    const {
      isAddMemberModalOpen,
      isRemoveUserModalOpen,
      usersCompany,
      isUsersCompanyLoading,
      member,
      userId
    } = this.state;

    return (
      <div className="company-members">
        { isRemoveUserModalOpen &&
          <div className="app-overlay remove-member-modal">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.closeRemoveMemberModal} />
                  <h4 className="app-modal-title">{Translation().account.company.modals.members.unlink.title},</h4>
                  <p className="app-modal-desc">{Translation().account.company.modals.members.unlink.description}</p>
                  <div className="app-modal-content">
                    <div className="btn-wrapper">
                      <div className="app-button-color app-button-black confirm" onClick={this.closeRemoveMemberModal}>
                        <span className="confirm-icon icon-fermer left" />
                        <span>{Translation().account.company.forms.members.buttons.unlink.cancel}</span>
                      </div>
                      <div className="app-button-color app-button-black confirm" onClick={unlinkMember.bind(this, member)}>
                        <span className="loader-api" style={isUnlinkMemberRequestLoading ? {display: 'inline-block'} : {}} />
                        <span style={isUnlinkMemberRequestLoading ? {visibility: 'hidden', opacity: 0} : {}}><span>{Translation().account.company.forms.members.buttons.unlink.submit}</span><span className="confirm-icon icon-check right" /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { isAddMemberModalOpen &&
          <div className="app-overlay add-member-modal">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.closeAddMemberModal} />
                  <h4 className="app-modal-title">{Translation().account.company.modals.members.link.title}</h4>
                  <p className="app-modal-desc">{Translation().account.company.modals.members.link.description}</p>
                  <div className="app-modal-content">
                    <form onSubmit={handleSubmit}>
                      <Field
                        component={renderField}
                        type="email"
                        name="email1"
                        className="app-basic-input add-member-input"
                        label={Translation().account.company.forms.members.labels.company_member_mail_example}
                      />
                      <Field
                        component={renderField}
                        type="email"
                        name="email2"
                        className="app-basic-input add-member-input"
                        label={Translation().account.company.forms.members.labels.company_member_mail_example}
                      />
                      <Field
                        component={renderField}
                        type="email"
                        name="email3"
                        className="app-basic-input add-member-input"
                        label={Translation().account.company.forms.members.labels.company_member_mail_example}
                      />
                      <div className="btn-wrapper">
                        <button type="submit" className="app-button-color app-button-black button-api">
                          { isAddMemberRequestLoading
                            ? <span className="loader-api" style={{display: 'inline-block'}} />
                            : <span><span className="send-icon icon-envoyer" /><span>{Translation().account.company.forms.members.buttons.link.submit}</span> </span>
                          }
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="members-header">
          <div className="add-member" onClick={this.openAddMemberModal}>
            <span className="add-icon icon-ajout_membre" />
          </div>
        </div>
        { usersCompany && usersCompany.length > 0
          ? (
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>{Translation().account.company.tables.members.last_name}</th>
                    <th>{Translation().account.company.tables.members.first_name}</th>
                    <th>{Translation().account.company.tables.members.admin}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { usersCompany && usersCompany.length > 0 && usersCompany.map((member, index) => {
                      return (
                        <CompanyMember
                          key={index}
                          dataMember={member}
                          userId={userId}
                          openRemoveMemberModal={this.openRemoveMemberModal}
                        />
                      );
                    })
                  }
                </tbody>
              </Table>
            )
        : ''
        }
        { isUsersCompanyLoading &&
          <div className="overlay-lcm">
            <div className="loader-api" />
          </div>
        }
        { (!usersCompany || !usersCompany.length) && !isUsersCompanyLoading &&
          <div className="no-member">
            <p>{Translation().account.company.forms.members.no_member}</p>
          </div>
        }
      </div>
    );
  }

  componentWillUnmount() {
    this.props.onRef(undefined);

    const handleScrollListener = this.handleScroll;
    window.removeEventListener('scroll', handleScrollListener);
  }
}

export default reduxForm({
  form: 'companyMembers',
  validate
})(CompanyMembers);
