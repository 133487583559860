/**
 * SHOP ACTIONS
 */

export const storeProperty = (property) => ({
  type: 'STORE_PROPERTY',
  property: property
});

export const storePropertyTimezone = (timezone) => ({
  type: 'STORE_PROPERTY_TIMEZONE',
  timezone
});

export const storePropertyLocation = (location) => ({
  type: 'STORE_PROPERTY_LOCATION',
  location
});

export const removeProperty = () => ({
  type: 'REMOVE_PROPERTY'
});

export const storeServiceFamilies = (serviceFamilies) => ({
  type: 'STORE_SERVICE_FAMILY',
  serviceFamilies: serviceFamilies
});

export const removeServiceFamilies = () => ({
  type: 'REMOVE_SERVICE_FAMILY'
});

export const storeServices = (services) => ({
  type: 'STORE_SERVICES',
  services: services
});

export const removeServices = () => ({
  type: 'REMOVE_SERVICES'
});

export const storeOptions = (options) => ({
  type: 'STORE_OPTIONS',
  options: options
});

export const removeOptions = () => ({
  type: 'REMOVE_OPTIONS'
});

export const storePlanning = (planning) => ({
  type: 'STORE_PLANNING',
  planning: planning
});

export const removePlanning = () => ({
  type: 'REMOVE_PLANNING'
});

export const storeServiceFamilyStatus = (id, status) => ({
  type: 'STORE_SERVICE_FAMILY_STATUS',
  id: id,
  DOMstatus: status
});

export const storeRequirePlanning = (isPlanningRequired) => ({
  type: 'STORE_REQUIRE_PLANNING',
  isPlanningRequired: isPlanningRequired
});

export const removeRequirePlanning = () => ({
  type: 'REMOVE_REQUIRE_PLANNING'
});

export const storeSlotSelected= (slotSelected) => ({
  type: 'STORE_SLOT_SELECTED',
  slotSelected: slotSelected
});

export const removeSlotSelected = () => ({
  type: 'REMOVE_SLOT_SELECTED'
});
