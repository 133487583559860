import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';

import Translation from '../../../../translation/Translation';

class DeliveryStatus extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.stopPropagation();

    const { onSelect, id } = this.props;

    if (onSelect && id) {
      onSelect(id);
    }
  }

  render() {

    const {
      id,
      color,
      selected,
      name,
    } = this.props;

    return (
      <div className={"delivery-status-item " + (selected.indexOf(id) >= 0 ? "selected" : "")} onClick={this.handleClick}>
        <Checkbox
          style={{
            width: 'auto',
            verticalAlign: 'middle',
            display:  'inline-block'
          }}
          inputStyle={{margin: 0}}
          iconStyle={{margin: 0, fill: color ? color : "#b1b1b1"}}
          checked={selected.indexOf(id) >= 0}
        />
        <span className="delivery-status-name">{name ? name : "Missing status name"}</span>
      </div>
    )
  }
}

class DeliveryStatusFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selected: []
    };
    
    this.deliveryStatusArray = [
      {
        "id": 6,
        "name": Translation().account.orders.filters.delivery_status.delivred,
        "color": "#64dd17"
      },
      {
        "id": 4,
        "name": Translation().account.orders.filters.delivery_status.post_production,
        "color": "#1de9b6"
      },
      {
        "id": 3,
        "name": Translation().account.orders.filters.delivery_status.done,
        "color":"#00e5ff"
      },
      {
        "id": 2,
        "name": Translation().account.orders.filters.delivery_status.in_progress,
        "color":"#42a5f5"
      },
      {
        "id": 1,
        "name": Translation().account.orders.filters.delivery_status.planned,
        "color": "#5c6bc0"
      }
    ];
  
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.renderDeliveryStatus = this.renderDeliveryStatus.bind(this);
  }

  handleToggle() {
    const { filtered } = this.props;
    const { isOpen } = this.state;

    if (isOpen) {
      if (filtered) {
        this.setState({ selected: filtered });
      }
  
      this.setState({ isOpen: false });
    }
    else {
      this.setState({ isOpen: true });
    }
  }

  handleSubmit(event) {
    event.stopPropagation();

    const { onSelect, disabled } = this.props;
    const { selected } = this.state;

    if (disabled) return;

    this.setState({ isOpen: false });

    if (onSelect) {
      onSelect(selected);
    }
  }

  handleSelect(id) {
    if (!id) return;

    const { selected } = this.state;

    let newArray = [...selected];

    if (selected.indexOf(id) >= 0) {
      newArray = selected.filter(x => {
        return x !== id;
      });
    }
    else {
      newArray.push(id);
    }

    this.setState({ selected: newArray });
  }

  renderDeliveryStatus() {
    const deliveryStatuses = [];
    const { selected } = this.state;

    for (let i = 0; i < this.deliveryStatusArray.length; i++) {
      deliveryStatuses.push(
        <DeliveryStatus
          key={i}
          {...this.deliveryStatusArray[i]}
          selected={selected}
          onSelect={this.handleSelect}
        />
      );
    }

    return deliveryStatuses;
  }

  render() {
    const { disabled } = this.props;
    const { isOpen, selected } = this.state;
    const isActive = selected.length > 0 || isOpen ? true : false;

    return (
      <button 
        tabIndex={0}
        className={"filter-delivery-status " + (isActive ? "active" : "")}
        onClick={this.handleToggle}
        disabled={disabled}
      >
        <img src="/assets/images/commons/filters-icon.png" alt="filter" />
        <div className={"filter-delivery-status-dropdown " + (isOpen ? "open" : "closed")}>
          { this.renderDeliveryStatus() }
          <div className="submit-wrapper">
            <div className="app-button-color" onClick={this.handleSubmit}>
              {Translation().account.orders.filters.submit}
            </div>
          </div>
        </div>
      </button>
    );
  }
}

export default DeliveryStatusFilter;