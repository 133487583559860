import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';

import { apiRegisterUser } from '../../../helpers/UserFunctions';
import RegisterForm from './RegisterForm';
import TermsOfUse from '../termsOfUse/TermsOfUse'
import Translation from '../../../translation/Translation';

class RegisterView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isTermOfUseOpen: false,
    }

    this.openTermOfUse = this.openTermOfUse.bind(this);
    this.closeTermOfUse = this.closeTermOfUse.bind(this);
  }

  openTermOfUse() {
    this.setState({ isTermOfUseOpen: true });
  }

  closeTermOfUse() {
    this.setState({ isTermOfUseOpen: false });
  }

  render() {

    const { geoSettings } = this.props;

    return (
      <div>
        <RegisterForm
          onSubmit={apiRegisterUser}
          openTermOfUse={this.openTermOfUse}
          geoSettings={geoSettings}
        />
        <div form="register" />
        <Dialog
          modal={false}
          actions={[<div onClick={this.closeTermOfUse} className="close-tos-modal">{Translation().terms_of_use.modal.close}</div>,]}
          className="term-use-modal"
          autoScrollBodyContent={true}
          onRequestClose={this.closeTermOfUse}
          open={this.state.isTermOfUseOpen}>
            <TermsOfUse isDialog />
         </Dialog>
      </div>
    )
  }
}

export default RegisterView;
