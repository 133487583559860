import React, { Component } from 'react';
import * as moment from 'moment';
import 'moment-timezone';
import { Field, reduxForm } from 'redux-form';

import SelectField from '../../../commons/fields/SelectField';

import { initUserSettingsInfos } from '../../../../helpers/AccountFunctions';
import { initPlaceholder } from '../../../../helpers/JqueryFunctions';

import validate from './validation/validateSettings';
import Config from '../../../../config/Config';

const countries = require('country-list')();

class SettingsForm extends Component {

  constructor(props) {
    super(props);

    this.timezonesArray = [];
    this.currencyArray = [];
    this.localeArray = [];
    this.countriesArray = [];
    this.metricsArray = [
      { label: props.labels.metric || "Metric", value: "metric" },
      { label: props.labels.imperial || "Imperial", value: "imperial" },
      { label: props.labels.imperial_us || "Imperial (US)", value: "imperial_us" }
    ]

    const momentTzArray = moment.tz.names();

    for (let i = 0; i < momentTzArray.length; i++) {
      this.timezonesArray.push({
        label: momentTzArray[i],
        value: momentTzArray[i]
      });
    }

    for (let i = 0; i < Config.open_countries_lang.length; i++) {
      this.localeArray.push({
        label: <span><img width={14} src={"/assets/images/commons/languages/flag-" + Config.open_countries_lang[i].code + ".png"} alt={Config.open_countries_lang[i].name} /><span style={{marginLeft: 5}}> {Config.open_countries_lang[i].name}</span></span>,
        value: Config.open_countries_lang[i].code
      });
    }

    for (let i = 0; i < Config.open_countries_currencies.length; i++) {
      this.currencyArray.push({
        label: Config.open_countries_currencies[i].code + " - " + Config.open_countries_currencies[i].name,
        value: Config.open_countries_currencies[i].code
      });
    }

    for (let i = 0; i < countries.getData().length; i++) {
      this.countriesArray.push({
        label: countries.getData()[i].name,
        value: countries.getData()[i].code
      });
    }
  }

  componentDidMount() {
    initPlaceholder();
  }

  render() {

    const {
      handleSubmit,
      labels,
      buttonLabel
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="edit-account-form" ref={initUserSettingsInfos}>
        <div className="settings">
          <div className="form-cel">
            <label>{labels.language}</label>
            <Field
              name="language_locale"
              id="language_locale"
              type="select"
              placeHolder={labels.language}
              options={this.localeArray}
              component={SelectField}
              className="app-basic-input select-inputs"
              clearable={"false"}
            />
          </div>
          <div className="form-cel">
            <label>{labels.timezone}</label>
            <Field
              name="timezone_code"
              id="timezone_code"
              type="select"
              placeHolder={labels.timezone}
              searchable={true}
              options={this.timezonesArray}
              component={SelectField}
              className="app-basic-input select-inputs"
              clearable="false"
            />
          </div>
          <div className="form-cel">
            <label>{labels.currency}</label>
            <Field
              name="currency_isocode"
              id="currency_isocode"
              type="select"
              placeHolder={labels.currency}
              searchable={false}
              options={this.currencyArray}
              component={SelectField}
              className="app-basic-input select-inputs"
              clearable="false"
            />
          </div>
          <div className="form-cel">
            <label>{labels.residence_country}</label>
            <Field
              name="residence_country_isocode"
              id="residence_country_isocode"
              type="select"
              placeHolder={labels.residence_country}
              searchable={true}
              options={this.countriesArray}
              component={SelectField}
              className="app-basic-input select-inputs"
              clearable="false"
            />
          </div>
          <div className="form-cel">
            <label>{labels.citizen_country}</label>
            <Field
              name="citizen_country_isocode"
              id="citizen_country_isocode"
              type="select"
              placeHolder={labels.citizen_country}
              searchable={true}
              options={this.countriesArray}
              component={SelectField}
              className="app-basic-input select-inputs"
              clearable="false"
            />
          </div>
          <div className="form-cel">
            <label>{labels.unit_system}</label>
            <Field
              name="unit_system"
              id="unit_system"
              type="select"
              placeHolder={labels.unit_system}
              searchable={false}
              options={this.metricsArray}
              component={SelectField}
              className="app-basic-input select-inputs"
              clearable="false"
            />
          </div>
        </div>
        <button type="submit" className="app-button-color button-api btn-edit">
          <span id="edit-settings-loader" className="loader-api" />
          <span id="edit-settings-txt">{buttonLabel}</span>
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'editSettings',
  validate
})(SettingsForm);
