import { getUser, getAccessToken } from './UserFunctions';
import { change } from 'redux-form';
import store from '../redux/store';
import Config from '../config/Config';

import { CallApi } from './ApiFunctions';
import { formatDate } from './DateFunctions';

export const initUserBasicsInfos = () => {
  const user = getUser();

  if (user) {
    if (user.gender) {
      store.dispatch(change('editBasicAccount', 'gender', user.gender));
    }
    if (user.first_name) {
      store.dispatch(change('editBasicAccount', 'first_name', user.first_name));
    }
    if (user.last_name) {
      store.dispatch(change('editBasicAccount', 'last_name', user.last_name));
    }
    if (user.date_of_birth) {
      const birthDate = formatDate(user.date_of_birth, 'YYYY-MM-DD');
      const year = formatDate(birthDate, 'YYYY');
      const month = formatDate(birthDate, 'M');
      const day = formatDate(birthDate, 'D');

      /**
       * Set into redux form the 3 birthday fields
       */
      store.dispatch(change('editBasicAccount', 'birthdayYear', year));
      store.dispatch(change('editBasicAccount', 'birthdayMonth', month));
      store.dispatch(change('editBasicAccount', 'birthdayDay', day));
      /**
       * Set into redux form the unique birthday input send to api
       */
      store.dispatch(change('editBasicAccount', 'date_of_birth', birthDate));
    }
    if (user.email) {
      store.dispatch(change('editBasicAccount', 'email', user.email));
    }
    if (user.mobile) {
      store.dispatch(change('editBasicAccount', 'mobile', user.mobile));
    }
    if (user.phone) {
      store.dispatch(change('editBasicAccount', 'phone', user.phone));
    }
  }
};

export const initUserSettingsInfos = () => {
  const user = getUser();

  if (user) {
    if (user.language && user.language.locale) {
      store.dispatch(
        change('editSettings', 'language_locale', user.language.locale)
      );
    }
    if (user.currency && user.currency.isocode) {
      store.dispatch(
        change('editSettings', 'currency_isocode', user.currency.isocode)
      );
    }
    if (user.timezone_code) {
      store.dispatch(
        change('editSettings', 'timezone_code', user.timezone_code)
      );
    }
    if (user.residence_country && user.residence_country.isocode) {
      store.dispatch(
        change(
          'editSettings',
          'residence_country_isocode',
          user.residence_country.isocode
        )
      );
    }
    if (user.citizen_country && user.citizen_country.isocode) {
      store.dispatch(
        change(
          'editSettings',
          'citizen_country_isocode',
          user.citizen_country.isocode
        )
      );
    }
    if (user.unit_system) {
      store.dispatch(change('editSettings', 'unit_system', user.unit_system));
    }
  }
};

export const initCompanyInfos = () => {
  const user = getUser();

  if (user) {
    if (user.company) {
      if (user.company.commercial_name) {
        store.dispatch(
          change('editCompany', 'commercial_name', user.company.commercial_name)
        );
      }
      if (user.company.legal_name) {
        store.dispatch(
          change('editCompany', 'legal_name', user.company.legal_name)
        );
      }
      if (user.company.fax) {
        store.dispatch(change('editCompany', 'fax', user.company.fax));
      }
      if (user.company.phone) {
        store.dispatch(change('editCompany', 'phone', user.company.phone));
      }
      if (user.company.email) {
        store.dispatch(change('editCompany', 'email', user.company.email));
      }
      if (user.company.website_url) {
        store.dispatch(
          change('editCompany', 'website_url', user.company.website_url)
        );
      }
      if (user.company.logo_url) {
        store.dispatch(
          change('editCompany', 'logo_url', user.company.logo_url)
        );
      }
      if (user.company.watermark_url) {
        store.dispatch(
          change('editCompany', 'watermark_url', user.company.watermark_url)
        );
      }

      return user.company;
    }
  }
};

/**
 * Get all orders for a user
 */
export const apiGetUserOrders = (
  query = {},
  routePage,
  isCompany,
  callBackSuccess,
  callBackError
) => {
  if (callBackSuccess && callBackError) {
    if (routePage) {
      /**
       * Replace the api route name delivred by api
       */
      routePage = routePage.replace(Config.api_url, '');

      CallApi(
        'get',
        routePage,
        query,
        null,
        null,
        callBackSuccess,
        callBackError
      );
    } else {
      CallApi(
        'get',
        isCompany ? 'companies/self/orders/active' : 'users/self/orders/active',
        query,
        null,
        null,
        callBackSuccess,
        callBackError
      );
    }
  }
};

/**
 * Get orderProduct medias
 */
export const apiGetUserOrderProductMedias = (
  userType,
  orderId,
  orderProductId,
  callBackSuccess,
  callBackError
) => {
  if (!orderId || !userType || !orderProductId) {
    return;
  }

  if (callBackSuccess && callBackError) {
    CallApi(
      'get',
      userType +
        '/self/orders/' +
        orderId +
        '/products/' +
        orderProductId +
        '/medias/structured',
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Cancel a product of an order
 */
export const cancelOrderProduct = (
  userType,
  orderId,
  orderProductId,
  callBackSuccess,
  callBackError
) => {
  if (!orderId || !userType || !orderProductId) {
    return;
  }

  if (callBackSuccess && callBackError) {
    CallApi(
      'post',
      userType +
        '/self/orders/' +
        orderId +
        '/products/' +
        orderProductId +
        '/cancel',
      null,
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Get thumbnails for a media type
 */
export const apiGetMediaTypeThumbnail = (
  mediaGroupId,
  callBackSuccess,
  callBackError
) => {
  if (!mediaGroupId) {
    return;
  }

  if (callBackSuccess && callBackError) {
    CallApi(
      'get',
      'media-groups/' + mediaGroupId + '/thumbnails',
      { sort: '-landscape_width' },
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Download orderProduct medias
 */
export const apiDownloadOrderProductMedias = (
  userType,
  orderId,
  orderProductId,
  downloadRequest,
  callBackSuccess,
  callBackError
) => {
  if (!orderId || !userType || !orderProductId || !downloadRequest) {
    return;
  }

  if (callBackSuccess && callBackError) {
    CallApi(
      'post',
      userType +
        '/self/orders/' +
        orderId +
        '/products/' +
        orderProductId +
        '/medias/download',
      null,
      downloadRequest,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Request for reset password
 */
export const apiRequestPassword = (
  requestObject,
  callBackSuccess,
  callBackError
) => {
  if (!requestObject) return;

  CallApi(
    'post',
    'users/password/request',
    null,
    requestObject,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Reset user password
 */
export const apiResetPassword = (
  resetObject,
  callBackSuccess,
  callBackError
) => {
  if (!resetObject) return;

  CallApi(
    'post',
    'users/password/reset',
    null,
    resetObject,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Get the invoices of a user / company
 */
export const getInvoices = (
  isCompany = false,
  paging,
  query = {},
  callBackSuccess,
  callBackError
) => {
  CallApi(
    'get',
    paging
      ? paging.replace(Config.api_url, '')
      : (isCompany ? 'companies' : 'users') + '/self/invoices',
    query,
    null,
    null,
    callBackSuccess,
    callBackError
  );
};
export const getInvoice = (
  isCompany = false,
  invoiceId,
  callBackSuccess,
  callBackError
) => {
  CallApi(
    'get',
    (isCompany ? 'companies' : 'users') + '/self/invoices/' + invoiceId,
    null,
    null,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Rate a product of an order
 */
export const rateOrderProduct = (
  userType,
  orderId,
  orderProductId,
  data,
  callBackSuccess,
  callBackError
) => {
  if (!orderId || !userType || !orderProductId || !data) {
    return;
  }

  if (callBackSuccess && callBackError) {
    CallApi(
      'post',
      userType +
        '/self/orders/' +
        orderId +
        '/products/' +
        orderProductId +
        '/rate',
      null,
      data,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Get the order product hosting plans (expire date)
 */
export const getOrderProductHostingPlans = (
  userType = 'users',
  orderId,
  orderProductId,
  callBackSuccess,
  callBackError
) => {
  if (!orderId || !userType || !orderProductId) {
    return;
  }

  CallApi(
    'get',
    userType +
      '/self/orders/' +
      orderId +
      '/products/' +
      orderProductId +
      '/hosting-plans',
    null,
    null,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Post order product new hosting plan (expire date)
 */
export const postOrderProductHostingPlan = (
  userType,
  orderId,
  orderProductId,
  data,
  callBackSuccess,
  callBackError
) => {
  if (!orderId || !userType || !orderProductId || !data) {
    return;
  }

  CallApi(
    'post',
    userType +
      '/self/orders/' +
      orderId +
      '/products/' +
      orderProductId +
      '/hosting-extensions',
    null,
    data,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Load in DOM the payment iframe
 */
export const loadPaymentInvoiceIframe = (
  companyToggleValue = false,
  invoiceId,
  callBackFunction
) => {
  if (!callBackFunction || !invoiceId) {
    return;
  }

  const iframeContainer = document.getElementById(
    'payment-invoice-frame-container'
  );
  const iframePaymentLoader = document.getElementById(
    'frame-payment-invoice-loader'
  );

  if (!iframeContainer) {
    return;
  }

  if (iframePaymentLoader) {
    iframePaymentLoader.style.display = 'block';
  }

  /**
   * Iframe URL
   */
  const route = companyToggleValue
    ? 'companies/self/invoices/' + invoiceId + '/pay/form'
    : 'users/self/invoices/' + invoiceId + '/pay/form';

  const src =
    Config.api_url +
    route +
    '?access_token=' +
    (getAccessToken() ? getAccessToken() : null);
  const paymentFrame =
    '<iframe id="frame-payment-invoice" class="app-basic-iframe" title="payInvoice" src=' +
    src +
    ' />';

  iframeContainer.innerHTML = '';
  iframeContainer.innerHTML = paymentFrame;

  document.getElementById('frame-payment-invoice').onload = function () {
    if (iframePaymentLoader) {
      iframePaymentLoader.style.display = 'none';
    }

    const paymentFrame = document.getElementById('frame-payment-invoice');

    /**
     * Check if our front app can access to iframe
     * Check if the ifram exists
     * Check if iframe exist but hash doesn't exist
     */
    try {
      const frameWindowLocation = paymentFrame.contentWindow.location.href;

      if (!frameWindowLocation) {
        return;
      } else if (
        frameWindowLocation &&
        !paymentFrame.contentWindow.location.hash
      ) {
        return;
      }
    } catch (e) {
      if (iframePaymentLoader) {
        iframePaymentLoader.style.display = 'none';
      }
      return;
    }

    /**
     * Get and split all hash
     */
    const hash = paymentFrame.contentWindow.location.hash;

    if (hash === '#success') {
      callBackFunction();
    }
  };
};

/**
 * Get order for delegation
 * @param query the query
 * @param onSuccess
 * @param onError
 * @returns
 */
export function getOrderDelegate(oid, query = {}, onSuccess, onError) {
  if (!oid) return;

  CallApi(
    'get',
    'orders/delegate/' + oid,
    query,
    null,
    null,
    onSuccess,
    onError
  );
}

/**
 * Decline order delegation
 * @param oid the order id
 * @param query the query
 * @param onSuccess
 * @param onError
 * @returns
 */
export function decineOrderDelegate(oid, query = {}, onSuccess, onError) {
  if (!oid) return;

  CallApi(
    'post',
    'orders/delegate/' + oid + '/decline-delegate',
    query,
    null,
    null,
    onSuccess,
    onError
  );
}

/**
 * Accept order delegation
 * @param oid the order id
 * @param data the data payload
 * @param onSuccess
 * @param onError
 * @returns
 */
export function acceptOrderDelegate(oid, data = {}, onSuccess, onError) {
  if (!oid) return;

  CallApi(
    'post',
    'users/self/orders/delegate/' + oid + '/accept-delegate',
    null,
    data,
    null,
    onSuccess,
    onError
  );
}
