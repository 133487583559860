import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import initSubscriber from 'redux-subscriber';

import Config from '../config/Config';

import './actions/shop/shopActions';
import './actions/user/userActions';
import './actions/cart/cartActions';

const initialState = null;

const initialCart = {
  property: null,
  product_id: null,
  options: null,
  slot: null,
  discount_code: null
};

const initialGeo = {
  brand_phone_number: null,
  locale: null,
  timezone_code: null,
  currency: null,
  residence_country: null,
  citizen_country: null,
  support_email: null
};

/**
 * User reducer
 */
const user = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_USER':
      return action.user
    case 'STORE_COMPANY':
      return { ...state, company: action.company }
    case 'REMOVE_USER':
      return null
    default:
      return state
  }
}

/**
 * Shop service options reducer
 */
const property = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_PROPERTY':
      return action.property
    case 'REMOVE_PROPERTY':
      return null
    default:
      return state
  }
}
const propertyGeo = (state = null, action) => {
  switch (action.type) {
    case 'STORE_PROPERTY_TIMEZONE':
      return {
        ...state,
        timezone_code: action.timezone
      }
    case 'STORE_PROPERTY_LOCATION':
      return {
        ...state,
        location: action.location
      }
    case 'REMOVE_PROPERTY':
      return null
    default:
      return state
  }
}


/**
 * Shop services families reducer
 */
const serviceFamilies = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_SERVICE_FAMILY':
      return action.serviceFamilies
    case 'STORE_SERVICE_FAMILY_STATUS': {
      for (let i = 0; i < state.length; i++) {
        if (state[i].id === action.id) {
          state[i].DOMstatus = action.DOMstatus;
        }
      }
      return state
    }
    case 'REMOVE_SERVICE_FAMILY':
      return null
    default:
      return state
  }
}

/**
 * shop services reducer
 */
const services = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_SERVICES':
      return action.services
    case 'REMOVE_SERVICES':
      return null
    default:
      return state
  }
}

/**
 * Shop service options reducer
 */
const options = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_OPTIONS':
      return action.options
    case 'REMOVE_OPTIONS':
      return null
    default:
      return state
  }
}

/**
 * Shop service planning reducer
 */
const planning = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_PLANNING':
      return action.planning
    case 'REMOVE_PLANNING':
      return null
    default:
      return state
  }
}

/**
 * Check for each service if planning is required
 */
const requirePlanning = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_REQUIRE_PLANNING':
      return action.isPlanningRequired
    case 'REMOVE_REQUIRE_PLANNING':
      return null
    default:
      return state
  }
}

/**
 * Check for each service if planning is required
 */
const slotSelected = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_SLOT_SELECTED':
      return action.slotSelected
    case 'REMOVE_SLOT_SELECTED':
      return null
    default:
      return state
  }
}

/**
 * Store to redux the cart responded by the api
 */
const userCart = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_USER_CART':
      return action.userCart
    case 'REMOVE_USER_CART':
      return null
    default:
      return state
  }
}

/**
 * Cart request reducer (cart send to api)
 */
const cartRequest = (state = initialCart, action) => {
  switch (action.type) {
    case 'STORE_CART_REQUEST':
      return action.cartRequest
    case 'STORE_CART_REQUEST_SLOT':
      return { ...state, slot: action.slot }
    case 'REMOVE_CART_REQUEST_SLOT':
      return { ...state, slot: null }
    case 'STORE_CART_REQUEST_PROPERTY':
      return { ...state, property: action.property }
    case 'STORE_CART_REQUEST_PRODUCT_ID':
      return { ...state, product_id: action.product_id }
    case 'STORE_CART_REQUEST_OPTIONS':
      return { ...state, options: action.options }
    case 'REMOVE_CART_REQUEST_OPTIONS':
      return { ...state, options: null }
    case 'REMOVE_CART_REQUEST':
      return null
    default:
      return state
  }
}

/**
 * User call api loading
 */
const userLoading = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_USER_LOADING':
      return action.isUserLoading
    default:
      return state
  }
}

const geoSettings = (state = initialGeo, action) => {
  switch (action.type) {
    case 'STORE_GEO_SETTING':
      return {
        ...state,
        ...action.setting
      }
    default:
      return state
  }
}


/**
 * Reducers
 */
const reducers = combineReducers({
  geoSettings,
  user,
  userCart,
  userLoading,
  cartRequest,
  property,
  propertyGeo,
  serviceFamilies,
  services,
  options,
  planning,
  requirePlanning,
  slotSelected,
  form: reduxFormReducer,
});

/**
 * Init redux state
 * We check if there is a dev tools in chrome extensions and if the project is in DEV env,
 * then we pass the store to it, if not we just create the store.
 */
const store = (window.__REDUX_DEVTOOLS_EXTENSION__ && Config.debug === "DEV"
  ? window.__REDUX_DEVTOOLS_EXTENSION__()(createStore)
  : createStore)(reducers);

/**
 * Init redux subscriber
 */
// eslint-disable-next-line
const subscribe = initSubscriber(store);

export default store;
