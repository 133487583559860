import React from 'react';

import Translation from '../../translation/Translation';

function CovidCharterView() {

  return (
    <div className="covid-charter-view">
      <div className="container">
        <h1>{Translation().covid.title}</h1>
        <p>
          {Translation().covid.baseline}
        </p>
        <br/>
        <p className="major-info">
          {Translation().covid.respect_rules.title}
        </p>
        <ul>
          <li>
            {Translation().covid.respect_rules.rules[0]}
          </li>
          <li>
            {Translation().covid.respect_rules.rules[1]}
          </li>
          <li>
            {Translation().covid.respect_rules.rules[2]}
          </li>
          <li>
            {Translation().covid.respect_rules.rules[3]}
          </li>
          <li>
            {Translation().covid.respect_rules.rules[4]}
          </li>
          <li>
            {Translation().covid.respect_rules.rules[5]}
          </li>
        </ul>
        <br/>
        <p className="major-info">
          {Translation().covid.providers.title}
        </p>
        <ul>
        <li>
          {Translation().covid.providers.rules[0]}
        </li>
        <li>
          {Translation().covid.providers.rules[1]}
        </li>
        <li>
          {Translation().covid.providers.rules[2]}
        </li>
        </ul>
        <br/>
        <p>
          {Translation().covid.cancel}
        </p>
      </div>
    </div>
  )
}

export default CovidCharterView;
