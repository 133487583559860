import React from 'react';

const renderField = ({ input, label, type, className, id, meta: { touched, error } }) => {
  return (
    <div className="render-field">
      <input
        {...input}
        placeholder={label}
        type={type}
        className={touched && error ? (className + " field-get-error") : className}
        id={id}
      />
      {touched && error && <span className="error-message error-field">{error}</span>}
    </div>
  );
}

export default renderField;
