import React, { Component } from 'react';

import { getBrandPhoneNumber } from '../../../helpers/UserFunctions';
import Translation from '../../../translation/Translation';

class ZoneNotCovered extends Component {

  render() {

    const phoneNumber = getBrandPhoneNumber();

    return (
      <div className="shop-property-error" id="zoneNotCovered">
        <div className="container">
          <div className="row">
            <div className="error-message-shop zone-not-covered">
              <h3>{Translation().shop.failure.zone_not_covered.title}</h3>
              <p>{Translation().shop.failure.zone_not_covered.baseline}</p>
              <a className="app-button-color" href="/shop">{Translation().shop.failure.zone_not_covered.button}</a>
              <div className="error-send">
                { phoneNumber &&
                  <a href={"tel:" + phoneNumber}>
                    Contact: <span className="phone-number">{phoneNumber}</span>
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ZoneNotCovered;
