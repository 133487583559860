import React, { Component } from 'react';

/**
 * Translation title : WALL-MARKET DATENSCHUTZRICHTLINIE
 */

class PrivacyPolicyDE extends Component {
  render () {
    return (
      <div className="app-terms-of-use">
        <div className="articles">
          <div className="article-content" id="introduction">
            <p>Ihre Privatsphäre ist für Wall-Market wichtig. Diese Richtlinie soll Ihnen dabei helfen, zu verstehen, wie wir Ihre personenbezogenen Daten im Zusammenhang mit der Erbringung unserer Dienstleistungen und Ihrer Nutzung unserer Website <a href="https://lightshop.wall-market.com" target="_blank" rel="noopener noreferrer">lightshop.wall-market.com</a> und der Lightshop-Anwendungen erfassen und verarbeiten und welche Rechte Sie haben. </p>
            <ul>
              <li>1. WER IST FÜR DIE VERARBEITUNG IHRER DATEN VERANTWORTLICH?</li>
              <li>2. WELCHE DATEN SAMMELN WIR?</li>
              <li>3. WARUM SAMMELN WIR IHRE DATEN UND WIE LANGE BEWAHREN WIR SIE AUF?</li>
              <li>4. MIT WEM TEILEN WIR IHRE DATEN? </li>
              <li>5. WELCHE RECHTE HABEN SIE UND WIE KÖNNEN SIE DIESE AUSÜBEN? ?</li>
              <li>6. KONTAKT</li>
              <li>7. ÄNDERUNGEN AN DER DATENSCHUTZRICHTLINIE </li>
            </ul>
          </div>
          <div className="article-content">
            <h2>1. WER IST FÜR DIE VERARBEITUNG IHRER DATEN VERANTWORTLICH?</h2>
            <p>
              Verantwortlich für die Verarbeitung Ihrer Daten ist die Firma Wall-Market, die unter der Nummer 532 727 740 RCS Paris registriert ist und deren Sitz sich in 22 rue Legendre, 75017 Paris, Frankreich befindet.
            </p>
          </div>
          <div className="article-content with-list">
            <h2>2. WELCHE DATEN SAMMELN WIR?</h2>
            <p>Wir erheben die folgenden personenbezogenen Daten:</p>
            <ul>
              <li>
                <span className="bold">Die Daten, die Sie uns zur Verfügung stellen:</span> Wenn Sie sich auf unserer Website oder in unserer Anwendung registrieren, um auf unsere Dienste zuzugreifen oder Ihr Konto zu aktualisieren, oder wenn Sie sich mit uns in Verbindung setzen (insbesondere über unser Kontaktformular, E-Mail, Telefon oder soziale Netzwerke), erfassen wir die personenbezogenen Daten, die Sie uns zur Verfügung stellen. Wenn Sie beispielsweise ein Konto erstellen, werden Sie möglicherweise aufgefordert, uns Ihren Vor- und Nachnamen, Ihre E-Mail-Adresse, Ihre Postanschrift (ggf. Ihr Wohnsitzland), Ihre Mobil- und/oder Festnetztelefonnummer, Ihr Geburtsdatum, Ihre Nationalität, Ihre Kreditkarten- oder Bankkontodaten, Ihre gesprochene(n) Sprache(n) und ggf. ein Foto mitzuteilen. 
                <br /><br />
                In jedem Fall werden wir Sie nur nach den Informationen fragen, die wir wirklich benötigen. Es steht Ihnen frei, ob Sie sie angeben oder nicht, aber wenn Sie sich weigern, können Sie unsere Dienste nicht nutzen.
                <br /><br />
              </li>
              <li>
                <span className="bold">Transaktionsdaten:</span> Wir erfassen Transaktionsdaten im Zusammenhang mit der Nutzung unserer Dienste, einschließlich der Art des bestellten oder erbrachten Dienstes, der Bestelldetails, der Uhrzeit und des Datums der Ausführung des Dienstes, des in Rechnung gestellten Betrags und der Zahlungsmethode.
                <br /><br />
              </li>
              <li>
                <span className="bold">Nutzungsdaten:</span> Wir sammeln Daten über die Interaktion der Nutzer mit unseren Diensten. Dazu gehören Daten wie Zugriffsdaten und -zeiten, verwendete Anwendungsfunktionen oder Seiten, Anwendungsabstürze und andere systembezogene Aktivitäten sowie der Browsertyp. Wir sammeln diese Daten automatisch durch die Verwendung von Cookies. Um mehr über diese Technologien zu erfahren, lesen Sie bitte unsere <a href="javascript:Didomi.preferences.show();">Cookie-Richtlinie</a>. 
                <br /><br />
              </li>
              <li>
                <span className="bold">Geolokalisierung:</span> Wir sammeln Ihre Geolokalisierung, insbesondere um die Organisation und die Nachverfolgung der Missionen zu ermöglichen. Wir sammeln diese Daten mit Ihrer Zustimmung. Sie können die Geolokalisierung jederzeit in den Anwendungseinstellungen deaktivieren. Die Geolokalisierung ist optional, aber sie verbessert die Funktion unserer Dienste erheblich und reduziert die Probleme beim Verbindungsaufbau erheblich.
                <br /><br />
              </li>
            </ul>
            <p>
              Unsere Dienste sind nicht für Minderjährige bestimmt und nicht für die Nutzung durch Minderjährige vorgesehen. Wir sammeln nicht wissentlich Informationen von Minderjährigen und fragen sie auch nicht nach Informationen. Wir erlauben Minderjährigen nicht wissentlich, unsere Dienste zu nutzen. Jeder, der WallMarket über die Anmeldeseite für Neukunden, die Registrierungsseite oder einen anderen Teil unserer Website oder Anwendung Informationen zur Verfügung stellt, versichert, dass er in dem Land, in dem er wohnt, volljährig ist. Wenn wir feststellen, dass wir Informationen von einem Minderjährigen ohne die Erlaubnis der Eltern oder des gesetzlichen Vertreters gesammelt haben, werden wir diese Informationen löschen. Bitte kontaktieren Sie uns unter den in Abschnitt 6 aufgeführten Kontaktinformationen, wenn Sie glauben, dass Sie uns Informationen über einen Minderjährigen ohne die Erlaubnis der Eltern oder des gesetzlichen Vormunds zur Verfügung gestellt haben.
            </p>
          </div>
          <div className="article-content">
            <h2>3. WARUM SAMMELN WIR IHRE DATEN UND WIE LANGE BEWAHREN WIR SIE AUF?</h2>
            <p>
              Wir verarbeiten Ihre Daten für definierte Zwecke, auf der Grundlage einer gesetzlichen "Rechtfertigung" (oder Rechtsgrundlage) und für einen begrenzten Zeitraum. In der folgenden Tabelle sind die Zwecke und Rechtsgrundlagen für unsere Verarbeitung sowie die geltenden Aufbewahrungsfristen aufgeführt. 
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{textAlign: 'center'}}>Ziele</th>
                  <th style={{textAlign: 'center'}}>Rechtsgrundlage</th>
                  <th style={{textAlign: 'center'}}>Haltbarkeitsdauer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Verwaltung Ihres Benutzerkontos (Erstellung und Aktualisierung)
                  </td>
                  <td>
                    Diese Verarbeitung ist für die Erfüllung des zwischen Ihnen und Wall-Market geschlossenen Vertrages erforderlich.
                  </td>
                  <td>
                    Solange Sie Ihr Konto behalten <br/><br/> Bei Inaktivität über einen Zeitraum von 5 Jahren wird Ihr Konto automatisch gelöscht.
                  </td>
                </tr>
                <tr>
                  <td>
                    Auftragsmanagement (Bereitstellung von Dienstleistungen, Beziehungsmanagement und statistische Auswertung)
                  </td>
                  <td>
                    Diese Verarbeitung ist für die Erfüllung des zwischen Ihnen und Wall-Market geschlossenen Vertrages erforderlich.
                  </td>
                  <td>
                    Wir bewahren außerdem eine Historie aller Ihrer Bestellungen der letzten 5 Jahre zur Kundenverfolgung und statistischen Analyse auf. 
                  </td>
                </tr>
                <tr>
                  <td>
                    Geolokalisierung (Vorschlag von Einsätzen in der Nähe, Nachbereitung des Einsatzes und Verwaltung von Streitfällen)
                  </td>
                  <td>
                    Diese Verarbeitung erfolgt mit Ihrer Zustimmung
                  </td>
                  <td>
                    48 Stunden. Dennoch können die Daten im Falle einer Streitigkeit über die erbrachten Leistungen länger aufbewahrt werden, bis die Streitigkeit beigelegt ist.
                  </td>
                </tr>
                <tr>
                  <td>Benutzerauswertung</td>
                  <td>Diese Verarbeitung ist zur Verfolgung unseres berechtigten Interesses erforderlich, um die Qualität der Dienste zu gewährleisten und zu verbessern.</td>
                  <td>Solange Sie ein aktives Konto haben, plus 5 Jahre</td>
                </tr>
                <tr>
                  <td>TBearbeitung von Anfragen, die auf beliebigem Wege eingehen (Kontaktformular, E-Mail, Telefon)</td>
                  <td>
                    Diese Verarbeitung erfolgt mit Ihrer Zustimmung
                  </td>
                  <td>Erforderliche Zeit zur Bearbeitung Ihrer Anfrage</td>
                </tr>
                <tr>
                  <td>Versenden von Kommuniqués an unabhängige Partneranbieter</td>
                  <td>Diese Verarbeitung ist zur Verfolgung unseres berechtigten Interesses erforderlich, unsere Gemeinschaft unabhängiger Dienstleister zu animieren und sie über Neuigkeiten von WallMarket zu informieren.</td>
                  <td>Solange der unabhängige Auftragnehmer ein aktives Konto hat oder bis er sich vom Erhalt dieser Mitteilungen abmeldet</td>
                </tr>
                <tr>
                  <td>Versenden von Marketing-E-Mails (kommerzielle Angebote, Newsletter, etc.)</td>
                  <td>Diese Verarbeitung ist erforderlich, um unser berechtigtes Interesse an der Entwicklung unseres Geschäfts zu verfolgen.</td>
                  <td>Bis Sie sich vom Erhalt unserer Marketing-E-Mails abmelden oder 3 Jahre nach dem letzten Kontakt</td>
                </tr>
                <tr>
                  <td>Verpflichtungen für Buchhaltung, Steuern usw.</td>
                  <td>Diese Verarbeitung ist notwendig, um einer gesetzlichen Verpflichtung nachzukommen, der wir unterliegen. </td>
                  <td>Für die geltende gesetzliche Aufbewahrungsfrist</td>
                </tr>
                <tr>
                  <td>Management von vorgerichtlichen und gerichtlichen Verfahren</td>
                  <td>Diese Verarbeitung ist zur Verfolgung unseres berechtigten Interesses am Nachweis eines Rechts oder Vertrags und zur Verteidigung unserer Rechte erforderlich.</td>
                  <td>Für die Dauer der zugehörigen Verschreibung. In der Regel bewahren wir die Daten 6 Jahre lang auf.</td>
                </tr>
                <tr>
                  <td>Verwaltung von Anträgen zur Ausübung von Rechten</td>
                  <td>Diese Verarbeitung ist notwendig, um einer gesetzlichen Verpflichtung nachzukommen, der wir unterliegen. </td>
                  <td>5 Jahre ab dem Ende des Kalenderjahres, in dem Sie sich bewerben.<br/><br/>
                    Wenn eine Kopie Ihres Ausweises angefordert wird, wird diese für 1 Jahr aufbewahrt, wenn Sie Ihr Recht auf Auskunft oder Berichtigung ausüben, und für 3 Jahre, wenn Sie Ihr Recht auf Widerspruch oder Löschung ausüben.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Bitte beachten Sie, dass Ihre Daten unter Umständen länger aufbewahrt werden, z. B. um ein Recht nachzuweisen oder einer gesetzlichen Verpflichtung nachzukommen. In jedem Fall werden Ihre Daten nicht länger aufbewahrt, als es für die Zwecke, für die sie verarbeitet werden, unbedingt erforderlich ist. Wenn Ihre Daten nicht mehr benötigt werden, sorgen wir dafür, dass sie entweder gelöscht oder anonymisiert werden.
            </p>
          </div>
          <div className="article-content with-list">
            <h2>4. MIT WEM TEILEN WIR IHRE DATEN? </h2>
            <p>
              Wir geben Ihre Daten nur an:
            </p>
            <ul>
              <li>andere Benutzer nur, um die Erfüllung von Missionen zu ermöglichen,</li>
              <li>ordnungsgemäß befugte Personen von Wall-Market, die im Rahmen ihrer Aufgaben Zugang zu Ihren Daten benötigen,</li>
              <li>unsere Unterauftragnehmer, die Ihre Daten im Auftrag von Wall-Market verarbeiten (einschließlich unserer Hosting- und Wartungsanbieter, Analyse- und Zahlungsdienste),</li>
              <li>unsere externen Berater (Rechtsanwälte, Wirtschaftsprüfer, etc.), soweit dies für die berechtigten Interessen von Wall-Market erforderlich ist,</li>
              <li>öffentliche oder gerichtliche Behörden, wenn wir gesetzlich dazu verpflichtet sind.</li>
            </ul>
            <p>Wir können Ihre personenbezogenen Daten auch im Zusammenhang mit einem potenziellen oder tatsächlichen Verkauf oder einer Umstrukturierung unseres Unternehmens oder bestimmter unserer Vermögenswerte an Dritte weitergeben; in diesem Fall können Ihre Daten zu den übertragenen Vermögenswerten gehören</p>
            <p>Ihre personenbezogenen Daten können außerhalb des Europäischen Wirtschaftsraums (EWR) in Länder übertragen werden, die nicht das gleiche Schutzniveau für Ihre Daten bieten wie innerhalb des EWR. In Ermangelung eines Angemessenheitsbeschlusses der Europäischen Kommission gemäß Artikel 45 der DSGVO wird die Übermittlung Ihrer personenbezogenen Daten durch geeignete Übermittlungsmechanismen gemäß Artikel 46 ff. der DSGVO geregelt (z. B. durch von der Europäischen Kommission angenommene Standardvertragsklauseln). Sie können eine Kopie davon (mit Ausnahme der vertraulichen Bestimmungen) erhalten, indem Sie sich mit uns unter den in Artikel <a href="#privacy-policy-contact">« Kontakt »</a> genannten Kontaktdaten in Verbindung setzen.</p>
          </div>
          <div className="article-content with-list">
            <h2>5. WELCHE RECHTE HABEN SIE UND WIE KÖNNEN SIE DIESE AUSÜBEN? ?</h2>
            <p>
              Sie haben eine Reihe von Rechten über Ihre Daten.
            </p>
            <ul>
              <li>Sie können Ihre Einwilligung in die Verarbeitung Ihrer Daten, die auf Ihrer Einwilligung beruht, jederzeit widerrufen..</li>
              <li>Sie können von uns eine Bestätigung darüber verlangen, ob wir Ihre Daten verarbeiten, und, falls dies der Fall ist, Sie über die Merkmale der Verarbeitung informieren, Ihnen Zugang zu den Daten gewähren und eine Kopie davon erhalten.</li>
              <li>Sie können uns auffordern, Ihre Daten zu korrigieren oder zu vervollständigen, wenn sie falsch oder unvollständig sind.</li>
              <li>In einigen Fällen können Sie uns auffordern, Ihre Daten zu löschen oder die Verarbeitung Ihrer Daten einzuschränken.</li>
              <li>In einigen Fällen können Sie von uns verlangen, dass wir Ihre Daten in einem strukturierten, allgemein gebräuchlichen und maschinenlesbaren Format bereitstellen, oder Sie können verlangen, dass sie direkt an einen anderen Verantwortlichen übermittelt werden.</li>
              <li>Sie haben das Recht, Weisungen (allgemein oder speziell) darüber zu erteilen, was mit Ihren Daten nach Ihrem Tod geschehen soll. </li>
              <li className="bold">
                Sie können jeder Verarbeitung Ihrer Daten widersprechen, die auf unserem "berechtigten Interesse" beruht. Wenn Sie von diesem Recht Gebrauch machen, müssen wir die Verarbeitung einstellen, es sei denn, wir können zwingende schutzwürdige Gründe nachweisen, die Ihre Grundrechte und Grundfreiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. 
              </li>
              <li className="bold">
                Sie können der Verarbeitung Ihrer Daten zum Zwecke der Prospektierung jederzeit widersprechen.
              </li>
            </ul>
            <p>Sie können Ihre Rechte ausüben, indem Sie uns unter der in Artikel <a href="#privacy-policy-contact">« Kontakt »</a> angegebenen Adresse kontaktieren.</p>
            <p>Sie haben außerdem das Recht, bei der zuständigen Aufsichtsbehörde eine Beschwerde über die Verarbeitung Ihrer Daten einzureichen.</p>
          </div>
          <div className="article-content" id="privacy-policy-contact">
            <h2>6. KONTAKT</h2>
            <p>Für weitere Informationen über Ihre Rechte, deren Ausübung oder wenn Sie Fragen oder Beschwerden über den Schutz Ihrer persönlichen Daten haben, kontaktieren Sie uns bitte unter <a href="mailto: rgpd@wall-market.com">rgpd@wall-market.com</a>.</p>
          </div>
          <div className="article-content">
            <h2>7. ÄNDERUNGEN AN DER DATENSCHUTZRICHTLINIE </h2>
            <p>Wir behalten uns vor, diese Datenschutzrichtlinie von Zeit zu Zeit zu ändern, um z.B. rechtlichen Änderungen, technologischen Fortschritten und guten Geschäftspraktiken Rechnung zu tragen. Wir werden Sie über alle wesentlichen Änderungen informieren.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicyDE;
