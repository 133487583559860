import React, { Component } from 'react';

import { getUser } from '../../../helpers/UserFunctions';
import { autoScrollTop, initPlaceholder } from '../../../helpers/JqueryFunctions';
import Orders from './Orders';

class OrdersContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isCompanyAdmin: false,
      isCompanyToggled: false,
    }
  }

  componentDidMount() {
    initPlaceholder();
    autoScrollTop();
  }

  componentWillMount() {
    const user = getUser();

    if (user && user.company_admin) {
      this.setState({
        isCompanyAdmin: true,
        isCompanyToggled: true,
      });
    }
  }

  render() {

    const {
      isCompanyToggled,
      isCompanyAdmin
    } = this.state;

    return (
      <Orders
        {...this.props}
        isCompanyAdmin={isCompanyAdmin}
        initialValues={{
          companyToggle: isCompanyToggled
        }}
      />
    );
  }
}

export default OrdersContainer;
