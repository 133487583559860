import React, { Component } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import 'jquery-ui-dist/jquery-ui.min.js';

import Translation from '../../../translation/Translation';

class BeforeAfterSlider extends Component {

  render() {
    return (
      <div className="be-af-slider">
        <div className="slider-container">
          <div className="label-bef label-ba">{Translation().pages.sliders.before_after.before}</div>
          <div className="label-aft label-ba">{Translation().pages.sliders.before_after.after}</div>
          <div className="slider-aft">
            <div className="slider-bg bg-aft"></div>
          </div>
          <div className="slider-bef">
            <div className="slider-bg bg-bef"></div>
          </div>
          <div className="handle-wrapper">
            <div className="slider-bar">
              <div className="slider-handle ui-draggable ui-draggable-handle">
                <div className="icon-chevron_gauche slider-icon"></div>
                <div className="icon-chevron_droite slider-icon"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BeforeAfterSlider;

/**
 * Mobile compatibility
 */
// eslint-disable-next-line
!function(a){function f(a,b){if(!(a.originalEvent.touches.length>1)){a.preventDefault();var c=a.originalEvent.changedTouches[0],d=document.createEvent("MouseEvents");d.initMouseEvent(b,!0,!0,window,1,c.screenX,c.screenY,c.clientX,c.clientY,!1,!1,!1,!1,0,null),a.target.dispatchEvent(d)}}if(a.support.touch="ontouchend"in document,a.support.touch){var e,b=a.ui.mouse.prototype,c=b._mouseInit,d=b._mouseDestroy;b._touchStart=function(a){var b=this;!e&&b._mouseCapture(a.originalEvent.changedTouches[0])&&(e=!0,b._touchMoved=!1,f(a,"mouseover"),f(a,"mousemove"),f(a,"mousedown"))},b._touchMove=function(a){e&&(this._touchMoved=!0,f(a,"mousemove"))},b._touchEnd=function(a){e&&(f(a,"mouseup"),f(a,"mouseout"),this._touchMoved||f(a,"click"),e=!1)},b._mouseInit=function(){var b=this;b.element.bind({touchstart:a.proxy(b,"_touchStart"),touchmove:a.proxy(b,"_touchMove"),touchend:a.proxy(b,"_touchEnd")}),c.call(b)},b._mouseDestroy=function(){var b=this;b.element.unbind({touchstart:a.proxy(b,"_touchStart"),touchmove:a.proxy(b,"_touchMove"),touchend:a.proxy(b,"_touchEnd")}),d.call(b)}}}(jQuery);

$( document ).ready(function() {

  jQuery(function () {
    var before = $('.slider-bef');
    var handle = $('.handle-wrapper');
    var slider = $('.slider-container'); 
    var currentWidth = $(before).width();
    var newTopWidth = currentWidth * 1/2;
  
    before.width(newTopWidth);
    handle.css('left', newTopWidth - 28);

    function slideBar(e) {
      setTimeout(function () {
         var newWidth = (parseInt($(handle).css('left') , 10) + 28);
         before.width(newWidth);
      }, 10);
      e.stopPropagation();
    }

    handle.draggable({
        containment: slider,
        axis: 'x'
    });
    slider.bind('drag', slideBar);
  });
}); 