import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { reset, change } from 'redux-form';

import store from '../../redux/store';
import { getUser, getBrandPhoneNumber, getSupportEmail } from '../../helpers/UserFunctions';
import { CallApi } from '../../helpers/ApiFunctions';
import ContactForm from './ContactForm';
import Config from '../../config/Config';

import Translation from '../../translation/Translation';

const autoFillContactForm = () => {

  const user = getUser();

  if (user) {
    if (user.last_name)
      store.dispatch(change('contact', 'name', user.last_name));
    if (user.email)
      store.dispatch(change('contact', 'email', user.email));
  }
}

class Contact extends Component {

  componentDidUpdate() {
    /**
     * Autofill user infos in fields
     */
    autoFillContactForm();
  }

  sendContactForm(values) {

    if (!values || !values.text || !values.email || !values.name)
      return;

    const text = "Nouveau message depuis " + Config.app_name + ".\nNom de la personne: " + values.name +"\nEmail: " + values.email + "\nMessage: " + values.text;

    let contactObj = {
      text: text,
      from: {}
    };

    contactObj.from[values.name] = values.email;

    const btnContactText = document.getElementById('wrapper-contact-text');
    const sendContactIcon = document.getElementById('send-ctc-icon');
    const sendContactText = document.getElementById('send-ctc-txt');
    const sendContactButton = document.getElementById('send-contact');
    const apiLoader = document.getElementById('api-contact-loader');

    if (btnContactText && apiLoader) {
      btnContactText.style.display = "none";
      apiLoader.style.display = "block";;
    }

    CallApi(
      'post',
      'notifier/contact/send',
      null,
      contactObj,
      null,
      () => {
        if (sendContactIcon && sendContactText) {
          sendContactIcon.classList.remove('icon-envoyer');
          sendContactIcon.classList.add('icon-check');
          sendContactText.innerHTML = Translation().pages.contact.form.callbacks.success;
        }

        /**
         * Clean contact form fields
         */
        store.dispatch(reset('contact'));

        if (sendContactButton) {
          sendContactButton.disabled = "true";
        }
        if (apiLoader && btnContactText) {
          apiLoader.style.display = "none";
          btnContactText.style.display = "block";
        }
      },
      () => {
        if (apiLoader && btnContactText) {
          apiLoader.style.display = "none";
          btnContactText.style.display = "block";
        }
      }
    );
  }

  render() {

    return (
      <div className="app-contact">
        <div className="page-header-title">
          <h1>{Translation().pages.contact.page_title.title}</h1>
          <h2>{Translation().pages.contact.page_title.baseline}</h2>
        </div>
        <div className="container">
          <div className="row">
            <Col xs={12} sm={4} md={3} className="contact-col">
              <div className="infos-col">
                <h3>{Translation().pages.contact.informations.call_us}</h3>
                <ul>
                  <li><span className="icon-telephone icon-contact"></span>{getBrandPhoneNumber()}</li>
                  <li><span className="text-infos hours-infos"><span className="client-support">{Translation().pages.contact.informations.customer_support}</span><br/>
                    {Translation().pages.contact.informations.week} <br/>
                    {Translation().pages.contact.informations.hours}
                  </span></li>
                </ul>
                <h3>{Translation().pages.contact.informations.contact_infos}</h3>
                <ul>
                  <li><span className="icon-comment01 icon-contact"></span><span className="text-infos">{Config.brand_address}<br/> {Config.brand_city}</span></li>
                  <li><span className="icon-enveloppe icon-contact"></span><span className="text-infos">{getSupportEmail()}</span></li>
                </ul>
                <h3>{Translation().pages.contact.informations.follow_us}</h3>
                <div className="socials-network">
                  <a href="https://www.facebook.com/wallmarketlightshop/" target="_blank" rel="noopener noreferrer"><span className="icon-facebook icon-social"></span></a>
                  <a href="https://twitter.com/wallmarketserv" target="_blank" rel="noopener noreferrer"><span className="icon-twitter icon-social"></span></a>
                  <a href="https://www.instagram.com/lightshopwm/" target="_blank" rel="noopener noreferrer"><span className="icon-instagram icon-social"></span></a>
                  <a href="https://www.linkedin.com/company-beta/2598629/" target="_blank" rel="noopener noreferrer"><span className="icon-linkedin icon-social"></span></a>
                  <a href="https://fr.pinterest.com/wallmarket/" target="_blank" rel="noopener noreferrer"><span className="icon-pinterest icon-social"></span></a>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={8} md={9} className="contact-col">
              <div className="form-col">
                <div className="form-content">
                  <Col xs={12} sm={12} md={12}>
                    <h3>{Translation().pages.contact.form.title}</h3>
                  </Col>
                  <Clearfix/>
                  <ContactForm
                    onSubmit={this.sendContactForm}
                    labels={Translation().pages.contact.form.labels}
                    btnText={Translation().pages.contact.form.button}
                  />
                  <div form="contact" />
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
