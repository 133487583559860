import Translation from '../../translation/Translation';

const validate = values => {

  const errors = {};

  if (!values.new_password) {
    errors.new_password = Translation().forms.errors.required;
  }
  if (values.new_password && values.new_password.length < 8) {
    errors.new_password = Translation().forms.errors.password_length;
  }
  if (!values.confirmPwd) {
    errors.confirmPwd = Translation().forms.errors.required;
  }
  if (values.new_password && values.confirmPwd) {
    if (values.new_password !== values.confirmPwd) {
      errors.confirmPwd = Translation().forms.errors.password_not_matching;
    }
  }
  return errors;
}

export default validate;
