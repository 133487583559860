import Translation from '../../../translation/Translation';

const validate = (values) => {
  const errors = {};

  if (!values.address) {
    errors.address = Translation().forms.errors.required;
  }

  if (!values.size) {
    errors.size = Translation().forms.errors.required;
  }

  if (!values.dateSelected) {
    errors.dateSelected = Translation().forms.errors.required;
  }

  if (!values.invoiceAddress) {
    errors.invoiceAddress = Translation().forms.errors.required;
  }

  if (!values.paymentMethod) {
    errors.paymentMethod = Translation().forms.errors.required;
  }

  if (!values.termsOfSales) {
    errors.termsOfSales = Translation().forms.errors.required;
  }

  if (!values.onPlace) {
    errors.onPlace = Translation().forms.errors.required;
  }

  if (!values.phone) {
    errors.phone = Translation().forms.errors.required;
  }

  if (!values.delegate_email) {
    errors.delegate_email = Translation().forms.errors.required;
  }

  if (
    values.delegate_amount &&
    !/^\d*\.?\d*\,?\d*%?$/.test(values.delegate_amount)
  ) {
    errors.delegate_amount = Translation().shop.payment_delegate.error_regexp;
  }

  return errors;
};

export default validate;
