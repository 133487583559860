import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import Translation from '../../../translation/Translation';

import { convertMinsToHrsMins, dateInTz } from '../../../helpers/DateFunctions';

class CartItem extends Component {
  render() {
    const { item, productInfos, priceInfos = false, timezoneCode } = this.props;

    const serviceDuration = convertMinsToHrsMins(
      item && item.duration,
      Translation().cart.time_separator
    );

    return (
      <div className="cart-item">
        <p className="item-title">
          {item && item.name && productInfos
            ? item.name
            : item && item.product_group_name
            ? item.product_group_name
            : ''}
        </p>
        {productInfos && (
          <Col
            xs={12}
            sm={12}
            md={priceInfos ? 6 : 12}
            lg={priceInfos ? 7 : 12}
            className="item-options"
          >
            {item.options && item.options.length > 0
              ? item.options.map((option, index) => {
                  return (
                    <p key={index} className="cart-option">
                      - {option.name && option.name}
                    </p>
                  );
                })
              : ''}
          </Col>
        )}
        {priceInfos && (
          <Col xs={12} sm={12} md={6} lg={5} className="col-price">
            <p className="item-price without-tax">
              <span>{Translation().shop.tax_excluded}</span>{' '}
              {item && item.amount_tax_excluded_formatted
                ? item.amount_tax_excluded_formatted
                : 0}
            </p>
            <p className="item-price with-tax">
              <span>{Translation().shop.tax_included}</span>{' '}
              {item && item.amount_formatted ? item.amount_formatted : 0}
            </p>
          </Col>
        )}
        <Clearfix />
        {item.start && (
          <div className="item-planning">
            <p>
              {Translation().cart.day_prefix}{' '}
              {item.start &&
                dateInTz(item.start, 'localized-date', timezoneCode)}{' '}
              {Translation().cart.hours_prefix}{' '}
              {item.start && dateInTz(item.start, 'HH:mm', timezoneCode)}{' '}
              {Translation().cart.during}{' '}
              {serviceDuration ? serviceDuration : ''}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default CartItem;
