import { CallApi } from './ApiFunctions';
import store from '../redux/store';
import { formValueSelector, change } from 'redux-form';

import Translation from '../translation/Translation';
import { autoScrollTop, removeElement } from './JqueryFunctions';
import { isFloat, isInteger } from './HelperFunctions';
import {
  storeProperty,
  removeProperty,
  storeServiceFamilies,
  removeServiceFamilies,
  storeServices,
  removeServices,
  storeOptions,
  removeOptions,
  storePlanning,
  storeServiceFamilyStatus,
  storeRequirePlanning,
  removeRequirePlanning,
  storeSlotSelected,
  removeSlotSelected,
  storePropertyTimezone,
  storePropertyLocation,
} from '../redux/actions/shop/shopActions';

/**
 * Set the property into redux store
 */
export const setProperty = (property) => {
  if (property) {
    store.dispatch(storeProperty(property));
  }
};
export const setPropertyTimezone = (tz) => {
  store.dispatch(storePropertyTimezone(tz));
};
export const setPropertyLocation = (location) => {
  store.dispatch(storePropertyLocation(location));
};

/**
 * Unset property into redux store
 */
export const unsetProperty = () => {
  store.dispatch(removeProperty());
};

/**
 * Get the property into redux store
 */
export const getProperty = () => {
  const state = store.getState();

  if (state && state.property) {
    const property = state.property;
    return property;
  }
};
export const getPropertyGeo = () => {
  const state = store.getState();

  if (state && state.propertyGeo) {
    const propertyGeo = state.propertyGeo;
    return propertyGeo;
  }
};

/**
 * Update the current shop property by missing fields values
 */
export const updateProperty = (fieldsIds) => {
  if (!fieldsIds) {
    return;
  }

  const property = getProperty();
  let newProperty = property;

  if (fieldsIds.length > 0) {
    for (let i = 0; i < fieldsIds.length; i++) {
      newProperty[fieldsIds[i].replace('input-', '')] = document.getElementById(
        fieldsIds[i]
      ).value;
    }

    /**
     * Set the property updated to redux form
     */
    setProperty(newProperty);
  }
};

export const checkMissingFieldsValues = (fields, fieldsIds) => {
  let fieldsValues = [];
  let fieldsTypes = [];
  let errorMessage = '';
  const modalError = document.getElementsByClassName('mf-modal-error')[0];

  if (fieldsIds.length > 0) {
    for (let i = 0; i < fieldsIds.length; i++) {
      fieldsValues.push(document.getElementById(fieldsIds[i]).value);

      for (let t = 0; t < fields.length; t++) {
        fieldsTypes.push(fields[t].field.input_type);

        for (let j = 0; j < fieldsValues.length; j++) {
          for (let k = 0; k < fieldsTypes.length; k++) {
            if (fieldsValues[j].length < 0) {
              errorMessage = Translation().forms.errors.complete_fields;

              if (modalError) {
                modalError.style.display = 'block';
                modalError.innerHTML =
                  '<div id="m-error-field" class="error-message">' +
                  errorMessage +
                  ' </div>';
              }
              return false;
            }
            if (fieldsTypes[k] === 'number' && !isInteger(fieldsValues[j])) {
              errorMessage = Translation().forms.errors.only_numbers;

              if (modalError) {
                modalError.style.display = 'block';
                modalError.innerHTML =
                  '<div id="m-error-field" class="error-message">' +
                  errorMessage +
                  ' </div>';
              }
              return false;
            } else if (
              fieldsTypes[k] === 'float' &&
              !isFloat(fieldsValues[j])
            ) {
              errorMessage = Translation().forms.errors.only_numbers;

              if (modalError) {
                modalError.style.display = 'block';
                modalError.innerHTML =
                  '<div id="m-error-field" class="error-message">' +
                  errorMessage +
                  ' </div>';
              }
              return false;
            } else {
              return true;
            }
          }
        }
      }
    }
  }
};

/**
 * Trigger the missing field shop modal on callback error
 */
export const triggerMissingFieldModal = (errorData, callBackFunction) => {
  const modal = document.getElementsByClassName('overlay-missing-field')[0];
  const errors = errorData.errors;

  const previousFields = document.getElementById('update-fields');

  if (previousFields) {
    removeElement(previousFields);
  }

  if (modal) {
    modal.style.display = 'block';
    let fieldsIds = [];

    const modalContent = document.getElementsByClassName('mf-modal-content')[0];
    let fieldZone = document.createElement('div');
    let fieldZoneHTML =
      '<div id="update-fields"><span class="icon-fermer" id="close-mfm"></span>';

    for (let i = 0; i < errors.length; i++) {
      if (errors[i].field) {
        fieldsIds.push('input-' + errors[i].field.name);
      }

      if (modalContent) {
        if (errors[i].field.choices && errors[i].field.choices.length > 0) {
          let options = errors[i].field.choices;

          fieldZoneHTML +=
            '<label>' +
            errors[i].field.label +
            '</label><select id="input-' +
            errors[i].field.name +
            '" class="app-basic-input" name="' +
            errors[i].field.name +
            '">';

          for (let j = 0; j < options.length; j++) {
            fieldZoneHTML +=
              '<option id="option-' +
              options[j].id +
              '" value="' +
              options[j].value +
              '">' +
              options[j].value +
              '</option>';
          }
          fieldZoneHTML += '</select>';
        } else if (errors[i].field.type === 'textarea') {
          fieldZoneHTML +=
            '<label>' +
            errors[i].field.label +
            '</label><textarea rows="10" id="input-' +
            errors[i].field.name +
            '" class="app-basic-input" name="' +
            errors[i].field.name +
            '"/></textarea>';
        } else if (
          errors[i].field.type === 'image' ||
          errors[i].field.type === 'file'
        ) {
          fieldZoneHTML +=
            '<label>' +
            errors[i].field.label +
            '</label><input id="input-' +
            errors[i].field.name +
            '" class="app-basic-input" type="file" name="' +
            errors[i].field.name +
            '"/>';
        } else if (
          errors[i].field.type === 'bool' ||
          errors[i].field.type === 'boolean'
        ) {
          fieldZoneHTML +=
            '<input id="input-' +
            errors[i].field.name +
            '" type="checkbox" value="' +
            errors[i].field.value +
            '" name="' +
            errors[i].field.name +
            '"/>';
        } else if (
          errors[i].field.type === 'float' ||
          errors[i].field.type === 'integer'
        ) {
          fieldZoneHTML +=
            '<label>' +
            errors[i].field.label +
            '</label><input id="input-' +
            errors[i].field.name +
            '" class="app-basic-input" type="number" name="' +
            errors[i].field.name +
            '"/>';
        } else {
          fieldZoneHTML +=
            '<label>' +
            errors[i].field.label +
            '</label><input id="input-' +
            errors[i].field.name +
            '" class="app-basic-input" type="text" name="' +
            errors[i].field.name +
            '"/>';
        }
      }
    }

    fieldZoneHTML +=
      '<button id="send-mf" class="app-button-color"><span id="mf-loader" class="loader-api"></span><span id="send-mf-text">' +
      Translation().shop.step_buttons.send +
      '</span></button>';
    fieldZoneHTML += '</div>';
    fieldZone.innerHTML = fieldZoneHTML;
    document
      .getElementsByClassName('mf-modal-content')[0]
      .appendChild(fieldZone);
    autoScrollTop();

    const btnSend = document.getElementById('send-mf');
    const btnClose = document.getElementById('close-mfm');
    const mfBtnLoader = document.getElementById('mf-loader');
    const mfBtnText = document.getElementById('send-mf-text');

    if (btnClose && modal) {
      btnClose.onclick = function () {
        /**
         * window.stop() => for stop all xhr request in progress
         */
        window.stop();
        modal.style.display = 'none';
        document
          .getElementsByClassName('mf-modal-content')[0]
          .removeChild(fieldZone);
      };
    }

    if (btnSend) {
      btnSend.onclick = function () {
        if (checkMissingFieldsValues(errors, fieldsIds) === true) {
          if (mfBtnLoader && mfBtnText) {
            mfBtnText.style.display = 'none';
            mfBtnLoader.style.display = 'block';
          }
          updateProperty(fieldsIds);
          callBackFunction();
        } else {
          if (mfBtnLoader && mfBtnText) {
            mfBtnLoader.style.display = 'none';
            mfBtnText.style.display = 'block';
          }
        }
      };
    }
  }
};

/**
 * Get the family of services from the API
 */
export const apiGetFamilyService = (
  property,
  callBackSuccess,
  callBackError
) => {
  CallApi(
    'get',
    'product-groups/available',
    { sort: 'position', property: property },
    null,
    null,
    callBackSuccess,
    callBackError
  );
};

/**
 * Get the family of services from the redux store
 */
export const getServiceFamilies = () => {
  const state = store.getState();

  if (state && state.serviceFamilies) {
    const serviceFamilies = state.serviceFamilies;
    return serviceFamilies;
  }
};

/**
 * Set the family of services from the API into redux store
 */
export const setServiceFamilies = (servicesFamilies) => {
  if (servicesFamilies) {
    store.dispatch(storeServiceFamilies(servicesFamilies));
  }
};

/**
 * Set the family of services from the API into redux store
 */
export const unsetServiceFamilies = () => {
  store.dispatch(removeServiceFamilies());
};

/**
 * Set the status of the services families
 */
export const setServiceFamilyStatus = (id, status) => {
  if (id && status) {
    store.dispatch(storeServiceFamilyStatus(id, status));
  }
};

/**
 * Get the status of the services families
 */
export const getServiceFamilyStatus = (idFamily) => {
  const state = store.getState();

  if (!state) return;

  for (let i = 0; i < state.serviceFamilies.length; i++) {
    if (state.serviceFamilies[i].id === idFamily) {
      return state.serviceFamilies[i].status;
    }
  }
};

/**
 * Get the availables services of a service family from the API
 */
export const apiGetServices = (
  idFamily,
  property,
  callBackSuccess,
  callBackError
) => {
  if (idFamily && property) {
    CallApi(
      'get',
      'products/available',
      {
        property: property,
        filters: 'product_group_id|eq|' + idFamily,
        sort: 'position',
      },
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Set the services from the API into redux store
 */
export const setServices = (services) => {
  if (services) {
    store.dispatch(storeServices(services));
  }
};

/**
 * Get the services from the redux store
 */
export const getServices = () => {
  const state = store.getState();

  if (state && state.services) {
    const services = state.services;
    return services;
  }
};

/**
 * Remove services in the redux store
 */
export const unsetServices = () => {
  store.dispatch(removeServices());
};

/**
 * Get the current service selected
 */
export const getServiceSelected = () => {
  const state = store.getState();
  const selector = formValueSelector('shop');
  const serviceSelected = selector(state, 'serviceSelected');
  return serviceSelected;
};

export const unsetServiceSelected = () => {
  store.dispatch(change('shop', 'serviceSelected', null));
};

/**
 * Get the options for one service selected
 */
export const apiGetOptions = (
  serviceId,
  property,
  callBackSuccess,
  callBackError
) => {
  if (serviceId && property) {
    CallApi(
      'get',
      'products/' + serviceId + '/options/available',
      { property: property },
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Set the options from the API into redux store
 */
export const setOptions = (options) => {
  if (options) {
    store.dispatch(storeOptions(options));
  }
};

/**
 * Get the options from the redux store
 */
export const getOptions = () => {
  const state = store.getState();

  if (state && state.options) {
    const options = state.options;
    return options;
  }
};

/**
 * Remove options in the redux store
 */
export const unsetOptions = () => {
  store.dispatch(removeOptions());
};

/**
 * Remove options values in the redux store
 */
export const unsetOptionsValues = () => {
  store.dispatch(change('shop', 'options', null));
};

/**
 * Get tue current options selected
 */
export const getOptionsSelected = () => {
  const state = store.getState();
  const selector = formValueSelector('shop');
  const optionsSelected = selector(state, 'options');
  return optionsSelected;
};

/**
 * Get the planning dates for one service and his options selected
 */
export const apiGetPlanning = (
  start = null,
  end = null,
  serviceId,
  options,
  property,
  callBackSuccess,
  callBackError
) => {
  if (serviceId && property) {
    CallApi(
      'get',
      'products/available/' + serviceId + '/slots',
      { options, property, start, end },
      null,
      null,
      callBackSuccess,
      callBackError
    );
  }
};

/**
 * Set the planning from the API into redux store
 */
export const setPlanning = (planning) => {
  if (planning) {
    store.dispatch(storePlanning(planning));
  }
};

/**
 * Get the planning from the redux store
 */
export const getPlanning = () => {
  const state = store.getState();

  if (state && state.planning) {
    const planning = state.planning;
    return planning;
  }
};

/**
 * Calculate the service price with options
 */
export const calcCurrentServicePrice = () => {
  const services = getServices();
  const options = getOptions();
  const serviceSelected = getServiceSelected();
  const optionsSelected = getOptionsSelected();
  let servicePrice = 0;
  let optionsPrice = 0;
  let totalPrice = 0;

  if (!serviceSelected || !services) {
    return totalPrice;
  }

  for (let i = 0; i < services.length; i++) {
    if (serviceSelected === services[i].id) {
      servicePrice = services[i].price_tax_excluded;
    }
  }

  totalPrice += servicePrice;

  if (!optionsSelected || !options || !options.length) {
    return totalPrice;
  }

  for (let optionSelected in optionsSelected) {
    for (let k = 0; k < options.length; k++) {
      if (optionSelected.replace('option-', '') === options[k].id.toString()) {
        optionsPrice += options[k].price_tax_excluded;
      }
    }
  }

  totalPrice += optionsPrice;

  return totalPrice;
};

/**
 * Calculate the service duration with options
 */
export const calcCurrentServiceDuration = () => {
  const services = getServices();
  const options = getOptions();
  const serviceSelected = getServiceSelected();
  const optionsSelected = getOptionsSelected();
  let serviceDuration = 0;
  let optionsDuration = 0;
  let totalDuration = 0;

  if (!serviceSelected || !services) {
    return totalDuration;
  }

  for (let i = 0; i < services.length; i++) {
    if (serviceSelected === services[i].id) {
      serviceDuration = services[i].duration;
    }
  }

  totalDuration += serviceDuration;

  if (!optionsSelected || !options || !options.length) {
    return totalDuration;
  }

  for (let optionSelected in optionsSelected) {
    for (let k = 0; k < options.length; k++) {
      if (optionSelected.replace('option-', '') === options[k].id.toString()) {
        optionsDuration += options[k].duration;
      }
    }
  }

  totalDuration += optionsDuration;

  return totalDuration;
};
/**
 * Set the required planning service bool
 */
export const setRequirePlanning = (bool) => {
  if (bool) {
    store.dispatch(storeRequirePlanning(bool));
  }
};

/**
 * Unset the current service family amount into redux store
 */
export const unsetRequirePlanning = () => {
  store.dispatch(removeRequirePlanning());
};

/**
 * Get the current service family amount from redux store
 */
export const getRequirePlanning = () => {
  const state = store.getState();

  if (state && state.requirePlanning) {
    const isPlanningRequired = state.requirePlanning;
    return isPlanningRequired;
  }
};

/**
 * Set the slot time selected in shop planning into redux store
 */
export const setSlotSelected = (slot) => {
  if (slot) {
    store.dispatch(storeSlotSelected(slot));
  }
};

/**
 * Unset the slot time selected in shop planning into redux store
 */
export const unsetSlotSelected = () => {
  store.dispatch(removeSlotSelected());
};

/**
 * Get the slot time selected in shop planning from redux store
 */
export const getSlotSelected = () => {
  const state = store.getState();

  if (state && state.slotSelected) {
    const slotSelected = state.slotSelected;
    return slotSelected;
  }
};

/**
 * Send the final order object
 */
export const apiPostOrder = (orderObj, callBackSuccess, callBackError) => {
  if (orderObj && callBackSuccess && callBackError) {
    CallApi(
      'post',
      'carts/self/order',
      null,
      orderObj,
      null,
      callBackSuccess,
      callBackError
    );
  }
};
