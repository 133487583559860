import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Config from '../../../../config/Config';
import Translation from '../../../../translation/Translation';

class OrderProductMedia extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isZoomModalOpen: false
    }

    this.downloadMedia = this.downloadMedia.bind(this);
    this.openZoomMediaModal = this.openZoomMediaModal.bind(this);
    this.closeZoomMediaModal = this.closeZoomMediaModal.bind(this);
  }

  downloadMedia() {
      const url = this.props.media.url + '/download?client_id=' + Config.api_key;

      const downloadIframe = document.getElementById('frame-download');
      const iframeContainer =  document.getElementById('frames-container');

      /*
       * If iframe already exists we only change url, if not we create it
       */           
      if (downloadIframe) {
        downloadIframe.setAttribute('src', url);
      }
      else { 
        const downloadIframe =  '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +  url + ' />';
        iframeContainer.innerHTML = downloadIframe;
      }
  }

  openZoomMediaModal() {
    this.setState({ isZoomModalOpen: true });

    const imgModalStyle =  { backgroundImage: 'url("' +  (this.props.media.url ? this.props.media.url : this.props.media.thumbnail_url) + '?client_id=' + Config.api_key + '")' };

    /**
     * Force to use ReactDom render form inner modal in bottom of DOM
     */
    ReactDOM.render(
      <div id="order-media-dialog">
        <div className="mm-overlay">
          <div className="media-modal-view">
            <div className="img-view" style={imgModalStyle}>
              <div className="close-mmv" onClick={this.closeZoomMediaModal}>
                <span className="icon-fermer" /> {Translation().account.orders.close}
              </div>
            </div>
          </div>
        </div>
      </div>
      , document.getElementById('frames-container')
    );
  }

  closeZoomMediaModal() {
    const framesContainer = document.getElementById('frames-container');
    const orderMediaDialog = document.getElementById('order-media-dialog');

    /**
     * Check if modal exists then remove it from DOM
     */
    if (orderMediaDialog && framesContainer)
      framesContainer.removeChild(orderMediaDialog);
  }

  render() {

    const { media } = this.props;

    /**
     * Check if client id is already in the img url
     */    
    let imgPreviewStyle = null;

    if (media.thumbnail_url) 
      imgPreviewStyle = { backgroundImage: 'url("' + ( (media.thumbnail_url.indexOf('?client_id=' + Config.api_key)) !== -1 ? (media.thumbnail_url + '?client_id=' + Config.api_key) : media.thumbnail_url) + '")' };
    else if (media.extension)
      imgPreviewStyle = { backgroundImage: 'url("/assets/images/medias/file-extension-' + media.extension + '.png")' }
    else
      return false;

    return (
      <div className="prod-media">
        <div className="img-preview" style={imgPreviewStyle}>
          <div className="media-actions">
            {/*<div className="icon-search action-icon" onClick={this.openZoomMediaModal} />*/}
            <div className="icon-download action-icon" onClick={this.downloadMedia} />
          </div>
        </div>
      </div>
    );
  }
}

export default OrderProductMedia; 