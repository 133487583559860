import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import { autoScrollTop } from '../../helpers/JqueryFunctions';

import Translation from '../../translation/Translation';

class HowItWork extends Component {

  componentDidMount() {
    autoScrollTop();
  }

  render() {

    return (
      <div className="app-hiw">
        <div className="page-header-title">
          <h1>{Translation().pages.how_it_work.page_title.title}</h1>
          <h2>{Translation().pages.how_it_work.page_title.baseline}</h2>
        </div>
        <section className="inquire-hiw">
          <div className="container">
            <div className="row">
              <Col xs={12} sm={5} md={5}>
                <img src="/assets/images/how_it_work/chose-service.png" alt="services" className="image-left"/>
              </Col>
              <Col sm={2} md={2} className="step-track">
                <div className="tracker">
                  <span className="icon-chevron_bas" />
                </div>
              </Col>
              <Col xs={12} sm={5} md={5} className="step-description">
                <div className="icon-packs step-icon" />
                <h3>{Translation().pages.how_it_work.first_step.title}</h3>
                <p className="title-baseline">{Translation().pages.how_it_work.first_step.text}</p>
              </Col>
            </div>
          </div>
          <Clearfix/>
        </section>
        <section className="select-hiw">
          <div className="container">
            <div className="row">
              <Col xs={12} sm={5} md={5} className="step-description">
                <div className="icon-comment03 step-icon" />
                <h3>{Translation().pages.how_it_work.second_step.title}</h3>
                <p className="title-baseline">{Translation().pages.how_it_work.second_step.text}</p>
              </Col>
              <Col sm={2} md={2} className="step-track">
                <div className="tracker">
                  <span className="icon-chevron_bas" />
                </div>
              </Col>
              <Col xs={12} sm={5} md={5}>
                <img src="/assets/images/how_it_work/chose-date.png" alt="selectionner" className="image-left"/>
              </Col>
            </div>
          </div>
          <Clearfix/>
        </section>
        <section className="reserv-hiw">
          <div className="container">
            <div className="row">
              <Col xs={12} sm={5} md={5}>
                <img src="/assets/images/how_it_work/provider-interventions.png" alt="reserver" className="image-left"/>
              </Col>
              <Col sm={2} md={2} className="step-track">
                <div className="tracker">
                  <span className="icon-chevron_bas" />
                </div>
              </Col>
              <Col xs={12} sm={5} md={5} className="step-description">
                <div className="icon-comment01 step-icon" />
                <span className="track-mq-limit" />
                <h3>{Translation().pages.how_it_work.third_step.title}</h3>
                <p className="title-baseline">{Translation().pages.how_it_work.third_step.text}</p>
              </Col>
            </div>
          </div>
          <Clearfix/>
        </section>
        <section className="download-hiw">
          <div className="container">
            <div className="row">
              <Col xs={12} sm={5} md={5} className="step-description">
                <div className="icon-comment04 step-icon" />
                <h3>{Translation().pages.how_it_work.last_step.title}</h3>
                <p className="title-baseline">{Translation().pages.how_it_work.last_step.text}</p>
              </Col>
              <Col sm={2} md={2} className="step-track">
                <div className="tracker">
                  <span className="icon-chevron_bas" />
                </div>
              </Col>
              <Col xs={12} sm={5} md={5}>
                <img src="/assets/images/how_it_work/check-order.png" alt="télécharger" className="image-left"/>
              </Col>
            </div>
          </div>
          <Clearfix/>
        </section>
        <div className="get-ready">
          <div className="container">
            <div className="row">
              <h3>{Translation().pages.how_it_work.ready.title}</h3>
              <a href="/shop" className="app-button-color">{Translation().pages.how_it_work.ready.button}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowItWork;
