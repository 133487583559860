import React, { Component } from 'react';

/**
 * For add a country, check Config file => open_countries_lang array
 */
class LanguageSelector extends Component {

  render() {

    const {
      languages,
      selected,
      selectLang
    } = this.props;

    return (
      <ul className="dropdown-lang-desktop">
        { languages && languages.map((lang, index) => {
          if (selected !== lang.code) {
            return (
              <li key={index} onClick={() => selectLang(lang.code)}>
                <img
                  src={"/assets/images/commons/languages/flag-" + lang.code + ".png"}
                  alt={lang.name}
                  width={16}
                />
              </li>
            );
          }
            else return false;
        })
        }
      </ul>
    );
  }
}

export default LanguageSelector;
