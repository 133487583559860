import React from 'react';

const renderTextareaField = ({ input, textarea, label, type, className, id, meta: { touched, error } }) => {
  return (
    <div>
      <textarea
        {...input}
        placeholder={label}
        type={type}
        className={error && touched ? (className + " field-get-error") : className}
        id={id}
      />
      {touched && error && <span className="error-message error-field">{error}</span>}
    </div>
  );
}

export default renderTextareaField;
