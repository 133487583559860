import $ from 'jquery';
import 'jquery-placeholder/jquery.placeholder.js';

/** 
 * Init IE 9~ placeholders
 */
export const initPlaceholder = () => {
  $('input, textarea').placeholder({ customClass: 'ie-fix-placeholder' });
}

/** 
 * Remove element from dom
 */
export const removeElement = (element) => {
  $(element).remove();
}

/** 
 * Init shop slider
 */
export const initSampleslider = (index) => {
  setTimeout(() => $('#sample-slider-' + index).slick({
    dots: true,
    arrows: true,
    autoplay: false,
    infinite: true,
    waitForAnimate: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<div class="arrow-left icon-chevron_gauche"></div>',
    nextArrow: '<div class="arrow-right icon-chevron_droite"></div>',
  }), 20);
}

/** 
 * Scroll auto top when switching shop steps
 */
export const autoScrollTop = () => {
  $('html,body').animate( {scrollTop: 0}, 800);
}

export const scrollToAnchor = (anchor) => {
  $('html, body').animate({
    'scrollTop': $(anchor).offset().top - 80
  }, 1000);
}

/** 
 * Scroll auto on service's steps
 */
export const serviceScroll = (familyServiceId) => {

  if (!familyServiceId)
    return;

  const speed = 800;
  const $domPage = $('html, body');
  const $elem = $('.family-' + familyServiceId);
  const $target = $elem.offset().top;

  $domPage.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
     $domPage.stop();
  });
  $domPage.animate( { scrollTop: $target }, speed, function() { 
     $domPage.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
  });
  return false;
}

/** 
 * Calculate the width of the services shop row
 */
export const setNewPosition = (div, index) => {
  const currentWidth = $(''+ div + '').width();

  /** 
   * Remove this by a dynamic function
   */
  if (div) {
    const borderWidth = 1;
    let divider = 0;

    if (index === 1 || index === 4 || index === 7 || index === 10)
      return -1; 
    if (index === 2 || index === 5 || index === 8 || index === 11)
      divider = 3
    if (index === 3 || index === 6 || index === 9 || index === 12)
      divider = 1.5

    const newPosition = (currentWidth / divider) + borderWidth;
    return -newPosition;
  }
  else
    return 0;
}

export const getDivWidth = (div) => {
  if (div) {
    const padding = 30;
    const newWidth = ( $(''+ div + '').width() - padding);
    return newWidth;
  }
  else
    return 0;
}

/** 
 * Calc width and position of order cards
 */
export const adjustOrderCard = () => {
  const wrapperWidth = $('.orders-container').width();

  $('.order-content').each(function() {
    const offset = $(this).position().left;
    const sidePadding = 0;
    $(this).width(wrapperWidth);
    $(this).css('margin-left', '-' + (offset + sidePadding) + 'px');
  });
}

export const orderContentScroll = (orderContentId) => {

  if (!orderContentId)
    return;

  const speed = 800;
  const headerHeight = 130;
  const orderCardHeight = $('.cover').height();
  const $domPage = $('html, body');
  const $elem = $('#order-' + orderContentId);
  const $target = $elem.offset().top -= (headerHeight - orderCardHeight);

  $domPage.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
     $domPage.stop();
  });
  $domPage.animate( { scrollTop: $target }, speed, function() { 
     $domPage.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
  });
  return false;
}

$(window).resize(function() {
  adjustOrderCard();
});

$(document).ready(function() {

  initPlaceholder();

  /** 
   * Init smooth scroll
   */
  const speed = 750;
  const domPage = $('html, body');

  $('.scroll-anch').on('click', function() {
    const page = $(this).attr('href');
    const target = $(page).offset().top -= 100;

    domPage.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
       domPage.stop();
    });
    domPage.animate( { scrollTop: target }, speed, function() {
       window.location.hash = page;
       domPage.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
    });
    return false;
  });

  /** 
   * Add scroll to top button when user is on bottom page
   */  
  $(window).scroll(function() {
    if( $(window).scrollTop() + $(window).height() === $(document).height() ) {
     $('#scroll-to-top').fadeIn();
    }
    else {
      $('#scroll-to-top').fadeOut();
    }
  });
  $('#scroll-to-top').on('click', function() {
    domPage.animate( { scrollTop: 0 }, speed, function() {
       domPage.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
    });
    return false;
  });

  /** 
   * Change aspect of radio group on first page load
   */
  $('.radio-group').on('click', function() {
    $('.radio-group').removeClass('property-selected');
    $(this).addClass('property-selected');
  });

}); 