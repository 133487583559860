import React, { Component } from 'react';
import { subscribe } from 'redux-subscriber';
import { Alert } from 'react-bootstrap';

import LoginView from '../../../components/commons/login/LoginView';
import { parseUrl } from '../../../helpers/HelperFunctions';
import {
  acceptOrderDelegate,
  getOrderDelegate,
} from '../../../helpers/AccountFunctions';

import RegisterDelegateUser from './components/register/RegisterDelegateUser';
import Payment from './components/payment/Payment';
import PaymentShortlink from './components/payment/PaymentShortlink';
import Order from '../../../components/commons/delegate/Order';
import Translation from '../../../translation/Translation';

class AcceptDelegate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: undefined,
      delegate_email: undefined, // string
      delegate_token: undefined, // string
      delegate_user_exists: undefined, // 0 or 1
      id: undefined, // integer
      error: undefined, // string
      user_logged: false, // boolean
      loading: false, // boolean
      success: false, // boolean
      order: null, // the order to delegate
      order_loading: false, // boolean
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    this.handleRegisterSuccess = this.handleRegisterSuccess.bind(this);
    this.handleSubmitRegister = this.handleSubmitRegister.bind(this);

    this.subscribeUser = undefined;
  }

  componentWillMount() {
    const urlQueryString = parseUrl(window.location);
    const query = urlQueryString.query;

    // Check window location for get all delegate params required for the process.
    if (query) {
      if (query.delegate_token) {
        this.setState({
          delegate_token: query.delegate_token,
        });
      } else {
        this.setState({ error: 'Error url params: no delegate_token found.' });
      }

      if (query.delegate_email) {
        this.setState({
          delegate_email: query.delegate_email,
        });
      } else {
        this.setState({
          error: 'Error url params: no delegate_email found.',
        });
      }

      if (query.delegate_user_exists !== undefined) {
        this.setState({
          delegate_user_exists: query.delegate_user_exists,
        });
      } else {
        this.setState({
          error: 'Error url params: no delegate_user_exists found.',
        });
      }

      if (query.id) {
        this.setState({
          id: query.id,
        });
      } else {
        this.setState({
          error: 'Error url params: no id found.',
        });
      }

      /**
       * Get the order to delegate for check status and display infos
       */
      if (query.id && query.delegate_token) {
        this.setState({
          order_loading: true,
          order: null,
        });

        getOrderDelegate(
          query.id,
          { delegate_token: query.delegate_token },
          (order) => {
            this.setState({
              order_loading: false,
              order,
            });

            if (order && order.delegate_status === true) {
              this.setState({
                error: Translation().pages.delegate.errors[0],
              });
            } else if (order && order.delegate_status === false) {
              this.setState({
                error: Translation().pages.delegate.errors[1],
              });
            }
          },
          (error) => {
            const errorMessage =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.detail
                ? error.response.data.detail
                : Translation().pages.delegate.errors[2];

            this.setState({
              error: errorMessage,
              order_loading: false,
            });
          }
        );
      }
    } else {
      this.setState({
        error: 'Error url params: no query found.',
      });
    }

    this.subscribeUser = subscribe('user', (state) => {
      if (state && state.user) {
        this.setState({ user_logged: true });
      } else {
        this.setState({ user_logged: false });
      }
    });
  }

  handleLoginSuccess() {
    this.setState({ user_logged: true });
  }

  handleRegisterSuccess(address) {
    this.setState({ address, user_logged: true });
  }

  handleSubmitRegister() {
    this.subscribeUser();
    this.subscribeUser = undefined;
  }

  handleSubmit(values) {
    const { id, address, delegate_token } = this.state;

    const delegate_invoice_address_id = address
      ? address
      : values.address
      ? values.address
      : null;
    const delegate_invoice_payment_method_id = values.payment_method
      ? values.payment_method
      : null;

    if (
      !delegate_invoice_address_id ||
      !delegate_invoice_payment_method_id ||
      !delegate_token
    )
      return;

    this.setState({
      error: null,
      success: false,
      loading: true,
    });

    acceptOrderDelegate(
      id,
      {
        delegate_invoice_address_id,
        delegate_invoice_payment_method_id,
        delegate_token,
      },
      () => {
        this.setState({
          error: null,
          success: true,
          loading: false,
        });
      },
      (error) => {
        const errorMessage =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : Translation().pages.delegate.errors[2];

        this.setState({
          success: false,
          error: errorMessage,
          loading: false,
        });
      }
    );
  }

  render() {
    const {
      address,
      delegate_email,
      delegate_user_exists,
      error,
      user_logged,
      loading,
      success,
      order,
      order_loading,
    } = this.state;

    return (
      <div className="payment-delegate accept-delegate">
        <div className="container">
          <div className="page-header-title">
            <h1>{Translation().pages.delegate.accept.title}</h1>
          </div>
        </div>
        {error ? (
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Alert bsStyle="danger" className="delegate-alert">
                    <b>
                      <span
                        className="icon-warning"
                        style={{ marginRight: 4 }}
                      />
                      {Translation().pages.delegate.error}
                    </b>
                    <p>{error}</p>
                  </Alert>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content">
            {success ? (
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Alert bsStyle="success" className="delegate-alert">
                      <b>
                        <span
                          className="icon-check"
                          style={{ marginRight: 8 }}
                        />
                        {Translation().pages.delegate.accept.success.title}
                      </b>
                      <p>{Translation().pages.delegate.accept.success.text}</p>
                    </Alert>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                {order_loading ? (
                  <div className="loader-wrapper">
                    <div
                      className="loader-api"
                      style={{ display: 'inline-block' }}
                    />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      {user_logged ? (
                        <div>
                          {address ? (
                            <PaymentShortlink
                              onSubmit={this.handleSubmit}
                              loading={loading}
                              order={order}
                            />
                          ) : (
                            <Payment
                              onSubmit={this.handleSubmit}
                              loading={loading}
                              order={order}
                            />
                          )}
                        </div>
                      ) : parseInt(delegate_user_exists, 10) === 1 ? (
                        <div className="delegate-login">
                          <div className="app-login">
                            <div className="login-view">
                              <h2>
                                {
                                  Translation().header.login_dropdown.login
                                    .title
                                }
                              </h2>
                              <LoginView onLogin={this.handleLoginSuccess} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="delegate-login">
                          <div className="app-login">
                            <div className="login-view">
                              <h2>
                                {Translation().pages.delegate.register.title}
                              </h2>
                              <RegisterDelegateUser
                                email={delegate_email}
                                onRegister={this.handleRegisterSuccess}
                                onSubmit={this.handleSubmitRegister}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      {order && <Order order={order} />}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.subscribeUser();
    this.subscribeUser = undefined;
  }
}

export default AcceptDelegate;
