import React, { Component } from 'react';

import LoginView from '../../components/commons/login/LoginView';

import Translation from '../../translation/Translation';

class Login extends Component {

  render() {

    return (
      <div className="app-login">
        <div className="container">
          <div className="row">
            <div className="login-view">
              <h3>{Translation().login.page_title}</h3>
              <LoginView/>
              <a className="forgot-pwd" style={{marginTop: 40, fontSize: 14}} href="/register">{Translation().login.no_account}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
