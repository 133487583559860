import React, { Component } from 'react';

import { objectMapToArray } from '../../../../helpers/HelperFunctions';
import {
  setPlanning,
  apiGetPlanning,
  getServiceSelected,
  getOptionsSelected,
  getProperty,
  setSlotSelected
} from '../../../../helpers/ShopFunctions';
import { getTimezone } from '../../../../helpers/UserFunctions';
import { setCartRequestSlot } from '../../../../helpers/CartFunctions';
import {
  createDate,
  dateInTz,
  getCurrentDate,
  addToDate,
  formatDate,
  toUtcTimestamp
} from '../../../../helpers/DateFunctions';
import Translation from '../../../../translation/Translation';

class LoadMoreTimes extends Component {

  render() {
    const { isPlanningToggled } = this.props;

    return (
      <div className= {"full-planning " + (isPlanningToggled ? "close" : "") }>
        <i onClick={this.props.togglePlanning}>
          <span className="icon-detail" />
          { Translation().shop.services.planning.see_more }
        </i>
      </div>
    );
  }
}

export class Planning extends Component {

  constructor(props) {
    super(props);

    this.state = {
      planning: {},
      nbTimesMax: 0,
      isPlanningToggled: false,
      slotSelected: undefined,
      isRefreshLoading: false,
      startDate: getCurrentDate(null, this.props.timezoneCode),
      minStartDate: getCurrentDate(null, this.props.timezoneCode),
      maxStartDate: addToDate(getCurrentDate(null, this.props.timezoneCode), 3, 'months'),
      nbDays: !isNaN(this.props.nbDays) && parseInt(this.props.nbDays, 10) > 0 ? parseInt(this.props.nbDays, 10) : 7
    }

    this.autoRefreshPlanning = this.autoRefreshPlanning.bind(this);
    this.createPlanning = this.createPlanning.bind(this);
    this.togglePlanning = this.togglePlanning.bind(this);
    this.loadNextPeriod = this.loadNextPeriod.bind(this);
    this.loadPrevPeriod = this.loadPrevPeriod.bind(this);
    this.selectDate = this.selectDate.bind(this);

    this.timeout = undefined;
    this.refreshTimer = 15000;
  }

  componentDidMount() {
    const slots = this.props.object;

    this.createPlanning(slots);
    this.autoRefreshPlanning();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  autoRefreshPlanning() {
    clearTimeout(this.timeout);

    this.timeout = setInterval(() => {

      if (this.state.isRefreshLoading) {
        return;
      }

      this.setState({ isRefreshLoading: true });

      const { startDate } = this.state;

      const endDate = addToDate(startDate, this.state.nbDays, 'days');

      const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
      const endtDateFormated = formatDate(endDate, 'YYYY-MM-DD');
      const serviceSelected = getServiceSelected();
      const property = getProperty();
      const optionsSelected = getOptionsSelected();
      const options = [];
  
      for (let optionSelected in optionsSelected) {
        const option = optionSelected.replace('option-', '');
        options.push(option);
      }
  
      apiGetPlanning(
        startDateFormated,
        endtDateFormated,
        serviceSelected,
        options,
        property,
        (responseData) => {
          if (responseData) {
            setPlanning(responseData);

            this.createPlanning(responseData);
            this.setState( {isRefreshLoading: false });
          }
        },
        () => {
          this.setState({ isRefreshLoading: false });
        }
      );
    }, this.refreshTimer);
  }

  createPlanning(slots) {
    const { timezoneCode } = this.props;

    const planning = {};
    let nbTimesMax = 0;

    /**
     * Create planning days
     */
    for (let i = 0; i < this.state.nbDays; i++) {
      const day = addToDate(this.state.startDate, i, 'days', 'YYYY-MM-DD');
      planning[day] = [];
    }

    /**
     * Create slots time and push them to their right day
     */
    for (let j = 0; j < slots.length; j++) {
      const slotDate = formatDate(dateInTz(slots[j], null, timezoneCode), 'YYYY-MM-DD');
      const slotTime = formatDate(dateInTz(slots[j], null, timezoneCode), 'HH:mm');

      if (planning[slotDate]) {
        planning[slotDate].push(slotTime);
      }
    }

    /**
     * Push new planning to component state
     */
    this.setState({ planning });

    /**
     * Calculate the number max of slots in all days
     */
    for (let slotDate in planning) {
      nbTimesMax = Math.max(5, nbTimesMax, planning[slotDate].length);
      this.setState({ nbTimesMax });
    }
  }

  selectDate(time, date) {
    const { timezoneCode } = this.props;

    if (!time || !date) {
      return;
    }

    if (time !== "Invalid date" || date !== "Invalid date") {
      /**
       * Get the date selected from planning
       */
      const slotSelected = createDate(date + " " + time, timezoneCode);
      const slotSelectedUTC = toUtcTimestamp(slotSelected);

      setSlotSelected(slotSelectedUTC);
      setCartRequestSlot(slotSelectedUTC);
      this.setState({ slotSelected: slotSelectedUTC });
    }
  }

  togglePlanning() {
    this.setState({ isPlanningToggled: !this.state.isPlanningToggled });
  }

  loadNextPeriod() {
    this.autoRefreshPlanning();

    const { minStartDate, maxStartDate} = this.state;

    const startDate = addToDate(this.state.startDate, this.state.nbDays, 'days');
    const endDate = addToDate(startDate, this.state.nbDays, 'days');
    const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
    const endtDateFormated = formatDate(endDate, 'YYYY-MM-DD');
    const minStartDateFormated = formatDate(minStartDate, 'YYYY-MM-DD');
    const maxStartDateFormated = formatDate(maxStartDate, 'YYYY-MM-DD');
    const serviceSelected = getServiceSelected();
    const property = getProperty();
    const optionsSelected = getOptionsSelected();

    if (startDateFormated < minStartDateFormated || startDateFormated > maxStartDateFormated) {
      return;
    }

    const options = [];

    for (let optionSelected in optionsSelected) {
      const option = optionSelected.replace('option-', '');
      options.push(option);
    }

    /**
     * Displaying overlay
     */
    const overlay = document.getElementsByClassName('overlay-planning')[0];
    if (overlay) {
      overlay.style.display = 'block';
    }

    this.setState({ startDate });

    /**
     * Load the next week of planning
     */
    apiGetPlanning(
      startDateFormated,
      endtDateFormated,
      serviceSelected,
      options,
      property,
      (responseData) => {
        if (responseData) {
          /**
           * Store new planning to redux store
           */
          setPlanning(responseData);

          const overlay = document.getElementsByClassName('overlay-planning')[0];
          if (overlay) {
            overlay.style.display = 'none';
          }

          /**
           * Set new planning to component state
           */
          this.createPlanning(responseData);
          this.setState({ isPlanningToggled: false });
        }
      },
      (error) => {
        const overlay = document.getElementsByClassName('overlay-planning')[0];
        if (overlay) {
          overlay.style.display = 'none';
        }
      }
    );
  }

  loadPrevPeriod() {
    this.autoRefreshPlanning();

    const { minStartDate, maxStartDate } = this.state;

    const startDate = addToDate(this.state.startDate, this.state.nbDays * -1, 'days');
    const endDate = addToDate(startDate, this.state.nbDays, 'days');
    const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
    const endtDateFormated = formatDate(endDate, 'YYYY-MM-DD');
    const minStartDateFormated = formatDate(minStartDate, 'YYYY-MM-DD');
    const maxStartDateFormated = formatDate(maxStartDate, 'YYYY-MM-DD');
    const serviceSelected = getServiceSelected();
    const property = getProperty();
    const optionsSelected = getOptionsSelected();

    if (startDateFormated < minStartDateFormated || startDateFormated > maxStartDateFormated) {
      return;
    }

    const options = [];

    for (let optionSelected in optionsSelected) {
        const option = optionSelected.replace('option-', '');
        options.push(option);
    }

    /**
     * Displaying overlay
     */
    const overlay = document.getElementsByClassName('overlay-planning')[0];
    if (overlay) {
      overlay.style.display = 'block';
    }

    this.setState({ startDate });

    /**
     * Load the previous week of planning
     */
    apiGetPlanning(
      startDateFormated,
      endtDateFormated,
      serviceSelected,
      options,
      property,
      (responseData) => {
        if (responseData) {
          /**
           * Store new planning to redux store
           */
          setPlanning(responseData);

          const overlay = document.getElementsByClassName('overlay-planning')[0];
          if (overlay) {
            overlay.style.display = 'none';
          }

          /**
           * Set new planning to component state
           */
          this.createPlanning(responseData);
          this.setState({ isPlanningToggled: false });
        }
      },
      () => {
        const overlay = document.getElementsByClassName('overlay-planning')[0];
        if (overlay) {
          overlay.style.display = 'none';
        }
      }
    );
  }

  render() {

    const {
      planning,
      nbTimesMax,
      startDate,
      minStartDate,
      maxStartDate,
      isPlanningToggled,
      slotSelected,
    } = this.state;

    const { timezoneCode } = this.props;

    const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
    const minStartDateFormated = formatDate(minStartDate, 'YYYY-MM-DD');
    const maxStartDateFormated = formatDate(maxStartDate, 'YYYY-MM-DD');

    return (
      <div className="service-planning">
        <div className="overlay-planning">
          <div className="loader-api" />
        </div>
        { timezoneCode && timezoneCode !== getTimezone() &&
          <div className="service-planning-warning">
            <p><span className="icon-infos" />{Translation().shop.services.planning.timezone_diff} ({timezoneCode})</p>
          </div>
        }
        <div className={"planning-table " + (isPlanningToggled ? "open" : "")}>
          <table>
            <thead>
              <tr className="planning-head">
                <th className="planning-day">
                  <div className={"icon-chevron_gauche nav-planning left " + (startDateFormated <= minStartDateFormated ? "unavailable" : "")} onClick={this.loadPrevPeriod}/>
                </th>
                { Object.keys(planning).map((date, index) => {
                    return (
                      <th className="planning-day" key={index}>
                        <span className="day-alpha">{formatDate(date, "dddd")}</span>
                        <span className="day-numeric">{formatDate(date, 'L')}</span>
                      </th>
                    );
                  })
                }
                <th className="planning-day">
                  <div className={"icon-chevron_droite nav-planning right " + (startDateFormated >= maxStartDateFormated ? "unavailable" : "")} onClick={this.loadNextPeriod}/>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                { objectMapToArray(planning, (date, times, index, object) => {
                    let nbEmptyTimes = Math.max(0, nbTimesMax - times.length);
                    let emptySlots = [];
                    /**
                     * Calculte how many slots are empty
                     */
                    for (let g = 0; g < nbEmptyTimes; g++) {
                      emptySlots.push(g);
                    }
                    return (
                      <td key={index} className="planning-hours">
                        { times.map((time, index) => {
                            return (
                              <div key={index} className={"planning-hour " + (slotSelected === toUtcTimestamp(createDate(date + " " + time, timezoneCode)) ? "time-selected" : "")} onClick={this.selectDate.bind(this, time, date)}>
                                { time }
                              </div>
                            );
                          })
                        }
                        {
                          /**
                           * Display all slots empty
                           */
                          emptySlots.map((elem, index) => {
                            return <div key={index} className="empty-slot"><span /></div>
                          })
                        }
                      </td>
                    );
                  })
                }
                <td/>
              </tr>
            </tbody>
          </table>
        </div>
        { nbTimesMax > 6 && <LoadMoreTimes togglePlanning={this.togglePlanning} isPlanningToggled={isPlanningToggled}/> }
      </div>
    );
  }
}
