import React, { Component } from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import bowser from 'bowser';
import Select from 'react-select';
import RaisedButton from 'material-ui/RaisedButton';

import { copyStringToClipboard, generateHash, objectMapToArray } from '../../../../helpers/HelperFunctions';
import { apiGetMediaTypeThumbnail, apiDownloadOrderProductMedias } from '../../../../helpers/AccountFunctions';
import OrderProductMedia from './OrderProductMedia';
import { getAccessToken, getUser } from '../../../../helpers/UserFunctions';

import Translation from '../../../../translation/Translation';
import Config from '../../../../config/Config';

class OrderProductMediaGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDlSettingsOpen: false,
      isDlDefinitionLoading: false,
      isWatermarkAvailable: undefined,
      selectedDlDefinition: '',
      downloadDefinitionArray: [],
      selectedDlWatermark: '',
      isDownloadInProgress: false,
      isLinkCopied: false,
    }

    this.downloadOrder = this.downloadOrder.bind(this);
    this.toggleDlSettings = this.toggleDlSettings.bind(this);
    this.openDlSettings = this.openDlSettings.bind(this);
    this.closeDlSettings = this.closeDlSettings.bind(this);
    this.handleChangeDlDefinition = this.handleChangeDlDefinition.bind(this);
    this.handleChangeDlWatermark = this.handleChangeDlWatermark.bind(this);
    this.copyLinkToClipboard = this.copyLinkToClipboard.bind(this);
  }

  componentDidMount() {
    /**
     * Get the user and check if he got comapny and if watermark url is set
     */
    const user = getUser();
    this.setState({ isWatermarkAvailable: user && user.company && user.company.watermark_url && this.props.isCompanyOrder ? true : false });
  }

  toggleDlSettings() {
    if (this.state.isDlSettingsOpen) {
      this.closeDlSettings();
    }
    else {
      this.openDlSettings();
    }
  }

  openDlSettings() {
    this.setState({ isDlSettingsOpen: true, isDlDefinitionLoading: true });

    if (this.props.productMedia.has_thumbnails) {
      apiGetMediaTypeThumbnail(
        this.props.productMedia.id,
        (thumbnails) => {
          let downloadDefinitionArray = [{
            value: "",
            label: Translation().account.orders.original
          }];

          objectMapToArray(thumbnails, (index, thumbnail) => {
            let downloadOptions = {};

            downloadOptions['value'] = thumbnail.id;
            downloadOptions['label'] = thumbnail.name + (thumbnail.landscape_width ? ' (' + thumbnail.landscape_width + 'px)' : '');
            downloadDefinitionArray.push(downloadOptions);
          });

          this.setState({
            downloadDefinitionArray,
            isDlDefinitionLoading: false
          });
        },
        (error) => {
          this.setState({ isDlDefinitionLoading: false });
        }
      );
    }
  }

  closeDlSettings() {
    this.setState({ isDlSettingsOpen: false });
  }

  handleChangeDlDefinition(selectedDlDefinition) {
    this.setState({ selectedDlDefinition });
  }

  handleChangeDlWatermark(selectedDlWatermark) {
    this.setState({ selectedDlWatermark });
  }

  downloadOrder() {
    this.setState({ isDownloadInProgress: true });

    const downloadRequest = {
      media_group_id: this.props.productMedia.id,
      media_thumbnail_id: this.state.selectedDlDefinition && this.state.selectedDlDefinition.value ? this.state.selectedDlDefinition.value : (this.state.downloadDefinitionArray && this.state.downloadDefinitionArray[0] && this.state.downloadDefinitionArray[0].value ? this.state.downloadDefinitionArray[0].value : null),
      watermark_position: this.state.selectedDlWatermark && this.state.selectedDlWatermark.value  ? this.state.selectedDlWatermark.value : null
    };

    apiDownloadOrderProductMedias(
      this.props.userType,
      this.props.orderId,
      this.props.product.id,
      downloadRequest,
      (dataUrl) => {
        if (dataUrl) {
          const downloadIframe = document.getElementById('frame-download');
          const iframeContainer =  document.getElementById('frames-container');

          /*
           * If iframe already exists we only change url, if not we create it
           */
          if (downloadIframe) {
            downloadIframe.setAttribute('src', dataUrl);
          }
          else {
            const downloadIframe =  '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +  dataUrl + ' />';
            iframeContainer.innerHTML = downloadIframe;
          }

          this.setState({ isDownloadInProgress: false });
        }
      },
      (error) => {
        this.setState({ isDownloadInProgress: false });
      }
    );
  }

  copyLinkToClipboard(link) {
    copyStringToClipboard(".copy-clipb-" + generateHash(link));

    this.setState({ isLinkCopied: true });

    setTimeout(() => {
      this.setState({ isLinkCopied: false });
    }, 2500)
  }

  render() {

    const {
      userType,
      product,
      productMedia,
      needDownloader
    } = this.props;

    const {
      isDlSettingsOpen,
      selectedDlDefinition,
      downloadDefinitionArray,
      selectedDlWatermark,
      isDlDefinitionLoading,
      isWatermarkAvailable,
      isDownloadInProgress,
      isLinkCopied
    } = this.state;

    const downloadWatermarkArray = [
      { value: '', label: Translation().account.orders.watermark_labels.none },
      { value: 'center-center', label: Translation().account.orders.watermark_labels.center_center },
      { value: 'left-bottom', label: Translation().account.orders.watermark_labels.left_bottom },
      { value: 'right-bottom', label: Translation().account.orders.watermark_labels.right_bottom },
      { value: 'left-top', label: Translation().account.orders.watermark_labels.left_top },
      { value: 'right-top', label: Translation().account.orders.watermark_labels.right_top },
      { value: 'center-bottom', label: Translation().account.orders.watermark_labels.center_bottom },
      { value: 'center-top', label: Translation().account.orders.watermark_labels.center_top },
    ];

    /**
     * Check bowser version for allow or not clopy url clipboard function
     */
    let copyUrlIframeAvailable = true;

    if (bowser) {
      if (bowser.name === "Internet Explorer" && bowser.version < 10) {
        copyUrlIframeAvailable = false;
      }
      else if (bowser.name === "Chrome" && bowser.version < 43) {
        copyUrlIframeAvailable = false;
      }
      else if (bowser.name === "Firefox" && bowser.version < 41) {
        copyUrlIframeAvailable = false;
      }
      else if (bowser.name === "Opera" && bowser.version < 29) {
        copyUrlIframeAvailable = false;
      }
    }
    else {
      copyUrlIframeAvailable = false;
    }

    return (
      <div className="media-group">
        <Col xs={6} md={9} className="col-media-grp">
          <p className="name">{productMedia.name && productMedia.name}</p>
        </Col>
        <Col xs={6} md={3} className="col-media-grp col-btn-dl">
          { needDownloader ?
            <span>
              { productMedia.has_thumbnails
                ? (
                  <span>
                    <div className={"btn-download " + (!isDownloadInProgress && product.downloadable ? "" : "unavailable")}>
                      <div
                        className="dl-action"
                        onClick={!isDownloadInProgress && product.downloadable ? this.downloadOrder : () => { return false; }}>
                        <span className="loader-api" style={isDownloadInProgress ? {display: 'block', left: '44%'} : {}} />
                        <span style={isDownloadInProgress ? {visibility: 'hidden', opacity: 0} : {}} >{Translation().account.orders.download}</span>
                      </div>
                      <div
                        className={"dl-settings " + (isDlSettingsOpen ? "active" : "")}
                        onClick={!isDownloadInProgress && product.downloadable ? this.toggleDlSettings : () => { return false; }}>
                        <span className="icon-parametres" />
                      </div>
                    </div>
                    { isDlSettingsOpen &&
                      <div className="dl-settings-popup">
                        <label>{Translation().account.orders.definition}</label>
                        { isDlDefinitionLoading
                          ? <div className="loader-api" />
                          : (
                            <Select
                              id={"select-dl-definition-" + productMedia.id}
                              name={"select-dl-definition-" + productMedia.id}
                              options={downloadDefinitionArray}
                              placeholder="Séléctionner"
                              noResultsText="Pas de résultat"
                              className="menu-outer-top select-inputs"
                              value={selectedDlDefinition.value ? selectedDlDefinition.value : (downloadDefinitionArray && downloadDefinitionArray.length ? downloadDefinitionArray[0].value : '')}
                              searchable={false}
                              clearable={false}
                              onChange={this.handleChangeDlDefinition}
                            />
                          )
                        }
                        { isWatermarkAvailable &&
                          <div>
                            <label>{Translation().account.orders.watermark}</label>
                            <Select
                              id={"select-dl-watermark-" + productMedia.id}
                              name={"select-dl-watermark-" + productMedia.id}
                              options={downloadWatermarkArray}
                              placeholder="Séléctionner"
                              noResultsText="Pas de résultat"
                              className="menu-outer-top select-inputs"
                              value={selectedDlWatermark.value ? selectedDlWatermark.value : (downloadWatermarkArray && downloadWatermarkArray.length ? downloadWatermarkArray[0].value : '')}
                              searchable={false}
                              clearable={false}
                              onChange={this.handleChangeDlWatermark}
                            />
                          </div>
                        }
                        <RaisedButton
                          label="Ok"
                          fullWidth
                          style={{marginTop: '15px'}}
                          buttonStyle={{zIndex: 0}}
                          labelStyle={{fontWeight: 'bold'}}
                          onClick={this.toggleDlSettings}
                        />
                      </div>
                    }
                  </span>
                )
                : (
                  <div className={"btn-download " + (product.downloadable ? "" : "unavailable")}>
                    <div className="dl-action no-option" onClick={product.downloadable ? this.downloadOrder : () => { return false; }}>{Translation().account.orders.download}</div>
                  </div>
                )
              }

            </span>
          : ''
          }
        </Col>
        <Clearfix/>
        { productMedia.media_types && productMedia.media_types.length
          ? productMedia.media_types.map((mediaType, index) => {
            if (mediaType.type && mediaType.type === "iframe") {
              return (
                <div className="media-input-wrapper" key={index}>
                  { mediaType && mediaType.medias && mediaType.medias.length
                    ? (
                        <div className="row product-medias">
                          { mediaType.medias.map((media, idx) => {
                              return <OrderProductMedia key={idx} media={media} />
                            })
                          }
                        </div>
                      )
                    : ''
                  }
                  <iframe
                    src={mediaType.medias && mediaType.medias.length && mediaType.medias[0].url ? mediaType.medias[0].url : ''}
                    width="100%"
                    height="500"
                    frameBorder="0"
                    allowFullScreen
                    title={"iframe-" + (mediaType.medias && mediaType.medias.length && mediaType.medias[0].url ? mediaType.medias[0].url :'')}
                  />
                  <input
                    type="text"
                    defaultValue={mediaType.medias && mediaType.medias.length && mediaType.medias[0].url ? mediaType.medias[0].url : ''}
                    placeholder={mediaType.name && mediaType.name}
                    name={"input-iframe-" + (mediaType.shortcode ? mediaType.shortcode : "")}
                    readOnly={true}
                    className={"app-basic-input media-input copy-clipb-" + (generateHash(mediaType.medias[0].url))}
                  />
                  { copyUrlIframeAvailable
                    ? (
                        <div
                          onClick={() => this.copyLinkToClipboard(mediaType.medias[0].url)}
                          className={"copy-to-clipboard " + (isLinkCopied && "copied fadeIn")}>
                          { isLinkCopied ? Translation().account.orders.link_copied : Translation().account.orders.copy_link }
                        </div>
                      )
                    : ''
                  }
                </div>
              )
            }
            else if (mediaType.type && mediaType.type === "form") {
              return (
                <div className="media-type-form" key={index}>
                  <a
                    className="app-button-color"
                    target="_blank"
                    href={`${Config.api_url}${userType}/self/orders/${product.order_id}/products/${product.id}/medias/forms/${mediaType.shortcode}/form?access_token=${getAccessToken()}`}
                    rel="noopener noreferrer"
                  >
                    {Translation().account.orders.media_type_form.view}
                  </a>
                </div>
              );
            }
            else {
              return (
                <div className="row product-medias" key={index}>
                  { mediaType && mediaType.medias && mediaType.medias.length
                    ? mediaType.medias.map((media, i) => {
                        return <OrderProductMedia key={i} media={media} />
                      })
                    : (
                        <span>
                          <p className="no-media">{Translation().account.orders.no_media}</p>
                        </span>
                      )
                  }
                </div>
              )
            }
          })
          : ''
        }
        <div className="product-separator">
          <div className="first-sep" />
          <div className="second-sep" />
        </div>
      </div>
    )
  }
}

export default OrderProductMediaGroup;
