import { getApiConfigFromLocation } from '../helpers/ApiFunctions';

class Config {

  constructor() {
    // this.debug = 'PROD';
    this.debug = 'DEV';

    this.current_year = new Date().getFullYear();
    this.app_name = "LightShop";

    this.api_url = getApiConfigFromLocation("api_url");
    this.api_key = getApiConfigFromLocation("api_key");
    this.api_env = getApiConfigFromLocation("name");

    /**
     * App branding
     */
    this.brand_phone_number = "+33 1 42 27 45 53";
    this.brand_address = "22 rue Legendre";
    this.brand_city = "75017 Paris";

    /**
     * USER COOKIES
     */
    this.access_token_cookie_name = "_Secure-access_token";
    this.visitor_id_cookie_name = "_Secure-visitor_id";
    this.lang_cookie_name = "lang";
    this.locale_cookie_name = "locale";
    this.currency_cookie_name = "currency";
    this.timezone_cookie_name = "timezone";
    this.citizen_country_cookie_name = "citizen_country";
    this.residence_country_cookie_name = "residence_country";
    this.unit_system_cookie_name = "unit_system";
    this.referrer_cookie_name = "referrer_code";
    this.invite_cookie_name = "invite_token";
    this.invite_email_cookie_name = "invite_email";
    this.brand_phone_number_cookie_name = "brand_phone_number";
    this.support_email_cookie_name = "support_email";

    /**
     * DEFAULT VALUES
     */
    this.default_locale = "fr";
    this.default_currency = "EUR";
    this.default_currency_symbol = "€";
    this.default_timezone = "Europe/Paris";
    this.default_country = "FR";
    this.default_unit_system = "metric";
    this.support_email = "sales@wall-market.com";

    this.countries_blacklist = ["AE"];

    this.open_locales = ["fr", "en", "es", "it", "de", "no"];
    this.open_countries_lang = [
      { code: "de", name: "Deutsch" },
      { code: "en", name: "English" },
      { code: "es", name: "Español"},
      { code: "fr", name: "Français" },
      { code: "it", name: "Italiano"},
      { code: "no", name: "Norsk"}
    ];
    this.open_countries_currencies = [
      { code: "CAD", name: "Canadian Dollar"},
      { code: "CHF", name: "Franc suisse"},
      { code: "EUR", name: "Euro" },
      { code: "GBP", name: "British Pound" },
      { code: "NOK", name: "Norsk Krone"},
      { code: "SEK", name: "Svensk Krona"},
      { code: "USD", name: "US Dollar" }
    ];
  }
}

export default new Config();
