import * as Cookies from 'js-cookie';
import axios from 'axios';

import { isObjEmpty, stringifyQuery } from './HelperFunctions';
import {
  logoutUser,
  setUser,
  getLocale,
  getCurrency,
  getTimezone,
  getUnitSystem,
  setUserLoading,
} from './UserFunctions';

import Config from '../config/Config';

/**
 * Main function for all api calls
 */
export const CallApi = (
  method,
  route,
  query,
  data,
  headers,
  callBackSuccess,
  callBackFail
) => {
  /**
   * Define default method if no method in params
   */
  if (!method) {
    method = 'get';
  }

  if (['get', 'post', 'put', 'delete', 'options'].indexOf(method) < 0) {
    throw new Error('Wrong method');
  }

  if (!route) {
    throw new Error('Route not found');
  }

  if (!query) {
    query = {};
  }

  if (!data) {
    data = {};
  }

  if (!headers) {
    headers = {};
  }

  const accessToken = Cookies.get(Config.access_token_cookie_name);
  const visitorId = Cookies.get(Config.visitor_id_cookie_name);

  if (accessToken) {
    query.access_token = accessToken;
  } else {
    logoutUser();
    query.client_id = Config.api_key;
  }

  if (visitorId) {
    query.visitor_id = visitorId;
  }

  const locale = getLocale();
  if (locale) {
    query.locale = locale;
  }

  const currency = getCurrency();
  if (currency) {
    query.currency = currency;
  }

  const unitSystem = getUnitSystem();
  if (unitSystem) {
    query.unit_system = unitSystem;
  }

  const timezone = getTimezone();
  if (timezone) {
    query.timezone = timezone;
  }

  const axiosRequest = {};

  if (method) {
    axiosRequest.method = method;
  }
  if (route) {
    axiosRequest.url = Config.api_url + route;
  }
  if (!isObjEmpty(query)) {
    axiosRequest.params = query;
  }
  if (!isObjEmpty(data)) {
    axiosRequest.data = data;
  }
  if (!isObjEmpty(headers)) {
    axiosRequest.headers = headers;
  }

  /**
   * Always pass all parameters. If u don't use all, pass null for all paramas not used.
   */
  axios(axiosRequest)
    .then((response) => {
      const responseData = response.data.data;
      const paging = response.data.paging;

      /**
       * Success function
       */
      if (callBackSuccess && typeof callBackSuccess === 'function') {
        callBackSuccess(responseData, paging);
      }
    })
    .catch((error) => {
      if (callBackFail && typeof callBackFail === 'function') {
        /**
         * Fail function
         */
        callBackFail(error);
      }
      if (error.response) {
        /**
         * Logout if token is expired
         */
        if (error.response.status === 401) {
          logoutUser();
        }
      }
    });
};

export const getApiUrl = (route, query, encodeUrl = true) => {
  if (!route) {
    throw new Error('Route not found');
  }

  if (!query) {
    query = {};
  }

  let url = null;
  let queryString,
    queryJoin = '';
  const accessToken = Cookies.get(Config.access_token_cookie_name);
  const visitorId = Cookies.get(Config.visitor_id_cookie_name);

  if (accessToken) query.access_token = accessToken;

  if (visitorId) query.visitor_id = visitorId;

  const locale = getLocale();
  if (locale) query.locale = locale;

  const currency = getCurrency();
  if (currency) query.currency = currency;

  const timezone = getTimezone();
  if (timezone) query.timezone = timezone;

  if (route) url = Config.api_url + route;

  queryString = stringifyQuery(query, encodeUrl);

  if (queryString) queryJoin = url.indexOf('?') >= 0 ? '&' : '?';

  return url + queryJoin + queryString;
};

/**
 * Get the user's infos by calling the Api
 */
export const apiLoadUser = (callBackFunc = null) => {
  setUserLoading(true);

  const errorOutput = document.getElementsByClassName('error-message')[0];

  CallApi(
    'get',
    'users/self',
    null,
    null,
    null,
    (user) => {
      if (user) {
        setUser(user);

        const loaderApi = document.getElementsByClassName('loader-api')[0];

        if (loaderApi) loaderApi.style.display = 'none';

        if (callBackFunc && typeof callBackFunc === 'function')
          callBackFunc(user);

        setUserLoading(false);
      }
    },
    (error) => {
      let errorMessage = '';

      /**
       * The request was made and the server responded with a status code
       */
      if (error.response) {
        if (error.response.status) {
          if (error.response.status === 403) {
            errorMessage = 'Forbidden access.';
          } else if (error.response.status === 401) {
            errorMessage = 'Unauthorized.';
          } else {
            errorMessage = 'An error occurred, please retry later.';
          }
        }
      } else if (error.request) {
        /**
         * The request was made but no response was received
         */
        errorMessage = 'The server does not respond, please retry later.';
      } else {
        /**
         * Something happened in setting up the request that triggered an Error
         */
        errorMessage = 'An error occurred, please retry later.';
      }

      if (errorOutput) errorOutput.textContent = errorMessage;

      if (callBackFunc && typeof callBackFunc === 'function')
        callBackFunc(null);

      setUserLoading(false);
    }
  );
};

/**
 * Geo coder api
 */
export const apiGeocode = (query = {}, callBackSuccess, CallBackError) => {
  CallApi(
    'get',
    'geocoder/geocode',
    query,
    null,
    null,
    callBackSuccess,
    CallBackError
  );
};
export const apiGeocodeTimezone = (
  query = {},
  callBackSuccess,
  CallBackError
) => {
  CallApi(
    'get',
    'geocoder/timezone',
    query,
    null,
    null,
    callBackSuccess,
    CallBackError
  );
};

/**
 * Post files to clouding
 */
export const apiPostFile = (fileToUpload, callBackSuccess, callBackError) => {
  if (!fileToUpload) return;

  if (fileToUpload) {
    const formData = new FormData();
    formData.append('file', fileToUpload);

    axios
      .post(
        Config.api_url + 'files/temp?client_id=' + Config.api_key,
        formData,
        { headers: { 'X-Requested-With': 'XMLHttpRequest' } }
      )
      .then((response) => {
        callBackSuccess(response.data);
      })
      .catch((error) => {
        callBackError(error);
      });
  }
};

export const getApiConfigFromLocation = (key) => {
  const envs = {
    PROD: {
      api_url: 'https://api.wall-market.com/v1/',
      api_key: 'b6189cec28e235c745a6d892dd13eb29',
      name: 'prod',
    },
    SANDBOX: {
      api_url: 'https://api.sandbox.wall-market.com/v1/',
      api_key: '4f76102f6c019c80dbf58cd161e78136',
      name: 'sandbox',
    },
    DEV: {
      api_url: 'https://api.dev-wall-market.com/v1/',
      api_key: '4f76102f6c019c80dbf58cd161e78136',
      name: 'dev',
    },
    LOCAL: {
      api_url: 'https://api.dev-wall-market.com/v1/',
      api_key: '4f4dfe7dba16f20e4af8a7a53fe7ccc0',
      name: 'local',
    },
  };

  const locations = {
    PROD: 'lightshop.wall-market.com',
    SANDBOX: 'lightshop.sandbox.wall-market.com',
    DEV: 'lightshop.dev-wall-market.com',
    LOCAL: 'localhost',
  };

  if (window && window.location && window.location.hostname) {
    for (let prop in locations) {
      if (window.location.hostname === locations[prop]) {
        return envs[prop][key];
      }
    }
  } else {
    return envs['LOCAL'][key];
  }
};
