import React, { Component } from 'react';

import Config from '../../../../config/Config';

class TermsOfUseEN extends Component {
  render() {
    return (
      <div className="articles">
        <div className="article-content" id="introduction">
          <p>Welcome to our <a href="https://lightshop.wall-market.com" target="_blank" rel="noopener noreferrer">lightshop.wall-market.com</a> website (the "Website").</p>
          <p>These general terms and conditions of sale set out the terms and conditions of purchase applicable for the various services available on the Website.</p>
          <p className="bold">Please read these terms and conditions carefully before placing an order on the Website. By accessing the Website and placing an order, you agree to be unconditionally bound by these general terms and conditions of sale.</p>
          <p>Our Privacy Policy explains how we collect and use personal data in connection with your access to and use of the Website and is available at <a href="https://lightshop.wall-market.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://lightshop.wall-market.com/privacy-policy</a></p>
          <p>Should you have any questions about these terms and conditions, please contact us before placing an order. If you do not wish to accept these terms and conditions in full, you should not place an order on our Website.</p>
        </div>
        <div className="article-content" id="about-us">
          <h2>1. Who are we?</h2>
          <p>The Website is operated by SAS Wall-Market, with a share capital of €19,146, whose registered office is located at 22 Rue Legendre, 75017 Paris, registered in the Paris Trade and Companies Register under unique identification number 532 727 740.</p>
          <p>We may be contacted as follows:</p>
          <ul>
            <li>Address : {Config.brand_address}, {Config.brand_city}</li>
            <li>Tel : +33 (0)1 42 27 45 53</li>
            <li>Email : <a href="mailto:sales@wall-market.com" target="_blank" rel="noopener noreferrer">sales@wall-market.com</a></li>
          </ul>
        </div>
        <div className="article-content" id="services-description">
          <h2>2. Description of the Services</h2>
          <p>Several different services are available on the Website (the « <b>Service(s)</b> »), designed to facilitate the listing or promotion of properties.</p>
          <p>The performance of our Services is split into several stages:</p>
          <p>
            - Registration by the Customer of information relating to the property, in particular its surface area, and the Customer’s contact details; <br/>
            - Performance of the work needed to deliver the completed Service by an independent provider selected by Wall-Market, remotely or at the relevant property. If the work is to be performed at the property, the Customer agrees to ensure that the relevant property is clean and tidy to allow the immediate performance of the work by the provider; and<br/>
            - Delivery of the Service in the form of an electronic file (hereinafter the  « <b>File(s)</b> »), that may be stored and used for the period specified for each Service at the time of the order.
          </p>
          <p>
            The Services may be ordered separately or as part of a Pack, with extra Options available. A detailed description of the Services, the Options available and the period of storage in your personal account is available when the order is placed.
          </p>
          <p>
            <b>NB: not all Services and Options are available in all countries.</b>
          </p>
          <p>Please also note that:</p>
          <p>
            - The « <b>PHOTOSHOOT</b> » or « <b>PHOTOGRAPHY</b> » Service involves the production of HD photos of your property using professional equipment. <br/>
            - The « <b>PLAN</b> » Service produces 2D plans (and also 3D plans if this Option is chosen) of your property. 
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              NB: the 2D and 3D plans, and the measurements on which they are based, have no legal effect and thus are not binding. They are for information and illustrative purposes only. For example, they may not be used to perform building works, determine the square footage or as a substitute for an official plan (for example: cadastral plan, plan appended to the condominium rules). Likewise, if structural information (such as the location of load-bearing walls) is marked, this is for information purposes only: this type of information is legally binding only if provided by certified professionals.
            </b>
          </p>
          <p>
            - The real estate « <b>DIAGNOSTICS</b> » Service delivers one or more diagnostics (« <b>INDIVIDUAL DIAGNOSTICS</b> ») that are mandatory under French law for properties located in France, conducted by a professional certified by a body accredited by the French Accreditation Committee (COFRAC). The mandatory legal DIAGNOSTICS are not the same for all properties and depend on their nature and use.
          </p>
          <p>
            <b>As Wall-Market does not provide consultancy services, you should first determine, based on the objective pursued (for example, sale or rental of your property), which diagnostic(s) is/are required by law. Wall-Market disclaims all liability in that respect.</b>
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              NB: for “INDIVIDUAL DIAGNOSTICS”, you will need to pay: (i) the travelling expenses of the provider, in the form of a fixed fee notified to you before you place your order, <span style={{textDecoration: "underline"}}>and</span> (ii) the cost of each diagnostic selected.
            </b>
          </p>
          <p>
            - <b>The “PICTURES” (e.g., proposed for the “PACK” services) are not “PHOTOGRAPHY” or “PHOTOSHOOT” services, but “SCANS”.</b> SCANS” are not produced using professional photographic equipment but high-definition quality pictures obtained using the SCAN 3D MATTERPORT equipment used for “ <b>VIRTUAL TOURS</b> ».
          </p>
        </div>
        <div className="article-content" id="access-services">
          <h2>3. Access to the Website and its Services </h2>
          <p>
            Access to our Website and its Services is permitted on a temporary basis. We reserve the right to delete or modify the access to our Website or our Services without notice. <br/> <br/>
            We may not be held liable in the event that, for any reason whatsoever, our Website or the services marketed on the Website are not available at any given time or for any given period.
          </p>
        </div>
        <div className="article-content" id="product-delivery">
          <h2>4. Delivery of the Services, period of use and period of storage</h2>
          <p className="bold">4.1 Delivery times</p>
          <p>
            Wall-Market undertakes to deliver the File(s) for the Services ordered within the times indicated for each Service, in the description notified prior to any order.
          </p>
          <p>
            The delivery times indicated are stated in business days.
          </p>
          <p>
            In the event of a delay attributable to Wall-Market, Wall-Market undertakes to perform and deliver the Services within a reasonable period of time.
          </p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            Wall-Market’s potential liability in that respect is limited to the price of the Service(s) of an order.<br/><br/>
            The Customer acknowledges and accepts that no other loss (pecuniary or non-pecuniary) may be caused by a late performance of a Service or its late delivery.<br/><br/>
            If the Customer is of the opinion that such a delay could cause any other type of loss (pecuniary or non-pecuniary) to the Customer, the Customer must not place an order on the Website.
          </p>
          <p className="bold">4.2 Delivery arrangements</p>
          <p>
            The File(s) produced for the Service(s) is/are delivered to the Customer via the Customer’s personal account, created at the time of the first order, within the time specified in Clause 4.1. 
          </p>
          <p>
            It should be noted that Files will be delivered in an intangible, electronic format. No other format will be delivered (for example: printed materials, CD-ROMs or USB flash drives).
          </p>
          <p className="bold">4.3 Period of use and period of storage of the Files in personal accounts</p>
          <p>The length of time that a File will be stored in a Customer’s personal account and the length of time that a File may be used varies depending on the Service ordered.</p>
          <p>The periods of storage and use applicable for a given File are specified in the Service information sheet.</p>
          <p>In general:</p>
          <p>
            - Files are <span className="bold" style={{textDecoration: "underline"}}>stored</span> in the Customer’s personal account for <span className="bold" style={{textDecoration: "underline"}}>6 months</span> after delivery.
          </p>
          <p className="bold">Once a File has been delivered, the Customer should download it to allow the Customer to use and exploit it and save it separately.</p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              At the end of the 6-month period, the Files will cease to be available in the Customer’s personal account. To retain access, the Customer should therefore download them and save them separately.  <br/><br/>
              NO COPY WILL BE PROVIDED IF THE FILE IS LOST.
            </b>
          </p>
          <p>
            - most Files may be <span className="bold" style={{textDecoration: "underline"}}>used</span> for an unlimited period, subject to any legal obligations that may apply, to be identified by the Customer (for example, for mandatory SURVEYS).
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>THE CUSTOMER IS RESPONSIBLE FOR IDENTIFYING THE PERIOD OF USE FOR EACH SERVICE ORDERED.</b><br/><br/>
            For example, <span className="bold">Files recording VIRTUAL TOURS </span> <span className="bold" style={{textDecoration: "underline"}}>may only be used for 6 months</span>. This period may be renewed at an extra cost.
          </p>
        </div>
        <div className="article-content" id="retractation-right">
          <h2>5. Right of withdrawal</h2>
          <p>
            Any Customer acting as a consumer has the right to withdraw from the contract for a Service ordered from us, without any need to give any reason, within fourteen days of the order, except in the cases mentioned below. The date on which the contract is concluded is not included in the above-mentioned period. If that period expires on a Saturday, Sunday or a public holiday or non-working day, it will be extended until the next business day.
          </p>
          <p>
            Pursuant to Article L. 221-28 of the French Consumer Code (code de la consommation), this right of withdrawal does not apply to certain contracts, such as the following: <br/>
            - A service contract that has been fully performed before the end of the withdrawal period if the performance has begun with the consumer’s prior express consent and the consumer expressly waived the right of withdrawal;<br/>
            - A supply of goods made to the consumer’s specifications or clearly personalised;<br/>
            - A supply of digital content which is not supplied on a tangible medium if the performance has begun with the consumer’s prior express consent and the consumer expressly waived the right of withdrawal.<br/>
          </p>
          <p>
            To exercise your right of withdrawal, you just need to cancel your order.
          </p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            Accordingly, Customers treated as “consumers” within the meaning of the French Consumer Code acknowledge that they expressly waive their right of withdrawal for any service to be provided less than 14 calendar days after the confirmation of the order.
          </p>
          <p>
            Consumers may exercise their right of withdrawal by informing Wall-Market of their decision to withdraw from the contract by sending the withdrawal form appended hereto in Appendix I or any other unequivocal statement setting out their decision to withdraw from the contract, before the end of the period provided for above.
          </p>
          <p>
            If the Customer exercises this right of withdrawal, Wall-Market will refund all sums paid without undue delay and not later than fourteen (14) days after the date on which Wall-Market is informed of the Customer’s decision to withdraw. The refund will be made via the payment method used by the Customer for the initial transaction.
          </p>
        </div>
        <div className="article-content" id="client-duty">
          <h2>6. Customer’s obligations</h2>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            The Customer undertakes to provide accurate information <span style={{textDecoration: "underline"}}>at the time of the order:</span> (i) to enable the Customer to be contacted and (ii) relating to the surface area of the property for which the Services are ordered. Information about the surface area is critical in determining the price of the Services and the time required to perform them. If inaccurate information is provided in that respect, the price of the Service(s) will be revised as is hereby accepted by the Customer.<br/><br/>
            The Customer must be present <span style={{textDecoration: "underline"}}>during the performance of a Service</span> to be provided by an independent provider at the property itself.<br/><br/>
            The Customer undertakes to ensure that the relevant property is clean and tidy at the time of the provider’s visit, to allow the service to be provided immediately. In the event that the condition of the property is such that the services cannot be provided when the provider arrives at the property, the service will not be provided and the Customer will not be entitled to a refund.<br/><br/>
            Likewise, the Customer undertakes to store any objects, especially valuables, securely. The Customer will solely bear any damage caused by a failure to comply with this obligation.
          </p>
        </div>
        <div className="article-content" id="property-right">
          <h2>7. Limitations on the right to use the Services</h2>
          <p>The Services ordered remain the property of Wall-Market.</p>
          <p>Accordingly, the Customer may only use the Services to promote the property, for the Customer’s own uses or for the sale or rental of the property. Any other use is prohibited and requires the prior express consent of Wall-Market.</p>
        </div>
        <div className="article-content" id="client-account">
          <h2>8. Operation of Customer Accounts</h2>
          <p className="bold">8.1 Need to create a Customer account to place an order on our Website</p>
          <p>To place an order on our Website, the Customer must register and create a personal account. </p>
          <p>To register an account, the Customer must provide some personal information. That information must be accurate and kept up to date.</p>
          <p className="bold">8.2 Customer’s responsibility for the management of the account</p>
          <p>
          The Customer is responsible for protecting the confidentiality of the login information and for any account activity. If you have any concerns about your login information or if you believe that it may have been compromised, please inform us immediately by emailing <a href="mailto:rgpd@wall-market.com" target="_blank" rel="noopener noreferrer">rgpd@wall-market.com</a>.
          </p>
          <p className="bold">8.3 Account closure and deactivation</p>
          <p>
            We may deactivate your account and your access to the Services at any time, including for the following reasons:<br/>
            - Breach of these general terms and conditions;<br/>
            - Technical or security problems or difficulties;<br/>
            - Account inactive for one (1) year;<br/>
            - Illegal or fraudulent use of the account or the Website.
          </p>
          <p>The account may also be deactivated at the Customer’s request.</p>
          <p>One of the main consequences of the closure of an account is that the Files stored in the account are deleted. </p>
          <p>The Customer acknowledges that Wall-Market may not be held liable to the Customer or to any other person for the closure or deactivation of the account.</p>
        </div>
        <div className="article-content" id="responsibility">
          <h2>9. Liability disclaimer</h2>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            In the event of proven wrongdoing by the Customer, Wall-Market may only be held liable to compensate the pecuniary consequences of direct, foreseeable damage.<br/><br/>
            Accordingly, Wall-Market’s potential liability is limited to the cost of the service ordered. Wall-Market disclaims all liability for any loss of profit, lost earnings or non-pecuniary losses.<br/><br/>
            In any event, Wall-Market may not be held liable for any late performance of a Service, late delivery of a File or the loss of a File.<br/><br/>
            The limitations and exclusions set out in this Clause do not purport to limit the liability or vary the rights afforded to “consumer” Customers. 
          </p>
        </div>
        <div className="article-content" id="personal-data">
          <h2>10. Personal data</h2>
          <p>
            The personal information provided for the processing of the services proposed on the Website is protected using the measures described on the “Legal Notice” page of the Website.<br/><br/>
            For all Service orders placed on the Website, the Customer authorises Wall-Market to transfer the data required for the performance of one or more Services, in particular to the selected provider.
          </p>
        </div>
        <div className="article-content" id="price-refund">
          <h2>11. Prices, refunds and cancellations</h2>
          <p><span className="bold">11.1</span> The Service is provided for the price stated on the Website. </p>
          <p><span className="bold">11.2</span> It should be noted that for “INDIVIDUAL DIAGNOSTICS”, a fixed fee to cover the travelling expenses of the person conducting the diagnostic is invoiced on top of the price of each DIAGNOSTIC selected.</p>
          <p className="bold">11.3 Cancellation by the Customer:</p>
          <p>
            - <b>Services for which an independent provider is not required to attend the property</b> may be cancelled up to one hour after the Service has been ordered. <b>Thereafter, the Services will be invoiced in full;</b> <br/>
            - <b>Services for which an independent provider is required to attend the property</b> may be cancelled 6 hours or more before the appointment arranged with the provider for the provision of the one or more Services. <b>Thereafter, the Services will be invoiced in full.</b>
          </p>
          <p><span className="bold">11.4 Cancellation by Wall-Market:</span> Wall-Market may be forced to cancel a Service and, accordingly, reserves the right to do so. In such a case, the Customer will be informed by telephone or by email and will be asked to choose between the performance of the ordered Service(s) on another date or at another time slot accepted by the Customer OR a refund.</p>
          <p><span className="bold">11.5 Payment terms</span></p>
          <p style={{border: "1px solid", padding: 4}}>
            All payments are made through MangoPay. Accordingly, any Customer placing an order for a service proposed by Wall-Market is deemed to unconditionally accept the general terms and conditions of use of MangoPay, which may be consulted by clicking on the following link <a href="https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf" target="_blank" rel="noopener noreferrer">https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf</a>.
          </p>
          <p>An invoice will be issued and made available in the Customer’s account within the Customer’s personal area.</p>
          <p><span className="bold">11.6 Penalties for non-payment</span></p>
          <p>
            Any non-payment will immediately and automatically result in the following, without any need to give prior formal notice:<br/>
            - the sums owed will become immediately payable in full; <br/>
            - the performance or delivery of the Service(s) in progress will be immediately suspended until the Customer has paid in full the sums owed; <br/>
            - late payment interest will be invoiced at a rate of 10% (ten per cent), calculated on the total amount of the sums owed by the Customer, along with a fixed debt collection fee of one hundred euros (€100).
          </p>
        </div>
        <div className="article-content" id="payment-modality">
          <h2>12. Mediation for consumers</h2>
          <p>
            <span className="bold">Mediation.</span >In case of dispute between the professional and the consumer, they will try to find an amicable solution. If no amicable agreement is reached, pursuant to Article L. 152-1 of the French Consumer Code, you are entitled to use the free AME Conso scheme that we have signed up for, by submitting a request electronically: <a href="https://www.mediationconso-ame.com/demande-de-mediation-ame.html" target="_blank" rel="noopener noreferrer">https://www.mediationconso-ame.com/demande-de-mediation-ame.html</a> or by post: AME Conso – 11 place Dauphine 75001 Paris.
          </p>
          <p>
            Accordingly, in the event that a complaint cannot be resolved through negotiation after giving prior written notice of the complaint to Wall-Market, the Ombudsman may be appointed to handle any unresolved consumer dispute. Click on the following link to find out how to refer a matter to the Ombudsman: <a href="https://www.mediationconso-ame.com/le-processus-de-la-mediation-de-la-consommation.html" target="_blank" rel="noopener noreferrer">https://www.mediationconso-ame.com/le-processus-de-la-mediation-de-la-consommation.html</a>.
          </p>
          <p>
            At European level, the European Commission has set up an online dispute resolution platform, which can be accessed by clicking on the following link: <a href="https://ec.europa.eu/consumers/odr/main/?event=main.trader.register" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/main/?event=main.trader.register</a>.
          </p>
          <p>Wall-Market is not obliged and does not undertake to use an alternative dispute resolution method to resolve all consumer disputes.</p>
          <p>
            <b>Online Dispute Resolution Platform</b>. Pursuant to Article 14 of Regulation (EU) 524/2013, the European Commission has set up an Online Dispute Resolution platform, facilitating the independent out-of-court resolution of disputes online between consumers and traders in the European Union. This platform may be accessed by clicking on the following link: <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage</a>
          </p>
        </div>
        <div className="article-content" id="applicable-law">
          <h2>13. Jurisdiction and governing law</h2>
          <p>These general terms and conditions of sale are governed by French law.</p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            Any dispute will be subject to the exclusive jurisdiction of the French courts in the City of Paris (75), unless provided otherwise under a mandatory provision (for example, action taken by a French consumer). 
          </p>
        </div>
      </div>
    );
  }
}

export default TermsOfUseEN;
