import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ValidatingField from './ValidatingField';

import Translation from '../../../../../translation/Translation';

class ValidatingFieldsForm extends Component {

  render() {

    const {
      fields,
      loading,
      formName,
      handleSubmit
    } = this.props;

    return (
      <div className="validating-fields">
        {
          fields.length > 0 ? fields.map((field, index) => {
            return (
              <ValidatingField
                key={index}
                field={field.field}
                formName={formName}
              />
            )
          })
          : ''
        }
        <i className="required-fields">* {Translation().forms.errors.required}</i>
        <div style={{textAlign: "center", minHeight: "60px"}}>
          <div onClick={loading ? () => { return false } : handleSubmit} className="add-to-cart app-button-color" disabled={loading}>
            <span style={loading ? {display: "none"} : {}}>{Translation().shop.step_buttons.add_to_cart}</span>
            <span style={loading ? {display: "inline-block"} : {display: "none"}} className="loader-api" />
          </div>
        </div>
      </div>
    )
  }
}

ValidatingFieldsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  formName: PropTypes.string.isRequired
}

export default ValidatingFieldsForm;
