import React from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';

import { getResidenceCountry } from '../../../../helpers/UserFunctions';

import GoogleAddressObjectField from '../../../../components/commons/fields/GoogleAddressObjectField';
import NumberField from '../../../../components/commons/fields/NumberField';
import SelectField from '../../../../components/commons/fields/SelectField';
import validate from '../../../../components/shop/shopRouter/validate';

import { googleMapsAddressMapper } from '../../../../helpers/HelperFunctions';
import { setPropertyLocation } from '../../../../helpers/ShopFunctions';

import store from '../../../../redux/store';
import Config from '../../../../config/Config';

class HomeShopPropertyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddressFailed: false,
    };

    this.handleAddressValue = this.handleAddressValue.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
  }

  handleAddressValue(addressObject) {
    if (!addressObject) return;

    if (addressObject.geometry && addressObject.geometry.location) {
      const location = {
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      };

      setPropertyLocation(location);
    }

    /**
     * Check if address property have the required elements for API
     */
    const addressMapped = googleMapsAddressMapper(addressObject);

    if (addressMapped) {
      if (
        !addressMapped.locality ||
        (!addressMapped.route &&
          getResidenceCountry().indexOf(Config.countries_blacklist) === -1)
      ) {
        this.setState({ isAddressFailed: true });
        store.dispatch(change('homeShopPropertyForm', 'address', ''));
        store.dispatch(
          change('homeShopPropertyForm', 'addressAutocomplete', '')
        );
      } else {
        this.setState({ isAddressFailed: false });
        store.dispatch(
          change(
            'homeShopPropertyForm',
            'address',
            addressObject.formatted_address
          )
        );
        store.dispatch(
          change(
            'homeShopPropertyForm',
            'addressAutocomplete',
            addressObject.formatted_address
          )
        );
      }
    } else {
      this.setState({ isAddressFailed: true });
      store.dispatch(change('homeShopPropertyForm', 'address', ''));
      store.dispatch(change('homeShopPropertyForm', 'addressAutocomplete', ''));
    }
  }

  handleChangeAddress() {
    store.dispatch(change('homeShopPropertyForm', 'address', ''));
    setPropertyLocation(null);
  }

  render() {
    /**
     * Get the redux state and get the input values in state for allow or not the next step
     */
    const state = store.getState();
    const selector = formValueSelector('homeShopPropertyForm');
    const isAddressDirty = selector(state, 'address');
    const isSizeDirty = selector(state, 'size');

    const { isAddressFailed } = this.state;
    const { handleSubmit, Translation, labels, unitSystem } = this.props;

    const typeOptions = [
      { label: labels.house, value: labels.house },
      { label: labels.apartment, value: labels.apartment },
      { label: labels.office, value: labels.office },
      { label: labels.other, value: 'other' },
    ];

    return (
      <form onSubmit={handleSubmit}>
        <Col xs={12} sm={4} md={6} lg={6} className="col-input-wpr">
          <label htmlFor="homepptAddress">{labels.address}</label>
          <div className="input-wpr">
            <span className="icon-comment01 field-icon" />
            <Field
              name="addressAutocomplete"
              id="homepptAddress"
              type="text"
              label=""
              className={'home-ppty-input bordered ppty-input'}
              onSelect={(address) => {
                this.handleAddressValue(address);
              }}
              onBlur={() => this.setState({ isAddressFailed: false })}
              onFocus={() => this.setState({ isAddressFailed: false })}
              onChange={this.handleChangeAddress}
              component={GoogleAddressObjectField}
              isFailed={isAddressFailed}
              required={true}
              autoFocus={true}
            />
            <Field
              name="address"
              type="hidden"
              id="address-input-hidden"
              className="app-hidden-input"
              component="input"
            />
          </div>
        </Col>
        <div className="clearfix-mobile" />
        <Col xs={6} sm={3} md={2} lg={2} className="col-input-wpr">
          <label htmlFor="homepptSize">{labels.size}</label>
          <div className="home-size-input input-wpr">
            <span className="icon-surface field-icon" />
            <Field
              name="size"
              type="number"
              id="homepptSize"
              integer={25}
              fraction={2}
              required={true}
              positive={true}
              negative={false}
              separator={'.'}
              isUnitSystem={true}
              unitSystem={unitSystem}
              component={NumberField}
            />
          </div>
        </Col>
        <Col xs={6} sm={3} md={2} lg={2} className="col-input-wpr">
          <label htmlFor="homepptType">{labels.type}</label>
          <div className="input-wpr">
            <span className="icon-type2 field-icon" />
            <Field
              id="homepptType"
              placeHolder=""
              name="propertyType"
              options={typeOptions}
              component={SelectField}
              className="select-inputs home-ppty-input type-input"
            />
          </div>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} className="col-input-wpr">
          <label style={{ opacity: 0, visibilty: 'hidden' }}>Commander</label>
          <div className="input-wpr">
            <button
              type="submit"
              className={
                'app-button-color ' +
                (isAddressDirty && isSizeDirty ? 'active' : '')
              }
            >
              <span className="btn-txt">
                {Translation().pages.home.home_shop_form.submit}
              </span>
              <span className="btn-icon icon-chevron_droite" />
            </button>
          </div>
        </Col>
        <Clearfix />
        {isAddressFailed && (
          <p className="no-googleplace-result output">
            {Translation().shop.informations.property_not_found.incomplete}
          </p>
        )}
      </form>
    );
  }
}

export default reduxForm({
  form: 'homeShopPropertyForm',
  validate,
})(HomeShopPropertyForm);
