import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';

import { dateInTz } from '../../../helpers/DateFunctions';

import Translation from '../../../translation/Translation';

const RateCheckbox = props => {

  const {
    id,
    onClick,
    onUnselect,
    fontIcon,
    text,
    checked
  } = props;

  function handleClick() {

    if (checked) {
      if (id && onUnselect && typeof onUnselect === 'function')
        onUnselect(id);
    }
    else if (id && onClick && typeof onClick === 'function')
      onClick(id);
  }

  return (
    <div className={"product-rate-radio" + (checked ? " selected" : "")} onClick={handleClick}>
      <div className={fontIcon + " option-rate-icon"} />
      {text && <span>{text}</span>}
    </div>
  )
};

RateCheckbox.propTypes = {
  id: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  fontIcon: PropTypes.string.isRequired,
  text: PropTypes.string,
  checked: PropTypes.bool,
};

class ProductRating extends Component {

  constructor(props) {
    super(props);

    this.state = {
      minRating: 3,
      ratingValue: 0,
      ratingTextareaValue: '',
      ratingCheckboxValues: []
    };

    this.handleChangeRateComments = this.handleChangeRateComments.bind(this);
    this.handleSelectRateCheckbox = this.handleSelectRateCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRate = this.handleRate.bind(this);
    this.handleUnselectRateCheckbox = this.handleUnselectRateCheckbox.bind(this);
  }

  handleRate(rate) {

    const { minRating } = this.state;

    if (rate >= minRating) {
      this.setState({
        ratingValue: rate,
        ratingCheckboxValues: [],
        ratingTextareaValue: ''
      });
    }
    else {
      this.setState({ ratingValue: rate });
    }
  };

  handleSubmit() {

    const {
      ratingValue,
      ratingTextareaValue,
      ratingCheckboxValues
    } = this.state;

    const {
      onSubmit
    } = this.props;

    if (!ratingValue)
      return;

    if (onSubmit && typeof onSubmit === "function") {
      onSubmit({
        rating: ratingValue,
        issues: ratingCheckboxValues,
        comment: ratingTextareaValue
      });
    }
  }

  handleChangeRateComments(event) {
    this.setState({ ratingTextareaValue: event.target.value });
  };

  handleSelectRateCheckbox(id) {
    this.setState({ ratingCheckboxValues: [...this.state.ratingCheckboxValues, id] });
  };

  handleUnselectRateCheckbox(id) {

    const newArray = this.state.ratingCheckboxValues.filter((el) => {
      return (el !== id)
    });

    this.setState({ ratingCheckboxValues: newArray });
  };

  render() {

    const {
      ratingValue,
      ratingTextareaValue,
      ratingCheckboxValues,
      minRating
    } = this.state;

    const {
      timezoneCode,
      isLoading,
      product,
      propertyAddress,
      onClose
    } = this.props;

    return (
      <div className="app-overlay product-rate-overlay">
        <div className="container">
          <div className="row">
            <div className="app-modal">
              <div className="icon-fermer close-modal" onClick={onClose} />
              <img src={product.icon_url_dark} alt={product.name} className="product-rate-img"/>
              <h4 className="app-modal-title">{product.name}</h4>
              { propertyAddress &&
                <p className="app-modal-desc">{propertyAddress}</p>
              }
              { product.start
                ? (
                  <div className="product-rate-details">
                    <span className="detail">
                      <span className="detail-icon icon-calendrier" />
                      { dateInTz(product.start, 'localized-date', timezoneCode) }
                    </span>
                    <span className="detail">
                      <span className="detail-icon icon-horaire" />
                      { dateInTz(product.start, 'HH:mm', timezoneCode) }
                    </span>
                  </div>
                )
                : ''
              }
              <div className="app-modal-content">
                <div className="product-rating">
                  <Rating
                    initialRating={ratingValue}
                    onClick={this.handleRate}
                    emptySymbol={<span className="icon-etoilepleine rate-icon" />}
                    fullSymbol={<span className="icon-etoilepleine rate-icon filled"/>}
                  />
                </div>
                {
                  ratingValue && ratingValue <= minRating
                    ? (
                      <div className="product-rate-more">
                        <p style={{fontFamily: "Montserrat-medium"}}>{Translation().account.orders.rating.title}</p>
                        <RateCheckbox
                          id={"provider_punctuality"}
                          checked={ratingCheckboxValues.indexOf("provider_punctuality") >= 0}
                          fontIcon="icon-comment03"
                          text={Translation().account.orders.rating.rating_radios.appointment}
                          onClick={this.handleSelectRateCheckbox}
                          onUnselect={this.handleUnselectRateCheckbox}
                        />
                        <RateCheckbox
                          id={"provider_behaviour"}
                          checked={ratingCheckboxValues.indexOf("provider_behaviour") >= 0}
                          fontIcon="icon-connecte"
                          text={Translation().account.orders.rating.rating_radios.attitude}
                          onClick={this.handleSelectRateCheckbox}
                          onUnselect={this.handleUnselectRateCheckbox}
                        />
                        <RateCheckbox
                          id={"delivery_time"}
                          checked={ratingCheckboxValues.indexOf("delivery_time") >= 0}
                          fontIcon="icon-rea01"
                          text={Translation().account.orders.rating.rating_radios.delivery_time}
                          onClick={this.handleSelectRateCheckbox}
                          onUnselect={this.handleUnselectRateCheckbox}
                        />
                        <RateCheckbox
                          id={"medias_quality"}
                          checked={ratingCheckboxValues.indexOf("medias_quality") >= 0}
                          fontIcon="icon-detail"
                          text={Translation().account.orders.rating.rating_radios.quality}
                          onClick={this.handleSelectRateCheckbox}
                          onUnselect={this.handleUnselectRateCheckbox}
                        />
                        <textarea
                          rows={5}
                          className="product-rate-textarea"
                          placeholder={Translation().account.orders.rating.form.labels.commentary}
                          onChange={this.handleChangeRateComments}
                          checked={ratingTextareaValue}
                        />
                      </div>
                    )
                    : ''
                }
                <div className="btn-wrapper">
                  <button
                    className="app-button-color"
                    disabled={isLoading}
                    onClick={this.handleSubmit}
                  >
                    <span className="loader-api" style={isLoading ? {display: 'block'} : {}} />
                    <span style={isLoading ? {opacity: '0',visbility: 'hidden'} : {}}>
                      <span>{Translation().account.orders.rating.form.button.submit}</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

ProductRating.propTypes = {
  isLoading: PropTypes.bool,
  product: PropTypes.object.isRequired,
  propertyAddress: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProductRating;
