import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { change } from 'redux-form';
import { Checkbox, Toggle } from 'redux-form-material-ui';
import { Table } from 'react-bootstrap';
import $ from 'jquery';

import Snackbar from '../../commons/snackbar/Snackbar';
import store from '../../../redux/store';
import {
  getUser,
  getUserPaymentMethod,
  deleteUserPaymentMethod,
  loadPaymentMethodIframe,
  apiGetUsersCompany,
  authorizeCompanyPaymentMethod,
  unauthorizeCompanyPaymentMethod,
  getUserAuthorizationStatusPaymentMethod,
} from '../../../helpers/UserFunctions';
import { autoScrollTop } from '../../../helpers/JqueryFunctions';

import Translation from '../../../translation/Translation';

class UsersPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAllowRequestInProgress: false,
    }

    this.dispatchAllowPayment = this.dispatchAllowPayment.bind(this);
    this.allowPaymentMethod = this.allowPaymentMethod.bind(this);
    this.unallowPaymentMethod = this.unallowPaymentMethod.bind(this);
  }

  componentDidMount() {

    const user = getUser();

    if (this.props.dataUser.id && user.id !== this.props.dataUser.id) {
      this.setState({ isAllowRequestInProgress: true });

      getUserAuthorizationStatusPaymentMethod(
        this.props.dataUser.id,
        this.props.paymentMethodId,
        (successData) => {
          this.setState({ isAllowRequestInProgress: false });
          store.dispatch(change('editPaymentAccount', "allowUserPayment-" + this.props.dataUser.id, true));
        },
        (error) => {
          this.setState({ isAllowRequestInProgress: false });
          store.dispatch(change('editPaymentAccount', "allowUserPayment-" + this.props.dataUser.id, ''));
        }
      );
    }
  }

  dispatchAllowPayment(value) {
    if (value)
      this.allowPaymentMethod();
    else
      this.unallowPaymentMethod();
  }

  allowPaymentMethod() {

    if (this.props.dataUser.id) {
      this.setState({ isAllowRequestInProgress: true });

      authorizeCompanyPaymentMethod(
        this.props.dataUser.id,
        this.props.paymentMethodId,
        (successData) => {
          this.setState({ isAllowRequestInProgress: false });
          store.dispatch(change('editPaymentAccount', "allowUserPayment-" + this.props.dataUser.id, true));
        },
        (error) => {
          this.setState({ isAllowRequestInProgress: false });
          store.dispatch(change('editPaymentAccount', "allowUserPayment-" + this.props.dataUser.id, ''));
        }
      );
    }
  }

  unallowPaymentMethod() {

    if (this.props.dataUser.id) {
      this.setState({ isAllowRequestInProgress: true });

      unauthorizeCompanyPaymentMethod(
        this.props.dataUser.id,
        this.props.paymentMethodId,
        (successData) => {
          this.setState({ isAllowRequestInProgress: false });
          store.dispatch(change('editPaymentAccount', "allowUserPayment-" + this.props.dataUser.id, ''));
        },
        (error) => {
          this.setState({ isAllowRequestInProgress: false });
          store.dispatch(change('editPaymentAccount', "allowUserPayment-" + this.props.dataUser.id, true));
        }
      );
    }
  }

  render() {

    const { dataUser } = this.props;
    const { isAllowRequestInProgress } = this.state;

    /**
     * Checkbox style
     */
    const checkboxStyle = {
      width: '30px',
      display: 'inline-block',
      float: 'right'
    };

    const user = getUser();

    return (
      <tr width="100%">
        <td width="40%">{dataUser.last_name && dataUser.last_name}</td>
        <td width="50%">{dataUser.first_name && dataUser.first_name}</td>
        <td width="10%">
          { isAllowRequestInProgress
            ? <span className="loader-api" />
            : (
                <span>
                  { user.id !== dataUser.id
                    ? (
                        <Field
                          name={"allowUserPayment-" + dataUser.id}
                          component={Checkbox}
                          onChange={(event, value) => this.dispatchAllowPayment(value)}
                          style={checkboxStyle}
                        />
                      )
                    : ''
                  }
                </span>
              )
          }
        </td>
      </tr>
    );
  }
}

class PaymentMethod extends Component {

  render() {

    const {
      isUserCompanyAdmin,
      dataPayment,
      openRemovePaymentMethodModal,
      openAllowPaymentMethodModal,
      isCompanyToggled,
    } = this.props;

    return (
      <tr className={dataPayment.blocked ? "payment-blocked" : ""}>
        <td>{dataPayment.type ? dataPayment.type : ''}</td>
        <td>{dataPayment.currency_isocode ? dataPayment.currency_isocode : ''}</td>
        <td width="48%">
          { dataPayment.blocked &&
            <span className="icon-warning alias-blocked" />
          }
          {dataPayment.name ? dataPayment.name : ''}
        </td>
        <td>{dataPayment.owner_name ? dataPayment.owner_name : ''}</td>
        <td className="td-icons">
          { isCompanyToggled && isUserCompanyAdmin &&
            <span className="crud-method icon-connexion" onClick={openAllowPaymentMethodModal.bind(this, dataPayment.id)}/>
          }
          <span className="delete-method icon-delete" onClick={openRemovePaymentMethodModal.bind(this, dataPayment.id)}/>
        </td>
      </tr>
    );
  }
}

class InvoicesPayment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      paymentMethods: null,
      pagingPayMethod: null,
      pagingUsersCompany: null,
      scrollBufferPayMethod: 1,
      scrollBufferUsersCompany: 1,
      isAddPaymentModalOpen: false,
      isRemovePaymentMethodOpen: false,
      isAllowPaymentMethodOpen: false,
      autoHideDuration: 4000,
      isSnackbarOpen: undefined,
      snackbarMessage: '',
      snackbarStatus: '',
      paymentMethodId: null,
      usersCompany: null,
      isPaymentMethodLoading: false,
      isUsersCompanyLoading: false,
      isCompanyToggled: this.props.isUserCompanyAdmin
    }

    this.openSnackbar = this.openSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.loadPaymentMethods = this.loadPaymentMethods.bind(this);
    this.removePaymentMethod = this.removePaymentMethod.bind(this);
    this.openAddPaymentModal = this.openAddPaymentModal.bind(this);
    this.closeAddPaymentModal = this.closeAddPaymentModal.bind(this);
    this.openRemovePaymentMethodModal = this.openRemovePaymentMethodModal.bind(this);
    this.closeRemovePaymentMethodModal = this.closeRemovePaymentMethodModal.bind(this);
    this.openAllowPaymentMethodModal = this.openAllowPaymentMethodModal.bind(this);
    this.closeAllowPaymentMethodModal = this.closeAllowPaymentMethodModal.bind(this);
    this.loadUsersCompany = this.loadUsersCompany.bind(this);
    this.handleCompanyToggle = this.handleCompanyToggle.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    /**
     * Get the current payment method(s)
     */
    this.loadPaymentMethods(this.props.isUserCompanyAdmin, null);

    const handleScrollListener = this.handleScroll;
    window.addEventListener('scroll', handleScrollListener);
  }

  handleCompanyToggle(isCompanyToggled) {

    if (isCompanyToggled === this.state.isCompanyToggled)
      return;

    store.dispatch(change('editPaymentAccount', 'companyToggle', isCompanyToggled ? true : ''));

    this.setState({
      isCompanyToggled: isCompanyToggled ? true : false,
      paymentMethods: null,
      pagingPayMethod: null,
      scrollBufferPayMethod: 1
    });

    this.loadPaymentMethods(isCompanyToggled ? true : false, null);
  }

  handleScroll() {

    const {
      scrollBufferPayMethod,
      scrollBufferUsersCompany,
      isAllowPaymentMethodOpen,
      pagingPayMethod,
      isCompanyToggled,
      pagingUsersCompany,
    } = this.state;

    if (scrollBufferPayMethod === 1) {
      if (($(window).scrollTop() + $(window).height()) + ($('.app-footer').height()) > $(document).height()) {
        if (pagingPayMethod && pagingPayMethod.next) {
          this.loadPaymentMethods(isCompanyToggled, pagingPayMethod);
          this.setState({ scrollBufferPayMethod: 2 });
        }
      }
    }

    if (isAllowPaymentMethodOpen && scrollBufferUsersCompany === 1) {
      if (($(window).scrollTop() + $(window).height()) + ($('.app-footer').height()) > $(document).height()) {
        if (pagingUsersCompany && pagingUsersCompany.next) {
          this.loadUsersCompany(pagingUsersCompany);
          this.setState({ scrollBufferUsersCompany: 2 });
        }
      }
    }
  }

  loadUsersCompany(pagingUsersCompany) {

    this.setState({ isUsersCompanyLoading: true });

    apiGetUsersCompany(
      pagingUsersCompany && pagingUsersCompany.next ? pagingUsersCompany.next : null,
      (successData, pagingUsersCompany) => {
        const usersCompany = this.state.usersCompany ? this.state.usersCompany.concat(successData) : successData;

        this.setState({
          usersCompany,
          pagingUsersCompany,
          scrollBufferUsersCompany: 1,
          isUsersCompanyLoading: false
        });
      },
      (error) => {
        this.setState({
          usersCompany: this.state.usersCompany ? this.state.usersCompany : null,
          scrollBufferUsersCompany: 1,
          isUsersCompanyLoading: false
        });
      }
    );
  }

  loadPaymentMethods(companyToggleValue, pagingPayMethod) {

    this.setState({ isPaymentMethodLoading: true });

    getUserPaymentMethod(
      null,
      pagingPayMethod && pagingPayMethod.next ? pagingPayMethod.next : null,
      companyToggleValue,
      {},
      (successData, pagingPayMethod) => {
        const paymentMethods = this.state.paymentMethods ? this.state.paymentMethods.concat(successData) : successData;

        this.setState({
          paymentMethods,
          pagingPayMethod,
          scrollBufferPayMethod: 1,
          isPaymentMethodLoading: false
        });
      },
      (error) => {
        this.setState({
          paymentMethods: this.state.paymentMethods ? this.state.paymentMethods : null,
          isPaymentMethodLoading: false,
          scrollBufferPayMethod: 1
        });
      }
    );
  }

  openSnackbar() {
    this.setState({ isSnackbarOpen: true });
  };

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  };

  openAddPaymentModal() {
    autoScrollTop();

    const paymentMethodModal = document.getElementsByClassName('overlay-payment-method')[0];

    if (paymentMethodModal) { paymentMethodModal.style.display = 'block'; }

    loadPaymentMethodIframe(
      this.state.isCompanyToggled,
      (success) => {
        getUserPaymentMethod(
          null,
          null,
          this.state.isCompanyToggled,
          {},
          (paymentMethods) => {
            this.setState({ snackbarMessage: Translation().account.invoices.modals.payment_methods.create.callbacks.success, snackbarStatus: "success"});
            this.openSnackbar();
            if (paymentMethods && paymentMethods.length > 0) {
              this.setState({ paymentMethods });
            }
            else {
              this.setState({ paymentMethods: null });
            }
            /**
             * Close the modal
             */
            this.closeAddPaymentModal();
          },
          (error) => {
            this.setState({ snackbarMessage: Translation().account.invoices.modals.payment_methods.create.callbacks.error, snackbarStatus: "error"});
            this.openSnackbar();
            this.closeAddPaymentModal();
          }
        );
      }
    );
  }

  closeAddPaymentModal() {
    const paymentMethodModal = document.getElementsByClassName('overlay-payment-method')[0];

    if (paymentMethodModal) {
      paymentMethodModal.style.display = 'none';
    }
  }

  openRemovePaymentMethodModal(paymentMethodId) {
    /**
     *  Set the payment method id to component state
     */
    this.setState({
      isRemovePaymentMethodOpen: true,
      paymentMethodId: paymentMethodId
    });
  }

  closeRemovePaymentMethodModal() {
    this.setState({ isRemovePaymentMethodOpen: false });
  }

  openAllowPaymentMethodModal(paymentMethodId) {
    /**
     * Get the users of the company then store to component state
     */
    this.loadUsersCompany(null);

    /**
     * Set the payment method id to component state
     */
    this.setState({
      isAllowPaymentMethodOpen: true,
      paymentMethodId: paymentMethodId
    });
  }

  closeAllowPaymentMethodModal() {
    this.setState({ isAllowPaymentMethodOpen: false, usersCompany: null });
  }

  removePaymentMethod() {
    const removePaymentMethodLoader = document.getElementById('confirm-rpm-loader');
    const removePaymentMethodText = document.getElementById('confirm-rpm-txt');

    if (removePaymentMethodLoader && removePaymentMethodText) {
      removePaymentMethodText.style.visibility = "hidden";
      removePaymentMethodLoader.style.display = "block";
    }

    /**
     * Get the payment method id from component state
     */
    if (this.state.paymentMethodId) {
      deleteUserPaymentMethod(
        this.state.isCompanyToggled,
        this.state.paymentMethodId,
        (successData) => {
          if (removePaymentMethodLoader && removePaymentMethodText) {
            removePaymentMethodText.style.visibility = "visible";
            removePaymentMethodLoader.style.display = "none";
          }
          this.setState({
            paymentMethods: null,
            snackbarMessage: Translation().account.invoices.modals.payment_methods.delete.form.callbacks.success,
            snackbarStatus: "success"
          });
          this.openSnackbar();
          this.loadPaymentMethods(this.state.isCompanyToggled, null);
          this.closeRemovePaymentMethodModal();
        },
        (error) => {
          const errorMessage = error && error.response && error.response.data && error.response.data.detail ? error.response.data.detail : Translation().account.invoices.modals.payment_methods.delete.form.callbacks.error;

          if (removePaymentMethodLoader && removePaymentMethodText) {
            removePaymentMethodText.style.visibility = "visible";
            removePaymentMethodLoader.style.display = "none";
          }

          this.setState({ snackbarMessage: errorMessage, snackbarStatus: "error"});
          this.openSnackbar();
          this.closeRemovePaymentMethodModal();
        }
      )
    }
  }

  render() {

    const {
      paymentMethods,
      paymentMethodId,
      isRemovePaymentMethodOpen,
      isAllowPaymentMethodOpen,
      isPaymentMethodLoading,
      isUsersCompanyLoading,
      usersCompany,
      isCompanyToggled
    } = this.state;

    const {
      isUserCompanyAdmin
    } = this.props;

    /**
     * Toggle switch style
     */
    const toggleStyle = {
      backgroundColor: isCompanyToggled ? "#1a2123" : "#FFD700",
      zIndex: 1
    };

    return (
      <div className="payment-list">
        <div className="payment-header">
          <div className={"add-payment " + (isCompanyToggled ? "company" : "")} onClick={this.openAddPaymentModal}>
            <span className="add-icon icon-ajout_carte"/>
          </div>
        </div>
        <div className="app-overlay overlay-payment-method">
          <div className="container">
            <div className="row">
              <div className="app-modal">
                <div className="icon-fermer close-modal" onClick={this.closeAddPaymentModal} />
                <h4 className="app-modal-title">{Translation().account.invoices.modals.payment_methods.create.title}</h4>
                <p className="app-modal-desc">{Translation().account.invoices.modals.payment_methods.create.description}</p>
                <p className="bold">{ isCompanyToggled ? Translation().account.invoices.modals.payment_methods.create.pro_method : Translation().account.invoices.modals.payment_methods.create.personal_method } </p>
                <div className="app-modal-content">
                  <form>
                    <div className="payment-container">
                      <div id="frame-payment-loader" className="loader-api" />
                      <div id="payment-frame-container" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        { isRemovePaymentMethodOpen &&
          <div className="app-overlay remove-payment-modal">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.closeRemovePaymentMethodModal} />
                  <h4 className="app-modal-title">{Translation().account.invoices.modals.payment_methods.delete.title},</h4>
                  <p className="app-modal-desc">{Translation().account.invoices.modals.payment_methods.delete.description}</p>
                  <div className="app-modal-content">
                    <div className="btn-wrapper">
                      <div className="app-button-color confirm" onClick={this.closeRemovePaymentMethodModal}>
                        <span className="confirm-icon icon-fermer left" /> {Translation().account.invoices.modals.payment_methods.delete.form.buttons.cancel}
                      </div>
                      <div className="app-button-color confirm" onClick={this.removePaymentMethod}>
                        <span id="confirm-rpm-loader" className="loader-api" />
                        <span id="confirm-rpm-txt">
                          <span>{Translation().account.invoices.modals.payment_methods.delete.form.buttons.submit}</span><span className="confirm-icon icon-check right" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { isAllowPaymentMethodOpen &&
          <div className="app-overlay allow-payment-modal">
            <div className="container">
              <div className="row">
                <div className="app-modal">
                  <div className="icon-fermer close-modal" onClick={this.closeAllowPaymentMethodModal} />
                  <h4 className="app-modal-title">{Translation().account.invoices.modals.payment_methods.manage.title}</h4>
                  <p className="app-modal-desc">{Translation().account.invoices.modals.payment_methods.manage.description}</p>
                  <div className="app-modal-content">
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th width="48%">{Translation().account.invoices.modals.payment_methods.manage.table.last_name}</th>
                          <th>{Translation().account.invoices.modals.payment_methods.manage.table.first_name}</th>
                          <th className="td-checkboxes">
                            <span>{Translation().account.invoices.modals.payment_methods.manage.table.allowed}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        { usersCompany && usersCompany.length > 0 && usersCompany.map((user, index) => {
                            return (
                              <UsersPayment
                                key={user.id}
                                paymentMethodId={paymentMethodId}
                                dataUser={user}
                              />
                            );
                          })
                        }
                      </tbody>
                    </Table>
                    { isUsersCompanyLoading &&
                      <div className="overlay-usersc">
                        <div className="loader-api" />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { isUserCompanyAdmin &&
          <div className="select-user-type list">
            <span
              className={"text-ut " + (isCompanyToggled ? "" : "active")}
              disabled={isPaymentMethodLoading}
              onClick={() => {
                if (!isPaymentMethodLoading) {
                  this.handleCompanyToggle(false);
                }
                else { return false; }
              }}>
                {Translation().account.invoices.company_toggle.personal}
            </span>
            <Field
              type="checkbox"
              component={Toggle}
              className="toggle-ut"
              thumbStyle={toggleStyle}
              thumbSwitchedStyle={toggleStyle}
              name="companyToggle"
              disabled={isPaymentMethodLoading}
              onChange={(event, value) => {
                this.handleCompanyToggle(value);
              }}
            />
            <span
              className={"text-ut right " + (isCompanyToggled ? "active" : "")}
              disabled={isPaymentMethodLoading}
              onClick={() => {
                if (!isPaymentMethodLoading) {
                  this.handleCompanyToggle(true);
                }
                else { return false; }
              }}>
                {Translation().account.invoices.company_toggle.pro}
            </span>
          </div>
        }
        <div id="payment-methods-wrapper">
          { paymentMethods && paymentMethods.length > 0
            ? (
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>{Translation().account.invoices.tables.payment_methods.type}</th>
                      <th>{Translation().account.invoices.tables.payment_methods.currency_isocode}</th>
                      <th width="48%">{Translation().account.invoices.tables.payment_methods.alias}</th>
                      <th>{Translation().account.invoices.tables.payment_methods.owner}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { paymentMethods && paymentMethods.length > 0 && paymentMethods.map((paymentMethod, index) => {
                        return (
                          <PaymentMethod
                            key={index}
                            dataPayment={paymentMethod}
                            isUserCompanyAdmin={isUserCompanyAdmin}
                            openRemovePaymentMethodModal={this.openRemovePaymentMethodModal}
                            openAllowPaymentMethodModal={this.openAllowPaymentMethodModal}
                            isCompanyToggled={isCompanyToggled}
                          />
                        );
                      })
                    }
                  </tbody>
                </Table>
              )
            : ''
          }
          { isPaymentMethodLoading &&
            <div className="overlay-lpm">
              <div className="loader-api" />
            </div>
          }
          { (!paymentMethods || !paymentMethods.length) && !isPaymentMethodLoading &&
            <div className="no-method">
              <p>{Translation().account.invoices.tables.payment_methods.no_method}</p>
            </div>
          }
        </div>
        <Snackbar
          messageText={this.state.snackbarMessage}
          statusClass={this.state.snackbarStatus}
          open={this.state.isSnackbarOpen}
          autoHideDuration={this.state.autoHideDuration}
          closeFunction={this.closeSnackbar}
        />
      </div>
    );
  }

  componentWillUnmount() {
    const handleScrollListener = this.handleScroll;
    window.removeEventListener('scroll', handleScrollListener);
  }
}

export default reduxForm({
  form: 'editPaymentAccount',
})(InvoicesPayment);
