import React, { Component } from 'react';

import Config from '../../../../config/Config';

class TermsOfUseFR extends Component {
  render() {
    return (
      <div className="articles">
        <div className="article-content" id="introduction">
          <p>Bienvenue sur notre site internet <a href="https://lightshop.wall-market.com" target="_blank" rel="noopener noreferrer">lightshop.wall-market.com</a> (le "Site").</p>
          <p>Les présentes conditions générales de vente définissent les modalités et conditions d’achat des différentes prestations que le Site propose.</p>
          <p className="bold">Merci de lire attentivement ces conditions avant de passer toute commande sur le Site. En y accédant et en passant une commande, vous acceptez d'être lié sans réserve par les présentes conditions générales de vente.</p>
          <p>La manière dont nous recueillons et utilisons des données à caractère personnel en lien avec votre accès et votre utilisation au Site est décrite dans notre politique de confidentialité  <a href="https://lightshop.wall-market.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://lightshop.wall-market.com/privacy-policy</a></p>
          <p>Si vous avez des questions au sujet de ces conditions, veuillez prendre contact avec nous avant de passer commande. Si vous n'acceptez pas intégralement ces conditions, ne passez pas commande sur notre Site.</p>
        </div>
        <div className="article-content" id="about-us">
          <h2>1. Qui sommes-nous?</h2>
          <p>Le Site est exploité par la société SAS Wall-Market, au capital de 19.146 euros, dont le siège social est situé 22 rue Legendre 75017 Paris, et ayant pour numéro d’immatriculation unique RCS Paris 532 727 740 RCS Paris.</p>
          <p>Vous pouvez nous contacter aux coordonnées suivantes :</p>
          <ul>
            <li>Adresse : {Config.brand_address}, {Config.brand_city}</li>
            <li>Téléphone : 01.42.27.45.53</li>
            <li>Email : <a href="mailto:sales@wall-market.com" target="_blank" rel="noopener noreferrer">sales@wall-market.com</a></li>
          </ul>
        </div>
        <div className="article-content" id="services-description">
          <h2>2. Description des services</h2>
          <p>Le Site propose plusieurs services (le ou les « <b>Service(s)</b> ») destinés à faciliter la commercialisation ou la mise en valeur de biens immobiliers.</p>
          <p>L’exécution de nos Services se réalise en plusieurs étapes :</p>
          <p>
            - Enregistrement par le Client des informations relatives à son bien, notamment sa superficie, et permettant de le contacter ; <br/>
            - Exécution de la prestation permettant la livraison du Service par un prestataire indépendant sélectionné par Wall-Market, à distance ou au lieu de situation du bien immobilier objet du Service. Dans l’hypothèse d’une exécution sur place, le Client s’engage à accueillir le prestataire dans un lieu propre et rangé pour permettre l’exécution immédiate de la prestation ; et <br/>
            - Livraison du Service sous forme de fichier immatériel (ci-après le/les « <b>Fichier(s)</b> »), dont la durée de conservation et d’usage varie selon les indications données pour chaque Service au moment de la commande.
          </p>
          <p>
            Les Services peuvent être commandés à la carte ou par PACK et des Options peuvent les compléter. La description détaillée des Services, des Options disponibles et de leur durée de conservation sur votre compte personnel est disponible au moment de leur commande.
          </p>
          <p>
            <b>ATTENTION : tous les Services ou Options ne sont pas disponibles dans tous les pays.</b>
          </p>
          <p>Il est également indiqué que :</p>
          <p>
            - Un « <b>SHOOTING</b> » ou une « <b>PHOTO</b> » correspond à la réalisation de photos HD de votre bien immobilier par l’utilisation d’un matériel professionnel. <br/>
            - Le Service « <b>PLAN</b> » permet la réalisation de plans 2D (également 3D si cette Option est choisie) de votre bien immobilier.
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              ATTENTION, ces plans 2D ou 3D, ainsi que les mesures sur la base desquelles ils sont élaborés, n'ont aucune valeur juridique. Ils sont strictement indicatifs et illustratifs. Par exemple, ils ne peuvent être utilisés comme support à la réalisation de travaux, pour déterminer le métrage carrez, ou se substituer à un plan officiel (ex : plan cadastral, plan annexé au règlement de copropriété). De la même manière, si les éléments structurels (comme l'emplacement des murs porteurs) sont donnés, c’est à titre indicatif : seuls des professionnels certifiés étant habilités à fournir de telles informations ayant une valeur juridique.
            </b>
          </p>
          <p>
            - Le Service « <b>DIAGNOSTICS</b> » permet la réalisation d’un ou plusieurs diagnostics (« <b>DIAGNOSTICS A LA CARTE</b> ») rendus obligatoires par la loi française pour les biens immobiliers situés en France, par un professionnel disposant d’un certificat délivré par un organisme accrédité par le Comité français d’accréditation (COFRAC). Les diagnostics obligatoires au regard de la loi ne sont pas les mêmes pour tous les biens immobiliers, selon leur nature ou leur destination.
          </p>
          <p>
            <b>Wall-Market ne proposant pas des prestations de conseils, il vous appartient de déterminer, selon l’objectif poursuivi (par exemple, vente ou location de votre bien immobilier), quel(s) diagnostic(s) sont requis par la loi. Toute responsabilité de Wall-Market est exclue à ce titre.</b>
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              Attention : le prix de « diagnostics à la carte » implique le règlement : (i) du déplacement d’un prestataire, pour une somme forfaitaire dont le prix est précisé avant de passer commander <span style={{textDecoration: "underline"}}>et</span> (ii) de chaque diagnostic sélectionné.
            </b>
          </p>
          <p>
            - <b>Les « IMAGES » (ex : proposées pour les « PACK ») ne sont pas des Services « PHOTOS » ou « SHOOTING », mais des « CAPTURES ».</b> Les « CAPTURES » ne sont pas réalisées avec un matériel de photographie professionnel mais des images de qualité haute définition, extraites de la machine SCAN 3D MATTERPORT utilisée pour réaliser des « <b>VISITES VIRTUELLES</b> ».
          </p>
        </div>
        <div className="article-content" id="access-services">
          <h2>3. Accès au Site et à ses Services </h2>
          <p>
            L'accès à notre Site et à ses services est autorisé de manière temporaire. Nous nous réservons le droit de supprimer ou de modifier sans préavis l'accès à notre Site ou à nos Services. <br/> <br/>
            Nous ne sommes pas responsables si, pour quelque motif que ce soit, notre Site ou les services qu’il propose n'est pas disponible à un moment donné ou pendant une période donnée.
          </p>
        </div>
        <div className="article-content" id="product-delivery">
          <h2>4. Livraison des Services, durée d’usage et durée de conservation</h2>
          <p className="bold">4.1 Délai de livraison</p>
          <p>
            Wall-Market s'engage à livrer le/les Fichier(s) des Services commandés selon les délais indiqués pour chaque Service, dans le cadre de la description proposée préalablement à toute commande.
          </p>
          <p>
            Les délais de livraison indiqués s’entendent en jours ouvrés.
          </p>
          <p>
            En cas de retard dû par Wall-Market, Wall-Market s’engage à exécuter et livrer les Services dans un délai raisonnable.
          </p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            L’éventuelle responsabilité de Wall-Market est limitée au prix du ou des Service(s) d’une commande. <br/><br/>
            Le Client reconnaît et accepte qu'aucun préjudice complémentaire (économique ou moral) ne pourra résulter du retard de l’exécution d’un Service ou de sa livraison.<br/><br/>
            Si le Client estime qu’un tel retard est susceptible de lui causer un préjudice additionnel (économique ou moral), il doit renoncer à passer commande sur le Site.
          </p>
          <p className="bold">4.2 Modalités de livraison</p>
          <p>
            Le ou les Fichiers résultant de(s) Service(s) sont livrés au Client via sa/leur mise à disposition sur leur compte dédié, créé au moment de la première commande, dans le délai stipulé à la clause 4.1.
          </p>
          <p>
            Il convient de rappeler que le support des Fichiers est immatériel et électronique. Aucun autre format ne sera communiqué (ex : papier, CD ROM ou clé USB).
          </p>
          <p className="bold">4.3 Durée d’usage / Durée de conservation des Fichiers sur le compte dédié </p>
          <p>La durée de conservation d’un Fichier sur le compte dédié du Client, et la durée d’usage d’un Fichier varie selon le Service concerné.</p>
          <p>Les durées de conservation et d’usage d’un Fichier sont précisées sur la fiche Service.</p>
          <p>De manière générale :</p>
          <p>
            - <span className="bold" style={{textDecoration: "underline"}}>la durée de conservation</span> d’un Fichier sur le compte dédié est de <span className="bold" style={{textDecoration: "underline"}}>6 mois</span> à compter de sa livraison.
          </p>
          <p className="bold">Une fois un Fichier livré, il appartient au Client de le télécharger pour pouvoir l’/les utiliser/exploiter et assurer sa/leur conservation.</p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>
              Passé le délai de 6 mois, les Fichiers ne sont plus accessibles sur le compte Client dédié. Pour en conserver l’accès, il appartient donc au Client de le télécharger de le conserver. <br/><br/>
              EN CAS DE PERTE, AUCUNE COPIE NE SERA REMISE.
            </b>
          </p>
          <p>
            - <span className="bold" style={{textDecoration: "underline"}}>la durée d’usage</span>  d’un Fichier est, pour la plupart, illimitée, sous réserve des obligations légales susceptibles de s’appliquer et qu’il appartient au Client de déterminer (par exemple, pour les DIAGNOSTICS obligatoires).
          </p>
          <p style={{border: "1px solid", padding: 4}}>
            <b>IL APPARTIENT AU CLIENT DE SE RENSEIGNER SUR LA DUREE D’USAGE DE CHAQUE SERVICE COMMANDE.</b><br/><br/>
            Par exemple, <span className="bold">les Fichiers d’une VISITE VIRTUELLE ont une</span> <span className="bold" style={{textDecoration: "underline"}}>durée d’usage limitée à 6 mois</span>. Cette durée peut être renouvelée moyennant paiement.
          </p>
        </div>
        <div className="article-content" id="retractation-right">
          <h2>5. Droit de rétractation</h2>
          <p>
            Le Client ayant la qualité de consommateur a le droit de se rétracter sans donner de motif dans un délai de quatorze jours après avoir commandé l’un de nos Services, sauf dans les hypothèses mentionnées ci-dessous. Le jour où le contrat est conclu n’est pas compté dans le délai précité. Si ce délai expire un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu’au premier jour ouvrable suivant.
          </p>
          <p>
            Conformément à l’article L.221-28 du code de la consommation, le droit de rétractation ne peut être exercé pour certains contrats, comme les suivants : <br/>
            - De fourniture de services pleinement exécutés avant la fin du délai de rétractation et dont l’exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation ; <br/>
            - De fourniture de biens confectionnés selon les spécifications du consommateur ou nettement personnalisés ; <br/>
            - De fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation. <br/>
          </p>
          <p>
            Pour exercer votre droit de rétractation, il vous suffit d’annuler votre commande.
          </p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            Ainsi, le Client pouvant bénéficier de la qualification de « consommateur » au sens du Code de la consommation reconnaît renoncer expressément à son droit de rétractation pour toute prestation devant être réalisée dans un délai inférieur à 14 jours calendaires à compter de la confirmation de sa commande.
          </p>
          <p>
            Le Client consommateur exerce son droit de rétractation en informant Wall-Market de sa décision de se rétracter par l’envoi, avant l'expiration du délai prévu ci-avant, du formulaire de rétractation joint en Annexe I des présentes ou de toute autre déclaration, dénuée d'ambiguïté, exprimant sa volonté de se rétracter.
          </p>
          <p>
            En cas d’exercice par le Client de son droit de rétractation, Wall-Market lui remboursera la totalité des sommes versées sans retard injustifié et au plus tard dans les quatorze (14) jours à compter de la date à laquelle Wall-Market est informée de la décision du Client de se rétracter. Le remboursement sera effectué en utilisant le même moyen de paiement que celui utilisé par le Client pour la transaction initiale.
          </p>
        </div>
        <div className="article-content" id="client-duty">
          <h2>6. Obligations du Client</h2>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            Le Client s’engage à renseigner, <span style={{textDecoration: "underline"}}>au moment de la commande</span> des informations exactes : (i) pour permettre de le contacter et (ii) relatives à la superficie du bien immobilier objet des Services. L’information relative à la superficie est déterminante pour fixer le prix des Services et le temps nécessaire à leur exécution. Toute information erronée sur ce point aura pour effet de réviser le prix du ou des Services, ce que le Client accepte.<br/><br/>
            La présence du Client <span style={{textDecoration: "underline"}}>lors de l’exécution d’un Service</span> requérant le déplacement d’un prestataire indépendant au lieu du bien immobilier est obligatoire.<br/><br/>
            Le Client s'engage à ce que le bien immobilier objet du Service soit propre et rangé au moment de l’accueil du prestataire, pour permettre son exécution de manière immédiate. Dans l'hypothèse où l'état du bien immobilier ne permettrait pas la réalisation des prestations au moment de l’arrivée du prestataire, la prestation ne sera pas réalisée et le Client ne pourra pas être remboursé.<br/><br/>
            De la même manière, le Client s’engage à sécuriser ses objets, notamment de valeur. Tout dommage en raison du manquement par le Client à cette obligation sera à sa charge exclusive.
          </p>
        </div>
        <div className="article-content" id="property-right">
          <h2>7. Limites au droit d'utilisation des Services </h2>
          <p>Les Services commandés demeurent la propriété de Wall-Market.</p>
          <p>En conséquence, le Client ne pourra utiliser les Services que pour mettre en valeur son bien immobilier, pour son propre usage ou pour sa mise en vente ou mise en location. Toute autre utilisation est interdite et nécessite l’accord préalable et express de Wall-Market.</p>
        </div>
        <div className="article-content" id="client-account">
          <h2>8. Fonctionnement du compte Client</h2>
          <p className="bold">8.1 Nécessité de créer un compte Client pour passer une commande sur notre Site</p>
          <p>Pour passer une commande sur notre Site, le Client doit enregistrer et créer un compte qui lui sera dédié.</p>
          <p>Pour enregistrer un compte, le Client doit renseigner certaines informations à caractère personnel. Ces informations doivent être exactes et tenues à jours.</p>
          <p className="bold">8.2 Responsabilité du Client s’agissant de la gestion du compte</p>
          <p>
            Le Client est responsable de la préservation de la confidentialité de ses informations de connexion et des activités de son compte. Si vous avez des inquiétudes au sujet de vos informations de connexion ou si vous pensez qu'elles ont été piratées, prenez immédiatement contact avec  <a href="mailto:rgpd@wall-market.com" target="_blank" rel="noopener noreferrer">rgpd@wall-market.com</a> pour nous en informer.
          </p>
          <p className="bold">8.3 Résiliation et désactivation du compte</p>
          <p>
            Nous pouvons désactiver votre compte et à l’accès aux services à tout moment, notamment pour les raisons suivantes : <br/>
            - Non-respect des présentes conditions générales; <br/>
            - Problèmes ou difficultés d'ordre technique ou de sécurité ; <br/>
            - Période d'inactivité par le Client durant une (1) année ; <br/>
            - Utilisation du compte ou du Site de manière illégale ou frauduleuse.
          </p>
          <p>Le compte peut également être désactivé à la demande du Client.</p>
          <p>La résiliation du compte emporte notamment pour conséquence la suppression des Fichiers y figurant.</p>
          <p>Le Client reconnaît que Wall-Market ne pourra être tenue responsable, à son égard ou à l’égard de toute autre personne, en raison de la résiliation ou de la désactivation du compte.</p>
        </div>
        <div className="article-content" id="responsibility">
          <h2>9. Exclusion de responsabilité</h2>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            En cas de faute prouvée par le Client, Wall-Market ne pourra être tenue responsable que de la réparation des conséquences pécuniaires des dommages directs et prévisibles.<br/><br/>
            Il en résulte que la responsabilité éventuelle de Wall-Market sera limitée au coût de la prestation commandée. Ainsi la responsabilité de Wall-Market pour perte de profit, gain manqué ou préjudice moral, est exclue.<br/><br/>
            En toute hypothèse, Wall-Market ne peut être tenu responsable du retard de l’exécution d’un Service, de la livraison d’un Fichier ou de la perte de celui-ci.<br/><br/>
            Les limites et les exclusions du présent article ne prétendent pas limiter la responsabilité ou modifier les droits bénéficiant aux Clients « consommateurs ».
          </p>
        </div>
        <div className="article-content" id="personal-data">
          <h2>10. Données personnelles</h2>
          <p>
            Les informations personnelles fournies pour le traitement des prestations proposées par le Site font l'objet des mesures de protection décrites sur la page « Mentions légales » du Site.<br/><br/>
            Pour toute commande d’un Service sur le Site, le Client autorise Wall-Market à transférer les données nécessaires pour permettre l’exécution d’un ou plusieurs Service(s), en particulier au prestataire sélectionné.
          </p>
        </div>
        <div className="article-content" id="price-refund">
          <h2>11. Prix, remboursement, annulation</h2>
          <p><span className="bold">11.1</span> Le Service est fourni au prix indiqué sur le Site. </p>
          <p><span className="bold">11.2</span> Il est rappelé que pour le prix des « DIAGNOSTICS A LA CARTE », une somme forfaitaire pour le déplacement du diagnostiqueur est facturée en complément du prix de chaque DIAGNOSTIC sélectionné. </p>
          <p className="bold">11.3 Annulation par le Client :</p>
          <p>
            - <b>Les Services n’impliquant pas le déplacement d’un prestataire indépendant au lieu du bien immobilier</b> peuvent être annulés jusqu’à une heure après la commande du Service. <b>Passé ce délai, ces Services sont facturés à 100 % ;</b> <br/>
            - <b>Les Services impliquant le déplacement d’un prestataire indépendant au lieu du bien immobilier</b>  peuvent être annulés jusqu’à 6 heures avant le rendez-vous pris avec le prestataire pour l’exécution du/des Service(s). <b>Passé ce délai, ces Services sont facturés à 100 % ;</b>
          </p>
          <p><span className="bold">11.4 Annulation par Wall-Market :</span> Wall-Market peut être contrainte d’annuler l’exécution d’un Service et se réserve en conséquence ce droit. Dans cette hypothèse, le Client sera informé par téléphone ou par courriel et il lui sera proposé, au choix, l’exécution du/des Service(s) commandé(s) à une autre date ou créneau accepté par le Client OU leur remboursement.</p>
          <p><span className="bold">11.5 Modalités de paiement</span></p>
          <p style={{border: "1px solid", padding: 4}}>
            Tout paiement est réalisé par l’intermédiaire de la société MangoPay. Toute commande d’une prestation proposée par Wall-Market implique en conséquence l’acceptation sans réserve des conditions générales d’utilisation de MangoPay, disponibles en cliquant sur le lien ci-après <a href="https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf" target="_blank" rel="noopener noreferrer">https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf</a>.
          </p>
          <p>Une facture sera émise et disponible sur le compte du Client au sein de son espace dédié.</p>
          <p><span className="bold">11.6 Sanctions en cas d’incident de paiement</span></p>
          <p>
            Tout incident de paiement aura immédiatement et automatiquement pour conséquence, sans mise en demeure préalable : <br/>
            - l’exigibilité immédiate des sommes dues ; <br/>
            - la suspension immédiate de l’exécution ou de la livraison du/des Services en cours jusqu’au complet paiement de l’intégralité des sommes dues par le Client ; <br/>
            - la facturation d’un intérêt de retard au taux de 10 (dix) %, calculé sur la base du montant total des sommes dues par le Client et d’une indemnité forfaitaire de cent (100) € au titre des frais de recouvrement.
          </p>
        </div>
        <div className="article-content" id="payment-modality">
          <h2>12. Médiation pour les clients consommateurs</h2>
          <p>
            <span className="bold">Médiation.</span> En cas de litige entre le professionnel et le consommateur, ceux-ci s’efforceront de trouver une solution amiable. A défaut d’accord amiable, conformément à l’article L. 152-1 du code de la consommation, vous pouvez recourir gratuitement au service de médiation AME Conso dont nous relevons par voie électronique: <a href="https://www.mediationconso-ame.com/demande-de-mediation-ame.html" target="_blank" rel="noopener noreferrer">https://www.mediationconso-ame.com/demande-de-mediation-ame.html</a> ou par voie postale: AME Conso – 11 place Dauphine 75001 Paris. 
          </p>
          <p>
            Ainsi, en cas de réclamation non résolue amiablement et après démarche préalable écrite vis-à-vis de Wall-Market, le Médiateur peut être saisi pour tout litige de consommation dont le règlement n’aurait pas abouti. Le lien suivant permet de connaître les modalités de saisine du Médiateur <a href="https://www.mediationconso-ame.com/le-processus-de-la-mediation-de-la-consommation.html" target="_blank" rel="noopener noreferrer">https://www.mediationconso-ame.com/le-processus-de-la-mediation-de-la-consommation.html</a>.
          </p>
          <p>
            Au niveau européen, la Commission Européenne met à votre disposition une plateforme de résolution en ligne des litiges, à laquelle vous pouvez accéder en cliquant sur le lien suivant : <a href="https://ec.europa.eu/consumers/odr/main/?event=main.trader.register" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/main/?event=main.trader.register</a>.
          </p>
          <p>Wall-Market n'est pas obligée et ne s’engage pas à utiliser un mode de résolution de litige alternatif pour résoudre tout litige avec un consommateur.</p>
          <p>
            <b>Plateforme de Règlement en Ligne des Litiges</b>. Conformément à l’article 14 du Règlement (UE) n°524/2013, la Commission Européenne a mis en place une plateforme de Règlement en Ligne des Litiges, facilitant le règlement indépendant par voie extrajudiciaire des litiges en ligne entre consommateurs et professionnels de l’Union européenne. Cette plateforme est accessible via le lien suivant : <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage</a>
          </p>
        </div>
        <div className="article-content" id="applicable-law">
          <h2>13. Juridiction compétente et loi applicable</h2>
          <p>Les présentes conditions générales de vente sont soumises au droit français.</p>
          <p style={{border: "1px solid", padding: 4}} className="bold">
            En cas de litige, seuls les juridictions françaises de la ville de Paris (75) seront compétentes, sauf disposition impérative contraire (ex : action d’un consommateur français).
          </p>
        </div>
      </div>
    );
  }
}

export default TermsOfUseFR;
