import React, { Component } from 'react';
import { subscribe } from 'redux-subscriber';
import { formValueSelector, change } from 'redux-form';

import ShopFailure from './ShopFailure';
import AddressFailure from './AddressFailure';
import NoFamilyAvailable from './NoFamilyAvailable';
import ZoneNotCovered from './ZoneNotCovered';
import Property from '../property/Property';
import Services from '../services/Services';
import Connection from '../connection/Connection';
import Payment from '../payment/Payment';
import Validation from '../validation/Validation';
import Snackbar from '../../../components/commons/snackbar/Snackbar';
import store from '../../../redux/store';
import Translation from '../../../translation/Translation';
import {
  getProperty,
  apiGetFamilyService,
  setServiceFamilies,
  setProperty,
  apiPostOrder,
} from '../../../helpers/ShopFunctions';
import { setCartRequestProperty } from '../../../helpers/CartFunctions';

class ShopRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      stepService: undefined,
      address: undefined,
      size: undefined,
      propertyType: undefined,
      isCartNotEmpty: false,
      isUserLogged: false,
      isOrderDone: false,
      succcessOrderData: null,
      isSendOrderInProgress: false,
      autoHideDuration: 4000,
      isSnackbarOpen: undefined,
      snackbarMessage: '',
      snackbarStatus: '',
    };

    this.sendFirstStep = this.sendFirstStep.bind(this);
    this.sendSecondStep = this.sendSecondStep.bind(this);
    this.finishOrder = this.finishOrder.bind(this);
    this.handleLoginUser = this.handleLoginUser.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    /**
     * Routing with url, need to be refactored
     */
    if (nextProps.step >= 1 && nextProps.step <= 4) {
      /**
       * If user got one product minimum
       */
      if (nextProps.step === 2 && nextProps.cartStatus) {
        this.setState({ step: 1, stepService: 2 });
      }
      /**
       * If user logged with at least one cart item
       */
      if (
        nextProps.step === 3 &&
        nextProps.userStatus &&
        nextProps.cartStatus
      ) {
        this.setState({ step: 4 });
      }
      /**
       * If user not logged with at least one cart item
       */
      if (
        nextProps.step === 3 &&
        !nextProps.userStatus &&
        nextProps.cartStatus
      ) {
        this.setState({ step: 3 });
      }
      /**
       * If user logged with at least one cart item
       */
      if (
        nextProps.step === 4 &&
        nextProps.userStatus &&
        nextProps.cartStatus
      ) {
        this.setState({ step: 4 });
      }
      /**
       * If user not logged with at least one cart item
       */
      if (
        nextProps.step === 4 &&
        !nextProps.userStatus &&
        nextProps.cartStatus
      ) {
        this.setState({ step: 3 });
      }
      /**
       * If user logged with no cart item
       */
      if (
        nextProps.step === 4 &&
        nextProps.userStatus &&
        !nextProps.cartStatus
      ) {
        this.setState({ step: 1 });
      }
      /**
       * If user isn't logged with no cart item
       */
      if (
        nextProps.step === 4 &&
        !nextProps.userStatus &&
        !nextProps.cartStatus
      ) {
        this.setState({ step: 1 });
      }
    }
  }

  componentDidMount() {
    /**
     * Display none the header order button
     */
    const buttonCommandHeader =
      document.getElementsByClassName('btn-order-dekstop')[0];
    const addressInput = document.getElementById('address-input');

    if (buttonCommandHeader) buttonCommandHeader.style.display = 'none';

    // eslint-disable-next-line
    const subscribeUser = subscribe('user', (state) => {
      if (state.user) this.setState({ isUserLogged: true });
    });

    /**
     * Check if userCart exist with a product
     */
    // eslint-disable-next-line
    const subscribeUserCartProducts = subscribe(
      'userCart.products',
      (state) => {
        const userCart = state.userCart;

        if (userCart && userCart.products && userCart.products.length > 0) {
          this.setState({ isCartNotEmpty: true });
        } else {
          this.setState({ isCartNotEmpty: false });
        }
      }
    );

    /**
     * Check if userCart exist with a property_product,
     * Then set the property product values to  the <Property/> component props
     */
    // eslint-disable-next-line
    const subscribeUserCart = subscribe('userCart', (state) => {
      const userCart = state.userCart;

      if (userCart && userCart.property_object) {
        const previousProperty = userCart.property_object;

        this.setState({
          address: previousProperty.address
            ? previousProperty.address
            : undefined,
          size: previousProperty.size ? previousProperty.size : undefined,
          propertyType: previousProperty.property_type
            ? previousProperty.property_type
            : undefined,
        });
      } else {
        if (addressInput) {
          addressInput.focus();
        }
        this.setState({
          address: undefined,
          size: undefined,
          propertyType: undefined,
        });
      }
    });

    /**
     * Check if history contains infos property, this case happen when the user filled property inputs from the home page
     */
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.property
    ) {
      const property = this.props.history.location.property;

      if (property && property.address && property.size) {
        this.setState({
          step: 2,
          address: property.address,
          size: property.size,
          propertyType: property.propertyType ? property.propertyType : null,
        });
      }

      store.dispatch(change('shop', 'address', property.address));
      store.dispatch(change('shop', 'addressAutocomplete', property.address));
      store.dispatch(change('shop', 'size', property.size));

      if (property.propertyType) {
        store.dispatch(change('shop', 'propertyType', property.propertyType));
      }

      this.sendFirstStep(property);
    }
  }

  openSnackbar() {
    this.setState({ isSnackbarOpen: true });
  }

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  }

  sendFirstStep(propertyProvided = null) {
    /**
     * Loader top button next on desktop
     */
    const btnTextStep1_1 = document.getElementById('btn-text-step1-1');
    const chevronStep1_1 = document.getElementById('chevron-step1-1');
    const loaderStep1_1 = document.getElementById('loader-step1-1');

    if (btnTextStep1_1) btnTextStep1_1.style.display = 'none';

    if (chevronStep1_1) chevronStep1_1.style.display = 'none';

    if (loaderStep1_1) loaderStep1_1.style.display = 'inline-block';

    /**
     * Loader top button next on mobile
     */
    const btnTextStep1_3 = document.getElementById('btn-text-step1-3');
    const chevronStep1_3 = document.getElementById('chevron-step1-3');
    const loaderStep1_3 = document.getElementById('loader-step1-3');

    if (btnTextStep1_3) btnTextStep1_3.style.display = 'none';

    if (chevronStep1_3) chevronStep1_3.style.display = 'none';

    if (loaderStep1_3) loaderStep1_3.style.display = 'inline-block';

    /**
     * Loader bottom button next
     */
    const chevronStep1_2 = document.getElementById('chevron-step1-2');
    const loaderStep1_2 = document.getElementById('loader-step1-2');

    if (chevronStep1_2) chevronStep1_2.style.display = 'none';

    if (loaderStep1_2) loaderStep1_2.style.display = 'inline-block';

    /**
     * Here we make the first api call, if have a response 'TRUE', we can set the step state to 2
     * But if we have a 'FALSE' reponse we are able to display the error modal <NoServiceAvailable/>
     */
    const state = store.getState();
    const selector = formValueSelector('shop');
    let propertyForm = {};

    /**
     * Check if a property is provided from another components
     */
    if (propertyProvided) {
      propertyForm.address = propertyProvided.address;
      propertyForm.size = propertyProvided.size;
      propertyForm.property_type = propertyProvided.propertyType
        ? propertyProvided.propertyType
        : null;
    } else {
      propertyForm.address = selector(state, 'address');
      propertyForm.size = selector(state, 'size');
      propertyForm.property_type = selector(state, 'propertyType');
    }

    /**
     * Set the property to redux store
     * Then get the property to redux store
     */
    setProperty(propertyForm);
    const property = getProperty();

    if (property) {
      if (property.property_type && property.property_type === 'other') {
        property.property_type = null;
      }
      /**
       * Interrogate the API about the services availables for the property submited
       */
      apiGetFamilyService(
        property,
        (successData) => {
          if (successData && successData.length) {
            /**
             * Set property to redux cart
             */
            setCartRequestProperty(property);

            /**
             * Store service families response into redux
             */
            setServiceFamilies(successData);

            this.setState({ step: 2 });
            let isZoneCovered = false;

            for (let i = 0; i < successData.length; i++) {
              if (successData[i].orderable) {
                isZoneCovered = true;
                break;
              }
            }

            if (!isZoneCovered) {
              const zoneNotCovered = document.getElementById('zoneNotCovered');
              const serviceShopContent =
                document.getElementById('serviceShopContent');

              if (serviceShopContent) serviceShopContent.style.display = 'none';
              if (zoneNotCovered) zoneNotCovered.style.display = 'block';
            }
          } else {
            this.setState({ step: 2 });

            const noFamilyAvailable =
              document.getElementById('noFamilyAvailable');
            const serviceShopContent =
              document.getElementById('serviceShopContent');

            if (serviceShopContent) serviceShopContent.style.display = 'none';
            if (noFamilyAvailable) noFamilyAvailable.style.display = 'block';
          }
        },
        (error) => {
          this.setState({ step: 2 });

          const shopFailure = document.getElementById('shopFailure');
          const addressNotFoundFailure = document.getElementById(
            'addressNotFoundFailure'
          );
          const updateBrowser = document.getElementById('updateBrowser');
          const serviceShopContent =
            document.getElementById('serviceShopContent');

          if (serviceShopContent) serviceShopContent.style.display = 'none';

          if (updateBrowser) updateBrowser.style.display = 'block';

          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 400
          ) {
            /**
             * Error on property address
             */
            if (addressNotFoundFailure)
              addressNotFoundFailure.style.display = 'block';
          } else {
            if (shopFailure) shopFailure.style.display = 'block';
          }
        }
      );
    }
  }

  sendSecondStep() {
    /**
     * If api respond a valid cart then we go no next step
     * If user already logged we go to step4.
     */
    const state = store.getState();

    store.subscribe(() => {
      if (state.user) this.setState({ step: 4 });
    });

    this.setState({ step: 3 });
  }

  handleLoginUser() {
    /**
     * Auto log user after login or register on step 3
     * If user already logged we go to step 4.
     */
    // eslint-disable-next-line
    const subscribeUser = subscribe('user', (state) => {
      if (this.state.step === 3) {
        if (state.user) {
          this.setState({ step: 4 });
        }
      }
    });
  }

  finishOrder(values) {
    const { isSendOrderInProgress } = this.state;

    if (isSendOrderInProgress) {
      return;
    }

    const appPaymentBtn = document.getElementById('appPaymentBtn');
    const orderBtnTxt = document.getElementById('order-btn-txt');
    const orderBtnLoader = document.getElementById('order-btn-loader');
    const paymentErrorOuput = document.getElementById('payment-failed');

    if (!values.invoiceAddress || !values.paymentMethod) {
      return;
    }

    if (orderBtnTxt && orderBtnLoader) {
      orderBtnTxt.style.display = 'none';
      orderBtnLoader.style.display = 'inline-block';
    }

    if (appPaymentBtn) {
      appPaymentBtn.disabled = true;
    }

    let propertyAccessDetails = null;

    if (values.floor || values.digicode || values.interphone) {
      propertyAccessDetails =
        (values.floor
          ? '\n' +
            Translation().shop.step_fields.names.floor +
            ': ' +
            values.floor +
            ', '
          : '') +
        (values.digicode
          ? '\n' +
            Translation().shop.step_fields.names.digital_code +
            ': ' +
            values.digicode +
            ', '
          : '') +
        (values.interphone
          ? '\n' +
            Translation().shop.step_fields.names.intercom +
            ': ' +
            values.interphone
          : '');
    }

    const orderObj = {
      invoice_address_id: values.invoiceAddress,
      invoice_payment_method_id: values.paymentMethod,
      property_contact_name: values.onPlace ? values.onPlace : null,
      property_contact_phone: values.phone ? values.phone : null,
      property_contact_comment: values.comment ? values.comment : null,
      property_access_details: propertyAccessDetails,
    };

    if (values.reference) {
      orderObj.reference = values.reference;
    }

    if (values.delegate_email && values.delegate_amount) {
      orderObj.delegate_email = values.delegate_email;
      orderObj.delegate_amount = values.delegate_amount;
    }

    this.setState({ isSendOrderInProgress: true });

    apiPostOrder(
      orderObj,
      (successData) => {
        /**
         * Send order to google tag manager
         */
        if (window && window.dataLayer) {
          window.dataLayer.push({
            event: 'order.created',
            order_id: successData.id,
            total: successData.total,
            total_tax_excluded: successData.total_tax_excluded,
            currency_isocode:
              successData.currency && successData.currency.isocode
                ? successData.currency.isocode
                : null,
            country_isocode:
              successData.property && successData.property.country_isocode
                ? successData.property.country_isocode
                : null,
            zip_code:
              successData.property && successData.property.zip_code
                ? successData.property.zip_code
                : null,
            city:
              successData.property && successData.property.city
                ? successData.property.city
                : null,
          });

          if (successData.products && successData.products.length > 0) {
            for (let i = 0; i < successData.products.length; i++) {
              window.dataLayer.push({
                event: 'order_product.created',
                order_product_id: successData.products[i].id,
                product_id: successData.products[i].product_id,
                product_shortcode: successData.products[i].shortcode,
                amount: successData.products[i].amount,
                amount_tax_excluded:
                  successData.products[i].amount_tax_excluded,
              });
            }
          }
        }

        if (orderBtnTxt && orderBtnLoader) {
          orderBtnTxt.style.display = 'inline-block';
          orderBtnLoader.style.display = 'none';
        }
        if (appPaymentBtn) {
          appPaymentBtn.disabled = false;
        }
        this.setState({
          isSendOrderInProgress: false,
          isOrderDone: true,
          succcessOrderData: successData,
        });
      },
      (error) => {
        if (appPaymentBtn) {
          appPaymentBtn.disabled = false;
        }
        if (orderBtnTxt && orderBtnLoader) {
          orderBtnTxt.style.display = 'inline-block';
          orderBtnLoader.style.display = 'none';
        }

        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : Translation().shop.payment.payment_failed;

        if (paymentErrorOuput) paymentErrorOuput.innerHTML = errorMsg;

        this.openSnackbar();
        this.setState({
          isSendOrderInProgress: false,
          snackbarMessage: errorMsg,
          snackbarStatus: 'error',
        });
      }
    );
  }

  render() {
    const { history, shopReady } = this.props;
    const {
      step,
      isOrderDone,
      succcessOrderData,
      snackbarMessage,
      snackbarStatus,
      isSnackbarOpen,
      autoHideDuration,
    } = this.state;

    return (
      <div className="app-shop">
        {step === 1 && !isOrderDone && (
          <Property
            address={this.state.address}
            size={this.state.size}
            propertyType={this.state.propertyType}
            cartStatus={this.state.isCartNotEmpty}
            onSubmit={this.sendFirstStep}
            stepService={this.state.stepService}
            history={history}
            shopReady={shopReady}
          />
        )}
        {step === 2 && !isOrderDone && (
          <Services onSubmit={this.sendSecondStep} history={history} />
        )}
        {step === 3 && !isOrderDone && (
          <Connection onSubmit={this.handleLoginUser} history={history} />
        )}
        {step === 4 && !isOrderDone && (
          <Payment onSubmit={this.finishOrder} history={history} />
        )}
        {isOrderDone && <Validation dataObject={succcessOrderData} />}
        <AddressFailure />
        <ZoneNotCovered />
        <NoFamilyAvailable />
        <ShopFailure />
        <Snackbar
          messageText={snackbarMessage}
          statusClass={snackbarStatus}
          open={isSnackbarOpen}
          autoHideDuration={autoHideDuration}
          closeFunction={this.closeSnackbar}
        />
      </div>
    );
  }
}

export default ShopRouter;
