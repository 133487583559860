import React, { Component } from 'react';
import Select from 'react-select';
import { Col, Clearfix } from 'react-bootstrap';
import Checkbox from 'material-ui/Checkbox';
import Dialog from 'material-ui/Dialog';

import {
  getOrderProductHostingPlans,
  postOrderProductHostingPlan,
} from '../../../../../helpers/AccountFunctions';
import {
  getUserPaymentMethod,
  getInvoiceUserAddresses,
} from '../../../../../helpers/UserFunctions';
import { objectMapToArray } from '../../../../../helpers/HelperFunctions';

import Snackbar from '../../../../commons/snackbar/Snackbar';
import TermsOfUse from '../../../../commons/termsOfUse/TermsOfUse';
import HostingPlan from './HostingPlan';

import Translation from '../../../../../translation/Translation';

class HostingPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: null,
      addressValue: null,
      autoHideDuration: 4000,
      hostingPlans: null,
      isAddressesLoading: false,
      isHostingPlansLoading: false,
      isPaymentMethodsLoading: false,
      isSubmitLoading: false,
      isTermsOpen: false,
      isTermsAccepted: false,
      error: null,
      paymentMethods: null,
      paymentMethodValue: null,
      planSelected: null,
      isSnackbarOpen: false,
      snackbarMessage: '',
      snackbarStatus: '',
    };

    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleChangePaymentMethods =
      this.handleChangePaymentMethods.bind(this);
    this.handleSelectPlan = this.handleSelectPlan.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenTerms = this.handleOpenTerms.bind(this);
    this.handleCheckTerms = this.handleCheckTerms.bind(this);
    this.handleCloseTerms = this.handleCloseTerms.bind(this);
  }

  componentWillMount() {
    const { orderId, productId, userType } = this.props;

    this.setState({
      error: null,
      isAddressesLoading: true,
      isHostingPlansLoading: true,
      isPaymentMethodsLoading: true,
    });

    getOrderProductHostingPlans(
      userType,
      orderId,
      productId,
      (data) => {
        this.setState({
          hostingPlans: data,
          isHostingPlansLoading: false,
        });
      },
      (error) => {
        const errorMessage =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : Translation().account.orders.hosting_plans.callbacks_get.error;

        this.setState({
          error: errorMessage,
          hostingPlans: null,
          isHostingPlansLoading: false,
          isSnackbarOpen: true,
          snackbarMessage: errorMessage,
          snackbarStatus: 'error',
        });
      }
    );

    getInvoiceUserAddresses(
      (success) => {
        let addressesArray = [];

        objectMapToArray(success, (index, address) => {
          let addressObject = {};

          addressObject['value'] = address.id;
          addressObject['label'] =
            address.name +
            ' (' +
            (address.street_number ? address.street_number : '') +
            ' ' +
            (address.street ? address.street : '') +
            ' ' +
            (address.zip_code ? address.zip_code : ' ') +
            ' ' +
            (address.city ? address.city : '') +
            ')';
          addressesArray.push(addressObject);
        });

        this.setState({
          addresses: addressesArray,
          addressValue:
            addressesArray[0] && addressesArray[0] ? addressesArray[0] : null,
          isAddressesLoading: false,
        });
      },
      (error) => {
        const errorMessage =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : Translation().account.orders.hosting_plans.callbacks_get.error;

        this.setState({
          addresses: null,
          isAddressesLoading: false,
          isSnackbarOpen: true,
          snackbarMessage: errorMessage,
          snackbarStatus: 'error',
        });
      }
    );

    getUserPaymentMethod(
      'usable',
      null,
      false,
      { way: 'pay' },
      (success) => {
        let paymentMethodsArray = [];

        for (let i = 0; i < success.length; i++) {
          let paymentMethods = {};

          paymentMethods['value'] = success[i].id;
          paymentMethods['label'] =
            (success[i].type ? success[i].type : '') + ' - ' + success[i].name;

          paymentMethodsArray.push(paymentMethods);
        }

        this.setState({
          paymentMethods: paymentMethodsArray,
          paymentMethodValue:
            paymentMethodsArray[0] && paymentMethodsArray[0]
              ? paymentMethodsArray[0]
              : null,
          isPaymentMethodsLoading: false,
        });
      },
      (error) => {
        const errorMessage =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : Translation().account.orders.hosting_plans.callbacks_get.error;

        this.setState({
          paymentMethods: null,
          isPaymentMethodsLoading: false,
          isSnackbarOpen: true,
          snackbarMessage: errorMessage,
          snackbarStatus: 'error',
        });
      }
    );
  }

  closeSnackbar() {
    this.setState({ isSnackbarOpen: false });
  }

  handleChangeAddress(value) {
    this.setState({
      addressValue: value,
    });
  }

  handleChangePaymentMethods(value) {
    this.setState({
      paymentMethodValue: value,
    });
  }

  handleSelectPlan(id) {
    if (!id) return;

    this.setState({ planSelected: id });
  }

  handleSubmit() {
    const { orderId, productId, onPostSuccess, userType } = this.props;

    const { addressValue, isTermsAccepted, planSelected, paymentMethodValue } =
      this.state;

    if (!planSelected || !isTermsAccepted) return;

    this.setState({ isSubmitLoading: true });

    postOrderProductHostingPlan(
      userType,
      orderId,
      productId,
      {
        invoice_payment_method_id: paymentMethodValue.value,
        invoice_address_id: addressValue.value,
        product_hosting_plan_id: planSelected,
      },
      (success) => {
        if (onPostSuccess && typeof onPostSuccess === 'function') {
          onPostSuccess(success);
        }
        this.setState({ isSubmitLoading: false });
      },
      (error) => {
        const errorMessage =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.detail
            ? error.response.data.detail
            : Translation().account.orders.hosting_plans.callbacks_post.error;

        this.setState({
          isSubmitLoading: false,
          isSnackbarOpen: true,
          snackbarMessage: errorMessage,
          snackbarStatus: 'error',
        });
      }
    );
  }

  handleOpenTerms() {
    this.setState({ isTermsOpen: true });
  }

  handleCloseTerms() {
    this.setState({ isTermsOpen: false });
  }

  handleCheckTerms(event) {
    this.setState({ isTermsAccepted: event.target.checked });
  }

  render() {
    const {
      addresses,
      addressValue,
      autoHideDuration,
      error,
      isAddressesLoading,
      isHostingPlansLoading,
      isPaymentMethodsLoading,
      isSubmitLoading,
      isTermsAccepted,
      isTermsOpen,
      hostingPlans,
      paymentMethods,
      paymentMethodValue,
      planSelected,
      isSnackbarOpen,
      snackbarMessage,
      snackbarStatus,
    } = this.state;

    const isLoading =
      isHostingPlansLoading ||
      isAddressesLoading ||
      isPaymentMethodsLoading ||
      isSubmitLoading;

    return (
      <div className="hosting-plans">
        {!error ? (
          <div>
            <div className="hosting-plans-result">
              {isHostingPlansLoading && (
                <div className="hosting-loader-wrapper">
                  <div className="loader-api" />
                </div>
              )}
              {hostingPlans && hostingPlans.length ? (
                hostingPlans.map((plan, index) => {
                  return (
                    <HostingPlan
                      key={index}
                      plan={plan}
                      selected={planSelected}
                      onClick={this.handleSelectPlan}
                    />
                  );
                })
              ) : !isHostingPlansLoading ? (
                <div className="error-message">
                  {Translation().account.orders.hosting_plans.empty}
                </div>
              ) : (
                false
              )}
            </div>
            {hostingPlans && hostingPlans.length ? (
              <div>
                <div className="hosting-payment">
                  <Col lg={6} md={6} sm={12}>
                    <label htmlFor="address">
                      {Translation().shop.payment.invoice_address}: *
                    </label>
                    {isAddressesLoading ? (
                      <div className="loader-api" />
                    ) : (
                      <div>
                        {addresses && addresses.length > 0 ? (
                          <Select
                            valueKey="value"
                            value={addressValue}
                            onChange={this.handleChangeAddress}
                            clearable
                            searchable={false}
                            id="address"
                            name="address"
                            options={addresses}
                            placeHolder="Adresse"
                            className="app-basic-input select-inputs invoice-input"
                          />
                        ) : (
                          <p>
                            {
                              Translation().shop.step_fields.names
                                .add_invoice_address
                            }
                          </p>
                        )}
                      </div>
                    )}
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <label htmlFor="payment_method">
                      {Translation().shop.payment.payment_method}: *
                    </label>
                    {isPaymentMethodsLoading ? (
                      <div className="loader-api" />
                    ) : (
                      <div>
                        {paymentMethods && paymentMethods.length > 0 ? (
                          <Select
                            valueKey="value"
                            value={paymentMethodValue}
                            onChange={this.handleChangePaymentMethods}
                            clearable
                            searchable={false}
                            id="payment_method"
                            name="payment_method"
                            options={paymentMethods}
                            placeHolder="CB N°"
                            className="app-basic-input select-inputs payment-input"
                          />
                        ) : (
                          <p>
                            {
                              Translation().shop.step_fields.names
                                .add_payment_method
                            }
                          </p>
                        )}
                      </div>
                    )}
                  </Col>
                  <Clearfix />
                  <div className="accept-terms">
                    <label htmlFor="terms-of-sales">
                      <Checkbox
                        name="terms_of_sales"
                        id="terms-of-sales"
                        style={{
                          width: '30px',
                          display: 'inline-block',
                        }}
                        required={true}
                        onCheck={this.handleCheckTerms}
                      />
                      <span>
                        {Translation().shop.payment.term_of_sales.read}{' '}
                        <a onClick={this.handleOpenTerms}>
                          {Translation().shop.payment.term_of_sales.tos}
                        </a>{' '}
                        {Translation().shop.payment.term_of_sales.agree} *
                      </span>
                    </label>
                  </div>
                </div>
                <div className="submit-wrapper">
                  <button
                    className="app-button-color"
                    disabled={
                      !addressValue ||
                      !paymentMethodValue ||
                      isLoading ||
                      !planSelected ||
                      !isTermsAccepted
                    }
                    onClick={this.handleSubmit}
                  >
                    {isSubmitLoading ? (
                      <span
                        className="loader-api"
                        style={{ display: 'inline-block' }}
                      />
                    ) : (
                      Translation().account.orders.hosting_plans.modal.confirm
                    )}
                  </button>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        ) : (
          <div className="error-message">{error}</div>
        )}
        <Dialog
          modal={false}
          actions={[
            <div onClick={this.handleCloseTerms} className="btn-tos-modal">
              {Translation().shop.payment.modal.close
                ? Translation().shop.payment.modal.close
                : 'Close'}
            </div>,
          ]}
          className="term-use-modal"
          autoScrollBodyContent={true}
          onRequestClose={this.handleCloseTerms}
          open={isTermsOpen}
        >
          <TermsOfUse isDialog={true} />
        </Dialog>
        <Snackbar
          messageText={snackbarMessage}
          statusClass={snackbarStatus}
          open={isSnackbarOpen}
          autoHideDuration={autoHideDuration}
          closeFunction={this.closeSnackbar}
        />
      </div>
    );
  }
}

export default HostingPlans;
