import React, { Component } from 'react';

import LoginForm from './LoginForm';
import { apiImplicitLogin } from '../../../helpers/UserFunctions';
import store from '../../../redux/store';

class LoginView extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps() {
    const { onLogin } = this.props;
    const state = store.getState();

    if (state && state.user && onLogin) {
      onLogin();
    }
  }

  handleSubmit(values) {
    const { onLogin } = this.props;
    apiImplicitLogin(values, onLogin);
  }

  render() {
    return (
      <div>
        <LoginForm onSubmit={this.handleSubmit} />
        <div form="login" />
      </div>
    );
  }
}

export default LoginView;
