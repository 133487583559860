import React from 'react';
import { Col, Clearfix } from 'react-bootstrap';
import Translation from '../../../translation/Translation';

class HowItWorkStepper extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      activeStep: 1,
      duration: 4000,
    }

    this.setStep = this.setStep.bind(this);
    this.timer = undefined;
  }

  componentDidMount() {

    let buffer = this.state.activeStep;

    this.timer = setInterval(() => {

      buffer += 1;

      if (buffer > 4)
        buffer = 1;

      this.setState({ activeStep: buffer });

    }, this.state.duration);
  }

  setStep(step) {

    if (!step) return;

    clearInterval(this.timer);
    this.setState({ activeStep: step });
  }

  render() {

    const {
      activeStep
    } = this.state;

    return (
      <section className="hiw-home" id="lunch-milestones">
        <div className="container">
          <div className="row">
            <Col xs={12} sm={12} md={1} className="hiw-steps-list">
              <ul>
                <li className={activeStep === 1 ? "active-step" : ""}><span onClick={() => this.setStep(1)}>01</span></li>
                <li className={activeStep === 2 ? "active-step" : ""}><span onClick={() => this.setStep(2)}>02</span></li>
                <li className={activeStep === 3 ? "active-step" : ""}><span onClick={() => this.setStep(3)}>03</span></li>
                <li className={activeStep === 4 ? "active-step" : ""}><span onClick={() => this.setStep(4)} className="last-step">04</span></li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={5}>
              <div className="hiw-images">
                <div className={"img-step " + (activeStep === 1 ? "active-step" : "")}><img src="/assets/images/how_it_work/chose-service.png" alt=""/></div>
                <div className={"img-step " + (activeStep === 2 ? "active-step" : "")}><img src="/assets/images/how_it_work/chose-date.png" alt=""/></div>
                <div className={"img-step " + (activeStep === 3 ? "active-step" : "")}><img src="/assets/images/how_it_work/provider-interventions.png" alt=""/></div>
                <div className={"img-step " + (activeStep === 4 ? "active-step" : "")}><img src="/assets/images/how_it_work/check-order.png" alt=""/></div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} className="hiw-steps-blocks">
              <div className="intro-hiw">
                <h2>{Translation().pages.home.how_it_work.title}</h2>
                <p>{Translation().pages.home.how_it_work.baseline}</p>
              </div>
              <div className={"hiw-steps " + (activeStep === 1 ? "active-step" : "")}>
                <h4>{Translation().pages.home.how_it_work.steps.select.title}</h4>
                <p>{Translation().pages.home.how_it_work.steps.select.text}</p>
              </div>
              <div className={"hiw-steps " + (activeStep === 2 ? "active-step" : "")}>
                <h4>{Translation().pages.home.how_it_work.steps.book.title}</h4>
                <p>{Translation().pages.home.how_it_work.steps.book.text}</p>
              </div>
              <div className={"hiw-steps " + (activeStep === 3 ? "active-step" : "")}>
                <h4>{Translation().pages.home.how_it_work.steps.provider.title}</h4>
                <p>{Translation().pages.home.how_it_work.steps.provider.text}</p>
              </div>
              <div className={"hiw-steps " + (activeStep === 4 ? "active-step" : "")}>
                <h4>{Translation().pages.home.how_it_work.steps.download.title}</h4>
                <p>{Translation().pages.home.how_it_work.steps.download.text}</p>
              </div>
              <div className="hiw-btn-wrapper">
                <a href="/how-it-works" className="more-infos">{Translation().pages.home.products.more_infos}</a>
                <div className="app-button-video play-video"><span className="icon-player"></span>{Translation().pages.home.page_title.buttons.watch_vid}</div>
              </div>
            </Col>
            <Clearfix/>
          </div>
        </div>
      </section>
    )
  }


  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = undefined;
  }
}

export default HowItWorkStepper;
